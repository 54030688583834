/**
 * EZModelArea
 * @class
 * @classdesc This is the **EZModelArea** class.
 *
 * This class manages each of the geometric faces of a {@link EZModelRoof}.
 *
 * @description This is the constructor of the **EZModelArea** class.
 *
 * @param {EZModelScene}    ez3dScene   - Main container for 3DLayout project model.
 * @param {EZModelRoof}     roof        - The parent of the area.
 * @param {object}          data        - JSON with the data of the area.
 * @param {boolean}         clone       - Is the area being created as a clone?
 */
function EZModelArea(ez3dScene, roof, data, clone) {
    // ez3dScene = ez3dScene;
    if (clone || data === undefined) {
        EZModelObject.call(this, null);
    } else {
        EZModelObject.call(this, data.id || null);
    }

    // Main properties
    this.parent = roof;
    this.setPath(data.path, roof, clone);
    // @deprecated parentId
    this.parentId = this.parent.id;

    this.style = {
        visible: true,
        colorState: 'standard'
    };

    // Load (and set watched) properties
    this.__data = this.getDefaultArea(data);
    this.addWatchers();
    this.subareas = [];

    // Custom properties
    if (data.customProperties && data.customProperties.length > 0) {
        this.customProperties = data.customProperties;
        data.customProperties.forEach((property) => {
            this[property] = data[property];
        });
    }

    // inclination
    if (data && data.inclination !== undefined) {
        data.inclination = Number(data.inclination);
    }
    this.inclination = roof.calculateInclination(this.path, data.inclination);
    const maxAreaInclination = ez3dScene.layoutRules
        .scenePreferences.maxAreaInclination;
    this.ramp = Math.abs(this.inclination) > maxAreaInclination;
    if (this.inclination === 0 && roof.type !== 'flat') {
        const roofData = {
            path: this.path,
            baseHeight: [roof.height, roof.id],
            version: ez3dScene.version
        };
        this.linkedRoof = ez3dScene.factories['Roof']
            .createRoof(ez3dScene, roof.parent, roofData);
    } else if (this.ramp || roof.parent.populated === false) {
        this.__data.disabled = true;
        this.__data.populated = false;
    }

    // calculate axis (with azimuth and angle)
    this.axis = this.calculateAxis();
    this.flattenOrigin = this.path.calculateFlattenCartesian(
        this.axis, this.angle, this.inclination);
    if (this.flattenOrigin.z <= 0.5) {
        this.ramp = true; return;
    }
    /**
     * vertical distance from the gutter of the building to this area
     * @type {float}
     * @default 0
     */
    this.height = this.calculateHeight(roof);
    this.updateHeight();
    if (this.linkedRoof || this.ramp) return;
    delete this.ramp;

    // system info
    this.systemInfo = {holders: 0, modules: 0, power: 0};
    this.keepouts = {};

    // Children
    if (!roof.isEditing && !this.linkedRoof) {
        if (data.subareas && data.subareas.length) {
            data.subareas.forEach((subarea) => {
                this.addSubarea(ez3dScene.factories['Subarea']
                    .createSubarea(ez3dScene, this, subarea, clone));
            });
        } else {
            this.createSubareas();
        }
    }

    // Update
    if (!ez3dScene.isLoadingProject &&
        this.getAncestor('EZModelBuilding').isCreated) {
        this.updatePath(undefined, true);
    }
}

EZModelArea.prototype = Object.create(EZModelObject.prototype);
EZModelArea.prototype.constructor = EZModelArea;

// GET
/**
 * Method to generate and/or asign the watched properties of the area.
 *
 * @param  {object} data    - JSON with the data of the area.
 *
 * @return {object} JSON with the watched properties of the area
 */
EZModelArea.prototype.getDefaultArea = function(data) {
    var properties = {};
    data = (data) ? data : {};

    // getDefault
    properties.disabled = ez3dScene.getModelProperty(
        'defaultModelValues', 'area', 'disabled', data);
    properties.populated = ez3dScene.getModelProperty(
        'defaultModelValues', 'area', 'populated', data);
    properties.offset = ez3dScene.getModelProperty(
        'roofByType', this.parent.type, 'offset', data);

    // generate
    properties.name = this.getName();
    properties.linkedRoof = data.linkedRoof;

    // restrictions
    properties.offset = (ez3dScene.projectType === 'simple')
        ? [0] : properties.offset;

    // console.log('areas', properties);
    return properties;
};

/**
 * Method to calculate (and asign) the index of the area.
 *
 * @param  {EZModelRoof} roof   - the parent of the area.
 *
 * @return {integer}    The index of the area.
 */
EZModelArea.prototype.getIndex = function() {
    const roof = this.parent;
    const indexInParent = (roof && roof.areas)
        ? roof.areas.indexOf(this) : 0;
    return (indexInParent === -1)
        ? roof.areas.length + 1 : indexInParent + 1;
};

/**
 * Method to generate the name of the area.
 *
 * @return {string} The name of the area.
 */
EZModelArea.prototype.getName = function() {
    this.index = this.getIndex();
    return 'Area ' + this.index;
};

/**
 * Method to get the area's children.
 *
 * @param  {boolean} [returnObject]     - **Optional.** The array should be of objects instead of ids?
 * @param  {boolean} [includeParent]    - **Optional.** The parent should be included in the array?
 *
 * @return {array} - Array of the area's children.
 */
EZModelArea.prototype.getChildren = function (returnObject, includeParent) {
    // @TODO replace for a proper import whenever we use ES6+ modules
    const uniteSets = ez3dScene.utils.uniteSets;

    var children = new Set();

    if (includeParent) {
        children.add((returnObject) ? this : this.id);
    }

    children = uniteSets(children, this.path.getChildren(returnObject, true));

    this.subareas.forEach(function(subarea) {
        children = uniteSets(children, subarea.getChildren(returnObject, true));
    });

    var objectsKeys = [];
    objectsKeys = Object.keys(this.keepouts);
    objectsKeys.forEach((key) => {
        this.keepouts[key].forEach((path) => {
            children = uniteSets(children, path.getChildren(returnObject, true));
        });
    });

    return children;
};

// SET
/**
 * Method to set the path of the area.
 *
 * @param {EZModelPath} path    - The path of the area.
 * @param {EZModelRoof} roof    - The parent of the area.
 * @param {boolean} clone       - Is the area being created as a clone?
 */
EZModelArea.prototype.setPath = function(path, roof, clone) {
    ez3dScene.utils.destroyObject(this.path);
    // setCoordSystem
    if (path) {
        this.path = (clone)
            ? new EZModelPath(ez3dScene, path.cloneVertices())
            : path;
    } else {
        const offsetteds = roof.path.getOffsetteds(roof.offset, true);
        let offsettedPath;
        if (offsetteds.length > 1) {
            console.log('EZModelArea', 'setPath', 'multiple offsetted paths');
            offsettedPath = offsetteds[((this.index - 1) < offsetteds)
                ? (this.index - 1) : 0];
        } else if (offsetteds.length === 1) {
            offsettedPath = offsetteds[0];
        } else {
            ee.emitEvent('invalidOffset', [{args: this}]);
            console.warn('EZModelArea', 'setPath',
                'invalid offset, using zero instead');
            roof.__data.offset = [0];
            offsettedPath = roof.path;
        }
        this.path = new EZModelPath(ez3dScene, offsettedPath.cloneVertices());
        // this.fromScratch = true;
    }
    // forceClockWise
    this.path.forceClockWise();
    this.path.closed = true;
    this.path.setCoordSystem('building', this, 'EZModelArea');
};

// UPDATE
/**
 * Method to update the height coordinate of the locations
 */
EZModelArea.prototype.updateHeight = function() {
    this.path.vertices.forEach((vertex) => {
        const opposite = ez3dScene.utils
            .getOppositeH(this.inclination, Math.abs(vertex.yF));
        vertex.z = this.parent.baseHeight[0] + this.height + opposite;
        vertex.z = parseFloat(vertex.z.toFixed(2));
    });
};

/**
 * Method to update the path of the area.
 *
 * @param {object}  offset          - {lat: delta degrees, lng: delta degrees}
 * @param {boolean} ignoreKeepouts  - Should the keepouts be updated too?
 */
EZModelArea.prototype.updatePath = function(offset, ignoreKeepouts) {
    if (offset) {
        this.path.move(offset);
    }
    // Center
    this.path.calculatePathCenter();
    // Update
    this.path.updateCalculations();
    this.path.updateCartesianCoords();
    // Flatten Path
    this.flattenOrigin = this.path.calculateFlattenCartesian(
        this.axis, this.angle, this.inclination);
    this.updateHeight();
    // Offsetted
    var offsetteds = this.path.getOffsetteds(this.offset, true);
    if (offsetteds.length === 0) {
        ee.emitEvent('invalidOffset', [{args: this}]);
        console.warn('EZModelArea', 'updatePath', 'invalid offset, using zero instead');
        this.__data.offset = [0];
        this.path.getOffsetteds(this.offset, true);
        this.createSubareas();
    }
    // Update Childrens
    this.subareas.forEach((subarea) => {
        subarea.updatePath(offset);
    });
    // Update Building
    if (!ignoreKeepouts) {
        this.getAncestor('EZModelBuilding').updateKeepouts();
    }
};

/**
 * Method to update and/or get the info of the system of all the childrens of the area.
 *
 * @param  {boolean} skipUpdate      - Is only the system info wanted? (skip update)
 * @param  {boolean} fromBuilding    - Is this method being called from the building?
 *
 * @return {object} - Info (modules and power) of the systems updated
 */
EZModelArea.prototype.updateSystem = function(skipUpdate, fromBuilding) {
    var data = {holders: 0, modules: 0, power: 0};
    var holder;

    this.subareas.forEach(function(subarea) {
        holder = subarea.updateSystem(skipUpdate, fromBuilding);
        data.holders += (subarea.disabled) ? 0 : holder.holders;
        data.modules += (subarea.disabled) ? 0 : holder.modules;
        data.power += (subarea.disabled) ? 0 : holder.power;
    });

    if (!fromBuilding) {
        this.getAncestor('EZModelBuilding').updateSystem(true);
    }
    this.systemInfo = data;

    ee.emitEvent('areaUpdateSystemFunctionFinished');
    return data;
};

// CALCULATE
/**
 * Method to calculate the area's axis (points of the orientation facing-wall).
 *
 * @return {array} The area's axis (2 {@EZModelLocations}).
 */
EZModelArea.prototype.calculateAxis = function() {
    var axis = [];
    var index = 0;
    if (this.inclination === 0) {
        if (this.path.vertices.length === 4) {
            index = this.path.wallFacing.indexOf('S');
            index = (index === -1) ? 0 : index;
            axis = [
                this.path.vertices[index],
                this.path.vertices[(index + 1) % 4]
            ];
        } else {
            var southest = 270;
            this.path.edgeAzimuths.forEach(function(azimuth, edge) {
                if (Math.abs(azimuth + 90) < southest) {
                    index = edge;
                    southest = Math.abs(azimuth + 90);
                }
            });
            axis = [
                this.path.vertices[index],
                this.path.vertices[(index + 1) % this.path.vertices.length]
            ];
        }
    } else {
        axis = [this.path.vertices[0], this.path.vertices[1]];
    }

    // Calculate Azimuth & Angle using the Axis Index
    this.azimuth = ez3dScene.utils.cleanDegrees(this.path.edgeAzimuths[index] - 90);
    this.angle = this.calculateRotationAngle(this.path.edgeAzimuths[index]);
    return axis;
};

EZModelArea.prototype.calculateHeight = function(roof) {
    let height = roof.elevation;
    if (roof.height === undefined) {
        roof.calculateHeight(this.flattenOrigin.h, this.flattenOrigin.z);
    }
    roof.calculateRidgeHeight();

    if (this.inclination === 0) height += roof.height;
    return height;
};

/**
 * Method to calculate the flatten rotation angle.
 *
 * @param  {float} angle    - azimuth of the area's axis.
 *
 * @return {float}  The rotation angle in radians.
 */
EZModelArea.prototype.calculateRotationAngle = function(angle) {
    return ez3dScene.utils.cleanDegrees((270 - angle));
};


// UTILS
/**
 * Method to add a new subarea to the area's subarea list
 *
 * @param {EZModelSubarea} subarea - the subarea to be added
 *
 * @return {EZModelSubarea} the subarea that has been added
 */
EZModelArea.prototype.addSubarea = function(subarea) {
    if (subarea !== undefined) {
        this.subareas.push(subarea);
    }
    return subarea;
};

/**
 * Method to (re)create the default subareas ({@EZModelSubarea}) of the area.
 */
EZModelArea.prototype.createSubareas = function() {
    var subarea;
    this.subareas = [];
    var offsetteds = this.path.getOffsetteds(this.offset, true);
    if (offsetteds.length) {
        var offsettedPath;
        offsetteds.forEach((offsetted) => {
            offsettedPath = new EZModelPath(ez3dScene, offsetted.cloneVertices());
            subarea = this.addSubarea(ez3dScene.factories['Subarea']
                .createSubarea(ez3dScene, this, offsettedPath));
            subarea.fromScratch = true;
        });
    } else {
        ee.emitEvent('invalidOffset', [{args: this}]);
        console.warn('EZModelArea', 'createSubareas', 'invalid offset, using zero instead');
        this.__data.offset = [0];
        subarea = this.addSubarea(ez3dScene.factories['Subarea']
            .createSubarea(ez3dScene, this));
        subarea.fromScratch = true;
    }
};

/**
 * This method removes (all) the keepouts projections of the area.
 * If a keepoutId is given only the projections of that keepout will be erased.
 *
 * @param {uuid} keepoutId  - **Optional.** The id of the keepout.
 */
EZModelArea.prototype.removeKeepouts = function(keepoutId) {
    if (keepoutId) {
        ez3dScene.utils.destroyObject(this.keepouts[keepoutId]);
        delete this.keepouts[keepoutId];
    } else {
        this.keepouts = {};
    }
};

/**
 * EZModelBuilding
 * @class
 * @classdesc This is the **EZModelBuilding** class.
 *
 * This class manages each of the buildings of the project
 *
 * @description This is the constructor of the **EZModelBuilding** class.
 *
 * @param {EZModelScene}    ez3dScene   - Main container for 3DLayout project model.
 * @param {object}          data        - JSON with the data of the building.
 * @param {boolean}         clone       - Is the building being created as a clone?
 */
function EZModelBuilding(ez3dScene, data, clone) {
    // ez3dScene = ez3dScene;
    if (clone || data === undefined) {
        EZModelObject.call(this, null);
    } else {
        EZModelObject.call(this, data.id || null);
    }

    // Main properties
    this.parent = ez3dScene;
    // @deprecated parentId
    this.parentId = this.parent.id;

    this.isCreated = (data && data.isCreated !== undefined)
        ? data.isCreated : false;
    this.isEditing = (data && data.isEditing !== undefined)
        ? data.isEditing : false;

    this.style = {
        visible: true,
        colorState: 'standard'
    };

    // Watched properties
    this.__data = this.getDefaultBuilding(data);
    this.addWatchers();

    // Custom properties
    if (data.customProperties && data.customProperties.length > 0) {
        this.customProperties = data.customProperties;
        data.customProperties.forEach((property) => {
            this[property] = data[property];
        });
    }

    this.roofs = [];
    this.keepouts = [];
    this.structures = [];
    this.systemInfo = {holders: 0, modules: 0, power: 0};

    if (data && data.path) {
        // Finished Object
        this.setPath(data.path, clone);
    } else {
        // Unfinished Object
        this.parent.unfinishedObject = this.id;
        return;
    }

    // Children
    // - Roofs
    if (data && data.roofs && data.roofs.length) {
        data.roofs.forEach((roof) => {
            this.roofs.push(ez3dScene.factories['Roof']
                .createRoof(ez3dScene, this, roof, clone));
        });
    } else {
        var offsetteds = this.path.getOffsetteds(this.offset);
        if (offsetteds.length) {
            offsetteds.forEach((offsetted) => {
                this.roofs.push(ez3dScene.factories['Roof']
                    .createRoof(ez3dScene, this, offsetted));
            });
        } else {
            this.roofs.push(ez3dScene.factories['Roof']
                .createRoof(ez3dScene, this));
        }
    }

    // - Keepouts
    if (data && data.keepouts) {
        data.keepouts.forEach((keepout) => {
            this.keepouts.push(ez3dScene.factories['Keepout']
                .createKeepout(ez3dScene, this, keepout, clone));
        });
    }

    // - Structures
    if (data && data.structures) {
        data.structures.forEach((structure) => {
            // @todo Structures are yet to be implemented
        });
    }

    // Finish
    this.isCreated = true;
    this.updatePath();
}

EZModelBuilding.prototype = Object.create(EZModelObject.prototype);
EZModelBuilding.prototype.constructor = EZModelBuilding;

// GET
/**
 * Method to generate and/or asign the watched properties of the building.
 *
 * @param  {object} data    - JSON with the data of the building.
 *
 * @return {object} JSON with the watched properties of the building.
 */
EZModelBuilding.prototype.getDefaultBuilding = function(data) {
    const loadProperty = (property) => {
        return ez3dScene.getModelProperty(
            'defaultModelValues', 'building', property, data);
    };
    const properties = {};
    data = (data) ? data : {};

    // getDefault
    properties.color = loadProperty('color');
    properties.height = loadProperty('height');
    properties.minHeight = loadProperty('minHeight');
    properties.offset = loadProperty('offset');
    properties.padding = loadProperty('padding');
    properties.populated = loadProperty('populated');
    properties.regular = loadProperty('regular');
    properties.ridge = loadProperty('ridge');
    properties.texture = loadProperty('texture');

    // generate
    // properties.areas = [];
    properties.name = this.getName();
    properties.ridge.height = (properties.ridge.height === undefined)
        ? properties.height : properties.ridge.height;
    properties.ridge.maxRoof = 0;
    properties.ridge.minRoof = 0;

    // restrictions
    if (ez3dScene.projectType === 'simple') {
        properties.offset = [0];
        properties.populated = true;
    }

    return properties;
};

/**
 * Method to calculate (and asign) the index of the building.
 *
 * @return {integer}    The index of the building.
 */
EZModelBuilding.prototype.getIndex = function() {
    const scene = this.parent;
    const indexInParent = (scene && scene.buildings)
        ? scene.buildings.indexOf(this) : 0;
    return (indexInParent === -1)
        ? ez3dScene.buildings.length + 1 : indexInParent + 1;
};

/**
 * Method to generate the name of the building.
 *
 * @return {string} The name of the building.
 */
EZModelBuilding.prototype.getName = function() {
    this.index = this.getIndex();
    return 'Building ' + this.index;
};

/**
 * Method to get the building's children.
 *
 * @param  {boolean} [returnObject]     - **Optional.** The array should be of objects instead of ids?
 * @param  {boolean} [includeParent]    - **Optional.** The parent should be included in the array?
 *
 * @return {array} - Array of the building's children.
 */
EZModelBuilding.prototype.getChildren = function(returnObject, includeParent) {
    // @TODO replace for a proper import whenever we use ES6+ modules
    const uniteSets = ez3dScene.utils.uniteSets;

    var children = new Set();

    if (includeParent) {
        children.add((returnObject) ? this : this.id);
    }

    if (this.path) {
        children = uniteSets(children, this.path
            .getChildren(returnObject, true));
    }
    if (this.roofs && this.roofs.length > 0) {
        this.roofs.forEach((roof) => {
            children = uniteSets(children, roof
                .getChildren(returnObject, true));
        });
    }

    if (this.keepouts && this.keepouts.length > 0) {
        this.keepouts.forEach((keepout) => {
            children = uniteSets(children, keepout
                .getChildren(returnObject, true));
        });
    }

    return children;
};

// SET
/**
 * Method to set the path of the building.
 *
 * @param {EZModelPath} path    - The path of the building.
 * @param {boolean} clone       - Is the building being created as a clone?
 */
EZModelBuilding.prototype.setPath = function(path, clone) {
    ez3dScene.utils.destroyObject(this.path);
    // setCoordSystem
    this.path = (clone) ? new EZModelPath(ez3dScene, path.cloneVertices()) : path;
    // forceClockWise
    this.path.forceClockWise();
    this.path.closed = true;
    this.path.setCoordSystem('building', this, 'EZModelBuilding');
};

// UPDATE
/**
 * Method to update the keepouts of the building.
 *
 * @param {object}  offset          - {lat: delta degrees, lng: delta degrees}
 * @param {boolean} [ignoreSystems] - Should we skip the update of the systems?
 */
EZModelBuilding.prototype.updateKeepouts = function(offset, ignoreSystems) {
    this.roofs.forEach((roof) => {
        roof.areas.forEach((area) => {
            area.removeKeepouts();
        });
    });
    this.keepouts.forEach((keepout) => {
        keepout.updatePath(offset, ignoreSystems);
    });
    if (!ignoreSystems) {
        this.updateSystem();
    }
    ee.emitEvent('updateKeepoutsFunctionFinished');
};

/**
 * Method to update the height coordinate of the locations
 */
EZModelBuilding.prototype.updateHeight = function() {
    this.path.vertices.forEach((vertex) => {
        vertex.z = this.height;
    });
};

/**
 * Method to update the path of the building.
 *
 * @param {object} [offset] - {lat: delta degrees, lng: delta degrees}
 */
EZModelBuilding.prototype.updatePath = function(offset) {
    this.path.updateCalculations();
    if (offset) {
        this.path.move(offset);
    }
    this.path.calculatePathCenter();
    this.path.updateCartesianCoords();
    // Offsetted
    var offsetteds = this.path.getOffsetteds(this.offset, true);
    if (offsetteds.length === 0) {
        this.__data.offset = [0];
        this.path.getOffsetteds(this.offset, true);
    }

    this.updateHeight();
    // Update Childrens
    this.roofs.forEach((roof) => {
        roof.updatePath(offset, true);
    });

    this.updateKeepouts(offset, true);

    // Update Systems
    this.updateSystem();

    // @todo Add Structures
    // this.structures.forEach(function (structure) {
    //     structure.updatePath();
    // });
};

/**
 * Method to update and/or get the info of the system of all the childrens of the building
 *
 * @param  {[type]} skipUpdate      - Is only the system info wanted? (skip update)
 *
 * @return {object} Info (modules and power) of the systems updated
 */
EZModelBuilding.prototype.updateSystem = function(skipUpdate) {
    // console.warn('building.updateSystem', skipUpdate);
    var data = {holders: 0, modules: 0, power: 0};
    var holder;

    this.roofs.forEach((roof) => {
        holder = roof.updateSystem(skipUpdate, true);
        data.holders += holder.holders;
        data.modules += holder.modules;
        data.power += holder.power;
    });

    this.systemInfo = data;
    return data;
};

// UTILS
EZModelBuilding.prototype.checkRidgeHeight = function() {
    const building = this.__data;
    const delta = building.ridge.maxRoof - building.ridge.minRoof;
    if (building.ridge.height >= delta + this.minHeight) {
        building.height = building.ridge.height - building.ridge.maxRoof;
    } else if (building.isCreated === false) {
        console.warn('Invalid ridge height detected');
        building.ridge.maxRoof = 0;
        building.height = building.ridge.height;
    } else {
        console.error('EZModelRoof', 'calculateHeight',
            'Invalid ridge height, adding minimun gutter height');
        // @todo send this notification from interface
        ee.emitEvent('widget_notification', [{
            'name': 'buildingHeight',
            'title': 'alertBuildingHeightTitle',
            'content': 'alertBuildingHeightContent'
        }]);
        building.height = this.minHeight - building.ridge.minRoof;
        building.ridge.height = this.minHeight + building.ridge.maxRoof;
    }
};

/**
 * Method to validate building height
 * @param  {object} data this is a json data for construct the block
 * @return {[type]}      [description]
 */
EZModelBuilding.prototype.confirmBuildingHeight = function(data) {
    var confirmation = false;
    var ridge = this.ridge.enabled;
    if (ridge && data <= this.ridge.maxRoof + this.minHeight) {
        confirmation = true;
    }
    return confirmation;
};

/**
 * Method to get enabled building subareas.
 * @param  {object} building    - Building object.
 * @return {array} subareasData - List of enabled subareas of the building
 */
EZModelBuilding.prototype.getEnabledSubareas = function(building) {
    var subareasData = [];
    building.roofs.forEach((roof) => {
        roof.areas.forEach((area) => {
            area.subareas.forEach((subarea) => {
                if (!subarea.disabled) {
                    subareasData.push(subarea);
                }
            });
        });
    });
    return subareasData;
};

/**
 * Check if a building child (subarea, keepout, etc) is being created or edited.
 * @param  {constructor} objectConstructor  - Constructor
 * @return {boolean}                        - If a object by constructor is being created/edited
 */
EZModelBuilding.prototype.checkIfChildIsBeingEdited = function (objectConstructor) {
    if (ez3dScene.activeBuilding.id !== this.id) return false;
    return (ez3dScene.context === 'pathEditor' &&
        ez3dScene.active.constructor === objectConstructor);
};

/**
 * EZModelObject
 *
 * @class
 * @classdesc This id the **EZModelObject** class.
 *
 * This class is used to extend other classes with the clone, move,
 * edit and delete methods. It also adds the *editableAttr* attribute to the
 * other classes and manages the id creation for this classes.
 *
 * @param {string} id           - Optional. UUID v4 value.
 * @param {string} editableAttr - Optional. It's used for modify an object in the operators.
 */
function EZModelObject(id, editableAttr) {
    this.id = id || uuid.v4();
    this.editableAttr = editableAttr || 'path';
}

/**
 * Method to create getters and setters from the __data attribute.
 *
 * When a value is updated a (watcher) event is throwed.
 */
EZModelObject.prototype.addWatchers = function() {
    var self = this;
    // add watchers to attributes in __data
    Object.keys(self.__data).forEach(function(i) {
        var eventName = self.constructor.name + '_set' + ez3dScene.utils.capitalize(i);
        if (typeof self.__data[i] === 'object' && !Array.isArray(self.__data[i])) {
            self[i] = {};
            Object.keys(self.__data[i]).forEach(function(j) {
                eventName += ez3dScene.utils.capitalize(j);
                self.watchAttribute(i + '.' + j, eventName);
            });
        } else {
            self.watchAttribute(i, eventName);
        }
        if (ez3dScene.events.indexOf(eventName) === -1) {
            ez3dScene.events.push(eventName);
        }
    });
};

/**
 * Method to toggle the interactive move mode (true <-> false)
 */
EZModelObject.prototype.setInteractiveMoveToggle = function() {
    if (ez3dScene.mode === 'interactiveMove') {
        ee.emitEvent('setInteractiveMove', [false]);
    } else {
        ee.emitEvent('setInteractiveMove', [true]);
    }
};

/**
 * Method to get a specific ancestor of the object
 *
 * @param  {string} object  - type of the wanted ancestor
 *
 * @return {object} The ancestor (once is found)
 */
EZModelObject.prototype.getAncestor = function(object) {
    var target = this.parent;
    if (target.constructor.name === 'EZModelScene') {
        console.error('EZModelObject', 'getAncestor', object + ' not found');
    } else if (target.constructor.name !== object) {
        target = target.getAncestor(object);
    }
    return target;
};

/**
 * Method to remove (emancipate) the object from his parent's tree
 */
EZModelObject.prototype.removeObject = function() {
    var parent = this.parent;
    var list = this.constructor.name.substr(7).toLowerCase();
    if (parent[list]) {
        delete parent[list];
    } else if (parent[list + 's']) {
        var self = this;
        var select = -1;
        parent[list + 's'].forEach(function(item, index) {
            if (select === -1 && item.id === self.id) {
                select = index;
            }
        });
        if (select >= 0) {
            parent[list + 's'].splice(select, 1);
        }
    } else {
        console.error('EZModelObject', 'removeObject', 'Object to remove does not have a parent');
    }
};

EZModelObject.prototype.destroyObject = function() {
    ez3dScene.utils.destroyObject(this);
};

/**
 * EZModelKeepout
 * @class
 * @classdesc This is the **EZModelKeepout** class.
 *
 * This class manages each of the obstacles to be found in the building's roofs
 *
 * @description This is the constructor of the **EZModelKeepout** class.
 *
 * @param {EZModelScene} ez3dScene      - Main container for 3DLayout project model.
 * @param {EZModelBuilding} building    - The parent of this keepout.
 * @param {object} data                 - JSON with the data of the keepout.
 * @param {boolean} clone               - Is this keepout being created as a clone?
 */
function EZModelKeepout(ez3dScene, building, data, clone) {
    // ez3dScene = ez3dScene;
    if (clone || data === undefined) {
        EZModelObject.call(this, null);
    } else {
        EZModelObject.call(this, data.id || null);
    }
    this.parent = building;
    this.setPath(data.path, building, clone);
    // @deprecated parentId
    this.parentId = this.parent.id;

    this.style = {
        visible: true,
        colorState: 'standard'
    };

    this.__data = this.getDefaultKeepout(data);
    this.addWatchers();

    this.isCreated = (data && data.isCreated !== undefined) ? data.isCreated : true;
    this.isEditing = (data && data.isEditing !== undefined) ? data.isEditing : false;
    if (!ez3dScene.isLoadingProject) {
        this.updatePath(0, true);
    }
}

EZModelKeepout.prototype = Object.create(EZModelObject.prototype);
EZModelKeepout.prototype.constructor = EZModelKeepout;

// GET
/**
 * Method to generate and/or asign the watched properties of the keepout.
 *
 * @param  {object} data    - JSON with the data of the keepout.
 *
 * @return {object} JSON with the watched properties of the keepout
 */
EZModelKeepout.prototype.getDefaultKeepout = function(data) {
    var properties = {};
    data = (data) ? data : {};

    // getDefault
    properties.height = ez3dScene.getModelProperty('defaultModelValues', 'keepout', 'height', data);
    properties.invisible = ez3dScene.getModelProperty('defaultModelValues', 'keepout', 'invisible', data);
    properties.offset = ez3dScene.getModelProperty('defaultModelValues', 'keepout', 'offset', data);
    properties.regular = ez3dScene.getModelProperty('defaultModelValues', 'keepout', 'regular', data);
    properties.type = ez3dScene.getModelProperty('defaultModelValues', 'keepout', 'type', data);
    properties.color = ez3dScene.getModelProperty('defaultModelValues', 'keepout', 'color', data);

    // generate
    properties.name = this.getName();

    return properties;
};

/**
 * Method to calculate (and asign) the index of the keepout.
 *
 * @param  {EZModelBuilding}    building    - the parent of the keepout.
 *
 * @return {integer}    The index of the keepout.
 */
EZModelKeepout.prototype.getIndex = function() {
    const building = this.parent;
    const indexInParent = (building && building.keepouts)
        ? building.keepouts.indexOf(this) : 0;
    return (indexInParent === -1)
        ? building.keepouts.length + 1 : indexInParent + 1;
};

/**
 * Method to generate the name of the keepout.
 *
 * @return {string} The name of the keepout.
 */
EZModelKeepout.prototype.getName = function() {
    this.index = this.getIndex();
    return 'Keepout ' + this.index;
};

/**
 * Method to get the keepout's children.
 *
 * @param  {boolean} [returnObject] - **Optional.** The array should be of objects instead of ids?
 * @param  {boolean} [includeParent]    - **Optional.** The parent should be included in the array?
 *
 * @return {array} - Array of the keepout's children.
 */
EZModelKeepout.prototype.getChildren = function (returnObject, includeParent) {
    // @TODO replace for a proper import whenever we use ES6+ modules
    const uniteSets = ez3dScene.utils.uniteSets;

    var children = new Set();

    if (includeParent) {
        children.add((returnObject) ? this : this.id);
    }

    children = uniteSets(children, this.path.getChildren(returnObject, true));

    this.parent.roofs.forEach((roof) => {
        roof.areas
            .filter((area) => area.keepouts && area.keepouts[this.id])
            .forEach((area) => {
                area.keepouts[this.id].forEach((path) => {
                    children = uniteSets(children, path.getChildren(returnObject, true));
                });
            });
    });

    return children;
};

// SET
/**
 * Method to set the path of the keepout.
 *
 * @param {EZModelPath} path            - The path of this keepout.
 * @param {EZModelBuilding} building    - The parent of this keepout.
 * @param {boolean} clone               - Is this keepout being created as a clone?
 */
EZModelKeepout.prototype.setPath = function(path, building, clone) {
    ez3dScene.utils.destroyObject(this.path);
    // setCoordSystem
    if (path) {
        this.path = (clone) ? new EZModelPath(ez3dScene, path.cloneVertices()) : path;
    } else {
        var vertices = [];
        var center = building.path.center;
        // @todo default keepout lng & lat delta needed
        vertices.push(new EZModelLocation(ez3dScene, center.lng + 0, center.lat + 0));
        vertices.push(new EZModelLocation(ez3dScene, center.lng + 0, center.lat + 0));
        vertices.push(new EZModelLocation(ez3dScene, center.lng + 0, center.lat + 0));
        vertices.push(new EZModelLocation(ez3dScene, center.lng + 0, center.lat + 0));
        this.path = new EZModelPath(ez3dScene, vertices);
    }
    // forceClockWise
    this.path.forceClockWise();
    this.path.closed = true;
    this.path.setCoordSystem('building', this, 'EZModelKeepout');
};

// UPDATE
/**
 * Method to update the keepouts projection on the areas.
 */
EZModelKeepout.prototype.updateCollisions = function() {
    var keepoutPath = this.path.getOffsetteds(this.offset);
    keepoutPath = (keepoutPath.length) ? keepoutPath[0] : this.path;
    var collider = {};
    this.multiAreas = [];
    this.parent.roofs.forEach((roof) => {
        roof.areas.forEach((area) => {
            if (area.linkedRoof) return;
            var areaPath = area.path;
            var collisionVertices = ez3dScene.utils.intersectPaths(keepoutPath, areaPath);
            area.removeKeepouts(this.id);
            if (collisionVertices.length > 0) {
                area.keepouts[this.id] = [];
                collisionVertices.forEach((collision) => {
                    var cartesianVertices = [];
                    var sphericalVertices = [];
                    var collisionLocations = [];
                    collision.forEach((vertex, index) => {
                        // change vertices to cartesian scene coords
                        cartesianVertices.push({
                            x: vertex.x + this.parent.path.center.x,
                            y: vertex.y + this.parent.path.center.y
                        });
                        // change vertices from cartesian to spherical coords
                        sphericalVertices.push(ez3dScene.utils.convertCartesianToSpherical(
                            cartesianVertices[index], ez3dScene.projectCenter));
                        // create collision locations
                        collisionLocations.push(new EZModelLocation(
                            ez3dScene, sphericalVertices[index].lng, sphericalVertices[index].lat));
                    });
                    collider = new EZModelPath(ez3dScene, collisionLocations);
                    collider.parent = area;
                    collider.setCoordSystem('building', area, 'EZModelArea', 'Area Keepout Projection');
                    collider.updateCartesianCoords();
                    collider.calculateFlattenCartesian(
                        area.axis, area.angle, area.inclination, area.flattenOrigin
                    );
                    area.keepouts[this.id].push(collider);
                });
                this.multiAreas.push(area);
            }
        });
    });
    // @todo check if some of the keepout's vertices are outside the building's roofs
    this.__data.outside = false;
};

/**
 * Method to update the height coordinate of the locations
 */
EZModelKeepout.prototype.updateHeight = function() {
    this.path.vertices.forEach((vertex) => {
        vertex.z = this.height;
    });

    if (this.outside) {
        // TOWER
        this.path.vertices.forEach((vertex) => {
            vertex.z += this.parent.height;
        });
    } else {
        // CHIMNEY
        var list = [];
        this.multiAreas.forEach((area) => {
            var roof = area.parent;
            var ratio = roof.height / area.flattenOrigin.z;
            if (area.keepouts[this.id]) {
                this.path.vertices.forEach((vertex, index) => {
                    area.keepouts[this.id].forEach((keepout) => {
                        if (keepout.vertices[index]) {
                            var height = Math.abs(keepout.vertices[index].yF);
                            height *= ratio;
                            height += roof.baseHeight[0];
                            height = Math.abs(height);
                            // keepout type
                            if (this.type === 'vertical') {
                                list.push(height);
                            } else {
                                vertex.z = height;
                            }
                        }
                    });
                });
            }
        });
        if (this.type === 'vertical') {
            this.path.vertices.forEach((vertex) => {
                vertex.z += Math.min.apply(null, list);
            });
        }
    }
};

/**
 * Method to update the path of the keepout.
 *
 * @param {object}  [offset]        - {lat: delta degrees, lng: delta degrees}
 * @param {boolean} [ignoreSystems] - Should we skip the update of the systems?
 */
EZModelKeepout.prototype.updatePath = function(offset, ignoreSystems) {
    if (offset) {
        this.path.move(offset);
    }
    this.path.calculatePathCenter();
    this.path.updateCartesianCoords();
    this.path.getOffsetteds(this.offset, false);

    // Update Childrens
    this.updateCollisions(ignoreSystems);
    this.updateHeight();
};

/**
 * EZModelLocation
 * @class
 * @classdesc This is the **EZmodelLocation** class.
 *
 * This class manages the 2d location in World Coord System and Scene Coord System.
 *
 * @description This is the constructor of the class **EZModelLocation**.
 *
 * @param {EZModelScene}    ez3dScene   - Main container for 3DLayout project model.
 * @param {float}           lng         - A longitude.
 * @param {float}           lat         - A latitude.
 * @param {object}          data        - JSON with the data of the location.
 */
function EZModelLocation(ez3dScene, lng, lat, data) {
    // ez3dScene = ez3dScene;
    if (data) {
        this.id = data.id || uuid.v4();
    } else {
        this.id = uuid.v4();
    }

    this.parent = this.path;
    this.lng = lng;
    this.lat = lat;
    this.coordSystem = 'scene';

    this.updateCartesianCoords();
}

EZModelLocation.prototype = Object.create(EZModelObject.prototype);
EZModelLocation.prototype.constructor = EZModelLocation;

/**
 * Method to move the position of the location.
 *
 * @param  {object} offset - Required. A object with lat and lng keys.
 */
EZModelLocation.prototype.updatePosition = function(offset) {
    this.move(offset);
};


// GET
/**
 * Method to get the GeoJSON of the location.
 *
 * @return {object} - A GeoJSON value.
 */
EZModelLocation.prototype.getGeoJSON = function() {
    var geoJSON = {
        type: 'Feature',
        geometry: {
            type: 'Point',
            coordinates: [this.lng, this.lat]
        },
        properties: {
            name: this.id
        }
    };
    return geoJSON;
};

// SET
/**
 * Method to set the location's id, coordinate system and parent pointer
 *
 * @param {string} coordSystem  - The location's coordinate system.
 * @param {object} parent       - The parent of the location.
 * @param {string} parentType   - The class of the parent of the location.
 * @param {object} data         - JSON with the data of the location.
 */
EZModelLocation.prototype.setCoordSystem = function(coordSystem, parent, parentType, data) {
    var self = this;
    // Set id
    if (!self.id) {
        if (data) {
            self.id = (data.id) ? data.id : uuid.v4();
        } else {
            self.id = uuid.v4();
        }
    }

    // Set coordSystem
    self.coordSystem = coordSystem;

    // Set parent
    if (parent && typeof parent === 'string') {
        self.parentId = parent;
        self.parent = ez3dScene.findById(parent);
    } else if (parent) {
        self.parent = parent;
        self.parentId = parent.id;
    }
    self.parentType = parentType;

    // Update
    self.updateCartesianCoords();
};

// UPDATE
/**
 * Method to calculate the cartesian points.
 *
 * @return {EZModelLocation}    The location after being updated.
 */
EZModelLocation.prototype.updateCartesianCoords = function() {
    var cartesian = {};
    if (ez3dScene.projectCenter) {
        cartesian = ez3dScene.utils.convertSphericalToCartesian(this, ez3dScene.projectCenter);

        if (this.coordSystem === 'building' && this.id) {
            var building = this.getAncestor('EZModelBuilding');
            // var building = ez3dScene.getBuilding(this.id);
            if (building && building.path && building.path.center) {
                cartesian.x -= building.path.center.x;
                cartesian.y -= building.path.center.y;
            } else if (this.parentId) {
                console.error('DEPRECATED getBuilding this.parentId');
                // building = ez3dScene.getBuilding(this.parentId);
                cartesian.x -= building.path.center.x;
                cartesian.y -= building.path.center.y;
            } else {
                console.error('Error finding building of location with id ' + this.id);
            }
            building = undefined;
        }
        this.x = cartesian.x;
        this.y = cartesian.y;
    } else {
        this.x = 0;
        this.y = 0;
        ez3dScene.projectCenter = this;
    }
    return this;
};

// OTHER
/**
 * Method to calculate the rotated flatten coordinates of the location
 * @param  {object} center  - pivoting point of the rotation
 * @param  {float}  degree  - angle of the rotation (in degrees)
 */
EZModelLocation.prototype.rotateFlattenPoints = function(center, degree) {
    var angle = ez3dScene.utils.degToRad(degree);
    var pivot = {x: this.x - center.x, y: this.y - center.y};
    this.xF = ((pivot.x * Math.cos(angle) - pivot.y * Math.sin(angle)) + center.x);
    this.yF = ((pivot.x * Math.sin(angle) + pivot.y * Math.cos(angle)) + center.y);
};

/**
 * Method to get the fields about this class.
 * @param  {array} fields - **Required**. The fields to get.
 * @return {object}       - Object with the fields to getted.
 */
EZModelLocation.prototype.getJson = function(fields) {
    if (!fields) {
        fields = [
            'id',
            'lat',
            'lng'
        ];
    }
    const output = EZModelUtils.getSeed(this, fields, ['parent']);
    output['version'] = ez3dScene.version;
    return output;
};

/**
 * EZModelPath
 * @class
 * @classdesc Polyline of 2d vertex in Scene Coord System and World Coord System.
 *
 * @description This is the constructor of the class **EZModelPath**.
 *
 * @param {EZModelScene}    ez3dScene   - Main container for 3DLayout project model.
 * @param {array}           vertices    - Array of {@link EZModelLocation}.
 * @param {object}          data        - JSON with the data of the path.
 */
function EZModelPath(ez3dScene, vertices, data) {
    // ez3dScene = ez3dScene;
    if (data) {
        this.id = data.id || uuid.v4();
    } else {
        this.id = uuid.v4();
    }

    const prefs = ez3dScene.layoutRules.scenePreferences;
    if (prefs.enableBuildingShapes) {
        this.buildingShapeType = prefs.defaultBuildingShape;
        this.buildingShapeValues = ez3dScene.getBuildingShapeValues();
        // customShapeSteps is an array of steps: [wallSize in meters, corner angles in degrees (clockwise if positive)]
        this.customShapeSteps = [
            [prefs.defaultWallDimension, 0],
            [prefs.defaultWallDimension, prefs.defaultCornerAngle],
        ];
    }

    // Main properties
    this.parent = undefined;
    // Children
    this.vertices = vertices;
    this.vertices.forEach((vertex, i) => {
        vertex.index = i;
        vertex.parent = this;
    });

    // Other properties
    this.getDefaultPath();
    this.setCoordSystem('scene', '', 'EZModelScene', data);
}

EZModelPath.prototype = Object.create(EZModelObject.prototype);
EZModelPath.prototype.constructor = EZModelPath;

// CALCULATE
/**
 * Method to calculate the flatten proyection of each vertex of the path.
 *
 * @param  {array}  axis        - points to be used as base line.
 * @param  {float}  azimuth     - rotation angle to be used.
 * @param  {float}  inclination - inclinaton angle to be used.
 * @param  {object} point       - pivoting point to be used.
 *
 * @return {object} The object with the info about the flatten transformation.
 */
EZModelPath.prototype.calculateFlattenCartesian = function(axis, azimuth, inclination, point) {
    var origin = this.translateFlattenPoints(axis, azimuth, point);
    var top = [];
    var right = [];

    var ratio = 1 / Math.cos(ez3dScene.utils.degToRad(inclination));
    this.vertices.forEach((location) => {
        location.yF *= ratio;
        top.push(location.yF);
        right.push(location.xF);
    });

    top = Math.abs(Math.min.apply(null, top));
    right = Math.abs(Math.max.apply(null, right));

    // Update offsettedPaths
    if (this.offsettedPaths) {
        this.offsettedPaths.forEach((offseted) => {
            offseted.calculateFlattenCartesian(axis, azimuth, inclination, point);
        });
    }
    this.updateFlattenCartesian();
    return {x: origin[0], y: origin[1], z: top, w: right, h: origin[2]};
};

/**
 * Method to calculate corners angles of the cartesian path.
 *
 * @return {array}  Array containing the cartesian corner angles.
 */
EZModelPath.prototype.calculateCornersAngles = function() {
    let nextVertex = {};
    let prevVertex = {};
    let nextVector = {};
    let prevVector = {};

    let angleToNext;
    let angleToPrev;
    let angle;

    const bias = ez3dScene.layoutRules.scenePreferences.angleBias + 1.0;
    let regular = true;
    let convex = true;
    this.cornerAngles = [];
    this.concaveAngles = [];

    if (this.vertices.length > 2) {
        this.vertices.forEach((vertex, i, vertices) => {
            if (i === vertices.length - 1) {
                nextVertex = vertices[0];
            } else {
                nextVertex = vertices[i + 1];
            }
            if (i === 0) {
                prevVertex = vertices[vertices.length - 1];
            } else {
                prevVertex = vertices[i - 1];
            }

            nextVector = {
                x: nextVertex.x - vertex.x,
                y: nextVertex.y - vertex.y
            };

            prevVector = {
                x: prevVertex.x - vertex.x,
                y: prevVertex.y - vertex.y
            };

            angleToNext = Math.atan2(nextVector.y, nextVector.x);
            angleToPrev = Math.atan2(prevVector.y, prevVector.x);

            angle = angleToPrev - angleToNext;
            if (angle < -Math.PI) {
                angle += Math.PI * 2;
            }
            angle = ez3dScene.utils.radToDeg(angle);
            angle = ez3dScene.utils.cleanDegrees(angle);

            // checkings
            if (regular) {
                regular = (Math.abs(Math.abs(angle) - 90) < bias);
            }
            const convexAngle = (angle <= (-180 + bias) ||
                (angle >= (0 - bias) &&
                angle <= (180 + bias))
            );
            if (!convexAngle) {
                convex = false;
                let index = (i - 1);
                index = (index < 0) ? vertices.length + index : index;
                if (this.concaveAngles.indexOf(index) === -1) {
                    this.concaveAngles.push(index);
                }
                if (this.concaveAngles.indexOf(i) === -1) {
                    this.concaveAngles.push(i);
                }
            }
            this.cornerAngles.push(angle);
        });
    }
    this.regularAngles = regular;
    // console.log('EZModelPath', 'regularAngles', this.regularAngles);
    this.convex = convex;
    // console.log('EZModelPath', 'convex', this.convex);
    return this.cornerAngles;
};

/**
 * Method to calculate the edge azimuths of the cartesian path.
 *
 * @param  {bool}  isFlatten  - use flatten coords instead of the cartesian ones?
 *
 * @return {array}  Array containing the cartesian edge azimuths.
 */
EZModelPath.prototype.calculateEdgeAzimuths = function (isFlatten) {
    const {pairWithNext, radToDeg, cleanDegrees, getCardinalPoints} = EZModelUtils;
    const PI = Math.PI;
    const edgeAzimuths = [];

    if (this.vertices.length > 1) {
        this.vertices
            .map(pairWithNext)
            .forEach(([vertex, next]) => {
                const vector = {
                    x: (isFlatten)
                        ? (next.xF * 10000) - (vertex.xF * 10000)
                        : (next.x * 10000) - (vertex.x * 10000),
                    y: (isFlatten)
                        ? (next.yF * 10000) - (vertex.yF * 10000)
                        : (next.y * 10000) - (vertex.y * 10000)
                };
                let angle = radToDeg(
                    Math.atan2(vector.y, vector.x) + PI / 2);
                angle = cleanDegrees(angle);

                getCardinalPoints(angle, true);
                edgeAzimuths.push(angle);
            });
    }
    if (isFlatten) {
        this.edgeAzimuthsFlatten = edgeAzimuths;
    } else {
        this.edgeAzimuths = edgeAzimuths;
        this.calculateParallelSides();
    }
    return edgeAzimuths;
};

/**
 * Method to calculate the edge azimuths of the flatten path.
 *
 * @return {array}  Array containing the flatten edge azimuths.
 */
EZModelPath.prototype.calculateEdgeAzimuthsFlatten = function () {
    return this.calculateEdgeAzimuths(true);
};

EZModelPath.prototype.calculateParallelSides = function () {
    const {cleanDegrees} = EZModelUtils;

    const parallelSides = [];
    const parallelConcaves = [];
    const angleBias = ez3dScene.layoutRules.scenePreferences.angleBias;

    this.edgeAzimuths
        .forEach((angle, index) => {
            const concave = this.concaveAngles.includes(index);
            let opposite = (angle < 0) ? (angle + 180) : (angle - 180);
            opposite = cleanDegrees(opposite);
            for (var i = index + 1; i < this.edgeAzimuths.length; i++) {
                let delta = cleanDegrees(this.edgeAzimuths[i]);
                delta = Math.abs(opposite - delta);
                if (delta < angleBias) {
                    if (concave || this.concaveAngles.includes(i)) {
                        parallelConcaves.push([index, i]);
                    } else {
                        parallelSides.push([index, i]);
                    }
                }
            }
        });
    this.trapezoid = (parallelSides.length > 0);
    this.parallelSides = parallelSides;
    this.parallelConcaves = parallelConcaves;
    return parallelSides;
};

/**
 * Method for calculate the edge dimensions of the cartesian path.
 *
 * @param  {bool}  isFlatten  - use flatten coords instead of the cartesian ones?
 *
 * @return {array}  Array containing the cartesian edge dimensions.
 */
EZModelPath.prototype.calculateEdgeDimensions = function(isFlatten) {
    const {pairWithNext} = EZModelUtils;
    const edgeDimensions = [];
    const edgeCenters = [];
    // @todo clean vertices whose vectorDimension is lower than the dimensionBias
    // const vertices = [];
    // const dimensionBias = ez3dScene.layoutRules.scenePreferences.collisionBias;

    if (this.vertices.length > 1) {
        this.vertices
            .map(pairWithNext)
            .forEach(([vertex, next]) => {
                const vector = {
                    x: (isFlatten)
                        ? next.xF - vertex.xF
                        : next.x - vertex.x,
                    y: (isFlatten)
                        ? next.yF - vertex.yF
                        : next.y - vertex.y
                };
                const vectorDimension = Math.sqrt(
                    Math.pow(vector.x, 2) + Math.pow(vector.y, 2));
                // if (vectorDimension >= dimensionBias) {
                edgeDimensions.push(vectorDimension);
                const edgeCenter = {
                    x: (isFlatten)
                        ? (next.xF + vertex.xF) / 2
                        : (next.x + vertex.x) / 2,
                    y: (isFlatten)
                        ? (next.yF + vertex.yF) / 2
                        : (next.y + vertex.y) / 2
                };
                edgeCenters.push(edgeCenter);
                // vertices.push(vertex);
                // }
            });
        // this.vertices = vertices;
    }
    if (isFlatten) {
        this.edgeDimensionsFlatten = edgeDimensions;
        this.edgeCentersFlatten = edgeCenters;
    } else {
        this.edgeDimensions = edgeDimensions;
        this.edgeCenters = edgeCenters;
    }
    return edgeDimensions;
};

/**
 * Method for calculate the edge dimensions of the flatten path.
 *
 * @return {array}  Array containing the flatten edge dimensions.
 */
EZModelPath.prototype.calculateEdgeDimensionsFlatten = function() {
    return this.calculateEdgeDimensions(true);
};

/**
 * Method for calculate the polygon line data of the cartesian path.
 *
 * @param  {bool}  isFlatten  - use flatten coords instead of the cartesian ones?
 *
 * @return {object} JSON containing the cartesian polygon data.
 */
EZModelPath.prototype.calculateLineData = function(isFlatten) {
    let lineData = _.clone(this.vertices);
    lineData.push(lineData[0]);

    var lineFunction = d3.line()
        .x(function(d) {
            return (isFlatten) ? d.xF : d.x;
        })
        .y(function(d) {
            return (isFlatten) ? d.yF : d.y;
        });
    lineData = lineFunction(lineData);

    if (isFlatten) {
        this.lineDataFlatten = lineData;
    } else {
        this.lineData = lineData;
    }
    return lineData;
};

/**
 * Method for calculate the polygon line data of the flatten path.
 *
 * @return {object} JSON containing the flatten polygon data.
 */
EZModelPath.prototype.calculateLineDataFlatten = function() {
    return this.calculateLineData(true);
};

/**
 * Method to calculate to the orientation of the path's vertices.
 *
 * @return {boolean}    Are the vertices of the path clockwise oriented?
 */
EZModelPath.prototype.calculateOrientation = function() {
    var vertices = this.formatVertices();
    this.clockwiseRotation = ClipperLib.Clipper.Orientation(vertices);
    return this.clockwiseRotation;
};

/**
 * Method to calculate the center of the cartesian path.
 *
 * @return {EZModelLocation}    Center of the path.
 */
EZModelPath.prototype.calculatePathCenter = function() {
    const center = d3.geoCentroid(this.getGeoJSON());
    if (this.center instanceof EZModelLocation) {
        if (!this.center.id) {
            this.center.id = uuid.v4();
        }
        this.center.lng = center[0];
        this.center.lat = center[1];
    } else {
        this.center = new EZModelLocation(ez3dScene, center[0], center[1]);
    }

    if (this.id && !this.center.parentId) {
        this.center.setCoordSystem('scene', this, 'EZModelPath');
    }

    return this.center.updateCartesianCoords();
};

/**
 * Method to calculate the orientation of each wall of the path.
 */
EZModelPath.prototype.calculateWallFacing = function() {
    this.wallFacing = [];
    this.edgeAzimuths.forEach((edgeAzimuth) => {
        var cardinal = ez3dScene.utils.getCardinalPoints(edgeAzimuth, true);
        this.wallFacing.push(cardinal);
    });
};

/**
 * Method to calculate the cartesian (and flatten) vertices of the path.
 *
 * @param  {object}     origin      - origin of the cartesian scene coords.
 * @param  {array}      vertices    - list of vertices of the path.
 * @param  {boolean}    flatten     - are we working in the flatten space?
 *
 * @param  {EZModelPath|array}  [collision] - polygon to calculate the intersection.
 *
 * @return {array}  Array of {EZModelPath}s with the new vertices.
 */
EZModelPath.prototype.calculateVertices = function(
    origin, vertices, flatten, collision) {
    const offsettedPaths = [];
    vertices.forEach((offsettedPath) => {
        if (collision) {
            let intersectedPath = ez3dScene.utils
                .intersectPaths(offsettedPath, collision, flatten, false)[0];
            if (intersectedPath === undefined) {
                console.error('EZModelPath', 'calculateVertices', 'undefined offseted');
                intersectedPath = offsettedPath;
            }
            offsettedPath = intersectedPath;
        }
        const sphericalVertices = [];
        const offsetedLocations = [];
        const cartesianVertices = [];
        offsettedPath.forEach((vertex, index) => {
            // change flatten vertices to ortogonal
            if (flatten) {
                vertex = this.unFlattenPoint(vertex);
            }

            // change vertices to cartesian scene coords
            cartesianVertices.push({
                x: vertex.x + origin.x,
                y: vertex.y + origin.y
            });

            // change vertices from cartesian to spherical coords
            sphericalVertices.push(ez3dScene.utils.convertCartesianToSpherical(
                cartesianVertices[index], ez3dScene.projectCenter));

            // create collision locations
            offsetedLocations.push(new EZModelLocation(ez3dScene,
                sphericalVertices[index].lng,
                sphericalVertices[index].lat
            ));

            offsetedLocations[index] = ez3dScene.utils.fixCartesianToSphericalPrecision(
                offsetedLocations[index], cartesianVertices[index]);

            offsetedLocations[index].updateCartesianCoords();

            if (flatten) {
                offsetedLocations[offsetedLocations.length - 1].xF = vertex.xF;
                offsetedLocations[offsetedLocations.length - 1].yF = vertex.yF;
            }
        });
        offsettedPath = new EZModelPath(ez3dScene, offsetedLocations);
        offsettedPaths.push(offsettedPath);
    });
    return offsettedPaths;
};

EZModelPath.prototype.isInLimits = function(limits, flatten) {
    var shape = this;
    if (this.coordSystem === 'building' && !flatten) {
        shape = this.getSceneCoords();
    }
    var limitsPath = [
        {x: limits[0], y: limits[1]},
        {x: limits[2], y: limits[1]},
        {x: limits[2], y: limits[3]},
        {x: limits[0], y: limits[3]},
        {x: limits[0], y: limits[1]}
    ];
    return (ez3dScene.utils.intersectPaths(shape, limitsPath, flatten).length > 0) || (ez3dScene.utils.intersectPaths(limitsPath, shape, flatten).length > 0);
};

// BUILDING SHAPES
/**
 * Method to calculate cartesian vertices in Step by step custom shape.
 *
 * @return {cartesianVertices}  - Cartesian vertices
 */
EZModelPath.prototype.calculateStepByStepCartesianVertices = function() {
    const self = this;
    const steps = this.customShapeSteps;
    const cartesianVertices = [{x: 0, y: 0}];
    let azimuthCounter = 0;
    // keep edgeAzimuths updated
    self.updateCalculations();
    for (var i = 0; i < steps.length; i++) {
        azimuthCounter += steps[i][1];
        const previousCartesian = {
            x: i === 0 ? 0 : cartesianVertices[i].x,
            y: i === 0 ? 0 : cartesianVertices[i].y
        };
        console.log('index', i);
        console.log('previousCartesian', previousCartesian.x, previousCartesian.y);
        cartesianVertices.push({
            x: previousCartesian.x + steps[i][0] * Math.cos(ez3dScene.utils.degToRad(azimuthCounter)),
            y: previousCartesian.y + steps[i][0] * Math.sin(ez3dScene.utils.degToRad(azimuthCounter))
        });
    }
    return cartesianVertices;
};

/**
 * Method to convert cartesian vertices in locations.
 *
 * @param {object} cartesianVertices     - Cartesian vertices.
 */
EZModelPath.prototype.calculateCustomShapeLocations = function(cartesianVertices) {
    const self = this;
    self.vertices = [];
    cartesianVertices.forEach(function(vertex) {
        const pointPosition = ez3dScene.utils.convertCartesianToSpherical({x: vertex.x, y: vertex.y}, ez3dScene.projectCenter);
        ez3dScene.addLocation([{lat: pointPosition.lat, lng: pointPosition.lng}, undefined, false, {x: vertex.x, y: vertex.y}, false, self]);
    });
};

// GET
EZModelPath.prototype.getDefaultPath = function() {
    this.closed = false;
    this.clockwiseRotation = false;
    this.concaveAngles = [];
    this.convex = true;
    this.cornerAngles = [];
    this.edgeAzimuths = [];
    this.edgeDimensions = [];
    this.parallelConcaves = [];
    this.parallelSides = [];
    this.regularAngles = false;
    this.trapezoid = false;
    this.wallFacing = [];
};

/**
 * Method to get the GeoJSON of the path.
 *
 * @return {GeoJSON} - The GeoJSON of the path.
 */
EZModelPath.prototype.getGeoJSON = function() {
    var coordinates = [];
    this.vertices.forEach((location) => {
        coordinates.push([location.lng, location.lat]);
    });
    if (this.closed === true) {
        coordinates.push([this.vertices[0].lng, this.vertices[0].lat]);
        coordinates = [coordinates];
    }

    var geoJSON = {
        type: 'Feature',
        geometry: {
            type: this.closed ? 'Polygon' : 'LineString',
            coordinates: coordinates
        },
        properties: {
            name: this.id
        }
    };
    return geoJSON;
};

/**
 * Rotate cartesian vertices by a given angle number.
 *
 * @param {array} cartesianVertices     - Array of objects with the vertices (x, y).
 * @param {number} angle                - Rotation angle.
 * @return {array} Rotated vertices.
 */
EZModelPath.prototype.rotateCartesianVertices = function(cartesianVertices, angle) {
    angle = ez3dScene.utils.degToRad(angle);
    const rotatedVertices = [];
    cartesianVertices.forEach(function(vertex) {
        rotatedVertices.push(ez3dScene.utils.changeAxis(vertex, angle));
    });
    return rotatedVertices;
};

/**
 * Calculate the cartesian vertices of the rectangular building.
 *
 * @return {array}  Array of objects with the new vertices (x, y).
 */
EZModelPath.prototype.calculateRectangularCartesianVertices = function() {
    const shapeValues = this.buildingShapeValues[this.buildingShapeType];
    const cartesianVertices = [
        {x: 0, y: 0},
        {x: 0, y: -shapeValues.mainVertical},
        {x: shapeValues.mainHorizontal, y: -shapeValues.mainVertical},
        {x: shapeValues.mainHorizontal, y: 0},
    ];
    return cartesianVertices;
};

/**
 * Method to get a polygon form the object.
 * @param {boolean}     flatten      - are we working in the flatten space?
 */
EZModelPath.prototype.getPolygon = function(flatten) {
    var polygon = [];
    if (flatten) {
        var x;
        var y;
        this.vertices.forEach((vertex) => {
            x = vertex.xF || vertex.x;
            y = vertex.yF || vertex.y;
            polygon.push([
                x,
                y
            ]);
        });
    } else {
        this.vertices.forEach((vertex) => {
            polygon.push([
                vertex.x,
                vertex.y
            ]);
        });
    }
};

/**
 * Recalculate L building shape values.
 *
 * @param {array} modifiedAttr      - [modified attr name, old value].
 */
EZModelPath.prototype.calculateLShapeBuildingValues = function(modifiedAttr) {
    if (!modifiedAttr) return;
    const shapeValues = this.buildingShapeValues.lShape;
    const deltaValue = shapeValues[modifiedAttr[0]] - modifiedAttr[1];

    switch (modifiedAttr[0]) {
        // Vertical values
        case 'mainVertical':
            if (shapeValues.secondaryVertical1Locked) {
                shapeValues.secondaryVertical0 += deltaValue;
            } else if (shapeValues.secondaryVertical0Locked) {
                shapeValues.secondaryVertical1 += deltaValue;
            } else {
                let offset = 0;
                shapeValues.secondaryVertical0 += deltaValue / 2;
                if (shapeValues.secondaryVertical0 < 0.1) {
                    offset = 0.1 - shapeValues.secondaryVertical0;
                    shapeValues.secondaryVertical0 = 0.1;
                }
                shapeValues.secondaryVertical1 += deltaValue / 2 - offset;
            }
            break;
        case 'secondaryVertical0':
            if (shapeValues.mainVerticalLocked) {
                shapeValues.secondaryVertical1 -= deltaValue;
            } else {
                shapeValues.mainVertical += deltaValue;
            }
            break;
        case 'secondaryVertical1':
            if (shapeValues.mainVerticalLocked) {
                shapeValues.secondaryVertical0 -= deltaValue;
            } else {
                shapeValues.mainVertical += deltaValue;
            }
            break;
        // Horizontal values
        case 'mainHorizontal':
            if (shapeValues.secondaryHorizontal1Locked) {
                shapeValues.secondaryHorizontal0 += deltaValue;
            } else if (shapeValues.secondaryHorizontal0Locked) {
                shapeValues.secondaryHorizontal1 += deltaValue;
            } else {
                let offset = 0;
                shapeValues.secondaryHorizontal0 += deltaValue / 2;
                if (shapeValues.secondaryHorizontal0 < 0.1) {
                    offset = 0.1 - shapeValues.secondaryHorizontal0;
                    shapeValues.secondaryHorizontal0 = 0.1;
                }
                shapeValues.secondaryHorizontal1 += deltaValue / 2 - offset;
            }
            break;
        case 'secondaryHorizontal0':
            if (shapeValues.mainHorizontalLocked) {
                shapeValues.secondaryHorizontal1 -= deltaValue;
            } else {
                shapeValues.mainHorizontal += deltaValue;
            }
            break;
        case 'secondaryHorizontal1':
            if (shapeValues.mainHorizontalLocked) {
                shapeValues.secondaryHorizontal0 -= deltaValue;
            } else {
                shapeValues.mainHorizontal += deltaValue;
            }
            break;
        // Locked vertical values
        case 'mainVerticalLocked':
            if (shapeValues.mainVerticalLocked) {
                shapeValues.secondaryVertical0Locked = false;
                shapeValues.secondaryVertical1Locked = false;
                shapeValues.secondaryVertical0Max = shapeValues.mainVertical - shapeValues.secondaryVertical1Min;
                shapeValues.secondaryVertical1Max = shapeValues.mainVertical - shapeValues.secondaryVertical0Min;
            } else {
                delete shapeValues.secondaryVertical0Max;
                delete shapeValues.secondaryVertical1Max;
            }
            break;
        case 'secondaryVertical0Locked':
            if (shapeValues.secondaryVertical0Locked) {
                shapeValues.mainVerticalLocked = false;
                shapeValues.secondaryVertical1Locked = false;
                delete shapeValues.mainVerticalMax;
                shapeValues.mainVerticalMin = shapeValues.secondaryVertical0 + shapeValues.secondaryVertical1Min;
                delete shapeValues.secondaryVertical1Max;
            } else {
                shapeValues.mainVerticalMin = 0.2;
            }
            break;
        case 'secondaryVertical1Locked':
            if (shapeValues.secondaryVertical1Locked) {
                shapeValues.mainVerticalLocked = false;
                shapeValues.secondaryVertical0Locked = false;
                delete shapeValues.mainVerticalMax;
                shapeValues.mainVerticalMin = shapeValues.secondaryVertical1 + shapeValues.secondaryVertical0Min;
                delete shapeValues.secondaryVertical0Max;
            } else {
                shapeValues.mainVerticalMin = 0.2;
            }
            break;
        // Locked horizontal values
        case 'mainHorizontalLocked':
            if (shapeValues.mainHorizontalLocked) {
                shapeValues.secondaryHorizontal1Locked = false;
                shapeValues.secondaryHorizontal0Locked = false;
                shapeValues.secondaryHorizontal0Max = shapeValues.mainHorizontal - shapeValues.secondaryHorizontal1Min;
                shapeValues.secondaryHorizontal1Max = shapeValues.mainHorizontal - shapeValues.secondaryHorizontal0Min;
            } else {
                delete shapeValues.secondaryHorizontal0Max;
                delete shapeValues.secondaryHorizontal1Max;
            }
            break;
        case 'secondaryHorizontal0Locked':
            if (shapeValues.secondaryHorizontal0Locked) {
                shapeValues.mainHorizontalLocked = false;
                shapeValues.secondaryHorizontal1Locked = false;
                delete shapeValues.mainHorizontalMax;
                shapeValues.mainHorizontalMin = shapeValues.secondaryHorizontal0 + shapeValues.secondaryHorizontal1Min;
                delete shapeValues.secondaryHorizontal1Max;
            } else {
                shapeValues.mainHorizontalMin = 0.2;
            }
            break;
        case 'secondaryHorizontal1Locked':
            if (shapeValues.secondaryHorizontal1Locked) {
                shapeValues.mainHorizontalLocked = false;
                shapeValues.secondaryHorizontal0Locked = false;
                delete shapeValues.mainHorizontalMax;
                shapeValues.mainHorizontalMin = shapeValues.secondaryHorizontal1 + shapeValues.secondaryHorizontal0Min;
                delete shapeValues.secondaryHorizontal0Max;
            } else {
                shapeValues.mainHorizontalMin = 0.2;
            }
            break;
    }
};

/**
 * Method to get the path's children.
 *
 * @param  {boolean} [returnObject]     - **Optional.** The array should be of objects instead of ids?
 * @param  {boolean} [includeParent]    - **Optional.** The parent should be included in the array?
 *
 * @return {array} - Array of the path's children.
 */
EZModelPath.prototype.getChildren = function (returnObject, includeParent) {
    const children = new Set();
    if (includeParent) {
        children.add((returnObject) ? this : this.id);
    }
    children.add((returnObject) ? this.center : this.center.id);
    this.vertices.forEach((vertex) => {
        children.add((returnObject) ? vertex : vertex.id);
    });
    return children;
};

/**
 * Calculate the cartesian vertices of the L shaped building.
 *
 * @return {array}  Array of objects with the new vertices (x, y).
 */
EZModelPath.prototype.calculateLShapeCartesianVertices = function() {
    const shapeValues = this.buildingShapeValues[this.buildingShapeType];
    const cartesianVertices = [
        {x: 0, y: 0},
        {x: 0, y: -shapeValues.mainVertical},
        {x: shapeValues.secondaryHorizontal0, y: -shapeValues.mainVertical},
        {x: shapeValues.secondaryHorizontal0, y: -shapeValues.secondaryVertical0},
        {x: shapeValues.mainHorizontal, y: -shapeValues.secondaryVertical0},
        {x: shapeValues.mainHorizontal, y: 0},
    ];
    return cartesianVertices;
};

/**
 * Method to calculate the offsetted paths (the resulting path after applying a given offset).
 *
 * @param  {float}      offset      - offset value that is going to be used.
 * @param  {boolean}    flatten     - are we working in the flatten space?
 * @param  {object}     center      - origin of the coord system (building center).
 * @param  {boolean}    literal     - should the offset value be used literally?
 *
 * @param  {EZModelPath|array}  [collision] - polygon to calculate the intersection.
 *
 * @return {array}  Array of {@link EZModelPath}s resulting after applying the offset.
 */
EZModelPath.prototype.getOffsetteds = function(offset, flatten, center, literal, collision) {
    var offsetteds = [];
    if (offset === undefined) {
        offset = (this.parent.offset === undefined) ? [0] : this.parent.offset;
    } else {
        offset = (offset instanceof Array) ? offset : [offset];
    }
    offset = (offset.length) ? offset : [0];

    if (offset.length === 1 && offset[0] === 0 && !collision) {
        // Without offset (offsetteds = path)
        offsetteds = [new EZModelPath(ez3dScene, this.cloneVertices())];
    } else if (offset.length === 1) {
        const origin = (center) ? center : this.getAncestor('EZModelBuilding').path.center;
        // With regular offset
        let vertices;
        if (this.parentType === 'EZModelKeepout' || literal) {
            vertices = ez3dScene.utils.offsetPaths(this, offset[0], flatten);
        } else {
            vertices = ez3dScene.utils.offsetPaths(this, -Math.abs(offset[0]), flatten);
        }
        offsetteds = this.calculateVertices(origin, vertices, flatten, collision);
    } else {
        console.error('EZModelPath', 'getOffsetteds', 'WIP multiple offset');
        offsetteds = [new EZModelPath(ez3dScene, this.cloneVertices())];
    }
    offsetteds.forEach((offsetted) => {
        offsetted.setCoordSystem(this.coordSystem, this, 'EZModelPath');
        // offsetted.calculatePathCenter();
    });
    this.offsettedPaths = offsetteds;
    return offsetteds;
};

/**
 * Method to get the path's vertices in scene coordinates.
 *
 * @return {array}  Array of vertices (points) in scene coords.
 */
EZModelPath.prototype.getSceneCoords = function() {
    var vertices = [];
    var center = undefined;
    switch (this.parent.constructor.name) {
        case 'EZModelBuilding':
            center = {
                x: this.center.x,
                y: this.center.y};
            break;
        case 'EZModelPath':
            // used in offseted subarea validations... ??? revisar
            center = {
                x: this.parent.parent.path.center.x,
                y: this.parent.parent.path.center.y};
            break;
        case 'EZModelKeepout':
            center = {
                x: this.parent.parent.path.center.x,
                y: this.parent.parent.path.center.y};
            break;
        case 'EZModelRoof':
            center = {
                x: this.parent.parent.path.center.x,
                y: this.parent.parent.path.center.y};
            break;
        case 'EZModelArea':
            center = {
                x: this.parent.parent.parent.path.center.x,
                y: this.parent.parent.parent.path.center.y};
            break;
        case 'EZModelSubarea':
            center = {
                x: this.parent.parent.parent.parent.path.center.x,
                y: this.parent.parent.parent.parent.path.center.y};
            break;
        default:
            break;
    }
    if (center) {
        for (var i = 0; i < this.vertices.length; i++) {
            vertices.push({
                x: this.vertices[i].x + center.x,
                y: this.vertices[i].y + center.y
            });
        }
    } else {
        console.error('EZModelPath', 'getSceneCoords', 'Unvalid EZModelBuilding');
    }
    return vertices;
};

/**
 * Method to get the surface of the path in square meters.
 * @param {boolean}     flatten      - are we working in the flatten space?
 *
 * @return {float}  The surface of the path in square meters.
 */
EZModelPath.prototype.getSurface = function(flatten) {
    return ClipperLib.Clipper.Area(this.formatVertices(flatten));
};

// SET
EZModelPath.prototype.setActiveVertex = function(index) {
    for (var i = 0; i < this.vertices.length; i++) {
        this.vertices[i].active = false;
    }
    if (index === -1) {
        index = 0;
    }
    this.vertices[index].active = true;
};

/**
 * Method to set the path's id, coordinate system and parent pointer.
 *
 * @param {string}  coordSystem - The path's coordinate system.
 * @param {object}  parent      - The parent of the path.
 * @param {string}  parentType  - The class of the parent of the path.
 * @param {object}  data        - JSON with the data of the path.
 * @param {bool}    cropping    - should the path be cropped in the process?.
 */
EZModelPath.prototype.setCoordSystem = function (coordSystem, parent, parentType, data, cropping) {
    // Set id
    if (!this.id) {
        if (data) {
            this.id = (data.id) ? data.id : uuid.v4();
        } else {
            this.id = uuid.v4();
        }
    }
    // Set coordSystem
    if (coordSystem) {
        this.coordSystem = coordSystem;
    }
    // Set parent
    if (parent && typeof parent === 'string') {
        this.parentId = parent;
        this.parent = ez3dScene.findById(parent);
    } else if (parent) {
        this.parent = parent;
        this.parentId = parent.id;
    }
    if (parentType) {
        this.parentType = parentType;
    }
    if (this.vertices.length > 0) {
        // Update childrens
        this.vertices.forEach((location) => {
            location.setCoordSystem(this.coordSystem, this, 'EZModelPath');
        });
        // Cropping
        if (cropping) {
            this.cropPath();
        }
        // Center
        this.calculatePathCenter();
        // Update
        this.updateCartesianCoords();
        this.updateCalculations();
        // LineData
        this.calculateLineData();
        this.calculateLineDataFlatten();
    }
};

// UPDATE
/**
 * Method to update all the cartesian properties of the path.
 *
 * @return {EZModelPath}    The path after being updated.
 */
EZModelPath.prototype.updateCalculations = function() {
    // console.log('updateCalc ' + this.id);
    this.calculateEdgeDimensions();
    this.updateIndexes();
    if (this.vertices.length > 0) {
        this.calculatePathCenter(true);
    }
    this.calculateCornersAngles();
    this.calculateEdgeAzimuths();
    this.calculateWallFacing();
    this.calculateOrientation();
    return this;
};

/**
 * Method to update the cartesian coords of all the children.
 */
EZModelPath.prototype.updateCartesianCoords = function() {
    this.center.updateCartesianCoords();
    this.vertices.forEach((location) => {
        location.parent = this;
        location.updateCartesianCoords();
    });
    // LineData
    this.calculateLineData();
};

/**
 * Method to update all the flatten properties of the path.
 */
EZModelPath.prototype.updateFlattenCartesian = function() {
    this.calculateLineDataFlatten();
    this.calculateEdgeDimensionsFlatten();
    this.calculateEdgeAzimuthsFlatten();

    // Update offsettedPaths
    if (this.offsettedPaths) {
        this.offsettedPaths.forEach((offseted) => {
            offseted.updateFlattenCartesian();
        });
    }
};

EZModelPath.prototype.updateIndexes = function() {
    for (var i = 0; i < this.vertices.length; i++) {
        this.vertices[i].index = i;
    }
};

/**
 * Method to recalculate C building shape values.
 *
 * @param {array} modifiedAttr      - [modified attr name, old value].
 */
EZModelPath.prototype.calculateCShapeBuildingValues = function(modifiedAttr) {
    if (!modifiedAttr) return;
    const shapeValues = this.buildingShapeValues.cShape;
    const deltaValue = shapeValues[modifiedAttr[0]] - modifiedAttr[1];

    switch (modifiedAttr[0]) {
        // Vertical values
        case 'mainVertical':
            if (shapeValues.secondaryVertical0Locked) {
                if ((shapeValues.secondaryVertical1 + deltaValue) >= shapeValues.secondaryVertical1Min) {
                    shapeValues.secondaryVertical1 += deltaValue;
                } else {
                    shapeValues.mainVertical = shapeValues.mainVerticalMin;
                    shapeValues.secondaryVertical1 = shapeValues.secondaryVertical1Min;
                }
            } else if (shapeValues.secondaryVertical1Locked) {
                shapeValues.secondaryVertical0 += deltaValue / 2;
            } else {
                const quantity = deltaValue / 3;
                shapeValues.secondaryVertical0 += quantity;
                shapeValues.secondaryVertical1 += quantity;
            }
            break;
        case 'secondaryVertical0':
            if (shapeValues.mainVerticalLocked) {
                shapeValues.secondaryVertical1 -= (deltaValue * 2);
            } else {
                shapeValues.mainVertical += (deltaValue * 2);
            }
            break;
        case 'secondaryVertical1':
            if (shapeValues.mainVerticalLocked) {
                shapeValues.secondaryVertical0 -= (deltaValue / 2);
            } else {
                shapeValues.mainVertical += deltaValue;
            }
            break;
        // Horizontal values
        case 'mainHorizontal':
            if (!shapeValues.secondaryHorizontal0Locked) {
                shapeValues.secondaryHorizontal0 += deltaValue / 2;
                if (shapeValues.secondaryHorizontal0 < 0.1) {
                    shapeValues.secondaryHorizontal0 = 0.1;
                }
            }
            break;
        case 'secondaryHorizontal0':
            if (!shapeValues.mainHorizontalLocked) {
                shapeValues.mainHorizontal += deltaValue;
            }
            break;
        // Locked vertical values
        case 'mainVerticalLocked':
            if (shapeValues.mainVerticalLocked) {
                shapeValues.secondaryVertical0Locked = false;
                shapeValues.secondaryVertical0Max = (shapeValues.mainVertical - shapeValues.secondaryVertical1Min) / 2;
                shapeValues.secondaryVertical1Locked = false;
                shapeValues.secondaryVertical1Max = shapeValues.mainVertical - (shapeValues.secondaryVertical0Min * 2);
            } else {
                delete shapeValues.secondaryVertical0Max;
                delete shapeValues.secondaryVertical1Max;
            }
            break;
        case 'secondaryVertical0Locked':
            if (shapeValues.secondaryVertical0Locked) {
                shapeValues.mainVerticalLocked = false;
                shapeValues.mainVerticalMin = shapeValues.secondaryVertical0 + shapeValues.secondaryVertical1Min;
                delete shapeValues.mainVerticalMax;
                shapeValues.secondaryVertical1Locked = false;
                delete shapeValues.secondaryVertical1Max;
            } else {
                shapeValues.mainVerticalMin = 0.2;
            }
            break;
        case 'secondaryVertical1Locked':
            if (shapeValues.secondaryVertical1Locked) {
                shapeValues.mainVerticalLocked = false;
                delete shapeValues.mainVerticalMax;
                shapeValues.mainVerticalMin = shapeValues.secondaryVertical1 + shapeValues.secondaryVertical0Min;
                shapeValues.secondaryVertical0Locked = false;
                delete shapeValues.secondaryVertical0Max;
            } else {
                shapeValues.mainVerticalMin = 0.2;
            }
            break;
        // Locked horizontal values
        case 'mainHorizontalLocked':
            if (shapeValues.mainHorizontalLocked) {
                shapeValues.secondaryHorizontal0Locked = false;
                shapeValues.secondaryHorizontal0Max = shapeValues.mainHorizontal - shapeValues.secondaryHorizontal0Min;
            } else {
                delete shapeValues.secondaryHorizontal0Max;
            }
            break;
        case 'secondaryHorizontal0Locked':
            if (shapeValues.secondaryHorizontal0Locked) {
                shapeValues.mainHorizontalLocked = false;
                delete shapeValues.mainHorizontalMax;
                shapeValues.mainHorizontalMin = shapeValues.secondaryHorizontal0 + shapeValues.secondaryHorizontal0Min;
            } else {
                shapeValues.mainHorizontalMin = 0.2;
            }
            break;
    }
};

/**
 * Calculate the cartesian vertices of the C shaped building.
 *
 * @return {array}  Array of objects with the new vertices (x, y).
 */
EZModelPath.prototype.calculateCShapeCartesianVertices = function() {
    const shapeValues = this.buildingShapeValues[this.buildingShapeType];
    const cartesianVertices = [
        {x: 0, y: 0},
        {x: 0, y: -shapeValues.mainVertical},
        {x: shapeValues.mainHorizontal, y: -shapeValues.mainVertical},
        {x: shapeValues.mainHorizontal, y: -(shapeValues.mainVertical - shapeValues.secondaryVertical0)},
        {x: shapeValues.mainHorizontal - shapeValues.secondaryHorizontal0, y: -(shapeValues.mainVertical - shapeValues.secondaryVertical0)},
        {x: shapeValues.mainHorizontal - shapeValues.secondaryHorizontal0, y: -shapeValues.secondaryVertical0},
        {x: shapeValues.mainHorizontal, y: -shapeValues.secondaryVertical0},
        {x: shapeValues.mainHorizontal, y: 0},
    ];
    return cartesianVertices;
};

/**
 * Recalculate O building shape values.
 *
 * @param {array} modifiedAttr      - [modified attr name, old value].
 */
EZModelPath.prototype.calculateOShapeBuildingValues = function(modifiedAttr) {
    if (!modifiedAttr) return;
    const shapeValues = this.buildingShapeValues.oShape;
    const deltaValue = shapeValues[modifiedAttr[0]] - modifiedAttr[1];

    switch (modifiedAttr[0]) {
        // Vertical values
        case 'mainVertical':
            if (!shapeValues.secondaryVertical0Locked) {
                shapeValues.secondaryVertical0 += deltaValue / 2;
                if (shapeValues.secondaryVertical0 < 0.1) {
                    shapeValues.secondaryVertical0 = 0.1;
                }
            }
            break;
        case 'secondaryVertical0':
            if (!shapeValues.mainVerticalLocked) {
                shapeValues.mainVertical += deltaValue;
            }
            break;
        // Horizontal values
        case 'mainHorizontal':
            if (!shapeValues.secondaryHorizontal0Locked) {
                shapeValues.secondaryHorizontal0 += deltaValue / 2;
                if (shapeValues.secondaryHorizontal0 < 0.1) {
                    shapeValues.secondaryHorizontal0 = 0.1;
                }
            }
            break;
        case 'secondaryHorizontal0':
            if (!shapeValues.mainHorizontalLocked) {
                shapeValues.mainHorizontal += deltaValue;
            }
            break;
        // Locked vertical values
        case 'mainVerticalLocked':
            if (shapeValues.mainVerticalLocked) {
                shapeValues.secondaryVertical0Locked = false;
                shapeValues.secondaryVertical0Max = shapeValues.mainVertical - shapeValues.secondaryVertical0Min;
            } else {
                delete shapeValues.secondaryVertical0Max;
            }
            break;
        case 'secondaryVertical0Locked':
            if (shapeValues.secondaryVertical0Locked) {
                shapeValues.mainVerticalLocked = false;
                delete shapeValues.mainVerticalMax;
                shapeValues.mainVerticalMin = shapeValues.secondaryVertical0 + shapeValues.secondaryVertical0Min;
            } else {
                shapeValues.mainVerticalMin = 0.2;
            }
            break;
        // Locked horizontal values
        case 'mainHorizontalLocked':
            if (shapeValues.mainHorizontalLocked) {
                shapeValues.secondaryHorizontal0Locked = false;
                shapeValues.secondaryHorizontal0Max = shapeValues.mainHorizontal - shapeValues.secondaryHorizontal0Min;
            } else {
                delete shapeValues.secondaryHorizontal0Max;
            }
            break;
        case 'secondaryHorizontal0Locked':
            if (shapeValues.secondaryHorizontal0Locked) {
                shapeValues.mainHorizontalLocked = false;
                delete shapeValues.mainHorizontalMax;
                shapeValues.mainHorizontalMin = shapeValues.secondaryHorizontal0 + shapeValues.secondaryHorizontal0Min;
            } else {
                shapeValues.mainHorizontalMin = 0.2;
            }
            break;
    }
};

/**
 * Calculate the cartesian vertices of the O shaped building.
 *
 * @return {array}  Array of objects with the new vertices (x, y).
 */
EZModelPath.prototype.calculateOShapeBuildingCartesianVertices = function() {
    const shapeValues = this.buildingShapeValues[this.buildingShapeType];
    const cartesianVertices = [
        {x: 0, y: 0},
        {x: 0, y: -shapeValues.mainVertical},
        {x: shapeValues.mainHorizontal, y: -shapeValues.mainVertical},
        {x: shapeValues.mainHorizontal, y: 0},
    ];
    return cartesianVertices;
};

/**
 * Calculate the cartesian vertices of the O shaped building keepout.
 *
 * @return {array}  Array of objects with the new vertices (x, y).
 */
EZModelPath.prototype.calculateOShapeKeepoutCartesianVertices = function() {
    const shapeValues = ez3dScene.active.customPath.buildingShapeValues[ez3dScene.active.customPath.buildingShapeType];

    // Get movement offset to center keepout
    const horOffset = (shapeValues.mainHorizontal - shapeValues.secondaryHorizontal0) / 2;
    const vertOffset = (shapeValues.mainVertical - shapeValues.secondaryVertical0) / 2;

    const cartesianVertices = [
        {x: horOffset, y: -vertOffset},
        {x: horOffset, y: -(shapeValues.secondaryVertical0 + vertOffset)},
        {x: horOffset + shapeValues.secondaryHorizontal0, y: -(vertOffset + shapeValues.secondaryVertical0)},
        {x: horOffset + shapeValues.secondaryHorizontal0, y: -vertOffset},
    ];
    return cartesianVertices;
};

// OTHER
EZModelPath.prototype.calculateEstimatedFourthPoint = function() {
    if (this.vertices.length === 3) {
        this.calculateCornersAngles();
        if (Math.abs(Math.abs(ez3dScene.tempPath.cornerAngles[1]) - 90) <=
            ez3dScene.layoutRules.scenePreferences.angleBias) {
            const a = this.vertices[0];
            const b = this.vertices[1];
            const c = this.vertices[2];

            let vector = [a.x - b.x, a.y - b.y];
            let point = {x: c.x + vector[0], y: c.y + vector[1]};
            let oldPoint = {x: point.x, y: point.y};
            point = ez3dScene.utils.translateSphericalByCartesianOffset(ez3dScene.projectCenter, point);

            ez3dScene.addLocation([point, undefined, true, oldPoint])
                .then(() => {
                    this.estimatedPoint = true;
                    this.vertices[this.vertices.length - 1].estimated = true;
                    this.updateCalculations();
                    // ee.emitEvent('calculateEstimatedFourthPoint_Finished');
                });
        }
    }
};

EZModelPath.prototype.removeEstimatedFourthPoint = function() {
    this.vertices.forEach((loc, i) => {
        if (loc.estimated) {
            this.vertices.splice(i, 1);
        }
    });
    delete this.estimatedPoint;
    this.updateCalculations();
};

/**
 * Method to clone the vertices of the path.
 *
 * @return {array}  Array of {@link EZModelLocation}.
 */
EZModelPath.prototype.cloneVertices = function() {
    const newVertices = [];
    this.vertices.forEach((vertex) => {
        var point = new EZModelLocation(
            ez3dScene,
            vertex.lng,
            vertex.lat
        );
        newVertices.push(point);
        if (vertex.xF !== undefined) {
            point.xF = vertex.xF;
            point.yF = vertex.yF;
        }
    });
    return newVertices;
};

/**
 * Method to force a clockwise order in the path vertices.
 *
 * @param {boolean} fromV2 - Correct vertices order from V2?
 *
 * @return {EZModelPath} The path in clockwise order.
 */
EZModelPath.prototype.forceClockWise = function(fromV2) {
    this.calculateOrientation();
    if (this.clockwiseRotation === false) {
        if (fromV2) {
            this.vertices.push(this.vertices.shift());
            this.vertices.push(this.vertices.shift());
        }
        this.vertices.reverse();
    }
    this.clockwiseRotation = true;
    this.updateCalculations();
    return this;
};

/**
 * Method to get the coords (X,Y) of each vertex of the path.
 *
 * @param {boolean} flatten   - Use the flatten coords (xF & yF)?
 *
 * @return {array} Array with each vertex's coordinates.
 */
EZModelPath.prototype.formatVertices = function(flatten) {
    return ez3dScene.utils.formatVertices(this.vertices, flatten);
};

/**
 * Method to change the coordinates of the vertices of the path.
 *
 * @param {object} offset - {lat: delta degrees, lng: delta degrees}.
 */
EZModelPath.prototype.move = function (offset) {
    this.vertices.forEach((vertex) => {
        vertex.move(offset);
    });
    this.calculatePathCenter();
    this.updateCartesianCoords();
};

/**
 * Method to crop the path using his parent's offsetted path.
 *
 * @param  {object} [path]  - Path to be used in the cropping (instead of the parent's one).
 */
EZModelPath.prototype.cropPath = function(path = this.parent.parent.path) {
    const collisionPaths = (path.offsettedPaths) ? path.offsettedPaths : [path];
    const collisionPath = collisionPaths.find((intersectPath) => {
        const intersectedPath = ez3dScene.utils.intersectPaths(
            this.vertices, intersectPath.vertices, false, false);
        return intersectedPath.length;
    });
    if (collisionPath) {
        const offsettedPaths = this.getOffsetteds(
            // offset, flatten, center, literal, collision
            0, false, false, false, collisionPath.vertices);
        const vertices = offsettedPaths[0].vertices;
        vertices.forEach((location) => {
            location.setCoordSystem(this.coordSystem, this, 'EZModelPath');
            location.updateCartesianCoords();
        });
        if (vertices.length > this.vertices.length) {
            console.error('EZModelPath.cropPath', 'clearPath needed');
        } else {
            this.vertices = vertices;
        }
    }
};

/**
 * Method to check if the path intersect with his parent's offsetted paths.
 *
 * @param  {object} [paths] - Paths to be used in the cropping (instead of the parent's one).
 *
 * @return {integer}    Number of intersections between the paths.
 */
EZModelPath.prototype.testCrop = function(paths) {
    var inside = 0;
    var parentPath = paths;
    if (parentPath === undefined) {
        parentPath = this.parent.parent.path;
        if (parentPath.offsettedPaths) {
            parentPath = parentPath.offsettedPaths;
        }
    }
    if (Array.isArray(parentPath)) {
        parentPath.forEach((path) => {
            inside += ez3dScene.utils.intersectPaths(this.vertices, path.vertices, false, true).length;
        });
    } else {
        inside += ez3dScene.utils.intersectPaths(this.vertices, parentPath.vertices, false, true).length;
    }
    return inside;
};

/**
 * Method to translate and rotate the points before the flatten transformation.
 *
 * @param  {array}  axis        - points to be used as base line.
 * @param  {float}  azimuth     - rotation angle to be used.
 * @param  {object} point       - pivoting point to be used.
 *
 * @return {array} The array with the bounding points.
 */
EZModelPath.prototype.translateFlattenPoints = function(axis, azimuth, point) {
    var left = [];
    var bottom = [];
    var top = [];

    if (axis[1] === undefined || isNaN(azimuth)) {
        console.error('EZModelPath', 'translateFlattenPoints', 'CRITICAL ERROR');
    }
    this.vertices.forEach((location) => {
        location.rotateFlattenPoints(axis[1], azimuth);
        left.push(location.xF); bottom.push(location.yF);
    });

    left = (point) ? point.x : Math.min.apply(null, left);
    bottom = (point) ? point.y : Math.max.apply(null, bottom);

    this.vertices.forEach((location) => {
        location.xF -= left;
        location.yF -= bottom;
        top.push(location.yF);
    });

    top = Math.abs(Math.min.apply(null, top));

    return [left, bottom, top];
};

/**
 * Method to unFlatten (calculate the cartesian) a given (flatten) point
 *
 * @param  {object} point       - point that is going to be unFlatten.
 * @param  {array}  axis        - points to be used as base line.
 * @param  {float}  azimuth     - rotation angle to be used.
 * @param  {float}  inclination - inclinaton angle to be used.
 * @param  {object} origin      - pivoting point to be used.
 *
 * @return {object} Point after the unFlatting process.
 */
EZModelPath.prototype.unFlattenPoint = function(point, axis, azimuth, inclination, origin) {
    var vertex = EZModelUtils.omitDeep(point);
    var area = this.getAncestor('EZModelArea');

    if (!area || area.constructor.name !== 'EZModelArea') {
        console.error('EZModelPath', 'unFlattenPoint',
            'This point (' + this + ') doesn`t have a matching area (' + area + ')');
    }

    axis = (axis === undefined) ? area.axis : axis;
    azimuth = (inclination === undefined) ? area.angle : azimuth;
    inclination = (inclination === undefined) ? area.inclination : inclination;
    origin = (origin === undefined) ? area.flattenOrigin : origin;

    // inclination
    vertex.y *= Math.cos(ez3dScene.utils.degToRad(inclination));

    // translation
    vertex.x += origin.x;
    vertex.y += origin.y;

    // rotation
    var angle = ez3dScene.utils.degToRad(-azimuth);
    var pivot = {x: vertex.x - axis[1].x, y: vertex.y - axis[1].y};
    vertex.x = ((pivot.x * Math.cos(angle) - pivot.y * Math.sin(angle)) + axis[1].x);
    vertex.y = ((pivot.x * Math.sin(angle) + pivot.y * Math.cos(angle)) + axis[1].y);

    // save flatten
    vertex.xF = point.x;
    vertex.yF = point.y;

    return vertex;
};

// GET JSON
/**
 * Method to get the fields about this class.
 * @param  {array} fields - **Required**. The fields to get.
 * @return {object}       - Object with the fields to getted.
 */
EZModelPath.prototype.getJson = function(fields) {
    if (!fields) {
        fields = [
            'id',
            // 'center',
            'vertices'
        ];
    }
    const output = EZModelUtils.getSeed(this, fields, ['parent']);
    output['version'] = ez3dScene.version;
    return output;
};

/**
 * EZModelRoof
 * @class
 * @classdesc This is the **EZModelRoof** class.
 *
 * @description This is the constructor of the **EZModelRoof** class.
 *
 * @param {EZModelScene} ez3dScene  - Main container for 3DLayout project model.
 * @param {EZModelPath} building    - The parent of this roof.
 * @param {object} data             - JSON with the data of the roof.
 * @param {boolean} clone           - Is this roof being created as a clone?
 */
function EZModelRoof(ez3dScene, building, data, clone) {
    // ez3dScene = ez3dScene;
    if (clone || data === undefined) {
        EZModelObject.call(this, null);
    } else {
        EZModelObject.call(this, data.id || null);
    }

    // Main properties
    this.parent = building;
    this.setPath(data.path, building, clone);
    // @deprecated parentId
    this.parentId = this.parent.id;
    this.style = {visible: true, colorState: 'standard'};

    // Load properties
    this.__data = this.loadWatchedProperties(data);
    this.addWatchers();
    this.checkings(data);
    this.loadUnwatchedProperties(data, clone);

    // Custom properties
    if (data.customProperties && data.customProperties.length > 0) {
        this.customProperties = data.customProperties;
        data.customProperties.forEach((property) => {
            this[property] = data[property];
        });
    }

    // Children
    if (data && data.areas && data.areas.length &&
        this.flatReset === undefined) {
        this.createAreas(data.areas, clone);
    } else {
        this.createAreas();
    }

    // Update
    if (this.flatReset) delete this.flatReset;
    if (!ez3dScene.isLoadingProject &&
        this.getAncestor('EZModelBuilding').isCreated) {
        this.updatePath();
    }
}

EZModelRoof.prototype = Object.create(EZModelObject.prototype);

EZModelRoof.prototype.constructor = EZModelRoof;

// GET
EZModelRoof.prototype.loadWatchedProperties = function(data) {
    const properties = {};
    data = (data) ? data : {};

    const loadProperty = (property, type) => {
        if (type) {
            return this.getDefaultValue(property, type, data);
        } else if (this.parent.creatingBuildingShape) {
            return ez3dScene.getModelProperty(
                'defaultModelValues', 'roofShapes', property, data);
        }
        return ez3dScene.getModelProperty(
            'defaultModelValues', 'roof', property, data);
    };

    // getDefault
    properties.type = loadProperty('type');
    properties.type = this.checkRoofType(properties.type);
    this.checkParallelRidge(properties.type);

    properties.disabled = loadProperty('disabled', properties.type);
    properties.elevation = loadProperty('elevation', properties.type);
    properties.inclination = loadProperty('inclination', properties.type);
    properties.roofPointsSymmetry = loadProperty('roofPointsSymmetry', properties.type);
    properties.material = loadProperty('material', properties.type);

    properties.orientation = loadProperty('orientation', properties.type);
    properties.orientation = this.checkInclinationAxis(properties.orientation);

    // generate
    properties.name = this.getName();

    // restrictions
    properties.offset = loadProperty('offset');

    return properties;
};

EZModelRoof.prototype.loadUnwatchedProperties = function(data, clone) {
    this.isCreated = (data && data.isCreated !== undefined)
        ? data.isCreated : true;
    this.isEditing = (data && data.isEditing !== undefined)
        ? data.isEditing : false;

    this.systemInfo = {holders: 0, modules: 0, power: 0};

    if (this.flatReset === undefined) {
        this.generateRoofPoints(data, clone);
    }

    this.areaTypes = (data && data.areaTypes) ? data.areaTypes : [];
    this.baseHeight = (data && data.baseHeight) ? data.baseHeight : [0, 0];
    if (this.baseHeight[0] === undefined) this.baseHeight[0] = 0;

    this.link = (data) ? data.link : undefined;
    if (this.link && this.link.type === 'roof') {
        const linked = this.parent.roofs
            .find((roof) => (roof.id === this.link.id));
        if (linked && linked.geometry && linked.geometry[this.link.face]) {
            linked.geometry[this.link.face].link = this.id;
        }
    }
};

EZModelRoof.prototype.getIndex = function() {
    const building = this.parent;
    const indexInParent = (building && building.roofs)
        ? building.roofs.indexOf(this) : 0;
    return (indexInParent === -1)
        ? building.roofs.length + 1 : indexInParent + 1;
};

EZModelRoof.prototype.getName = function() {
    this.index = this.getIndex();
    return 'Roof ' + this.index;
};

/**
 * Method to get the roof's children.
 *
 * @param  {boolean} returnObject   - **Optional.** The array should be of objects instead of ids?
 * @param  {boolean} includeParent  - **Optional.** The parent should be included in the array?
 *
 * @return {array} - Array of the roof's children.
 */
EZModelRoof.prototype.getChildren = function (returnObject, includeParent) {
    // @TODO replace for a proper import whenever we use ES6+ modules
    const uniteSets = EZModelUtils.uniteSets;

    var children = new Set();

    if (includeParent) {
        children.add((returnObject) ? this : this.id);
    }

    children = uniteSets(children, this.path.getChildren(returnObject, true));

    this.roofPoints.forEach(function (roofPoint) {
        children.add((returnObject) ? roofPoint : roofPoint.id);
    });

    this.areas.forEach(function(area) {
        children = uniteSets(children, area.getChildren(returnObject, true));
    });

    return children;
};

EZModelRoof.prototype.getDefaultValue = function(property, type, data) {
    var value = ez3dScene.getModelProperty('roofByType', type, property, data);
    var holder = {};
    holder[property] = value;

    value = ez3dScene.getModelProperty('defaultModelValues', 'roof', property, holder);
    return value;
};

/**
 * This method gets the list of available types of this roof with attributes.
 *
 * @return {object} An object of the available types of this roof.
 */
EZModelRoof.prototype.getAvailableTypes = function() {
    const defaultAvailableTypes = ez3dScene
        .getModelProperty('defaultModelValues', 'roof', 'availableTypes');

    const fromPairs = (types) => {
        const pairs = types
            .filter((type) => (defaultAvailableTypes.length)
                ? defaultAvailableTypes.includes(type) : true)
            .map((type) => [
                type,
                {value: type, image: type, label: type},
            ]);
        return _.fromPairs(pairs);
    };

    // simple mode
    if (ez3dScene.projectType === 'simple') {
        return fromPairs(['flat', 'pent']);
    }
    // v1.0
    const availableTypes = ['flat'];
    if (this.linkedArea) {
        availableTypes.push('courtyard');
    }
    // Irregular
    if (this.path.convex) {
        // Convex & Irregular
        availableTypes.push('pent', 'pyramid');
    } else {
        availableTypes.push('pitched');
    }
    if (this.path.vertices.length === 4 && this.path.trapezoid) {
        // 4 Sides & Trapezoid
        availableTypes.push('pergola', 'hipped', 'gabled');
    }
    // v2.0
    if (this.path.convex) {
        // Convex & Irregular
        availableTypes.push('mansard');
    } else if (this.path.regularAngles) {
        // Concave & Regular
        // availableTypes.push('cross');
    }
    if (this.path.vertices.length === 4 && this.path.trapezoid) {
        // 4 Sides & Trapezoid
        // availableTypes.push('gambrel');
    }
    return fromPairs(availableTypes);
};

/**
 * This method gets the list of available material of this roof with attributes.
 *
 * @return {object} An object of the available material of this roof.
 */
EZModelRoof.prototype.getAvailableMaterials = function() {
    var defaultAvailableMaterial = this
        .getDefaultValue('availableMaterial', this.type);

    var availableMaterial = {};

    if (Array.isArray(defaultAvailableMaterial)) {
        defaultAvailableMaterial.forEach((material) => {
            if (Array.isArray(material)) {
                availableMaterial[material[0]] = {
                    value: material[1],
                    image: this.formatMaterialsClass(material[2]),
                    label: material[3]
                };
            } else {
                availableMaterial[material] = {
                    value: material,
                    image: this.formatMaterialsClass(material),
                    label: material
                };
            }
        });
    } else {
        Object.keys(defaultAvailableMaterial).forEach((material) => {
            availableMaterial[material] = {
                value: defaultAvailableMaterial[material].value,
                image: this.formatMaterialsClass(defaultAvailableMaterial[material].image),
                label: defaultAvailableMaterial[material].label
            };
        });
    }
    return availableMaterial;
};

// CALCULATE
/**
 * Method to calculate the vertices of the roof's areas
 *
 * @param  {string} orientation - letter indicating the orientation
 * @param  {array} facesSides - list with the number of faces of each area
 *
 * @param  {integer} index - index of this roof area
 * @param  {bool} valid - is this a valid area or just a facade (support)?
 *
 * @return {array} - Array of {@link EZModelLocation} to create the area's path
 */
EZModelRoof.prototype.calculateAreaPath = function(orientation, facesSides, index, valid) {
    const faces = facesSides[index];
    let points = [];

    const axis = orientation;
    let roofIndex = [];

    if (axis === -1) {
    // FLAT ROOFS
        if (this.roofPoints.length > 0) {
            // mansard roof: flat center face
            points = this.roofPoints.map((loc) =>
                ({x: loc.x, y: loc.y, z: loc.z, lng: loc.lng, lat: loc.lat}));
            roofIndex = [...Array(this.roofPoints.length).keys()];
        } else {
            // flat roof
            points = this.path.vertices.map((loc) =>
                ({x: loc.x, y: loc.y, z: loc.z, lng: loc.lng, lat: loc.lat}));
        }
    } else {
    // INCLINED ROOFS
        const next = (axis + 1) % this.path.vertices.length;

        // roofWalls (AKA axis)
        let loc = this.path.vertices[axis];
        points.push({x: loc.x, y: loc.y, z: loc.z, lng: loc.lng, lat: loc.lat});

        loc = this.path.vertices[next];
        points.push({x: loc.x, y: loc.y, z: loc.z, lng: loc.lng, lat: loc.lat});

        // roofPoints
        switch (this.type) {
            default: // falls through
            case 'pyramid':
                roofIndex = [0];
                break;
            case 'pergola':
                roofIndex = [0, 1];
                break;
            case 'pitched': // falls through
            case 'pent':
                if (index === 0) {
                    roofIndex = [...this.roofPoints.keys()];
                } else if (faces === 4) {
                    roofIndex = [index - 1, index - 2];
                } else {
                    roofIndex = (index === 1) ? [0] : [index - 2];
                }
                break;
            case 'gabled': // falls through
            case 'hipped':
                if (index === 0) {
                    roofIndex = [1, 0];
                } else if (index === 1) {
                    roofIndex = [1];
                } else if (index === 2) {
                    roofIndex = [0, 1];
                } else {
                    roofIndex = [0];
                }
                break;
            case 'gambrel': // falls through
            case 'mansard':
                roofIndex = [(index + 1) % this.path.vertices.length, index];
                break;
        }

        // eslint-disable-next-line no-unused-vars
        this.roofPoints.forEach((point, i) => {
            if (i + 2 < faces) {
                loc = this.roofPoints[roofIndex[i]];
                points.push({x: loc.x, y: loc.y, z: loc.z, lng: loc.lng, lat: loc.lat});
            }
        });
    }

    // Formatting output
    let path = [];
    if (valid) {
        points.forEach((point) => {
            const loc = new EZModelLocation(ez3dScene, point.lng, point.lat);
            if (point.z !== undefined) loc.z = point.z;
            path.push(loc);
        });
    } else {
        path = points.map((loc) => ({x: loc.x, y: loc.y, z: loc.z}));
    }
    return {
        vertices: path,
        pointsIndexes: roofIndex,
    };
};

EZModelRoof.prototype.calculateEdgeLines = function(roofPoints) {
    const indexPoints = roofPoints || this.roofPoints;
    if (_.isEmpty(indexPoints)) return [];
    const edgeLines = [];
    this.geometry.forEach((face, index, geometry) => {
        if ((face.type === 'area' && face.inclination) ||
            (face.inclination > 0 && face.inclination < 90)) {
            // const nextFace = geometry[(index + 1) % geometry.length];
            for (let i = 1; i < face.vertices.length - 1; i++) {
                // if (i === 1 && nextFace.inclination === 90) continue;
                const first = (i === 1)
                    ? face.vertices[1]
                    : indexPoints[face.index[i - 2]];
                const second = indexPoints[face.index[i - 1]];
                edgeLines.push({face: index,
                    x1: first.x, y1: first.y,
                    x2: second.x, y2: second.y,
                });
            }
        }
    });
    return edgeLines;
};

EZModelRoof.prototype.calculateGeometry = function(polygon, pointsIndexes) {
    const isArea = (polygon.constructor.name === 'EZModelArea');
    const face = {inclination: 90, type: 'facade'};

    // Adding face vertices (from area or polygon)
    if (isArea) {
        face.inclination = Number(polygon.inclination);
        face.vertices = polygon.path.vertices.map((vertex) =>
            ({x: vertex.x, y: vertex.y, z: vertex.z}));
        if (polygon.ramp) {
            face.type = (face.inclination < 89)
                ? 'ramp' : 'facade';
        } else if (polygon.linkedRoof) {
            face.type = 'roof';
            face.link = polygon.linkedRoof.id;
            polygon.linkedRoof.link = {
                type: 'roof',
                id: this.id,
                face: this.geometry.length
            };
        } else {
            face.type = 'area';
            face.link = polygon.id;
        }
    } else {
        face.vertices = polygon.map((vertex) =>
            ({x: vertex.x, y: vertex.y, z: vertex.z}));
    }
    // Update roofPoints height (z)
    if (isArea && pointsIndexes) {
        if (!polygon.ramp) {
            pointsIndexes.forEach((point, i) => {
                if (this.roofPoints[point] &&
                    this.roofPoints[point].z === undefined &&
                    face.vertices[i + 2]) {
                    this.roofPoints[point].z = face.vertices[i + 2].z;
                }
            });
        }
        face.index = pointsIndexes;
    }
    if (this.geometry.length < this.path.vertices.length) {
        face.wallIndex = (this.geometry.length + this.orientation) %
            this.path.vertices.length;
    }
    this.geometry.push(face);
};

/**
 * Method to calculate the height of the roof based on its areas' slopes.
 *
 * @param  {float}  adjacent    - size of the adjacent side of the triangle.
 * @param  {float}  hypotenuse  - size of the hypotenuse of the triangle.
 *
 * @return {float} - Trigonometrically calculated height of the roof.
 */
EZModelRoof.prototype.calculateHeight = function(adjacent, hypotenuse) {
    var height = 0;
    var check = 0;
    if (this.type !== 'flat') {
        height = EZModelUtils.getOppositeA(this.inclination, adjacent);
        check = EZModelUtils.getOppositeH(this.inclination, hypotenuse);
        if (height !== check) {
            console.error('EZModelRoof', 'calculateHeight',
                'Trigonometrically invalid height', height, check);
        }
    }
    if (this.height === undefined) this.height = 0;
    if (height !== this.height &&
        (height > 0 && height > this.height) ||
        (height < 0 && height < this.height)) {
        this.height = height;
    }

    return this.height;
};

EZModelRoof.prototype.calculateRidgeHeight = function() {
    const building = this.parent.__data;
    var height = this.height;
    height += (this.baseHeight[0] + this.elevation);
    if (height >= 0) {
        let maxRoof = height;
        if (this.parent.roofs.length >= 1) {
            maxRoof = Math.max(...this.parent.roofs.map((roof) => {
                const h = (roof.baseHeight[0] + roof.elevation + roof.height);
                return (h >= 0) ? h : 0;
            }));
        }
        building.ridge.maxRoof = isFinite(maxRoof) ? maxRoof : 0;
    }
    if (height <= 0) {
        let minRoof = height;
        if (this.parent.roofs.length >= 1) {
            minRoof = Math.min(...this.parent.roofs.map((roof) => {
                const h = (roof.baseHeight[0] + roof.elevation + roof.height);
                return (h <= 0) ? h : 0;
            }));
        }
        building.ridge.minRoof = isFinite(minRoof) ? minRoof : 0;
    }
    if (building.ridge.enabled) {
        this.parent.checkRidgeHeight();
    } else {
        building.ridge.height = building.height + building.ridge.maxRoof;
    }
};

EZModelRoof.prototype.calculateInclination = function(path, areaInclination) {
    let inclination = 0;
    if (this.type === 'flat') return inclination;
    if (this.inclination === 0) return inclination;
    if (areaInclination !== undefined && areaInclination === 0) return inclination;

    if (this.height === undefined) {
        inclination = this.inclination;
    } else if (this.height !== 0) {
        // Calculate this area inclination
        let adjacent = path.translateFlattenPoints(
            [path.vertices[0], path.vertices[1]],
            (270 - EZModelUtils.cleanDegrees(path.edgeAzimuths[0])));
        adjacent = adjacent[2];
        // angle = Math.atan(opposite / adjacent);
        inclination = this.height / adjacent;
        inclination = Math.atan(inclination);
        inclination = EZModelUtils.radToDeg(inclination);
        inclination = parseFloat(inclination.toFixed(2));
    }
    if (areaInclination !== undefined) {
        areaInclination = parseFloat(parseFloat(areaInclination).toFixed(2));
        const angleBias = ez3dScene.layoutRules.scenePreferences.angleBias;
        const delta = Math.abs(inclination - areaInclination);
        if (delta > angleBias) {
            console.warn('EZModelRoof', 'calculateInclination',
                '(i: ' + inclination, ' !== ', areaInclination + ' :d)');
            inclination = areaInclination;
        }
    }
    return inclination;
};

EZModelRoof.prototype.calculatePointsAreas = function() {
    const {getMiddlePoint, getFixedIndex, calculateLineAzimuth,
        rotate, intersectLines, aproxPointToLine} = EZModelUtils;
    const getPoint = (point) => {
        return {x: point.x, y: point.y};
    };

    this.roofPointsAreas = [];
    const axis = this.orientation;

    const getVertex = (steps) => {
        const length = this.path.vertices.length;
        const index = getFixedIndex(axis, steps, length);
        return this.path.vertices[index];
    };

    // const rotatedEdgeCenters = rotate(this.path.edgeCenters, axis);
    const rotatedRoofPoints = rotate(this.roofPoints, -axis);
    const rotatedEdgeCenters = [];
    const rotatedRoofPointsAreas = [];
    // eslint-disable-next-line no-unused-vars
    rotatedRoofPoints.forEach((point, index) => {
        const nextIndex = getFixedIndex(index, 1, this.path.vertices.length);
        rotatedEdgeCenters[index] =
            getMiddlePoint(
                this.path.vertices[index],
                this.path.vertices[nextIndex]);
    });

    if (rotatedRoofPoints.length === 0) return;
    if (['pent', 'pergola', 'pitched'].includes(this.type)) return;

    if (rotatedRoofPoints.length === 1) {
        this.roofPointsAreas = [
            this.path.vertices.map((vertex) => ({x: vertex.x, y: vertex.y}))
        ];
    } else if (rotatedRoofPoints.length === 2) {
        // eslint-disable-next-line no-unused-vars
        rotatedRoofPoints.forEach((point, index) => {
            const first = 2 * index;
            const delta = 2 - first;
            this.roofPointsAreas.push([
                getPoint(getVertex(first)),
                getMiddlePoint(
                    getVertex(first),
                    getVertex(first + 1)),
                getMiddlePoint(
                    getVertex(delta),
                    getVertex(delta + 1)),
                getPoint(getVertex(delta + 1))
            ]);
        });
    } else {
        let origin;
        if (rotatedRoofPoints.length === 4) {
            const azimuthSym1 = calculateLineAzimuth(
                rotatedEdgeCenters[0],
                rotatedEdgeCenters[2]);
            const azimuthSym2 = calculateLineAzimuth(
                rotatedEdgeCenters[1],
                rotatedEdgeCenters[3]);
            origin = intersectLines(
                [rotatedEdgeCenters[0], azimuthSym1],
                [rotatedEdgeCenters[1], azimuthSym2]);
        } else {
            origin = {x: 0, y: 0};
        }
        const center = (this.topPoint)
            ? this.topPoint : origin;
        // eslint-disable-next-line no-unused-vars
        rotatedRoofPoints.forEach((point, index) => {
            const azimuthSym1 = calculateLineAzimuth(
                rotatedEdgeCenters[index],
                origin);
            const nextIndex = getFixedIndex(index, 1, rotatedEdgeCenters.length);
            const prevIndex = getFixedIndex(index, -1, rotatedEdgeCenters.length);
            const azimuthSym2 = calculateLineAzimuth(
                rotatedEdgeCenters[nextIndex],
                origin);
            let intersect1 = intersectLines(
                [center, azimuthSym1],
                [this.path.vertices[index], this.path.edgeAzimuths[index]]);
            let intersect2 = intersectLines(
                [center, azimuthSym2],
                [this.path.vertices[index], this.path.edgeAzimuths[prevIndex]]);
            intersect1 = aproxPointToLine(
                intersect1, [getPoint(this.path.vertices[nextIndex])],
                getPoint(this.path.vertices[index]), false);
            intersect2 = aproxPointToLine(
                intersect2, [getPoint(this.path.vertices[index])],
                getPoint(this.path.vertices[prevIndex]), false);
            rotatedRoofPointsAreas.push([
                getPoint(this.path.vertices[index]),
                intersect1,
                getPoint(center),
                intersect2
            ]);
        });
        this.roofPointsAreas = rotate(rotatedRoofPointsAreas, axis);
        if (this.roofPoints.length === 4 &&
            this.path.parallelSides.length === 1 &&
            !this.roofPointsSymmetry) {
            this.checkPointsAreas(center);
        }
    }
};

EZModelRoof.prototype.calculatePointsLines = function(movingIndex, newPosition) {
    if (movingIndex === undefined) return [];
    if (_.isEmpty(this.roofPointsAreas)) return [];
    const areaLines = [];
    if (movingIndex === -1) {
        this.roofPointsAreas.forEach((vertices) => {
            areaLines.push({
                x1: vertices[0].x, y1: vertices[0].y,
                x2: newPosition.x, y2: newPosition.y,
            });
        });
    } else if (this.type === 'mansard' && (this.roofPointsSymmetry ||
        this.roofPointsSymmetry === undefined)) {
        this.roofPointsAreas.forEach((vertices) => {
            areaLines.push({
                x1: vertices[0].x, y1: vertices[0].y,
                x2: this.topPoint.x, y2: this.topPoint.y,
            });
        });
    } else {
        const vertices = this.roofPointsAreas[movingIndex];
        areaLines.push({
            x1: vertices[1].x, y1: vertices[1].y,
            x2: vertices[2].x, y2: vertices[2].y,
        });
        if (this.roofPoints.length === 2) return areaLines;
        areaLines.push({
            x1: vertices[2].x, y1: vertices[2].y,
            x2: vertices[3].x, y2: vertices[3].y,
        });
    }
    return areaLines;
};

EZModelRoof.prototype.calculatePointsSymmetry = function(roofPoints, movingIndex, newPosition) {
    const {aproxPointsByPercent, getPointsPercent, rotate} = EZModelUtils;

    const axis = this.orientation;
    const isTopPoint = (movingIndex === -1);

    const getPathPercent = () => {
        const rotatedPath = rotate(this.path.vertices, axis);
        const refIndex = (isTopPoint) ? 0 : movingIndex;
        const percent = getPointsPercent(
            rotatedPath[refIndex], this.topPoint, roofPoints[refIndex]);
        const target = (isTopPoint) ? newPosition : this.topPoint;
        return roofPoints.map((point, index) => {
            const coords = aproxPointsByPercent(
                rotatedPath[index], target, percent);
            point.x = coords.x;
            point.y = coords.y;
            return point;
        });
    };

    if (isTopPoint) return getPathPercent();
    if (this.topPoint && (this.roofPointsSymmetry ||
        this.roofPointsSymmetry === undefined)) return getPathPercent();
    roofPoints = this.calculatePointsCoords(roofPoints, movingIndex, newPosition);

    return roofPoints;
};

EZModelRoof.prototype.calculatePointsCoords = function(roofPoints, movingIndex, newPosition) {
    const {aproxPointToLine, calculateLineAzimuth, degToRad, getMiddlePoint,
        getFixedIndex, intersectLines, rotatePoint} = EZModelUtils;

    const axis = this.orientation;
    const edgeLength = this.path.vertices.length;

    const sharedEdge = [getFixedIndex(axis, movingIndex, edgeLength)];
    const opposedEdge = [getFixedIndex(axis + movingIndex, 1, edgeLength)];
    const nextIndex = getFixedIndex(movingIndex, 1, roofPoints.length);
    const linkedPoints = [roofPoints[nextIndex]];

    if (roofPoints.length === 2) {
        sharedEdge[0] = getFixedIndex(sharedEdge[0], movingIndex, edgeLength);
        opposedEdge[0] = getFixedIndex(opposedEdge[0], movingIndex, edgeLength);
    } else {
        sharedEdge.push(getFixedIndex(axis + movingIndex, -1, edgeLength));
        const prevIndex = getFixedIndex(movingIndex, -1, roofPoints.length);
        linkedPoints.push(roofPoints[prevIndex]);
        opposedEdge.push(getFixedIndex(axis + movingIndex, -2, edgeLength));
    }
    linkedPoints.forEach((point, index) => {
        let azimuthA = this.path.edgeAzimuths[sharedEdge[index]];
        const delta = edgeLength / 2;
        const step = getFixedIndex(sharedEdge[index], delta, edgeLength);

        // Invalid Ridge
        const next = sharedEdge[index];
        if (this.parallelRidgeInvalid &&
            this.path.vertices.length !== roofPoints.length) {
            const pivot = intersectLines(
                [this.path.vertices[next], this.path.edgeAzimuths[next]],
                [this.path.vertices[step], this.path.edgeAzimuths[step]]);
            azimuthA = calculateLineAzimuth(newPosition, pivot);
        }
        const azimuthB = this.path.edgeAzimuths[opposedEdge[index]];
        let intersect = intersectLines(
            [newPosition, azimuthA], [point, azimuthB]);

        // Points Symmetry
        if (this.roofPointsSymmetry) {
            const symmetryPoint = getMiddlePoint(this.path.vertices[next],
                this.path.vertices[getFixedIndex(next, 1, edgeLength)]);
            const symmetryAzimuth = calculateLineAzimuth(symmetryPoint,
                getMiddlePoint(this.path.vertices[step],
                    this.path.vertices[getFixedIndex(step, 1, edgeLength)])
            );
            const symmetryAngle = degToRad(symmetryAzimuth);
            const rotatedA = rotatePoint(newPosition, -symmetryAngle);
            const rotatedB = rotatePoint(intersect, -symmetryAngle);
            const rotatedS = rotatePoint(symmetryPoint, -symmetryAngle);
            rotatedS.x -= (rotatedA.x - rotatedS.x);

            const azimuthAB = calculateLineAzimuth(rotatedA, rotatedB);
            const auxPoint = intersectLines(
                [rotatedS, 0], [rotatedA, azimuthAB]);
            intersect = rotatePoint(auxPoint, symmetryAngle);
        } else if (roofPoints.length === 2) {
            // Checking Bounds
            const pointArea = this.roofPointsAreas[roofPoints.indexOf(point)];
            intersect = aproxPointToLine(intersect, [pointArea[1]],
                pointArea[2], true, azimuthA);
        } else if (this.roofPoints.length === 4 &&
            this.path.parallelSides.length === 1) {
            const parallelPair = this.path.parallelSides[0];
            const i = (
                this.path.edgeDimensions[parallelPair[0]] >
                    this.path.edgeDimensions[parallelPair[1]])
                ? 1 : 0;
            const smallParallelSide = [
                parallelPair[i],
                getFixedIndex(
                    parallelPair[i], 1, this.path.vertices.length)
            ];
            const rotatedSmallParallelSide = [];
            smallParallelSide.forEach((smallPoint) => {
                rotatedSmallParallelSide.push(getFixedIndex(smallPoint,
                    -axis, this.path.vertices.length));
            });
            if (rotatedSmallParallelSide.includes(roofPoints.indexOf(point)) &&
                rotatedSmallParallelSide.includes(movingIndex)) {
                const pointArea = this.roofPointsAreas[roofPoints.indexOf(point)];
                let aux = aproxPointToLine(intersect, [pointArea[1]],
                    pointArea[2], true, azimuthA);
                aux = aproxPointToLine(aux, [pointArea[2]],
                    pointArea[3], true, azimuthA);
                const maxAdmit = 0.02;
                const isOutlineRoofType =
                    (Math.abs(intersect.x - aux.x) > maxAdmit) ||
                    (Math.abs(intersect.y - aux.y) > maxAdmit);
                if (isOutlineRoofType) {
                    intersect = aux;
                    const roofPointsModif = this.calculatePointsCoords(
                        roofPoints, roofPoints.indexOf(point), intersect);
                    const modifIndex = getFixedIndex(
                        movingIndex, 2, roofPoints.length);
                    roofPoints[modifIndex] = roofPointsModif[modifIndex];
                }
            }
        }

        // Assignment
        point.x = intersect.x;
        point.y = intersect.y;
    });
    return roofPoints;
};

/**
 * Method to calculate and create the roofPoints
 * @return {array} Array of {@link EZModelLocation}
 */
EZModelRoof.prototype.calculateRoofPoints = function() {
    const {getMiddlePoint, setLocation} = EZModelUtils;
    const axis = this.orientation;

    this.roofPoints = [];
    switch (this.type) {
        default: // falls through
        case 'courtyard': // falls through
        case 'flat':
            this.roofPoints = [];
            break;
        case 'pergola': // falls through
        case 'pitched': // falls through
        case 'pent':
            for (let i = 0; i < this.path.vertices.length - 2; i++) {
                const index = (axis + 2 + i) % this.path.vertices.length;
                this.roofPoints.push(
                    setLocation(this.path.vertices[index], this));
            }
            break;
        case 'gabled': // falls through
        case 'gambrel': // falls through
        case 'hipped': {
            // First point
            for (let i = 0; i < 2; i++) {
                const j = 3 - i;
                const point1 = this.path.vertices[(axis + i) % 4];
                const point2 = this.path.vertices[(axis + j) % 4];
                let center = getMiddlePoint(point1, point2, true);
                if (this.type === 'hipped') {
                    center = setLocation(center, this);
                    center = getMiddlePoint(this.path.center, center, true);
                }
                if (this.type === 'gambrel') {
                    const point3 = this.path.vertices[(axis + j - 2) % 4];
                    const point4 = this.path.vertices[(axis + i + 2) % 4];
                    let center2 = getMiddlePoint(point3, point4, true);
                    center = setLocation(center, this);
                    center2 = setLocation(center2, this);
                    /*
                    this.roofPoints[i] = setLocation(
                        getMiddlePoint(point1, center, true), this);
                    this.roofPoints[j] = setLocation(
                       getMiddlePoint(point2, center, true), this);
                    */
                    const percent = 0.25;
                    const aux = (i === 0) ? [point1, point3, center, center2]
                        : [point4, point2, center2, center];
                    const sphericalRoofPoint = {
                        lng: aux[0].lng * (1 - percent) + (aux[2].lng * percent),
                        lat: aux[0].lat * (1 - percent) + (aux[2].lat * percent)
                    };
                    this.roofPoints[(4 - i) % 4] = setLocation(
                        sphericalRoofPoint, this);
                    const newPercent = (this.path.parallelSides[0].includes(axis))
                        ? percent : percent * (
                            this.path.edgeDimensions[(4 + axis - 1) % 4] /
                            this.path.edgeDimensions[(4 + axis + 1) % 4]);
                    const sphericalRoofPoint2 = {
                        lng: aux[1].lng * (1 - newPercent) +
                            (aux[3].lng * newPercent),
                        lat: aux[1].lat * (1 - newPercent) +
                            (aux[3].lat * newPercent)
                    };
                    this.roofPoints[i + 1] = setLocation(
                        sphericalRoofPoint2, this);
                } else {
                    this.roofPoints.push(setLocation(center, this));
                }
            }
            break;
        }
        case 'pyramid':
            this.roofPoints.push(setLocation(this.path.center, this));
            break;
        case 'mansard': {
            if (!this.topPoint) {
                this.topPoint = setLocation(this.path.center, this);
            }
            const rotatedPath = EZModelUtils.rotate(this.path.vertices, axis);
            rotatedPath.forEach((point) => {
                const center = getMiddlePoint(this.topPoint, point, true);
                this.roofPoints.push(setLocation(center, this));
            });
            break;
        }
    }
    if (this.type !== 'mansard') {
        delete this.topPoint; // this.topPoint = undefined;
    }
    return this.roofPoints;
};

/**
 * Method to recalculate all the properties of the roof
 *
 * @param  {boolean} preservePoints - Should the roofPoints be recalculated?
 * @param  {boolean} regeneratePath - Should the path be regenerated?
 */
EZModelRoof.prototype.calculateRoof = function(preservePoints, regeneratePath) {
    if (regeneratePath) this.setPath(null, this.parent);
    if (!preservePoints) delete this.roofPoints;
    // clear heights
    const building = this.parent.__data;
    if (!building.ridge.enabled) {
        building.ridge.height = building.height;
    }
    const maxRoof = Math.max(...this.parent.roofs
        .filter((roof) => (roof.id !== this.id)).map((roof) => {
            const h = (roof.baseHeight[0] + roof.elevation + roof.height);
            return (h >= 0) ? h : 0;
        }));
    building.ridge.maxRoof = isFinite(maxRoof) ? maxRoof : 0;
    const minRoof = Math.min(...this.parent.roofs
        .filter((roof) => (roof.id !== this.id)).map((roof) => {
            const h = (roof.baseHeight[0] + roof.elevation + roof.height);
            return (h <= 0) ? h : 0;
        }));
    building.ridge.minRoof = isFinite(minRoof) ? minRoof : 0;
    delete this.height; // this.height = undefined;
    this.areaTypes = [];
    this.createAreas();
    // Update Paths
    this.updatePath();
};

// CREATE
/**
 * Method to (re)create all the areas of the roof
 *
 * @param  {object} areaData    - JSON with the data of the areas.
 * @param  {boolean} clone      - Are these areas being created as clones?
 */
EZModelRoof.prototype.createAreas = function(areaData, clone) {
    // Remove Areas
    this.areas = [];
    if (this.geometry && this.parent.roofs.length) {
        this.removeLinks();
    }

    // Check roofPoints
    if (this.roofPoints === undefined) {
        this.calculateRoofPoints();
    } else {
        this.clearRoofPointsHeight();
    }
    this.calculatePointsAreas();

    let roofFacesFacing = []; // Face Orientation
    let roofFacesSides = []; // Number of Edges
    let roofFacesValid = []; // Area-Valid Face
    let roofWallType = []; // Roof Wall Type
    // - main       - only inclination
    // - support    - only orientation
    // - standard   - inclination & orientation
    // - undefined  - nothing at all (flat)

    let axis = this.orientation;
    if (axis === -1) axis = 0;

    switch (this.type) {
        // 1 Face without orientation
        default: // falls through
        case 'flat':
            roofFacesFacing = [-1];
            roofFacesSides = [this.path.vertices.length];
            roofFacesValid = [true];
            roofWallType = new Array(this.path.vertices.length);
            break;
        // 1 Face with orientation
        case 'pergola': // falls through
        case 'pitched': // falls through
        case 'pent':
            roofFacesFacing = [axis];
            roofFacesSides = [2 + this.roofPoints.length];
            roofFacesValid = [true];
            roofWallType = ['main'];
            for (let i = 0; i <= this.roofPoints.length; i++) {
                if (this.type === 'pent') {
                    roofFacesFacing.push((axis + 1 + i) %
                        this.path.vertices.length);
                    roofFacesSides.push((i === 0 ||
                        i === this.roofPoints.length) ? 3 : 4);
                    roofFacesValid.push(false);
                }
                roofWallType.push('support');
            }
            break;
        // 2 Faces with orientation
        case 'gabled':
            for (let i = 0; i < 4; i++) {
                roofFacesFacing.push((axis + i) % 4);
            }
            roofFacesSides = [4, 3, 4, 3];
            roofFacesValid = [true, false, true, false];
            roofWallType = ['main', 'support', 'standard', 'support'];
            break;
        // 4 Faces with orientation
        case 'hipped':
            for (let i = 0; i < 4; i++) {
                roofFacesFacing.push((axis + i) % 4);
            }
            roofFacesSides = [4, 3, 4, 3];
            roofFacesValid = [true, true, true, true];
            roofWallType = ['main', 'support', 'standard', 'support'];
            // roofWallType = ['main', 'standard', 'standard', 'standard'];
            break;
        // X Faces with orientation
        case 'mansard': // falls through
        case 'gambrel': // falls through
        case 'pyramid': {
            roofFacesFacing = [axis];
            const sides = (this.type === 'pyramid') ? 3 : 4;
            roofFacesSides = [sides];
            roofFacesValid = [true];
            roofWallType = ['main'];
            for (let i = 1; i < this.path.vertices.length; i++) {
                roofFacesFacing.push((axis + i) % this.path.vertices.length);
                roofFacesSides.push(sides);
                roofFacesValid.push(
                    (this.type === 'gambrel') ? (i % 2 === 0) : true);
                roofWallType.push('standard');
            }
            if (['mansard', 'gambrel'].includes(this.type)) {
                roofFacesFacing.push(-1);
                roofFacesSides.push(this.path.vertices.length);
                roofFacesValid.push(true);
            }
            break;
        }
    }

    // Create Areas
    this.geometry = [];
    let areaFaces = [];
    if (areaData) {
        const validAreas = [...areaData];
        if (this.areaTypes.length) {
            areaFaces = this.areaTypes.map((type) => (type === 'area')
                ? validAreas.shift() : undefined);
        } else {
            areaFaces = roofFacesValid.map((valid) => (valid)
                ? validAreas.shift() : undefined);
        }
    }
    const mainInclination = this.inclination;
    roofFacesFacing.forEach((facing, index) => {
        const valid = roofFacesValid[index];
        const {vertices, pointsIndexes} = this
            .calculateAreaPath(facing, roofFacesSides, index, valid);
        if (valid) {
            let area = areaFaces[index];
            this.__data.inclination = (facing >= 0) ? mainInclination : 0;
            if (area) {
                area = ez3dScene.factories['Area']
                    .createArea(ez3dScene, this, area, clone);
                this.areas.push(area);
            } else {
                const areaPath = new EZModelPath(ez3dScene, vertices);
                area = ez3dScene.factories['Area']
                    .createArea(ez3dScene, this, areaPath);
                if (area.linkedRoof) {
                    if (this.areaTypes[index] !== 'roof') {
                        this.parent.roofs.push(area.linkedRoof);
                        this.areaTypes[index] = 'roof';
                    }
                } else if (area.ramp) {
                    this.areaTypes[index] = 'ramp';
                } else {
                    this.areas.push(area);
                    this.areaTypes[index] = 'area';
                }
            }
            this.calculateGeometry(area, pointsIndexes);
        } else {
            this.calculateGeometry(vertices, pointsIndexes);
        }
    });
    this.__data.inclination = mainInclination;

    if (this.type === 'pergola') {
        this.createPergolaPost();
    } else {
        this.createFacades();
    }

    // WIP: avoid orientation on facades
    roofWallType = EZModelUtils.rotate(roofWallType, -axis);
    roofWallType.forEach((wall, index) => {
        if (wall === undefined || this.checkInvalidAxis(index)) {
            roofWallType[index] = 'default';
        }
    });
    this.path.wallType = roofWallType;
};

EZModelRoof.prototype.createPergolaPost = function(ratio = 6) {
    const {pairWithNext, aproxCoordsByRatio, aproxPointsByRatio} = EZModelUtils;
    const center = {
        x: (this.geometry[0].vertices[0].x + this.geometry[0].vertices[2].x) / 2,
        y: (this.geometry[0].vertices[0].y + this.geometry[0].vertices[2].y) / 2,
        z: (this.geometry[0].vertices[0].z + this.geometry[0].vertices[2].z) / 2,
    };
    this.geometry[0].vertices
        .map(pairWithNext)
        .forEach(([vertex, next]) => {
            this.geometry.push({
                area: undefined,
                inclination: 90,
                type: 'facade',
                vertices: [{
                    x: aproxCoordsByRatio(vertex.x, center.x, ratio),
                    y: aproxCoordsByRatio(vertex.y, center.y, ratio),
                    z: -1 * this.parent.height,
                }, {
                    x: aproxCoordsByRatio(next.x, center.x, ratio),
                    y: aproxCoordsByRatio(next.y, center.y, ratio),
                    z: -1 * this.parent.height,
                },
                aproxPointsByRatio(next, center, ratio),
                aproxPointsByRatio(vertex, center, ratio)
                ]
            });
        });
};

EZModelRoof.prototype.createFacades = function() {
    const {pairWithNext} = EZModelUtils;
    const setFacade = (vertices) => {
        return {
            area: undefined,
            inclination: 90,
            type: 'facade',
            vertices: vertices
        };
    };
    const facades = [];
    const invalid = (this.inclination < 0) ? this.height : this.baseHeight[0];
    this.geometry = this.geometry.filter((face) => (face.type !== 'facade'));

    this.geometry.forEach((face) => {
        if (!['area', 'roof'].includes(face.type)) return;
        face.vertices
            .map(pairWithNext)
            .forEach(([vertex, next]) => {
                if (vertex.z === invalid && next.z === invalid) return;
                // Lower
                const facade = [
                    // undefined >> Renderer.prototype.drawRoof
                    {x: vertex.x, y: vertex.y, z: undefined},
                    {x: next.x, y: next.y, z: undefined}];
                // Upper
                if (next.z !== invalid) {
                    facade.push({x: next.x, y: next.y, z: next.z});
                }
                if (vertex.z !== invalid) {
                    facade.push({x: vertex.x, y: vertex.y, z: vertex.z});
                }
                facades.push(setFacade(facade));
            });
    });
    this.geometry = this.geometry.concat(facades);
};

// SET
/**
 * Method to set the path of the roof.
 *
 * @param {EZModelPath} path            - The path of this roof.
 * @param {EZModelBuilding} building    - The parent of this roof.
 * @param {boolean} clone               - Is this roof being created as a clone?
 */
EZModelRoof.prototype.setPath = function(path, building, clone) {
    EZModelUtils.destroyObject(this.path);
    // setCoordSystem
    if (path) {
        this.path = (clone)
            ? new EZModelPath(ez3dScene, path.cloneVertices())
            : path;
    } else {
        const offsetteds = building.path.getOffsetteds(building.offset, false);
        let offsettedPath;
        if (offsetteds.length > 1) {
            console.log('EZModelRoof', 'setPath', 'multiple offsetted paths');
            offsettedPath = offsetteds[((this.index - 1) < offsetteds)
                ? (this.index - 1) : 0];
        } else if (offsetteds.length === 1) {
            offsettedPath = offsetteds[0];
        } else {
            console.warn('EZModelRoof', 'setPath',
                'invalid offset, using zero instead');
            // building.__data.offset = [0];
            offsettedPath = building.path;
        }
        this.path = new EZModelPath(ez3dScene, offsettedPath.cloneVertices());
    }
    // forceClockWise
    this.path.forceClockWise();
    this.path.closed = true;
    this.path.setCoordSystem('building', this, 'EZModelRoof');
};

// UPDATE
/**
 * Method to update the height coordinate of the locations
 */
EZModelRoof.prototype.updateHeight = function() {
    // console.log('EZModelRoof updateHeight');
    if (this.baseHeight[1]) {
        const linked = this.parent.roofs
            .find((roof) => (roof.id === this.baseHeight[1]));
        if (linked) {
            this.baseHeight[0] = (linked.baseHeight[0] + linked.elevation +
                (linked.height === undefined) ? 0 : linked.height);
        }
    }
    for (var i = 0; i < this.path.vertices.length; i++) {
        this.path.vertices[i].z = this.baseHeight[0];
    }
    if (this.height === undefined) {
        console.error('EZModelRoof', 'updateHeight',
            'Roof with undefined height');
        this.height = 0;
    }
};

/**
 * Method to update the path of the roof.
 *
 * @param {object} offset - {lat: delta degrees, lng: delta degrees}
 * @param  {boolean} ignoreKeepouts - Should the keepouts ignored?
 */
EZModelRoof.prototype.updatePath = function(offset, ignoreKeepouts) {
    if (offset) this.path.move(offset);
    this.path.calculatePathCenter();
    this.path.updateCartesianCoords();
    this.path.getOffsetteds(this.offset, false);

    this.updateHeight();

    // Update Roof Points
    this.roofPoints.forEach((point) => {
        if (offset) point.move(offset);
        point.setCoordSystem('building', this, 'EZModelRoof');
        point.updateCartesianCoords();
    });
    // Update Areas
    this.areas.forEach((area) => {
        area.updatePath(offset, ignoreKeepouts);
    });
    // Update Building
    if (!ignoreKeepouts) {
        this.parent.updateKeepouts();
    }
};

EZModelRoof.prototype.updateSystem = function(skipUpdate, fromBuilding) {
    var data = {holders: 0, modules: 0, power: 0};
    var holder;

    this.areas.forEach(function(area) {
        holder = area.updateSystem(skipUpdate, fromBuilding);
        data.holders += (area.disabled) ? 0 : holder.holders;
        data.modules += (area.disabled) ? 0 : holder.modules;
        data.power += (area.disabled) ? 0 : holder.power;
    });

    if (!fromBuilding) {
        this.parent.updateSystem(true);
    }
    this.systemInfo = data;
    return data;
};

// UTILS
EZModelRoof.prototype.clearRoofPointsHeight = function() {
    this.roofPoints.forEach((point) => {
        delete point.z;
    });
};

EZModelRoof.prototype.formatMaterialsClass = function(data) {
    var value = data.toLowerCase();
    var items = value.split(' ');
    for (var i = 0; i < items.length; i++) {
        items[i] = items[i].replace(/[^A-Za-z0-9]/g, '');
        if (items[i] === '') {
            items.splice(i, 1);
        }
    }
    value = items.join('-');
    return value;
};

EZModelRoof.prototype.generateRoofPoints = function(data, clone) {
    const {setLocation} = EZModelUtils;
    this.roofPoints = [];

    if (this.type === 'flat') return;
    // topPoint
    if (data && data.topPoint) {
        this.topPoint = setLocation(data.topPoint, this);
    } else if (this.type === 'mansard') {
        this.topPoint = setLocation(this.path.center, this);
    }
    // roofPoints
    if (data && data.roofPoints) {
        if (clone || (data.roofPoints.length > 0 &&
            data.roofPoints[0].constructor.name !== 'EZModelLocation')) {
            data.roofPoints.forEach((point) => {
                this.roofPoints.push(
                    setLocation(point, this)
                );
            });
        } else {
            this.roofPoints = data.roofPoints;
            this.roofPoints.forEach((point) => {
                point.setCoordSystem('building', this, 'EZModelRoof');
            });
        }
    } else {
        this.calculateRoofPoints();
    }
};

EZModelRoof.prototype.isOutlineRoofType = function(type = this.type) {
    return ['gabled', 'gambrel'].includes(type);
};

EZModelRoof.prototype.removeLinks = function () {
    this.geometry.forEach((face) => {
        if (face.type !== 'roof') return;
        const next = this.parent.roofs
            .find((roof) => roof.id === face.link);
        if (next) {
            if (next.geometry) next.removeLinks();
            EZModelUtils.removeElementFromArray(
                this.parent.roofs, next
            );
        }
    });
};

// CHECK
EZModelRoof.prototype.checkings = function(data) {
    // - Orientation
    if (typeof data.orientation === 'string') {
        const availableOrientation = this
            .getDefaultValue('availableOrientation', this.type, data);
        if (availableOrientation && !availableOrientation.includes(data.orientation)) {
            // fix invalid orientations from v2 for flat roofs
            this.__data.orientation = this.getDefaultValue('orientation', this.type);
        }
        this.__data.orientation = this.checkInclinationAxis();
    }
    // console.log('EZModelRoof', 'orientation', this.orientation);

    // - Inclination
    this.__data.inclination = this.checkRoofInclination(this.inclination);
};

EZModelRoof.prototype.checkInclinationAxis = function(orientation) {
    const validateFacing = (facing, starting) => {
        let candidateAxis = facing;
        const invalidAxis = this.checkInvalidAxis(facing, true);
        if (invalidAxis) {
            const nextIndex = (facing + 1) % this.path.vertices.length;
            if (nextIndex === starting) return starting;
            candidateAxis = validateFacing(nextIndex, starting);
        }
        return candidateAxis;
    };
    const findValidFacing = (facing, index) => {
        const candidateAxis = this.path.wallFacing.indexOf(facing, index);
        if (candidateAxis === -1) return validateFacing(0, 0);
        const invalidAxis = this.checkInvalidAxis(candidateAxis, true);
        return (invalidAxis)
            ? findValidFacing(facing, candidateAxis + 1)
            : candidateAxis;
    };
    let axis;
    this.parallelRidgeInvalid = false;
    const facing = (orientation === undefined)
        ? this.orientation : orientation;
    if (typeof facing === 'string') {
        // Roofs 1.0
        axis = (facing === '') ? validateFacing(0, 0)
            : findValidFacing(facing[0]);
    } else {
        // Roofs 2.0
        axis = validateFacing(facing, facing);
    }
    return axis;
};

EZModelRoof.prototype.checkInvalidAxis = function(facing, checkParallel) {
    const invalid = this.path.concaveAngles.includes(facing);
    if (checkParallel && this.parallelRidge) {
        const validRidge = this.path.parallelSides.find((sides) => {
            return sides.includes(facing);
        });
        this.parallelRidgeInvalid = (validRidge === undefined);
    }
    return invalid;
};

EZModelRoof.prototype.checkParallelRidge = function(type = this.type) {
    this.parallelRidge = (this.path.vertices.length === 4) &&
        ['gabled', 'hipped', 'gambrel', 'mansard'].includes(type);
    return this.parallelRidge;
};

EZModelRoof.prototype.checkPointsAreas = function(centerSymPoint) {
    const {calculateLineAzimuth, getFixedIndex,
        intersectLines, rotate} = EZModelUtils;

    const axis = this.orientation;
    const parallelPair = this.path.parallelSides[0];
    const rotatedRoofPointsAreas = rotate(this.roofPointsAreas, -axis);
    const i = (this.path.edgeDimensions[parallelPair[0]] <
        this.path.edgeDimensions[parallelPair[1]]) ? 1 : 0;
    const nextIndex = getFixedIndex(
        parallelPair[i], 1, rotatedRoofPointsAreas.length);
    const changingPointArea = [
        rotatedRoofPointsAreas[parallelPair[i]],
        rotatedRoofPointsAreas[nextIndex]];

    // Points
    const rotatedRoofPoints = rotate(this.roofPoints, -axis);
    const oppositeIndex = getFixedIndex(i, 1, parallelPair.length);
    const oppositePoint = rotatedRoofPoints[parallelPair[oppositeIndex]];

    // Azimuths
    const azimuthParallel = this.path.edgeAzimuths[parallelPair[oppositeIndex]];

    const azimuthSymetry = calculateLineAzimuth(
        changingPointArea[0][1], changingPointArea[0][2]);

    const azimuthNextEdge = this.path.edgeAzimuths[nextIndex];
    const prevIndex = getFixedIndex(
        parallelPair[i], -1, this.path.vertices.length);
    const azimuthPreviousEdge = this.path.edgeAzimuths[prevIndex];

    // Calculation
    const auxPoint = intersectLines(
        [oppositePoint, azimuthParallel],
        [centerSymPoint, azimuthSymetry]);
    changingPointArea[0][1] = intersectLines(
        [auxPoint, azimuthPreviousEdge],
        [changingPointArea[0][0], azimuthParallel]);
    changingPointArea[0][2] = intersectLines(
        [auxPoint, azimuthPreviousEdge],
        [centerSymPoint, azimuthParallel]);
    changingPointArea[1][2] = intersectLines(
        [auxPoint, azimuthNextEdge],
        [centerSymPoint, azimuthParallel]);
    changingPointArea[1][3] = intersectLines(
        [auxPoint, azimuthNextEdge],
        [changingPointArea[1][0], azimuthParallel]);

    // Redefine RoofAreas
    rotatedRoofPointsAreas[parallelPair[i]] = changingPointArea[0];
    rotatedRoofPointsAreas[nextIndex] = changingPointArea[1];
    this.roofPointsAreas = rotate(rotatedRoofPointsAreas, axis);
};


/**
 * This method checks the type of this roof.
 *
 * @param {string} type type of roof
 *
 * @return {float} The valid type of this roof.
 */
EZModelRoof.prototype.checkRoofType = function(type) {
    var availableType = this.getAvailableTypes();
    if (availableType[type] === undefined) {
        console.error('EZModelRoof:', 'Invalid roof [',
            type, '] in building', this.parent.index);
        type = 'flat';
        this.flatReset = true;
    }
    return type;
};

EZModelRoof.prototype.checkRoofInclination = function(inclination) {
    if (Array.isArray(inclination)) {
        inclination = Number(inclination[0]);
    } else {
        inclination = Number(inclination);
    }

    if (this.type === 'flat' && this.inclination !== 0) {
        inclination = 0;
    }
    return inclination;
};

/**
 * EZModelSubarea
 * @class
 * @classdesc This is the **EZModelSubarea** class.
 *
 * @description This is the constructor of the **EZModelSubarea** class.
 *
 * @param {EZModelScene}    ez3dScene   - Main container for 3DLayout project model.
 * @param {EZModelArea}     area        - The parent of the subarea.
 * @param {object}          data        - JSON with the data of the subarea.
 * @param {boolean}         clone       - Is the subarea being created as a clone?
 */
function EZModelSubarea(ez3dScene, area, data, clone) {
    // ez3dScene = ez3dScene;
    if (clone || data === undefined) {
        EZModelObject.call(this, null);
    } else {
        EZModelObject.call(this, data.id || null);
    }

    // Main properties
    this.parent = area;
    this.setPath(data.path, area, clone);
    // @deprecated parentId
    this.parentId = this.parent.id;

    this.style = {
        visible: true,
        colorState: 'standard'
    };

    // Watched properties
    this.__data = this.getDefaultSubarea(data);
    this.addWatchers();

    // Custom properties
    var self = this;
    if (data.customProperties && data.customProperties.length > 0) {
        self.customProperties = data.customProperties;
        data.customProperties.forEach(function(property) {
            self[property] = data[property];
        });
    }

    // Children
    if (data.system) {
        this.system = ez3dScene.factories['System'].createSystem(ez3dScene, this, data.system, clone);
    } else {
        this.createSystem();
    }

    // Finish
    this.isCreated = (data && data.isCreated) ? data.isCreated : true;
    this.isEditing = (data && data.isEditing) ? data.isEditing : false;
    this.systemInfo = (data.systemInfo) ? data.systemInfo
        : {holders: 0, modules: 0, power: 0};

    // Update
    if (!ez3dScene.isLoadingProject &&
        this.getAncestor('EZModelBuilding').isCreated) {
        this.updatePath();
    }
}

EZModelSubarea.prototype = Object.create(EZModelObject.prototype);
EZModelSubarea.prototype.constructor = EZModelSubarea;

// GET
/**
 * Method to generate and/or asign the watched properties of the subarea.
 *
 * @param   {object}    data    - JSON with the data of the subarea.
 *
 * @return  {object}    JSON with the watched properties of the subarea
 */
EZModelSubarea.prototype.getDefaultSubarea = function (data) {
    var properties = {};
    data = (data) ? data : {};

    // getDefault
    properties.disabled = ez3dScene.getModelProperty('defaultModelValues', 'subarea', 'disabled', data);
    properties.offset = ez3dScene.getModelProperty('defaultModelValues', 'subarea', 'offset', data);
    properties.regular = ez3dScene.getModelProperty('defaultModelValues', 'subarea', 'regular', data);

    // generate
    properties.fromScratch = (data.fromScratch || this.fromScratch);
    properties.name = this.getName();

    // restrictions
    properties.offset = (ez3dScene.projectType === 'simple') ? [0] : properties.offset;

    // console.log('subareas', properties);
    return properties;
};

/**
 * Method to calculate (and asign) the index of the subarea.
 *
 * @param  {EZModelArea} area   - the parent of the subarea.
 *
 * @return {integer}    The index of the subarea.
 */
EZModelSubarea.prototype.getIndex = function() {
    const area = this.parent;
    const indexInParent = (area && area.subareas)
        ? area.subareas.indexOf(this) : 0;
    return (indexInParent === -1)
        ? area.subareas.length + 1 : indexInParent + 1;
};

/**
 * Method to generate the name of the subarea.
 *
 * @return {string} The name of the subarea.
 */
EZModelSubarea.prototype.getName = function() {
    this.index = this.getIndex();
    return (this.fromScratch ? 'Default ' : '') +
        'Subarea ' + this.index;
};

/**
 * Method to get the subarea's children.
 *
 * @param  {boolean} returnObject       - **Optional.** The array should be of objects instead of ids?
 * @param  {boolean} includeParent      - **Optional.** The parent should be included in the array?
 *
 * @return {array} - Array of the subarea's children.
 */
EZModelSubarea.prototype.getChildren = function (returnObject, includeParent) {
    // @TODO replace for a proper import whenever we use ES6+ modules
    const uniteSets = ez3dScene.utils.uniteSets;

    var children = new Set();

    if (includeParent) {
        children.add((returnObject) ? this : this.id);
    }

    children = uniteSets(children, this.path.getChildren(returnObject, true));
    children = uniteSets(children, this.system.getChildren(returnObject, true));

    return children;
};

/**
 * Method to get and format the list of default modules models.
 *
 * @return  {array}  Array with all the modules models.
 */
EZModelSubarea.prototype.getDefaultModules = function() {
    var models = ez3dScene.layoutRules.defaultModules;
    var optionsModel = [];
    models.forEach(function (option) {
        optionsModel.push([option.id, option.name + ' ' + option.reference]);
    });
    return optionsModel;
};

// SET
/**
 * Method to set the path of the subarea.
 *
 * @param {EZModelPath} path    - The path of the subarea.
 * @param {EZModelArea} area    - The parent of the subarea.
 * @param {boolean}     clone   - Is the subarea being created as a clone?
 */
EZModelSubarea.prototype.setPath = function(path, area, clone) {
    ez3dScene.utils.destroyObject(this.path);
    // setCoordSystem
    if (path) {
        this.path = (clone)
            ? new EZModelPath(ez3dScene, path.cloneVertices())
            : path;
    } else {
        const offsetteds = area.path.getOffsetteds(area.offset, true);
        let offsettedPath;
        if (offsetteds.length > 1) {
            console.log('EZModelSubarea', 'setPath', 'multiple offsetted paths');
            offsettedPath = offsetteds[((this.index - 1) < offsetteds)
                ? (this.index - 1) : 0];
        } else if (offsetteds.length === 1) {
            offsettedPath = offsetteds[0];
        } else {
            ee.emitEvent('invalidOffset', [{args: this}]);
            console.warn('EZModelSubarea', 'setPath',
                'invalid offset, using zero instead');
            area.__data.offset = [0];
            offsettedPath = area.path;
        }
        this.path = new EZModelPath(ez3dScene, offsettedPath.cloneVertices());
        this.fromScratch = true;
    }
    // forceClockWise
    this.path.forceClockWise();
    this.path.closed = true;
    this.path.setCoordSystem('building', this, 'EZModelSubarea');
};

// UPDATE
/**
 * Method to update the height coordinate of the locations
 */
EZModelSubarea.prototype.updateHeight = function() {
    var self = this;
    var roof = this.getAncestor('EZModelRoof');
    var ratio = roof.height / this.parent.flattenOrigin.z;
    self.path.vertices.forEach(function (vertex) {
        vertex.z = Math.abs(vertex.yF);
        vertex.z *= ratio;
        vertex.z += roof.baseHeight[0];
        vertex.z = Math.abs(vertex.z);
    });
};

/**
 * Method to update the path of the subarea.
 *
 * @param {object}  offset      - {lat: delta degrees, lng: delta degrees}
 * @param {boolean} cropping    - should the path be cropped in the process?
 */
EZModelSubarea.prototype.updatePath = function(offset, cropping) {
    if (offset) {
        this.path.move(offset);
    }
    if (cropping) {
        this.path.setCoordSystem('building', this, 'EZModelSubarea', null, true);
    } else {
        // Center
        this.path.calculatePathCenter();
        // Update
        this.path.updateCalculations();
        this.path.updateCartesianCoords();
    }
    // Flatten Path
    this.path.calculateFlattenCartesian(
        this.parent.axis,
        this.parent.angle,
        this.parent.inclination,
        this.parent.flattenOrigin
    );
    this.updateHeight();
    // Offsetted
    var offsetteds = this.path.getOffsetteds(this.offset, true);
    if (offsetteds.length === 0) {
        ee.emitEvent('invalidOffset', [{args: this}]);
        console.warn('EZModelSubarea', 'updatePath', 'invalid offset, using zero instead');
        this.__data.offset = [0];
        this.path.getOffsetteds(this.offset, true);
        this.createSystem();
    }
    // Update Childrens
    if (this.system) {
        this.system.updatePath(offset);
    }
};

EZModelSubarea.prototype.updateSimpleSystem = function() {
    let data = {holders: 0, modules: 0, power: 0};
    if (this.system.grid.rows === undefined) {
        var building = this.getAncestor('EZModelBuilding');
        building.rows = (building.rows === undefined)
            ? 3 : building.rows;
        building.columns = (building.columns === undefined)
            ? 3 : building.columns;
        this.system.grid.rows = [{
            literal: Array(building.columns).fill(1)
        }];
        this.system.changeRowNumber(building.rows);
    } else if (this.system.grid.rows.length > 0 &&
        this.system.grid.rows[0].modules === undefined) {
        data = this.system.updateGrid();
    } else {
        data = this.system.getSystemInfo();
    }
    return data;
};

/**
 * Method to update and/or get the info of the system of the subarea.
 *
 * @param  {boolean} skipUpdate     - Is only the system info wanted? (skip update)
 * @param  {boolean} fromBuilding   - Is this method being called from the building?
 * @param  {boolean} movingSubarea  - Is the subarea being moved in this process?
 *
 * @return {object} Info (modules and power) of the systems updated
 */
EZModelSubarea.prototype.updateSystem = function(skipUpdate, fromBuilding, movingSubarea) {
    let data = {holders: 0, modules: 0, power: 0};
    const counter = [undefined, undefined];

    if (this.parent.populated === true && !this.disabled && !this.parent.disabled) {
        if (ez3dScene.isLoadingProject || !this.disabled && !this.parent.disabled) {
            if (ez3dScene.isLoadingProject) {
                counter[0] = this.system.getSystemInfo(true);
                counter[1] = this.system.getLiteral();
            }

            if (ez3dScene.projectType === 'simple') {
                data = this.updateSimpleSystem();
            } else if (skipUpdate) {
                data = this.system.getSystemInfo();
            } else {
                const resetGridConditions = [
                    !ez3dScene.isLoadingProject,
                    !(ez3dScene.history && ez3dScene.history.isExecutingOp),
                    (ez3dScene.active.constructor.name === 'EZModelSubarea'),
                ];
                const shouldResetGrid = resetGridConditions.every((bool) => bool);
                // console.warn('shouldResetGrid', shouldResetGrid, resetGridConditions);
                data = this.system.updateGrid(
                    false, // resetLine
                    shouldResetGrid, // resetGrid
                    false, // resetPath
                    movingSubarea // movingGrid
                );
            }
        }
        if (!fromBuilding) {
            this.getAncestor('EZModelBuilding').updateSystem(true);
        } else if (ez3dScene.isLoadingProject && counter[0].modules !== data.modules) {
            counter[0] = ['EZModelSubarea updateSystem mismatching active modules\n' +
                ' Building:' + this.parent.parent.parent.index +
                ' Roof:' + this.parent.parent.index +
                ' Area:' + this.parent.index +
                ' Subarea:' + this.index + '  (' +
                counter[0].modules + ' !== ' + data.modules + ')'];
            counter[0].push('\n' + counter[1]);
            console.warn(counter[0].join('\n'));
            ez3dScene.moduleCountErrorDialogInfo.push(
                '* ' + this.parent.parent.parent.name + ' / ' +
                this.parent.name + '\n' +
                '    ' + this.name + '\n');
        }
    }

    if (ez3dScene.isLoadingProject === true) {
        ee.addOnceListener('viewportReady', function() {
            if (ez3dScene.moduleCountErrorDialogInfo.length > 0 &&
                ez3dScene.layoutRules.scenePreferences.viewportMode !== 1) {
                if (ez3dViewport.ez3dGuiManager.widgets.includes('ez3d-widget-moduleCountError')) {
                    ee.emitEvent('widget_notification', [{
                        'name': 'moduleCountError',
                        'title': 'Modules count error',
                        'content': ez3dScene.moduleCountErrorDialogInfo.join('\n') +
                            '\n Please, use the move \nsystem tool in this \nsubarea to fix the issue'
                    }]);
                }
            }
        });
    }

    this.systemInfo = data;

    ee.emitEvent('updateSystemFunctionFinished');
    return data;
};

// UTILS
/**
 * Method to (re)create the default system ({@EZModelSystem}) of the subarea.
 */
EZModelSubarea.prototype.createSystem = function() {
    this.system = undefined;
    var offsetteds = this.path.getOffsetteds(this.offset, true);
    if (offsetteds.length > 1) {
        console.error('EZModelSubarea', 'createSystem', 'This subarea has an unvalid shape, so it is going to be splitted');
        this.splitSubarea(offsetteds, this.offset);
    } else if (offsetteds.length) {
        this.system = ez3dScene.factories['System'].createSystem(ez3dScene, this, offsetteds[0]);
    } else {
        ee.emitEvent('invalidOffset', [{args: this}]);
        console.warn('EZModelSubarea', 'createSystem', 'invalid offset, using zero instead');
        this.__data.offset = [0];
        this.system = ez3dScene.factories['System'].createSystem(ez3dScene, this);
    }
};

/**
 * Method to reset the system of the subarea
 */
EZModelSubarea.prototype.resetSystem = function() {
    this.system.updateGrid(true, true);
    this.getAncestor('EZModelBuilding').updateSystem(true);
};

EZModelSubarea.prototype.splitSubarea = function(offsetteds, offset) {
    var self = this;
    var area = this.parent;

    offsetteds.forEach(function (offsetted, index) {
        if (index === 0) {
            if (area.subareas.length > 0) {
                area.subareas.splice(index, 1);
            }
            area.subareas.push(self);
            self.fromScratchSplit = area.subareas.length - 1;
        } else {
            area.subareas.push(ez3dScene.factories['Subarea'].createSubarea(ez3dScene, area, self));
        }

        area.subareas[area.subareas.length - 1].fromScratch = true;
        // ez3dScene.addObjectToList(self);

        // TEMP
        var building = self.getAncestor('EZModelBuilding');
        var path = offsetted.getOffsetteds(offset, false, building.path.center, true)[0];
        offsetted.vertices = path.vertices;

        area.subareas[area.subareas.length - 1].setPath(offsetted, area);
        // Create System
        area.subareas[area.subareas.length - 1].system = ez3dScene.factories['System'].createSystem(
            ez3dScene,
            area.subareas[area.subareas.length - 1],
            offsetted
        );
    });
};

// CHECK
/**
 *
 *  * Method to check if row offset or module orientation has been changed
 *
 * @param {boolean} enablingDilatations value of dilatation lines enable
 *
 * @return {[boolean]}   [showWidget]
 */
EZModelSubarea.prototype.checkRowOffsetOrStaggered = function(enablingDilatations) {
    var system = ez3dScene.active.system;
    var showWidget = false;
    if (enablingDilatations === true) {
        system.grid.rows.forEach(function(row) {
            // Check module orientation and row offset
            if (row.offset.x !== 0 || row.offset.y !== 0 || system.placement !== row.placement) {
                showWidget = true;
            }
        });
        if (system.staggered.enabled) {
            showWidget = true;
        }
    }
    return showWidget;
};

/**
 * EZModelSystem
 * @class
 * @classdesc This is the **EZModelSystem** class.
 *
 * This class manages the {@link EZModelSubarea}'s system.
 *
 * @description This is the constructor of the **EZModelSystem** class.
 *
 * @param   {EZModelScene}  ez3dScene   - Main container for 3DLayout project model.
 * @param   {EZModelArea}   subarea     - The parent of the system.
 * @param   {object}        data        - JSON with the data of the system.
 * @param   {boolean}       clone       - Is the system being created as a clone?
 */
function EZModelSystem(ez3dScene, subarea, data, clone) {
    // ez3dScene = ez3dScene;
    if (clone || data === undefined) {
        EZModelObject.call(this, null);
    } else {
        EZModelObject.call(this, data.id || null);
    }

    // Main properties
    this.parent = subarea;
    this.setPath(data.path, clone);
    // @deprecated parentId
    this.parentId = this.parent.id;

    // Watched properties
    this.__data = this.getDefaultSystem(data);
    this.addWatchers();

    // Other properties
    this.grid = {};
    this.setModel();
    this.fromScratch = true;

    this.firstRowInverted = data.firstRowInverted;
    if (this.firstRowInverted === undefined) {
        this.firstRowInverted = false;
    }

    this.angle = this.getAngle(subarea);
    if (data && data.grid && data.grid.rows && data.grid.rows.length) {
        this.grid = _.cloneDeep(data.grid);
        // DEBUG FUNCTION: Print the grid literals
        // console.warn(this.getLiteral());
    }

    this.rotatedData = (data && data.rotatedData)
        ? _.cloneDeep(data.rotatedData) : undefined;
    const roofType = this.getAncestor('EZModelRoof').type;
    this.dilatationLines = ez3dScene.getModelProperty(
        'subareaByRoofType', roofType, 'dilatationLines', data);
    this.dilatationLines.col = (this.dilatationLines.col)
        ? this.dilatationLines.col : 0;
    this.dilatationLines.row = (this.dilatationLines.row)
        ? this.dilatationLines.row : 0;

    // Update
    if (!ez3dScene.isLoadingProject &&
        this.getAncestor('EZModelBuilding').isCreated) {
        this.updatePath();
        if (clone) {
            this.activePoint = EZModelUtils.omitDeep(data.activePoint);
        }
    }
}
EZModelSystem.prototype = Object.create(EZModelObject.prototype);
EZModelSystem.prototype.constructor = EZModelSystem;

// GET CONSTRUCTOR
/**
 * Method to generate and/or asign the watched properties of the system.
 *
 * @param  {object} data    - JSON with the data of the system.
 *
 * @return {object} JSON with the watched properties of the system
 */
EZModelSystem.prototype.getDefaultSystem = function(data) {
    var properties = {}; data = (data) ? data : {};
    var type = this.getAncestor('EZModelRoof').type;
    var area = this.getAncestor('EZModelArea');

    // getDefault
    properties.azimuth = ez3dScene.getModelProperty('subareaByRoofType', type, 'azimuth', data);
    properties.azimuth = (properties.azimuth === '') ? (180 - area.angle) : properties.azimuth;

    properties.inclination = ez3dScene.getModelProperty('subareaByRoofType', type, 'inclination', data);
    properties.modelId = ez3dScene.getModelProperty('subareaByRoofType', type, 'modelId', data);
    properties.placement = ez3dScene.getModelProperty('subareaByRoofType', type, 'placement', data);
    properties.structure = ez3dScene.getModelProperty('subareaByRoofType', type, 'structure', data);

    properties.useShadowsCalculation = ez3dScene.getModelProperty('subareaByRoofType', type, 'useShadowsCalculation', data);
    properties.staggered = ez3dScene.getModelProperty('subareaByRoofType', type, 'staggered', data);
    properties.sails = ez3dScene.getModelProperty('subareaByRoofType', type, 'sails', data);

    // inset
    properties.totalInset = ez3dScene.getModelProperty('subareaByRoofType', type, 'totalInset', data);
    if (data.inset || properties.totalInset === undefined) {
        properties.inset = ez3dScene.getModelProperty('subareaByRoofType', type, 'inset', data);
        properties.totalInset = {x: undefined, y: undefined};
    } else {
        properties.inset = {x: undefined, y: undefined};
    }

    // generate
    properties.color = (data.color === undefined) ? 'blue' : data.color;

    // restrictions
    properties.useShadowsCalculation = (ez3dScene.projectType === 'simple') ? false : properties.useShadowsCalculation;

    return properties;
};

/**
 * Method to get the system's children.
 *
 * @param  {boolean} returnObject   - **Optional.** The array should be of objects instead of ids?
 * @param  {boolean} includeParent  - **Optional.** The parent should be included in the array?
 *
 * @return {array} - Array of the system's children.
 */
EZModelSystem.prototype.getChildren = function (returnObject, includeParent) {
    // @TODO replace for a proper import whenever we use ES6+ modules
    const uniteSets = EZModelUtils.uniteSets;

    var children = new Set();

    if (includeParent) {
        children.add((returnObject) ? this : this.id);
    }

    children = uniteSets(children, this.path.getChildren(returnObject, true));

    return children;
};

/**
 * Method to generate a string with the grid's literal representation.
 *
 * @param  {[type]} detailed [description]
 *
 * @return {[type]}          [description]
 */
EZModelSystem.prototype.getLiteral = function(detailed) {
    var literal = [];
    if (this.grid && this.grid.rows) {
        var modules = 0;
        this.grid.rows.forEach((row) => {
            literal.push('\n');
            row.literal.forEach((module, colIndex) => {
                if (detailed && row.modules[colIndex].type === 'collision') {
                    literal[literal.length - 1] += '-';
                } else {
                    literal[literal.length - 1] += module;
                }
                modules = (module === 1) ? modules + 1 : modules;
            });
        });
        if (detailed) {
            literal.push(' active:' + modules + ' ]',
                'cols:' + this.grid.rows[0].literal.length,
                '[ rows:' + this.grid.rows.length + ' ',
                '[ angle:' + this.angle + ']\n');
        }
        literal.push('EZModelSystem getLiteral\n');
    }
    return literal.reverse().join('');
};

// UPDATE
/**
 * Method to update the path of the system.
 *
 * @param {object} offset - {lat: delta degrees, lng: delta degrees}
 */
EZModelSystem.prototype.updatePath = function(offset) {
    var temp = {x: this.path.vertices[0].xF, y: this.path.vertices[0].yF};
    if (offset) {
        this.path.move(offset);
    } else {
        this.path.calculatePathCenter();
        this.path.updateCartesianCoords();
    }
    // Update Flatten Path
    var area = this.getAncestor('EZModelArea');
    this.path.calculateFlattenCartesian(area.axis, area.angle, area.inclination, area.flattenOrigin);
    area = undefined;

    // Update Active Point
    if (offset && this.activePoint) {
        this.activePoint.x += this.path.vertices[0].xF - temp.x;
        this.activePoint.y += this.path.vertices[0].yF - temp.y;
    }
};

/**
 * Method to update (and calculate) the grid (rows of modules) of the system.
 *
 * @param  {boolean} resetLine  - Reset the rows (lines) individual properties?
 * @param  {boolean} resetGrid  - Reset the grid (modules) properties (pos & type)?
 * @param  {boolean} resetPath  - Reset the path shape and re-calculate the system?
 * @param  {boolean} movingGrid - Is the system's grid being moved during the update?
 *
 * @return {object} - Info (modules and power) of the updated system.
 */
EZModelSystem.prototype.updateGrid = function(resetLine, resetGrid, resetPath, movingGrid) {
    // STEP: console.log('starting updateGrid');
    ee.emitEvent('updateProgressBar', [{
        label1: 'Starting updating grid'
    }]);
    if (!this.model || this.model.id !== this.modelId) {
        this.setModel();
    }
    if (resetPath) {
        if (this.setPath()) return undefined;
        this.updatePath();
    }
    // Every time the dilatation lines or staggered are enabled,
    // movingGrid must be true (except if the grid is empty)
    resetGrid = (Object.keys(this.grid).length) ? resetGrid : true;
    movingGrid = (this.dilatationLines.enabled && !resetGrid)
        ? true : movingGrid;
    movingGrid = (resetLine && this.staggered.enabled) ? true : movingGrid;
    movingGrid = (ez3dScene.isLoadingProject &&
        (this.dilatationLines.enabled || this.staggered.enabled))
        ? true : movingGrid;

    // Preparing the properties
    // STEP: console.log('updateGrid calculations');
    ee.emitEvent('updateProgressBar', [{
        label1: 'Updating grid calculations'
    }]);
    this.angle = this.getAngle();
    this.boundingBox = this.getBoundingBox();
    this.pivotingPoint = this.getPivotingPoint();

    var inset = this.getInset();
    if (inset !== this.__data.inset) {
        this.__data.inset = inset;
        this.calculateTotalInset();
    }

    // Preparing the grid
    if (resetGrid && ez3dScene.projectType !== 'simple') {
        this.grid = {};
        this.activePoint = undefined;
    }
    this.grid.size = {
        x: this.boundingBox[3].x - this.boundingBox[0].x,
        y: this.boundingBox[0].y - this.boundingBox[1].y
    };
    this.startingPoint = this.getStartingPoint();
    this.grid.offset = this.getOffset(this.grid.offset);

    // DRAW GRID
    /*
    if (ez3dScene.context === 'subarea') {
        this.areaC = (ez3dScene.projectType === 'simple')
            ? ez3dViewport.svgElements.svgSimple : ez3dViewport.svgElements.svgArea;
        this.container = d3.select('#' + this.areaC.name + '-elementsGroup');
        this.rotatedGroup = this.container.append('g')
            .attr('class', 'rotatedPoints');
        this.areaC.drawRotatedSpaceInRotatedTime(this.rotatedGroup);
    }
    */

    // POPULATE MODULES
    // STEP: console.log('populateGrid');
    this.populateGrid(resetLine, movingGrid);
    // this.drawGrid();


    // Apply stagger and/or dilatation lines
    // STEP: console.log('updateRowOffset');
    this.updateRowOffset();


    // Adjusting (snap) grid position
    if (ez3dScene.projectType !== 'simple' && this.grid.rows.length > 1) {
        // STEP: console.log('calculateSpace');
        ee.emitEvent('updateProgressBar', [{
            label1: 'Calculating space'
        }]);
        this.calculateSpace(movingGrid);

        // STEP: console.log('checkGrid');
        ee.emitEvent('updateProgressBar', [{
            label1: 'Checking grid'
        }]);
        this.checkGrid();
    }

    // STEP: console.log('getFinishingPoint');
    ee.emitEvent('updateProgressBar', [{
        label1: 'Getting finishing point'
    }]);
    this.finishingPoint = this.getFinishingPoint();

    // Calculate draw rotated dilatation lines
    if (this.dilatationLines.enabled) {
        this.calculateDilatationLinesCoords();
    }
    // this.drawGrid();

    // Update modules (Center grid, Check collisions & Rotate grid)
    // STEP: console.log('updateModules');
    ee.emitEvent('updateProgressBar', [{
        label1: 'Updating modules'
    }]);
    this.updateModules(true);
    // this.drawGrid();

    // Save the rotated grid info
    this.rotatedData = this.getRotatedData();

    // SIMPLE MODE: Resizing Building Path
    if (ez3dScene.projectType === 'simple') {
        this.changePathSize(this.finishingPoint);
    }

    // Cleaning up
    delete this.boundingBox;
    delete this.rotatedPath;
    delete this.pivotingPoint;
    delete this.startingPoint;
    delete this.finishingPoint;

    // STEP: console.log('finish updateGrid');

    ee.emitEvent('updateGridFunctionFinished');
    // Return the system info.
    return this.getSystemInfo();
};

// SET
/**
 * Method to set the system's model properties (using the modelID system's property)
 *
 * @param {string}  [modelId]   - ID of the new modelID to be assigned.
 */
EZModelSystem.prototype.setModel = function(modelId) {
    if (modelId) {
        this.__data.modelId = modelId;
    }
    this.model = ez3dScene.getModuleModelbyId(this.modelId);
    this.model.length = (EZModelUtils.isNumber(this.model.length))
        ? parseFloat(this.model.length) : this.model.length;
    this.model.width = (EZModelUtils.isNumber(this.model.width))
        ? parseFloat(this.model.width) : this.model.width;

    // Swap length and width values if length < width
    if (this.model.length < this.model.width) {
        this.model.length = [this.model.width, this.model.width = this.model.length][0];
    }
    this.getInclinatedModule();

    // update inset
    if (this.__data.inset.x === undefined && this.__data.totalInset.x === undefined) {
        this.__data.inset = {x: 0, y: 0};
    } else if (this.__data.inset.x === undefined) {
        this.calculateInset();
    }
    this.calculateTotalInset();
};
/**
 * Method to set the path of the system.
 *
 * @param {EZModelPath} path    - The path of the system.
 * @param {boolean} clone       - Is the system being created as a clone?
 *
 * @return {boolean} - Is this path unvalid and going to be splitted?
 */
EZModelSystem.prototype.setPath = function(path, clone) {
    EZModelUtils.destroyObject(this.path);
    // setCoordSystem
    var pathSplit = false;
    if (path) {
        this.path = (clone)
            ? new EZModelPath(ez3dScene, path.cloneVertices())
            : path;
    } else {
        var subarea = this.parent;
        const offsetteds = subarea.path.getOffsetteds(subarea.offset, true);
        let offsettedPath;
        if (offsetteds.length > 1) {
            console.error('EZModelSystem', 'setPath',
                'This subarea has an unvalid shape, so it is going to be splitted');
            subarea.splitSubarea(offsetteds, subarea.offset);
            pathSplit = true;
        } else if (offsetteds.length === 1) {
            offsettedPath = offsetteds[0];
        } else {
            offsettedPath = subarea.path;
        }
        this.path = new EZModelPath(ez3dScene, offsettedPath.cloneVertices());
    }
    if (!pathSplit) {
        // forceClockWise
        this.path.forceClockWise();
        this.path.closed = true;
        this.path.setCoordSystem('building', this, 'EZModelSystem');
    }
    return pathSplit;
};

// GET BASIC
/**
 * Method to get the angle needed to proyect the rotated flatten space.
 *
 * @param  {EZModelSubarea} [subarea]   - The subarea to be used to get the angle.
 *
 * @return {float} - The angle (in degrees) of the rotated flatten space.
 */
EZModelSystem.prototype.getAngle = function(subarea) {
    var area = (subarea) ? subarea.parent : this.getAncestor('EZModelArea');
    this.getInclinatedModule();
    return EZModelUtils.cleanDegrees(180 - area.angle - this.azimuth);
};

/**
* Method to get the inclinated module depending on their placement.
*/
EZModelSystem.prototype.getInclinatedModule = function() {
    var inclination = Math.cos(EZModelUtils.degToRad(this.inclination));
    this.model['portrait'] = {x: this.model.width, y: this.model.length * inclination};
    this.model['landscape'] = {x: this.model.length, y: this.model.width * inclination};
};

/**
 * Method to get the bounding box of the grid (in the rotated flatten space).
 *
 * @return {object} - Bounding box of the grid.
 */
EZModelSystem.prototype.getBoundingBox = function() {
    var points = [[], []];
    // var systemPath = EZModelUtils.omitDeep(this.path.vertices);
    var systemPath = [];
    this.path.vertices.forEach((vertex) => {
        systemPath.push({
            x: vertex.x,
            xF: vertex.xF,
            y: vertex.y,
            yF: vertex.yF
        });
    });

    // Rotating the system
    EZModelUtils.destroyObject(this.rotatedPath);
    this.rotatedPath = this.getRotatedPath(systemPath, -this.angle);
    systemPath = undefined;

    this.rotatedPath.forEach((point) => {
        points[0].push(point.x);
        points[1].push(point.y);
    });

    var box = {
        x: d3.min(points[0]),
        y: d3.max(points[1]),
        w: d3.max(points[0]),
        h: d3.min(points[1])
    };

    box = [
        {x: box.x, y: box.y},
        {x: box.x, y: box.h},
        {x: box.w, y: box.h},
        {x: box.w, y: box.y}
    ];
    return box;
};

/**
 * Method to get the proyection of a polygon in the rotated flatten space.
 *
 * @param  {array} vertices - Array with the vertices to rotate.
 * @param  {float} angle    - Desired rotation angle to apply.
 *
 * @return {array}  - Array with the proyected (rotated) vertices.
 */
EZModelSystem.prototype.getRotatedPath = function(vertices, angle) {
    var rotatedPath = [];
    var point;
    vertices.forEach((vertex) => {
        if (vertex.x !== undefined || vertex.xF !== undefined) {
            point = {
                x: (vertex.xF === undefined) ? vertex.x : vertex.xF,
                y: (vertex.yF === undefined) ? vertex.y : vertex.yF
            };
            point = EZModelUtils.changeAxis(point, EZModelUtils.degToRad(angle));
        } else {
            point = vertex;
        }
        rotatedPath.push(point);
    });
    return rotatedPath;
};

/**
 * Method to calculate the pivonting point of the grid.
 * The pivoting point is the one used to rotate the flatten space.
 *
 * @return {object} - The pivoting point of the rotated grid.
 */
EZModelSystem.prototype.getPivotingPoint = function() {
    var pivotingPoint = (this.rotatedData) ? this.rotatedData.pivotingPoint : undefined;

    if (pivotingPoint === undefined) {
        pivotingPoint = {};
    } else {
        this.fromScratch = false;
        var point = this.getRotatedPath([pivotingPoint], -this.angle)[0];
        pivotingPoint.w = point.x;
        pivotingPoint.h = point.y;
    }

    pivotingPoint.x = (this.boundingBox[0].x + this.boundingBox[3].x) / 2;
    pivotingPoint.y = (this.boundingBox[0].y + this.boundingBox[1].y) / 2;

    pivotingPoint.w = (pivotingPoint.w === undefined) ? pivotingPoint.x : pivotingPoint.w;
    pivotingPoint.h = (pivotingPoint.h === undefined) ? pivotingPoint.y : pivotingPoint.h;

    // console.warn('System', 'getPivotingPoint', pivotingPoint);
    return pivotingPoint;
};

// INSET
/**
 * Method to calculate the appropiate inset using the Shadow's Calculation.
 *
 * @return {object} - The appropiate inset of the grid (not auto assigned).
 */
EZModelSystem.prototype.getInset = function() {
    var inset = _.cloneDeep(this.inset);

    if (!inset || this.useShadowsCalculation) {
        if (inset === undefined) inset = {x: 0};
        inset.y = this.getShadowInset();
    }

    if (inset.x < 0 || inset.y < 0) {
        inset.x = (inset.x < 0) ? 0 : inset.x;
        inset.y = (inset.y < 0) ? 0 : inset.y;
    }
    return inset;
};

EZModelSystem.prototype.getShadowInset = function() {
    const {degToRad} = EZModelUtils;
    const debug = false;

    let shadowInset = 0;
    // 0) Ready Base Variables
    const moduleHeight = (this.placement === 'portrait')
        ? this.model.length : this.model.width;
    const moduleInclination = degToRad(this.inclination);
    const moduleAzimuth = degToRad(this.azimuth);
    const area = this.parent.parent;
    const areaInclination = degToRad(area.inclination);
    const areaAzimuth = degToRad(area.azimuth);
    const latitude = degToRad(Math.abs(this.path.center.lat));
    if (debug) {
        console.warn('moduleHeight', moduleHeight);
        console.log('moduleInclination', this.inclination, moduleInclination);
        console.log('moduleAzimuth', this.azimuth, moduleAzimuth);
        console.log('areaInclination', area.inclination, areaInclination);
        console.log('areaAzimuth', area.azimuth, areaAzimuth);
        console.log('latitude', Math.abs(this.path.center.lat), latitude);
    }

    const sunRange = [degToRad(-30), degToRad(30)];
    const maxShadow = degToRad(-23.45);
    const shadowProyections = sunRange.map((sunAngle) => {
        if (debug) console.warn('sunAngle', sunAngle);
        // 1) Calculate Sun Inclination
        const sunInclination = Math.asin(
            Math.sin(latitude) * Math.sin(maxShadow) +
            Math.cos(latitude) * Math.cos(maxShadow) * Math.cos(sunAngle)
        );
        if (debug) console.log('sunInclination', sunInclination);
        // 2) Calculate Sun Vector
        const sunVector = {
            x: Math.sin(-sunAngle),
            y: -Math.cos(sunInclination),
            z: Math.sin(sunInclination)
        };
        if (debug) console.log('FIRST sunVector', sunVector);
        const sunVectorModule = Math.sqrt(
            Math.pow(sunVector.x, 2) +
            Math.pow(sunVector.y, 2) +
            Math.pow(sunVector.z, 2)
        );
        if (debug) console.log('sunVectorModule', sunVectorModule);
        sunVector.x /= sunVectorModule;
        sunVector.y /= sunVectorModule;
        sunVector.z /= sunVectorModule;
        if (debug) console.log('FINAL sunVector', sunVector);
        // 3) Calculate Area sun vector
        const areaAngle = -Math.sin(areaAzimuth) * sunVector.x -
            Math.cos(areaAzimuth) * sunVector.y;
        const areaSunVector = {
            x: Math.sin(areaAzimuth) * sunVector.y -
                Math.cos(areaAzimuth) * sunVector.x,
            y: Math.cos(areaInclination) * areaAngle +
                Math.sin(areaInclination) * sunVector.z,
            z: -Math.sin(areaInclination) * areaAngle +
                Math.cos(areaInclination) * sunVector.z
        };
        if (debug) console.log('areaSunVector', areaSunVector);
        if (areaSunVector.z === 0) return 0;
        // 4) Calculate Shadow proyection vector
        const shadowHeight = -moduleHeight *
            Math.sin(moduleInclination) / areaSunVector.z;
        const shadowProyectionVector = {
            x: areaSunVector.x * shadowHeight,
            y: areaSunVector.y * shadowHeight
        };
        if (debug) console.log('shadowProyectionVector', shadowProyectionVector);
        // 5) Calculate Shadow proyection inset
        const totalAzimuth = moduleAzimuth - areaAzimuth;
        const shadowProyection =
            Math.sin(totalAzimuth) * shadowProyectionVector.x +
            Math.cos(totalAzimuth) * shadowProyectionVector.y;
        if (debug) console.log('shadowProyection', shadowProyection);
        return shadowProyection;
    });
    if (debug) console.warn('shadowProyections', shadowProyections);
    shadowInset = _.max(shadowProyections);
    if (debug) console.warn('shadowInset', shadowInset);
    return shadowInset;
};

/**
 * Method to calculate the total inset using the model's size and system's inset
 */
EZModelSystem.prototype.calculateTotalInset = function() {
    this.__data.totalInset = {
        x: this.model[this.placement].x,
        y: this.model[this.placement].y
    };

    if (this.structure === 'EW') {
        this.__data.totalInset.y *= 2;
    }

    this.__data.totalInset.x += this.inset.x;
    this.__data.totalInset.y += this.inset.y;
};
/**
 * Method to calculate the total inset using the model's size and system's total inset
 */
EZModelSystem.prototype.calculateInset = function() {
    this.__data.inset = {
        x: this.model[this.placement].x,
        y: this.model[this.placement].y
    };

    if (this.structure === 'EW') {
        this.__data.inset.y *= 2;
    }

    this.__data.inset.x = this.totalInset.x - this.__data.inset.x;
    if (this.__data.inset.x < 0) {
        this.__data.inset.x = 0;
    }

    this.__data.inset.y = this.totalInset.y - this.__data.inset.y;
    if (this.__data.inset.y < 0) {
        this.__data.inset.y = 0;
    }
};

// LOAD
/**
 * Method to calculate the starting point of the grid.
 * The starting point is where the first module starts.
 *
 * @return {object} - The starting point of the grid.
 */
EZModelSystem.prototype.getStartingPoint = function() {
    var startingPoint = {
        x: this.boundingBox[0].x,
        y: this.boundingBox[0].y
    };

    return startingPoint;
};
/**
 * Method to calculate and update the offset of the grid.
 *
 * @param {object}  offset  - Original offset of the grid.
 *
 * @return {object} - The offset of the grid.
 */
EZModelSystem.prototype.getOffset = function(offset) {
    var gridOffset = {x: 0, y: 0};
    if (offset && ez3dScene.projectType !== 'simple') {
        gridOffset = offset;
    }
    return gridOffset;
};

// POPULATE
/**
 * Method to populate (generate the modules) of the grid's rows.
 *
 * @param  {boolean} resetLine  - Reset the rows (lines) individual properties?
 * @param  {boolean} movingGrid - Is the system's grid being moved during the update?
 */
EZModelSystem.prototype.populateGrid = function(resetLine, movingGrid) {
    // console.log('populateGrid');
    // POPULATE ROWs (Lines)
    var sorting = {row: 0, col: 0};
    if (this.grid.rows) {
        sorting.y = this.grid.rows.length;
    } else {
        this.grid.rows = [{}];
        sorting.y = 0;
    }
    this.grid.populatingPoint = {x: 0, y: 0};

    // START DRAW
    /*
    if (ez3dScene.context === 'subarea') {
        this.populatingPointGroup = this.areaC.drawLocation(
            {
                x: this.grid.populatingPoint.x + this.startingPoint.x,
                y: this.startingPoint.y - this.grid.populatingPoint.y
            },
            this.container
        );
        this.populatingPointGroup.select('circle')
            .attr('fill', 'blue')
            .attr('r', 0.2);

        this.modulesGroup = this.container.append('g');
    }
    */
    // END DRAW

    // Calculate estimation of rows number for progress bar
    var estimatedRowsNumber = this.estimateRowsNumber();
    ee.emitEvent('resetProgressBar');

    for (var row = 0; row <= this.grid.rows.length; row++) {
        // STEP: console.log('    populate row ' + row + '/' + (this.grid.rows.length-1));
        ee.emitEvent('updateProgressBar', [{
            label1: 'Populating modules',
            label2: 'Row ' + (row + 1) + '/' + estimatedRowsNumber,
            value: 100 / estimatedRowsNumber
        }]);
        // if (ez3dScene.context === 'subarea') {
        //     this.rowGroup = this.modulesGroup.append('g');
        // }

        var line = this.grid.rows[row];
        if (!line.literal) {
            line.literal = [];
        }
        // Row inclination
        line.inclination = (line.inclination && !resetLine) ? line.inclination : this.inclination;

        // Row placement
        line.placement = (line.placement && !resetLine) ? line.placement : this.placement;

        // Row structure
        if (this.structure === 'Standard' || row === 0) {
            line.structure = (this.firstRowInverted) ? 'Inverted' : 'Standard';
        } else {
            line.structure = (this.grid.rows[row - 1].structure === 'Standard') ? 'Inverted' : 'Standard';
        }

        // Row offset
        line.offset = {
            x: (line.offset && line.offset.x && !resetLine) ? line.offset.x : 0,
            y: (line.offset && line.offset.y && !resetLine) ? line.offset.y : 0
        };
        this.grid.populatingPoint.x = line.offset.x;
        line.size = {y: 0, start: this.grid.populatingPoint.y};
        this.grid.populatingPoint.y += line.offset.y;

        // Updating modules
        // line.oldModules = (line.modules && !resetLine) ? line.modules : undefined;
        line.modules = [];

        // Updating sorting
        sorting.col = 0;
        sorting.x = line.literal.length;

        // POPULATE COLs (Modules)
        this.populateModules(sorting, row, line, movingGrid);
        delete line.oldModules;

        // Adding vertical inset
        this.grid.populatingPoint.y += line.size.y;
        if (this.structure === 'Standard' || line.structure !== 'Standard') {
            this.grid.populatingPoint.y += this.inset.y;
            line.size.y += this.inset.y;
        }

        if (sorting.row === 2) {
            sorting.row--;
        } else {
            if (sorting.row === 1) {
                this.addRows(this.grid.rows, row);
                sorting.row--;
            }

            // Looking for vertical empty space
            var nextRow = this.grid.rows[row + 1];
            if (nextRow === undefined) {
                line.size.next = this.model[this.placement].y;
            } else if (nextRow.inclination === undefined) {
                line.size.next = this.model[nextRow.placement || this.placement].y;
            } else {
                line.size.next = ((nextRow.placement || this.placement) === 'landscape')
                    ? this.model.width : this.model.length;
                line.size.next *= Math.cos(EZModelUtils.degToRad(nextRow.inclination));
            }

            if (ez3dScene.projectType !== 'simple') {
                if (this.grid.populatingPoint.y + line.size.next > this.grid.size.y) {
                    if (this.fromScratch) { // && nextRow === undefined
                        this.grid.rows.length = row + 1;
                    }
                } else if (nextRow === undefined && !this.grid.delta) {
                    // Adding rows
                    if (movingGrid || !sorting.y || this.structure !== 'Standard') {
                        this.grid.rows.push({});
                    } else if (this.grid.populatingPoint.y + (2 * line.size.next) + this.inset.y < this.grid.size.y) {
                        this.grid.rows.push({}); this.grid.rows.push({});
                        sorting.row = 2;
                    }
                }
            }
        }

        // Update size
        delete line.size.next;
        line.size.x = this.grid.populatingPoint.x;

        // Stoping the ROW iteration
        if (row === this.grid.rows.length - 1) {
            row++;
            // Correct the last row inset
            if (this.structure === 'Standard' || line.structure !== 'Standard') {
                this.grid.populatingPoint.y -= this.inset.y;
            }
        }

        // // this.drawGrid();
    }
};

/**
  * Method to calculate an estimation of the number of rows in the system.
  *
  * @return {number} - number of rows.
  */
EZModelSystem.prototype.estimateRowsNumber = function() {
    var rowsNumber = 0;

    ee.emitEvent('updateProgressBar', [{
        label1: 'Estimating number of rows'
    }]);

    // Check if the grid has been reset to estimate rows
    if (this.grid.rows.length === 1) {
        var rowHeight = 0;

        // Calculate module height
        rowHeight = this.model[this.placement].y;

        // Calculate inset
        rowHeight += this.inset.y;

        while (rowsNumber * rowHeight <= this.grid.size.y) {
            rowsNumber++;
        }
    } else {
        rowsNumber = this.grid.rows.length;
    }

    return rowsNumber;
};

/**
 * Method to populate each of the rows's columns (modules).
 *
 * @param  {object}     sorting     - Regulator the process of adding rows and cols.
 * @param  {integer}    row         - The current row index (inside the grid's rows).
 * @param  {object}     line        - JSON with the data of the (current) row.
 * @param  {boolean}    movingGrid  - Is the system's grid being moved during the update?
 */
EZModelSystem.prototype.populateModules = function(sorting, row, line, movingGrid) {
    line.cos = Math.cos(EZModelUtils.degToRad(line.inclination));
    // console.log('populateModules');
    for (var col = 0; col <= line.literal.length; col++) {
        // console.log(col, line.literal.length);
        if (line.literal[col] === undefined) {
            line.literal.push((sorting.y) ? 0 : 1);
        }
        var module = line.literal[col];

        if (module >= 0) {
            // Adding the module to the grid
            line.modules.push({
                id: 'id_' + row + ':' + col + '_' + this.id,
                col: col,
                row: row,
                type: (module === 0) ? 'empty' : 'module',
                x: this.startingPoint.x + this.grid.populatingPoint.x,
                y: this.startingPoint.y - this.grid.populatingPoint.y
            });
        }

        // Update filled space
        module = line.modules[line.modules.length - 1];
        if (Array.isArray(line.placement)) {
            module.w = this.model[line.placement[col]].x;
            module.h = (line.placement[col] === 'landscape') ? this.model.width : this.model.length;
            if (line.placement[col + 1]) {
                module.next = this.model[line.placement[col + 1]].x;
            }
        } else {
            module.w = this.model[line.placement].x;
            module.h = (line.placement === 'landscape') ? this.model.width : this.model.length;
        }

        // Update values
        this.grid.populatingPoint.x += module.w;
        module.h *= line.cos;
        line.size.y = (module.h > line.size.y) ? module.h : line.size.y;

        // Adding horizontal inset
        this.grid.populatingPoint.x += this.inset.x;

        if (sorting.col === 2) {
            sorting.col--;
        } else {
            if (sorting.col === 1) {
                this.addColumns(line, col);
                sorting.col--;
            }
            // Looking for horizontal empty space
            var nextCol = line.literal[col + 1];
            if (!module.next) {
                module.next = this.model[line.placement].x;
            }
            if (ez3dScene.projectType !== 'simple') {
                if (this.grid.populatingPoint.x + module.next > this.grid.size.x) {
                    //
                    if (this.fromScratch) {
                        line.literal.length = col + 1;
                    }
                } else if (nextCol === undefined && !this.grid.delta) {
                    // Adding new columns
                    if (movingGrid) {
                        line.literal.push(0);
                    } else if (!sorting.x && (!row || !col || col < this.grid.rows[row - 1].literal.length - 1)) {
                        line.literal.push((sorting.y) ? 0 : 1);
                    } else if (this.grid.populatingPoint.x + (2 * module.next) + this.inset.x < this.grid.size.x) {
                        line.literal.push(0); line.literal.push(0);
                        sorting.col = 2;
                    }
                }
            }
        }
        delete module.next;

        // Stoping the COL iteration
        if (col === line.literal.length - 1) {
            col++;
            this.grid.populatingPoint.x -= this.inset.x;
        }

        // this.drawGrid();
    }
    delete line.cos;
};

// OFFSET
/**
 * Method to apply staggering and/or dilatation lines to the grid.
 */
EZModelSystem.prototype.updateRowOffset = function() {
    var lines = this.dilatationLines;

    if (!lines.custom) {
        EZModelUtils.destroyObject(lines.log);
        lines.log = {rows: [], cols: []};
        /*
        CUSTOM LOG EXPLANATION
        lines.log {
            'rows': [[DL height, [col position, row position], [col position, row position]]],
            'cols': [[DL width, [col position, row position], [col position, row position]]]
        }

        EXAMPLE:
        - ROWS (drawing horizontal DL): in y:5 from x:0 to x:4, in y:10 from x:4 to x:8, ...
        - COLS (drawing vertical DL): in x:2 from y:0 to y:5, in x:4 from y:5 to y:10, ...
        lines.log = {
            'rows': [[0.35, [0, 5], [4, 10], [8, 12]]],
            'cols': [[0.35, [2, 0], [4, 5], [8, 10]]]
        };
        */
    }

    // Add inset to DL height and width
    if (ez3dScene.layoutRules.scenePreferences.includeInsetInDL) {
        lines.h += this.inset.y;
        lines.w += this.inset.x;
    }

    if (this.staggered.enabled || lines.enabled) {
        if (this.staggered.enabled) {
            // Update Max Staggered Offset
            this.__data.staggered.offsetMax = (this.placement === 'landscape')
                ? this.model.length : this.model.width;
            this.__data.staggered.offsetMax += this.inset.x;
        }

        var temp = 0;
        // var dilatation: x and y are counters, w and h are accumulators
        var dilatation = {
            x: 0, y: 0,
            w: 0, h: 0
        };

        // ROW Iteration
        var iterator = (this.structure === 'EW') ? 2 : 1;
        lines.row = (this.structure === 'EW' && lines.row % 2 !== 0) ? lines.row + 1 : lines.row;
        ee.emitEvent('resetProgressBar');

        for (var row = 0; row < this.grid.rows.length; row++) {
            var line = this.grid.rows[row];

            ee.emitEvent('updateProgressBar', [{
                label1: this.staggered.enabled ? 'Calculating staggered' : 'Calculating dilatation lines',
                label2: 'Row ' + (row + 1) + '/' + this.grid.rows.length,
                value: 100 / this.grid.rows.length
            }]);

            // Staggered
            var staggeredOffset = 0;
            if (this.staggered.enabled) {
                if (this.staggered.offset) {
                    staggeredOffset = this.staggered.offset;
                } else {
                    staggeredOffset = (line.placement === 'landscape')
                        ? this.model.length / 2 : this.model.width / 2;
                    staggeredOffset += this.inset.x / 2;
                }
            }
            // Dilatation Line Y
            if (lines.enabled && !lines.custom) {
                if (iterator === 1 || (iterator === 2 && row % 2 === 0)) {
                    var lineSize = line.size.y;
                    if (iterator === 2) {
                        lineSize += (row + 1 < this.grid.rows.length)
                            ? this.grid.rows[row + 1].size.y : 0;
                    }


                    if (row >= lines.row && (dilatation.y + lineSize > lines.y || (row && row === lines.row))) {
                        dilatation.h += lines.h - this.inset.y;
                        dilatation.y = lineSize;
                        lines.log.rows.push([lines.h, [0, row]]);
                        // STEP: console.log('    added row ' + row);
                    } else {
                        dilatation.y += lineSize;
                    }
                }
            }

            // COL Iteration
            dilatation.x = 0;
            dilatation.w = 0;

            var module = {};
            for (var col = 0; col < line.modules.length; col++) {
                module = line.modules[col];
                // Staggered
                if (this.staggered.enabled) {
                    if (row % 2 === 1 && !this.staggered.alternate ||
                        row % 2 === 0 && this.staggered.alternate) {
                        module.x += staggeredOffset;
                    }
                }
                // Dilatation Line X
                if (lines.enabled) {
                    if (lines.custom) {
                        dilatation.h = 0;
                        lines.log['rows'].forEach((drow) => {
                            for (temp = 1; temp < drow.length; temp++) {
                                if (row >= drow[temp][1] && col >= drow[temp][0] &&
                                    (temp === (drow.length - 1) || col < drow[temp + 1][0])) {
                                    // dilatationLines.log.rows arrays must be orderBy [0]
                                    dilatation.h += (drow[0] - this.inset.y);
                                }
                            }
                        });
                        dilatation.w = 0;
                        lines.log['cols'].forEach((dcol) => {
                            for (temp = 1; temp < dcol.length; temp++) {
                                if (col >= dcol[temp][0] && row >= dcol[temp][1] &&
                                    (temp === (dcol.length - 1) || row < dcol[temp + 1][1])) {
                                    // dilatationLines.log.cols arrays must be orderBy [1]
                                    dilatation.w += (dcol[0] - this.inset.x);
                                }
                            }
                        });
                    } else if (col >= lines.col && (dilatation.x + module.w > lines.x || (col && col === lines.col))) {
                        dilatation.w += lines.w - this.inset.x;
                        dilatation.x = (module.w + this.inset.x);
                        if (row === 0) {
                            lines.log.cols.push([lines.w, [col, 0]]);
                            // STEP: console.log('    added col ' + col);
                        }
                    } else {
                        dilatation.x += (module.w + this.inset.x);
                    }
                    // UPDATE MODULE COORDS
                    module.x += dilatation.w;
                    module.y -= dilatation.h;
                }
            }
            module = undefined;

            line.size.x += dilatation.w;
            if (row) this.grid.rows[row - 1].size.y += dilatation.h;
        }
        this.grid.populatingPoint.x += dilatation.w;
        this.grid.populatingPoint.y += dilatation.h;
    }

    // Reset dilatation lines height and width
    if (ez3dScene.layoutRules.scenePreferences.includeInsetInDL) {
        lines.h -= this.inset.y;
        lines.w -= this.inset.x;
    }
    lines = undefined;
};
/**
 * Method to calculate the offset necessary to correctly adjust the grid.
 *
 * @param   {boolean}   movingGrid  - Is the system's grid being moved?
 *
 * @return {object} - The adjustment stored as the new grid offset.
 */
EZModelSystem.prototype.calculateSpace = function(movingGrid) {
    var adjustment = {
        x: this.grid.offset.x,
        y: this.grid.offset.y
    };

    // calculate margin between bounding box and modules grid
    this.grid.offset.w = (this.grid.size.x - this.grid.populatingPoint.x) / 2;
    this.grid.offset.h = -(this.grid.size.y - this.grid.populatingPoint.y) / 2;

    if (this.grid.delta) {
        // v2 Adjustment (stored as grid.delta in the EZ-Factory-System)
        var module = this.grid.rows[this.grid.delta.row].modules[this.grid.delta.col];
        if (module !== undefined) {
            this.grid.delta = this.getRotatedPath([this.grid.delta], -this.angle)[0];
            adjustment.x = this.grid.delta.x - (module.x + module.w / 2);
            adjustment.y = this.grid.delta.y - (module.y - module.h / 2);
        }
        delete this.grid.delta;
    } else if (!movingGrid && this.fromScratch) {
        // creating
        adjustment.x = this.grid.offset.x + this.grid.offset.w;
        adjustment.y = this.grid.offset.y + this.grid.offset.h;
    } else if (!movingGrid && !this.fromScratch) {
        // reloading
        adjustment.x += (this.grid.offset.w + this.pivotingPoint.w);
        adjustment.y += (this.grid.offset.h + this.pivotingPoint.h);

        adjustment.x -= (this.pivotingPoint.x + this.grid.offset.x);
        adjustment.y -= (this.pivotingPoint.y + this.grid.offset.y);

        adjustment = this.getActiveAdjustment(adjustment);
    }

    // update offset with values to correct the grid position
    this.grid.offset.x = adjustment.x;
    this.grid.offset.y = adjustment.y;

    // console.warn('EZModelSystem', 'calculateSpace', adjustment);
    return adjustment;
};
/**
 * Method to calculate the adjustment needed to avoid (correct) random grid movement.
 * The active point is calculated and used each iteration to correct the position.
 *
 * @param {object}  adjustment  - Offset needed to update the points.
 *
 * @return {object} - The adjustmen calculated using the active point.
 */
EZModelSystem.prototype.getActiveAdjustment = function(adjustment) {
    var activeAdjustment;

    EZModelUtils.destroyObject(this.modulesBox);
    this.modulesBox = [];

    if (this.activePoint) {
        this.grid.rows.forEach((row) => {
            row.modules.forEach((module) => {
                if (module.type === 'module') {
                    this.updateModulesBox([
                        {x: module.x, y: module.y},
                        {x: module.x + module.w, y: module.y - module.h}
                    ]);
                }
            });
        });
        // Update activePoint
        this.activePoint = this.getRotatedPath([this.activePoint], -this.angle)[0];
        if (this.modulesBox.x === undefined) {
            this.activePoint.w = this.activePoint.x;
            this.activePoint.h = this.activePoint.y;
        } else {
            this.activePoint.w = (this.modulesBox.x + this.modulesBox.w) / 2;
            this.activePoint.h = (this.modulesBox.y + this.modulesBox.h) / 2;
        }
        // Define activeAdjustment
        activeAdjustment = {
            x: (this.activePoint.x - this.activePoint.w),
            y: (this.activePoint.y - this.activePoint.h)
        };
    } else {
        activeAdjustment = adjustment;
    }
    // console.warn('System', 'getActiveAdjustment', activeAdjustment);
    return activeAdjustment;
};
/**
 * Method to update the grid's modules box (box containing all the modules).
 *
 * @param  {array} box      - The array of vertices of the current module.
 * @param  {boolean} rotate - Should the current module's box be rotated?
 */
EZModelSystem.prototype.updateModulesBox = function(box, rotate) {
    if (box === undefined) return;
    if (box) {
        box.forEach((vertex) => {
            if (this.modulesBox.x === undefined || vertex.x < this.modulesBox.x) {
                this.modulesBox.x = vertex.x;
            }
            if (this.modulesBox.w === undefined || vertex.x > this.modulesBox.w) {
                this.modulesBox.w = vertex.x;
            }
            if (this.modulesBox.y === undefined || vertex.y > this.modulesBox.y) {
                this.modulesBox.y = vertex.y;
            }
            if (this.modulesBox.h === undefined || vertex.y < this.modulesBox.h) {
                this.modulesBox.h = vertex.y;
            }
        });
        if (rotate) {
            var rotatedBox = this.getRotatedPath(box, -this.angle);
            rotatedBox.forEach((vertex) => {
                if (this.modulesBox.xF === undefined || vertex.x < this.modulesBox.xF) {
                    this.modulesBox.xF = vertex.x;
                }
                if (this.modulesBox.wF === undefined || vertex.x > this.modulesBox.wF) {
                    this.modulesBox.wF = vertex.x;
                }
                if (this.modulesBox.yF === undefined || vertex.y > this.modulesBox.yF) {
                    this.modulesBox.yF = vertex.y;
                }
                if (this.modulesBox.hF === undefined || vertex.y < this.modulesBox.hF) {
                    this.modulesBox.hF = vertex.y;
                }
            });
        }
    } else {
        // Update activePoint
        if (this.modulesBox.x === undefined) {
            this.modulesBox.x = (this.rotatedData.startingPoint.x < this.rotatedData.finishingPoint.x)
                ? this.rotatedData.startingPoint.x : this.rotatedData.finishingPoint.x;
            this.modulesBox.w = (this.rotatedData.startingPoint.x > this.rotatedData.finishingPoint.x)
                ? this.rotatedData.startingPoint.x : this.rotatedData.finishingPoint.x;
            this.modulesBox.y = (this.rotatedData.startingPoint.y > this.rotatedData.finishingPoint.y)
                ? this.rotatedData.startingPoint.y : this.rotatedData.finishingPoint.y;
            this.modulesBox.h = (this.rotatedData.startingPoint.y < this.rotatedData.finishingPoint.y)
                ? this.rotatedData.startingPoint.y : this.rotatedData.finishingPoint.y;
            this.activePoint = this.rotatedData.pivotingPoint;
        } else if (box === false) {
            this.activePoint = {
                x: (this.modulesBox.xF + this.modulesBox.wF) / 2,
                y: (this.modulesBox.yF + this.modulesBox.hF) / 2
            };
            this.activePoint = this.getRotatedPath([this.activePoint], this.angle)[0];
        } else {
            this.activePoint = {
                x: (this.modulesBox.x + this.modulesBox.w) / 2,
                y: (this.modulesBox.y + this.modulesBox.h) / 2
            };
        }

        // Update modulesBox
        this.modulesBox.push({x: this.modulesBox.x, y: this.modulesBox.y});
        this.modulesBox.push({x: this.modulesBox.x, y: this.modulesBox.h});
        this.modulesBox.push({x: this.modulesBox.w, y: this.modulesBox.h});
        this.modulesBox.push({x: this.modulesBox.w, y: this.modulesBox.y});

        // Clean object
        delete this.modulesBox.x; delete this.modulesBox.y;
        delete this.modulesBox.w; delete this.modulesBox.h;
    }
};

// POINTS
EZModelSystem.prototype.checkGrid = function() {
    var temp = {x: 0, y: 0, row: 0};

    this.startingPoint.x += this.grid.offset.x;
    this.startingPoint.y += this.grid.offset.y;

    this.grid.populatingPoint.x += this.grid.offset.x;
    this.grid.populatingPoint.y -= this.grid.offset.y;

    // BOTTOM
    temp.bottom = this.boundingBox[0].y - this.startingPoint.y;
    temp.first = this.grid.rows[0].size.y;
    if (this.structure !== 'Standard') {
        temp.first += (this.grid.rows[0].structure === 'Standard') ? this.inset.y : -this.inset.y;
    }

    if (temp.bottom < 0) {
        // console.warn('shiftRow');
        while (temp.bottom < 0) {
            temp.first = this.grid.rows[0].size.y;
            temp.y += temp.first; // Move Down
            temp.row--; // Less Rows
            this.grid.rows.shift();
            // Update
            this.grid.offset.y -= temp.first; // Offest Up
            this.startingPoint.y -= temp.first; // Start Up
            temp.bottom = this.boundingBox[0].y - this.startingPoint.y;
        }
    } else if (temp.bottom > temp.first) {
        // console.warn('unshiftRow');
        while (temp.bottom > temp.first) {
            temp.first = this.grid.rows[0].size.y;
            temp.row++; // More Rows
            temp.bottom = this.unshiftRow(temp);
        }
    }

    // TOP
    temp.top = this.grid.size.y - this.grid.populatingPoint.y;
    temp.last = this.grid.rows[this.grid.rows.length - 1].size.y;
    if (this.structure === 'Standard') {
        temp.last += (this.grid.rows[this.grid.rows.length - 1].structure === 'Standard')
            ? this.inset.y : -this.inset.y;
    } else {
        temp.last += this.inset.y;
    }

    if (temp.top < 0) {
        // console.warn('popRow');
        while (temp.top < 0 && (this.grid.rows.length - 1 !== 0)) {
            temp.last = this.grid.rows[this.grid.rows.length - 1].size.y;
            this.grid.rows.pop();
            // Update
            this.grid.populatingPoint.y -= temp.last; // Finish Down
            temp.top = this.grid.size.y - this.grid.populatingPoint.y;
        }
    } else if (temp.top > temp.last) {
        // console.warn('pushRow');
        while (temp.top > temp.last) {
            temp.last = this.grid.rows[this.grid.rows.length - 1].size.y;
            temp.top = this.pushRow(temp);
        }
    }

    if (!ez3dScene.isLoadingProject) {
        temp.left = undefined;
        temp.right = undefined;
        this.grid.rows.forEach((line, index) => {
            temp.x = 0;
            temp.col = 0;

            // LEFT
            temp.bottom = (line.modules[0].x + this.grid.offset.x) - this.boundingBox[0].x;
            temp.first = line.modules[0].w + this.inset.x;
            if (temp.bottom < 0) {
                // console.warn('shiftCol', index);
                while (temp.bottom < 0 && line.modules.length > 1) {
                    temp.x -= line.modules[0].w + this.inset.x; // Move Left
                    temp.col--; // Less Cols
                    line.modules.shift();
                    line.literal.shift();
                    // Update
                    temp.bottom = (line.modules[0].x + this.grid.offset.x);
                    temp.bottom -= this.boundingBox[0].x;
                }
            } else if (temp.bottom > temp.first) {
                // console.warn('unshiftCol', index);
                while (temp.bottom > temp.first) {
                    temp.col++; // More Cols
                    temp.bottom = this.unshiftCol(temp, line);
                }
            }
            // Update
            temp.left = (!temp.left || temp.x < temp.left) ? temp.x : temp.left;

            // RIGHT
            temp.top = (line.modules[line.modules.length - 1].x + this.grid.offset.x);
            temp.top = this.boundingBox[2].x - (temp.top + line.modules[line.modules.length - 1].w);
            temp.last = (line.modules[line.modules.length - 1].w + this.inset.x);

            if (temp.top < 0) {
                // console.warn('popCol', index);
                while (temp.top < 0 && line.modules.length > 1) {
                    line.modules.pop();
                    line.literal.pop();
                    // Update
                    temp.top = (line.modules[line.modules.length - 1].x + this.grid.offset.x);
                    temp.top = this.boundingBox[2].x - (temp.top + line.modules[line.modules.length - 1].w);
                }
            } else if (temp.top > temp.last) {
                // console.warn('pushCol', index);
                while (temp.top > temp.last) {
                    temp.top = this.pushCol(temp, line);
                }
            }
            // Update
            if (!temp.right || line.modules[line.modules.length - 1].x + this.grid.offset.x + line.modules[line.modules.length - 1].w > temp.right) {
                temp.right = line.modules[line.modules.length - 1].x + this.grid.offset.x + line.modules[line.modules.length - 1].w;
            }

            // UPDATE
            line.modules.forEach((column) => {
                column.row += temp.row;
                column.y += temp.y;
                column.col += temp.col;
                column.x += temp.x;
                column.id = 'id_' + column.row + ':' + column.col + '_' + column.id.split('_')[2];
            });
        });

        // UPDATE
        this.grid.offset.x -= temp.left;
        this.startingPoint.x -= temp.left;

        this.grid.populatingPoint.x = this.grid.size.x;
        this.grid.populatingPoint.x -= (this.boundingBox[2].x - temp.right);

        // this.drawGrid(true);
        // console.log('-------------------------------');
    }
    this.firstRowInverted = (this.grid.rows[0].structure === 'Inverted' && this.structure !== 'EW');
    // Update Grid Offset
    this.grid.offset.w = (this.grid.size.x - this.grid.populatingPoint.x) / 2;
    this.grid.offset.h = -(this.grid.size.y - this.grid.populatingPoint.y) / 2;
};

/**
 * Method to calculate the finishing point of the grid.
 * The finishing point is where the last module ends.
 *
 * @return {object} - The finishing point of the rotated grid.
 */
EZModelSystem.prototype.getFinishingPoint = function() {
    var finishingPoint = {
        x: this.boundingBox[0].x + this.grid.populatingPoint.x,
        y: this.boundingBox[0].y - this.grid.populatingPoint.y
    };
    delete this.grid.populatingPoint;

    this.pivotingPoint.x = (this.startingPoint.x + finishingPoint.x) / 2;
    this.pivotingPoint.y = (this.startingPoint.y + finishingPoint.y) / 2;

    return finishingPoint;
};

/**
 * Method to update the module's (hit)box, (un)rotate the grid and check the collisions.
 * @param  {boolean}    rotated - Should the rotation of the grid be undone?
 */
EZModelSystem.prototype.updateModules = function(rotated) {
    var module;
    this.grid.rows.forEach((row) => {
        for (var col = 0; col < row.modules.length; col++) {
            module = row.modules[col];
            // Updating grid values
            if (this.grid.offset) {
                module.x += this.grid.offset.x;
                module.y += this.grid.offset.y;
            }
            // Creating module box
            if (!module.box) {
                module.box = [];
                module.box.push({x: module.x, y: module.y});
                module.box.push({x: module.x, y: module.y - module.h});
                module.box.push({x: module.x + module.w, y: module.y - module.h});
                module.box.push({x: module.x + module.w, y: module.y});
                // Creating module hitbox
                module.hit = [];
                if (ez3dScene.projectType !== 'simple') {
                    var bias = ez3dScene.layoutRules.scenePreferences.collisionBias;
                    module.hit.push({x: module.box[0].x + bias, y: module.box[0].y - bias});
                    module.hit.push({x: module.box[1].x + bias, y: module.box[1].y + bias});
                    module.hit.push({x: module.box[2].x - bias, y: module.box[2].y + bias});
                    module.hit.push({x: module.box[3].x - bias, y: module.box[3].y - bias});
                }
            }
            // Rotating grid
            if (rotated) {
                module.box = this.getRotatedPath(module.box, this.angle);
                module.hit = this.getRotatedPath(module.hit, this.angle);
                module.x = module.box[0].x;
                module.y = module.box[0].y;
            }
            // Checking Collisions
            if (ez3dScene.projectType !== 'simple') {
                if (this.checkCollision(row, module) >= 1) {
                    // row.modules[col] = {type: 'collision'};
                    row.modules[col] = undefined;
                }
            }
            // Clearing modules
            if (module) {
                ['h', 'w', 'box', 'hit'].forEach((prop) => {
                    module[prop] = undefined;
                    delete module[prop];
                });
            }
        }
    });
};

/**
 * Method to check if a module is off-limits or collides with any keepout.
 * If so, this method automatically changes the module type to 'collision'.
 *
 * @param  {object} row     - JSON with the data of the (current) row.
 * @param  {object} module  - JSON with the data of the (current) module.
 * @return {number} hit
 */
EZModelSystem.prototype.checkCollision = function(row, module) {
    // Intersection check
    var hit = 0;
    var collision = EZModelUtils.intersectPaths(module.hit, this.path.vertices, true, true);
    if (collision.length) {
        module.type = 'collision';
        row.literal[module.col] = 0;
        hit = 1;
    } else {
        var area = this.getAncestor('EZModelArea');
        Object.keys(area.keepouts).forEach((key) => {
            if (!hit) {
                area.keepouts[key].forEach((keepout) => {
                    collision = EZModelUtils.intersectPaths(module.hit, keepout.vertices, true, false);
                    if (!hit && collision.length) {
                        module.type = 'collision';
                        row.literal[module.col] = 0;
                        hit = 2;
                    }
                });
            }
        });
    }
    return hit;
};

// STORAGE
/**
 * Method to get dilatation lines coords and rotate them
 */
EZModelSystem.prototype.calculateDilatationLinesCoords = function() {
    var strokeWeight = 0;
    var i = 0;
    var temp;
    /* Delta Module: can be valued 2, 1 or 0:
     * 2: Last module inside the grid
     * 1: First invalid module
     * 0: Any other invalid module
    */
    var deltaModule;
    var point;
    this.dilatationLines.draw = {cols: [], rows: []};

    // Get starting and finishing points of each dilatation lines col
    this.dilatationLines.log.cols.forEach((col, coli) => {
        strokeWeight = col[0];
        temp = [strokeWeight];
        for (i = 1; i < col.length; i++) {
            deltaModule = (this.grid.rows[col[i][1]].modules[col[i][0] - 1]) ? 1 : 0;
            deltaModule = (this.grid.rows[col[i][1]].modules[col[i][0]]) ? 2 : deltaModule;
            // Check if it exists a starting row and its starting module
            if (this.grid.rows[col[i][1]] && deltaModule) {
                deltaModule = 2 - deltaModule;
                // Get module with offset and set as starting point
                point = this.grid.rows[col[i][1]].modules[col[i][0] - deltaModule];
                point = [point.x + this.grid.offset.x + ((deltaModule) ? point.w + strokeWeight : 0),
                    point.y + this.grid.offset.y];
                temp[2 * i - 1] = {x: point[0] - strokeWeight / 2, y: point[1]};

                // Check if it exists a finishing col and finishing module and set as finishing point
                if (i < col.length - 1 && this.grid.rows[col[i + 1][1] - 1] &&
                    this.grid.rows[col[i + 1][1] - 1].modules[col[i][0] - deltaModule]) {
                    point = this.grid.rows[col[i + 1][1] - 1].modules[col[i][0] - deltaModule];
                } else {
                    point = this.grid.rows[this.grid.rows.length - 1];
                    point = point.modules[col[i][0] - deltaModule];
                }
                point = [point.x + this.grid.offset.x + ((deltaModule) ? point.w + strokeWeight : 0),
                    point.y + this.grid.offset.y - point.h];
                temp[2 * i] = {x: point[0] - strokeWeight / 2, y: point[1]};
            }
        }
        temp = this.getRotatedPath(temp, this.angle);
        this.dilatationLines.draw.cols[coli] = temp;
    });

    // Get starting and finishing points of each dilatation lines row
    this.dilatationLines.log.rows.forEach((row, rowi) => {
        strokeWeight = row[0];
        temp = [strokeWeight];
        for (i = 1; i < row.length; i++) {
            deltaModule = (this.grid.rows[row[i][1] - 1]) ? 1 : 0;
            deltaModule = (this.grid.rows[row[i][1]]) ? 2 : deltaModule;
            // Check if it exists a starting row and its starting module
            if (deltaModule && this.grid.rows[row[i][1] - (2 - deltaModule)].modules[row[i][0]]) {
                deltaModule = (2 - deltaModule);
                // Get module with offset and set as starting point
                point = this.grid.rows[row[i][1] - deltaModule].modules[row[i][0]];
                point = [point.x + this.grid.offset.x,
                    point.y + this.grid.offset.y - ((deltaModule) ? point.h + strokeWeight : 0)];
                temp[2 * i - 1] = {x: point[0], y: point[1] + strokeWeight / 2};

                // Check if it exists a finishing row and finishing module and set as finishing point
                if (i < row.length - 1 && this.grid.rows[row[i][1] - deltaModule] &&
                    this.grid.rows[row[i][1] - deltaModule].modules[row[i + 1][0] - 1]) {
                    point = this.grid.rows[row[i][1] - deltaModule].modules[row[i + 1][0] - 1];
                } else {
                    point = this.grid.rows[row[i][1] - deltaModule];
                    point = point.modules[point.modules.length - 1];
                }
                point = [point.x + this.grid.offset.x + point.w,
                    point.y + this.grid.offset.y - ((deltaModule) ? point.h + strokeWeight : 0)];
                temp[2 * i] = {x: point[0], y: point[1] + strokeWeight / 2};
            }
        }
        temp = this.getRotatedPath(temp, this.angle);
        this.dilatationLines.draw.rows[rowi] = temp;
    });
};

/**
 * Method to get the data of the grid in the rotated (flatten) space.
 *
 * @return {object} - Bounding box, path and points of the rotated grid.
 */
EZModelSystem.prototype.getRotatedData = function() {
    var rotatedData = {};
    rotatedData.boundingBox = this.boundingBox;

    rotatedData.rotatedPath = this.rotatedPath;

    rotatedData.pivotingPoint = this.getRotatedPath([this.pivotingPoint], this.angle)[0];
    rotatedData.pivotingPoint.w = this.pivotingPoint.x;
    rotatedData.pivotingPoint.h = this.pivotingPoint.y;

    rotatedData.startingPoint = this.getRotatedPath([this.startingPoint], this.angle)[0];
    rotatedData.startingPoint.w = this.startingPoint.x;
    rotatedData.startingPoint.h = this.startingPoint.y;

    rotatedData.finishingPoint = this.getRotatedPath([this.finishingPoint], this.angle)[0];
    rotatedData.finishingPoint.w = this.finishingPoint.x;
    rotatedData.finishingPoint.h = this.finishingPoint.y;

    return rotatedData;
};


// SYSTEM INFO
/**
 * Method to get the info of the system and update the grid (modules box and limits).
 *
 * @param  {boolean} skipUpdate - Skip the modules box and grid limits update?
 *
 * @return {object} - Info (modules and power) of the system.
 */
EZModelSystem.prototype.getSystemInfo = function(skipUpdate) {
    var total = 0;
    var count = 0;
    this.modulesBox = [];
    if (this.grid.rows) {
        var box = this.getModuleBox();
        this.grid.rows.forEach((row) => {
            row.literal.forEach((col, index) => {
                total++;
                if (col === 1) {
                    count++;
                    if (!skipUpdate) {
                        this.updateModulesBox(
                            this.getModuleBox(box, row.placement, row.modules[index]),
                            // [{x: row.modules[index].x, y: row.modules[index].y}],
                            true);
                    }
                }
            });
        });
        if (!skipUpdate) {
            this.updateModulesBox(false);
            this.updateGridLimits();
        }
    }
    return {
        holders: total,
        modules: count,
        power: count * this.model.power
    };
};

EZModelSystem.prototype.getModuleBox = function(box, placement, origin) {
    if (box === undefined) {
        var base = {
            landscape: this.getRotatedPath([
                {x: 0, y: 0}, {x: 0, y: this.model.landscape.y},
                {x: this.model.landscape.x, y: this.model.landscape.y},
                {x: this.model.landscape.x, y: 0}], -this.angle),
            portrait: this.getRotatedPath([
                {x: 0, y: 0}, {x: 0, y: this.model.portrait.y},
                {x: this.model.portrait.x, y: this.model.portrait.y},
                {x: this.model.portrait.x, y: 0}], -this.angle)
        };
        return base;
    }
    return [
        {x: origin.x + box[placement][0].x, y: origin.y - box[placement][0].y},
        {x: origin.x + box[placement][1].x, y: origin.y - box[placement][1].y},
        {x: origin.x + box[placement][2].x, y: origin.y - box[placement][2].y},
        {x: origin.x + box[placement][3].x, y: origin.y - box[placement][3].y},
    ];
};

/**
 * Method to calculate and update the new limits of the grid.
 * The grid is limited by the empty space inside of the subarea.
 */
EZModelSystem.prototype.updateGridLimits = function() {
    this.gridLimits = {};
    this.path.vertices.forEach((vertex) => {
        if (this.gridLimits.x === undefined || vertex.xF < this.gridLimits.x) {
            this.gridLimits.x = vertex.xF;
        }
        if (this.gridLimits.w === undefined || vertex.xF > this.gridLimits.w) {
            this.gridLimits.w = vertex.xF;
        }
        if (this.gridLimits.y === undefined || vertex.yF > this.gridLimits.y) {
            this.gridLimits.y = vertex.yF;
        }
        if (this.gridLimits.h === undefined || vertex.yF < this.gridLimits.h) {
            this.gridLimits.h = vertex.yF;
        }
    });

    this.gridLimits.left = this.modulesBox[0].x - this.gridLimits.x;
    this.gridLimits.down = this.gridLimits.y - this.modulesBox[0].y;
    this.gridLimits.right = this.gridLimits.w - this.modulesBox[2].x;
    this.gridLimits.up = this.modulesBox[2].y - this.gridLimits.h;

    // Clean object
    delete this.gridLimits.x; delete this.gridLimits.y;
    delete this.gridLimits.w; delete this.gridLimits.h;

    // Clean numbers
    this.gridLimits.left = (this.gridLimits.left < 0) ? 0 : this.gridLimits.left;
    this.gridLimits.down = (this.gridLimits.down < 0) ? 0 : this.gridLimits.down;
    this.gridLimits.right = (this.gridLimits.right < 0) ? 0 : this.gridLimits.right;
    this.gridLimits.up = (this.gridLimits.up < 0) ? 0 : this.gridLimits.up;
};

EZModelSystem.prototype.loadCollisionBox = function() {
    if (this.grid.rows) {
        var box = this.getModuleBox();
        this.grid.rows.forEach((row) => {
            row.literal.forEach((col, index) => {
                if (col === 1) {
                    row.modules[index].box = this.getModuleBox(
                        box, row.placement, row.modules[index]);
                }
            });
        });
    }
};

EZModelSystem.prototype.unloadCollisionBox = function() {
    if (this.grid.rows) {
        this.grid.rows.forEach((row) => {
            row.modules.forEach((module) => {
                if (module && module.box) {
                    module.box = undefined;
                    delete module.box;
                }
            });
        });
    }
};

// SET MODULES
/**
 * Method to change (or switch) the type of a given module.
 * The type of a module can be 'module' or 'empty' (if disabled).
 *
 * @param {integer} row - The row of the module to change.
 * @param {integer} col - The column of the module to change.
 * @param {*} [type] - Integer, boolean or string with the type.
 */
EZModelSystem.prototype.setModule = function(row, col, type) {
    var module = this.grid.rows[row].modules[col];
    if (type === 0 || type === false ||
        type === undefined && module.type === 'module') {
        this.grid.rows[row].literal[col] = 0;
        module.type = 'empty';
    } else if (type === 1 || type === true ||
        type === undefined && module.type === 'empty') {
        this.grid.rows[row].literal[col] = 1;
        module.type = 'module';
    }
};

/**
 * Method to change (or switch) the type of modules in the grid.
 *
 * @param {*}       [type]  - Integer, boolean or string with the type.
 * @param {string}  [mode]  - Change 'one' module, a 'row' or a 'col'?
 * @param {array}   index   - Row(s) and/or column(s) to change indices.
 */
EZModelSystem.prototype.setModules = function(type, mode, index) {
    // console.warn('EZModelSystem', 'setModules', type, mode, index);
    if (mode === 'one') {
        if (Array.isArray(index[0])) {
            index.forEach((i) => {
                this.setModule(i[0], i[1], type);
            });
        } else {
            this.setModule(index[0], index[1], type);
        }
    } else if (Array.isArray(index)) {
        index.forEach((i) => {
            this.grid.rows.forEach((row, rowIndex) => {
                if (mode === undefined || (mode === 'col') ||
                 (mode === 'row' && i === rowIndex)) {
                    row.modules.forEach((module, colIndex) => {
                        if (mode === undefined || (mode === 'row') ||
                         (mode === 'col' && i === colIndex)) {
                            if (module && module.type !== 'collision') {
                                row.literal[module.col] = (type) ? 1 : 0;
                                module.type = (type) ? 'module' : 'empty';
                            }
                        }
                    });
                }
            });
        });
    } else {
        this.grid.rows.forEach((row, rowIndex) => {
            if (mode === undefined || (mode === 'col') ||
             (mode === 'row' && index === rowIndex)) {
                row.modules.forEach((module, colIndex) => {
                    if (mode === undefined || (mode === 'row') ||
                     (mode === 'col' && index === colIndex)) {
                        if (module && module.type !== 'collision') {
                            row.literal[module.col] = (type) ? 1 : 0;
                            module.type = (type) ? 'module' : 'empty';
                        }
                    }
                });
            }
        });
    }
};

// GRID OPERATIONS
/**
 * Method to add a new column (at the beginning) of a given row of the grid.
 *
 * @param {object}  row - JSON with the data of the (current) row.
 * @param {integer} col - The current column index (inside current row).
 */
EZModelSystem.prototype.addColumns = function(row, col) {
    var up = row.modules[col].x - row.modules[col - 1].x;
    var down = row.modules[col].x - row.modules[0].x;

    row.modules.forEach((module, index) => {
        if (index === row.modules.length - 1) {
            module.col = 0;
            module.x -= down;
        } else {
            module.col++;
            module.x += up;
        }
    });

    // modules sorting
    var modules = row.modules.splice(-1, 1);
    row.modules = modules.concat(row.modules);

    // literal sorting
    var literal = row.literal.splice(-1, 1);
    row.literal = literal.concat(row.literal);
};

/**
 * Method to add a new row (at the beginning) of the grid.
 *
 * @param {array}   lines   - Array cantaining all the rows of the grid.
 * @param {integer} row     - The current row index (inside the grid's rows).
 */
EZModelSystem.prototype.addRows = function(lines, row) {
    var up = lines[row].size.start - lines[row - 1].size.start;
    var down = lines[row].size.start - lines[0].size.start;

    lines.forEach((line, index) => {
        line.modules.forEach((module) => {
            if (index === lines.length - 1) {
                module.row = 0;
                module.y += down;
            } else {
                module.row++;
                module.y -= up;
            }
        });
        if (index === lines.length - 1) {
            line.size.start -= down;
        } else {
            line.size.start += up;
        }
    });
    // rows sorting
    var rows = lines.splice(-1, 1);
    this.grid.rows = rows.concat(lines);
};

EZModelSystem.prototype.unshiftRow = function(temp) {
    var line = _.cloneDeep(this.grid.rows[0]);
    var delta = temp.first;

    if (this.structure !== 'Standard') {
        line.structure = (line.structure === 'Standard') ? 'Inverted' : 'Standard';
        delta += (line.structure === 'Standard') ? -this.inset.y : this.inset.y;
        line.size.y = delta;
    }
    line.size.start -= delta;

    line.modules.forEach((column, index) => {
        column.row--;
        column.y += delta;
        column.type = 'empty';
        line.literal[index] = 0;
    });
    this.grid.rows.unshift(line);

    // Update
    temp.y -= delta; // Move Up
    this.grid.offset.y += delta; // Offset Down
    this.startingPoint.y += delta; // Start Down
    return this.boundingBox[0].y - this.startingPoint.y;
};

EZModelSystem.prototype.pushRow = function(temp) {
    var line = _.cloneDeep(this.grid.rows.pop());
    this.grid.rows.push(_.cloneDeep(line));
    var delta = temp.last;

    if (this.structure !== 'Standard') {
        line.structure = (line.structure === 'Standard') ? 'Inverted' : 'Standard';
        line.size.y += (line.structure === 'Standard') ? this.inset.y : -this.inset.y;
    }
    line.size.start += delta;
    line.modules.forEach((column, index) => {
        column.row++;
        column.y -= delta;
        column.type = 'empty';
        line.literal[index] = 0;
    });
    this.grid.rows.push(_.cloneDeep(line));

    // Update
    this.grid.populatingPoint.y += delta;
    return this.grid.size.y - this.grid.populatingPoint.y;
};

EZModelSystem.prototype.unshiftCol = function(temp, line) {
    var column = _.cloneDeep(line.modules[0]);
    var delta = temp.first;

    column.col--;
    column.x -= delta;
    column.type = 'empty';

    line.literal.unshift(0);
    line.modules.unshift(column);

    // Update
    temp.x += delta; // Move Right
    delta = line.modules[0].x + this.grid.offset.x;
    return (delta - this.boundingBox[0].x);
};

EZModelSystem.prototype.pushCol = function(temp, line) {
    var column = _.cloneDeep(line.modules.pop());
    line.modules.push(_.cloneDeep(column));
    var delta = temp.last;

    column.col++;
    column.x += delta;
    column.type = 'empty';

    line.literal.push(0);
    line.modules.push(_.cloneDeep(column));

    // Update
    delta = column.x + this.grid.offset.x + column.w;
    return this.boundingBox[2].x - delta;
};

// GRID MOVING
/**
 * Method to move the grid by changing (and updating) its offset.
 *
 * @param  {object}     delta   - The desired amount of movement (x,y).
 * @param  {integer}    [mode]  - Key pressed (1: Shift or 2: Ctrl).
 * @param  {integer}    [index] - Movement direction (numpad format).
 */
EZModelSystem.prototype.moveGrid = function(delta, mode, index) {
    var offset = {
        x: this.grid.offset.x + delta.x,
        y: this.grid.offset.y + delta.y
    };
    // Check the movement mode
    if (mode === 2 && index === 5) {
        // Reset : CENTER
        offset.x = this.grid.offset.w;
        offset.y = this.grid.offset.h;
    } else if (mode === 1) {
        // Shift : LITERAL
        var space = {
            x: this.model[this.placement].x,
            y: this.model[this.placement].y
        };
        space.w = space.x + this.inset.x;
        space.h = space.y + this.inset.y;

        offset.x = this.grid.offset.x + (delta.x * space.w);
        offset.y = this.grid.offset.y + (delta.y * space.h);
    } else {
        // STANDARD MOVEMENT
    }
    // Update the Offset
    this.grid.offset.x = offset.x;
    this.grid.offset.y = offset.y;
    // Update the Grid
    this.updateGrid(false, false, false, true);
};

/**
 * Method to move the grid in the flatten space (rotation needed).
 *
 * @param  {object}     delta   - The desired amount of movement (x,y).
 * @param  {integer}    [mode]  - Key pressed (1: Shift or 2: Ctrl).
 * @param  {integer}    [index] - Movement direction (numpad format).
 */
EZModelSystem.prototype.moveFlattenGrid = function(delta, mode, index) {
    var freeSpace = {x: 1, y: 1};
    if (index === 5 && mode === 2) {
        freeSpace = 0;
        this.moveGrid(delta, mode, index);
    } else if (mode === 1) {
        delta.x = Math.sign(delta.x);
        delta.y = Math.sign(delta.y);
        freeSpace = 1;
    } else {
        if (index % 3 === 0) {
            // Right
            if (mode === 2) {
                delta.x = this.gridLimits.right;
            } else if (this.gridLimits.right <= 0) {
                freeSpace.x = 0;
            } else if (delta.x > this.gridLimits.right) {
                freeSpace.x = this.gridLimits.right / delta.x;
            }
        } else if (index % 3 === 1) {
            // Left
            if (mode === 2) {
                delta.x = -this.gridLimits.left;
            } else if (this.gridLimits.left <= 0) {
                freeSpace.x = 0;
            } else if (-delta.x > this.gridLimits.left) {
                freeSpace.x = this.gridLimits.left / -delta.x;
            }
        }
        if (index === 5) {
            delta.x = (this.gridLimits.right - this.gridLimits.left) / 2;
            delta.y = (this.gridLimits.down - this.gridLimits.up) / 2;
        }
        if (index < 4) {
            // Down
            if (mode === 2) {
                delta.y = this.gridLimits.down;
            } else if (this.gridLimits.down <= 0) {
                freeSpace.y = 0;
            } else if (delta.y > this.gridLimits.down) {
                freeSpace.y = this.gridLimits.down / delta.y;
            }
        } else if (index > 6) {
            // Up
            if (mode === 2) {
                delta.y = -this.gridLimits.up;
            } else if (this.gridLimits.up <= 0) {
                freeSpace.y = 0;
            } else if (-delta.y > this.gridLimits.up) {
                freeSpace.y = this.gridLimits.up / -delta.y;
            }
        }
        freeSpace = (freeSpace.x < freeSpace.y) ? freeSpace.x : freeSpace.y;
    }
    if (freeSpace) {
        // Move the Grid
        var newDelta = {x: delta.x * freeSpace, y: delta.y * freeSpace};
        var angle = EZModelUtils.degToRad(this.angle);
        var rotatedDelta = EZModelUtils.changeAxis(newDelta, -angle);
        this.moveGrid(rotatedDelta, mode, index);
    }
};

// SIMPLE MODE
/**
 * Method to change the number of rows of the grid.
 *
 * @param  {integer} number - New number of grid's rows.
 */
EZModelSystem.prototype.changeRowNumber = function(number) {
    if (this.grid.rows === undefined) {
        this.grid.rows = [{literal: [1]}];
    }
    var length = this.grid.rows.length;
    var delta = number - length;
    if (delta > 0) {
        length = (length) ? this.grid.rows[length - 1].literal.length : 1;
        for (var i = 1; i <= delta; i++) {
            this.grid.rows.push({
                literal: Array(length).fill(1)
            });
        }
    } else if (delta < 0) {
        for (var j = -1; j >= delta; j--) {
            this.grid.rows.pop();
        }
    }
    this.updateGrid();
};

/**
 * Method to change the number of columns of the grid.
 *
 * @param  {integer} number - New number of grid's columns.
 */
EZModelSystem.prototype.changeColumnNumber = function(number) {
    if (this.grid.rows === undefined) {
        this.grid.rows = [{literal: [1]}];
    }
    var length = this.grid.rows[0].literal.length;
    var delta = number - length;
    if (delta > 0) {
        for (var i = 1; i <= delta; i++) {
            this.grid.rows.forEach((row) => {
                row.literal.push(1);
            });
        }
    } else if (delta < 0) {
        for (var j = -1; j >= delta; j--) {
            this.grid.rows.forEach((row) => {
                row.literal.pop();
            });
        }
    }
    this.updateGrid();
};

/**
 * Method to change and update the path (shape) of the building.
 *
 * @param  {object} finishingPoint  - The coords of the finishingPoint.
 */
EZModelSystem.prototype.changePathSize = function(finishingPoint) {
    // calculating / Updating the limits of the new boundingBox
    this.boundingBox[1].y = finishingPoint.y;
    this.boundingBox[2].x = finishingPoint.x;
    this.boundingBox[2].y = finishingPoint.y;
    this.boundingBox[3].x = finishingPoint.x;

    // preparing to calculate the positions of the new vertices
    var rotatedPath = this.getRotatedPath(this.boundingBox, this.angle);

    // calculating the positions of the new vertices
    let newPath = [];
    const delta = {x: 0, y: 0};
    rotatedPath.forEach((flattenVertex, index) => {
        // change flatten vertices to ortogonal
        const ortogonalVertex = this.path.unFlattenPoint(flattenVertex);

        // change vertices to cartesian scene coords
        const cartesianVertex = {x: 0, y: 0};
        if (index === 0) {
            delta.x -= ortogonalVertex.x;
            delta.y -= ortogonalVertex.y;
        } else {
            cartesianVertex.x = ortogonalVertex.x + delta.x;
            cartesianVertex.y = ortogonalVertex.y + delta.y;
        }

        // change vertices from cartesian to spherical coords
        const sphericalVertex = EZModelUtils.convertCartesianToSpherical(
            cartesianVertex, ez3dScene.projectCenter);

        // creating the new EZModelLocations
        const location = new EZModelLocation(ez3dScene, sphericalVertex.lng, sphericalVertex.lat);
        newPath.push(location);
    });
    // creating the new EZModelPath
    newPath.unshift(newPath.pop());
    newPath = new EZModelPath(ez3dScene, newPath);

    // preparing the building creation
    const building = this.getAncestor('EZModelBuilding');
    const json = building.getJson();
    json.path = newPath.getJson();

    // delete json.paths;
    delete json.roofs[0].path;
    delete json.roofs[0].areas[0].path;
    delete json.roofs[0].areas[0].subareas[0].path;
    delete json.roofs[0].areas[0].subareas[0].system.path;

    // creating the new EZModelBuilding
    // const index = ez3dScene.buildings.indexOf(building);
    const newBuilding = ez3dScene.factories['Building'].createBuilding(ez3dScene, json);
    ez3dScene.buildings[0] = newBuilding;

    // emitEvents
    var target = (ez3dScene.active.constructor.name === 'EZModelBuilding')
        ? newBuilding : newBuilding.roofs[0].areas[0].subareas[0];
    ee.addOnceListener('activeUpdated', function() {
        if (target.constructor.name === 'EZModelBuilding') {
            ee.emitEvent('openSimpleBuildingPanel');
        } else {
            ee.emitEvent('openSimpleSubareaPanel');
        }
        ee.emitEvent('updateRender',
            [{draw2d: true, draw3d: false, updatePanels: true, fitRange: false}]);
    });
    ee.emitEvent('EZModelScene_setActiveListener', [{args: target.id, undo: false, updateRender: false}]);
};

// DEBUG
EZModelSystem.prototype.drawGrid = function(offseted, delta) {
    var offset = {
        x: (offseted) ? this.grid.offset.x + (delta ? delta.x : 0) : 0,
        y: (offseted) ? this.grid.offset.y + (delta ? delta.y : 0) : 0
    };
    // redraw all rows
    if (ez3dScene.context === 'subarea') {
        this.modulesGroup.selectAll('*').remove();

        this.grid.rows.forEach((row) => {
            if (row.modules && row.modules.length) {
                this.rowGroup = this.modulesGroup.append('g');
                this.areaC.drawRow(row, this.rowGroup, offset);
            }
        });

        if (this.activePoint) {
            d3.select('.activePoint')
                .attr('cx', this.activePoint.x)
                .attr('cy', this.activePoint.y);
        }

        if (this.grid.populatingPoint) {
            this.populatingPointGroup.select('circle')
                .attr('cx', this.boundingBox[0].x + this.grid.populatingPoint.x)
                .attr('cy', this.boundingBox[0].y - this.grid.populatingPoint.y);
        }

        d3.select('.gridOffsetRotated')
            .attr('cx', ez3dScene.active.system.grid.offset.x + ez3dScene.active.system.boundingBox[0].x)
            .attr('cy', ez3dScene.active.system.grid.offset.y + ez3dScene.active.system.boundingBox[0].y);

        d3.select('.startingPoint')
            .attr('cx', ez3dScene.active.system.startingPoint.x)
            .attr('cy', ez3dScene.active.system.startingPoint.y);

        d3.select('.pivotingPoint')
            .attr('cx', ez3dScene.active.system.pivotingPoint.w)
            .attr('cy', ez3dScene.active.system.pivotingPoint.h);

        d3.select('.pivotingPointRotated')
            .attr('cx', ez3dScene.active.system.pivotingPoint.x)
            .attr('cy', ez3dScene.active.system.pivotingPoint.y);

        if (ez3dScene.active.system.finishingPoint) {
            d3.select('.finishingPointRotated')
                .attr('cx', ez3dScene.active.system.finishingPoint.x)
                .attr('cy', ez3dScene.active.system.finishingPoint.y);
        }

        d3.select('.rotatedPoints').raise();
    }
};

/**
 * EZModelTree
 * @class
 * @classdesc This is the **EZModelTree** class.
 *
 * @description This is the constructor of the **EZModelTree** class.
 *
 * @param {EZModelScene}    ez3dScene   - Main container for 3DLayout project model.
 * @param {object}          data        - JSON with the data of the tree.
 * @param {boolean}         clone       - Is the tree being created as a clone?
 */
function EZModelTree(ez3dScene, data, clone) {
    // ez3dScene = ez3dScene;
    if (clone || data === undefined) {
        EZModelObject.call(this, null);
    } else {
        EZModelObject.call(this, data.id || null);
    }

    // Main properties
    this.parent = ez3dScene;
    this.setPosition(data.position, clone);
    // @deprecated parentId
    this.parentId = this.parent.id;

    this.style = {
        visible: true,
        colorState: 'standard'
    };

    // Watched properties
    this.__data = this.getDefaultTree(data);
    this.addWatchers();

    // Finish
    this.isCreated = (data && data.isCreated) ? data.isCreated : true;
    this.isEditing = (data && data.isEditing) ? data.isEditing : false;
}

EZModelTree.prototype = Object.create(EZModelObject.prototype);
EZModelTree.prototype.constructor = EZModelTree;

// GET
/**
 * Method to generate and/or asign the watched properties of the tree.
 *
 * @param  {object} data    - JSON with the data of the tree.
 *
 * @return {object} - JSON with the watched properties of the tree.
 */
EZModelTree.prototype.getDefaultTree = function(data) {
    var properties = {};
    data = (data) ? data : {};

    // getDefault
    properties.disabled = ez3dScene.getModelProperty('defaultModelValues', 'tree', 'disabled', data);
    properties.shape = ez3dScene.getModelProperty('defaultModelValues', 'tree', 'shape', data);
    // Create properties for old trees
    if (data.height) {
        this.getTreeHeight(data.height, data);
        this.getTreeRadius(data.height, data, 'crownTopHeight');
    }

    // TOP
    properties.crownTopHeight = ez3dScene.getModelProperty('defaultModelValues', 'tree', 'crownTopHeight', data);
    // CROWN
    properties.crownHigherHeight = ez3dScene.getModelProperty('defaultModelValues', 'tree', 'crownHigherHeight', data);
    properties.crownHigherRadius = ez3dScene.getModelProperty('defaultModelValues', 'tree', 'crownHigherRadius', data);
    properties.crownMiddleHeight = ez3dScene.getModelProperty('defaultModelValues', 'tree', 'crownMiddleHeight', data);
    properties.crownMiddleRadius = ez3dScene.getModelProperty('defaultModelValues', 'tree', 'crownMiddleRadius', data);
    properties.crownLowerHeight = ez3dScene.getModelProperty('defaultModelValues', 'tree', 'crownLowerHeight', data);
    properties.crownLowerRadius = ez3dScene.getModelProperty('defaultModelValues', 'tree', 'crownLowerRadius', data);
    // TRUNK
    properties.trunkHeight = ez3dScene.getModelProperty('defaultModelValues', 'tree', 'trunkHeight', data);
    properties.trunkRadius = ez3dScene.getModelProperty('defaultModelValues', 'tree', 'trunkRadius', data);

    // generate
    properties.name = this.getName();

    return properties;
};

/**
 * Method to calculate (and asign) the index of the tree.
 *
 * @return {integer} - The index of the tree.
 */
EZModelTree.prototype.getIndex = function() {
    const scene = this.parent;
    const indexInParent = (scene && scene.trees)
        ? scene.trees.indexOf(this) : 0;
    return (indexInParent === -1)
        ? ez3dScene.trees.length + 1 : indexInParent + 1;
};

/**
 * Method to generate the name of the tree.
 *
 * @return {string} - The name of the tree.
 */
EZModelTree.prototype.getName = function() {
    this.index = this.getIndex();
    return 'Tree ' + this.index;
};

/**
 * Method to get the tree's children.
 *
 * @param  {boolean} returnObject       - **Optional.** The array should be of objects instead of ids?
 * @param  {boolean} includeParent      - **Optional.** The parent should be included in the array?
 *
 * @return {array} - Array of the tree's children.
 */
EZModelTree.prototype.getChildren = function(returnObject, includeParent) {
    var self = this;
    var children = new Set();
    var position;

    if (returnObject && includeParent) {
        children.add(self);
    } else if (includeParent) {
        children.add(self.id);
    }

    position = (returnObject) ? self.position : self.position.id;
    children = children.add(position);

    return children;
};

/**
 * Method to generate the default height of the tree sections.
 * @param  {float}  height  - Total height of the crown's top.
 * @param  {object} data    - JSON with the data of the tree.
 *
 * @return {object} - JSON with the height sections of the tree.
 */
EZModelTree.prototype.getTreeHeight = function(height, data) {
    var values = (data) ? data : {};
    var initialTreeValues = ez3dScene.layoutRules.defaultModelValues.tree[data.shape];

    // height * (initial property value / initial crownTopHeight)
    values.crownTopHeight = height;
    values.crownHigherHeight = height * (initialTreeValues.crownHigherHeight / initialTreeValues.crownTopHeight);
    values.crownMiddleHeight = height * (initialTreeValues.crownMiddleHeight / initialTreeValues.crownTopHeight);
    values.crownLowerHeight = height * (initialTreeValues.crownLowerHeight / initialTreeValues.crownTopHeight);
    values.trunkHeight = height * (initialTreeValues.trunkHeight / initialTreeValues.crownTopHeight);

    return values;
};

/**
 * This method gets the property name and value of the main radius.
 *
 * @return {object} An object of the main radius property and value.
 */
EZModelTree.prototype.getMainRadiusProperty = function() {
    var self = this;
    var initialTreeValues = ez3dScene.layoutRules.defaultModelValues.tree[self.shape];
    var mainRadius = {};
    Object.keys(initialTreeValues).forEach(function(key) {
        // Check properties that end with 'Radius'
        if (key.indexOf('Radius') !== -1) {
            // Save property and value of main radius
            if (!mainRadius.value || initialTreeValues[key] > initialTreeValues[mainRadius.property]) {
                mainRadius.property = key;
                mainRadius.value = self[key];
            }
        }
    });
    return mainRadius;
};

/**
 * Method to generate the radius of the tree sections.
 * @param  {float}  radius  - Total radius of the segment.
 * @param  {object} data    - JSON with the data of the tree.
 * @param  {string} segment - The segment which radius is given.
 *
 * @return {object} - JSON with the radius sections of the tree.
 */
EZModelTree.prototype.getTreeRadius = function(radius, data, segment) {
    var values = (data) ? data : {};
    var initialTreeValues = ez3dScene.layoutRules.defaultModelValues.tree[this.shape];

    if (segment === 'crownTopHeight') {
        // Create properties for old trees
        values.crownHigherRadius = radius / 7;
        values.crownMiddleRadius = radius / 5.7;
        values.crownLowerRadius = radius / 7;
        values.trunkRadius = radius / 30;
    } else if (segment === 'crownHigherRadius' || segment === 'crownMiddleRadius' || segment === 'crownLowerRadius') {
        // Change all radius values proportionally depending on the main radius
        Object.keys(initialTreeValues).forEach(function(key) {
            if (key.indexOf('Radius') !== -1) {
                // radius * (initial property value / initial main radius value)
                values[key] = radius * (initialTreeValues[key] / initialTreeValues[segment]);
            }
        });
    }
    return values;
};

/**
 * This method gets the list of available tree shapes with attributes.
 *
 * @return {object} An object of the available tree shapes.
 */
EZModelTree.prototype.getDefaultAvailableTreeShapes = function() {
    var availableTreeShapes = {};
    var shapes = [
        'columnar',
        'pyramidal',
        'conical',
        'oval',
        'round',
        'broad',
        'weeping',
        'vase',
        'default',
        'layered',
        'shrubby'
    ];

    shapes.forEach(
        function (shape) {
            availableTreeShapes[shape] = {
                value: shape,
                image: shape,
                label: shape
            };
        }
    );
    return availableTreeShapes;
};

// SET
/**
 * Method to set the position of the tree.
 *
 * @param {EZModelLocation} position    - The position of the tree.
 * @param {boolean} clone               - Is the tree being created as a clone?
 */
EZModelTree.prototype.setPosition = function(position, clone) {
    ez3dScene.utils.destroyObject(this.position);
    this.position = (clone || position.constructor.name !== 'EZModelLocation')
        ? new EZModelLocation(ez3dScene, position.lng, position.lat) : position;
    this.position.setCoordSystem('scene', this, 'EZModelTree');
};

// UPDATE
/**
 * Method to move the position of the tree.
 *
 * @param  {object} offset - Required. A object with lat and lng keys.
 */
EZModelTree.prototype.updatePosition = function(offset) {
    this.position.move(offset);
};

/**
 * Method to set properties when changing tree shape.
 */
EZModelTree.prototype.updateOnTreeShapeChange = function() {
    // TOP
    this.crownTopHeight = ez3dScene.layoutRules.defaultModelValues.tree[this.shape].crownTopHeight;
    // CROWN
    this.crownHigherHeight = ez3dScene.layoutRules.defaultModelValues.tree[this.shape].crownHigherHeight;
    this.crownHigherRadius = ez3dScene.layoutRules.defaultModelValues.tree[this.shape].crownHigherRadius;
    this.crownMiddleHeight = ez3dScene.layoutRules.defaultModelValues.tree[this.shape].crownMiddleHeight;
    this.crownMiddleRadius = ez3dScene.layoutRules.defaultModelValues.tree[this.shape].crownMiddleRadius;
    this.crownLowerHeight = ez3dScene.layoutRules.defaultModelValues.tree[this.shape].crownLowerHeight;
    this.crownLowerRadius = ez3dScene.layoutRules.defaultModelValues.tree[this.shape].crownLowerRadius;
    // TRUNK
    this.trunkHeight = ez3dScene.layoutRules.defaultModelValues.tree[this.shape].trunkHeight;
    this.trunkRadius = ez3dScene.layoutRules.defaultModelValues.tree[this.shape].trunkRadius;
};

// OTHER
EZModelTree.prototype.visibilityRuleCreationOrEditing = function() {
    return this.isCreated === false || this.isEditing === true;
};

EZModelArea.atlas = {
    'offset': {draw2d: true, draw3d: true},
    'disabled': {draw2d: true, draw3d: true},
};

EZModelBuilding.atlas = {
    'height': {draw2d: false, draw3d: true},
    'ridge.height': {draw2d: false, draw3d: true},
};

EZModelSubarea.atlas = {
    'disabled': {draw2d: true, draw3d: true},
};

/**
 * EZFactoryArea
 * @class
 * @classdesc This is the **EZFactoryArea** class.
 *
 * @description This is the constructor of the **EZFactoryArea** class.
 */
function EZFactoryArea() {}

/**
 * Method to create a new area
 *
 * @param  {EZModelScene}       scene   - Main container for 3DLayout project model.
 * @param  {EZModelRoof}        roof    - The parent of the area.
 * @param  {object|EZModelPath} param   - JSON or {@link EZModelPath} of the area.
 *
 * @return {EZModelArea} The new area created.
 */
EZFactoryArea.prototype.createArea = function(scene, roof, param) {
    let newArea;
    if (param === undefined || param instanceof EZModelPath) {
        // param === (EZModelPath)
        newArea = this.createAreaFromScratch(scene, roof, param);
    } else if (param && param instanceof EZModelArea) {
        // param === (EZModelArea)
        newArea = this.createAreaFromV3(scene, roof, param, true);
    } else if (param && param.version && param.version >= 3.0) {
        // param === v3 JSON
        newArea = this.createAreaFromV3(scene, roof, param, false);
    } else if (param && param.version === undefined) {
        // param === v2 JSON
        newArea = this.createAreaFromV2(scene, roof, param);
    } else {
        console.error('Error creating Area with param ' + param);
    }
    return newArea;
};

/**
 * Method to create a area using data from the old (v2) model
 *
 * @param  {EZModelScene} scene - Main container for 3DLayout project model.
 * @param  {EZModelRoof} roof   - The parent of the area.
 * @param  {object} oldJson     - JSON with the data of the area.
 *
 * @return {EZModelArea} The new area created.
 */
EZFactoryArea.prototype.createAreaFromV2 = function(scene, roof, oldJson) {
    let path;
    if (oldJson.verticesOrtoDeg) {
        const vertices = [];
        oldJson.verticesOrtoDeg.forEach(function(vertex) {
            const point = new EZModelLocation(
                scene,
                vertex.lng,
                vertex.lat
            );
            vertices.push(point);
        });
        path = new EZModelPath(scene, vertices);
        path = path.forceClockWise(true);
    }
    const data = {
        disabled: oldJson.disabled,
        id: oldJson.id,
        inclination: Math.abs(scene.utils.radToDeg(oldJson.angle)),
        index: oldJson.index,
        name: oldJson.name,
        offset: [oldJson.offset],
        path: path,
        subareas: oldJson.subareas || [{
            azimuth: oldJson.azimuth,
            grid: oldJson.modulesData,
            inclination: oldJson.inclination,
            inset: oldJson.inset,
            modelId: oldJson.model,
            placement: oldJson.placement,
            structure: oldJson.structure,
            totalInset: oldJson.totalInset,
            useShadowsCalculation: oldJson.useShadowsCalculation
        }]
    };
    return new EZModelArea(scene, roof, data);
};

/**
 * Method to create a area using data from the new (v3) model
 *
 * @param  {EZModelScene} scene - Main container for 3DLayout project model.
 * @param  {EZModelRoof} roof   - The parent of the area.
 * @param  {object} newJson     - JSON with the data of the area.
 * @param  {boolean} clone      - Is the area being created as a clone?
 *
 * @return {EZModelArea} The new area created.
 */
EZFactoryArea.prototype.createAreaFromV3 = function(scene, roof, newJson, clone) {
    const data = EZModelUtils.omitDeep(newJson);
    if (data.path) {
        const vertices = [];
        data.path.vertices.forEach(function(vertex) {
            const point = new EZModelLocation(
                scene,
                vertex.lng,
                vertex.lat,
                {id: vertex.id}
            );
            vertices.push(point);
        });
        data.path = new EZModelPath(scene, vertices, data.path);
    }
    if (clone) {
        delete data.index;
        delete data.name;
    }
    return new EZModelArea(scene, roof, data, clone);
};

/**
 * Method to create a area from scratch (using a path)
 *
 * @param  {EZModelScene} scene - Main container for 3DLayout project model.
 * @param  {EZModelRoof} roof   - The parent of the area.
 * @param  {EZModelPath} path   - Path with the vertices of the area.
 *
 * @return {EZModelArea} The new area created.
 */
EZFactoryArea.prototype.createAreaFromScratch = function(scene, roof, path) {
    const data = {
        path: path
    };
    return new EZModelArea(scene, roof, data);
};

/**
 * Method to get the fields about this class.
 * @param  {array} fields   - **Required**. The fields to get.
 * @param  {boolean} temp   - Boolean to add properties to getJson for undo/redo feature (isCreated, isEditing, ...).
 * @return {object} values  - Object with the fields to getted.
 */
EZModelArea.prototype.getJson = function(fields, temp) {
    if (!fields) {
        fields = [
            'angle',
            'disabled',
            'populated',
            'id',
            'inclination',
            'index',
            'linkedRoof',
            'offset',
            'path',
            'subareas'
            // DEPRECATED
            // 'name',
        ];
        if (this.customProperties) {
            fields.push('customProperties');
            fields = fields.concat(this.customProperties);
        }
    }
    const output = EZModelUtils.getSeed(this, fields, ['parent'], temp);
    output['version'] = ez3dScene.version;
    return output;
};

/**
 * EZFactoryBuilding
 * @class
 * @classdesc This is the **EZFactoryBuilding** class.
 *
 * @description This is the constructor of the **EZFactoryBuilding** class.
 */
function EZFactoryBuilding() {}

/**
 * Method to create a new building
 *
 * @param  {EZModelScene}   scene   - Main container for 3DLayout project model.
 * @param  {object}         param   - JSON or {@link EZModelPath} of the building.
 * @param  {boolean}        clone   - Is the building being created as a clone?
 *
 * @return {EZModelBuilding}    The new building created.
 */
EZFactoryBuilding.prototype.createBuilding = function(scene, param, clone) {
    let newBuilding;
    if (param === undefined || param instanceof EZModelPath) {
        // param === (EZModelPath) or undefined
        newBuilding = this.createBuildingFromScratch(scene, param);
    } else if (param && param instanceof EZModelBuilding) {
        // param === (EZModelBuilding)
        newBuilding = this.createBuildingFromV3(scene, param, true);
    } else if (param && param.version && param.version >= 3.0) {
        // param === v3 JSON
        newBuilding = this.createBuildingFromV3(scene, param, false);
    } else if (param && param.version === undefined) {
        // param === v2 JSON
        newBuilding = this.createBuildingFromV2(scene, param);
    } else {
        console.error('Error creating Building with param ' + param);
    }
    return newBuilding;
};

/**
 * Method to create a building using data from the old (v2) model
 *
 * @param  {EZModelScene}   scene   - Main container for 3DLayout project model.
 * @param  {object}         oldJson - JSON with the data of the building.
 *
 * @return {EZModelBuilding}    The new building created.
 */
EZFactoryBuilding.prototype.createBuildingFromV2 = function(scene, oldJson) {
    let path;
    if (oldJson.vertices) {
        const vertices = [];
        oldJson.vertices.forEach(function(vertex) {
            const point = new EZModelLocation(
                scene,
                vertex.lng,
                vertex.lat
            );
            vertices.push(point);
        });
        path = new EZModelPath(scene, vertices);
        path = path.forceClockWise(true);
    }
    oldJson.roof.areas = oldJson.areas;
    const data = {
        height: oldJson.height,
        id: oldJson.id,
        keepouts: oldJson.keepouts,
        name: oldJson.name,
        path: path,
        regular: oldJson.regular,
        roofs: [oldJson.roof]
    };
    return new EZModelBuilding(scene, data);
};

/**
 * Method to create a building using data from the new (v3) model
 *
 * @param  {EZModelScene}   scene   - Main container for 3DLayout project model.
 * @param  {object}         newJson - JSON with the data of the building.
 * @param  {boolean}        clone   - Is the building being created as a clone?
 *
 * @return {EZModelBuilding}    The new building created.
 */
EZFactoryBuilding.prototype.createBuildingFromV3 = function(scene, newJson, clone) {
    const data = EZModelUtils.omitDeep(newJson);
    if (data.path) {
        const vertices = [];
        data.path.vertices.forEach(function(vertex) {
            const point = new EZModelLocation(
                scene,
                vertex.lng,
                vertex.lat,
                {id: vertex.id}
            );
            vertices.push(point);
        });
        data.path = new EZModelPath(scene, vertices, data.path);
    }
    if (clone) {
        delete data.index;
        delete data.name;
    }
    return new EZModelBuilding(scene, data, clone);
};

/**
 * Method to create a building from scratch (using a path)
 *
 * @param  {EZModelScene}   scene   - Main container for 3DLayout project model.
 * @param  {EZModelPath}    path    - Path with the vertices of the building.
 *
 * @return {EZModelBuilding}    The new building created.
 */
EZFactoryBuilding.prototype.createBuildingFromScratch = function(scene, path) {
    const data = {
        path: path
    };
    return new EZModelBuilding(scene, data);
};

/**
 * Method to get the fields about this class.
 * @param  {array} fields               - The fields to get.
 * @param  {boolean} temp               - Boolean to add isCreated, isEditing, ...).
 * @return {object} values              - Object with the fields to getted.
 */
EZModelBuilding.prototype.getJson = function(fields, temp) {
    if (!fields) {
        fields = [
            'color',
            'height',
            'id',
            'index',
            'keepouts',
            'name',
            'offset',
            'padding',
            'path',
            'populated',
            'regular',
            'ridge',
            'roofs',
            // OPTIONAL
            'systemInfo',
            'texture'
        ];
        if (temp) {
            fields.push('isCreated');
            fields.push('isEditing');
        }
        if (this.customProperties) {
            fields.push('customProperties');
            fields = fields.concat(this.customProperties);
        }
    }
    const output = EZModelUtils.getSeed(this, fields, ['parent'], temp);
    output['version'] = ez3dScene.version;
    return output;
};

/**
 * EZFactoryKeepout
 * @class
 * @classdesc This is the **EZFactoryKeepout** class.
 *
 * @description This is the constructor of the **EZFactoryKeepout** class.
 */
function EZFactoryKeepout() {}

/**
 * Method to create a new keepout
 *
 * @param  {EZModelScene}       scene       - Main container for 3DLayout project model.
 * @param  {EZModelBuilding}    building    - The parent of the keepout.
 * @param  {object}             param       - JSON or {@link EZModelPath} of the keepout.
 *
 * @return {EZModelKeepout} The new keepout created.
 */
EZFactoryKeepout.prototype.createKeepout = function(scene, building, param) {
    let newKeepout;
    if (param === undefined || param instanceof EZModelPath) {
        // param === (EZModelPath) or undefined
        newKeepout = this.createKeepoutFromScratch(scene, building, param);
    } else if (param && param instanceof EZModelKeepout) {
        // param === (EZModelKeepout)
        newKeepout = this.createKeepoutFromV3(scene, building, param, true);
    } else if (param && param.version && param.version >= 3.0) {
        // param === v3 JSON
        newKeepout = this.createKeepoutFromV3(scene, building, param, false);
    } else if (param && param.version === undefined) {
        // param === v2 JSON
        newKeepout = this.createKeepoutFromV2(scene, building, param);
    } else {
        console.error('Error creating Keepout with param ' + param);
    }
    return newKeepout;
};

/**
 * Method to create a keepout using data from the old (v2) model
 *
 * @param  {EZModelScene}       scene       - Main container for 3DLayout project model.
 * @param  {EZModelBuilding}    building    - The parent of the keepout.
 * @param  {object}             oldJson     - JSON with the data of the keepout.
 *
 * @return {EZModelKeepout} The new keepout created.
 */
EZFactoryKeepout.prototype.createKeepoutFromV2 = function(scene, building, oldJson) {
    let path;
    if (oldJson.vertices) {
        const vertices = [];
        oldJson.vertices.forEach(function(vertex) {
            const point = new EZModelLocation(
                scene,
                vertex.lng,
                vertex.lat
            );
            vertices.push(point);
        });
        path = new EZModelPath(scene, vertices);
        path = path.forceClockWise(true);
    }
    const data = {
        height: oldJson.height,
        id: oldJson.id,
        invisible: oldJson.invisibleKeepout,
        name: oldJson.name,
        offset: [oldJson.offset],
        path: path
    };
    // exceptions
    data.height = (data.height < 0.1) ? 0.1 : data.height;
    // @todo delete this testing variables
    if (oldJson.horizontal !== undefined) {
        data.type = (oldJson.horizontal) ? 'vertical' : 'inclined';
    }
    return new EZModelKeepout(scene, building, data);
};

/**
 * Method to create a keepout using data from the new (v3) model
 *
 * @param  {EZModelScene}       scene       - Main container for 3DLayout project model.
 * @param  {EZModelBuilding}    building    - The parent of the keepout.
 * @param  {object}             newJson     - JSON with the data of the keepout.
 * @param  {boolean}            clone       - Is the keepout being created as a clone?
 *
 * @return {EZModelKeepout} The new keepout created.
 */
EZFactoryKeepout.prototype.createKeepoutFromV3 = function(scene, building, newJson, clone) {
    const data = EZModelUtils.omitDeep(newJson);
    // path
    if (data.path) {
        const vertices = [];
        data.path.vertices.forEach(function(vertex) {
            const point = new EZModelLocation(
                scene,
                vertex.lng,
                vertex.lat,
                {id: vertex.id}
            );
            vertices.push(point);
        });
        data.path = new EZModelPath(scene, vertices, data.path);
    }
    // exceptions
    data.height = (data.height < 0.1) ? 0.1 : data.height;
    if (data.visible) {
        data.invisible = data.visible;
        delete data.visible;
    }
    // clone
    if (clone) {
        delete data.index;
        delete data.name;
    }
    return new EZModelKeepout(scene, building, data, clone);
};

/**
 * Method to create a keepout from scratch (using a path)
 *
 * @param  {EZModelScene}       scene       - Main container for 3DLayout project model.
 * @param  {EZModelBuilding}    building    - The parent of this roof.
 * @param  {EZModelPath}        path        - Path with the vertices of the keepout.
 *
 * @return {EZModelKeepout} The new keepout created.
 */
EZFactoryKeepout.prototype.createKeepoutFromScratch = function(scene, building, path) {
    const data = {
        path: path
    };
    return new EZModelKeepout(scene, building, data);
};


/**
 * Method to get the fields about this class.
 * @param  {array} fields   - **Required**. The fields to get.
 * @param  {boolean} temp   - Boolean to add properties to getJson for undo/redo feature (isCreated, isEditing, ...).
 * @return {object} values  - Object with the fields to getted.
 */
EZModelKeepout.prototype.getJson = function(fields, temp) {
    if (!fields) {
        fields = [
            'color',
            'crop',
            'height',
            'id',
            'index',
            'invisible',
            'name',
            'offset',
            // 'outside',
            'path',
            'regular',
            'type'
        ];
        if (temp) {
            fields.push('isCreated');
            fields.push('isEditing');
        }
    }
    const output = EZModelUtils.getSeed(this, fields, ['parent'], temp);
    output['version'] = ez3dScene.version;
    return output;
};

// EZModelPath
function EZFactoryPath() {}
EZFactoryPath.prototype.createPath = function(scene, parent, param) {
    var data = EZModelUtils.omitDeep(param);

    var vertices = [];
    data.vertices.forEach(function(vertex) {
        var point = new EZModelLocation(
            scene,
            vertex.lng,
            vertex.lat,
            {id: vertex.id}
        );
        vertices.push(point);
    });

    return new EZModelPath(scene, vertices, data);
};

// EZModelLocation
function EZFactoryLocation() {}
EZFactoryLocation.prototype.createLocation = function(scene, parent, param) {
    return new EZModelLocation(scene, param.lng, param.lat, param);
};

/**
 * EZFactoryRoof
 * @class
 * @classdesc This is the **EZFactoryRoof** class.
 *
 * @description This is the constructor of the **EZFactoryRoof** class.
 */
function EZFactoryRoof() {
    //
}

/**
 * Method to create a new roof
 *
 * @param  {EZModelScene} scene         - Main container for 3DLayout project model.
 * @param  {EZModelBuilding} building   - The parent of this roof.
 * @param  {object} param               - JSON or Path of the roof.
 *
 * @return {EZModelRoof} The new roof created.
 */
EZFactoryRoof.prototype.createRoof = function(scene, building, param) {
    let newRoof;
    if (param === undefined || param instanceof EZModelPath) {
        // param === (EZModelPath)
        newRoof = this.createRoofFromScratch(scene, building, param);
    } else if (param && param instanceof EZModelRoof) {
        // param === (EZModelRoof)
        newRoof = this.createRoofFromV3(scene, building, param, true);
    } else if (param && param.version && param.version >= 3.0) {
        // param === v3 JSON
        newRoof = this.createRoofFromV3(scene, building, param, false);
    } else if (param && param.version === undefined) {
        // param === v2 JSON
        newRoof = this.createRoofFromV2(scene, building, param);
    } else {
        console.error('Error creating Roof with param ' + param);
    }
    return newRoof;
};

/**
 * Method to create a roof using data from the old (v2) model
 *
 * @param  {EZModelScene} scene         - Main container for 3DLayout project model.
 * @param  {EZModelBuilding} building   - The parent of this roof.
 * @param  {object} oldJson             - JSON with the data of the roof.
 *
 * @return {EZModelRoof} The new roof created.
 */
EZFactoryRoof.prototype.createRoofFromV2 = function(scene, building, oldJson) {
    const roofPoints = [];
    if (oldJson.points) {
        oldJson.points.forEach(function(point) {
            roofPoints.push(new EZModelLocation(scene, point.lng, point.lat));
        });
    }
    const path = (oldJson.path) ? oldJson.path
        : new EZModelPath(scene, building.path.cloneVertices());
    const data = {
        areas: oldJson.areas,
        disabled: oldJson.disabled,
        height: (oldJson.height)
            ? oldJson.height : oldJson.heightInMeters,
        id: oldJson.id,
        inclination: oldJson.inclination,
        material: oldJson.material,
        name: oldJson.name,
        orientation: oldJson.orientation,
        path: path,
        roofPoints: (oldJson.roofPoints)
            ? oldJson.roofPoints : roofPoints,
        type: oldJson.type
    };
    return new EZModelRoof(scene, building, data);
};

/**
 * Method to create a roof using data from the new (v3) model
 *
 * @param  {EZModelScene} scene         - Main container for 3DLayout project model.
 * @param  {EZModelBuilding} building   - The parent of this roof.
 * @param  {object} newJson             - JSON with the data of the roof.
 * @param  {boolean} clone              - Is this roof being created as a clone?
 *
 * @return {EZModelRoof} The new roof created.
 */
EZFactoryRoof.prototype.createRoofFromV3 = function(scene, building, newJson, clone) {
    const data = EZModelUtils.omitDeep(newJson);
    let vertices;
    // path
    if (data.path) {
        vertices = [];
        data.path.vertices.forEach(function(vertex) {
            const point = new EZModelLocation(
                scene,
                vertex.lng,
                vertex.lat,
                {id: vertex.id}
            );
            vertices.push(point);
        });
        data.path = new EZModelPath(scene, vertices, data.path);
    }
    // roofPoints
    if (data.roofPoints) {
        vertices = [];
        data.roofPoints.forEach(function(vertex) {
            const point = new EZModelLocation(
                scene,
                vertex.lng,
                vertex.lat,
                {id: vertex.id}
            );
            vertices.push(point);
        });
        data.roofPoints = vertices;
    }
    if (clone) {
        delete data.index;
        delete data.name;
    }
    return new EZModelRoof(scene, building, data, clone);
};

/**
 * Method to create a roof from scratch (using a path)
 *
 * @param  {EZModelScene} scene - Main container for 3DLayout project model.
 * @param  {EZModelBuilding} building   - The parent of this roof.
 * @param  {EZModelPath} path   - Path with the vertices of the roof.
 *
 * @return {EZModelRoof} The new roof created.
 */
EZFactoryRoof.prototype.createRoofFromScratch = function(scene, building, path) {
    const data = {
        path: path
    };
    return new EZModelRoof(scene, building, data);
};

/**
 * Method to get the fields about this class.
 * @param  {array} fields   - **Required**. The fields to get.
 * @param  {boolean} temp   - Boolean to add isCreated, isEditing, ...
 * @return {object} values  - Object with the fields to getted.
 */
EZModelRoof.prototype.getJson = function(fields, temp) {
    if (!fields) {
        fields = [
            'areas',
            'areaTypes',
            'baseHeight',
            'disabled',
            'elevation',
            'geometry',
            'id',
            'inclination',
            'index',
            'link',
            'material',
            'name',
            'orientation',
            'path',
            'roofPoints',
            'roofPointsSymmetry',
            'topPoint',
            'type',
            // DEPRECATED
            // 'offset',
        ];
        if (temp) {
            fields.push('isCreated');
            fields.push('isEditing');
        }
        if (this.customProperties) {
            fields.push('customProperties');
            fields = fields.concat(this.customProperties);
        }
    }
    const output = EZModelUtils.getSeed(this, fields, ['parent'], temp);
    output['version'] = ez3dScene.version;
    return output;
};

/**
 * EZFactorySubarea
 * @class
 * @classdesc This is the **EZFactorySubarea** class.
 *
 * @description This is the constructor of the **EZFactorySubarea** class.
 */
function EZFactorySubarea() {}

/**
 * Method to create a new subarea
 *
 * @param  {EZModelScene} scene - Main container for 3DLayout project model.
 * @param  {EZModelArea} area   - The parent of this subarea.
 * @param  {object} param       - JSON or Path of the subarea.
 *
 * @return {EZModelSubarea} The new subarea created.
 */
EZFactorySubarea.prototype.createSubarea = function(scene, area, param) {
    let newSubarea;
    if (param === undefined || param instanceof EZModelPath) {
        // param === (EZModelPath)
        newSubarea = this.createSubareaFromScratch(scene, area, param);
    } else if (param && param instanceof EZModelSubarea) {
        // param === (EZModelSubarea)
        newSubarea = this.createSubareaFromV3(scene, area, param, true);
    } else if (param && param.version && param.version >= 3.0) {
        // param === v3 JSON
        newSubarea = this.createSubareaFromV3(scene, area, param, false);
    } else if (param && param.version === undefined) {
        // param === v2 JSON
        newSubarea = this.createSubareaFromV2(scene, area, param);
    } else {
        console.error('Error creating Subarea with param ' + param);
    }
    return (newSubarea.fromScratchSplit === undefined) ? newSubarea : undefined;
};

/**
 * Method to create a subarea using data from the old (v2) model
 *
 * @param  {EZModelScene} scene - Main container for 3DLayout project model.
 * @param  {EZModelArea} area   - The parent of this subarea.
 * @param  {object} oldJson     - JSON with the data of the subarea.
 *
 * @return {EZModelSubarea} The new subarea created.
 */
EZFactorySubarea.prototype.createSubareaFromV2 = function(scene, area, oldJson) {
    let path;
    if (oldJson.verticesOrtoDeg) {
        const vertices = [];
        oldJson.verticesOrtoDeg.forEach(function(vertex) {
            const point = new EZModelLocation(
                scene,
                vertex.lng,
                vertex.lat
            );
            vertices.push(point);
        });
        path = new EZModelPath(scene, vertices);
    }
    if (!Array.isArray(oldJson.grid) && oldJson.modulesData) {
        oldJson.grid = oldJson.modulesData;
    }
    if (oldJson.modelId === undefined && oldJson.model) {
        oldJson.modelId = oldJson.model.id;
    }
    const data = {
        disabled: oldJson.disabled,
        id: oldJson.id,
        index: oldJson.index,
        name: oldJson.name,
        offset: [0],
        path: path,
        system: {
            azimuth: oldJson.azimuth,
            grid: oldJson.grid,
            inclination: oldJson.inclination,
            inset: oldJson.inset,
            modelId: oldJson.modelId,
            placement: oldJson.placement,
            structure: oldJson.structure,
            totalInset: oldJson.totalInset,
            useShadowsCalculation: false
        }
    };
    return new EZModelSubarea(scene, area, data);
};

/**
 * Method to create a subarea using data from the new (v3) model
 *
 * @param  {EZModelScene} scene - Main container for 3DLayout project model.
 * @param  {EZModelArea} area   - The parent of this subarea.
 * @param  {object} newJson     - JSON with the data of the subarea.
 * @param  {boolean} clone      - Is this subarea being created as a clone?
 *
 * @return {EZModelSubarea} The new subarea created.
 */
EZFactorySubarea.prototype.createSubareaFromV3 = function(scene, area, newJson, clone) {
    const data = EZModelUtils.omitDeep(newJson);
    if (data.path) {
        const vertices = [];
        data.path.vertices.forEach(function(vertex) {
            const point = new EZModelLocation(
                scene,
                vertex.lng,
                vertex.lat,
                {id: vertex.id}
            );
            vertices.push(point);
        });
        data.path = new EZModelPath(scene, vertices, data.path);
    }
    if (clone) {
        delete data.index;
        delete data.name;
    }
    return new EZModelSubarea(scene, area, data, clone);
};

/**
 * Method to create a subarea from scratch (using a path)
 *
 * @param  {EZModelScene} scene - Main container for 3DLayout project model.
 * @param  {EZModelArea} area   - The parent of this subarea.
 * @param  {EZModelPath} path   - Path with the vertices of the subarea.
 *
 * @return {EZModelSubarea} The new subarea created.
 */
EZFactorySubarea.prototype.createSubareaFromScratch = function(scene, area, path) {
    const data = {
        path: path
    };
    return new EZModelSubarea(scene, area, data);
};

/**
 * Method to get the fields about this class.
 * @param  {array} fields   - **Required**. The fields to get.
 * @param  {boolean} temp   - Boolean to add isCreated, isEditing, ...
 * @return {object} values  - Object with the fields to getted.
 */
EZModelSubarea.prototype.getJson = function(fields, temp) {
    if (!fields) {
        fields = [
            'disabled',
            'fromScratch',
            'id',
            'index',
            'offset',
            'path',
            'regular',
            'system'
            // DEPRECATED
            // 'name',
        ];
        if (temp) {
            fields.push('isCreated');
            fields.push('isEditing');
        }
        if (this.customProperties) {
            fields.push('customProperties');
            fields = fields.concat(this.customProperties);
        }
    }
    const output = EZModelUtils.getSeed(this, fields, ['parent'], temp);
    output['version'] = ez3dScene.version;
    return output;
};

/**
 * EZFactorySystem
 * @class
 * @classdesc This is the **EZFactorySystem** class.
 *
 * @description This is the constructor of the **EZFactorySystem** class.
 */
function EZFactorySystem() {}

/**
 * Method to create a new system
 *
 * @param  {EZModelScene} scene     - Main container for 3DLayout project model.
 * @param  {EZModelSubarea} subarea - The parent of this system.
 * @param  {object} param           - JSON or Path of the system.
 *
 * @return {EZModelSystem} The new system created.
 */
EZFactorySystem.prototype.createSystem = function(scene, subarea, param) {
    let newSystem;
    if (param === undefined || param instanceof EZModelPath) {
        // param === (EZModelPath)
        newSystem = this.createSystemFromScratch(scene, subarea, param);
    } else if (param && param instanceof EZModelSystem) {
        // param === (EZModelSystem)
        newSystem = this.createSystemFromV3(scene, subarea, param, true);
    } else if (param && param.version && param.version >= 3.0) {
        // param === v3 JSON
        newSystem = this.createSystemFromV3(scene, subarea, param, false);
    } else if (param && param.version === undefined) {
        // param === v2 JSON
        newSystem = this.createSystemFromV2(scene, subarea, param);
    } else {
        console.error('Error creating System with param ' + param);
    }
    return newSystem;
};

/**
 * Method to create a system using data from the old (v2) model
 *
 * @param  {EZModelScene} scene     - Main container for 3DLayout project model.
 * @param  {EZModelSubarea} subarea - The parent of this system.
 * @param  {object} oldJson         - JSON with the data of the system.
 *
 * @return {EZModelSystem} The new system created.
 */
EZFactorySystem.prototype.createSystemFromV2 = function(scene, subarea, oldJson) {
    let color;
    let grid = {};

    if (oldJson.grid && oldJson.grid.length) {
        const delta = {
            col: oldJson.grid[0].fixedCol,
            row: oldJson.grid[0].fixedRow,
            x: oldJson.grid[0].x,
            y: oldJson.grid[0].y
        };
        grid = {rows: []};
        // Getting system literals
        oldJson.grid.forEach(function(module) {
            // Row
            if (!grid.rows[module.fixedRow]) {
                for (let y = (module.fixedRow - grid.rows.length); y >= 0; y--) {
                    grid.rows.push({literal: []});
                }
            }
            // Col
            if (!grid.rows[module.fixedRow].literal[module.fixedCol]) {
                grid.rows.forEach(function(row) {
                    for (let x = (module.fixedCol - row.literal.length); x >= 0; x--) {
                        row.literal.push(0);
                    }
                });
            }
            grid.rows[module.fixedRow].literal[module.fixedCol] = 1;
            if ((module.x < delta.x) && (module.y < delta.y)) {
                delta.col = module.fixedCol;
                delta.row = module.fixedRow;
                delta.x = module.x;
                delta.y = module.y;
            }
        });
        grid.rows.forEach(function(row) {
            row.literal.reverse();
        });
        grid.rows.reverse();

        // Getting system color
        color = oldJson.grid[0].color;

        // Setting grid delta
        delta.col = (grid.rows[0].literal.length - 1) - delta.col;
        delta.row = (grid.rows.length - 1) - delta.row;
        delta.y = -delta.y;
        grid.delta = delta;
    } else {
        grid = {rows: [{literal: [0]}]};
    }
    const data = {
        azimuth: oldJson.azimuth,
        color: color,
        grid: grid,
        inclination: oldJson.inclination,
        inset: oldJson.inset,
        modelId: oldJson.modelId,
        placement: oldJson.placement,
        structure: oldJson.structure,
        // totalInset: oldJson.totalInset,
        useShadowsCalculation: oldJson.useShadowsCalculation
    };
    return new EZModelSystem(scene, subarea, data);
};

/**
 * Method to create a system using data from the new (v3) model
 *
 * @param  {EZModelScene} scene     - Main container for 3DLayout project model.
 * @param  {EZModelSubarea} subarea - The parent of this system.
 * @param  {object} newJson         - JSON with the data of the system.
 * @param  {boolean} clone          - Is this system being created as a clone?
 *
 * @return {EZModelSystem} The new system created.
 */
EZFactorySystem.prototype.createSystemFromV3 = function(scene, subarea, newJson, clone) {
    const data = EZModelUtils.omitDeep(newJson);
    // path
    if (data.path) {
        const vertices = [];
        data.path.vertices.forEach(function(vertex) {
            const point = new EZModelLocation(
                scene,
                vertex.lng,
                vertex.lat,
                {id: vertex.id}
            );
            vertices.push(point);
        });
        data.path = new EZModelPath(scene, vertices, data.path);
    }
    // exceptions
    if (data.testing) {
        data.rotatedData = data.testing;
        delete data.testing;
    }
    return new EZModelSystem(scene, subarea, data, clone);
};

/**
 * Method to create a system from scratch (using a path)
 *
 * @param  {EZModelScene} scene     - Main container for 3DLayout project model.
 * @param  {EZModelSubarea} subarea - The parent of this system.
 * @param  {EZModelPath} path       - Path with the vertices of the system.
 *
 * @return {EZModelSystem} The new system created.
 */
EZFactorySystem.prototype.createSystemFromScratch = function(scene, subarea, path) {
    const data = {
        path: path
    };
    return new EZModelSystem(scene, subarea, data);
};

EZModelSystem.prototype.getJson = function(fields) {
    if (!fields) {
        fields = [
            'azimuth',
            'dilatationLines',
            'firstRowInverted',
            'grid',
            'id',
            'inclination',
            'inset',
            'modelId',
            'name',
            'path',
            'pivotingPoint',
            'placement',
            'rotatedData',
            'sails',
            'staggered',
            'startingPoint',
            'structure',
            'useShadowsCalculation',
            'color',

            'totalInset',
        ];
    }
    const output = EZModelUtils.getSeed(this, fields, ['parent']);
    output['version'] = ez3dScene.version;
    return output;
};

/**
 * EZFactoryTree
 * @class
 * @classdesc This is the **EZFactoryTree** class.
 *
 * @description This is the constructor of the **EZFactoryTree** class.
 */
function EZFactoryTree() {}

/**
 * Method to create a new tree
 *
 * @param  {EZModelScene}   scene   - Main container for 3DLayout project model.
 * @param  {object}         param   - JSON or {@link EZModelLocation} of the tree.
 *
 * @return {EZModelTree}    The new tree created.
 */
EZFactoryTree.prototype.createTree = function(scene, param) {
    let newTree;
    if (param === undefined || param instanceof EZModelLocation) {
        // param === (EZModelLocation) or undefined
        newTree = this.createTreeFromScratch(scene, param);
    } else if (param && param instanceof EZModelTree) {
        // param === (EZModelTree)
        newTree = this.createTreeFromV3(scene, param, true);
    } else if (param && param.version && param.version >= 3.0) {
        // param === v3 JSON
        newTree = this.createTreeFromV3(scene, param, false);
    } else if (param && param.version === undefined) {
        // param === v2 JSON
        newTree = this.createTreeFromV2(scene, param);
    } else {
        console.error('Error creating Tree with param ' + param);
    }
    return newTree;
};

/**
 * Method to create a tree using data from the old (v2) model
 *
 * @param  {EZModelScene}   scene   - Main container for 3DLayout project model.
 * @param  {object}         oldJson - JSON with the data of the tree.
 *
 * @return {EZModelTree}    The new tree created.
 */
EZFactoryTree.prototype.createTreeFromV2 = function(scene, oldJson) {
    const position = new EZModelLocation(
        scene,
        oldJson.position.lng,
        oldJson.position.lat
    );
    const data = {
        height: oldJson.height,
        id: oldJson.id,
        name: oldJson.name,
        position: position
    };
    return new EZModelTree(scene, data);
};

/**
 * Method to create a tree using data from the new (v3) model
 *
 * @param  {EZModelScene} scene - Main container for 3DLayout project model.
 * @param  {object} newJson     - JSON with the data of the tree.
 * @param  {boolean} clone      - Is this tree being created as a clone?
 *
 * @return {EZModelTree}    The new tree created.
 */
EZFactoryTree.prototype.createTreeFromV3 = function(scene, newJson, clone) {
    const data = EZModelUtils.omitDeep(newJson);
    data.position = new EZModelLocation(
        scene,
        newJson.position.lng,
        newJson.position.lat,
        {id: newJson.position.id}
    );
    if (clone) {
        delete data.index;
        delete data.name;
    }
    return new EZModelTree(scene, data, clone);
};

/**
 * Method to create a tree from scratch (using a path)
 *
 * @param  {EZModelScene} scene         - Main container for 3DLayout project model.
 * @param  {EZModelLocation} location   - {@link EZModelLocation} with the position of the tree.
 *
 * @return {EZModelTree}    The new tree created.
 */
EZFactoryTree.prototype.createTreeFromScratch = function(scene, location) {
    const data = {
        position: location
    };
    return new EZModelTree(scene, data);
};

/**
  * Method to get the fields about this class.
  * @param  {array} fields  - **Required**. The fields to get.
  * @param  {boolean} temp  - Boolean to add isCreated, isEditing, ...
  * @return {object} values - Object with the fields to getted.
  */
EZModelTree.prototype.getJson = function(fields, temp) {
    if (!fields) {
        fields = [
            'crownHigherHeight',
            'crownHigherRadius',
            'crownLowerHeight',
            'crownLowerRadius',
            'crownMiddleHeight',
            'crownMiddleRadius',
            'crownTopHeight',
            'disabled',
            'id',
            'index',
            'name',
            'position',
            'trunkHeight',
            'trunkRadius',
            'shape'
        ];
        if (temp) {
            fields.push('isCreated');
            fields.push('isEditing');
        }
    }
    const output = EZModelUtils.getSeed(this, fields, ['parent'], temp);
    output['version'] = ez3dScene.version;
    return output;
};

EZModelArea.prototype.cloneSubarea = function(args) {
    var self = this;

    var promise = new Promise((resolve, reject) => {
        var cloneElement = {};
        var subareaId = args[0];
        var subarea = ez3dScene.findById(subareaId);
        var offset = args[1];

        if (!offset) {
            offset = {
                lat: -0.000009,
                lng: 0.000009
            };
        }

        cloneElement = ez3dScene.factories['Subarea'].createSubarea(ez3dScene, self, subarea);

        // Clone subarea post process
        subarea.fromScratch = false;
        cloneElement.fromScratch = false;
        self.subareas.push(cloneElement);

        self.moveSubarea([cloneElement.id, offset])
            .then(() => {
                self.interactiveMoveSubarea([cloneElement.id, true])
                    .then(() => {
                        var status = {
                            elementId: self.id,
                            status: 'FINISHED',
                            undo: true
                        };
                        resolve(status);
                    });
            });
    });

    return promise;
};

EZModelArea.prototype.createSubarea = function() {
    var self = this;

    var promise = new Promise((resolve, reject) => {
        var area = ez3dScene.active;
        var newSubarea = ez3dScene.factories['Subarea'].createSubarea(ez3dScene, area);
        newSubarea.isCreated = false;
        ez3dScene.tempObject = newSubarea;
        delete ez3dScene.tempPath;

        /**
         * finishSetActive - This function it is executed when the new subarea it is active
         *
         */
        function finishSetActive() {
            /**
             * finishSubareaCreation - This function it is executed when the user want to finish the subarea creation process
             *
             */
            function finishSubareaCreation() {
                /**
                 * cleanFromScratch - description
                 */
                function cleanFromScratch() {
                    var subareasConlficts = [];
                    var subareasFromScratch = ez3dScene.activeArea.subareas.filter(
                        function(subarea) {
                            return subarea.fromScratch === true && subarea.id !== newSubarea.id;
                        });

                    if (subareasFromScratch && subareasFromScratch.length > 0) {
                        subareasConlficts = subareasFromScratch.filter(
                            function(subarea) {
                                var intersections = ez3dScene.utils.intersectPaths(subarea.path, newSubarea.path);
                                return intersections.length > 0;
                            });
                    }

                    if (subareasConlficts && subareasConlficts.length > 0) {
                        var conflictIndex;
                        var index = newSubarea.index;
                        subareasConlficts.forEach(function(subarea) {
                            conflictIndex = self.subareas.indexOf(subarea);
                            self.subareas.splice(conflictIndex, 1);
                            index = (subarea.index < index) ? subarea.index : index;
                        });
                        newSubarea.index = index;
                        newSubarea.updateSystemOperator(true)
                            .then((result) => {
                                cleanFromScratchFinished();
                            });
                    } else {
                        cleanFromScratchFinished();
                    }
                }

                /**
                 * cleanFromScratchFinished - Function to call after cleanFromScratch()
                 */
                function cleanFromScratchFinished() {
                    ee.emitEvent('finishCreation', [{
                        args: newSubarea
                    }]);
                    ee.emitEvent('unlockInterface');

                    ez3dScene.setActive(newSubarea.id)
                        .then((result) => {
                            ee.emitEvent('hideProgressBar');

                            var status = {
                                status: 'FINISHED',
                                undo: true
                            };
                            resolve(status);
                        });
                }

                /**
                 * fromScratchSplitFinished - Function to call after fromScratchSplit
                 */
                function fromScratchSplitFinished() {
                    newSubarea.isCreated = true;
                    ez3dScene.locations = [];
                    delete ez3dScene.tempPath;

                    cleanFromScratch();
                }

                ee.emitEvent('showProgressBar');
                ee.emitEvent('updateProgressBar', [{
                    title: 'createSubarea'
                }]);
                area.subareas.push(newSubarea);
                delete ez3dScene.tempObject;
                ee.removeListener('cancelSubareaCreation', cancelSubareaCreation);

                var tempPath = EZModelUtils.omitDeep(ez3dScene.tempPath);
                newSubarea.setPath(tempPath, null, true);
                newSubarea.updatePath();
                newSubarea.createSystem();

                // fromScratchSplit
                if (newSubarea.fromScratchSplit === undefined) {
                    newSubarea.updateSystemOperator()
                        .then((result) => {
                            newSubarea.fromScratch = false;
                            fromScratchSplitFinished();
                        });
                } else {
                    // TODO: create array of operators to execute them
                    for (var i = newSubarea.fromScratchSplit; i < area.subareas.length; i++) {
                        area.subareas[i].updateSystem();
                        area.subareas[i].fromScratch = false;
                    }
                    delete newSubarea.fromScratchSplit;
                    fromScratchSplitFinished();
                }
            }

            /**
             * cancelSubareaCreation - This function it is executed when the user want to cancel the subarea creation process
             *
             */
            function cancelSubareaCreation() {
                ee.removeListener('finishSubareaCreation', finishSubareaCreation);
                ee.emitEvent('unlockInterface');

                ez3dScene.setActive(area.id)
                    .then((result) => {
                        ez3dScene.locations = [];
                        delete ez3dScene.tempObject;
                        resolve({status: 'CANCELLED'});
                    });
            }

            ee.emitEvent('lockInterface');
            ee.addOnceListener('finishSubareaCreation', finishSubareaCreation);
            ee.addOnceListener('cancelSubareaCreation', cancelSubareaCreation);
        }

        ez3dScene.setActive(newSubarea.id)
            .then((result) => {
                ee.emitEvent('updateRender',
                    [{draw2d: true, draw3d: false, updatePanels: true, fitRange: true}]);
                finishSetActive();
            });
    });

    return promise;
};

EZModelArea.prototype.cropSubarea = function(args) {
    var self = this;

    var promise = new Promise((resolve, reject) => {
        var subareaId = (Array.isArray(args)) ? args[0] : args;
        var subarea = ez3dScene.findById(subareaId);
        //
        subarea.updatePath(undefined, true);
        subarea.createSystem();
        subarea.updateSystemOperator()
            .then((result) => {
                subarea.fromScratch = false;
                resolve({
                    elementId: self.id,
                    status: 'FINISHED',
                    undo: true
                });
            });
    });
    return promise;
};

EZModelArea.prototype.deleteSubarea = function(args) {
    var self = this;

    var promise = new Promise((resolve, reject) => {
        var subareaId = args[0];
        // var purge = args[1];
        var addFromScratch = args[2];
        var subarea = ez3dScene.findById(subareaId);

        subarea.removeObject();

        /**
         * Set area active after deleting or reset subarea.
         */
        function deleteSubareaFinished() {
            ez3dScene.setActive(self.id)
                .then((result) => {
                    var status = {
                        elementId: self.id,
                        status: 'FINISHED',
                        undo: true
                    };
                    resolve(status);
                });
        }

        if (self.subareas.length <= 0 && addFromScratch !== false) {
            self.createSubareas();
            self.areaUpdateSystemOperator()
                .then((result) => {
                    deleteSubareaFinished();
                });
        } else {
            deleteSubareaFinished();
        }
    });

    return promise;
};

EZModelArea.prototype.editSubarea = function(args) {
    const self = this;

    const promise = new Promise((resolve, reject) => {
        var subareaId = args;
        var subarea = ez3dScene.findById(subareaId);

        // lock interface
        ee.emitEvent('lockInterface');
        subarea.isEditing = true;
        ez3dScene.tempLocations(subarea.path.vertices);
        ez3dScene.unfinishedObject = subarea.id;

        ez3dScene.setActive(subarea.id)
            .then((result) => {
                /**
                 * finishObjectEditing - description
                 *
                 */
                function finishObjectEditing() {
                    ee.emitEvent('showProgressBar');
                    ee.emitEvent('updateProgressBar', [
                        {
                            title: 'editSubarea'
                        }
                    ]);
                    // remove events
                    ee.removeEvent('cancelSubareaEditingOperator');

                    subarea.path.vertices = ez3dScene.locations;
                    ez3dScene.locations = [];
                    subarea.path.setCoordSystem(
                        'building', self, 'EZModelBuilding'
                    );
                    subarea.updatePath();
                    subarea.createSystem();

                    /**
                     * fromScratchSplitFinished - description
                     *
                     */
                    function fromScratchSplitFinished() {
                        subarea.path.updateCalculations();
                        subarea.isEditing = false;
                        delete ez3dScene.unfinishedObject;

                        ee.emitEvent('unlockInterface');

                        ez3dScene.setActive(subarea.id)
                            .then((result) => {
                                ee.emitEvent('finishSubareaEditing');
                                ee.emitEvent('hideProgressBar');
                                var status = {
                                    elementId: self.id,
                                    status: 'FINISHED',
                                    undo: true
                                };
                                resolve(status);
                            });
                    }

                    // fromScratchSplit
                    if (subarea.fromScratchSplit === undefined) {
                        subarea.updateSystemOperator()
                            .then((result) => {
                                subarea.fromScratch = false;
                                fromScratchSplitFinished();
                            });
                    } else {
                        // TODO: create array of operators to execute them
                        for (
                            var i = subarea.fromScratchSplit;
                            i < subarea.subareas.length;
                            i++
                        ) {
                            subarea.subareas[i].updateSystem();
                            subarea.subareas[i].fromScratch = false;
                        }
                        delete subarea.fromScratchSplit;
                        fromScratchSplitFinished();
                    }
                }

                /**
                 * cancelObjectEditing - description
                 *
                 */
                function cancelObjectEditing() {
                    // remove events
                    ee.removeEvent('finishSubareaEditingOperator');
                    subarea.isEditing = false;
                    delete ez3dScene.unfinishedObject;
                    ez3dScene.locations = [];

                    ee.emitEvent('unlockInterface');

                    ez3dScene.setActive(self.id)
                        .then((result)=>{
                            resolve({status: 'CANCELLED'});
                        });
                }
                ee.addOnceListener(
                    'finishSubareaEditingOperator',
                    finishObjectEditing
                );
                ee.addOnceListener(
                    'cancelSubareaEditingOperator',
                    cancelObjectEditing
                );
                ee.emitEvent('updateRender',
                    [{draw2d: true, draw3d: subarea, updatePanels: false, fitRange: false}]);
            });
    });
    return promise;
};

EZModelArea.prototype.interactiveMoveSubarea = function(args) {
    var self = this;
    var promise = new Promise((resolve, reject) => {
        self.subareaToMove = args[0];
        var setInteractiveMove = args[1];

        // Delete subareaToMove property when toggling from 'interactiveMove' to 'standard'
        if (ez3dScene.mode === 'interactiveMove') {
            ee.addOnceListener('modeChanged', function(attr, value) {
                // Avoid deleting subareaToMove property when cloning while moving a subarea
                if (value === 'standard') {
                    delete self.subareaToMove;
                }
            });
        }

        if (setInteractiveMove === undefined) {
            self.setInteractiveMoveToggle();
        } else {
            ee.emitEvent('setInteractiveMove', [setInteractiveMove]);
        }

        var status = {
            elementId: self.id,
            status: 'FINISHED',
            // This operator shouldn't have undo (see moveSubarea operator)
        };

        resolve(status);
    });

    return promise;
};

EZModelArea.prototype.moveSubarea = function(args) {
    var self = this;

    var promise = new Promise((resolve, reject) => {
        var subareaId = args[0];
        var subarea = ez3dScene.findById(subareaId);
        // When cloning a subarea and doing undo and redo, the subareaId doesn't
        // belong to any object because a new subarea with a new id is created.
        if (!subarea) {
            // self.subareaToMove property is initialized in interactiveMoveSubarea operator.
            subarea = ez3dScene.findById(self.subareaToMove);
        }
        var offset = args[1];

        subarea.updatePath(offset);
        subarea.updateSystemGrid([false, false, true])
            .then((result) => {
                subarea.fromScratch = false;
                var status = {
                    elementId: self.id,
                    status: 'FINISHED',
                    undo: true
                };

                resolve(status);
            });
    });

    return promise;
};

EZModelArea.prototype.resetSubareaExceptModel = function(args) {
    var self = this;

    var promise = new Promise((resolve, reject) => {
        var subareaId = args[0];
        // var purge = args[1];
        var addFromScratch = args[2];
        var subarea = ez3dScene.findById(subareaId);

        subarea.removeObject();

        /**
         * resetSubareaExceptModelFinished - description
         */
        function resetSubareaExceptModelFinished() {
            ez3dScene.setActive(self.id)
                .then((result)=>{
                    var status = {
                        elementId: self.id,
                        status: 'FINISHED',
                        undo: true
                    };
                    resolve(status);
                });
        }

        if (self.subareas.length <= 0 && addFromScratch !== false) {
            self.createSubareas();
            self.subareas[0].system.setModel(subarea.system.model.id);
            self.areaUpdateSystemOperator()
                .then((result) => {
                    resetSubareaExceptModelFinished();
                });
        } else {
            resetSubareaExceptModelFinished();
        }
    });

    return promise;
};

EZModelArea.prototype.areaUpdateSystemOperator = function(arg1, arg2) {
    var self = this;

    var promise = new Promise((resolve, reject) => {
        var areaUpdateSystemFunctionFinished = function() {
            resolve({status: 'FINISHED'});
        };

        ee.addOnceListener('areaUpdateSystemFunctionFinished', areaUpdateSystemFunctionFinished);
        self.updateSystem(arg1, arg2);
    });

    return promise;
};

EZModelBuilding.prototype.createKeepout = function(fromBuildingShape) {
    var self = this;

    var promise = new Promise((resolve, reject) => {
        var oldObjectId = self.id;
        var newKeepout = {};
        var building = ez3dScene.activeBuilding;
        var newKeepoutJson;

        newKeepout = ez3dScene.factories['Keepout'].createKeepout(ez3dScene, building);

        newKeepout.isCreated = false;
        ez3dScene.tempObject = newKeepout;
        if (fromBuildingShape === true) {
            ez3dScene.tempLocations(self.customKeepoutPath.vertices);
            delete self.customKeepoutPath;
        } else {
            ez3dScene.tempLocations();
        }

        /**
         * finishSetActive - description
         *
         */
        function finishSetActive() {
            /**
             * finishKeepoutCreation - description
             *
             */
            function finishKeepoutCreation() {
                ee.emitEvent('showProgressBar');
                ee.emitEvent('updateProgressBar', [{
                    title: 'addKeepout'
                }]);
                building.keepouts.push(newKeepout);
                delete ez3dScene.tempObject;
                ee.removeEvent('cancelKeepoutCreation');

                var newPath = new EZModelPath(ez3dScene, ez3dScene.tempPath.vertices);
                newKeepout.setPath(newPath);
                newKeepoutJson = newKeepout.getJson();

                ez3dScene.locations = [];
                delete ez3dScene.tempPath;

                delete ez3dScene.unfinishedObject;

                building.updateKeepoutsOperator()
                    .then((result) => {
                        // Finish
                        newKeepout.isCreated = true;
                        ee.emitEvent('finishCreation', [{
                            args: newKeepout
                        }]);

                        ee.emitEvent('unlockInterface');
                        ez3dScene.setActive(newKeepout.id)
                            .then(()=>{
                                ee.emitEvent('updateRender',
                                    [{draw2d: true, draw3d: false,
                                        updatePanels: true, fitRange: false}]);
                                ee.emitEvent('hideProgressBar');
                                var status = {
                                    status: 'FINISHED',
                                    undo: true
                                };
                                resolve(status);
                            });
                    });
            }

            /**
             * cancelKeepoutCreation - description
             *
             */
            function cancelKeepoutCreation() {
                ee.removeEvent('finishKeepoutCreation');
                ee.emitEvent('unlockInterface');

                ez3dScene.setActive(oldObjectId)
                    .then((result)=>{
                        ez3dScene.locations = [];
                        delete ez3dScene.tempObject;

                        resolve({status: 'CANCELLED'});
                    });
            }

            ee.emitEvent('lockInterface');
            ee.addOnceListener('finishKeepoutCreation', finishKeepoutCreation);
            ee.addOnceListener('cancelKeepoutCreation', cancelKeepoutCreation);

            // Finish keepout creation when creating an O shape building
            if (fromBuildingShape === true) {
                ee.emitEvent('finishKeepoutCreation');
            }
        }

        ez3dScene.setActive(newKeepout.id)
            .then((result)=>{
                finishSetActive();
            });
    });

    return promise;
};

EZModelBuilding.prototype.editBuilding = function() {
    const self = this; // self necessary
    const promise = new Promise((resolve, reject) => {
        self.isEditing = true;
        ez3dScene.tempLocations(self.path.vertices);
        ez3dScene.unfinishedObject = self.id;

        // @todo guardarse el edificio original para restaurarlo si se cancela

        // timeout to allow progressbar to show
        setTimeout(function () {
            ez3dScene.setActive(self.id)
                .then(() => {
                    /**
                     * finishObjectEditing - description
                     */
                    function finishObjectEditing() {
                        const shapeIsTheSame = (pathA, pathB) => {
                            if (pathA === pathB) return true;
                            if (pathA.length !== pathB.length) return false;
                            return _.zip(pathA, pathB)
                                .every(([vertexA, vertexB]) => {
                                    if (vertexA.x !== vertexB.x -
                                        self.path.center.x) return false;
                                    if (vertexA.y !== vertexB.y -
                                        self.path.center.y) return false;
                                    return true;
                                });
                        };

                        if (!shapeIsTheSame(self.path.vertices, ez3dScene.locations)) {
                            // set new path
                            self.path.vertices = ez3dScene.locations;
                            ez3dScene.locations = [];
                            self.path.updateCalculations();
                            self.path.setCoordSystem('building', self, 'EZModelBuilding');
                            if (self.path.regularAngles === false) {
                                self.roofs[0].type = 'flat';
                            }
                            // generate new roof
                            // esto lo que deberia hacer es borrarlos todos y crear uno nuevo
                            // tipo roof fromScratch
                            //
                            // @todo hay que guardarse los tejados antiguos,
                            // por si se cancela toda la operacion
                            self.roofs.forEach(function(roof) {
                                roof.needsUpdate = true;
                                roof.calculateRoof(false, true);
                                delete roof.needsUpdate;
                            });
                        }

                        self.isEditing = false;
                        delete ez3dScene.unfinishedObject;

                        self.editRoof(self.roofs[0].id)
                            .then((result) => {
                                if (result.status === 'CANCELLED') {
                                    ee.emitEvent('cancelBuildingEditingOperator');
                                } else {
                                    ee.emitEvent('showProgressBar');
                                    ee.emitEvent('updateProgressBar', [{
                                        title: 'editBuilding'
                                    }]);
                                    ee.removeEvent('cancelBuildingEditingOperator');
                                    setTimeout(function () {
                                        ez3dScene.setActive(ez3dScene.activeBuilding.id)
                                            .then(() => {
                                                ee.emitEvent('unlockInterface');
                                                const status = {
                                                    status: 'FINISHED',
                                                    undo: true
                                                };
                                                if (ez3dScene.layoutRules.scenePreferences.enablePlayer === true &&
                                                    ez3dScene.layoutRules.scenePreferences.buildingTextures === true) {
                                                    self.getTexture()
                                                        .then(() => {
                                                            ee.emitEvent('hideProgressBar');
                                                            resolve(status);
                                                        });
                                                } else {
                                                    ee.emitEvent('hideProgressBar');
                                                    resolve(status);
                                                }
                                            });
                                    }, 100);
                                }
                            });
                    }

                    /**
                     * cancelObjectEditing - description
                     *
                     */
                    function cancelObjectEditing() {
                        // remove events
                        ee.removeEvent('finishBuildingEditingOperator');
                        // aqui si se ha cancelado y cambiado los vertices,
                        // habria que reestablecer los vertices y los tejados
                        // originales
                        self.isEditing = false;
                        delete ez3dScene.unfinishedObject;
                        ez3dScene.locations = [];

                        ez3dScene.setActive(self.id)
                            .then((result)=>{
                                resolve({status: 'CANCELLED'});
                            });
                    }
                    ee.emitEvent('updateRender',
                        [{draw2d: true, draw3d: true, updatePanels: true, fitRange: false}]);
                    ee.emitEvent('building_editBuildingListener');
                    ee.addOnceListener('finishBuildingEditingOperator', finishObjectEditing);
                    ee.addOnceListener('cancelBuildingEditingOperator', cancelObjectEditing);
                });
        }, 100);
    });

    return promise;
};

EZModelBuilding.prototype.editRoof = function(roofId) {
    // This operator is used for creating and editing a roof
    var self = this;

    var promise = new Promise((resolve) => {
        ez3dScene.unfinishedObject = self.id;
        let selectedRoof;
        if (roofId === undefined) {
            const newRoof = ez3dScene.factories['Roof'].createRoof(ez3dScene, self);
            newRoof.needsUpdate = true;
            self.roofs.push(newRoof);
            selectedRoof = newRoof;
        } else {
            self.roofs.forEach(function(roof) {
                if (roof.id === roofId) selectedRoof = roof;
            });
        }
        const buildingJson = selectedRoof.parent.getJson();
        selectedRoof.isEditing = true;

        // timeout to allow progressbar to show
        setTimeout(function () {
            ez3dScene.setActive(selectedRoof.id)
                .then(() => {
                    /**
                     * finishRoofEditing - description
                     */
                    function finishRoofEditing() {
                        ee.emitEvent('showProgressBar');
                        ee.emitEvent('updateProgressBar', [{
                            title: 'editRoof'
                        }]);
                        ez3dScene.active.isEditing = false;
                        ez3dScene.active.calculateRoof(true);

                        delete ez3dScene.active.needsUpdate;
                        ee.removeEvent('cancelRoofEditOperator');
                        // close building creation before setActive
                        // to trigger close pathEditor
                        self.isCreated = true;
                        ee.emitEvent('finishAreaCreation');

                        ez3dScene.setActive(ez3dScene.activeBuilding.id)
                            .then(() => {
                                var status = {
                                    status: 'FINISHED'
                                };
                                ee.emitEvent('hideProgressBar');
                                ee.emitEvent('editRoof_Finished');
                                resolve(status);
                            });
                    }

                    /**
                     * cancelObjectEditing - description
                     *
                     */
                    function cancelRoofEditing() {
                        // remove events
                        ee.removeEvent('finishRoofEditOperator');
                        selectedRoof = undefined;
                        const index = ez3dScene.buildings.indexOf(self);
                        const oldBuilding = window.EZFactoryBuilding.prototype
                            .createBuilding(ez3dScene, buildingJson);
                        ez3dScene.buildings[index] = oldBuilding;
                        self.updatePath();

                        delete ez3dScene.unfinishedObject;
                        ez3dScene.locations = [];
                        ez3dScene.setActive(self.id)
                            .then(() => {
                                ee.emitEvent('hideProgressBar');
                                ee.emitEvent('editRoof_Cancelled');
                                resolve({status: 'CANCELLED'});
                            });
                    }

                    ee.emitEvent('updateRender',
                        [{draw2d: true, draw3d: self, updatePanels: true, fitRange: false}]);
                    ee.emitEvent('building_editRoofListener');
                    ee.addOnceListener('finishRoofEditOperator', finishRoofEditing);
                    ee.addOnceListener('cancelRoofEditOperator', cancelRoofEditing);
                });
        }, 100);
    });
    return promise;
};

EZModelBuilding.prototype.getTexture = function(generatingAllTextures) {
    var self = this;

    var id = self.id;
    var oldActiveRenderer = undefined;
    var oldActiveMapper = undefined;

    var svgProject = undefined;
    var playerContainer = undefined;
    var range = undefined;

    var timeCounter = undefined;

    var status = undefined;
    var promiseResolve = undefined;

    /**
     * Initialize timeCounter to show widget or send event to remove widget
     * @param  {boolean} createWidget     Create or remove alert widget
     */
    function setTimeCounter(createWidget) {
        if (createWidget) {
            timeCounter = setTimeout(function () {
                ee.emitEvent('manageCancelTexturesWidget', [createWidget]);
            }, ez3dScene.layoutRules.scenePreferences.slowInternetConexionTimeout);
        } else {
            clearInterval(timeCounter);
            ee.emitEvent('manageCancelTexturesWidget', [createWidget]);
        }
    }

    /**
     * draw scaled version of the img in a new 2d canvas
     * @param  {object} img    image dom element
     * @param  {float} ratioX factor for x scale
     * @param  {float} ratioY factor for y scale
     * @param  {boolean} draw   true to append the new canvas in the DOM, false for invisible canvas
     * @param  {string} canvasId     id for the canvas
     * @param  {boolean} texture   true if the canvas is a real size texture
     * @return {object}        2d canvas DOM element
     */
    function resizeImage(img, ratioX, ratioY, draw, canvasId, texture) {
        var canvas = d3.select('#texture-operations-canvas')
            .append('canvas')
            .attr('width', img.width)
            .attr('height', img.height)
            .style('display', function() {
                var value = 'none';
                if (draw) {
                    value = 'inline-block';
                }
                return value;
            })
            .attr('id', canvasId)
            .node();

        if (texture === true) {
            d3.select(canvas)
                .style('margin-right', '5px')
                .style('margin-left', '5px')
                .lower();
        }

        var ctx = canvas.getContext('2d');
        ctx.imageSmoothingEnabled = false;
        var canvasCopy = document.createElement('canvas');
        var copyContext = canvasCopy.getContext('2d');

        canvasCopy.width = img.width;
        canvasCopy.height = img.height;
        copyContext.drawImage(img, 0, 0);

        canvas.width = THREE.Math.floorPowerOfTwo(img.width * ratioX);
        canvas.height = THREE.Math.floorPowerOfTwo(img.height * ratioY);
        ctx.drawImage(canvasCopy, 0, 0, canvasCopy.width, canvasCopy.height,
            0, 0, canvas.width, canvas.height);

        return canvas;
    }

    /**
     * get dataURL png image from canvas
     * @param  {object} canvas a canvas element to adquire image
     * @return {object}        image DOM element
     */
    function getImg (canvas) {
        var url = canvas.toDataURL('image/png');

        var img = document.createElement('img');
        img.width = canvas.width;
        img.height = canvas.height;
        img.src = url;

        return img;
    }

    /**
     * Restore containers and mapper and renderer status
     */
    function restoreContainers() {
        if (generatingAllTextures === false) {
            playerContainer
                .style('min-width', 'inherit')
                .style('min-height', 'inherit')
                .style('width', '100%')
                .style('height', '100%');
            ez3dViewport.ez3dPlayer.activateRenderer(oldActiveRenderer);
            ez3dViewport.ez3dPlayer.activateMapper(oldActiveMapper);
        }
        d3.select('#svgContainer-svgProject')
            .style('display', 'inherit');
        d3.select('#texture-operations-canvas').remove();
        if (generatingAllTextures === false) {
            svgProject.fitRangeToContainer(range);
        }
    }

    /**
     * Function that cancel texture/s generation when waiting for satellite tiles
     */
    function cancelTexture() {
        restoreContainers();
        if (generatingAllTextures === false) {
            delete ez3dScene.cancelTexturesOp;
        }
        setTimeout(function () {
            status = {
                elementId: id,
                status: 'FINISHED'
            };
            promiseResolve(status);
        }, 100);
    }

    /**
     * Function to generate texture, resize it and apply it to the building
     */
    function generateTexture() {
        // Get gl canvas image
        var canvas = playerContainer.select('canvas').node();
        var img = getImg(canvas);

        ee.emitEvent('updateProgressBar', [{
            label2: 'gettingTextureImage'
        }]);

        img.addEventListener('load', function () {
            // Calculate power of two size for the texture
            var widthBase = THREE.Math.floorPowerOfTwo(canvas.width);
            var heightBase = THREE.Math.floorPowerOfTwo(canvas.height);
            // Resize gl image in a 2d canvas
            var canvasBase = resizeImage(img, widthBase / canvas.width, heightBase / canvas.height, true, 'canvas-base');
            ez3dScene.selectedCanvas = canvasBase.id;
            var imgBase = getImg(canvasBase);

            d3.select('#texture-operations-canvas')
                .append('h1')
                .html('image base ' + widthBase + 'x' + heightBase);

            imgBase.addEventListener('load', function () {
                var imgBaseToCompare = canvasBase.getContext('2d').getImageData(0, 0, widthBase, heightBase);
                var oldResponse = 30;

                /**
                 * Recursive function to reduce image resolution
                 * @param  {integer} ratio          reduction ratio
                 * @param  {object} img2           imgae element to operate the scale
                 * @param  {float} changeRatio    value to check diference with original image
                 * @return {float}                changeRatio
                 */
                function reduceImgSize(ratio, img2, changeRatio) {
                    changeRatio = changeRatio || 100;
                    return new Promise(function (textureResolve) {
                        var imgId = 'scale_1-' + ratio;
                        // draw scaled texture
                        img2 = getImg(resizeImage(imgBase, 1 / ratio, 1 / ratio, true, imgId, true));
                        img2.addEventListener('load', function () {
                            // draw enlarged version of scaled texture
                            var i4 = resizeImage(img2, ratio, ratio, true, 'enlarged-' + imgId).getContext('2d').getImageData(0, 0, widthBase, heightBase);
                            var diffCanvas = d3.select('#texture-operations-canvas')
                                .append('canvas')
                                .attr('width', widthBase)
                                .attr('height', heightBase)
                                .style('display', 'inline-block')
                                .node();
                            var diffCtx = diffCanvas.getContext('2d');
                            var diff = diffCtx.createImageData(widthBase, heightBase);
                            var diffNumber = pixelmatch(imgBaseToCompare.data, i4.data, diff.data, widthBase, heightBase, {threshold: 0.1});
                            diffCtx.putImageData(diff, 0, 0);
                            changeRatio = (widthBase * heightBase) / diffNumber;
                            d3.select('#texture-operations-canvas')
                                .append('h2')
                                .attr('imgId', 'title_' + imgId)
                                .html(imgId + ' - ' + widthBase / ratio + 'x' + heightBase / ratio + ' - diff value: ' + parseInt(changeRatio, 10));
                            // console.log(ratio);
                            // console.log('pixels', widthBase * heightBase);
                            // console.log('diff', diffNumber, changeRatio);
                            if (changeRatio > 30) {
                                ez3dScene.selectedCanvas = imgId;
                            }
                            textureResolve(changeRatio);
                        });
                    })
                        .then(function (response) {
                            if (response > 30 && !(response === Infinity && ratio === 2) && !(response <= oldResponse)) {
                                ratio *= 2;
                                oldResponse = response;
                                ee.emitEvent('updateProgressBar', [{
                                    label2: 'reducingTextureImage'
                                }]);

                                return reduceImgSize(ratio, img2, response);
                            }
                            setTimeout(function() {
                                // d3.select('#enlarged-' + ez3dScene.selectedCanvas).style('border', '10px solid green');
                                // d3.select('#' + ez3dScene.selectedCanvas).style('border', '5px solid green');
                                // d3.select('#title_' + ez3dScene.selectedCanvas).style('border', '5px solid green');
                                var texture = d3.select('#' + ez3dScene.selectedCanvas).node().toDataURL('image/jpeg', 0.5);
                                ez3dScene.activeBuilding.texture = texture;
                                restoreContainers();
                                setTimeCounter(false);
                                setTimeout(function () {
                                    status = {
                                        elementId: id,
                                        status: 'FINISHED',
                                        undo: false
                                    };
                                    promiseResolve(status);
                                }, 100);
                            }, 500);
                        });
                }

                var img2;
                var ratio = 1;
                var changeRatio;

                reduceImgSize(ratio, img2, changeRatio);
            });
        });
    }

    /**
     * Wait for satellite tiles
     */
    function waitForSatelliteTiles() {
        // Show cancel button on progress bar
        ee.emitEvent('showProgressBarCancelBtn', [true]);
        // Listener executed when cancel button has been clicked
        var closeProgressBarListener = function() {
            ez3dScene.cancelTexturesOp = true;
            ee.emitEvent('Mapper.AllTilesLoaded');
        };
        ee.addOnceListener('cancelProgressBar', closeProgressBarListener);

        ee.addOnceListener('Mapper.AllTilesLoaded', function() {
            ee.removeListener('cancelProgressBar', closeProgressBarListener);
            setTimeCounter(false);
            // If event was emitted from cancelProgressBar function or from mapper (all satellite tiles loaded)
            if (ez3dScene.cancelTexturesOp) {
                cancelTexture();
            } else {
                // Hide cancel button on progress bar and update
                ee.emitEvent('showProgressBarCancelBtn', [false]);
                ee.emitEvent('updateProgressBar', [{
                    label2: 'satelliteTilesLoaded'
                }]);
                // Hide d3 project container
                d3.select('#svgContainer-svgProject')
                    .style('display', 'none');

                setTimeout(generateTexture, 2000);
            }
        });

        var provider = ez3dViewport.ez3dPlayer.getProvider();
        ez3dViewport.ez3dPlayer.setProvider(provider);
        ee.emitEvent('updateProgressBar', [{
            label2: 'waitingSatelliteTiles'
        }]);
    }

    /**
     * Resize player on target building
     */
    function resizePlayer() {
        ez3dViewport.ez3dPlayer.fitToBounds(svgProject.center, svgProject.widthInMeters);
        var bbox = d3.select('#svgProject-L1_building_' + id).select('path').node().getBoundingClientRect();
        var width = bbox.width;
        var height = bbox.height;
        playerContainer = d3.select('#playerContainer')
            .style('width', width + 'px')
            .style('height', height + 'px')
            .style('min-width', '1px')
            .style('min-height', '1px')
            .style('position', 'relative');

        setTimeout(waitForSatelliteTiles, 1000);
    }

    var promise = new Promise((resolve, reject) => {
        var ez3dViewport = ez3dScene.layoutInstance.ez3dViewport;
        promiseResolve = resolve;

        if (!generatingAllTextures || generatingAllTextures.length === 0) {
            generatingAllTextures = false;
        }

        // Finish operator if it's generating all textures and the cancel button is clicked
        if (ez3dScene.cancelTexturesOp) {
            setTimeCounter(false);
            // Update progress bar text on second building and finish
            if (self.index === 2) {
                ee.emitEvent('updateProgressBar', [{
                    label1: 'cancel'
                }]);
            }
            setTimeout(function() {
                status = {
                    elementId: id,
                    status: 'FINISHED'
                };
                resolve(status);
            }, 100);
        } else {
            setTimeCounter(true);
            if (generatingAllTextures === false) {
                oldActiveRenderer = ez3dScene.layoutRules.scenePreferences.activeRenderer;
                oldActiveMapper = ez3dScene.layoutRules.scenePreferences.activeMapper;
                ee.emitEvent('updateProgressBar', [{
                    title: 'generatingTexture',
                    label1: ez3dScene.activeBuilding.name
                }]);
            }

            // Create div for texture operations
            d3.select('#texture-operations-canvas').remove();
            d3.select('#ez3d-main-container')
                .append('div')
                .attr('id', 'texture-operations-canvas')
                .style('display', 'none')
                .style('width', '100%');

            if (ez3dViewport && ez3dViewport.svgElements && ez3dViewport.svgElements.svgProject) {
                ez3dViewport.ez3dPlayer.activateRenderer(false);
                ez3dViewport.ez3dPlayer.activateMapper(true);

                svgProject = ez3dViewport.svgElements.svgProject;
                range = ez3dScene.utils.calculateBuildingRange(ez3dScene.activeBuilding);
                svgProject.fitRangeToContainerWithoutMargin(range);

                // waits for the fitRangeToContainerWithoutMargin and set render updates...
                setTimeout(resizePlayer, 1000);
            } else {
                setTimeCounter(false);
                status = {
                    elementId: id,
                    status: 'CANCELLED',
                };
                resolve(status);
            }
        }
    });

    return promise;
};

EZModelBuilding.prototype.removeTexture = function() {
    var self = this;

    var promise = new Promise((resolve, reject) => {
        var id = self.id;
        var status;

        if (self.texture) {
            // remove texture
            self.texture = undefined;
        }
        status = {
            elementId: id,
            status: 'FINISHED',
            undo: false
        };

        resolve(status);
    });

    return promise;
};

EZModelBuilding.prototype.updateKeepoutsOperator = function() {
    var promise = new Promise((resolve, reject) => {
        var updateKeepoutsOperatorFinish = function() {
            resolve({status: 'FINISHED'});
        };

        ee.addOnceListener('updateKeepoutsFunctionFinished', updateKeepoutsOperatorFinish);
        this.updateKeepouts();
    });

    return promise;
};

/**
 * EZModelOperator
 * @class
 * @classdesc This is the main operator class. Each operator registered in EZScene-operators-init
 * is instanced as an operator class and the main promise for each operator and the event
 * listeners are created here.
 *
 * @param {string} operatorName  -
 * @param {object} pollObject  -
 * @param {object} postAction  -
 * @param {EZModelScene} ez3dScene -
 */
function EZModelOperator(operatorName, pollObject, postAction, ez3dScene) {
    // ez3dScene = ez3dScene;
    this.pollObject = pollObject;
    this.poll = this.generatePoll(pollObject);
    this.operatorName = operatorName;
    this.operatorClassName = operatorName.split('_')[0];
    this.event = operatorName.split('_')[1];
    this.listener = this.operatorClassName + '_' + this.event + 'Listener';
    this.postAction = postAction;
}

EZModelOperator.prototype.action = function(args) {
    let debugOpTime = 0;
    this.args = (args && args['args'] !== undefined)
        ? args['args'] : [];
    const addHistoryAction = (args && args['undo'] !== undefined)
        ? args['undo'] : ez3dScene.layoutRules.scenePreferences.enableUndoRedo;
    const updateRender = (args && args['updateRender'] !== undefined)
        ? args['updateRender'] : false;
    this.notifications = (args && args['notifications'] !== undefined)
        ? args['notifications'] : this.notifications;
    this.progress = (args && args['progress'] !== undefined)
        ? args['progress'] : this.progress;

    var self = this;
    if (self.poll() === true) {
        if (ez3dScene.promiseCounter < 0) {
            ez3dScene.promiseCounter = 0;
        }

        ez3dScene.promiseCounter += 1;
        // Track the new operator in the list of operator promises
        ez3dScene.operatorPromisesList.push(this.operatorName);
        if (ez3dScene.layoutRules.scenePreferences.debugPromises === true) {
            console.log('[Operator Promises]: ' + this.operatorName + ' was just ADDED TO operatorPromisesList');
        }

        debugOpTime = Date.now();

        if (ez3dScene.layoutRules.scenePreferences.debugPromises === true) {
            console.log(ez3dScene.promiseCounter + ' - ' + self.operatorName);
        }
        ez3dScene.lastOperatorExecuted = self.event;
        // Progress bar
        if (self.progress) {
            var closeProgressBarFinished;
            var closeProgressBarCancelled = function() {
                ee.removeListener(self.event + '_Finished', closeProgressBarFinished);
                ee.emitEvent('hideProgressBar');
            };
            closeProgressBarFinished = function() {
                ee.removeListener(self.event + '_Cancelled', closeProgressBarCancelled);
                ee.emitEvent('hideProgressBar');
            };
            ee.addOnceListener(self.event + '_Finished', closeProgressBarFinished);
            ee.addOnceListener(self.event + '_Cancelled', closeProgressBarCancelled);
        }
        // History
        if (addHistoryAction && ez3dScene.active.id) {
            const root = ez3dScene.history.getRoot(ez3dScene.active);
            ez3dScene.history.action.root = root;
            ez3dScene.history.action.seed = ez3dScene.history.getRootJson(root);
        }
        var promise = new Promise((resolve, reject) => {
            var actionPromise;
            if (this.pollObject.active === '*') {
                actionPromise = ez3dScene[this.event](this.args, this.notifications);
            } else {
                actionPromise = ez3dScene.active[this.event](this.args, this.notifications);
            }
            actionPromise.then((result) => {
                result.event = this.operatorName;
                resolve(result);
            }).catch((error) => {
                error.event = this.operatorName;
                error.args = this.args;
                reject(error);
            });
        });
        // finally handler always passes results and errors to the next handler
        promise.finally(() => {
            ez3dScene.promiseCounter -= 1;
            // Untrack the oldest operator of the same kind
            const foundIndex = ez3dScene.operatorPromisesList.indexOf(this.lastOperatorExecuted);
            const removed = ez3dScene.operatorPromisesList.splice(foundIndex, 1)[0];
            if (ez3dScene.layoutRules.scenePreferences.debugPromises === true) {
                console.log('[Operator Promises]: ' + removed + ' was just REMOVED FROM operatorPromisesList');
                if (ez3dScene.operatorPromisesList.length !== ez3dScene.promiseCounter) {
                    console.warn('[Operator Promises]: Watch out, promiseCounter !== operatorPromisesList.length!');
                    console.warn('[Operator Promises]: ' + ez3dScene.promiseCounter + '!==' + ez3dScene.operatorPromisesList.length);
                }
            }
        }).then((response) => {
            if (ez3dScene.promiseCounter === 0 &&
                ee.getListeners('close-saving-notification').length > 0) {
                ee.emitEvent('close-saving-notification');
            }
            ez3dScene.updateProjectSystemInfo();
            self.postAction();

            // History
            if (addHistoryAction && response.undo) {
                ez3dScene.history.addAction(self);
                // console.warn('YESTORY:', ez3dScene.history);
            } else {
                // console.warn('NOSTORY:', self.operatorName, addHistoryAction, response.undo);
            }

            var object;
            if (self.event === 'setAttribute') {
                object = ez3dScene.findById(self.args[0]);
                self.args.splice(1, 0, object === undefined ? undefined : object.constructor.name);
                self.args.splice(2, 0, object);
            } else if (self.event === 'delete') {
                self.args = [self.args, self.operatorClassName];
            } else if (self.event === 'setActive') {
                var id;
                if (self.args === undefined) {
                    object = undefined;
                } else {
                    // TODO: this fixes the arguments that are transposed by other events arguments
                    let argsHolder = self.args;
                    while (id === undefined) {
                        if (Array.isArray(argsHolder)) {
                            argsHolder = argsHolder[0];
                        } else if (argsHolder) {
                            id = argsHolder;
                        } else {
                            break;
                        }
                    }
                    object = (id) ? ez3dScene.findById(id) : id;
                }
                self.args = [self.args];
                self.args.splice(1, 0, object === undefined ? undefined : object.constructor.name);
                self.args.splice(2, 0, object);
            }
            var debugOpTime2 = (Date.now() - debugOpTime) / 1000;

            if (ez3dScene.layoutRules.scenePreferences.debugPromises === true) {
                console.log(ez3dScene.promiseCounter + ' - finished: ' + self.operatorName + ' - ' + debugOpTime2 + ' sec');
                // console.log(self.args);
            }
            if (response.status === 'FINISHED') {
                ee.emitEvent(self.event + '_Finished', [self.args, debugOpTime]);
                // ez3dLayout.EZApi.sendOperatorFinished({
                //     event: self.event,
                //     args: self.args
                // });
            } else if (response.status === 'CANCELLED') {
                ee.emitEvent(self.event + '_Cancelled', [self.args, debugOpTime]);
            }
            if (updateRender) {
                updateRender.draw2d = (response.hasOwnProperty('draw2d')
                    ? response.draw2d : updateRender.draw2d);
                updateRender.draw3d = (response.hasOwnProperty('draw3d')
                    ? response.draw3d : updateRender.draw3d);
                ee.emitEvent('updateRender', [updateRender]);
            }
        }).catch((error) => {
            console.error(error.event, error);
            var debugOpTime2 = (Date.now() - debugOpTime) / 1000;
            if (ez3dScene.layoutRules.scenePreferences.debugPromises === true) {
                console.log('*** ' + ez3dScene.promiseCounter + ' - cancelled: ', self.operatorName + '\n' + debugOpTime2 + ' seconds');
            }
            if (ez3dScene.promiseCounter === 0 && ee.getListeners('close-saving-notification').length > 0) {
                ee.emitEvent('close-saving-notification');
            }
            ee.emitEvent(self.event + '_Cancelled', [error, debugOpTime2]);
            var ez3dViewport = ez3dScene.layoutInstance.ez3dViewport;
            ez3dViewport.ez3dGuiManager.notificationManager([self.event + ' Cancelled', {
                style: 'error'
            }]);
        });
    } else {
        console.log('Poll failed, operation not allowed');
    }
};

EZModelOperator.prototype.generatePoll = function(poll) {
    var self = this;
    var pollFunction = function() {
        if (ez3dScene.context === poll.context || poll.context === '*') {
            if (poll.active === '*') {
                return true;
            } else if (ez3dScene.active instanceof poll.active) {
                return true;
            }
        }
        return false;
    };
    return pollFunction;
};

/**
 * EZModelHistory
 * Class used to store executed actions of each object through its different operators.
 * Once the action has been saved, it's possible to undo or redo the action.
 * @param {EZModelScene} ez3dScene - A EZModelScene class.
 */
function EZModelHistory() {
    this.isExecutingOp = false;
    this.action = {};
}

/**
 * Method to add an action to the queue. If the index is lower than queue last index
 * and the queue length is higher than zero, the elements with index
 * higher than index are deleted.
 * @param {object} operator     - Executed operator data.
 */
EZModelHistory.prototype.addAction = function(operator) {
    var elementId = (ez3dScene.active) ? ez3dScene.active.id : undefined;
    elementId = (operator.event === 'delete')
        ? ez3dScene.lastElementSelected : elementId;
    var root = this.getRoot(elementId);
    if (root === undefined && this.action.root) {
        root = this.action.root;
    }

    // TARGET
    this.target = {};
    this.target.id = elementId;
    this.target.root = root;
    this.target.isRoot = (root.id === elementId);
    this.target.class = this.target.root.constructor.name.substr(7);

    // OPERATOR
    this.operator = {};
    this.operator.className = operator.operatorClassName;
    this.operator.class = this.operator.className.substr(7);
    this.operator.event = operator.event;
    if (operator.event === 'setAttribute') {
        this.operator.attribute = operator.args[1];
    } else {
        delete this.operator.attribute;
    }

    // LAST
    this.lastElementSelected = ez3dScene.lastElementSelected;
    this.undo = this.action.seed;
    this.redo = undefined;
    ee.emitEvent('updateUndoRedoButtons');
};

EZModelHistory.prototype.checkSubcontext = function (response) {
    const isSubcontext =
        ['pathEditor', 'location'].includes(ez3dScene.context) ||
        (ez3dScene.context === 'roof' && ((response && response.event
            .includes('setAttribute')) || !response));
    if (isSubcontext && this.subcontextName !== ez3dScene.context) {
        this.subcontextName = ez3dScene.context;
        this.undo = undefined;
    }
    return isSubcontext;
};

/**
 * Method to get the root of an element.
 * @param {string} element         - Root id.
 * @return {object} root           - Object of the root (building/tree).
 */
EZModelHistory.prototype.getRoot = function(element) {
    var root = element;
    if (element) {
        if (typeof element === 'string') {
            root = ez3dScene.findById(element);
        }
        if (root) {
            if (root.constructor.name !== 'EZModelTree' &&
                root.constructor.name !== 'EZModelBuilding') {
                root = root.getAncestor('EZModelBuilding');
            }
        } else {
            root = undefined;
        }
    }
    return root;
};

/**
 * Method to get the diff of active element before executing the operation.
 * @param {object} element         - Active element.
 * @return {object} root           - Diff of the active element.
 */
EZModelHistory.prototype.getRootJson = function(element) {
    var root = this.getRoot(element);
    if (root === undefined) return root;
    return root.getJson(undefined, true);
};


// UNDO - REDO

/**
 * Method to undo the action of an element.
 */
EZModelHistory.prototype.executeUndo = function() {
    if (!this.undo || this.redo) return;
    this.redo = this.getRootJson(this.target.root);
    this.executeOp(true);
};

/**
 * Method to redo the action of an element.
 */
EZModelHistory.prototype.executeRedo = function() {
    if (!this.redo) return;
    this.executeOp(false);
    if (this.redo) this.redo = undefined;
};

EZModelHistory.prototype.executeOp = function(isUndo) {
    this.isExecutingOp = true;
    if (ez3dScene.tempPath && ez3dScene.tempPath.vertices.length === 4 &&
        ez3dScene.tempPath.vertices[3].estimated === true) {
        ez3dScene.tempPath.removeEstimatedFourthPoint();
    }
    if (this.target.isRoot && ['delete', 'clone', 'createBuilding', 'createTree']
        .includes(this.operator.event)) {
        this.rootSwitch(isUndo, this.updateAfterOp);
    } else {
        const seed = (isUndo) ? this.undo : this.redo;
        this.updateRoot(seed);
        if (!isUndo && this.redo) this.redo = undefined;
        this.updateAfterOp();
    }
};

/**
 * Method to update root changes
 * @param {bool} isUndo         - is this an undo op?.
 * @param {function} callback   - Callback function.
 */
EZModelHistory.prototype.rootSwitch = function(isUndo, callback) {
    const destroyRoot = (
        (isUndo && ['clone', 'createBuilding', 'createTree']
            .includes(this.operator.event)) ||
        (!isUndo && ['delete'].includes(this.operator.event)));
    console.log(this.operator.event, (destroyRoot) ? 'destroy' : '(re)create');
    if (destroyRoot) {
        // UNDO "createRoot" > Pop & Destroy Root
        // REDO "deleteRoot" > Splice & Destroy Root
        if (!isUndo && this.redo) this.redo = undefined;
        this.target.root.delete()
            .then(() => callback());
    } else {
        // UNDO "deleteRoot" > Recreate & Insert Root
        // REDO "createRoot" > Recreate & Push Root
        const seed = (isUndo) ? this.undo : this.redo;
        const newRoot = this.updateRoot(seed);
        if (!isUndo && this.redo) this.redo = undefined;
        if (callback) callback(newRoot.id);
    }
};

EZModelHistory.prototype.updateRoot = function(seed) {
    // const className = this.operator.class;
    const className = this.target.class;
    const list = className.toLowerCase() + 's';
    const index = ez3dScene[list].indexOf(this.target.root);
    const factory = ['EZFactory' + className, 'create' + className];
    const newRoot = window[factory[0]]
        .prototype[factory[1]](ez3dScene, seed);
    if (index === -1) {
        if (ez3dScene[list].length && seed.index) {
            ez3dScene[list].splice(seed.index - 1, 0, newRoot);
        } else {
            ez3dScene[list].push(newRoot);
        }
    } else {
        ez3dScene[list][index] = newRoot;
    }
    this.target.root = newRoot;
    return newRoot;
};

/**
 * Method to update buttons, render and history index after executing undo/redo.
 * @param {string} id     - Id of the current step element.
 */
EZModelHistory.prototype.updateAfterOp = function(id) {
    const operator = ez3dScene.history.operator;
    const tempPath = ez3dScene.tempPath;
    if (tempPath && tempPath.vertices.length === 3) {
        tempPath.calculateEstimatedFourthPoint();
    }

    // Create args to send on customEvent
    let currentPanel = '';
    let isCustomProperty = false;
    if (ez3dScene.active.customProperties &&
        operator.event === 'setAttribute') {
        isCustomProperty = ez3dScene.active.customProperties
            .includes(operator.attribute);
        if (isCustomProperty) {
            currentPanel = d3.select('.ez3d-active-panel:' +
                'not(#ez3d-aside-tree-info):' +
                'not(#ez3d-aside-keepout-info)');
        }
    }
    if (!id) {
        console.warn('Missing undo id, using active id');
        id = ez3dScene.active.id;
    }
    ez3dScene.setActive(id)
        .then(() => {
            ee.emitEvent('setActive_Finished', [ez3dScene.active.id,
                ez3dScene.active.constructor.name, ez3dScene.active]);

            // Updates
            ez3dScene.updateProjectSystemInfo();
            ez3dViewport.ez3dGuiManager.updateWidgets();
            ee.emitEvent('updateUndoRedoButtons');
            const elementID = (id) ? id : ez3dScene.active.id;
            ee.emitEvent('updateRender',
                [{draw2d: true, draw3d: elementID, updatePanels: true, fitRange: false}]);
            ee.emitEvent('hideProgressBar');

            // Finish
            ez3dScene.history.isExecutingOp = false;

            // Send event if a panel has to be reopened
            if (isCustomProperty && !currentPanel.classed('ez3d-active-panel')) {
                // Format panel name
                currentPanel = currentPanel.attr('id').split('ez3d-aside-')[1];
                if (currentPanel.split('-').length > 1) {
                    currentPanel = currentPanel.split('-')[0] +
                        EZModelUtils.capitalize(currentPanel.split('-')[1]);
                }
                currentPanel = EZModelUtils.capitalize(currentPanel);
                ee.emitEvent('changePanelOnUndoRedo', [currentPanel]);
            }
            // Send event after executing undo/redo
            ee.emitEvent('executedUndoRedo', [currentPanel]);
        });
};

/**
 * EZModelOperatorsManager
 * @class
 * @classdesc This is a description of the <b>EZModelOperatorsManager</b> class.
 * @param {EZModelScene} ez3dScene  - Main container for 3DLayout project model.
 * @param {EZViewport} ez3dViewport  - Main container for 3DLayout project model.
 */
function EZModelOperatorsManager(ez3dScene) {
    var self = this;
    // ez3dScene = ez3dScene;

    ee.addOnceListener('viewportReady', function() {
        self.ez3dViewport = ez3dScene.layoutInstance.ez3dViewport;
    });

    self.registeredOperators = [];

    ez3dScene.promiseCounter = 0;
    ez3dScene.operatorPromisesList = [];

    ez3dScene.operatorList.forEach(function(operator) {
        var newOperator = new EZModelOperator(
            operator.name,
            operator.poll,
            operator.postAction,
            ez3dScene
        );
        self.registeredOperators.push(newOperator);
    });

    self.listener();
}

EZModelOperatorsManager.prototype.listener = function() {
    var self = this;
    ez3dScene.recordedOps = [];
    self.registeredOperators.forEach(function(operator) {
        // add listener to listeners array
        ez3dScene.operatorsListeners.push(operator.listener);

        ee.addListener(operator.listener, function(attr, val) {
            if (!attr || attr.notifications === undefined || attr.notifications === true) {
                if (self.ez3dViewport && self.ez3dViewport.ez3dGuiManager &&
                    ez3dScene.layoutRules.scenePreferences.viewportMode !== 3) {
                    self.ez3dViewport.ez3dGuiManager.notificationManager([operator.event, {
                        style: 'info',
                        event: operator.event + '_Finished'
                    }]);
                    // console.log(operator.event + '_Finished');
                }
            }

            if (attr && attr.progress && ez3dScene.layoutRules.scenePreferences.viewportMode !== 3) {
                self.ez3dViewport.ez3dGuiManager.showProgressBar();
                ee.emitEvent('updateProgressBar', [{
                    title: operator.event
                }]);
            }

            if (ez3dScene.layoutRules.scenePreferences.recordOperators === true) {
                var args = [];
                if (operator.listener === 'EZModelScene_setAttributeListener') {
                    attr.args.forEach(function(arg) {
                        args.push(arg);
                    });
                } else if (operator.listener === 'EZModelScene_addLocationListener') {
                    attr.args[3] = undefined;
                    args = attr.args;
                } else {
                    args = attr ? attr.args : undefined;
                }
                ez3dScene.recordedOps.push({
                    event: operator.listener,
                    args: (args && (args.length > 0 || typeof args === 'object')) ? args : undefined,
                    undo: attr ? attr.undo : undefined,
                    redo: attr ? attr.redo : undefined,

                    notifications: attr ? attr.notifications : undefined,
                    updateRender: attr ? attr.updateRender : undefined,
                    eventFinished: operator.event + '_Finished'
                });
            }

            // here the operator is executed !!!
            operator.action(attr, val);

            // Count events in event emitter
            if (ez3dScene.layoutRules.scenePreferences.debugListeners) {
                var count = 0;
                Object.keys(ee._getEvents()).forEach(function(event) {
                    if (Array.isArray(ee._getEvents()[event])) {
                        var length = ee._getEvents()[event].length;
                        count += length;
                    }
                });
                console.log('Event count', count);
            }
        });
    });
};

EZModelScene.prototype.registerOperators = function() {
    var self = this;
    var operators = [];

    // Scene
    operators.push([
        {
            name: 'EZModelScene_changeProjectCenter_operator',
            poll: {
                context: '*',
                active: '*'
            },
            postAction: function() {
                // console.log('accion para despues de un setActive');
            }
        },
        {
            name: 'EZModelScene_setActive_operator',
            poll: {
                context: '*',
                active: '*'
            },
            postAction: function() {
                // console.log('accion para despues de un setActive');
            }
        },
        {
            name: 'EZModelScene_createBuilding_operator',
            poll: {
                context: '*',
                active: '*'
            },
            postAction: function() {
                // console.log('accion para despues de un createBuilding');
            }
        },
        {
            name: 'EZModelScene_createBuildingShape_operator',
            poll: {
                context: '*',
                active: '*'
            },
            postAction: function() {
                // console.log('accion para despues de un createBuildingShape');
            }
        },
        {
            name: 'EZModelScene_createTree_operator',
            poll: {
                context: '*',
                active: '*'
            },
            postAction: function() {
                // console.log('accion para despues de un createBuilding');
            }
        },
        {
            name: 'EZModelScene_addLocation_operator',
            poll: {
                context: 'pathEditor',
                active: '*'
            },
            postAction: function() {
                // console.log('accion para despues de un addLocation');
            }
        },
        {
            name: 'EZModelScene_setAttribute_operator',
            poll: {
                context: '*',
                active: '*'
            },
            postAction: function() {
                // console.log('accion para despues de un setAttribute');
            }
        },
        {
            name: 'EZModelScene_setUnits_operator',
            poll: {
                context: '*',
                active: '*'
            },
            postAction: function() {
                // console.log('accion para despues de un setUnits');
            }
        },
        {
            name: 'EZModelScene_removeLocation_operator',
            poll: {
                context: '*',
                active: '*'
            },
            postAction: function() {
                // console.log('accion para despues de un setUnits');
            }
        },
        {
            name: 'EZModelScene_getTextures_operator',
            poll: {
                context: '*',
                active: '*'
            },
            postAction: function() {
                // console.log('accion para despues de un setUnits');
            }
        },
        {
            name: 'EZModelScene_setBuildingActiveAndGetTexture_operator',
            poll: {
                context: '*',
                active: '*'
            },
            postAction: function() {
                // console.log('accion para despues de un setUnits');
            }
        },
        {
            name: 'EZModelScene_removeTextures_operator',
            poll: {
                context: '*',
                active: '*'
            },
            postAction: function() {
                // console.log('accion para despues de un setUnits');
            }
        },
        {
            name: 'EZModelScene_moveByCartesianCoords_operator',
            poll: {
                context: '*',
                active: '*'
            },
            postAction: function() {
                // console.log('accion para despues de un moveByCartesianCoords');
            }
        },
        {
            name: 'EZModelScene_getMapTexture_operator',
            poll: {
                context: '*',
                active: '*'
            },
            postAction: function() {
                // console.log('accion para despues de un getMapTexture');
            }
        },
        {
            name: 'EZModelScene_removeMapTexture_operator',
            poll: {
                context: '*',
                active: '*'
            },
            postAction: function() {
                // console.log('accion para despues de un removeMapTexture');
            }
        },
        {
            name: 'EZModelScene_updateElementsStyles_operator',
            poll: {
                context: '*',
                active: '*'
            },
            postAction: function() {
                // console.log('accion para despues de un updateElementsStyles');
            }
        },
        {
            name: 'EZModelScene_setActiveAndExecuteOperator_operator',
            poll: {
                context: '*',
                active: '*'
            },
            postAction: function() {
                // console.log('accion para despues de un setActiveAndExecuteOperator');
            }
        },
        {
            name: 'EZModelScene_setActiveAndDelete_operator',
            poll: {
                context: '*',
                active: '*'
            },
            postAction: function() {
                // console.log('accion para despues de un setActiveAndDelete');
            }
        },
        {
            name: 'EZModelScene_snapshotMap_operator',
            poll: {
                context: '*',
                active: '*'
            },
            postAction: function() {
                // console.log('accion para despues de un snapshotMap');
            }
        },
        {
            name: 'EZModelScene_setTileAndGetMapTexture_operator',
            poll: {
                context: '*',
                active: '*'
            },
            postAction: function() {
                // console.log('accion para despues de un setTileAndGetMapTexture');
            }
        },
    ]);

    // Benchmark
    operators.push([
        {
            name: 'EZModelScene_wait_operator',
            poll: {
                context: '*',
                active: '*'
            },
            postAction: function() {
                // console.log('accion para despues de un setUnits');
            }
        },
        {
            name: 'EZModelScene_benchmarkExecuteEvent',
            poll: {
                context: '*',
                active: '*'
            },
            postAction: function() {
                // console.log('accion para despues de un setUnits');
            }
        },
        {
            name: 'EZModelScene_benchmarkExecuteEventsArray',
            poll: {
                context: '*',
                active: '*'
            },
            postAction: function() {
                // console.log('accion para despues de un setUnits');
            }
        },
        {
            name: 'EZModelScene_benchmarkDispatchClick',
            poll: {
                context: '*',
                active: '*'
            },
            postAction: function() {
                // console.log('accion para despues de un setUnits');
            }
        },
        {
            name: 'EZModelScene_fitRangeToContainer',
            poll: {
                context: '*',
                active: '*'
            },
            postAction: function() {
                // console.log('accion para despues de un setUnits');
            }
        },
        {
            name: 'EZModelScene_setDateTime',
            poll: {
                context: '*',
                active: '*'
            },
            postAction: function() {
                // console.log('accion para despues de un setDateTime');
            }
        },
        {
            name: 'EZModelScene_benchmarkSetActiveWithoutId_operator',
            poll: {
                context: '*',
                active: '*'
            },
            postAction: function() {
                // console.log('accion para despues de un runMethod en area');
            }
        },
        {
            name: 'EZModelScene_benchmarkSetAttributeScene_operator',
            poll: {
                context: '*',
                active: '*'
            },
            postAction: function() {
                // console.log('accion para despues de un runMethod en area');
            }
        },
        {
            name: 'EZModelScene_benchmarkMoveRoofPoints_operator',
            poll: {
                context: '*',
                active: '*'
            },
            postAction: function() {
                // console.log('accion para despues de mover roof points en el benchmark');
            }
        },
        {
            name: 'EZModelBuilding_benchmarkSetAttributeWithoutId_operator',
            poll: {
                context: '*',
                active: EZModelBuilding
            },
            postAction: function() {
                // console.log('accion para despues de un setAttribute en building');
            }
        },
        {
            name: 'EZModelArea_benchmarkSetAttributeWithoutId_operator',
            poll: {
                context: 'area',
                active: EZModelArea
            },
            postAction: function() {
                // console.log('accion para despues de un setAttribute en area');
            }
        },
        {
            name: 'EZModelKeepout_benchmarkSetAttributeWithoutId_operator',
            poll: {
                context: 'keepout',
                active: EZModelKeepout
            },
            postAction: function() {
                // console.log('accion para despues de un setAttribute en keepout');
            }
        }
    ]);

    // Location
    operators.push([
        {
            name: 'EZModelLocation_move_operator',
            poll: {
                context: '*',
                active: EZModelLocation
            },
            postAction: function() {
                // console.log('accion para despues de un move location');
            }
        }
    ]);

    // Building
    operators.push([
        {
            name: 'EZModelBuilding_clone_operator',
            poll: {
                context: 'building',
                active: EZModelBuilding
            },
            postAction: function() {
                // console.log('accion para despues de un clone building');
            }
        },
        {
            name: 'EZModelBuilding_delete_operator',
            poll: {
                context: 'building',
                active: EZModelBuilding
            },
            postAction: function() {
                // console.log('accion para despues de un delete building');
            }
        },
        {
            name: 'EZModelBuilding_move_operator',
            poll: {
                context: 'building',
                active: EZModelBuilding
            },
            postAction: function() {
                // console.log('accion para despues de un move building');
            }
        },
        {
            name: 'EZModelBuilding_moveByCartesianCoords_operator',
            poll: {
                context: 'building',
                active: EZModelBuilding
            },
            postAction: function() {
                // console.log('accion para despues de un move building');
            }
        },
        {
            name: 'EZModelBuilding_editBuilding_operator',
            poll: {
                context: 'building',
                active: EZModelBuilding
            },
            postAction: function() {
                // console.log('accion para despues de un edit building');
            }
        },
        {
            name: 'EZModelBuilding_runMethod_operator',
            poll: {
                context: 'building',
                active: EZModelBuilding
            },
            postAction: function() {
                // console.log('accion para despues de un runMethod en building');
            }
        },
        {
            name: 'EZModelBuilding_getTexture_operator',
            poll: {
                context: 'building',
                active: EZModelBuilding
            },
            postAction: function() {
                // console.log('accion para despues de un getTexture en building');
            }
        },
        {
            name: 'EZModelBuilding_removeTexture_operator',
            poll: {
                context: 'building',
                active: EZModelBuilding
            },
            postAction: function() {
                // console.log('accion para despues de un removeTexture en building');
            }
        },
        {
            name: 'EZModelBuilding_createKeepout_operator',
            poll: {
                context: 'building',
                active: EZModelBuilding
            },
            postAction: function() {
                // console.log('accion para despues de un createBuilding');
            }
        },
        {
            name: 'EZModelBuilding_updateKeepouts_operator',
            poll: {
                context: 'building',
                active: EZModelBuilding
            },
            postAction: function() {
                // console.log('accion para despues de un updateKeepouts');
            }
        },
        {
            name: 'EZModelBuilding_editRoof_operator',
            poll: {
                context: 'building',
                active: EZModelBuilding
            },
            postAction: function() {
                // console.log('accion para despues de un editRoof');
            }
        },
        {
            name: 'EZModelBuilding_addStepToCustomShape_operator',
            poll: {
                context: 'pathEditor',
                active: EZModelBuilding
            },
            postAction: function() {
                // console.log('accion para despues de un addStepToCustomShape');
            }
        },
        {
            name: 'EZModelBuilding_recalculateStepCustomPath_operator',
            poll: {
                context: 'pathEditor',
                active: EZModelBuilding
            },
            postAction: function() {
                // console.log('accion para despues de un recalculateStepCustomPath');
            }
        },
        {
            name: 'EZModelBuilding_removeStepToCustomShape_operator',
            poll: {
                context: 'pathEditor',
                active: EZModelBuilding
            },
            postAction: function() {
                // console.log('accion para despues de un removeStepToCustomShape');
            }
        }
    ]);

    // Area
    operators.push([
        {
            name: 'EZModelArea_deleteSubarea_operator',
            poll: {
                context: 'area',
                active: EZModelArea
            },
            postAction: function() {
                // console.log('accion para despues de un delete area');
            }
        },
        {
            name: 'EZModelArea_resetSubareaExceptModel_operator',
            poll: {
                context: 'area',
                active: EZModelArea
            },
            postAction: function() {
                // console.log('accion para despues de un delete/reset area');
            }
        },
        {
            name: 'EZModelArea_createSubarea_operator',
            poll: {
                context: 'area',
                active: EZModelArea
            },
            postAction: function() {
                // console.log('accion para despues de un createBuilding');
            }
        },
        {
            name: 'EZModelArea_moveSubarea_operator',
            poll: {
                context: 'area',
                active: EZModelArea
            },
            postAction: function() {
                // console.log('accion para despues de un createBuilding');
            }
        },
        {
            name: 'EZModelArea_interactiveMoveSubarea_operator',
            poll: {
                context: 'area',
                active: EZModelArea
            },
            postAction: function() {
                // console.log('accion para despues de un createBuilding');
            }
        },
        {
            name: 'EZModelArea_editSubarea_operator',
            poll: {
                context: 'area',
                active: EZModelArea
            },
            postAction: function() {
                // console.log('accion para despues de un createBuilding');
            }
        },
        {
            name: 'EZModelArea_cropSubarea_operator',
            poll: {
                context: 'area',
                active: EZModelArea
            },
            postAction: function () {
                // console.log('accion para despues de un createBuilding');
            }
        },
        {
            name: 'EZModelArea_cloneSubarea_operator',
            poll: {
                context: 'area',
                active: EZModelArea
            },
            postAction: function() {
                // console.log('accion para despues de un createBuilding');
            }
        },
        {
            name: 'EZModelArea_areaUpdateSystem_operator',
            poll: {
                context: 'area',
                active: EZModelArea
            },
            postAction: function() {
                // console.log('accion para despues de un update system');
            }
        },
        {
            name: 'EZModelArea_runMethod_operator',
            poll: {
                context: 'area',
                active: EZModelArea
            },
            postAction: function() {
                // console.log('accion para despues de un runMethod en area');
            }
        }
    ]);

    // Keepout
    operators.push([
        {
            name: 'EZModelKeepout_move_operator',
            poll: {
                context: 'keepout',
                active: EZModelKeepout
            },
            postAction: function() {
                // console.log('accion para despues de un move keepout');
            }
        },
        {
            name: 'EZModelKeepout_moveByCartesianCoords_operator',
            poll: {
                context: 'keepout',
                active: EZModelKeepout
            },
            postAction: function() {
                // console.log('accion para despues de un move keepout');
            }
        },
        {
            name: 'EZModelKeepout_clone_operator',
            poll: {
                context: 'keepout',
                active: EZModelKeepout
            },
            postAction: function() {
                // console.log('accion para despues de un clone keepout');
            }
        },
        {
            name: 'EZModelKeepout_delete_operator',
            poll: {
                context: 'keepout',
                active: EZModelKeepout
            },
            postAction: function() {
                // console.log('accion para despues de un delete keepout');
            }
        },
        {
            name: 'EZModelKeepout_editKeepout_operator',
            poll: {
                context: 'keepout',
                active: EZModelKeepout
            },
            postAction: function() {
                // console.log('accion para despues de un edit keepout');
            }
        },
        {
            name: 'EZModelKeepout_runMethod_operator',
            poll: {
                context: 'keepout',
                active: EZModelKeepout
            },
            postAction: function() {
                // console.log('accion para despues de un runMethod en keepout');
            }
        }
    ]);

    // Tree
    operators.push([
        {
            name: 'EZModelTree_delete_operator',
            poll: {
                context: 'tree',
                active: EZModelTree
            },
            postAction: function() {
                // console.log('accion para despues de un delete tree');
            }
        },
        {
            name: 'EZModelTree_move_operator',
            poll: {
                context: 'tree',
                active: EZModelTree
            },
            postAction: function() {
                // console.log('accion para despues de un move tree');
            }
        },
        {
            name: 'EZModelTree_moveByCartesianCoords_operator',
            poll: {
                context: 'tree',
                active: EZModelTree
            },
            postAction: function() {
                // console.log('accion para despues de un move tree');
            }
        },
        {
            name: 'EZModelTree_clone_operator',
            poll: {
                context: 'tree',
                active: EZModelTree
            },
            postAction: function() {
                // console.log('accion para despues de un clone tree');
            }
        },
        {
            name: 'EZModelTree_edit_operator',
            poll: {
                context: 'tree',
                active: EZModelTree
            },
            postAction: function() {
                // console.log('accion para despues de un edit tree');
            }
        },
        {
            name: 'EZModelTree_runMethod_operator',
            poll: {
                context: 'tree',
                active: EZModelTree
            },
            postAction: function() {
                // console.log('accion para despues de un runMethod en tree');
            }
        }
    ]);

    // Subarea
    operators.push([
        {
            name: 'EZModelSubarea_delete_operator',
            poll: {
                context: 'subarea',
                active: EZModelSubarea
            },
            postAction: function() {
                // console.log('accion para despues de un delete subarea');
            }
        },
        {
            name: 'EZModelSubarea_move_operator',
            poll: {
                context: 'subarea',
                active: EZModelSubarea
            },
            postAction: function() {
                // console.log('accion para despues de un move subarea');
            }
        },
        {
            name: 'EZModelSubarea_moveSystem_operator',
            poll: {
                context: 'subarea',
                active: EZModelSubarea
            },
            postAction: function() {
                // console.log('accion para despues de un move system');
            }
        },
        {
            name: 'EZModelSubarea_clone_operator',
            poll: {
                context: 'subarea',
                active: EZModelSubarea
            },
            postAction: function() {
                // console.log('accion para despues de un clone subarea');
            }
        },
        {
            name: 'EZModelSubarea_editSystem_operator',
            poll: {
                context: 'subarea',
                active: EZModelSubarea
            },
            postAction: function() {
                // console.log('accion para despues de un edit system');
            }
        },
        {
            name: 'EZModelSubarea_resetSubarea_operator',
            poll: {
                context: 'subarea',
                active: EZModelSubarea
            },
            postAction: function() {
                // console.log('accion para despues de un edit subarea');
            }
        },
        {
            name: 'EZModelSubarea_runMethod_operator',
            poll: {
                context: 'subarea',
                active: EZModelSubarea
            },
            postAction: function() {
                // console.log('accion para despues de un runMethod en subarea');
            }
        },
        {
            name: 'EZModelSubarea_addRowOffset_operator',
            poll: {
                context: 'subarea',
                active: EZModelSubarea
            },
            postAction: function() {
                // console.log('accion para despues de un runMethod en subarea');
            }
        },
        {
            name: 'EZModelSubarea_changeRowOrientation_operator',
            poll: {
                context: 'subarea',
                active: EZModelSubarea
            },
            postAction: function() {
                // console.log('accion para despues de un changeRowOrientation en subarea');
            }
        },
        {
            name: 'EZModelSubarea_updateSystem_operator',
            poll: {
                context: 'subarea',
                active: EZModelSubarea
            },
            postAction: function() {
                // console.log('accion para despues de un update system');
            }
        },
        {
            name: 'EZModelSubarea_updateSystemGrid_operator',
            poll: {
                context: 'subarea',
                active: EZModelSubarea
            },
            postAction: function() {
                // console.log('accion para despues de un update grid');
            }
        }
    ]);

    // Roof
    operators.push([
        {
            name: 'EZModelRoof_runMethod_operator',
            poll: {
                context: 'roof',
                active: EZModelRoof
            },
            postAction: function() {
                // console.log('accion para despues de un runMethod en roof');
            }
        }
    ]);

    // Register Operators
    for (var i = 0; i < operators.length; i++) {
        for (var j = 0; j < operators[i].length; j++) {
            self.operatorList.push(operators[i][j]);
        }
    }
};

EZModelKeepout.prototype.editKeepout = function() {
    const self = this;

    const promise = new Promise((resolve, reject) => {
        // lock interface
        ee.emitEvent('lockInterface');
        self.isEditing = true;

        ez3dScene.tempLocations(self.path.vertices);
        ez3dScene.unfinishedObject = self.id;

        ez3dScene.setActive(self.id)
            .then(() => {
                /**
                 * finishObjectEditing - description
                 *
                 */
                function finishObjectEditing() {
                    ee.emitEvent('showProgressBar');
                    ee.emitEvent('updateProgressBar', [{
                        title: 'editKeepout'
                    }]);
                    // remove events
                    ee.removeEvent('cancelKeepoutEditingOperator');
                    self.isEditing = false;

                    self.path.vertices = ez3dScene.locations;
                    ez3dScene.locations = [];
                    self.path.updateCalculations();

                    self.path.setCoordSystem('building', self, 'EZModelKeepout');

                    self.parent.updateKeepoutsOperator()
                        .then((result) => {
                            delete ez3dScene.unfinishedObject;

                            ez3dScene.setActive(self.id)
                                .then(() => {
                                    ee.emitEvent('unlockInterface');
                                    ee.emitEvent('hideProgressBar');

                                    var status = {
                                        status: 'FINISHED',
                                        undo: true
                                    };
                                    resolve(status);
                                });
                        });
                }

                /**
                 * cancelObjectEditing - description
                 *
                 */
                function cancelObjectEditing() {
                    // remove events
                    ee.removeEvent('finishKeepoutEditingOperator');
                    self.isEditing = false;
                    delete ez3dScene.unfinishedObject;
                    ez3dScene.locations = [];

                    ez3dScene.setActive(self.id)
                        .then((result) => {
                            ee.emitEvent('unlockInterface');
                            resolve({status: 'CANCELLED'});
                        });
                }

                ee.addOnceListener('finishKeepoutEditingOperator', finishObjectEditing);
                ee.addOnceListener('cancelKeepoutEditingOperator', cancelObjectEditing);
                ee.emitEvent('updateRender',
                    [{draw2d: true, draw3d: self, updatePanels: false, fitRange: false}]);
            });
    });

    return promise;
};

EZModelLocation.prototype.move = function(args) {
    let offset;
    let keepEstimated;
    if (Array.isArray(args)) {
        if (args.length) {
            offset = args[0];
            keepEstimated = args[1];
        } else {
            offset = {lat: 0, lng: 0};
        }
    } else {
        offset = args;
    }
    var self = this;
    var promise = new Promise((resolve, reject) => {

        if (self.estimated === true && keepEstimated !== true) {
            self.estimated = false;
        }
        const lastLat = self.lat;
        const lastLng =  self.lng;
        self.lat += offset.lat;
        self.lng += offset.lng;
        self.updateCartesianCoords();

        const status = {
            status: 'FINISHED',
            undo: false
        };

        if (!ez3dScene.tempPath) resolve(status);

        const indexMove = ez3dScene.tempPath.vertices.indexOf(self);
        const linePrev = EZModelUtils.pairWithPrevious(self, indexMove, ez3dScene.tempPath.vertices);
        const lineNext = EZModelUtils.pairWithNext(self, indexMove, ez3dScene.tempPath.vertices);

        ez3dScene.tempPath.isInvalid = false;
        ez3dScene.tempPath.vertices.forEach((point, index) => {
            
            const line2 = EZModelUtils.pairWithPrevious(point, index, ez3dScene.tempPath.vertices);
            let intersectPrev;
            let intersectNext;
            if (!(line2.includes(linePrev[0]) || line2.includes(linePrev[1]))){
                intersectPrev = EZModelUtils.intersectSegments(linePrev, line2);
            }
            if (!(line2.includes(lineNext[0]) || line2.includes(lineNext[1]))){
                intersectNext = EZModelUtils.intersectSegments(lineNext, line2);
            }
            if (intersectPrev){
                self.lat = lastLat;
                self.lng = lastLng;
                self.updateCartesianCoords();
            }

            if (intersectNext){
                ez3dScene.tempPath.isInvalid = true;
            }


        });

        resolve(status);
    });

    return promise;
};

/**
 * @description Method to clone an active element ( {@link EZModelBuilding} ).
 * The cloned element has new id's for all his children.
 *
 * @param  {EZModelScene} ez3dScene - Required. Main container for 3DLayout project model.
 * @param  {object} offset     - Required. A object with lat and lng keys.
 * @return {Promise}                - A Promise object.
 */
EZModelObject.prototype.clone = function(offset) {
    var promise = new Promise((resolve, reject) => {
        var self = this;
        var cloneElement = {};
        var deleteEvent = '';
        var moveEvent = '';
        var parent = {};
        var status = {};
        var buildingId;
        var buildingJson;

        if (!offset) {
            offset = {
                lat: -0.000009,
                lng: 0.000009
            };
        }

        /**
         * Function to call after clone.
         */
        function cloneActionFinished() {
            deleteEvent = self.constructor.name + '_deleteListener';
            moveEvent = self.constructor.name + '_moveListener';

            ez3dScene.setActive(cloneElement.id)
                .then((result) => {
                    ee.addOnceListener('move_Finished', function() {
                        ee.emitEvent('setInteractiveMove');
                        status = {
                            elementId: self.id,
                            status: 'FINISHED',
                            undo: true
                        };
                        resolve(status);
                    });
                    ee.emitEvent(moveEvent, [{
                        args: offset,
                        undo: false,
                        updateRender: false
                    }]);
                });
        }

        switch (self.constructor.name) {
            case 'EZModelBuilding':
                cloneElement = ez3dScene.factories['Building'].createBuilding(ez3dScene, self);
                ez3dScene.buildings.push(cloneElement);
                cloneActionFinished();
                break;
            case 'EZModelKeepout':
                // need to store entire building to restore modules
                buildingId = ez3dScene.activeBuilding.id;
                buildingJson = ez3dScene.activeBuilding.getJson();
                parent = ez3dScene.findById(self.parentId);
                cloneElement = ez3dScene.factories['Keepout'].createKeepout(ez3dScene, parent, self);
                parent.keepouts.push(cloneElement);
                self.parent.updateKeepoutsOperator().then(() => {
                    cloneActionFinished();
                });
                break;
            case 'EZModelTree':
                cloneElement = ez3dScene.factories['Tree'].createTree(ez3dScene, self);
                ez3dScene.trees.push(cloneElement);
                cloneActionFinished();
                break;
            default:
                console.error('Object it is not a building, keepout, tree or subarea');
                cloneActionFinished();
        }
    });

    return promise;
};

/**
 * @description Method to delete a element ( {@link EZModelBuilding} ).
 * If the elementId is undefined the active element is deleted.
 *
 * @return {Promise}                - A Promise object.
 */
EZModelObject.prototype.delete = function() {
    var self = this;

    var promise = new Promise((resolve, reject) => {
        var activeId;
        var element = ez3dScene.active;
        var building = ez3dScene.activeBuilding;
        var elementJson = ez3dScene.active.getJson();

        if (ez3dScene.activeBuilding !== false) {
            var buildingJson = ez3dScene.activeBuilding.getJson();
        }

        /**
         * changedActiveId - description
         *
`         */
        function changedActiveId() {
            ez3dScene.setActive(activeId)
                .then((result)=>{
                    var status = {
                        elementId: self.id,
                        type: self.constructor.name,
                        status: 'FINISHED',
                        undo: true
                    };
                    resolve(status);
                });
        }

        self.removeObject();
        switch (self.constructor.name) {
            case 'EZModelBuilding':
                if (ez3dScene.buildings.length > 0) {
                    activeId = ez3dScene.buildings[ez3dScene.buildings.length - 1].id;
                } else {
                    activeId = undefined;
                }
                changedActiveId();
                break;
            case 'EZModelKeepout':
                if (building.keepouts.length > 0) {
                    activeId = building.keepouts[building.keepouts.length - 1].id;
                } else {
                    activeId = building.id;
                }
                building.updateKeepoutsOperator()
                    .then((result) => {
                        changedActiveId();
                    });
                break;
            case 'EZModelTree':
                if (ez3dScene.trees.length > 0) {
                    activeId = ez3dScene.trees[ez3dScene.trees.length - 1].id;
                } else if (ez3dScene.buildings.length > 0) {
                    activeId = ez3dScene.buildings[ez3dScene.buildings.length - 1].id;
                } else {
                    activeId = undefined;
                }
                changedActiveId();
                break;
            case 'EZModelSubarea':
                // this operator is not used, we use Area_cloneSubarea_operator
                var parent = ez3dScene.activeArea;
                if (parent.subareas.length <= 0) {
                    parent.createSubareas();
                    parent.updateSystem();
                    activeId = parent.subareas[0].id;
                } else {
                    activeId = parent.subareas[0].id;
                }
                changedActiveId();
                break;
            default:
                console.error('Object it is not a building, keepout, tree or subarea');
        }
    });

    return promise;
};

EZModelObject.prototype.moveByCartesianCoords = function(cartesian) {
    var self = this;

    var promise = new Promise((resolve, reject) => {
        var updateElement = function(offsetMove) {
            if (self.constructor.name === 'EZModelTree' || self.constructor.name === 'EZModelLocation') {
                self.updatePosition(offsetMove);
            } else {
                self.updatePath(offsetMove);
            }

            if (self.constructor.name === 'EZModelLocation') {
                self.updateCartesianCoords();
            } else if (self.constructor.name === 'EZModelSubarea') {
                self.updateSystem(false, false, true);
                self.fromScratch = false;
            } else if (self.constructor.name === 'EZModelKeepout') {
                self.parent.updateSystem();
            }
            var status = {
                elementId: self.id,
                status: 'FINISHED',
                undo: false
            };
            resolve(status);
        };

        var origin;
        if (self.constructor.name === 'EZModelLocation') {
            origin = self;
        } else if (self.constructor.name === 'EZModelTree') {
            origin = self.position;
        } else {
            origin = self.path.center;
        }

        var targetCartesian = {
            x: origin.x + cartesian.x,
            y: origin.y + cartesian.y
        };
        var newPos = ez3dScene.utils.translateSphericalByCartesianOffset(origin, targetCartesian);
        newPos = new EZModelLocation(ez3dScene, newPos.lng, newPos.lat);
        newPos = ez3dScene.utils.fixCartesianToSphericalPrecision(newPos, targetCartesian);
        newPos.lat -= origin.lat;
        newPos.lng -= origin.lng;

        updateElement(newPos);
    });

    return promise;
};

/**
 * @todo The classes logic ( {@link EZModelSubarea} )
 * are not defined yet.
 *
 * @description Method to move a element ( {@link EZModelBuilding} ) based
 * in the *offset* params. The *offset* has two keys
 * *lat* with a latitude value and *lng* with a longitude value.
 *
 * @param  {EZModelScene} ez3dScene - Required. Main container for 3DLayout project model.
 * @param  {object} offset     - Required. A object with lat and lng keys.
 * @return {Promise}           - A Promise object.
 */
EZModelObject.prototype.move = function(offset) {
    var self = this;

    var promise = new Promise((resolve, reject) => {
        if (self.constructor.name === 'EZModelTree' || self.constructor.name === 'EZModelLocation') {
            self.updatePosition(offset);
        } else {
            self.updatePath(offset);
        }

        if (self.constructor.name === 'EZModelLocation') { 
            self.updateCartesianCoords();
        } else if (self.constructor.name === 'EZModelSubarea') {
            self.updateSystemOperator(false, false, true).then((result) => {
                self.fromScratch = false;
            });
        } else if (self.constructor.name === 'EZModelKeepout') {
            self.parent.updateSystem();
        }

        var status = {
            elementId: self.id,
            status: 'FINISHED',
            undo: true
        };
        resolve(status);
    });

    return promise;
};

//
// This operator runs a method. If args = [method, true]
// then it pass a callback function to the method to finish the promise
EZModelObject.prototype.runMethod = function(args) {
    var self = this;

    var promise = new Promise((resolve, reject) => {
        let method = args;
        let withCallback = false;
        let methodArgs = undefined;
        if (Array.isArray(args)) {
            method = args[0];
            withCallback = args[1] || false;
            methodArgs = args[2];
        }
        var finishRunMethod = function() {
            var status = {
                elementId: self.id,
                status: 'FINISHED',
                undo: true
            };
            ee.emitEvent(self.constructor.name + '_runMethodFinished_' + method);
            resolve(status);
        };

        if (withCallback) {
            self[method](function() {
                finishRunMethod();
            });
        } else {
            self[method](methodArgs);
            finishRunMethod();
        }
    });
    return promise;
};

EZModelScene.prototype.addLocation = function(args) {
    var self = this;

    var promise = new Promise((resolve, reject) => {
        var ezLoc = args[0];
        var index = args[1];
        // var isEstimated = args[2];
        var cartesian = args[3];
        var checkMaxDistance = args[4];
        var customPath = args[5];
        var newLoc = new EZModelLocation(self, ezLoc.lng, ezLoc.lat, ezLoc.data);
        // Store loc id for undo functions
        args[0].data = {id: newLoc.id};

        /**
         * finishAddLocation - last operations and resolve promise
         */
        function finishAddLocation() {
            // Remove estimatedPoint
            if (!customPath && ez3dScene.tempPath && ez3dScene.tempPath.estimatedPoint) {
                ez3dScene.tempPath.removeEstimatedFourthPoint();
            }

            if (customPath) {
                customPath.vertices.push(newLoc);
                customPath.updateCalculations();
            } else {
                // Push to locations array
                if (index === undefined) {
                    ez3dScene.locations.push(newLoc);
                } else {
                    ez3dScene.locations.splice(index + 1, 0, newLoc);
                }
                // Update TempPath
                if (!ez3dScene.tempPath) {
                    ez3dScene.tempPath = new EZModelPath(ez3dScene, ez3dScene.locations);
                    ez3dScene.tempPath.locked = false;
                }
                ez3dScene.tempPath.updateCalculations();
                if (!index) {
                    index = ez3dScene.tempPath.vertices.length - 1;
                }
                ez3dScene.tempPath.setActiveVertex(index);
                ez3dScene.tempPath.calculateEstimatedFourthPoint();
            }
            var status = {
                status: 'FINISHED',
                undo: false
            };
            resolve(status);
        }

        let wrongPoint = false;

        // Calculate point with cartesian precision
        if (cartesian !== undefined) {
            const newPos = self.utils.translateSphericalByCartesianOffset(newLoc, cartesian);
            const deltaSpherical = {lat: newPos.lat - newLoc.lat, lng: newPos.lng - newLoc.lng};
            newLoc.move([deltaSpherical, newLoc.estimated]).catch(() => {
                wrongPoint = true;
            });
        }

        if (wrongPoint) return;

        if (ez3dScene.tempPath) {

            const indexMove = ez3dScene.tempPath.vertices.indexOf(newLoc);
            const linePrev = EZModelUtils.pairWithPrevious(
                newLoc, indexMove, ez3dScene.tempPath.vertices);
            const lineNext = EZModelUtils.pairWithNext(
                newLoc, indexMove, ez3dScene.tempPath.vertices);

            ez3dScene.tempPath.isInvalid = false;
            ez3dScene.tempPath.vertices.forEach((point, pointIndex) => {
                if (pointIndex === 0) return;

                const line2 = EZModelUtils.pairWithPrevious(
                    point, pointIndex, ez3dScene.tempPath.vertices);
                let intersectPrev;
                let intersectNext;
                if (!(line2.includes(linePrev[0]) || line2.includes(linePrev[1]))) {
                    intersectPrev = EZModelUtils.intersectSegments(linePrev, line2);
                }
                if (!(line2.includes(lineNext[0]) || line2.includes(lineNext[1]))) {
                    intersectNext = EZModelUtils.intersectSegments(lineNext, line2);
                }
                if (intersectPrev) {
                    wrongPoint = true;
                }
                if (intersectNext) {
                    ez3dScene.tempPath.isInvalid = true;
                }
            });

            if (wrongPoint) return;
        }

        if (checkMaxDistance) {
            var maxDistanceFromCenter = ez3dScene.layoutRules.scenePreferences.maxDistanceFromCenter;
            var changeProjectCenter = ez3dScene.utils.getVector(newLoc, ez3dScene.projectCenter) >= maxDistanceFromCenter;

            if (changeProjectCenter) {
                ez3dScene.projectCenterChanged = true;
                ez3dScene.changeProjectCenter({lat: newLoc.lat, lng: newLoc.lng}).then(() => {
                    newLoc.updateCartesianCoords();
                    ee.emitEvent('projectCenterChanged');
                    finishAddLocation();
                });
            } else {
                finishAddLocation();
            }
        } else {
            finishAddLocation();
        }
    });

    return promise;
};

EZModelScene.prototype.changeProjectCenter = function(args) {
    var self = this;

    var promise = new Promise((resolve, reject) => {
        var loc;
        var container = 'svgProject';

        if (args.constructor === Array) {
            loc = args[0];
            container = args[1];
        } else {
            loc = args;
        }

        var ez3dViewport = self.layoutInstance.ez3dViewport;
        var svgContainer = ez3dViewport.svgElements[container];
        // console.log('loc', loc);

        ez3dScene.projectCenter.lat = loc.lat;
        ez3dScene.projectCenter.lng = loc.lng;
        ez3dScene.projectCenter.x = 0;
        ez3dScene.projectCenter.y = 0;
        ez3dScene.buildings = [];
        ez3dScene.trees = [];

        // Avoid changing active object if the building is being created
        if (ez3dScene.active.constructor.name === 'EZModelBuilding' && ez3dScene.active.isCreated) {
            ez3dScene.active = 'none';
        }

        var projectCenter = {
            x: ez3dViewport.svgElements.svgProject.center.x,
            y: ez3dViewport.svgElements.svgProject.center.y
        };
        ez3dViewport.svgElements.svgProject.center = {x: 0, y: 0};

        ee.emitEvent('changePlayerLatLng', [loc]);

        var scale = svgContainer.zoomValue;

        var offsetX = (ez3dViewport.svgElements[container].limits[2] - ez3dViewport.svgElements[container].limits[0]) / 2;
        var offsetY = (ez3dViewport.svgElements[container].limits[3] - ez3dViewport.svgElements[container].limits[1]) / 2;

        svgContainer.svg.transition()
            .call(svgContainer.zoom.transform, d3.zoomIdentity
                .scale(scale)
                .translate(
                    -projectCenter.x,
                    -projectCenter.y
                )
                .translate(offsetX, offsetY)
            );


        var status = {
            status: 'FINISHED',
            undo: false
        };

        resolve(status);
    });

    return promise;
};

EZModelScene.prototype.createBuilding = function(fromBuildingShape, oldObjectIdFromBuildingShape) {
    var self = this;

    var promise = new Promise((resolve, reject) => {
        // TODO: when calling createBuilding() from EZ-Operator it's sent a useless argument (fromBuildingShape = 'args')
        const oldObjectId = (fromBuildingShape === true)
            ? oldObjectIdFromBuildingShape
            : self.activeBuilding.id || self.activeTree.id;
        let newBuilding;
        if (fromBuildingShape === true) {
            newBuilding = ez3dScene.active;
            self.tempObject = newBuilding;
            self.tempLocations(newBuilding.customPath.vertices);
        } else {
            newBuilding = self.factories['Building'].createBuilding(self);
            self.tempObject = newBuilding;
            self.tempLocations();
        }

        self.setActive(newBuilding.id)
            .then(() => {
                /**
                 * finishBuildingCreation
                 */
                function finishBuildingCreation() {
                    self.buildings.push(newBuilding);
                    delete self.tempObject;

                    let newPath;
                    if (fromBuildingShape === true) {
                        newPath = self.tempPath;
                    } else {
                        var vertices = [];
                        self.tempPath.vertices.forEach(function(vertex) {
                            vertices.push(vertex);
                        });
                        newPath = new EZModelPath(self, vertices);
                    }
                    newBuilding.setPath(newPath);

                    newBuilding.editRoof()
                        .then((result) => {
                            if (result.status === 'CANCELLED') {
                                ee.emitEvent('cancelBuildingCreationOperator');
                            } else {
                                ee.emitEvent('showProgressBar');
                                ee.emitEvent('updateProgressBar', [{
                                    title: 'createBuilding'
                                }]);
                                ee.removeListener('cancelBuildingCreationOperator', cancelBuildingCreation);
                                // Cleaning
                                self.locations = [];
                                delete self.tempPath;
                                delete ez3dScene.unfinishedObject;
                                setTimeout(function () {
                                    var status = {
                                        status: 'FINISHED',
                                        undo: true
                                    };

                                    if (ez3dScene.layoutRules.scenePreferences.enablePlayer === true &&
                                        ez3dScene.layoutRules.scenePreferences.buildingTextures === true) {
                                        newBuilding.getTexture()
                                            .then(() => {
                                                ee.emitEvent('hideProgressBar');
                                                resolve(status);
                                            });
                                    } else {
                                        ee.emitEvent('hideProgressBar');
                                        resolve(status);
                                    }
                                }, 100);
                            }
                        });
                }

                /**
                 * cancelBuildingCreation
                 */
                function cancelBuildingCreation() {
                    ee.removeEvent('finishBuildingCreationOperator');
                    newBuilding.delete()
                        .then(() => {
                            ez3dScene.locations = [];
                            delete ez3dScene.tempObject;
                            delete self.tempPath;
                            ez3dScene.setActive(oldObjectId)
                                .then(() => {
                                    resolve({status: 'CANCELLED'});
                                });
                        });
                }

                ee.emitEvent('updateRender',
                    [{draw2d: true, draw3d: false, updatePanels: true, fitRange: false}]);
                ee.emitEvent('building_createBuildingListener');
                ee.addOnceListener('finishBuildingCreationOperator', finishBuildingCreation);
                ee.addOnceListener('cancelBuildingCreationOperator', cancelBuildingCreation);
            });
    });

    return promise;
};

EZModelScene.prototype.createTree = function() {
    var self = this;

    var promise = new Promise((resolve, reject) => {
        var newTree = {};
        var ez3dViewport = self.layoutInstance.ez3dViewport;
        var newTreeCenter = self.utils.translateSphericalByCartesianOffset(self.projectCenter, ez3dViewport.svgElements.svgProject.center);
        var newTreeLocation = new EZModelLocation(self, newTreeCenter.lng, newTreeCenter.lat);

        newTree = self.factories['Tree'].createTree(self, newTreeLocation);
        newTree.isCreated = false;
        self.tempObject = newTree;
        self.tempLocations();

        /**
         * finishSetActive - This function it is executed when the new tree it is active
         *
         */
        function finishSetActive() {
            newTree.isCreated = true;
            ez3dScene.trees.push(newTree);
            delete ez3dScene.tempObject;
            ee.emitEvent('setInteractiveMove');

            var status = {
                status: 'FINISHED',
                undo: true
            };
            resolve(status);
        }

        ez3dScene.setActive(newTree.id)
            .then((result)=>{
                finishSetActive();
            });
    });

    return promise;
};

EZModelScene.prototype.getMapTexture = function() {
    const sceneId = this.id;

    const promise = new Promise((resolve, reject) => {

        const {getImg, resizeImage} = EZModelUtils;
        
        const oldActiveRenderer = ez3dScene.layoutRules.scenePreferences.activeRenderer;
        ee.emitEvent('updateProgressBar', [{
            title: 'generatingMapTexture'
        }]);

        d3.select('#texture-operations-canvas').remove();
        d3.select('#ez3d-main-container')
            .append('div')
            .attr('id', 'texture-operations-canvas')
            .style('display', 'none')
            .style('width', '100%');

        const ez3dViewport = ez3dScene.layoutInstance.ez3dViewport;
        if (ez3dViewport && ez3dViewport.svgElements && ez3dViewport.svgElements.svgProject) {
            ez3dViewport.ez3dPlayer.activateRenderer(false);

            const svgProject = ez3dViewport.svgElements.svgProject;
            const playerContainer = d3.select('#playerContainer');
            d3.select('#svgContainer-svgProject')
                .style('display', 'none');

            setTimeout(function () {
                // get gl canvas image
                const canvas = playerContainer.select('canvas').node();
                const img = getImg(canvas);

                ee.emitEvent('updateProgressBar', [{
                    label2: 'gettingMapTextureImage'
                }]);

                img.addEventListener('load', function () {
                    // calculate power of two size for the texture
                    const widthBase = THREE.Math.floorPowerOfTwo(canvas.width);
                    const heightBase = THREE.Math.floorPowerOfTwo(canvas.height);
                    // resize gl image in a 2d canvas
                    const canvasBase = resizeImage(img, widthBase / canvas.width, heightBase / canvas.height, true, 'canvas-base');
                    ez3dScene.selectedCanvas = canvasBase.id;
                    const imgBase = getImg(canvasBase);

                    d3.select('#texture-operations-canvas')
                        .append('h1')
                        .html('image base ' + widthBase + 'x' + heightBase);

                    imgBase.addEventListener('load', function () {
                        const imgBaseToCompare = canvasBase.getContext('2d').getImageData(0, 0, widthBase, heightBase);
                        let oldResponse = 30;

                        /**
                         * recursive function to reduce image resolution
                         * @param  {integer} ratio          reduction ratio
                         * @param  {object} img2           imgae element to operate the scale
                         * @param  {float} changeRatio    value to check diference with original image
                         * @return {float}                changeRatio
                         */
                        function reduceImgSize(ratio, img2, changeRatio) {
                            changeRatio = changeRatio || 100;
                            return new Promise(function (textureResolve) {
                                const imgId = 'scale_1-' + ratio;
                                // draw scaled texture
                                img2 = getImg(resizeImage(imgBase, 1 / ratio, 1 / ratio, true, imgId, true));
                                img2.addEventListener('load', function () {
                                    // draw enlarged version of scaled texture
                                    const i4 = resizeImage(img2, ratio, ratio, true, 'enlarged-' + imgId).getContext('2d').getImageData(0, 0, widthBase, heightBase);
                                    const diffCanvas = d3.select('#texture-operations-canvas')
                                        .append('canvas')
                                        .attr('width', widthBase)
                                        .attr('height', heightBase)
                                        .style('display', 'inline-block')
                                        .node();
                                    const diffCtx = diffCanvas.getContext('2d');
                                    const diff = diffCtx.createImageData(widthBase, heightBase);
                                    const diffNumber = pixelmatch(imgBaseToCompare.data, i4.data, diff.data, widthBase, heightBase, {threshold: 0.1});
                                    diffCtx.putImageData(diff, 0, 0);
                                    changeRatio = (widthBase * heightBase) / diffNumber;
                                    d3.select('#texture-operations-canvas')
                                        .append('h2')
                                        .attr('imgId', 'title_' + imgId)
                                        .html(imgId + ' - ' + widthBase / ratio + 'x' + heightBase / ratio + ' - diff value: ' + parseInt(changeRatio, 10));
                                    // console.log(ratio);
                                    // console.log('pixels', widthBase * heightBase);
                                    // console.log('diff', diffNumber, changeRatio);
                                    if (changeRatio > 30) {
                                        ez3dScene.selectedCanvas = imgId;
                                    }
                                    textureResolve(changeRatio);
                                });
                            })
                                .then(function(response) {
                                    if (response > 30 && !(response === Infinity && ratio === 2) && !(response <= oldResponse)) {
                                        ratio *= 2;
                                        oldResponse = response;
                                        ee.emitEvent('updateProgressBar', [{
                                            label2: 'reducingTextureImage'
                                        }]);

                                        return reduceImgSize(ratio, img2, response);
                                    }
                                    setTimeout(function() {
                                        const texture = d3.select('#' + ez3dScene.selectedCanvas).node().toDataURL('image/jpeg', 0.5);
                                        ez3dScene.maps.push({
                                            id: 'mapId_' + ez3dScene.maps.length,
                                            x: svgProject.limits[0],
                                            y: svgProject.limits[1],
                                            w: svgProject.limits[2] - svgProject.limits[0],
                                            h: svgProject.limits[3] - svgProject.limits[1],
                                            texture: texture
                                        });
                                        playerContainer
                                            .style('min-width', 'inherit')
                                            .style('min-height', 'inherit')
                                            .style('width', '100%')
                                            .style('height', '100%');
                                        ez3dViewport.ez3dPlayer.activateRenderer(oldActiveRenderer);
                                        d3.select('#svgContainer-svgProject')
                                            .style('display', 'inherit');
                                        d3.select('#texture-operations-canvas').remove();
                                        ez3dScene.layoutRules.scenePreferences.snapshotMapStatus = 'generated';
                                        setTimeout(function () {
                                            const status = {
                                                elementId: sceneId,
                                                status: 'FINISHED',
                                                undo: false
                                            };
                                            ee.emitEvent('Renderer.mapSnapshot.mapReady');
                                            resolve(status);
                                        }, 100);
                                    }, 500);
                                })
                                .catch((error) => {
                                    ee.emitEvent('hideProgressBar');
                                    reject({status: 'CANCELLED', error: error});
                                });
                        }

                        let img2;
                        const ratio = 1;
                        let changeRatio;

                        reduceImgSize(ratio, img2, changeRatio);
                    });
                });
            }, 200);
        } else {
            ee.emitEvent('hideProgressBar');
            const status = {
                elementId: sceneId,
                status: 'CANCELLED',
            };
            resolve(status);
        }
    });

    return promise;
};

EZModelScene.prototype.getTextures = function() {
    var promise = new Promise((resolve, reject) => {
        var status = {};
        var originalActiveId = ez3dScene.active.id;

        ee.emitEvent('updateProgressBar', [{
            title: 'generatingTextures'
        }]);

        if (ez3dScene.buildings.length > 0) {
            var restoreCanvas = function() {
                d3.select('#playerContainer')
                    .style('min-width', 'inherit')
                    .style('min-height', 'inherit')
                    .style('width', '100%')
                    .style('height', '100%');

                ez3dViewport.ez3dPlayer.activateRenderer(oldActiveRenderer);
                ez3dViewport.ez3dPlayer.activateMapper(oldActiveMapper);
            };

            var setupCanvas = function() {
                ez3dViewport.ez3dPlayer.activateRenderer(false);
                ez3dViewport.ez3dPlayer.activateMapper(true);
            };

            var ez3dViewport = ez3dScene.layoutInstance.ez3dViewport;
            var oldActiveRenderer = ez3dScene.layoutRules.scenePreferences.activeRenderer;
            var oldActiveMapper = ez3dScene.layoutRules.scenePreferences.activeMapper;
            setupCanvas();

            var idList = [];
            ez3dScene.buildings.forEach(function(b) {
                idList.push(b.id);
            });
            // uncomment this to test an error scenario
            // idList.push('aaa');

            var counter = 0;
            var bigPromise = idList.reduce((accumulatorPromise, id) => {
                return accumulatorPromise.then(() => {
                    // Avoid updating progress bar when the operation has been cancelled
                    if (!ez3dScene.cancelTexturesOp) {
                        counter++;
                        ee.emitEvent('updateProgressBar', [{
                            label1: ez3dViewport.ez3dGuiManager.i18n('building') + ' ' + counter + '/' + ez3dScene.buildings.length,
                            value: 100 / idList.length
                        }]);
                    }
                    return ez3dScene.setBuildingActiveAndGetTexture(id, true);
                });
            }, Promise.resolve());

            bigPromise
                .then(() => {
                    restoreCanvas();
                    delete ez3dScene.cancelTexturesOp;
                    ez3dScene.setActive(originalActiveId)
                        .then(() => {
                            status = {
                                status: 'FINISHED',
                                undo: false
                            };
                            resolve(status);
                        });
                })
                .catch((error) => {
                    restoreCanvas();
                    ez3dScene.setActive(originalActiveId)
                        .then(() => {
                            reject({status: 'CANCELLED', error: error});
                        });
                });
        } else {
            resolve({status: 'FINISHED'});
        }
    });

    return promise;
};

EZModelScene.prototype.moveByCartesianCoords = function(args) {
    var self = this;

    var promise = new Promise((resolve, reject) => {
        var locations = args[0];
        var cartesian = args[1];
        var trueCartesian = args[2] || args[1];

        var counter = locations.length;
        ez3dScene.locations.forEach(function(loc) {
            if (locations.indexOf(loc.id) !== -1) {
                loc.moveByCartesianCoords(cartesian)
                    .then(() => {
                        counter--;
                        if (counter === 0) {
                            var status = {
                                status: 'FINISHED',
                                undo: true
                            };
                            resolve(status);
                        }
                    });
            }
        });
    });

    return promise;
};

EZModelScene.prototype.removeLocation = function(args) {
    var promise = new Promise((resolve, reject) => {
        var locationId = args[0];
        var locationRemovedIndex;
        var estimatedIndex;
        var removeEstimatedLoc = false;
        var removedData;

        // Save the point to be removed, to be used in the Undo
        var saveRemovedVertex = function(index) {
            var removedPoint = ez3dScene.tempPath.vertices[index];
            return {
                lat: removedPoint.lat, lng: removedPoint.lng,
                x: removedPoint.x, y: removedPoint.y,
                id: removedPoint.id, // checkMaxDistance ?
            }
        };

        ez3dScene.tempPath.vertices.forEach(function(loc, i) {
            if (loc.id === locationId) {
                locationRemovedIndex = i;
            }
            if (loc.estimated === true) {
                if (loc.id === locationId) {
                    removeEstimatedLoc = true;
                }
                estimatedIndex = i;
                removedData = saveRemovedVertex(estimatedIndex);
                ez3dScene.tempPath.removeEstimatedFourthPoint();
            }
        });

        if (removeEstimatedLoc) {
            ez3dScene.tempPath.vertices.splice(locationRemovedIndex, 1);
            ez3dScene.tempPath.updateCalculations();
        }

        if (locationRemovedIndex !== estimatedIndex) {
            ez3dScene.tempPath.vertices.splice(locationRemovedIndex, 1);
            ez3dScene.tempPath.updateCalculations();
        }

        ee.emitEvent('updateRender',
            [{draw2d: true, draw3d: false, updatePanels: true, fitRange: false}]);
        var status = {
            status: 'FINISHED',
            undo: true
        };
        resolve(status);
    });

    return promise;
};

EZModelScene.prototype.removeMapTexture = function() {
    var self = this;

    var promise = new Promise((resolve) => {
        self.maps = [];
        delete self.mapSnapshotData;
        ez3dViewport.ez3dPlayer.removeMapSnapshot();
        ez3dScene.layoutRules.scenePreferences.snapshotMapStatus = 'step2';

        resolve({
            status: 'FINISHED'
        });
    });

    return promise;
};

EZModelScene.prototype.removeTextures = function() {
    var promise = new Promise((resolve, reject) => {
        ez3dScene.buildings.forEach(function(building) {
            building.texture = undefined;
        });

        var status = {};
        status = {
            status: 'FINISHED',
            undo: false
        };
        resolve(status);
    });
    return promise;
};

EZModelScene.prototype.setActive = function(args) {
    var self = this;
    var promise = new Promise((resolve, reject) => {
        var objectId;
        var type;
        if (args && args.constructor.name === 'Array') {
            objectId = args[0];
            type = args[1];
        } else {
            objectId = args;
        }
        var oldObject = this.active;
        var oldObjectId = oldObject.id;

        this.lastElementSelected = oldObjectId;

        /**
         * Function to execute after setActive.
         */
        function onActiveUpdate() {
            self.updateElementsStyles()
                .then(() => {
                    resolve({
                        status: 'FINISHED',
                        undo: false
                    });
                });
        }

        ee.addOnceListener('activeUpdated', onActiveUpdate);

        if (objectId === undefined) {
            // Scene context
            this.active = 'none';
            ee.emitEvent('setInteractiveMove', [false]);
            // resolve({status: 'FINISHED'});
        } else {
            var newObject = this.findById(objectId, undefined, type);
            if (newObject === undefined) {
                ee.removeListener('activeUpdated', onActiveUpdate);
                reject({status: 'CANCELLED', error: 'setActive: ERROR. Id does not correspond to any object'});
            } else {
                this.active = newObject;
                if (
                    (oldObject.constructor === EZModelKeepout && newObject.constructor === EZModelKeepout && oldObject.id !== newObject.id) ||
                    newObject.constructor === EZModelTree
                ) {
                    // do not change this.mode
                } else {
                    ee.emitEvent('setInteractiveMove', [false]);
                }
            }
        }
    });

    return promise;
};

EZModelScene.prototype.setActiveAndDelete = function(objectId) {
    var promise = new Promise((resolve, reject) => {
        // Listener to update elements styles after deleting object.
        ee.addOnceListener('delete_Finished', () => {
            resolve({
                status: 'FINISHED',
            });
        });

        // Listener to execute after setActive.
        ee.addOnceListener('setActive_Finished', () => {
            ee.emitEvent(ez3dScene.active.constructor.name + '_deleteListener', [{
                updateRender: {draw2d: true, draw3d: true, updatePanels: true, fitRange: false}
            }]);
        });

        // Set active
        ee.emitEvent('EZModelScene_setActiveListener', [{args: objectId}]);
    });

    return promise;
};

EZModelScene.prototype.setActiveAndExecuteOperator = function(args) {
    var promise = new Promise((resolve, reject) => {
        // Set active data
        const objectId = args[0];
        // Custom operator data
        const operatorName = args[1];
        let operatorArgs;
        let undo;
        let updateRender;
        let progress;
        if (args[2]) {
            operatorArgs = args[2][0];
            undo = args[2][1];
            updateRender = args[2][2];
            progress = args[2][3];
        }

        /**
         * Function to update elements styles after custom operator.
         */
        function afterOperator() {
            resolve({
                status: 'FINISHED',
            });
        }
        ee.addOnceListener(operatorName.split('_')[1].split('Listener')[0] + '_Finished', afterOperator);

        /**
         * Function to execute after setActive.
         */
        function onActiveUpdate() {
            ee.emitEvent(operatorName, [{
                args: operatorArgs,
                undo: undo,
                updateRender:
                updateRender,
                progress: progress}]);
        }
        ee.addOnceListener('setActive_Finished', onActiveUpdate);

        // Set active
        ee.emitEvent('EZModelScene_setActiveListener', [{args: objectId}]);
    });

    return promise;
};

EZModelScene.prototype.setBuildingActiveAndGetTexture = function(buildingId, generatingAllTextures) {
    var promise = new Promise((resolve, reject) => {
        if (!generatingAllTextures) {
            generatingAllTextures = false;
        }
        ez3dScene.setActive(buildingId)
            .then((result) => {
                ez3dScene.active.getTexture(generatingAllTextures)
                    .then((result) => {
                        resolve({status: 'FINISHED'});
                    })
                    .catch((error) => {
                        reject({status: 'CANCELLED', error: error});
                    });
            })
            .catch((error) => {
                reject({status: 'CANCELLED', error: error});
            });
    });

    return promise;
};

EZModelScene.prototype.setAttribute = function(args) {
    var self = this;

    var promise = new Promise((resolve, reject) => {
        var objectId = args[0];
        var attr = args[1];
        var value = args[2];
        var runMethod = args[3];

        var oldValue;
        var oldObject;

        var object;

        if (objectId === '') {
            object = ez3dScene;
            if (attr === 'layoutRules.scenePreferences.viewportMode') {
                value = Number(value);
            }
            if (attr === 'layoutRules.scenePreferences.snapToGrid' || 'layoutRules.scenePreferences.snapToGuides' || 'layoutRules.scenePreferences.snapToVertices' || 'layoutRules.scenePreferences.snapToLines') {
                self.watcherAdjustmentSnaps(attr, value);
            }
        } else if (attr.indexOf('tempPath') > -1) {
            object = self.tempPath;
        } else if (attr.indexOf('customPath') > -1) {
            object = self.active;
        } else {
            object = this.findById(objectId);
            // WIP: Massive dump size > Diff
            oldObject = object.getJson();
        }

        if (object.constructor.name !== 'EZModelScene' && object.id === undefined) {
            resolve({status: 'CANCELLED'});
        } else if (attr.split('.').length > 1) {
            let element = object;
            for (var i = 0; i < attr.split('.').length - 1; i++) {
                const member = attr.split('.')[i];
                //if attr is not pressent in the object, grab it from the ez3dScene
                element = (member in element) ? element[member] : ez3dScene;
            }
            // WIP: Massive dump size > Diff
            oldValue = element[attr.split('.').pop()];
            element[attr.split('.').pop()] = value;
        } else {
            // WIP: Massive dump size > Diff
            oldValue = object[attr];
            object[attr] = value;
        }

        // Set undo and redo values
        const evaluateUndoType = (diff) => {
            return !ez3dScene.activeBuilding.creatingBuildingShape;
        };

        const response = (object.constructor.atlas && object.constructor.atlas[attr])
            ? _.clone(object.constructor.atlas[attr]) : {};

        response.draw3d = (response.hasOwnProperty('draw3d') && response.draw3d)
            ? object : undefined;

        var finishSetAttribute = function(diff) {
            self.updateElementsStyles()
                .then(() => {
                    response.status = 'FINISHED';
                    // Avoid adding action to queue when it's executing undo/redo
                    if (!ez3dScene.history.isExecutingOp) {
                        response.undo = evaluateUndoType(diff);
                    }
                    resolve(response);
                });
        };

        if (runMethod) {
            var method = runMethod[0].split('_')[1].split('Listener')[0];
            var methodArgs = runMethod[1];
            ez3dScene.active[method](methodArgs)
                .then(() => {
                    finishSetAttribute(true);
                });
        } else {
            finishSetAttribute();
        }
    });

    return promise;
};

EZModelScene.prototype.setDateTime = function(args) {
    var promise = new Promise((resolve, reject) => {
        var value = args[0];
        var season = args[1];
        var inputDate = args[2];
        var inputTime = args[3];
        if (typeof args === 'object') {
            if (season === 'shorterShadow') {
                value = ez3dScene.shorterShadow;
            } else if (season === 'longerShadow') {
                value = ez3dScene.longerShadow;
            } else {
                value = ez3dScene.layoutRules.scenePreferences.defaultDateTime;
            }
            d3.select(inputDate)._groups[0][0].value = ez3dScene.utils.formatDateTime(value)[0];
            d3.select(inputTime)._groups[0][0].value = ez3dScene.utils.formatDateTime(value)[1];
        } else {
            value = args;
        }

        ez3dViewport.ez3dPlayer.setDateTime(value);
        resolve({status: 'FINISHED'});
    });

    return promise;
};

EZModelScene.prototype.setTileAndGetMapTexture = function(tile, scale) {
    const promise = new Promise((resolve, reject) => {
        const svgContainer = ez3dViewport.svgElements.svgProject;
        ee.addOnceListener('Mapper.AllTilesLoaded', function() {
            ez3dScene.getMapTexture()
                    .then((result) => {
                        resolve({status: 'FINISHED'});
                    })
                    .catch((error) => {
                        reject({status: 'CANCELLED', error: error});
                    });
        });
        svgContainer.callZoom(scale, tile.x, tile.y);
        const provider = ez3dScene.mapSnapshotData
            ? ez3dScene.mapSnapshotData.provider
            : ez3dViewport.ez3dPlayer.getProvider();
        ez3dViewport.ez3dPlayer.setProvider(provider);
        ez3dViewport.ez3dPlayer.initMapSnapshot();
    });

    return promise;
};

EZModelScene.prototype.setUnits = function(args) {
    var promise = new Promise((resolve, reject) => {
        var unit = args;
        var oldValue;

        var status = {};

        if (unit === undefined) {
            status = {
                status: 'CANCELLED'
            };
            resolve(status);
        } else {
            oldValue = this.unit;
            this.unit = unit;
        }

        status = {
            status: 'FINISHED',
            undo: false
        };

        resolve(status);
    });

    return promise;
};

EZModelScene.prototype.snapshotMap = function() {
    d3.selectAll('.ez3d-mapsnapshot-hidden').classed('ez3d-mapsnapshot-hidden', false);
    d3.select('#ez3d-configure-mapsnapshot').classed('ez3d-mapsnapshot-hidden', true);
    const promise = new Promise((resolve) => {
        const {calculateMapTiles} = EZModelUtils;
        // eslint-disable-next-line no-unused-vars
        let snapshotMapStatus = 'none';
        const svgProject = ez3dViewport.svgElements.svgProject;
        let tileWidth;
        let tileHeight;
        let tileScale;
        let captureLimits;
        let limitsScale;

        if (ez3dScene.mapSnapshotData) {
            captureLimits = ez3dScene.mapSnapshotData.limits;
            limitsScale = ez3dScene.mapSnapshotData.limitsScale;
            tileWidth = ez3dScene.mapSnapshotData.tileWidth;
            tileHeight = ez3dScene.mapSnapshotData.tileHeight;
            tileScale = ez3dScene.mapSnapshotData.tileScale;
        }

        ez3dViewport.ez3dPlayer.activateMapper(true);
        ez3dViewport.ez3dPlayer.activateRenderer(false);

        ee.emitEvent('updateRender',
            [{draw2d: true, draw3d: true, updatePanels: true, fitRange: true}]);

        const setMapSnapshotZoomLevel = function () {
            snapshotMapStatus = 'step1';
            tileScale = svgProject.zoomValue;
            tileWidth = svgProject.limits[2] - svgProject.limits[0];
            tileHeight = svgProject.limits[3] - svgProject.limits[1];
            d3.select('#ez3d-zoomLevelHelper').remove();
            d3.select('#ez3d-defineZoomLevel').remove();
        };

        const setMapSnapshotLimits = function () {
            snapshotMapStatus = 'step2';
            captureLimits = svgProject.limits;
            limitsScale = svgProject.zoomValue;
            d3.select('#ez3d-mapLimitsHelper').remove();
            d3.select('#ez3d-defineMapLimits').remove();
        };

        const getMapTiles = function () {
            let counter = 0;
            ee.emitEvent('showProgressBar');
            const mapTiles = calculateMapTiles(captureLimits, tileWidth, tileHeight);
            const bigPromise = mapTiles.reduce((accumulatorPromise, tile) => {
                return accumulatorPromise.then(() => {
                    // Avoid updating progress bar when the operation has been cancelled
                    if (!ez3dScene.cancelTexturesOp) {
                        counter++;
                        ee.emitEvent('updateProgressBar', [{
                            label1: 'get map tile ' + counter + '/' + mapTiles.length,
                            value: 100 / mapTiles.length
                        }]);
                    }
                    return ez3dScene.setTileAndGetMapTexture(tile, tileScale);
                });
            }, Promise.resolve());

            bigPromise
                .then(() => {
                    snapshotMapStatus = 'generated';
                    ez3dScene.mapSnapshotData = {
                        provider: ez3dViewport.ez3dPlayer.getProvider(),
                        limits: captureLimits,
                        limitsScale: limitsScale,
                        tileWidth: tileWidth,
                        tileHeight: tileHeight,
                        tileScale: tileScale,
                    };
                    svgProject.callZoom(limitsScale, captureLimits[0], captureLimits[1]);
                    ez3dViewport.ez3dPlayer.setProvider(0);
                    ez3dViewport.ez3dPlayer.activateRenderer(true);

                    setTimeout(function () {
                        ee.emitEvent('hideProgressBar');
                        const status = {
                            status: 'FINISHED',
                        };
                        resolve(status);
                    }, 2000);
                });
        };

        if (ez3dScene.mapSnapshotData) {
            getMapTiles();
        } else {
            ee.addOnceListener('setMapSnapshotZoomLevel', setMapSnapshotZoomLevel);
            ee.addOnceListener('setMapSnapshotLimits', setMapSnapshotLimits);
            ee.addOnceListener('getMapTiles', getMapTiles);
        }
    });

    return promise;
};

EZModelScene.prototype.updateElementsStyles = function() {
    var promise = new Promise((resolve, reject) => {
        const isBeingModified = ({isEditing, isCreated}) => isEditing || !isCreated;

        const setAreaVisibilityAndStyle = function(area, value) {
            area.style.visible = value;
            area.style.colorState = ez3dScene.context === 'roof' ? 'roofEditor' : 'standard';
            // Filter subareas in area
            area.subareas.forEach((subarea) => {
                subarea.style.visible = value;
                subarea.style.colorState = 'standard';
            });
        };

        const setBuildingVisibilityAndStyle = function(build, value) {
            // Filter keepouts in building
            build.keepouts.forEach((keepout) => {
                keepout.style.visible = value;
                keepout.style.colorState = 'standard';
            });
            // Filter roofs in building
            build.roofs.forEach((roof) => {
                roof.style.visible = value;
                roof.style.colorState = 'standard';
                roof.areas.forEach((area) => setAreaVisibilityAndStyle(area, value));
            });
        };

        const shouldBeBuildingVisible = function(building) {
            if (isBeingModified(building)) return false;
            if (ez3dScene.isObjectBeingMoved(building.id)) return false;
            if (building.roofs.some(isBeingModified)) return false;
            if (building.keepouts.some(isBeingModified)) return false;
            if (ez3dScene.active.constructor === EZModelKeepout &&
                isBeingModified(ez3dScene.active)) return false;
            if (ez3dScene.active.constructor === EZModelSubarea &&
                isBeingModified(ez3dScene.active)) return false;
            if (building.roofs.some((roof) =>
                roof.areas.some((area) =>
                    area.subareas.some((subarea) =>
                        ez3dScene.isObjectBeingMoved(subarea.id)
                    )
                )
            )) return false;
            return true;
        };

        const shouldBeAreaVisible = function(area) {
            if (ez3dScene.active.constructor.name === 'EZModelKeepout' &&
                isBeingModified(ez3dScene.active)) return false;
            if (ez3dScene.isObjectBeingMoved(area.parent.parent.id)) return false;
            return true;
        };

        const shouldBeSubareaVisible = function(subarea) {
            if (subarea.disabled) return false;
            if (subarea.parent.disabled) return false;
            if (isBeingModified(subarea)) return false;
            if (ez3dScene.isObjectBeingMoved(subarea.id)) return false;
            if (ez3dScene.active.constructor.name === 'EZModelKeepout' &&
                isBeingModified(ez3dScene.active)) return false;
            return true;
        };

        const shouldBeKeepoutVisible = function(keepout, building) {
            if (keepout.disabled) return false;
            if (ez3dScene.active.constructor.name === 'EZModelSubarea' &&
                isBeingModified(ez3dScene.active)) return false;
            if (ez3dScene.isObjectBeingMoved(building.id)) return false;
            if (ez3dScene.isObjectBeingMoved(keepout.id)) return false;
            return !(building.roofs.some((roof) =>
                roof.areas.some((area) =>
                    area.subareas.some(isBeingModified))));
        };

        const shouldBeTreeVisible = function(tree) {
            if (ez3dScene.isObjectBeingMoved(tree.id)) return false;
            return true;
        };

        // Filter buildings
        ez3dScene.buildings.forEach((building) => {
            building.style.visible = shouldBeBuildingVisible(building);
            building.style.colorState = 'standard';
            if (building.style.visible) {
                setBuildingVisibilityAndStyle(building, true);
                // Filter keepouts
                building.keepouts.forEach((keepout) => {
                    const keepoutVisibility = shouldBeKeepoutVisible(keepout, building);
                    keepout.style.visible = keepoutVisibility;
                });
                // Filter areas
                if (building.keepouts.some(isBeingModified)) {
                    building.roofs.forEach((roof) => {
                        roof.areas.forEach((area) => {
                            setAreaVisibilityAndStyle(area, false);
                        });
                    });
                }
                building.roofs.forEach((roof) => {
                    roof.areas
                        .filter((area) => area.style.visible)
                        .forEach((area) => {
                            area.style.visible = shouldBeAreaVisible(area);
                            if (area.style.visible) {
                                // Filter subareas
                                area.subareas.forEach((subarea) => {
                                    if (subarea.style.visible) {
                                        subarea.style.visible = shouldBeSubareaVisible(subarea);
                                    }
                                });
                            } else {
                                setAreaVisibilityAndStyle(area, false);
                            }
                        });
                });
            } else {
                setBuildingVisibilityAndStyle(building, false);
            }
        });
        // Filter trees
        ez3dScene.trees.forEach((tree) => {
            tree.style.visible = shouldBeTreeVisible(tree);
            tree.style.colorState = 'standard';
        });

        // Set colorState
        if (ez3dScene.mode === 'interactiveMove') {
            const movingObject = ez3dScene.getMovingObject();
            if (movingObject && movingObject.style) {
                movingObject.style.colorState = 'move';
            }
        } else if (ez3dScene.active && ez3dScene.active.style) {
            ez3dScene.active.style.colorState = 'active';
        }

        resolve({status: 'FINISHED'});
    });
    return promise;
};

EZModelSubarea.prototype.addRowOffset = function(args) {
    var self = this;

    var promise = new Promise((resolve, reject) => {
        var rowIndex = args[0];
        var offset = (args.length >= 2) ? args[1] : {x: 0, y: 0};

        self.system.grid.rows[rowIndex].offset = offset;
        self.updateSystemOperator(false, false, true)
            .then((result) => {
                var status = {
                    status: 'FINISHED',
                    undo: true
                };

                resolve(status);
            });
    });

    return promise;
};

EZModelSubarea.prototype.changeRowOrientation = function(args) {
    var self = this;

    var promise = new Promise((resolve) => {
        var rowIndex = args[0];

        if (self.system.grid.rows[rowIndex].placement === 'portrait') {
            self.system.grid.rows[rowIndex].placement = 'landscape';
        } else {
            self.system.grid.rows[rowIndex].placement = 'portrait';
        }

        self.updateSystemOperator(false, false, true)
            .then(() => {
                var status = {
                    status: 'FINISHED',
                    undo: true
                };

                resolve(status);
            });
    });

    return promise;
};

EZModelSubarea.prototype.editSystem = function(args) {
    var self = this;

    var promise = new Promise((resolve, reject) => {
        var type = (args.length >= 1) ? args[0] : undefined;
        var mode = (args.length >= 2) ? args[1] : undefined;
        var index = (args.length >= 3) ? args[2] : undefined;

        self.system.setModules(type, mode, index);
        self.updateSystemOperator(true)
            .then((result) => {
                var status = {
                    status: 'FINISHED',
                    undo: true
                };

                resolve(status);
            });
    });

    return promise;
};

EZModelSubarea.prototype.moveSystem = function(args) {
    var self = this;

    var promise = new Promise((resolve, reject) => {
        var delta = (args.length >= 1) ? args[0] : undefined;
        var mode = (args.length >= 2) ? args[1] : undefined;
        var index = (args.length >= 3) ? args[2] : undefined;

        self.system.moveFlattenGrid(delta, mode, index);
        if (self.system.dilatationLines.enabled) {
            self.updateSystemGrid().then((result) => {
                var status = {
                    status: 'FINISHED',
                    undo: true
                };

                resolve(status);
            });
        } else {
            var status = {
                status: 'FINISHED',
                undo: [0]
            };

            resolve(status);
        }
    });

    return promise;
};

EZModelSubarea.prototype.resetSubarea = function() {
    var self = this;
    // var oldSystem = self.system;

    var promise = new Promise((resolve, reject) => {
        ez3dScene.utils.destroyObject(self.system.grid);
        self.system.grid = {};

        delete self.system.activePoint;
        delete self.system.rotatedData;

        if (self.system.sails.enabled) {
            self.system.sails.enabled = false;
        }

        self.updateSystemOperator()
            .then((result) => {
                if (self.system.dilatationLines.enabled) {
                    self.updateSystemGrid().then(() => {
                        var status = {
                            status: 'FINISHED',
                            undo: true
                        };
                        resolve(status);
                    });
                } else {
                    var status = {
                        status: 'FINISHED',
                        undo: [0]
                    };
                    resolve(status);
                }
            });
    });

    return promise;
};

EZModelSubarea.prototype.updateSystemGrid = function([resetLine, resetGrid, resetPath, movingGrid] = []) {
    var self = this;

    var promise = new Promise((resolve, reject) => {
        var subarea = self;
        var updateSystemGridFinish = function() {
            // Update system info
            subarea.updateSystem(true);
            resolve({
                status: 'FINISHED'
            });
        };

        ee.addOnceListener('updateGridFunctionFinished', updateSystemGridFinish);

        subarea.system.updateGrid(resetLine, resetGrid, resetPath, movingGrid);
    });

    return promise;
};

EZModelSubarea.prototype.updateSystemOperator = function(arg1, arg2, arg3) {
    var self = this;

    var promise = new Promise((resolve, reject) => {
        var updateSystemOperatorFinish = function() {
            resolve({
                status: 'FINISHED'
            });
        };

        ee.addOnceListener('updateSystemFunctionFinished', updateSystemOperatorFinish);
        self.updateSystem(arg1, arg2, arg3);
    });

    return promise;
};

EZModelSubarea.prototype.watcherAdjustmentOffsetOperator = function(arg1, arg2) {
    var self = this;

    var promise = new Promise((resolve, reject) => {
        var watcherAdjustmentOffsetOperatorFinish = function() {
            resolve({
                status: 'FINISHED'
            });
        };

        ee.addOnceListener('watcherAdjustmentOffsetFunctionFinished', watcherAdjustmentOffsetOperatorFinish);
        self.watcherAdjustmentOffset(arg1, arg2);
    });

    return promise;
};

EZModelArea.prototype.areaInfo = function() {
    var self = this;

    var objectJSON = {
        type: 'foldableBlock',
        id: this.id,
        object: 'area',
        title: {
            areaMoreInfo: {
                type: 'button',
                tooltip: 'moreInfo',
                classed: 'ez3d-button-resume foldable-down fa-caret-down'
            },
            name: {
                type: 'title',
                htmlElement: 'h2',
                classed: 'ez3d-title-resume',
                title: this.name
            },
            modulesInfo: {
                type: 'helper',
                classed: 'subtitle-info',
                // content: this.systemInfo.modules + ' MODULES-' + this.systemInfo.power + 'WP'
                content: {
                    text: 'modulesInfo',
                    vars: {
                        num: this.systemInfo.modules,
                        power: this.systemInfo.power
                    }
                }
            }
        },
        elements: {
            disabled: {
                type: 'button',
                property: 'disabled',
                tooltip: (this.disabled) ? 'enableArea' : 'disableArea',
                value: this.disabled,
                classed: 'ez3d-button-resume ez3d-disabled-eye',
                id: this.id
            },
            offset: {
                type: 'float',
                property: 'offset',
                label: 'areaEdgeZone',
                value: this.offset[0],
                max: 'infinity',
                min: 0,
                step: 0.1,
                numberOfDecimals: 2,
                universalUnits: true,
                editable: !this.disabled/*,
                confirmation: true,
                confirmationWidget: {
                    'name': 'edgeZone',
                    'title': 'edgeZoneAlertTitle',
                    'eventOk': 'widget_edgeZone_eventOk',
                    'eventCancel': 'close_widget_notification',
                    'functionOnCreation': ['formatEdgeZoneTooltip']
                }*/
            },
            inclination: {
                type: 'helper',
                classed: 'helperList',
                property: 'inclination',
                content: {
                    text: 'areaInlination',
                    vars: {
                        value: d3.format(',.2f')(this.inclination)
                    }
                }
            },
            area: {
                type: 'helper',
                classed: 'helperList',
                content: {
                    text: 'areaSurface',
                    vars: {
                        value: d3.format(',.2f')(this.path.getSurface(true))
                    }
                }
            },
            create: {
                position: '',
                type: 'button',
                tooltip: 'createSubarea',
                classed: 'ez3d-create ez3d-square-button ez3d-square-button-green',
                content: 'createSubarea',
                eventOnClick: [
                    'EZModelArea_createSubareaListener',
                    '',
                    true,
                    {draw2d: true, draw3d: true, updatePanels: true, fitRange: false},
                    false
                ],
                editable: !this.disabled
            }
        },
        functionOnClick: ['clickOnFoldableBlock', this.id]
    };

    self.subareas.forEach(function(subarea) {
        objectJSON.elements[subarea.id] = subarea.subareaInfoResume();
    });
    return objectJSON;
};

// areas info resume
EZModelBuilding.prototype.areasInfo = function() {
    var objectJSON = {
        title: {
            type: 'title',
            htmlElement: 'h2',
            classed: 'ez3d-group-title',
            title: 'areasInRoof'
        },
        helperList: {
            type: 'helper',
            classed: 'helperList',
            content: 'areaListAdvice1'
        },
        roofList: {
            type: 'blockList',
            emptyMesage: 'areasEmpty',
            classed: 'ez3d-block-list',
            elements: {}
        }
    };

    this.roofs.forEach((roof) => {
        objectJSON.roofList.elements[roof.id] = roof.roofAreasResume();
    });
    return objectJSON;
};

EZModelBuilding.prototype.buildingCreateAndEdit = function() {
    var objectJSON = {
        finish: {
            position: 'bottom',
            id: (ez3dScene.active.isCreated === false) ? 'ez3d-button-finish-building-create' : 'ez3d-button-finish-building-edit',
            type: 'button',
            classed: 'ez3d-square-button ez3d-square-button-green',
            content: 'finish',
            editable: 'finishBuildingValidation',
            eventOnClick: (ez3dScene.active.isCreated === false) ? ['finishBuildingCreationOperator', ''] : ['finishBuildingEditingOperator', '']
        },
        cancel: {
            id: (ez3dScene.active.isCreated === false) ? 'ez3d-button-cancel-building-create' : 'ez3d-button-cancel-building-edit',
            position: 'bottom',
            type: 'button',
            classed: 'ez3d-square-button ez3d-square-button-blue',
            content: 'cancel',
            eventOnClick: (ez3dScene.active.isCreated === false) ? ['cancelBuildingCreationOperator', ''] : ['cancelBuildingEditingOperator', '']
        },
        title: {
            type: 'title',
            htmlElement: 'h2',
            classed: 'ez3d-group-title',
            title: this.name,
            visibilityRule: (ez3dScene.active.isCreated === false)
        },
        helperList: {
            type: 'helper',
            classed: 'helperList',
            content: 'buildingAdvice2'
        },
        helperInfo: {
            type: 'helper',
            classed: 'helper',
            content: 'buildingAdvice1'
        },
        name: {
            type: 'string',
            property: 'name',
            label: 'buildingName',
            value: this.name,
            visibilityRule: ez3dScene.active.isCreated
        },
        populated: {
            type: 'boolean',
            property: 'populated',
            label: 'populatedWithModules',
            value: this.populated
        },
        ridge: {
            type: 'boolean',
            property: 'ridge.enabled',
            label: 'buildingHeight',
            labelTrue: 'ridge',
            labelFalse: 'gutter',
            value: this.ridge.enabled
        },
        height: {
            type: 'float',
            property: (this.ridge.enabled) ? 'ridge.height' : 'height',
            label: (this.ridge.enabled) ? 'ridgeHeight' : 'gutterHeight',
            value: (this.ridge.enabled) ? this.ridge.height : this.height,
            max: ez3dScene.getModelProperty('defaultModelValues', 'building', 'maxHeight'),
            min: ez3dScene.getModelProperty('defaultModelValues', 'building', 'minHeight'),
            step: 1,
            numberOfDecimals: 2,
            universalUnits: true
        },
        // offset: {
        //     type: 'float',
        //     property: 'offset',
        //     label: 'roofOffset',
        //     value: this.offset[0],
        //     max: 'infinity',
        //     min: 0,
        //     step: 0.1,
        //     numberOfDecimals: 2,
        //     universalUnits: true
        // },
        // padding: {
        //     type: 'boolean',
        //     property: 'padding.eaves',
        //     label: 'padding',
        //     labelTrue: 'eaves',
        //     labelFalse: 'parapet',
        //     value: this.padding.eaves,
        //     visibilityRule: (this.offset[0] !== 0)
        // },
        // parapet: {
        //     type: 'float',
        //     property: 'padding.parapet',
        //     label: 'parapetHeight',
        //     value: this.padding.parapet,
        //     max: 'infinity',
        //     min: 0,
        //     step: 0.1,
        //     numberOfDecimals: 2,
        //     universalUnits: true,
        //     visibilityRule: (!this.padding.eaves && this.offset[0] !== 0)
        // },
        regular: {
            type: 'boolean',
            property: 'regular',
            label: 'allowIrregularAngles',
            value: this.regular
        },
        helperRegular: {
            type: 'helper',
            classed: 'helperCenter',
            content: 'irregularAnglesHelper'
        }
    };
    return objectJSON;
};

EZModelBuilding.prototype.buildingCreateShape = function() {
    if (!this.customPath) return false;
    const shapeValues = this.customPath.buildingShapeValues[this.customPath.buildingShapeType];
    var objectJSON = {
        // Bottom buttons
        finish: {
            id: 'ez3d-button-finish-building-shape',
            position: 'bottom',
            type: 'button',
            classed: 'ez3d-square-button ez3d-square-button-green',
            content: 'finish',
            editable: 'finishBuildingValidation',
            eventOnClick: ['finishCreateBuildingShapeOperator', ''],
        },
        cancel: {
            id: 'ez3d-button-cancel-building-shape',
            position: 'bottom',
            type: 'button',
            classed: 'ez3d-square-button ez3d-square-button-blue',
            content: 'cancel',
            eventOnClick: ['cancelCreateBuildingShapeOperator', ''],
        },
        // Building shape selector
        type: {
            type: 'selectBySVGImage',
            property: 'customPath.buildingShapeType',
            label: 'buildingShapeType',
            classed: 'ez3d-building-shape',
            value: this.customPath.buildingShapeType,
            options: {
                rectangle: {
                    value: 'rectangle',
                    image: 'rectangle',
                    label: 'rectangle',
                },
                lShape: {
                    value: 'lShape',
                    image: 'lShape',
                    label: 'lShape',
                },
                cShape: {
                    value: 'cShape',
                    image: 'cShape',
                    label: 'cShape',
                },
                oShape: {
                    value: 'oShape',
                    image: 'oShape',
                    label: 'oShape',
                },
                step: {
                    value: 'step',
                    image: 'piramid',
                    label: 'stepByStep',
                },
            },
            runMethod: [
                'EZModelBuilding_runMethodListener',
                ['recalculateStepCustomPath'],
                true,
                {draw2d: true, draw3d: false, updatePanels: false, fitRange: false}
            ],
        },
        // Vertical values
        verticalTitle: {
            type: 'title',
            htmlElement: 'h4',
            classed: 'ez3d-group-title',
            title: 'verticalEdges',
            visibilityRule: this.customPath.buildingShapeType !== 'step',
        },
        groupMainVertical: {
            type: 'groupBlock',
            visibilityRule: 'mainVertical' in shapeValues,
            elements: {
                classed: 'ez3d-flex-between-wrap-row',
                mainVertical: {
                    type: 'float',
                    property: 'customPath.buildingShapeValues.' + this.customPath.buildingShapeType + '.mainVertical',
                    label: 'mainVerticalEdge',
                    value: shapeValues.mainVertical,
                    max: shapeValues.mainVerticalMax || Infinity,
                    min: shapeValues.mainVerticalMin || 0.1,
                    step: 0.1,
                    numberOfDecimals: 2,
                    universalUnits: true,
                    classed: ('mainVerticalLocked' in shapeValues) ? 'half-width' : undefined,
                    editable: !shapeValues.mainVerticalLocked,
                    undo: false,
                    eventOnChange: [
                        'EZModelBuilding_recalculateStepCustomPathListener',
                        ['mainVertical', shapeValues.mainVertical],
                        false,
                        {draw2d: true, draw3d: false, updatePanels: true, fitRange: false},
                        true
                    ],
                    d3Action: ['hoverBuildingEdgesOnInputFocus', ['mainVertical']],
                },
                mainVerticalLocked: {
                    type: 'button',
                    visibilityRule: 'mainVerticalLocked' in shapeValues,
                    property: 'customPath.buildingShapeValues.' + this.customPath.buildingShapeType + '.mainVerticalLocked',
                    value: shapeValues.mainVerticalLocked,
                    invertValue: true,
                    tooltip: 'lockEdge',
                    classed: shapeValues.mainVerticalLocked ? 'ez3d-button fa-lock' : 'ez3d-button fa-unlock',
                    parentClassed: 'padding-top-20',
                    undo: false,
                    eventOnChange: [
                        'EZModelBuilding_recalculateStepCustomPathListener',
                        ['mainVerticalLocked', shapeValues.mainVerticalLocked],
                        false,
                        {draw2d: true, draw3d: false, updatePanels: true, fitRange: false},
                        true
                    ],
                    d3Action: ['hoverBuildingEdgesOnInputFocus', ['mainVertical']],
                }
            },
        },
        groupSecondaryVertical0: {
            type: 'groupBlock',
            visibilityRule: 'secondaryVertical0' in shapeValues,
            elements: {
                classed: 'ez3d-flex-between-wrap-row',
                secondaryVertical0: {
                    type: 'float',
                    property: 'customPath.buildingShapeValues.' + this.customPath.buildingShapeType + '.secondaryVertical0',
                    label: 'secondaryVerticalEdge0',
                    value: shapeValues.secondaryVertical0,
                    max: shapeValues.secondaryVertical0Max || Infinity,
                    min: shapeValues.secondaryVertical0Min || 0.1,
                    step: 0.1,
                    numberOfDecimals: 2,
                    universalUnits: true,
                    classed: ('secondaryVertical0Locked' in shapeValues) ? 'half-width' : undefined,
                    editable: !shapeValues.secondaryVertical0Locked,
                    undo: false,
                    eventOnChange: [
                        'EZModelBuilding_recalculateStepCustomPathListener',
                        ['secondaryVertical0', shapeValues.secondaryVertical0],
                        false,
                        {draw2d: true, draw3d: false, updatePanels: true, fitRange: false},
                        true
                    ],
                    d3Action: ['hoverBuildingEdgesOnInputFocus', ['secondaryVertical0', this.customPath.buildingShapeType === 'oShape']],
                },
                secondaryVertical0Locked: {
                    type: 'button',
                    visibilityRule: 'secondaryVertical0Locked' in shapeValues,
                    property: 'customPath.buildingShapeValues.' + this.customPath.buildingShapeType + '.secondaryVertical0Locked',
                    value: shapeValues.secondaryVertical0Locked,
                    invertValue: true,
                    tooltip: 'lockEdge',
                    classed: shapeValues.secondaryVertical0Locked ? 'ez3d-button fa-lock' : 'ez3d-button fa-unlock',
                    parentClassed: 'padding-top-20',
                    undo: false,
                    eventOnChange: [
                        'EZModelBuilding_recalculateStepCustomPathListener',
                        ['secondaryVertical0Locked', shapeValues.secondaryVertical0Locked],
                        false,
                        {draw2d: true, draw3d: false, updatePanels: true, fitRange: false},
                        true
                    ],
                    d3Action: ['hoverBuildingEdgesOnInputFocus', ['secondaryVertical0', this.customPath.buildingShapeType === 'oShape']],
                }
            }
        },
        groupSecondaryVertical1: {
            type: 'groupBlock',
            visibilityRule: 'secondaryVertical1' in shapeValues,
            elements: {
                classed: 'ez3d-flex-between-wrap-row',
                secondaryVertical1: {
                    type: 'float',
                    property: 'customPath.buildingShapeValues.' + this.customPath.buildingShapeType + '.secondaryVertical1',
                    label: 'secondaryVerticalEdge1',
                    value: shapeValues.secondaryVertical1,
                    max: shapeValues.secondaryVertical1Max || Infinity,
                    min: shapeValues.secondaryVertical1Min || 0.1,
                    step: 0.1,
                    numberOfDecimals: 2,
                    universalUnits: true,
                    classed: ('secondaryVertical1Locked' in shapeValues) ? 'half-width' : undefined,
                    editable: !shapeValues.secondaryVertical1Locked,
                    undo: false,
                    eventOnChange: [
                        'EZModelBuilding_recalculateStepCustomPathListener',
                        ['secondaryVertical1', shapeValues.secondaryVertical1],
                        false,
                        {draw2d: true, draw3d: false, updatePanels: true, fitRange: false},
                        true
                    ],
                    d3Action: ['hoverBuildingEdgesOnInputFocus', ['secondaryVertical1']],
                },
                secondaryVertical1Locked: {
                    type: 'button',
                    visibilityRule: 'secondaryVertical1Locked' in shapeValues,
                    property: 'customPath.buildingShapeValues.' + this.customPath.buildingShapeType + '.secondaryVertical1Locked',
                    value: shapeValues.secondaryVertical1Locked,
                    invertValue: true,
                    tooltip: 'lockEdge',
                    classed: shapeValues.secondaryVertical1Locked ? 'ez3d-button fa-lock' : 'ez3d-button fa-unlock',
                    parentClassed: 'padding-top-20',
                    undo: false,
                    eventOnChange: [
                        'EZModelBuilding_recalculateStepCustomPathListener',
                        ['secondaryVertical1Locked', shapeValues.secondaryVertical1Locked],
                        false,
                        {draw2d: true, draw3d: false, updatePanels: true, fitRange: false},
                        true
                    ],
                    d3Action: ['hoverBuildingEdgesOnInputFocus', ['secondaryVertical1']],
                }
            }
        },
        // Horizontal values
        // - Main -
        horizontalTitle: {
            type: 'title',
            htmlElement: 'h4',
            classed: 'ez3d-group-title',
            title: 'horizontalEdges',
            visibilityRule: this.customPath.buildingShapeType !== 'step',
        },
        groupMainHorizontal: {
            type: 'groupBlock',
            visibilityRule: 'mainHorizontal' in shapeValues,
            elements: {
                classed: 'ez3d-flex-between-wrap-row',
                mainHorizontal: {
                    type: 'float',
                    property: 'customPath.buildingShapeValues.' + this.customPath.buildingShapeType + '.mainHorizontal',
                    label: 'mainHorizontalEdge',
                    value: shapeValues.mainHorizontal,
                    max: shapeValues.mainHorizontalMax || Infinity,
                    min: shapeValues.mainHorizontalMin || 0.1,
                    step: 0.1,
                    numberOfDecimals: 2,
                    universalUnits: true,
                    classed: ('mainHorizontalLocked' in shapeValues) ? 'half-width' : undefined,
                    editable: !shapeValues.mainHorizontalLocked,
                    undo: false,
                    eventOnChange: [
                        'EZModelBuilding_recalculateStepCustomPathListener',
                        ['mainHorizontal', shapeValues.mainHorizontal],
                        false,
                        {draw2d: true, draw3d: false, updatePanels: true, fitRange: false},
                        true],
                    d3Action: ['hoverBuildingEdgesOnInputFocus', ['mainHorizontal']],
                },
                mainHorizontalLocked: {
                    type: 'button',
                    visibilityRule: 'mainHorizontalLocked' in shapeValues,
                    property: 'customPath.buildingShapeValues.' + this.customPath.buildingShapeType + '.mainHorizontalLocked',
                    value: shapeValues.mainHorizontalLocked,
                    invertValue: true,
                    tooltip: 'lockEdge',
                    classed: shapeValues.mainHorizontalLocked ? 'ez3d-button fa-lock' : 'ez3d-button fa-unlock',
                    parentClassed: 'padding-top-20',
                    undo: false,
                    eventOnChange: [
                        'EZModelBuilding_recalculateStepCustomPathListener',
                        ['mainHorizontalLocked', shapeValues.mainHorizontalLocked],
                        false,
                        {draw2d: true, draw3d: false, updatePanels: true, fitRange: false},
                        true
                    ],
                    d3Action: ['hoverBuildingEdgesOnInputFocus', ['mainHorizontal']],
                }
            }
        },
        groupSecondaryHorizontal0: {
            type: 'groupBlock',
            visibilityRule: 'secondaryHorizontal0' in shapeValues,
            elements: {
                classed: 'ez3d-flex-between-wrap-row',
                secondaryHorizontal0: {
                    type: 'float',
                    property: 'customPath.buildingShapeValues.' + this.customPath.buildingShapeType + '.secondaryHorizontal0',
                    label: 'secondaryHorizontalEdge0',
                    value: shapeValues.secondaryHorizontal0,
                    max: shapeValues.secondaryHorizontal0Max || Infinity,
                    min: shapeValues.secondaryHorizontal0Min || 0.1,
                    step: 0.1,
                    numberOfDecimals: 2,
                    universalUnits: true,
                    classed: ('secondaryHorizontal0Locked' in shapeValues) ? 'half-width' : undefined,
                    editable: !shapeValues.secondaryHorizontal0Locked,
                    undo: false,
                    eventOnChange: [
                        'EZModelBuilding_recalculateStepCustomPathListener',
                        ['secondaryHorizontal0', shapeValues.secondaryHorizontal0],
                        false,
                        {draw2d: true, draw3d: false, updatePanels: true, fitRange: false},
                        true
                    ],
                    d3Action: ['hoverBuildingEdgesOnInputFocus', ['secondaryHorizontal0', this.customPath.buildingShapeType === 'oShape']],
                },
                secondaryHorizontal0Locked: {
                    type: 'button',
                    visibilityRule: 'secondaryHorizontal0Locked' in shapeValues,
                    property: 'customPath.buildingShapeValues.' + this.customPath.buildingShapeType + '.secondaryHorizontal0Locked',
                    value: shapeValues.secondaryHorizontal0Locked,
                    invertValue: true,
                    tooltip: 'lockEdge',
                    classed: shapeValues.secondaryHorizontal0Locked ? 'ez3d-button fa-lock' : 'ez3d-button fa-unlock',
                    parentClassed: 'padding-top-20',
                    undo: false,
                    eventOnChange: [
                        'EZModelBuilding_recalculateStepCustomPathListener',
                        ['secondaryHorizontal0Locked', shapeValues.secondaryHorizontal0Locked],
                        false,
                        {draw2d: true, draw3d: false, updatePanels: true, fitRange: false},
                        true
                    ],
                    d3Action: ['hoverBuildingEdgesOnInputFocus', ['secondaryHorizontal0', this.customPath.buildingShapeType === 'oShape']],
                }
            }
        },
        groupSecondaryHorizontal1: {
            type: 'groupBlock',
            visibilityRule: 'secondaryHorizontal1' in shapeValues,
            elements: {
                classed: 'ez3d-flex-between-wrap-row',
                secondaryHorizontal1: {
                    type: 'float',
                    property: 'customPath.buildingShapeValues.' + this.customPath.buildingShapeType + '.secondaryHorizontal1',
                    label: 'secondaryHorizontalEdge1',
                    value: shapeValues.secondaryHorizontal1,
                    max: shapeValues.secondaryHorizontal1Max || Infinity,
                    min: shapeValues.secondaryHorizontal1Min || 0.1,
                    step: 0.1,
                    numberOfDecimals: 2,
                    universalUnits: true,
                    classed: ('secondaryHorizontal1Locked' in shapeValues) ? 'half-width' : undefined,
                    editable: !shapeValues.secondaryHorizontal1Locked,
                    undo: false,
                    eventOnChange: [
                        'EZModelBuilding_recalculateStepCustomPathListener',
                        ['secondaryHorizontal1', shapeValues.secondaryHorizontal1],
                        false,
                        {draw2d: true, draw3d: false, updatePanels: true, fitRange: false},
                        true
                    ],
                    d3Action: ['hoverBuildingEdgesOnInputFocus', ['secondaryHorizontal1']],
                },
                secondaryHorizontal1Locked: {
                    type: 'button',
                    visibilityRule: 'secondaryHorizontal1Locked' in shapeValues,
                    property: 'customPath.buildingShapeValues.' + this.customPath.buildingShapeType + '.secondaryHorizontal1Locked',
                    value: shapeValues.secondaryHorizontal1Locked,
                    invertValue: true,
                    tooltip: 'lockEdge',
                    classed: shapeValues.secondaryHorizontal1Locked ? 'ez3d-button fa-lock' : 'ez3d-button fa-unlock',
                    parentClassed: 'padding-top-20',
                    undo: false,
                    eventOnChange: [
                        'EZModelBuilding_recalculateStepCustomPathListener',
                        ['secondaryHorizontal1Locked', shapeValues.secondaryHorizontal1Locked],
                        false,
                        {draw2d: true, draw3d: false, updatePanels: true, fitRange: false},
                        true
                    ],
                    d3Action: ['hoverBuildingEdgesOnInputFocus', ['secondaryHorizontal1']],
                }
            }
        },
        // - stepByStep -
        helperStep: {
            type: 'helper',
            visibilityRule: this.customPath.buildingShapeType === 'step',
            classed: 'helperList',
            content: 'stepByStepHelper0',
        },
        addStep: {
            id: 'ez3d-button-add-step-shape',
            type: 'button',
            classed: 'ez3d-square-button ez3d-square-button-green',
            content: 'addStep',
            eventOnClick: [
                'EZModelBuilding_addStepToCustomShapeListener',
                null,
                false,
                {draw2d: true, draw3d: false, updatePanels: true, fitRange: false},
                true
            ],
            visibilityRule: this.customPath.buildingShapeType === 'step',
        },
        // selectRotation: {
        //     type: 'boolean',
        //     property: 'customPath.clockwiseSteps',
        //     visibilityRule: this.customPath.buildingShapeType === 'step',
        //     label: 'stepRotation',
        //     labelTrue: 'clockwise',
        //     labelFalse: 'counterClockwise',
        //     value: this.customPath.clockwiseSteps,
        //     undo: false,
        //     eventOnChange: ['EZModelBuilding_recalculateStepCustomPathListener',
        //         ['corner', 0],
        //         false, true, true],
        // },
        stepByStep: {
            type: 'groupBlock',
            id: 'step-by-step-group-block',
            visibilityRule: this.customPath.buildingShapeType === 'step',
            elements: this.generateCustomShapeStepBlocks(),
        },
        // Angle values
        rotationTitle: {
            type: 'title',
            htmlElement: 'h4',
            classed: 'ez3d-group-title',
            title: 'buildingRotation',
        },
        angle: {
            type: 'integer',
            property: 'customPath.buildingShapeValues.' + this.customPath.buildingShapeType + '.angle',
            label: `${ez3dViewport.ez3dGuiManager.i18n('degrees')} (CCW)`,
            value: shapeValues.angle,
            max: 360 || Infinity,
            min: -360 || 0.1,
            step: 1,
            undo: false,
            eventOnChange: [
                'EZModelBuilding_recalculateStepCustomPathListener',
                null,
                false,
                {draw2d: true, draw3d: false, updatePanels: true, fitRange: false},
                true],
        },
    };
    return objectJSON;
};

EZModelBuilding.prototype.generateCustomShapeStepBlocks = function() {
    const pathSteps = this.customPath.customShapeSteps;
    const objectJSON = {};
    for (var i = 0; i < pathSteps.length; i++) {
        const groupName = 'group_' + i;
        const block = {};
        block[groupName] = {
            type: 'groupBlock',
            id: 'step-group-' + i,
            elements: {
                classed: 'ez3d-flex-between-nowrap-row',
                block0: {
                    type: 'groupBlock',
                    elements: {
                        classed: 'ez3d-flex-between-nowrap-row',
                        wall: {
                            type: 'float',
                            id: 'stepWall_' + i,
                            classed: 'ez3d-width-50',
                            label: `${ez3dViewport.ez3dGuiManager.i18n('wall')} ${i + 1}-${i + 2}`,
                            value: pathSteps[i][0],
                            max: Infinity,
                            min: 0,
                            step: 0.1,
                            numberOfDecimals: 2,
                            universalUnits: true,
                            eventOnChange: [
                                'EZModelBuilding_recalculateStepCustomPathListener',
                                ['wall', i],
                                false,
                                {draw2d: true, draw3d: false, updatePanels: true, fitRange: false},
                                true
                            ],
                            d3Action: ['hoverBuildingEdgesOnInputFocus', [i]],
                        },
                        corner: {
                            type: 'integer',
                            id: 'stepCorner_' + i,
                            classed: 'ez3d-width-50',
                            label: (i === 0) ? `${ez3dViewport.ez3dGuiManager.i18n('direction')} 1 (EºS)`
                                : `${ez3dViewport.ez3dGuiManager.i18n('angle')} ${i + 1} (CW)`,
                            value: pathSteps[i][1],
                            // editable: i !== 0,
                            max: 180,
                            min: -180,
                            step: 1,
                            eventOnChange: [
                                'EZModelBuilding_recalculateStepCustomPathListener',
                                ['corner', i],
                                false,
                                {draw2d: true, draw3d: false, updatePanels: true, fitRange: false},
                                true
                            ],
                        }
                    }
                },
                corner: {
                    type: 'integer',
                    id: 'stepCorner_' + i,
                    classed: (i > 1) ? 'ez3d-stepCorner' : '',
                    label: `${ez3dViewport.ez3dGuiManager.i18n('angle')} (E°S)`,
                    value: pathSteps[i][1],
                    max: 180,
                    min: -180,
                    step: 1,
                    eventOnChange: [
                        'EZModelBuilding_recalculateStepCustomPathListener',
                        ['corner', i],
                        false,
                        {draw2d: true, draw3d: false, updatePanels: true, fitRange: false},
                        true
                    ],
                },
                remove: {
                    type: 'button',
                    tooltip: 'remove',
                    id: 'ez3d-perspective-button',
                    classed: 'ez3d-button fa-trash ez3d-width-20',
                    parentClassed: 'padding-top-20 padding-left-10',
                    visibilityRule: (i > 1),
                    eventOnClick: [
                        'EZModelBuilding_removeStepToCustomShapeListener',
                        i,
                        false,
                        {draw2d: true, draw3d: false, updatePanels: true, fitRange: false},
                        true
                    ],
                }
            }
        };

        Object.assign(objectJSON, block);
    }
    return objectJSON;
};

EZModelBuilding.prototype.buildingInfo = function() {
    var objectJSON = {
        title: {
            type: 'title',
            htmlElement: 'h2',
            classed: 'ez3d-group-title',
            title: this.name
        },
        // name: {
        //     type: 'string',
        //     property: 'name',
        //     label: 'buildingName',
        //     value: this.name
        // },
        ridge: {
            type: 'boolean',
            property: 'ridge.enabled',
            label: 'buildingHeight',
            labelTrue: 'ridge',
            labelFalse: 'gutter',
            value: this.ridge.enabled
        },
        height: {
            type: 'float',
            property: (this.ridge.enabled) ? 'ridge.height' : 'height',
            label: (this.ridge.enabled) ? 'ridgeHeight' : 'gutterHeight',
            value: (this.ridge.enabled) ? this.ridge.height : this.height,
            max: ez3dScene.getModelProperty('defaultModelValues', 'building', 'maxHeight'),
            min: (this.ridge.enabled) ? ez3dScene.getModelProperty('defaultModelValues', 'building', 'minHeight') + this.ridge.maxRoof : ez3dScene.getModelProperty('defaultModelValues', 'building', 'minHeight'),
            step: 1,
            numberOfDecimals: 2,
            universalUnits: true,
            confirmation: 'confirmBuildingHeight',
            confirmationWidget: {
                'name': 'height',
                'title': 'alertBuildingHeightTitle',
                'content': 'alertBuildingHeightContent'
            }
        },
        totalHeight: {
            type: 'helper',
            classed: 'helperList',
            content: {
                text: (this.ridge.enabled) ? 'gutterHeightVar' : 'ridgeHeightVar',
                vars: {
                    value: d3.format(',.2f')((this.ridge.enabled) ? this.height : this.ridge.height)
                }
            }
        },
        buttonsPanel: {
            type: 'groupBlock',
            elements: {
                classed: 'ez3d-panel-buttons ez3d-flex-around-wrap-row',
                edit: {
                    id: 'ez3d-edit-building-button',
                    type: 'button',
                    tooltip: 'editBuilding',
                    classed: 'ez3d-button fa-pencil',
                    eventOnClick: [
                        'EZModelBuilding_editBuildingListener',
                        undefined,
                        true,
                        {draw2d: true, draw3d: this, updatePanels: true, fitRange: false}
                    ]
                },
                move: {
                    id: 'ez3d-move-building-button',
                    type: 'button',
                    tooltip: 'moveBuilding',
                    classed: 'ez3d-button fa-arrows',
                    active: ['isInteractiveMode', this.id],
                    eventOnClick: [
                        'EZModelBuilding_runMethodListener',
                        'setInteractiveMoveToggle',
                        false,
                        {draw2d: true, draw3d: this, updatePanels: false, fitRange: false}
                    ]
                },
                delete: {
                    id: 'ez3d-delete-building-button',
                    type: 'button',
                    tooltip: 'deleteBuilding',
                    classed: 'ez3d-button fa-trash',
                    confirmation: true,
                    confirmationWidget: {
                        'name': 'delete_building',
                        'title': 'deleteBuilding',
                        'content': 'deleteBuildingConfirmation',
                        'eventOk': ['EZModelBuilding_deleteListener'],
                        'eventCancel': 'widget_deleteBuilding_eventCancel'
                    }
                },
                clone: {
                    id: 'ez3d-clone-building-button',
                    type: 'button',
                    tooltip: 'cloneBuilding',
                    classed: 'ez3d-button fa-clone',
                    eventOnClick: [
                        'EZModelBuilding_cloneListener',
                        null,
                        true,
                        {draw2d: true, draw3d: this, updatePanels: true, fitRange: false},
                        true
                    ]

                },
                paint: {
                    id: 'ez3d-paint-building-button',
                    type: 'button',
                    tooltip: 'paint',
                    classed: 'ez3d-button fa-paint-brush',
                    confirmation: true,
                    confirmationWidget: {
                        'name': 'color-palette-selector-building',
                        'title': 'colorPaletteSelector',
                        'eventOk': 'widget_color_eventOk',
                        'eventCancel': 'widget_color_eventCancel',
                        'functionOnCreation': ['colorPaletteWidget', 'building']
                    }
                }
            }

        },
        roofList: {
            type: 'groupBlock',
            elements: {
                classed: 'ez3d-panel-resume-buttons',
            }
        },
        textureButtonsPanel: {
            type: 'groupBlock',
            label: 'satelliteImageTextures',
            visibilityRule: ez3dScene.layoutRules.scenePreferences.enablePlayer,
            elements: {
                classed: 'ez3d-panel-buttons ez3d-flex-around-wrap-row',
                genTexture: {
                    type: 'button',
                    classed: 'ez3d-button fa-plus',
                    // content: 'add',
                    tooltip: 'addSatelliteTexture',
                    visibilityRule: this.texture === undefined,
                    eventOnClick: [
                        'EZModelBuilding_getTextureListener',
                        undefined,
                        true,
                        {draw2d: true, draw3d: this, updatePanels: true, fitRange: false},
                        true
                    ]
                },
                regenTexture: {
                    type: 'button',
                    classed: 'ez3d-button fa-refresh',
                    // content: 'regen',
                    tooltip: 'regenerateSatelliteTexture',
                    visibilityRule: this.texture !== undefined,
                    eventOnClick: [
                        'EZModelBuilding_getTextureListener',
                        undefined,
                        true,
                        {draw2d: true, draw3d: this, updatePanels: true, fitRange: false},
                        true
                    ]
                },
                removeTexture: {
                    type: 'button',
                    classed: 'ez3d-button fa-trash',
                    // content: 'remove',
                    tooltip: 'removeSatelliteTexture',
                    visibilityRule: this.texture !== undefined,
                    eventOnClick: [
                        'EZModelBuilding_removeTextureListener',
                        undefined,
                        true,
                        {draw2d: true, draw3d: this, updatePanels: true, fitRange: false},
                        true
                    ]
                }
            }
        },
        genAllTextures: {
            type: 'button',
            classed: 'ez3d-square-button ez3d-square-button-green',
            content: 'generateForAllBuildings',
            tooltip: 'generateForAllBuildingsInProject',
            visibilityRule: ez3dScene.layoutRules.scenePreferences.enablePlayer,
            confirmation: true,
            confirmationWidget: {
                'name': 'generateTextures',
                'title': 'generateTexturesTitle',
                'eventOk': [
                    'EZModelScene_getTexturesListener',
                    undefined,
                    true,
                    {draw2d: true, draw3d: true, updatePanels: true, fitRange: true},
                    true
                ],
                'eventCancel': 'close_widget_notification',
                'functionOnCreation': ['formatGetTexturesTooltip']
            }
        },
        removeAllTextures: {
            type: 'button',
            classed: 'ez3d-square-button ez3d-square-button-green',
            content: 'removeAllTextures',
            tooltip: 'removeAllTexturesInProject',
            visibilityRule: ez3dScene.layoutRules.scenePreferences.enablePlayer,
            eventOnClick: [
                'EZModelScene_removeTexturesListener',
                undefined,
                true,
                {draw2d: false, draw3d: true, updatePanels: true, fitRange: false},
                true
            ]
        }
    };

    var self = this;

    self.roofs.forEach(function(roof) {
        objectJSON.roofList.elements[roof.id] = roof.roofInfoResume();
    });

    return objectJSON;
};

// keepouts info
EZModelBuilding.prototype.keepoutsInfo = function() {
    var objectJSON = {
        keepoutList: {
            type: 'blockList',
            emptyMesage: 'keepoutEmpty',
            classed: 'ez3d-block-list',
            elements: {}
        }
    };

    var self = this;

    self.keepouts.forEach(function(keepout) {
        objectJSON.keepoutList.elements[keepout.id] = keepout.keepoutInfo();
    });
    return objectJSON;
};

EZModelBuilding.prototype.simpleBuildingInfo = function() {
    var objectJSON = {
        next: {
            position: 'bottom',
            type: 'button',
            classed: 'ez3d-square-button ez3d-square-button-green',
            content: 'next',
            eventOnClick: ['EZModelScene_setActiveListener', ez3dScene.activeBuilding.roofs[0].areas[0].subareas[0].id, true, {draw2d: true, draw3d: false, updatePanels: true, fitRange: true}]
        },
        type: {
            type: 'selectByImage',
            property: 'type',
            label: 'roofType',
            value: this.roofs[0].type,
            options: this.roofs[0].getAvailableTypes(),
            runMethod: [
                'EZModelBuilding_runMethodListener',
                ['runRoofTypeChange', true],
                true,
                {draw2d: true, draw3d: true, updatePanels: true, fitRange: false}
            ],
        },
        height: {
            type: 'float',
            property: 'height',
            label: 'buildingHeight',
            value: this.height,
            max: ez3dScene.getModelProperty('defaultModelValues', 'building', 'maxHeight'),
            min: 0,
            step: 1,
            numberOfDecimals: 2,
            universalUnits: true
        },
        totalHeight: {
            type: 'helper',
            classed: 'helperList',
            property: 'totalHeight',
            content: {
                text: 'ridgeHeight',
                vars: {
                    value: d3.format(',.2f')(this.height + this.roofs[0].height)
                }
            },
            visibilityRule: this.roofs[0].type === 'pent'
        },
        inclination: {
            type: 'integer',
            property: 'inclination',
            label: 'roofInclination',
            value: this.roofs[0].inclination,
            max: 75,
            min: 5,
            step: 1,
            runMethod: [
                'EZModelBuilding_runMethodListener',
                ['runRoofTypeChange', true],
                true,
                {draw2d: true, draw3d: true, updatePanels: true, fitRange: false}
            ],
            visibilityRule: this.roofs[0].type === 'pent'
        },
        rows: {
            customProperty: true,
            value: this.roofs[0].areas[0].subareas[0].system.grid.rows.length || 3,
            type: 'integer',
            property: 'rows',
            label: 'rows',
            max: Infinity,
            min: 1,
            step: 1,
            runMethod: ['EZModelBuilding_runMethodListener', ['runRowNumberChange', true]],
        },
        columns: {
            customProperty: true,
            value: this.roofs[0].areas[0].subareas[0].system.grid.rows[0].literal.length || 3,
            type: 'integer',
            property: 'columns',
            label: 'columns',
            max: Infinity,
            min: 1,
            step: 1,
            runMethod: ['EZModelBuilding_runMethodListener', ['runColumnNumberChange', true]],
        },
        material: {
            type: 'selectByImage',
            property: 'material',
            label: 'roofMaterial',
            value: this.roofs[0].material,
            options: this.roofs[0].getAvailableMaterials(),
            runMethod: [
                'EZModelBuilding_runMethodListener',
                ['runRoofTypeChange', true],
                true,
                {draw2d: false, draw3d: false, updatePanels: true, fitRange: false}
            ]
        },
    };

    return objectJSON;
};

// Validations
// @todo mezcla interfaz y modelo
// finish building condition
EZModelBuilding.prototype.finishBuildingValidation = function() {
    let validBuilding = true;
    const ez3dViewport = ez3dScene.layoutInstance.ez3dViewport;
    const allowNotifications = ez3dViewport !== 3;

    const currentPath = this.customPath || ez3dScene.tempPath;
    const currentLocations = (this.customPath) ? this.customPath.vertices : ez3dScene.locations;

    validBuilding = (currentPath) ? !currentPath.isInvalid : true;

    if (ez3dScene.context === 'pathEditor') {
        if (currentLocations.length > 2 && currentPath) {
            // allowIrregularAngles (if irregular angles are not allowed)
            if (!this.regular) {
                currentPath.calculateCornersAngles();
                if (!currentPath.regularAngles) {
                    validBuilding = false;
                    if (allowNotifications) {
                        ez3dViewport.ez3dGuiManager.notificationManager(['anglesNotRegular', {style: 'error'}]);
                    }
                }
            }
        } else {
            validBuilding = false;
            if (allowNotifications) {
                ez3dViewport.ez3dGuiManager.notificationManager(['drawPoint', {style: 'info'}]);
            }
        }
    }
    return validBuilding;
};

// widget notification examples
// functionOnClick: ['widgetConstructor', {'name': 'notification1', 'title': 'notificationTitle', 'content': 'notification'}]
// ee.emitEvent('widget_notification', [{'name': 'notification1', 'title': 'notificationTitle', 'content': 'notification'}]);


// keepout create info
EZModelKeepout.prototype.keepoutCreate = function() {
    var objectJSON = {
        finish: {
            position: 'bottom',
            type: 'button',
            id: 'ez3d-button-finish-keepout-create',
            classed: 'ez3d-square-button ez3d-square-button-green',
            content: 'finish',
            editable: 'finishKeepoutValidation',
            eventOnClick: ['finishKeepoutCreation', '']
        },
        cancel: {
            position: 'bottom',
            type: 'button',
            classed: 'ez3d-square-button ez3d-square-button-blue',
            content: 'cancel',
            eventOnClick: ['cancelKeepoutCreation', '']
        },
        title: {
            type: 'title',
            htmlElement: 'h2',
            classed: 'ez3d-group-title',
            title: this.name
        },
        height: {
            type: 'float',
            property: 'height',
            label: 'height',
            value: this.height,
            max: Infinity,
            min: 0.1,
            step: 0.1,
            numberOfDecimals: 2,
            universalUnits: true
        },
        offset: {
            type: 'float',
            property: 'offset',
            label: 'offset',
            value: this.offset,
            max: Infinity,
            min: 0,
            step: 0.1,
            numberOfDecimals: 2,
            universalUnits: true
        },
        invisible: {
            type: 'boolean',
            property: 'invisible',
            label: 'invisibleKeepout',
            value: this.invisible
        },
        regular: {
            type: 'boolean',
            property: 'regular',
            label: 'allowIrregularAngles',
            value: this.regular
        },
        helperRegular: {
            type: 'helper',
            classed: 'helperCenter',
            content: 'irregularAnglesHelper'
        },
        type: {
            type: 'selectBySVGImage',
            property: 'type',
            label: 'keepoutType',
            value: this.type,
            options: {
                vertical: {value: 'vertical'},
                inclined: {value: 'inclined'}
            }
        }
    };
    return objectJSON;
};

// keepout edit info
EZModelKeepout.prototype.keepoutEdit = function() {
    var objectJSON = {
        finish: {
            position: 'bottom',
            type: 'button',
            classed: 'ez3d-square-button ez3d-square-button-green',
            content: 'finish',
            editable: 'finishKeepoutValidation',
            eventOnClick: ['finishKeepoutEditingOperator', '']
        },
        cancel: {
            position: 'bottom',
            type: 'button',
            classed: 'ez3d-square-button ez3d-square-button-blue',
            content: 'cancel',
            eventOnClick: ['cancelKeepoutEditingOperator', '']
        },
        title: {
            type: 'title',
            htmlElement: 'h2',
            classed: 'ez3d-group-title',
            title: this.name
        },
        height: {
            type: 'float',
            property: 'height',
            label: 'height',
            value: this.height,
            max: Infinity,
            min: 0.1,
            step: 1,
            numberOfDecimals: 2,
            universalUnits: true
        },
        offset: {
            type: 'float',
            property: 'offset',
            label: 'offset',
            value: this.offset,
            max: Infinity,
            min: 0,
            step: 0.1,
            numberOfDecimals: 2,
            universalUnits: true
        },
        invisible: {
            type: 'boolean',
            property: 'invisible',
            label: 'invisibleKeepout',
            value: this.invisible
        },
        regular: {
            type: 'boolean',
            property: 'regular',
            label: 'allowIrregularAngles',
            value: this.regular
        },
        helperRegular: {
            type: 'helper',
            classed: 'helperCenter',
            content: 'irregularAnglesHelper'
        },
        type: {
            type: 'selectBySVGImage',
            property: 'type',
            label: 'keepoutType',
            value: this.type,
            options: {
                vertical: {value: 'vertical'},
                inclined: {value: 'inclined'}
            }
        }
    };
    return objectJSON;
};


// keepout info
EZModelKeepout.prototype.keepoutInfo = function() {
    var objectJSON = {
        type: 'foldableBlock',
        id: this.id,
        object: 'keepout',
        title: {
            keepoutMoreInfo: {
                type: 'button',
                tooltip: 'moreInfo',
                classed: 'ez3d-button-resume foldable-down fa-caret-down'
            },
            name: {
                type: 'title',
                htmlElement: 'h2',
                classed: 'ez3d-title-resume',
                title: this.name
            },
            heightInfo: {
                type: 'helper',
                classed: 'subtitle-info',
                content: {
                    text: 'subtitleInfo',
                    vars: {
                        height: this.height
                    }
                }
            }
        },
        elements: {
            height: {
                type: 'float',
                property: 'height',
                label: 'height',
                value: this.height,
                max: Infinity,
                min: 0.1,
                step: 0.1,
                numberOfDecimals: 2,
                universalUnits: true
            },
            offset: {
                type: 'float',
                property: 'offset',
                label: 'offset',
                value: this.offset,
                max: Infinity,
                min: 0,
                step: 0.1,
                numberOfDecimals: 2,
                universalUnits: true
            },
            invisible: {
                type: 'boolean',
                property: 'invisible',
                label: 'invisibleKeepout',
                value: this.invisible
            },
            type: {
                type: 'selectBySVGImage',
                property: 'type',
                label: 'keepoutType',
                value: this.type,
                options: {
                    vertical: {value: 'vertical'},
                    inclined: {value: 'inclined'}
                }
            },
            buttonsPanel: {
                type: 'groupBlock',
                elements: {
                    classed: 'ez3d-panel-buttons ez3d-flex-around-wrap-row',
                    edit: {
                        type: 'button',
                        tooltip: 'editKeepout',
                        classed: 'ez3d-button fa-pencil',
                        eventOnClick: [
                            'EZModelKeepout_editKeepoutListener',
                            this.id,
                            {draw2d: true, draw3d: false, updatePanels: false, fitRange: false},
                            true
                        ]
                    },
                    move: {
                        type: 'button',
                        tooltip: 'moveKeepout',
                        classed: 'ez3d-button fa-arrows',
                        active: ['isInteractiveMode', this.id],
                        eventOnClick: [
                            'EZModelKeepout_runMethodListener',
                            'setInteractiveMoveToggle',
                            false,
                            {draw2d: true, draw3d: this, updatePanels: true, fitRange: false}
                        ]
                    },
                    delete: {
                        type: 'button',
                        tooltip: 'deleteKeepout',
                        classed: 'ez3d-button fa-trash',
                        eventOnClick: [
                            'EZModelKeepout_deleteListener',
                            this.id,
                            true,
                            {draw2d: true, draw3d: this, updatePanels: true, fitRange: true},
                            true
                        ]
                    },
                    clone: {
                        type: 'button',
                        id: 'ez3d-button-clone-keepout',
                        tooltip: 'cloneKeepout',
                        classed: 'ez3d-button fa-clone',
                        eventOnClick: [
                            'EZModelKeepout_cloneListener',
                            null,
                            true,
                            {draw2d: true, draw3d: this, updatePanels: true, fitRange: false},
                            true
                        ]
                    },
                    paint: {
                        id: 'ez3d-paint-keepout-button',
                        type: 'button',
                        tooltip: 'paint',
                        classed: 'ez3d-button fa-paint-brush',
                        confirmation: true,
                        confirmationWidget: {
                            'name': 'color-palette-selector-keepout',
                            'title': 'colorPaletteSelector',
                            'eventOk': 'widget_color_eventOk',
                            'eventCancel': 'widget_color_eventCancel',
                            'functionOnCreation': ['colorPaletteWidget', 'keepout']
                        }
                    }
                }

            }
        },
        functionOnClick: ['clickOnFoldableBlock', this.id],
        // updateOnClick: true
    };

    return objectJSON;
};

// Validations

// finish keepout condition
EZModelKeepout.prototype.finishKeepoutValidation = function() {
    var validKeepout = true;

    if (ez3dScene.locations.length > 2 && ez3dScene.tempPath) {
        // allowIrregularAngles
        if (!this.regular) {
            ez3dScene.tempPath.calculateCornersAngles();
            if (!ez3dScene.tempPath.regularAngles) {
                validKeepout = false;
            }
        }
    } else {
        validKeepout = false;
    }

    // lanzar notificacion con info de añadir puntos

    return validKeepout;
};

EZModelRoof.prototype.roofAreasResume = function() {
    var objectJSON = {
        type: 'blockResume',
        elements: {
            name: {
                type: 'string',
                value: this.name,
            },
            button: {
                type: 'button',
                tooltip: this.name,
                classed: 'ez3d-complex-button ez3d-' + this.type + '-on-icon'
            },
            areasList: {
                type: 'blockList',
                emptyMesage: 'areasEmpty',
                classed: 'ez3d-block-list',
                elements: {}
            }
        }

    };

    this.areas.forEach((area) => {
        objectJSON.elements.areasList.elements[area.id] = area.areaInfo();
    });

    return objectJSON;
};

EZModelRoof.prototype.roofEditAndCreate = function() {
    var objectJSON = {
        finishEdit: {
            position: 'bottom',
            id: 'ez3d-button-finish-roof-edit',
            type: 'button',
            classed: 'ez3d-square-button ez3d-square-button-green',
            content: 'finish',
            eventOnClick: ['finishRoofEditOperator', '']
        },
        cancelEdit: {
            id: 'ez3d-button-cancel-roof-edit',
            position: 'bottom',
            type: 'button',
            classed: 'ez3d-square-button ez3d-square-button-blue',
            content: 'cancel',
            eventOnClick: ['cancelRoofEditOperator', '']
        },
        title: {
            type: 'title',
            htmlElement: 'h2',
            classed: 'ez3d-group-title',
            title: this.name
        },
        type: {
            type: 'selectByImage',
            property: 'type',
            label: 'roofType',
            value: this.type,
            options: this.getAvailableTypes(),
            runMethod: [
                'EZModelRoof_runMethodListener',
                ['updateOnRoofTypeChange', true],
                true,
                {draw2d: true, draw3d: this, updatePanels: true, fitRange: false}],
            confirmation: 'roofNeedsUpdate',
            confirmationWidget: {
                'name': 'type',
                'title': 'alertRoofChangeTitle',
                'content': 'alertRoofChangeContent',
                'eventOk': 'widget_roofChangeType_eventOk',
                'eventCancel': 'roofTypeChanged',
                'functionOnCreation': ['roofTypeDisabledScreen']
            }
        },
        ridge: {
            type: 'float',
            property: 'parent.ridge.height',
            label: 'ridgeHeight',
            value: this.parent.ridge.height,
            max: ez3dScene.getModelProperty('defaultModelValues', 'building', 'maxHeight'),
            min: ez3dScene.getModelProperty('defaultModelValues', 'building', 'minHeight') +
                this.parent.ridge.maxRoof,
            step: 1,
            numberOfDecimals: 2,
            universalUnits: true,
            visibilityRule: this.parent.ridge.enabled
        },
        orientation: {
            type: 'compass',
            id: 'compass-' + this.type,
            property: 'orientation',
            label: 'roofOrientation',
            value: this.path.wallFacing[this.orientation],
            optionsLabels: this.getDefaultValue('availableOrientation', this.type),
            runMethod: [
                'EZModelRoof_runMethodListener',
                ['roofHardUpdate', true],
                {draw2d: true, draw3d: this, updatePanels: true, fitRange: false},
                true
            ],
            visibilityRule: ['gabled', 'hipped', 'gambrel'].includes(this.type),
            confirmation: 'roofNeedsUpdate',
            confirmationWidget: {
                'name': 'orientation',
                'title': 'alertRoofChangeTitle',
                'content': 'alertRoofChangeContent',
                'eventOk': 'widget_roofChangeOrientation_eventOk',
                'eventCancel': 'widget_roofChangeOrientation_eventCancel'
            }
        },
        roofPointsSymmetry: {
            id: 'roofPointsSymmetry',
            type: 'boolean',
            property: 'roofPointsSymmetry',
            label: 'symmetricRidge',
            labelTrue: 'yes',
            labelFalse: 'no',
            value: this.roofPointsSymmetry,
            visibilityRule: (this.roofPointsSymmetry !== undefined),
        },
        ridge: {
            type: 'float',
            property: 'parent.ridge.height',
            label: 'ridgeHeight',
            value: this.parent.ridge.height,
            max: ez3dScene.getModelProperty('defaultModelValues', 'building', 'maxHeight'),
            min: ez3dScene.getModelProperty('defaultModelValues', 'building', 'minHeight') +
                this.parent.ridge.maxRoof,
            step: 1,
            numberOfDecimals: 2,
            universalUnits: true,
            visibilityRule: this.parent.ridge.enabled
        },
        elevation: {
            type: 'float',
            property: 'elevation',
            label: 'roofElevation',
            value: this.elevation,
            max: ez3dScene.getModelProperty('defaultModelValues', 'building', 'maxHeight'),
            min: -(this.parent.height + this.baseHeight[0]),
            step: 1,
            runMethod: [
                'EZModelRoof_runMethodListener',
                ['roofSoftUpdate', true],
                true,
                {draw2d: true, draw3d: this, updatePanels: true, fitRange: false}
            ],
            visibilityRule: (this.index > 1),
        },
        material: {
            type: 'selectByImage',
            property: 'material',
            label: 'roofMaterial',
            value: this.material,
            options: this.getAvailableMaterials()
        },
    };
    return objectJSON;
};

EZModelRoof.prototype.roofInfo = function() {
    console.error('DEPRECATED');
    var objectJSON = {
        close: {
            position: 'bottom',
            type: 'button',
            id: 'ez3d-close-roof-button',
            classed: 'ez3d-square-button ez3d-square-button-green',
            content: 'close',
            eventOnClick: ['finishRoofEditing'],
            visibilityRule: ez3dScene.active.isCreated === true
        },
        title: {
            type: 'title',
            htmlElement: 'h2',
            classed: 'ez3d-group-title',
            title: this.name
        },
        type: {
            type: 'selectByImage',
            property: 'type',
            label: 'roofType',
            value: this.type,
            options: this.getAvailableTypes(),
            runMethod: [
                'EZModelRoof_runMethodListener',
                ['updateOnRoofTypeChange', true],
                true,
                {draw2d: true, draw3d: this, updatePanels: true, fitRange: false}
            ],
            confirmation: 'roofNeedsUpdate',
            confirmationWidget: {
                'name': 'type',
                'title': 'alertRoofChangeTitle',
                'content': 'alertRoofChangeContent',
                'eventOk': 'widget_roofChangeType_eventOk',
                'eventCancel': 'widget_roofChangeType_eventCancel'
            }
        },
        material: {
            type: 'selectByImage',
            property: 'material',
            label: 'roofMaterial',
            value: this.material,
            options: this.getAvailableMaterials()
        },
        inclination: {
            type: 'integer',
            property: 'inclination',
            label: 'roofInclination',
            value: this.inclination,
            max: ez3dScene.layoutRules.scenePreferences.maxAreaInclination,
            min: 0,
            step: 1,
            runMethod: [
                'EZModelRoof_runMethodListener',
                ['roofSoftUpdate', true],
                true,
                {draw2d: true, draw3d: this, updatePanels: true, fitRange: false}
            ],
            visibilityRule: 'visibilityRuleInclination',
            confirmation: 'roofNeedsUpdate',
            confirmationWidget: {
                'name': 'inclination',
                'title': 'alertRoofChangeTitle',
                'content': 'alertRoofChangeContent',
                'eventOk': 'widget_roofChangeInclination_eventOk',
                'eventCancel': 'widget_roofChangeInclination_eventCancel'
            }
        },
        orientation: {
            type: 'compass',
            id: 'compass-' + this.type,
            property: 'orientation',
            label: 'roofOrientation',
            value: this.orientation,
            optionsLabels: this.getDefaultValue('availableOrientation', this.type),
            runMethod: [
                'EZModelRoof_runMethodListener',
                ['roofHardUpdate', true],
                true,
                {draw2d: true, draw3d: this, updatePanels: true, fitRange: false}
            ],
            visibilityRule: 'visibilityRuleOrientation',
            confirmation: 'roofNeedsUpdate',
            confirmationWidget: {
                'name': 'orientation',
                'title': 'alertRoofChangeTitle',
                'content': 'alertRoofChangeContent',
                'eventOk': 'widget_roofChangeOrientation_eventOk',
                'eventCancel': 'widget_roofChangeOrientation_eventCancel'
            }
        },
        roofPointsSymmetry: {
            id: 'roofPointsSymmetry',
            type: 'boolean',
            property: 'roofPointsSymmetry',
            label: 'symmetricRidge',
            labelTrue: 'yes',
            labelFalse: 'no',
            value: this.roofPointsSymmetry,
            visibilityRule: this.type === 'hipped'
        }
    };
    return objectJSON;
};

EZModelRoof.prototype.roofInfoResume = function() {
    var objectJSON = {
        label: {
            text: 'editRoofName',
            vars: {
                value: this.name
            }
        },
        type: 'listResume',
        labels: {
            type: {
                value: this.type,
            },
            material: {
                value: this.material,
            }
        },
        elements: {
            roofType: {
                type: 'button',
                tooltip: {
                    text: 'editRoofName',
                    vars: {
                        value: this.name
                    }
                },
                classed: 'ez3d-complex-button ez3d-' + this.type + '-on-icon'
            },
            editRoof: {
                type: 'button',
                tooltip: {
                    text: 'editRoofName',
                    vars: {
                        value: this.name
                    }
                },
                classed: 'ez3d-complex-button fa-pencil'
            }
        },
        eventOnClick: [
            'EZModelBuilding_editRoofListener',
            this.id,
            true,
            {draw2d: true, draw3d: this, updatePanels: true, fitRange: false}
        ]
    };
    return objectJSON;
};

// Validations
EZModelRoof.prototype.visibilityRuleInclination = function() {
    var roofType = this.type;
    var value = (
        roofType !== 'flat'
    );
    return value;
};

EZModelRoof.prototype.updateOnRoofTypeChange = function(callback) {
    const defaultModelValues = ez3dScene.layoutRules.defaultModelValues;

    this.orientation = defaultModelValues.roofByType[this.type].orientation;
    this.material = defaultModelValues.roofByType[this.type].material;
    this.inclination = defaultModelValues.roofByType[this.type].inclination;

    this.roofHardUpdate(callback);
};

EZModelRoof.prototype.roofHardUpdate = function(callback) {
    this.calculateRoof();
    ee.emitEvent('finishUpdateOnChange');
    if (callback) callback();
};

EZModelRoof.prototype.roofSoftUpdate = function(callback) {
    this.calculateRoof(true);
    ee.emitEvent('finishUpdateOnChange');
    if (callback) callback();
};

EZModelRoof.prototype.updateFromArea = function(area, callback) {
    console.error('EZModelRoof', 'updateFromArea', 'WIP', area);
    // WIP adjust main area inclination from another area
    this.roofSoftUpdate(callback);
};

EZModelRoof.prototype.roofNeedsUpdate = function() {
    var value;
    if (ez3dScene.active.isCreated === false) {
        value = false;
    } else if (ez3dScene.active.needsUpdate === true) {
        value = false;
    } else {
        value = true;
    }
    return value;
};

// areas info panel
EZModelScene.prototype.areasInfoPanel = function() {
    var panelData = {
        name: 'areas-info',
        model: 'EZModelArea',
        object: 'area',
        json: this.activeBuilding.areasInfo()
    };

    return panelData;
};

// Building create panel
EZModelScene.prototype.buildingCreatePanel = function() {
    var panelData = {
        name: 'building-create',
        model: 'EZModelBuilding',
        object: 'building',
        customGuiMethods: {
            buildingCreateEnterAction: function() {
                ee.emitEvent('lockInterface');
                ee.emitEvent('openBuildingCreatePanel');
                ee.emitEvent('updateBuildingCreatePanel');
            },
            updateBuildingCreatePanel: function() {
                ee.emitEvent('updateBuildingCreatePanel');
            },
            closeBuildingCreatePanel: function() {
                ee.emitEvent('unlockInterface');
                ee.emitEvent('closeBuildingCreatePanel');
            },
        },
        customListeners: {
            enter: ['building_createBuildingListener', 'buildingCreateEnterAction'],
            update: ['updatePanels', 'updateBuildingCreatePanel'],
            addLocationFinished: ['addLocation_Finished', 'updateBuildingCreatePanel'],
            moveFinished: ['move_Finished', 'updateBuildingCreatePanel'],
            deleteLocationFinished: ['delete_Location_Finished', 'updateBuildingCreatePanel'],
            exit1: ['createBuilding_Cancelled', 'closeBuildingCreatePanel'],
            exit2: ['createBuilding_Finished', 'closeBuildingCreatePanel'],
        },
        populate: 'updatePanel',
        json: 'buildingCreateAndEdit'
    };

    return panelData;
};

// Building create panel
EZModelScene.prototype.buildingCreateShapePanel = function() {
    var panelData = {
        name: 'building-shape',
        model: 'EZModelBuilding',
        object: 'building',
        customGuiMethods: {
            createBuildingShapeEnterAction: function() {
                ee.emitEvent('lockInterface');
                ee.emitEvent('updateBuildingShapePanel');
                ee.emitEvent('openBuildingShapePanel');
            },
            createBuildingShapeUpdateAction: function() {
                if (ez3dScene.context === 'pathEditor' && ez3dScene.active.creatingBuildingShape) {
                    ee.emitEvent('updateBuildingShapePanel');
                }
            },
            createBuildingShapeExitAction: function() {
                ee.emitEvent('unlockInterface');
                ee.emitEvent('closeBuildingShapePanel');
            },
        },
        customListeners: {
            enter: ['building_createBuildingShapeListener', 'createBuildingShapeEnterAction'],
            update: ['updatePanels', 'createBuildingShapeUpdateAction'],
            cancelExit: ['createBuildingShape_Cancelled', 'createBuildingShapeExitAction'],
            finishExit: ['createBuildingShape_Finished', 'createBuildingShapeExitAction'],
        },
        populate: 'updatePanel',
        json: 'buildingCreateShape',
    };

    return panelData;
};

// Building edit panel
EZModelScene.prototype.buildingEditPanel = function() {
    var panelData = {
        name: 'building-edit',
        model: 'EZModelBuilding',
        object: 'building',
        customGuiMethods: {
            buildingEditEnterAction: function() {
                ee.emitEvent('lockInterface');
                ee.emitEvent('updateBuildingEditPanel');
                ee.emitEvent('openBuildingEditPanel');
            },
            updateBuildingEditPanel: function() {
                ee.emitEvent('updateBuildingEditPanel');
            },
            closeBuildingEditPanel: function() {
                ee.emitEvent('unlockInterface');
                ee.emitEvent('closeBuildingEditPanel');
            },
        },
        customListeners: {
            enter: ['building_editBuildingListener', 'buildingEditEnterAction'],
            update: ['updatePanels', 'updateBuildingEditPanel'],
            exit1: ['editBuilding_Cancelled', 'closeBuildingEditPanel'],
            exit2: ['editBuilding_Finished', 'closeBuildingEditPanel'],
        },
        populate: 'updatePanel',
        json: 'buildingCreateAndEdit'
    };

    return panelData;
};


// Building info panel
EZModelScene.prototype.buildingInfoPanel = function() {
    var panelData = {
        name: 'building-info',
        model: 'EZModelBuilding',
        object: 'building',
        json: this.activeBuilding.buildingInfo()
    };

    return panelData;
};

// keepout create panel
EZModelScene.prototype.keepoutCreatePanel = function() {
    var panelData = {
        name: 'keepout-create',
        model: 'EZModelKeepout',
        object: 'keepout',
        customGuiMethods: {
            onActiveUpdatedKeepoutCreatePanel: function() {
                if (ez3dScene.active.constructor.name === 'EZModelKeepout') {
                    if (ez3dScene.active.isEditing === false && ez3dScene.active.isCreated === false) {
                        ee.emitEvent('updateKeepoutCreatePanel');
                        ee.emitEvent('openKeepoutCreatePanel');
                    }
                }
            },
            closeKeepoutCreatePanel: function () {
                ez3dViewport.ez3dGuiManager.updateNavigation = false;
                ee.emitEvent('closeKeepoutCreatePanel');
                ee.emitEvent('updatePanels', [true]);
            },
            updateKeepoutCreatePanel: function() {
                ee.emitEvent('updateKeepoutCreatePanel');
            }
        },
        customListeners: {
            activeUpdated: ['activeUpdated', 'onActiveUpdatedKeepoutCreatePanel'],
            cancelCreation: ['createKeepout_Cancelled', 'closeKeepoutCreatePanel'],
            updatePanels: ['updatePanels', 'updateKeepoutCreatePanel'],
            addLocationFinished: ['addLocation_Finished', 'updateKeepoutCreatePanel'],
            moveFinished: ['move_Finished', 'updateKeepoutCreatePanel'],
            deleteLocationFinished: ['delete_Location_Finished', 'updateKeepoutCreatePanel']
        },
        populate: 'updatePanel',
        json: 'keepoutCreate'
    };

    return panelData;
};

// keepout edit panel
EZModelScene.prototype.keepoutEditPanel = function() {
    var panelData = {
        name: 'keepout-edit',
        model: 'EZModelKeepout',
        object: 'keepout',
        customGuiMethods: {
            onActiveUpdatedKeepoutEditPanel: function() {
                if (ez3dScene.active.constructor.name === 'EZModelKeepout' && ez3dScene.active.isEditing === true) {
                    ee.addOnceListener('tempPath_ready', function () {
                        ee.emitEvent('updateKeepoutEditPanel');
                        ee.emitEvent('openKeepoutEditPanel');
                    });
                }
            },
            closeKeepoutEditPanel: function () {
                ee.emitEvent('closeKeepoutEditPanel');
            },
            updateKeepoutEditPanel: function() {
                ee.emitEvent('updateKeepoutEditPanel');
            }
        },
        customListeners: {
            activeUpdated: ['activeUpdated', 'onActiveUpdatedKeepoutEditPanel'],
            cancelEdition: ['editKeepout_Cancelled', 'closeKeepoutEditPanel'],
            finishEdition: ['editKeepout_Finished', 'closeKeepoutEditPanel'],
            updatePanels: ['updatePanels', 'updateKeepoutEditPanel']
        },
        populate: 'updatePanel',
        json: 'keepoutEdit'
    };

    return panelData;
};

// keepouts info panel
EZModelScene.prototype.keepoutsInfoPanel = function() {
    var panelData = {
        name: 'keepout-info',
        model: 'EZModelKeepout',
        object: 'keepout',
        json: this.activeBuilding.keepoutsInfo()
    };

    return panelData;
};


// viewport buttons info
EZModelScene.prototype.mainButtonsInfo = function() {
    var objectJSON = this.layoutRules.defaultButtons;
    objectJSON.buttonsPanelRight.buttonsPanel.elements.provider.elements = this.constructProviders();
    return objectJSON;
};

// viewport buttons info panel
EZModelScene.prototype.mainButtonsInfoPanel = function() {
    const panelData = {
        name: 'viewport buttons',
        model: '',
        customGuiMethods: {
            onUpdateMainButtons: function() {
                this.generateButtons(ez3dScene.mainButtonsInfoPanel(), true);
                if (ez3dScene.layoutRules.scenePreferences.enableUndoRedo) {
                    ee.emitEvent('updateUndoRedoButtons');
                }
                if (ez3dScene.layoutRules.scenePreferences.readOnly) {
                    ee.emitEvent('disable_save_button');
                }
            }
        },
        /**
        * Method to check if the Player is on.
        * @return {boolean} isPlayerOn
        */
        checkPlayerOn: function() {
            return ez3dScene.layoutRules.scenePreferences.enablePlayer;
        },
        /**
        * Method to check if layoutRule to set undo/redo buttons visibilityRule.
        * @return {boolean} enableUndoRedo layoutRule
        */
        checkEnabledUndoRedo: function() {
            return ez3dScene.layoutRules.scenePreferences.enableUndoRedo;
        },
        customListeners: {
            updateMainButtons: ['updatePanels', 'onUpdateMainButtons']
        },
        json: this.mainButtonsInfo()
    };

    return panelData;
};

EZModelScene.prototype.mapInfo = function() {
    const objectJSON = {
        title: {
            type: 'title',
            classed: 'ez3d-group-title',
            title: 'Map definition',
        },
        enableSnapshotMap: {
            type: 'boolean',
            property: 'layoutRules.scenePreferences.enableSnapshotMap',
            label: 'enableSnapshotMap',
            value: this.layoutRules.scenePreferences.enableSnapshotMap
        },
        launchPromise: {
            id: 'ez3d-configure-mapsnapshot',
            type: 'button',
            eventOnClick: [
                'EZModelScene_snapshotMapListener',
                undefined,
                false,
                {draw2d: true, draw3d: true, updatePanels: false, fitRange: false},
                false
            ],
            content: (this.mapSnapshotData) ? 'useSnapshot' : 'configureSnapshot',
            classed: 'ez3d-square-button ez3d-square-button-green',
            visibilityRule: this.layoutRules.scenePreferences.enableSnapshotMap,
        },
        zoomLevelHelper: {
            id: 'ez3d-zoomLevelHelper',
            type: 'helper',
            classed: 'helperList ez3d-mapsnapshot-hidden',
            content: 'selectProviderAndZoom',
            visibilityRule: this.layoutRules.scenePreferences.enableSnapshotMap && !this.mapSnapshotData
        },
        defineZoomLevel: {
            id: 'ez3d-defineZoomLevel',
            type: 'button',
            classed: 'ez3d-square-button ez3d-square-button-green ez3d-mapsnapshot-hidden',
            content: 'set zoom level',
            eventOnClick: ['setMapSnapshotZoomLevel'],
            visibilityRule: this.layoutRules.scenePreferences.enableSnapshotMap && !this.mapSnapshotData
        },
        mapLimitsHelper: {
            id: 'ez3d-mapLimitsHelper',
            type: 'helper',
            classed: 'helperList ez3d-mapsnapshot-hidden',
            content: 'zoomOutSnapshotMap',
            visibilityRule: this.layoutRules.scenePreferences.enableSnapshotMap && !this.mapSnapshotData
        },
        defineMapLimits: {
            id: 'ez3d-defineMapLimits',
            type: 'button',
            classed: 'ez3d-square-button ez3d-square-button-green ez3d-mapsnapshot-hidden',
            content: 'defineLimits',
            eventOnClick: ['setMapSnapshotLimits'],
            visibilityRule: this.layoutRules.scenePreferences.enableSnapshotMap && !this.mapSnapshotData
        },
        generateMapHelper: {
            id: 'ez3d-generateMapHelper',
            type: 'helper',
            classed: 'helperList ez3d-mapsnapshot-hidden',
            content: 'generateSnapshotMap',
            visibilityRule: this.layoutRules.scenePreferences.enableSnapshotMap && !this.mapSnapshotData
        },
        generateMap: {
            id: 'ez3d-generateMap',
            type: 'button',
            classed: 'ez3d-square-button ez3d-square-button-green ez3d-mapsnapshot-hidden',
            content: 'generateMap ',
            eventOnClick: ['getMapTiles'],
            visibilityRule: this.layoutRules.scenePreferences.enableSnapshotMap && !this.mapSnapshotData
        },
        removeMap: {
            type: 'button',
            classed: 'ez3d-square-button ez3d-square-button-green',
            content: 'removeMap',
            eventOnClick: [
                'EZModelScene_removeMapTextureListener',
                undefined,
                false,
                {draw2d: false, draw3d: true, updatePanels: true, fitRange: false},
                false
            ],
            visibilityRule: Boolean(this.layoutRules.scenePreferences.enableSnapshotMap && this.mapSnapshotData)
        }
    };
    return objectJSON;
};


EZModelScene.prototype.mapInfoPanel = function() {
    var panelData = {
        name: 'map-info',
        model: 'EZModelScene',
        json: this.mapInfo(),
        populate: 'updatePanel'
    };

    return panelData;
};

// preferences info
EZModelScene.prototype.navInfo = function() {
    const objectJSON = {
        type: 'navigator',
        elements: {
            building: {
                name: 'building-info',
                text: 'building',
                className: '',
                eventOnClick: ['tabChanged', 'ez3d-building-info-tab']
            },
            areas: {
                name: 'areas-info',
                text: 'areas',
                className: '',
                eventOnClick: ['tabChanged', 'ez3d-areas-info-tab']
            },
            objects: {
                name: 'objects',
                text: 'objects',
                className: '',
                eventOnClick: ['tabChanged', 'ez3d-objects-tab']
            },
            map: {
                name: 'map-info',
                text: 'map',
                className: '',
                eventOnClick: ['tabChanged', 'ez3d-map-info-tab'],
            },
            preferences: {
                name: 'preferences',
                text: '',
                className: 'fa fa-cog',
                eventOnClick: ['tabChanged', 'ez3d-preferences-tab']
            }
        }
    };
    return objectJSON;
};

// Nav panel
EZModelScene.prototype.asideNavPanel = function() {
    var panelData = {
        name: 'nav',
        model: 'EZModelNav',
        customGuiMethods: {
            onTabChanged: function(attr) {
                var self = this;
                if (ez3dScene.buildings.length >= 0) {
                    if (ez3dScene.buildings.length === 0) {
                        self.lockedActiveTab = self.tabs[0];
                        self.lockedActiveTabName = self.lockedActiveTab.split('-')[1];
                        ee.emitEvent('lockInterface');
                    }

                    // Set activeTabObject if it's not initialized
                    if (!self.activeTabObject) {
                        // Default panel on secondary nav
                        var tabs = ez3dScene.objectsInfo().elements;
                        for (var tab in tabs) {
                            if (tabs[tab].hasOwnProperty('defaultPanel')) {
                                self.activeTabObject = tabs[tab].name;
                            }
                        }
                    }

                    if (self.ez3dViewport.lockedInterface === true) {
                        // Set lockedActiveTabName (avoid rewriting the variable when changing the tab before the change tab timeout finishes)
                        if (!self.lockedActiveTabName) {
                            self.lockedActiveTab = d3.select('.ez3d-active-nav').node().id;
                            self.lockedActiveTabName = self.lockedActiveTab.split('-')[1];
                        }
                    } else {
                        // Reset variable when interface is unlocked
                        self.lockedActiveTabName = null;
                        switch (self.activeTab) {
                            case 'ez3d-building-info-tab':
                                // nada
                                break;
                            case 'ez3d-areas-info-tab':
                                if (attr.args !== 'ez3d-preferences-tab' && attr.args !== 'ez3d-areas-info-tab') {
                                    if (self.ez3dViewport.svgElements.svgArea) {
                                        self.closeSubareaView();
                                    }
                                }
                                // From area to Building/Objects tab
                                if ((attr.args === 'ez3d-building-info-tab' || attr.args === 'ez3d-objects-tab') &&
                                    ez3dScene.active.constructor.name !== 'EZModelBuilding') {
                                    self.updateNavigation = false;
                                    ee.emitEvent('EZModelScene_setActiveListener', [{
                                        args: ez3dScene.activeBuilding.id,
                                        updateRender: false
                                    }]);
                                }
                                break;
                            case 'ez3d-objects-tab':
                                // From keepout to Areas tab
                                if (attr.args === 'ez3d-areas-info-tab' && !ez3dScene.activeTree &&
                                    ez3dScene.active.constructor.name !== 'EZModelBuilding') {
                                    self.updateNavigation = false;
                                    ee.emitEvent('EZModelScene_setActiveListener', [{
                                        args: ez3dScene.activeBuilding.id,
                                        updateRender: false
                                    }]);
                                }
                                // From tree to Building/Areas tab
                                if (ez3dScene.activeTree && attr.args !== 'ez3d-tree-info-tab' && attr.args !== 'ez3d-preferences-tab') {
                                    self.updateNavigation = false;
                                    if (attr.args === 'ez3d-building-info-tab') {
                                        self.updateNavigation = true;
                                    }
                                    ee.emitEvent('EZModelScene_setActiveListener', [{
                                        args: ez3dScene.buildings[ez3dScene.buildings.length - 1].id,
                                        updateRender: false
                                    }]);
                                }
                                break;
                            case 'ez3d-preferences-tab':
                            case 'ez3d-map-info-tab':
                                // Active building or let area/subarea
                                if (attr.args !== 'ez3d-objects-tab') {
                                    if (attr.args === 'ez3d-areas-info-tab' && ez3dScene.context !== 'tree') {
                                        // nada
                                    } else if (ez3dScene.active.constructor.name !== 'EZModelBuilding') {
                                        // From tree to any (with active building) || From area to Preferences panel and Building tab
                                        ee.emitEvent('EZModelScene_setActiveListener', [{
                                            args: ez3dScene.buildings[ez3dScene.buildings.length - 1].id,
                                            updateRender: false
                                        }]);
                                    }
                                }
                                // From area to Preferences panel and Objects tab
                                if (attr.args === 'ez3d-objects-tab' && ez3dScene.context !== 'building' && ez3dScene.context !== 'tree' && ez3dScene.context !== 'keepout') {
                                    ee.emitEvent('EZModelScene_setActiveListener', [{
                                        args: ez3dScene.activeBuilding.id,
                                        updateRender: false
                                    }]);
                                }
                                break;
                            default:
                                break;
                        }
                    }

                    // Set activeTab
                    if (attr.args === 'ez3d-keepout-info-tab' || attr.args === 'ez3d-tree-info-tab') {
                        self.activeTab = 'ez3d-objects-tab';
                        // Parameter sent when clicking on the secondary nav
                        self.activeTabObject = attr.args.split('-')[1] + '-' + attr.args.split('-')[2];
                    } else {
                        self.activeTab = attr.args;
                    }

                    self.activeTabName = self.activeTab.split('-')[1];

                    ez3dScene.activeMainTab(self.activeTabName);
                    ez3dScene.activeObjectsTab(self.activeTabObject);
                    ez3dScene.lockedInterfaceTab(self);
                    ez3dScene.setTabColors(self);
                }
            },
            onActiveUpdated: function() {
                var self = this;

                // Move to appropiate tab
                if (ez3dScene.context === 'building' || ez3dScene.activeBuilding.isCreated === false) {
                    if (self.updateNavigation !== false) {
                        self.activeTab = 'ez3d-building-tab';
                        ee.emitEvent('tabChanged', [{
                            args: 'ez3d-building-info-tab'
                        }]);
                    }
                } else if (ez3dScene.context === 'area') {
                    self.activeTab = 'ez3d-areas-tab';
                    ee.emitEvent('tabChanged', [{
                        args: 'ez3d-areas-info-tab'
                    }]);
                } else if (ez3dScene.context === 'subarea') {
                    self.activeTab = 'ez3d-areas-tab';
                    ee.emitEvent('tabChanged', [{
                        args: 'ez3d-areas-info-tab'
                    }]);
                } else if (ez3dScene.context === 'tree') {
                    self.activeTab = 'ez3d-objects-tab';
                    ee.emitEvent('tabChanged', [{
                        args: 'ez3d-tree-info-tab'
                    }]);
                } else if (ez3dScene.context === 'keepout') {
                    self.activeTab = 'ez3d-objects-tab';
                    ee.emitEvent('tabChanged', [{
                        args: 'ez3d-keepout-info-tab'
                    }]);
                }

                this.updatePanel(ez3dScene.asideNavPanel());
                this.updatePanel(ez3dScene.objectsPanel());
            },
            openedPreferencesPanel: function() {
                let touchedPanel = false;
                if (this.activeTabName === 'preferences' && d3.select('#ez3d-gui-container > .ez3d-active-panel').node()) {
                    // Open this panel without closing the others
                    d3.select('#ez3d-aside-preferences').classed('ez3d-active-tab', true);
                    touchedPanel = true;
                } else if (d3.select('#ez3d-aside-preferences').classed('ez3d-active-tab')) {
                    // If Preferences panel is still open, only close this panel
                    d3.select('#ez3d-aside-preferences').classed('ez3d-active-tab', false);
                }
                return touchedPanel;
            },
            openedMapPanel: function() {
                let touchedPanel = false;
                if (this.activeTabName === 'map' && d3.select('#ez3d-gui-container > .ez3d-active-panel').node()) {
                    // Open this panel without closing the others
                    d3.select('#ez3d-aside-map-info').classed('ez3d-active-tab', true);
                    touchedPanel = true;
                } else if (d3.select('#ez3d-aside-map-info').classed('ez3d-active-tab')) {
                    // If Preferences panel is still open, only close this panel
                    d3.select('#ez3d-aside-map-info').classed('ez3d-active-tab', false);
                }
                return touchedPanel;
            },
            updateNavPanels: function() {
                var self = this;
                if (ez3dScene.activeBuilding) {
                    var building = ez3dScene.activeBuilding;
                    if (building.isCreated) {
                        self.updatePanel(ez3dScene.buildingInfoPanel());
                        self.updatePanel(ez3dScene.areasInfoPanel());

                        // Objects selector
                        self.updatePanel(ez3dScene.objectsPanel());

                        // update keepoutsInfoPanel
                        self.updatePanel(ez3dScene.keepoutsInfoPanel());
                    }
                }
                if (ez3dScene.projectType !== 'simple') {
                    self.generateBuildingIndex(ez3dScene);
                    if (ez3dScene.activeSubarea) {
                        self.updatePanel(ez3dScene.subareaInfoPanel());
                    }
                }
                self.updatePanel(ez3dScene.treesInfoPanel());
                self.updatePanel(ez3dScene.preferencesInfoPanel());
                self.updatePanel(ez3dScene.mapInfoPanel());

                if (d3.select('.ez3d-main-template.ez3d-active-tab').node() === null) {
                    d3.select('#ez3d-aside-building-info').classed('ez3d-active-tab', true);
                }

                if (self.activeTabObject === 'keepout-info') {
                    d3.select('#ez3d-aside-keepout-info').classed('ez3d-active-panel', true);
                }
            },
            isInteractiveMode: function(id) {
                if (ez3dScene.context === 'area') {
                    return (ez3dScene.mode === 'interactiveMove' && ez3dScene.active.subareaToMove === id);
                } else if (ez3dScene.context === 'subarea') {
                    return (ez3dScene.mode === 'interactiveMove' && ez3dScene.active.system.id === id);
                }
                return (ez3dScene.mode === 'interactiveMove' && ez3dScene.active.id === id);
            }
        },
        customMethods: {
            /**
             * Allowed viewport modes to be selected in the preferences panel
             * @return {Object} dictionary with viewportModes and their labels
             */
            viewportModeOptions: function() {
                var options = {
                    // 0: 'invisible interface',
                    // 1: 'showcase mode',
                    2: 'open street map',
                    // 3: 'hide interface - DEPRECATED',
                    4: 'google streetview',
                    5: 'default values editor',
                    // 6: 'simple mode',
                    7: 'documentation',
                    8: 'autocad export',
                    //10: 'single-line Diagram'
                };

                return options;
            }
        },
        customListeners: {
            tabChangedListener: ['tabChanged', 'onTabChanged'],
            activeUpdated: ['activeUpdated', 'onActiveUpdated'],
            updatePanels: ['updatePanels', 'updateNavPanels']
        },
        json: this.navInfo()
    };

    return panelData;
};

EZModelScene.prototype.activeMainTab = function (activeTabName) {
    // Activate main tab
    d3.select('#ez3d-nav').selectAll('.ez3d-main-tab')
        .classed('ez3d-active-nav', function() {
            var active = false;
            var tab = this.id.split('-')[1];
            if (tab === activeTabName) {
                active = true;
            }
            return active;
        });
};

EZModelScene.prototype.setTabColors = function() {
    // Set main tab colors
    d3.selectAll('.ez3d-main-tab').nodes().forEach((element) => {
        var tabNav = d3.select(element);
        var tabName = element.id.split('-')[1];
        if (tabNav.classed('ez3d-active-nav')) {
            // If the interface is locked, show a grey color on the non-active-locked tab clicked
            if (ez3dViewport.lockedInterface && (tabName !== 'preferences' || tabName !== 'map' || tabName !== ez3dViewport.ez3dGuiManager.lockedActiveTabName)) {
                tabNav.style('color', '#808080');
                tabNav.style('border-color', '#808080');
            } else {
                tabNav.style('color', ez3dScene.layoutRules.defaultColors.cssColors.second);
                tabNav.style('border-color', ez3dScene.layoutRules.defaultColors.cssColors.first);
            }
        } else {
            tabNav.style('color', ez3dScene.layoutRules.defaultColors.cssColors.mediumgray);
            tabNav.style('border-color', 'transparent');
        }
    });
    // Set main tab colors on locked tab (not applicable on preferences or map tab)

    if (d3.select('#ez3d-aside-preferences').classed('ez3d-active-tab') === false ||
        d3.select('#ez3d-aside-map-info').classed('ez3d-active-tab') === false) {
        setTimeout(function () {
            if (ez3dViewport.lockedInterface === true) {
                d3.selectAll('.ez3d-main-tab').nodes().forEach((element) => {
                    var tabNav = d3.select(element);
                    // If the interface is locked, show a grey color on the non-active-locked tab clicked
                    if (tabNav.classed('ez3d-active-nav')) {
                        tabNav.style('color', ez3dScene.layoutRules.defaultColors.cssColors.second);
                        tabNav.style('border-color', ez3dScene.layoutRules.defaultColors.cssColors.first);
                    } else {
                        tabNav.style('color', ez3dScene.layoutRules.defaultColors.cssColors.mediumgray);
                        tabNav.style('border-color', 'transparent');
                    }
                });
            }
        }, 200);
        ez3dViewport.ez3dGuiManager.activeTabName = ez3dViewport.ez3dGuiManager.lockedActiveTabName;
    }

    // Set Objects tab styles
    d3.select('.objects-selector').selectAll('.ez3d-main-tab').nodes().forEach((element) => {
        var tabNav = d3.select(element);
        if (tabNav.classed('ez3d-active-nav')) {
            // If the interface is locked, show a grey color on the non-active-locked tab clicked
            tabNav.select('.ez3d-img-obj').style('background-color', ez3dScene.layoutRules.defaultColors.cssColors.first);
            tabNav.select('.ez3d-title').style('color', ez3dScene.layoutRules.defaultColors.cssColors.second);
        } else {
            tabNav.select('.ez3d-img-obj').style('background-color', ez3dScene.layoutRules.defaultColors.cssColors.mediumgray);
            tabNav.select('.ez3d-title').style('color', ez3dScene.layoutRules.defaultColors.cssColors.mediumgray);
        }
    });
};

EZModelScene.prototype.activeObjectsTab = function (activeTabObject) {
    // Activate Objects tab
    d3.select('.objects-selector').selectAll('.ez3d-main-tab')
        .classed('ez3d-active-nav', function () {
            var active = false;
            var tab = this.id.split('-')[2] + '-' + this.id.split('-')[3];
            if (tab === activeTabObject) {
                active = true;
            }
            return active;
        });
};

EZModelScene.prototype.lockedInterfaceTab = function (guiManager) {
    // If the interface is locked and Preferences panel hasn't been opened, always go to the current tab (setTimeout for the animation)
    if (guiManager.ez3dViewport.lockedInterface === true) {
        if (!guiManager.openedPreferencesPanel() && !guiManager.openedMapPanel()) {
            setTimeout(function () {
                d3.selectAll('.ez3d-main-tab')
                    .classed('ez3d-active-nav', function () {
                        var active = false;
                        var tab = this.id.split('-')[1];
                        if (tab === guiManager.lockedActiveTabName) {
                            active = true;
                        }
                        return active;
                    });
            }, 200);
            guiManager.activeTab = guiManager.lockedActiveTab;
            guiManager.activeTabName = guiManager.lockedActiveTabName;
        }
    } else {
        // Reset panels except when clicking Area/Preferences tabs with subarea panel opened
        // And when going from subarea to area
        if ((guiManager.activePanel === 'ez3d-aside-subarea-info' &&
            guiManager.activeTab !== 'ez3d-areas-info-tab' && guiManager.activeTab !== 'ez3d-preferences-tab') ||
            (guiManager.activePanel === 'ez3d-aside-subarea-info' && guiManager.activeTab === 'ez3d-areas-info-tab' && ez3dScene.active.constructor.name === 'EZModelArea')) {
            d3.selectAll('.ez3d-main-template')
                .classed('ez3d-active-panel', false);
        }
        // Open main panel
        d3.selectAll('.ez3d-main-template')
            .classed('ez3d-active-tab', function () {
                let active = false;
                const panel = this.id.split('ez3d-aside-')[1];
                const activePanel = guiManager.activeTab.split('ez3d-')[1].split('-tab')[0];
                if (panel === activePanel) {
                    active = true;
                    if (guiManager.activeTabName === 'objects') {
                        // Open secondary panel in Objects panel
                        d3.selectAll('.ez3d-main-template')
                            .classed('ez3d-active-panel', function () {
                                var actived = false;
                                var objectPanel = this.id.split('-').slice(-2).join('-');
                                if (objectPanel === guiManager.activeTabObject) {
                                    actived = true;
                                }
                                return actived;
                            });
                    }
                }
                return active;
            });

        if (guiManager.activeTabName === 'building') {
            if (ez3dScene.active.id !== ez3dScene.activeBuilding.id) {
                ee.emitEvent('EZModelScene_setActiveListener', [{
                    args: ez3dScene.activeBuilding.id,
                    updateRender: false
                }]);
            }
        }
    }
};

// Objects panel
EZModelScene.prototype.objectsPanel = function() {
    var panelData = {
        name: 'objects-selector',
        model: 'EZModelNav',
        json: this.objectsInfo()
    };

    return panelData;
};

// Image objects navigator info
EZModelScene.prototype.objectsInfo = function() {
    var objectJSON = {
        type: 'imageNavigator',
        elements: {
            keepouts: {
                defaultPanel: true,
                name: 'keepout-info',
                text: 'keepouts',
                image: {
                    id: 'ez3d-img-keepouts',
                    className: 'ez3d-img-keepouts'
                },
                button: {
                    type: 'button',
                    id: 'ez3d-add-keepout',
                    tooltip: 'addKeepout',
                    classed: 'ez3d-button fa fa-plus',
                    functionOnClick: ['addKeepout', '']
                },
                eventOnClick: ['tabChanged', 'ez3d-keepout-info-tab']
            },
            tree: {
                name: 'tree-info',
                text: 'tree',
                image: {
                    id: 'ez3d-img-trees',
                    className: 'ez3d-img-trees'
                },
                button: {
                    type: 'button',
                    id: 'ez3d-add-tree',
                    tooltip: 'addTree',
                    classed: 'ez3d-button fa fa-plus',
                    functionOnClick: ['addTree', '']
                },
                eventOnClick: ['tabChanged', 'ez3d-tree-info-tab']
            }
        }
    };
    return objectJSON;
};

// preferences info
EZModelScene.prototype.preferencesInfo = function() {
    var objectJSON = {
        // showOldSystemProject: {
        //     type: 'boolean',
        //     property: 'layoutRules.scenePreferences.showOldSystemProject',
        //     id: 'showOldSystemProject',
        //     label: 'show old d3 system in project container',
        //     value: this.layoutRules.scenePreferences.showOldSystemProject
        // },
        // showNewSystemProject: {
        //     type: 'boolean',
        //     property: 'layoutRules.scenePreferences.showNewSystemProject',
        //     id: 'showNewSystemProject',
        //     label: 'show new d3 system in project container',
        //     value: this.layoutRules.scenePreferences.showNewSystemProject
        // },
        title: {
            type: 'title',
            htmlElement: 'h2',
            classed: 'ez3d-group-title',
            title: 'preferences'
        },
        defaultLanguage: {
            type: 'select',
            id: 'language',
            property: 'layoutRules.scenePreferences.defaultLanguage',
            undo: false,
            label: 'language',
            value: this.layoutRules.scenePreferences.defaultLanguage,
            optionsLabels: ['en', 'es', 'nl', 'fr', 'no', 'se', 'fi', 'tr'],
            functionOnChange: ['changeLanguage', '']
        },
        viewportMode: {
            type: 'select',
            property: 'layoutRules.scenePreferences.viewportMode',
            undo: false,
            optionsFunction: 'viewportModeOptions',
            label: 'viewportMode',
            value: this.layoutRules.scenePreferences.viewportMode,
            eventOnChange: ['updateViewportMode']
        },
        enableUndoRedo: {
            type: 'boolean',
            property: 'layoutRules.scenePreferences.enableUndoRedo',
            undo: false,
            label: 'enableUndoRedo',
            value: this.layoutRules.scenePreferences.enableUndoRedo,
            functionOnChange: ['enableDisableUndoRedo', '']
        },
        undoRedoLimit: {
            type: 'integer',
            property: 'layoutRules.scenePreferences.limitUndoRedo',
            visibilityRule: this.layoutRules.scenePreferences.enableUndoRedo,
            undo: false,
            step: 1,
            min: 1,
            max: 'infinity',
            label: 'undoRedoQueueLimit',
            value: this.layoutRules.scenePreferences.limitUndoRedo
        },
        setDateTime: {
            type: 'boolean',
            property: 'layoutRules.scenePreferences.customDateTime',
            undo: false,
            id: 'setDateTimePreferences',
            label: 'customDateTime',
            value: this.layoutRules.scenePreferences.customDateTime
        },
        sunDate: {
            type: 'dateTimeWidget',
            property: 'layoutRules.scenePreferences.defaultDateTime',
            undo: false,
            value: this.layoutRules.scenePreferences.defaultDateTime,
            id: 'sunDatePreferences',
            classed: 'ez3d-date-time-preferences',
            label: 'date',
            label2: 'time',
            functionOnChange: ['setDateSunPreferences', ''],
            editable: this.layoutRules.scenePreferences.customDateTime
        },
        // defaultUnits: {
        //     'm',
        // },
        // enablePlayer: {
        //     type: 'boolean',
        //     property: 'layoutRules.scenePreferences.enablePlayer',
        //     label: 'enablePlayer',
        //     value: this.layoutRules.scenePreferences.enablePlayer
        // },
        buildingTextures: {
            type: 'boolean',
            property: 'layoutRules.scenePreferences.buildingTextures',
            undo: false,
            label: 'buildingTextures',
            visibilityRule: this.layoutRules.scenePreferences.enablePlayer,
            value: this.layoutRules.scenePreferences.buildingTextures
        },
        buildingPopulate: {
            type: 'boolean',
            property: 'layoutRules.defaultModelValues.building.populated',
            undo: false,
            label: 'populatedWithModules',
            value: this.layoutRules.defaultModelValues.building.populated
        },
        activeMapper: {
            type: 'boolean',
            property: 'layoutRules.scenePreferences.activeMapper',
            undo: false,
            label: 'activeMapper',
            value: this.layoutRules.scenePreferences.activeMapper,
            visibilityRule: this.layoutRules.scenePreferences.enablePlayer,
            functionOnChange: ['activeMapper', '']
        },
        activeRenderer: {
            type: 'boolean',
            property: 'layoutRules.scenePreferences.activeRenderer',
            undo: false,
            label: 'activeRenderer',
            value: this.layoutRules.scenePreferences.activeRenderer,
            visibilityRule: this.layoutRules.scenePreferences.enablePlayer,
            functionOnChange: ['activeRenderer', '']
        },
        angleBias: {
            type: 'float',
            property: 'layoutRules.scenePreferences.angleBias',
            undo: false,
            step: 0.1,
            min: 0.1,
            max: 5,
            label: 'angleBias',
            value: this.layoutRules.scenePreferences.angleBias
        },
        collisionBias: {
            type: 'float',
            property: 'layoutRules.scenePreferences.collisionBias',
            undo: false,
            step: 0.01,
            min: 0,
            max: 0.1,
            label: 'collisionBias',
            universalUnits: true,
            value: this.layoutRules.scenePreferences.collisionBias
        },
        movementStep: {
            type: 'float',
            property: 'layoutRules.scenePreferences.movementStep',
            undo: false,
            step: 0.1,
            min: 0.1,
            max: 50,
            label: 'movementStep',
            universalUnits: true,
            value: this.layoutRules.scenePreferences.movementStep
            // showUnit: 'm'
        },
        snapToGuides: {
            type: 'boolean',
            property: 'layoutRules.scenePreferences.snapToGuides',
            undo: false,
            label: 'snapToGuides',
            value: this.layoutRules.scenePreferences.snapToGuides
        },
        snapToGrid: {
            type: 'boolean',
            property: 'layoutRules.scenePreferences.snapToGrid',
            undo: false,
            label: 'snapToGrid',
            value: this.layoutRules.scenePreferences.snapToGrid
        },
        gridSize: {
            type: 'float',
            property: 'layoutRules.scenePreferences.gridSize',
            undo: false,
            step: 0.1,
            min: 0.1,
            max: 50,
            label: 'gridSize',
            value: this.layoutRules.scenePreferences.gridSize,
            editable: this.layoutRules.scenePreferences.snapToGrid,
            universalUnits: true
            // showUnit: 'm'
        },
        gridOffsetX: {
            type: 'float',
            property: 'layoutRules.scenePreferences.gridOffsetX',
            undo: false,
            step: 0.1,
            min: 0,
            max: 50,
            label: 'gridOffsetX',
            value: ez3dScene.layoutRules.scenePreferences.gridOffsetX,
            visibilityRule: ez3dScene.layoutRules.scenePreferences.snapToGrid,
            showUnit: 'm'
        },
        gridOffsetY: {
            type: 'float',
            property: 'layoutRules.scenePreferences.gridOffsetY',
            undo: false,
            step: 0.1,
            min: 0,
            max: 50,
            label: 'gridOffsetY',
            value: ez3dScene.layoutRules.scenePreferences.gridOffsetY,
            visibilityRule: ez3dScene.layoutRules.scenePreferences.snapToGrid,
            showUnit: 'm'
        }
    };
    return objectJSON;
};

// preferences info panel
EZModelScene.prototype.preferencesInfoPanel = function() {
    var panelData = {
        name: 'preferences',
        model: 'EZModelScene',
        json: this.preferencesInfo(),
        populate: 'updatePanel'
    };

    return panelData;
};

EZModelScene.prototype.roofCreatePanel = function() {
    var panelData = {
        name: 'roof-create',
        model: 'EZModelRoof',
        object: 'roof',
        customGuiMethods: {
            roofCreateEnterAction: function() {
                ee.emitEvent('lockInterface');
                ee.emitEvent('updateRoofCreatePanel');
                ee.emitEvent('openRoofCreatePanel');
            },
            roofCreateUpdateAction: function() {
                if (ez3dScene.context === 'roof') {
                    ee.emitEvent('updateRoofCreatePanel');
                }
            },
            roofCreateExitAction: function() {
                ee.emitEvent('unlockInterface');
                ee.emitEvent('closeRoofCreatePanel');
            },
        },
        customListeners: {
            enter: ['building_editRoofListener', 'roofCreateEnterAction'],
            update: ['updatePanels', 'roofCreateUpdateAction'],
            exit1: ['editRoof_Cancelled', 'roofCreateExitAction'],
            exit2: ['editRoof_Finished', 'roofCreateExitAction'],
        },
        populate: 'updatePanel',
        json: 'roofEditAndCreate'
    };

    return panelData;
};

EZModelScene.prototype.roofEditPanel = function() {
    var panelData = {
        name: 'roof-edit',
        model: 'EZModelRoof',
        object: 'roof',
        customGuiMethods: {
            roofEditEnterAction: function() {
                ee.emitEvent('lockInterface');
                ee.emitEvent('updateRoofEditPanel');
                ee.emitEvent('openRoofEditPanel');
            },
            roofEditUpdateAction: function() {
                if (ez3dScene.context === 'roof') {
                    ee.emitEvent('updateRoofEditPanel');
                }
            },
            roofEditExitAction: function() {
                ee.emitEvent('unlockInterface');
                ee.emitEvent('closeRoofEditPanel');
            }
        },
        customListeners: {
            enter: ['building_editRoofListener', 'roofEditEnterAction'],
            update: ['updatePanels', 'roofEditUpdateAction'],
            exit1: ['editRoof_Cancelled', 'roofEditExitAction'],
            exit2: ['editRoof_Finished', 'roofEditExitAction'],
        },
        populate: 'updatePanel',
        json: 'roofEditAndCreate'
    };

    return panelData;
};

// Roof info panel
EZModelScene.prototype.roofPanel = function() {
    var panelData = {
        name: 'roof',
        model: 'EZModelRoof',
        object: 'roof',
        customGuiMethods: {
            onActiveUpdatedRoofPanel: function() {
                if (ez3dScene.active.constructor.name === 'EZModelRoof') {
                    ee.emitEvent('updateRoofPanel', [ez3dScene.roofPanel()]);
                    ee.emitEvent('openRoofPanel');
                    ee.emitEvent('lockInterface');
                    ee.emitEvent('disable_perspective_button');
                }
            },
            onFinishUpdateOnChange: function() {
                if (ez3dScene.active.constructor.name === 'EZModelRoof') {
                    ee.emitEvent('updateRoofPanel', [ez3dScene.roofPanel()]);
                }
            },
            updateRoofPanel: function() {
                ee.emitEvent('updateRoofPanel');
            }
        },
        customListeners: {
            activeUpdated: ['activeUpdated', 'onActiveUpdatedRoofPanel'],
            finishUpdateOnChange: ['runMethod_Finished', 'onFinishUpdateOnChange'],
            updatePanels: ['updatePanels', 'updateRoofPanel']
        },
        populate: 'updatePanel',
        json: 'roofInfo'
    };

    return panelData;
};

// simple building info panel
EZModelScene.prototype.simpleBuildingPanel = function() {
    var panelData = {
        name: 'simple-building',
        model: 'EZModelBuilding',
        object: 'building',
        customMethods: {
            runRoofTypeChange: function(callback) {
                ee.addOnceListener('setActive_Finished', function() {
                    const active = ez3dScene.active;
                    const oldType = active.type;

                    if (ez3dScene.activeBuilding.type) {
                        active.type = ez3dScene.activeBuilding.type;
                    }
                    if (ez3dScene.activeBuilding.inclination) {
                        active.inclination = ez3dScene.activeBuilding.inclination;
                    }
                    if (ez3dScene.activeBuilding.material) {
                        active.material = ez3dScene.activeBuilding.material;
                    }


                    if (oldType !== active.type) {
                        active.orientation = ez3dScene.layoutRules.defaultModelValues.roofByType[active.type].orientation;
                        active.material = ez3dScene.layoutRules.defaultModelValues.roofByType[active.type].material;
                        active.inclination = ez3dScene.layoutRules.defaultModelValues.roofByType[active.type].inclination;
                    }

                    active.calculateRoof();
                    active.isEditing = false;
                    active.isCreated = true;

                    ez3dScene.activeBuilding.updateSystem();

                    ee.emitEvent('finishAreaCreation');

                    ee.addOnceListener('activeUpdated', function () {
                        ee.emitEvent('openSimpleBuildingPanel');
                        if (callback) {
                            callback();
                        }
                    });
                    ee.emitEvent('EZModelScene_setActiveListener', [{args: ez3dScene.activeBuilding.id, undo: false, updateRender: false}]);
                });
                ee.emitEvent('EZModelScene_setActiveListener', [{args: ez3dScene.activeBuilding.roofs[0].id, undo: false, updateRender: false}]);
            },
            runRowNumberChange: function(callback) {
                let system = ez3dScene.activeBuilding.roofs[0].areas[0].subareas[0].system;
                system.changeRowNumber(this.rows);
                ee.addOnceListener('simple_updateGrid', () => {
                    // system was recreated
                    system = ez3dScene.activeBuilding.roofs[0].areas[0].subareas[0].system;
                    system.updateGrid(undefined, true, true, true);
                });
                if (callback) {
                    callback();
                }
            },
            runColumnNumberChange: function(callback) {
                let system = ez3dScene.activeBuilding.roofs[0].areas[0].subareas[0].system;
                system.changeColumnNumber(this.columns);
                ee.addOnceListener('simple_updateGrid', function() {
                    // system was recreated
                    system = ez3dScene.activeBuilding.roofs[0].areas[0].subareas[0].system;
                    system.updateGrid(undefined, true, true, true);
                });
                if (callback) {
                    callback();
                }
            }
        },
        customGuiMethods: {
            onSimpleBuildingPanelOpen: function() {
                ee.emitEvent('lockInterface');
                ee.emitEvent('updateSimpleBuildingPanel');
            },
            onSetActiveBuilding: function() {
                if (ez3dScene.active.constructor.name === 'EZModelBuilding') {
                    ee.emitEvent('openSimpleBuildingPanel');
                }
            }
        },
        customListeners: {
            onPanelOpen: ['openSimpleBuildingPanel', 'onSimpleBuildingPanelOpen'],
            updateSimpleBuildingPanel: ['updatePanels', 'onSimpleBuildingPanelOpen'],
            onSetActive: ['setActive_Finished', 'onSetActiveBuilding']
        },
        populate: 'updatePanel',
        json: 'simpleBuildingInfo'
    };

    return panelData;
};

// simple subarea info panel
EZModelScene.prototype.simpleSubareaPanel = function() {
    var panelData = {
        name: 'simple-subarea',
        model: 'EZModelSubarea',
        object: 'subarea',
        customGuiMethods: {
            onSimpleSubareaPanelOpen: function() {
                ee.emitEvent('lockInterface');
                ee.emitEvent('updateSimpleSubareaPanel');
            },
            onSetActiveSubarea: function() {
                if (ez3dScene.active.constructor.name === 'EZModelSubarea') {
                    ee.emitEvent('openSimpleSubareaPanel');
                }
            }
        },
        customListeners: {
            onSimpleSubareaPanelOpen: ['openSimpleSubareaPanel', 'onSimpleSubareaPanelOpen'],
            updateSimpleAreaPanel: ['updatePanels', 'onSimpleSubareaPanelOpen'],
            onSetActiveSubarea: ['setActive_Finished', 'onSetActiveSubarea']
        },
        populate: 'updatePanel',
        json: 'simpleSubareaInfo'
    };

    return panelData;
};

// Start info
EZModelScene.prototype.startInfo = function() {
    var objectJSON = {
        title: {
            type: 'title',
            htmlElement: 'h2',
            classed: 'ez3d-group-title',
            title: 'noBuildingsHelp'
        },
        helperList: {
            type: 'helper',
            classed: 'helperList',
            content: 'zoomAdvice'
        },
        start: {
            position: 'bottom',
            type: 'button',
            classed: 'ez3d-square-button ez3d-square-button-green',
            content: 'start',
            eventOnClick: (this.layoutRules.scenePreferences.enableBuildingShapes)
                ? undefined
                : ['EZModelScene_createBuildingListener',
                    undefined,
                    true,
                    {draw2d: true, draw3d: true, updatePanels: true, fitRange: true},
                    false],
            confirmation: this.layoutRules.scenePreferences.enableBuildingShapes,
            confirmationWidget: {
                'name': 'create-building',
                'title': 'buildingCreationTitle',
                'content': 'buildingCreationMessage',
                'optionsButtons': {
                    'customShapes': {
                        'eventOnClick': ['EZModelScene_createBuildingShapeListener', 
                            undefined,
                            false,
                            {draw2d: true, draw3d: false, updatePanels: true, fitRange: false},
                            false],
                        'content': 'customShapes',
                        'imageClassName': 'ez3d-customShapes-icon',
                    },
                    'drawShape': {
                        'eventOnClick': ['EZModelScene_createBuildingListener', 
                            undefined,
                            false,
                            {draw2d: true, draw3d: false, updatePanels: true, fitRange: false},
                            false],
                        'content': 'drawShape',
                        'imageClassName': 'ez3d-drawShape-icon',
                    },
                }
            }
        }
    };
    return objectJSON;
};

// Start info panel
EZModelScene.prototype.startInfoPanel = function() {
    var panelData = {
        name: 'building-start',
        model: '',
        customGuiMethods: {
            onActiveUpdatedStartPanel: function() {
                if (ez3dScene.buildings.length < 1 && ez3dScene.active === 'none') {
                    // tabChanged for activating Building tab
                    if (ez3dViewport.ez3dGuiManager.activeTabName !== 'building') {
                        ee.emitEvent('tabChanged', [{
                            args: 'ez3d-building-info-tab'
                        }]);
                    }
                    ee.emitEvent('openBuildingStartPanel');
                } else if (ez3dScene.buildings.length === 1 && d3.select('#ez3d-aside-building-start').classed('ez3d-active-panel')) {
                    // Close Start info panel when recreating the first building (undo/redo)
                    ee.emitEvent('closeBuildingStartPanel');
                    ee.emitEvent('unlockInterface');
                    ee.emitEvent('tabChanged', [{
                        args: 'ez3d-building-info-tab'
                    }]);
                }
            },
            updateBuildingStartPanel: function() {
                ee.emitEvent('updateBuildingStartPanel');
            },
            exitOnStartBuildingCreation: function() {
                ee.emitEvent('closeBuildingStartPanel');
            },
        },
        customListeners: {
            activeUpdated: ['activeUpdated', 'onActiveUpdatedStartPanel'],
            updatePanels: ['updatePanels', 'updateBuildingStartPanel'],
            exitOnBuildingCreate: ['EZModelScene_createBuildingListener', 'exitOnStartBuildingCreation'],
            exitOnBuildingShapeCreate: ['EZModelScene_createBuildingShapeListener', 'exitOnStartBuildingCreation'],
        },

        populate: 'updatePanel',
        json: 'startInfo'
    };
    return panelData;
};

// subarea create panel
EZModelScene.prototype.subareaCreatePanel = function() {
    var panelData = {
        name: 'subarea-create',
        model: 'EZModelSubarea',
        object: 'subarea',
        customGuiMethods: {
            onActiveUpdatedSubareaCreatePanel: function() {
                if (ez3dScene.active.constructor.name === 'EZModelSubarea') {
                    if (ez3dScene.active.isEditing === false && ez3dScene.active.isCreated === false) {
                        ee.emitEvent('updateSubareaCreatePanel');
                        ee.emitEvent('openSubareaCreatePanel');
                    }
                }
            },
            onCancelSubareaCreation: function() {
                ee.emitEvent('closeSubareaCreatePanel');
            },
            updateSubareaCreatePanel: function() {
                ee.emitEvent('updateSubareaCreatePanel');
            }
        },
        customListeners: {
            activeUpdated: ['activeUpdated', 'onActiveUpdatedSubareaCreatePanel'],
            cancelEditing: ['cancelSubareaCreation', 'onCancelSubareaCreation'],
            updatePanels: ['updatePanels', 'updateSubareaCreatePanel'],
            addLocationFinished: ['addLocation_Finished', 'updateSubareaCreatePanel'],
            moveFinished: ['move_Finished', 'updateSubareaCreatePanel'],
            deleteLocationFinished: ['delete_Location_Finished', 'updateSubareaCreatePanel']
        },
        populate: 'updatePanel',
        json: 'subareaCreate'
    };

    return panelData;
};

// subarea edit panel
EZModelScene.prototype.subareaEditPanel = function() {
    var panelData = {
        name: 'subarea-edit',
        model: 'EZModelSubarea',
        object: 'subarea',
        customGuiMethods: {
            onActiveUpdatedSubareaEditPanel: function() {
                ee.addOnceListener('tempPath_ready', function () {
                    ee.emitEvent('updateSubareaEditPanel');
                    ee.emitEvent('openSubareaEditPanel');
                });
            },
            closeSubareaEditPanel: function() {
                ee.emitEvent('closeSubareaEditPanel');
            },
            updateSubareaEditPanel: function() {
                ee.emitEvent('updateSubareaEditPanel');
            }
        },
        customListeners: {
            activeUpdated: ['EZModelArea_editSubareaListener', 'onActiveUpdatedSubareaEditPanel'],
            cancelEditing: ['editSubarea_Cancelled', 'closeSubareaEditPanel'],
            updatePanels: ['updatePanels', 'updateSubareaEditPanel']
        },
        populate: 'updatePanel',
        json: 'subareaEdit'
    };

    return panelData;
};

// subarea info panel
EZModelScene.prototype.subareaInfoPanel = function() {
    var panelData = {
        name: 'subarea-info',
        model: 'EZModelSubarea',
        object: 'subarea',
        customGuiMethods: {
            onActiveUpdatedSubareaInfoPanel: function() {
                if (ez3dScene.active.constructor.name === 'EZModelSubarea') {
                    if (ez3dScene.active.isEditing === false && ez3dScene.active.isCreated === true) {
                        ee.emitEvent('updateSubareaInfoPanel', [ez3dScene.subareaInfoPanel()]);
                        ee.emitEvent('openSubareaInfoPanel');
                    }
                }
            },
        },
        customListeners: {
            activeUpdated: ['activeUpdated', 'onActiveUpdatedSubareaInfoPanel'],
            modeChanged: ['modeChanged', 'onActiveUpdatedSubareaInfoPanel']
        },
        populate: 'updatePanel',
        json: 'subareaInfo'
    };

    return panelData;
};

// Trees info
EZModelScene.prototype.treesInfo = function() {
    var self = this;

    var objectJSON = {
        treeList: {
            type: 'blockList',
            emptyMesage: 'treeEmpty',
            classed: 'ez3d-block-list',
            elements: {}
        }
    };

    self.trees.forEach(function(tree) {
        objectJSON.treeList.elements[tree.id] = tree.treeInfo();
    });

    return objectJSON;
};

// Trees info panel
EZModelScene.prototype.treesInfoPanel = function() {
    var panelData = {
        name: 'tree-info',
        model: 'EZModelTree',
        object: 'tree',
        json: this.treesInfo()
    };

    return panelData;
};

EZModelSubarea.prototype.simpleSubareaInfo = function() {
    var objectJSON = {
        previous: {
            position: 'bottom',
            type: 'button',
            classed: 'ez3d-square-button ez3d-square-button-green',
            content: 'previous',
            eventOnClick: ['EZModelScene_setActiveListener', ez3dScene.activeBuilding.id, true, false]
        },
        modelId: {
            type: 'select',
            property: 'system.modelId',
            label: 'moduleModel',
            value: this.system.modelId,
            options: this.getDefaultModules(),
            id: 'ez3d-module-model',
            eventOnChange: [
                'EZModelSubarea_updateSystemGridListener',
                undefined,
                true,
                {draw2d: true, draw3d: false, updatePanels: false, fitRange: false},
                true
            ],
        },
        placement: {
            type: 'select',
            property: 'system.placement',
            label: 'moduleOrientation',
            value: this.system.placement,
            optionsLabels: ez3dScene.layoutRules.defaultModelValues.subareaByRoofType[this.parent.parent.type].availablePlacement,
            id: 'ez3d-module-orientation',
            eventOnChange: [
                'EZModelSubarea_updateSystemGridListener',
                undefined,
                true,
                {draw2d: true, draw3d: false, updatePanels: false, fitRange: false},
                true
            ],
        },
        structure: {
            type: 'select',
            property: 'system.structure',
            label: 'mountingType',
            value: this.system.structure,
            optionsLabels: ez3dScene.layoutRules.defaultModelValues.subareaByRoofType[this.parent.parent.type].availableStructure,
            id: 'ez3d-panels-orientation',
            eventOnChange: [
                'EZModelSubarea_updateSystemGridListener',
                undefined,
                true,
                {draw2d: true, draw3d: false, updatePanels: false, fitRange: false},
                true
            ],
        },
        inclination: {
            type: 'integer',
            property: 'system.inclination',
            label: 'moduleInclination',
            value: this.system.inclination,
            max: 90,
            min: 0,
            step: 1,
            id: 'ez3d-module-inclination'
        },
        insetX: {
            type: 'float',
            property: 'system.inset.x',
            label: 'distanceModules',
            value: this.system.inset.x,
            max: 'infinity',
            min: 0,
            step: 0.1,
            numberOfDecimals: 3,
            universalUnits: true,
            id: 'ez3d-inset-x',
            eventOnChange: [
                'EZModelSubarea_updateSystemGridListener',
                undefined,
                true,
                {draw2d: true, draw3d: false, updatePanels: false, fitRange: false},
                true
            ],
        },
        insetY: {
            type: 'float',
            property: 'system.inset.y',
            label: 'distanceRows',
            value: this.system.inset.y,
            max: 'infinity',
            min: 0,
            step: 0.1,
            numberOfDecimals: 3,
            universalUnits: true,
            id: 'ez3d-inset-y',
            eventOnChange: [
                'EZModelSubarea_updateSystemGridListener',
                undefined,
                true,
                {draw2d: true, draw3d: false, updatePanels: false, fitRange: false},
                true
            ],
        },
        totalInsetY: {
            type: 'float',
            property: 'system.totalInset.y',
            label: 'totaldistanceRows',
            value: this.system.totalInset.y,
            max: 'infinity',
            min: 0,
            step: 0.1,
            numberOfDecimals: 3,
            universalUnits: true,
            id: 'ez3d-inset-y',
            eventOnChange: [
                'EZModelSubarea_updateSystemGridListener',
                undefined,
                true,
                {draw2d: true, draw3d: false, updatePanels: false, fitRange: false},
                true
            ],
        },
        dilatationLinesEnabled: {
            type: 'boolean',
            property: 'system.dilatationLines.enabled',
            label: 'dilatationLinesEnabled',
            value: this.system.dilatationLines.enabled,
            id: 'ez3d-dilatationLines-enabled',
            eventOnChange: [
                'EZModelSubarea_updateSystemGridListener',
                undefined,
                true,
                {draw2d: true, draw3d: false, updatePanels: false, fitRange: false},
                true
            ],
        },
        dilatationLinesCol: {
            type: 'integer',
            property: 'system.dilatationLines.col',
            label: 'dilatationLinesColOffset',
            value: this.system.dilatationLines.col,
            visibilityRule: this.system.dilatationLines.enabled,
            max: 'infinity',
            min: 0,
            step: 1,
            id: 'ez3d-dilatationLines-col',
            eventOnChange: [
                'EZModelSubarea_updateSystemGridListener',
                undefined,
                true,
                {draw2d: true, draw3d: false, updatePanels: false, fitRange: false},
                true
            ],
        },
        dilatationLinesRow: {
            type: 'integer',
            property: 'system.dilatationLines.row',
            label: 'dilatationLinesRowOffset',
            value: this.system.dilatationLines.row,
            visibilityRule: this.system.dilatationLines.enabled,
            max: 'infinity',
            min: 0,
            step: (this.system.structure === 'EW') ? 2 : 1,
            id: 'ez3d-dilatationLines-row',
            eventOnChange: [
                'EZModelSubarea_updateSystemGridListener',
                undefined,
                true,
                {draw2d: true, draw3d: false, updatePanels: false, fitRange: false},
                true
            ],
        },
        dilatationLinesX: {
            type: 'float',
            property: 'system.dilatationLines.x',
            label: 'dilatationLinesX',
            value: this.system.dilatationLines.x,
            visibilityRule: this.system.dilatationLines.enabled,
            max: 'infinity',
            min: 1,
            step: 1,
            id: 'ez3d-dilatationLines-x',
            numberOfDecimals: 2,
            universalUnits: true,
            eventOnChange: [
                'EZModelSubarea_updateSystemGridListener',
                undefined,
                true,
                {draw2d: true, draw3d: false, updatePanels: false, fitRange: false},
                true
            ],
        },
        dilatationLinesY: {
            type: 'float',
            property: 'system.dilatationLines.y',
            label: 'dilatationLinesY',
            value: this.system.dilatationLines.y,
            visibilityRule: this.system.dilatationLines.enabled,
            max: 'infinity',
            min: 1,
            step: 1,
            id: 'ez3d-dilatationLines-y',
            numberOfDecimals: 2,
            universalUnits: true,
            eventOnChange: [
                'EZModelSubarea_updateSystemGridListener',
                undefined,
                true,
                {draw2d: true, draw3d: false, updatePanels: false, fitRange: false},
                true
            ],
        },
        dilatationLinesW: {
            type: 'float',
            property: 'system.dilatationLines.w',
            label: 'dilatationLinesW',
            value: this.system.dilatationLines.w,
            visibilityRule: this.system.dilatationLines.enabled,
            max: 'infinity',
            min: 0.1,
            step: 0.1,
            id: 'ez3d-dilatationLines-w',
            numberOfDecimals: 2,
            universalUnits: true,
            eventOnChange: [
                'EZModelSubarea_updateSystemGridListener',
                undefined,
                true,
                {draw2d: true, draw3d: false, updatePanels: false, fitRange: false},
                true
            ],
        },
        dilatationLinesH: {
            type: 'float',
            property: 'system.dilatationLines.h',
            label: 'dilatationLinesH',
            value: this.system.dilatationLines.h,
            visibilityRule: this.system.dilatationLines.enabled,
            max: 'infinity',
            min: 0.1,
            step: 0.1,
            id: 'ez3d-dilatationLines-h',
            numberOfDecimals: 2,
            universalUnits: true,
            eventOnChange: [
                'EZModelSubarea_updateSystemGridListener',
                undefined,
                true,
                {draw2d: true, draw3d: false, updatePanels: false, fitRange: false},
                true
            ],
        }
    };
    return objectJSON;
};

EZModelSubarea.prototype.subareaCreate = function() {
    var objectJSON = {
        finish: {
            position: 'bottom',
            type: 'button',
            classed: 'ez3d-square-button ez3d-square-button-green',
            content: 'finish',
            editable: 'finishSubareaValidation',
            eventOnClick: ['finishSubareaCreation', '']
        },
        cancel: {
            position: 'bottom',
            type: 'button',
            classed: 'ez3d-square-button ez3d-square-button-blue',
            content: 'cancel',
            eventOnClick: ['cancelSubareaCreation', '']
        },
        regular: {
            type: 'boolean',
            property: 'regular',
            label: 'allowIrregularAngles',
            value: this.regular
        },
        helperRegular: {
            type: 'helper',
            classed: 'helperCenter',
            content: 'irregularAnglesHelper'
        }
    };
    return objectJSON;
};


EZModelSubarea.prototype.subareaEdit = function() {
    var objectJSON = {
        finish: {
            position: 'bottom',
            type: 'button',
            classed: 'ez3d-square-button ez3d-square-button-green',
            content: 'finish',
            editable: 'finishSubareaValidation',
            eventOnClick: ['finishSubareaEditingOperator', '']
        },
        cancel: {
            position: 'bottom',
            type: 'button',
            classed: 'ez3d-square-button ez3d-square-button-blue',
            content: 'cancel',
            eventOnClick: ['cancelSubareaEditingOperator', '']
        },
        regular: {
            type: 'boolean',
            property: 'regular',
            label: 'allowIrregularAngles',
            value: this.regular
        },
        helperRegular: {
            type: 'helper',
            classed: 'helperCenter',
            content: 'irregularAnglesHelper'
        }
    };
    return objectJSON;
};


EZModelSubarea.prototype.subareaInfo = function() {
    var objectJSON = {
        close: {
            position: 'bottom',
            type: 'button',
            classed: 'ez3d-square-button ez3d-square-button-green',
            content: 'close',
            eventOnClick: ['EZModelScene_setActiveListener', this.parentId, true, false],
            id: 'ez3d-close-subarea-button'
        },
        resume: {
            type: 'blockList',
            classed: 'ez3d-resume-subarea-block',
            elements: {
                name: {
                    type: 'title',
                    htmlElement: 'h2',
                    classed: 'ez3d-title-resume',
                    title: this.name
                },
                modulesInfo: {
                    type: 'helper',
                    classed: 'subtitle-info',
                    content: {
                        text: 'modulesInfo',
                        vars: {
                            num: this.systemInfo.modules,
                            power: this.systemInfo.power
                        }
                    }
                }
            }
        },
        offset: {
            type: 'float',
            property: 'offset',
            label: 'subareaOffset',
            value: this.offset[0],
            max: 'infinity',
            min: 0,
            step: 0.1,
            numberOfDecimals: 2,
            universalUnits: true,
            id: 'ez3d-edge-zone'
        },
        buttonsPanel: {
            type: 'groupBlock',
            elements: {
                classed: 'ez3d-panel-buttons ez3d-flex-around-wrap-row',
                refresh: {
                    type: 'button',
                    tooltip: 'refresh',
                    classed: 'ez3d-button fa-refresh',
                    eventOnClick: [
                        'EZModelSubarea_resetSubareaListener',
                        null,
                        true,
                        {draw2d: true, draw3d: this, updatePanels: false, fitRange: false},
                        true
                    ]
                },
                paint: {
                    id: 'ez3d-paint-subarea-button',
                    type: 'button',
                    tooltip: 'paint',
                    classed: 'ez3d-button fa-paint-brush',
                    visibilityRule: !this.system.model.colour,
                    confirmation: true,
                    confirmationWidget: {
                        'name': 'color-palette-selector-subarea',
                        'title': 'colorPaletteSelector',
                        'eventOk': 'widget_color_eventOk',
                        'eventCancel': 'widget_color_eventCancel',
                        'functionOnCreation': ['colorPaletteWidget', 'subarea']
                    }
                },
                move: {
                    type: 'button',
                    tooltip: 'move',
                    classed: 'ez3d-button fa-arrows',
                    active: ['isInteractiveMode', this.system.id],
                    eventOnClick: [
                        'EZModelSubarea_runMethodListener',
                        'setInteractiveMoveToggle',
                        false,
                        {draw2d: true, draw3d: true, updatePanels: false, fitRange: false}
                    ]
                }
            }
        },
        azimuthButton: {
            type: 'button',
            tooltip: 'changeAzimuthSystem',
            content: 'changeAzimuthSystem',
            classed: 'ez3d-square-button ez3d-square-button-green',
            id: 'ez3d-open-azimuth-view-button',
            visibilityRule: 'showOpenSubareaView',
            functionOnClick: ['openSubareaView', '']
        },
        azimuth: {
            type: 'azimuthRange',
            property: 'system.azimuth',
            label: 'azimuth',
            value: this.system.azimuth,
            max: 180,
            min: -180,
            step: 1,
            id: 'ez3d-azimuth',
            visibilityRule: 'showCloseSubareaView',
        },
        modelId: {
            type: 'select',
            property: 'system.modelId',
            label: 'moduleModel',
            value: this.system.modelId,
            options: this.getDefaultModules(),
            id: 'ez3d-module-model'
        },
        placement: {
            type: 'select',
            property: 'system.placement',
            label: 'moduleOrientation',
            value: this.system.placement,
            optionsLabels: ez3dScene.layoutRules.defaultModelValues.subareaByRoofType[ez3dScene.activeRoof.type].availablePlacement,
            id: 'ez3d-module-orientation'
        },
        structure: {
            type: 'select',
            property: 'system.structure',
            label: 'mountingType',
            value: this.system.structure,
            visibilityRule: !this.system.sails.enabled,
            optionsLabels: ez3dScene.layoutRules.defaultModelValues.subareaByRoofType[ez3dScene.activeRoof.type].availableStructure,
            id: 'ez3d-panels-orientation',
        },
        inclination: {
            type: 'integer',
            property: 'system.inclination',
            label: 'moduleInclination',
            value: this.system.inclination,
            max: 90,
            min: 0,
            step: 1,
            id: 'ez3d-module-inclination'
        },
        insetX: {
            type: 'float',
            property: 'system.inset.x',
            label: 'distanceModules',
            value: this.system.inset.x,
            max: 'infinity',
            min: 0,
            step: 0.1,
            numberOfDecimals: 3,
            universalUnits: true,
            id: 'ez3d-inset-x',
        },
        insetY: {
            type: 'float',
            property: 'system.inset.y',
            label: 'distanceRows',
            value: this.system.inset.y,
            max: 'infinity',
            min: 0,
            step: 0.1,
            numberOfDecimals: 3,
            editable: (!this.system.useShadowsCalculation),
            universalUnits: true,
            id: 'ez3d-inset-y',
            visibilityRule: !this.system.sails.enabled,
        },
        totalInsetY: {
            type: 'float',
            property: 'system.totalInset.y',
            label: 'totaldistanceRows',
            value: this.system.totalInset.y,
            max: 'infinity',
            min: 0,
            step: 0.1,
            numberOfDecimals: 3,
            editable: (!this.system.useShadowsCalculation),
            universalUnits: true,
            id: 'ez3d-inset-y',
            visibilityRule: !this.system.sails.enabled,
        },
        useShadowsCalculation: {
            type: 'boolean',
            property: 'system.useShadowsCalculation',
            label: 'shadowCalculation',
            value: this.system.useShadowsCalculation,
            id: 'ez3d-use-shadows-calculation',
            visibilityRule: !this.system.sails.enabled,
        },
        staggeredEnabled: {
            type: 'boolean',
            property: 'system.staggered.enabled',
            label: 'staggeredEnabled',
            value: this.system.staggered.enabled,
            visibilityRule: (this.system.structure !== 'EW') && !this.system.sails.enabled,
            id: 'ez3d-staggered-enabled',
            confirmation: this.system.dilatationLines.enabled,
            confirmationWidget: {
                'name': 'enablingDilatations',
                'title': 'enablingDilatationsAlertTitle',
                'eventOk': 'widget_enablingDilatationLines_eventOk',
                'eventCancel': 'widget_enablingDilatationLines_eventCancel',
                'functionOnCreation': ['formatRowOffsetOrStaggeredTooltip']
            }
        },
        staggeredOffset: {
            type: 'float',
            property: 'system.staggered.offset',
            label: 'staggeredOffset',
            value: this.system.staggered.offset,
            max: this.system.staggered.offsetMax,
            min: 0,
            step: 0.1,
            numberOfDecimals: 3,
            visibilityRule: this.system.staggered.enabled,
            universalUnits: true,
            id: 'ez3d-staggered-offset'
        },
        staggeredAlternate: {
            type: 'boolean',
            property: 'system.staggered.alternate',
            label: 'staggeredAlternate',
            value: this.system.staggered.alternate,
            visibilityRule: this.system.staggered.enabled,
            id: 'ez3d-staggered-alternate'
        },
        sailsEnabled: {
            type: 'boolean',
            property: 'system.sails.enabled',
            label: 'sailsEnabled',
            value: this.system.sails.enabled,
            id: 'ez3d-sails-enabled',
            confirmation: !this.system.sails.enabled,
            confirmationWidget: {
                name: 'enablingSails',
                title: 'enablingSailsAlertTitle',
                eventOk: 'widget_enablingSails_eventOk',
                eventCancel: 'widget_enablingSails_eventCancel',
                functionOnCreation: ['enablingModuleSailsTooltip'],
            }
        },
        sailsLevels: {
            type: 'integer',
            property: 'system.sails.levels',
            label: 'sailsLevels',
            value: this.system.sails.levels,
            max: 90,
            min: 2,
            step: 1,
            id: 'ez3d-sails-levels',
            visibilityRule: this.system.sails.enabled
        },
        sailsSpacing: {
            type: 'float',
            property: 'system.sails.spacing',
            label: 'sailsSpacing',
            value: this.system.sails.spacing,
            max: 'infinity',
            min: 0,
            step: 0.1,
            numberOfDecimals: 2,
            universalUnits: true,
            id: 'ez3d-sails-spacing',
            visibilityRule: this.system.sails.enabled
        },
        dilatationLinesEnabled: {
            type: 'boolean',
            property: 'system.dilatationLines.enabled',
            label: 'dilatationLinesEnabled',
            value: this.system.dilatationLines.enabled,
            id: 'ez3d-dilatationLines-enabled',
            // This should be checked in system watcher
            eventOnChange: this.checkRowOffsetOrStaggered()
                ? undefined
                : [
                    'EZModelSubarea_updateSystemGridListener',
                    [true, false, false, true],
                    null,
                    {draw2d: true, draw3d: this, updatePanels: true, fitRange: false},
                    true],
            confirmation: 'checkRowOffsetOrStaggered',
            confirmationWidget: {
                'name': 'enablingDilatations',
                'title': 'enablingDilatationsAlertTitle',
                'eventOk': 'widget_enablingDilatationLines_eventOk',
                'eventCancel': 'widget_enablingDilatationLines_eventCancel',
                'functionOnCreation': ['formatRowOffsetOrStaggeredTooltip']
            },
            visibilityRule: !this.system.sails.enabled
        },
        dilatationLinesCol: {
            type: 'integer',
            property: 'system.dilatationLines.col',
            label: this.system.sails.enabled ? 'sailsCorridorOffset' : 'dilatationLinesColOffset',
            value: this.system.dilatationLines.col,
            visibilityRule: this.system.dilatationLines.enabled,
            max: 'infinity',
            min: 0,
            step: 1,
            id: 'ez3d-dilatationLines-col',
            eventOnChange: [
                'EZModelSubarea_updateSystemGridListener',
                [false, false, false, true],
                null,
                {draw2d: true, draw3d: this, updatePanels: true, fitRange: false},
                true
            ]
        },
        dilatationLinesRow: {
            type: 'integer',
            property: 'system.dilatationLines.row',
            label: 'dilatationLinesRowOffset',
            value: this.system.dilatationLines.row,
            visibilityRule: this.system.dilatationLines.enabled && !this.system.sails.enabled,
            max: 'infinity',
            min: 0,
            step: (this.system.structure === 'EW') ? 2 : 1,
            id: 'ez3d-dilatationLines-row',
            eventOnChange: [
                'EZModelSubarea_updateSystemGridListener',
                [false, false, false, true],
                null,
                {draw2d: true, draw3d: this, updatePanels: true, fitRange: false},
                true
            ]
        },
        dilatationLinesX: {
            type: 'float',
            property: 'system.dilatationLines.x',
            label: 'dilatationLinesX',
            value: this.system.dilatationLines.x,
            visibilityRule: this.system.dilatationLines.enabled,
            max: 'infinity',
            min: 1,
            step: 1,
            id: 'ez3d-dilatationLines-x',
            numberOfDecimals: 2,
            universalUnits: true,
            eventOnChange: [
                'EZModelSubarea_updateSystemGridListener',
                [false, false, false, true],
                null,
                {draw2d: true, draw3d: this, updatePanels: true, fitRange: false},
                true
            ]
        },
        dilatationLinesY: {
            type: 'float',
            property: 'system.dilatationLines.y',
            label: 'dilatationLinesY',
            value: this.system.dilatationLines.y,
            visibilityRule: this.system.dilatationLines.enabled && !this.system.sails.enabled,
            max: 'infinity',
            min: 1,
            step: 1,
            id: 'ez3d-dilatationLines-y',
            numberOfDecimals: 2,
            universalUnits: true,
            eventOnChange: [
                'EZModelSubarea_updateSystemGridListener',
                [false, false, false, true],
                null,
                {draw2d: true, draw3d: this, updatePanels: true, fitRange: false},
                true
            ]
        },
        dilatationLinesW: {
            type: 'float',
            property: 'system.dilatationLines.w',
            label: this.system.sails.enabled ? 'sailsCorridorWidth' : 'dilatationLinesW',
            value: this.system.dilatationLines.w,
            visibilityRule: this.system.dilatationLines.enabled,
            max: 'infinity',
            min: 0.1,
            step: 0.1,
            id: 'ez3d-dilatationLines-w',
            numberOfDecimals: 2,
            universalUnits: true,
            eventOnChange: [
                'EZModelSubarea_updateSystemGridListener',
                [false, false, false, true],
                null,
                {draw2d: true, draw3d: this, updatePanels: true, fitRange: false},
                true
            ]
        },
        dilatationLinesH: {
            type: 'float',
            property: 'system.dilatationLines.h',
            label: 'dilatationLinesH',
            value: this.system.dilatationLines.h,
            visibilityRule: this.system.dilatationLines.enabled && !this.system.sails.enabled,
            max: 'infinity',
            min: 0.1,
            step: 0.1,
            id: 'ez3d-dilatationLines-h',
            numberOfDecimals: 2,
            universalUnits: true,
            eventOnChange: [
                'EZModelSubarea_updateSystemGridListener',
                [false, false, false, true],
                null,
                {draw2d: true, draw3d: this, updatePanels: true, fitRange: false},
                true
            ]
        }
    };
    return objectJSON;
};

EZModelSubarea.prototype.subareaInfoResume = function() {
    var objectJSON = {
        type: 'subareaBlock',
        id: this.id,
        object: 'subarea',
        disabled: this.parent.disabled || this.disabled,
        title: {
            areaMoreInfo: {
                type: 'button',
                tooltip: 'moreInfo',
                classed: 'ez3d-button-resume foldable-down fa-caret-down'
            },
            name: {
                type: 'title',
                htmlElement: 'h2',
                classed: 'ez3d-title-resume',
                title: this.name,
                disabled: this.parent.disabled || this.disabled
            },
            modulesInfo: {
                type: 'helper',
                classed: 'subtitle-info',
                content: {
                    text: 'modulesInfo',
                    vars: {
                        num: this.systemInfo.modules,
                        power: this.systemInfo.power
                    }
                }
            }
        },
        elements: {
            disabled: {
                type: 'button',
                property: 'disabled',
                tooltip: (this.disabled) ? 'enableSubarea' : 'disableSubarea',
                value: this.disabled,
                classed: 'ez3d-button-resume ez3d-disabled-eye',
                id: this.id,
                disabled: this.parent.disabled
            },
            buttonsPanel: {
                type: 'groupBlock',
                elements: {
                    classed: 'ez3d-panel-buttons ez3d-flex-around-wrap-row',
                    edit: {
                        type: 'button',
                        tooltip: 'editSubarea',
                        classed: 'ez3d-button fa-pencil',
                        eventOnClick: [
                            'EZModelArea_editSubareaListener',
                            this.id,
                            true,
                            {draw2d: true, draw3d: false, updatePanels: false, fitRange: false}
                        ],
                        disabled: this.parent.disabled || this.disabled
                    },
                    crop: {
                        type: 'button',
                        tooltip: 'cropSubarea',
                        classed: 'ez3d-button fa-crop',
                        eventOnClick: [
                            'EZModelArea_cropSubareaListener',
                            this.id,
                            true,
                            {draw2d: true, draw3d: true, updatePanels: false, fitRange: false}
                        ],
                        disabled: this.parent.disabled || this.disabled
                    },
                    move: {
                        type: 'button',
                        tooltip: 'moveSubarea',
                        classed: 'ez3d-button fa-arrows',
                        active: ['isInteractiveMode', this.id],
                        eventOnClick: [
                            'EZModelArea_interactiveMoveSubareaListener',
                            [this.id],
                            false,
                            {draw2d: true, draw3d: true, updatePanels: false, fitRange: false}
                        ],
                        disabled: this.parent.disabled || this.disabled
                    },
                    delete: {
                        type: 'button',
                        tooltip: (this.parent.subareas.length === 1) ? 'resetSubarea' : 'deleteSubarea',
                        classed: (this.parent.subareas.length === 1)
                            ? 'ez3d-button fa-refresh' : 'ez3d-button fa-trash',
                        eventOnClick: [
                            'EZModelArea_deleteSubareaListener',
                            [this.id, true],
                            true,
                            {draw2d: true, draw3d: this, updatePanels: true, fitRange: false},
                            true],
                        disabled: this.parent.disabled || this.disabled
                    },
                    clone: {
                        type: 'button',
                        tooltip: 'cloneSubarea',
                        classed: 'ez3d-button fa-clone',
                        eventOnClick: [
                            'EZModelArea_cloneSubareaListener',
                            [this.id, undefined],
                            true,
                            {draw2d: true, draw3d: this, updatePanels: true, fitRange: false},
                            true
                        ],
                        disabled: this.parent.disabled || this.disabled
                    }
                }
            },
            area: {
                type: 'helper',
                classed: 'helperList paddingLeft_13',
                content: {
                    text: 'subareaSurface',
                    vars: {
                        value: d3.format(',.2f')(this.path.getSurface(true))
                    }
                }
            },
        },
        eventOnClick: ['EZModelScene_setActiveListener', this.id, true, false]
        // updateOnClick: true
    };
    return objectJSON;
};

// Validations

EZModelSubarea.prototype.getIcon = function() {
    var iconClass = 'fa-eye';
    if (this.disabled) {
        iconClass = 'fa-eye-slash';
    }
    return iconClass;
};

// finish subarea condition
EZModelSubarea.prototype.finishSubareaValidation = function() {
    var validSubarea = true;

    var ez3dViewport = ez3dScene.layoutInstance.ez3dViewport;

    if (ez3dScene.locations.length > 2 && ez3dScene.tempPath) {
        // allowIrregularAngles
        if (!this.regular) {
            ez3dScene.tempPath.calculateCornersAngles();
            if (!ez3dScene.tempPath.regularAngles) {
                if (ez3dScene.layoutRules.scenePreferences.viewportMode !== 3) {
                    ez3dViewport.ez3dGuiManager.notificationManager(['anglesNotRegular', {style: 'error'}]);
                }
                validSubarea = false;
            }
        }
    } else {
        validSubarea = false;
        if (ez3dScene.layoutRules.scenePreferences.viewportMode !== 3) {
            ez3dViewport.ez3dGuiManager.notificationManager(['drawPoint', {style: 'info'}]);
        }
    }
    // lanzar notificacion con info de añadir puntos

    return validSubarea;
};

// Tree info
EZModelTree.prototype.treeInfo = function() {
    var mainRadius = this.getMainRadiusProperty();
    var objectJSON = {
        type: 'foldableBlock',
        id: this.id,
        object: 'tree',
        title: {
            treeMoreInfo: {
                type: 'button',
                tooltip: 'moreInfo',
                classed: 'ez3d-button-resume foldable-down fa-caret-down'
            },
            name: {
                type: 'title',
                htmlElement: 'h2',
                classed: 'ez3d-title-resume',
                title: this.name + ((ez3dViewport.ez3dGuiManager) ? ' - ' + ez3dViewport.ez3dGuiManager.i18n(this.shape) : '')
            },
            heightInfo: {
                type: 'helper',
                classed: 'subtitle-info',
                content: {
                    text: 'subtitleInfo',
                    vars: {
                        height: this.crownTopHeight
                    }
                }
            }
        },
        elements: {
            height: {
                type: 'float',
                property: 'crownTopHeight',
                label: 'height',
                value: this.crownTopHeight,
                max: Infinity,
                min: 0.5,
                step: 1,
                numberOfDecimals: 2,
                universalUnits: true
            },
            radius: {
                type: 'float',
                property: mainRadius.property,
                label: 'radius',
                value: mainRadius.value,
                max: Infinity,
                min: 0.5,
                step: 1,
                numberOfDecimals: 2,
                universalUnits: true
            },
            buttonsPanel: {
                type: 'groupBlock',
                elements: {
                    classed: 'ez3d-panel-buttons ez3d-flex-around-wrap-row',
                    shape: {
                        type: 'button',
                        tooltip: 'changeTreeShape',
                        classed: 'ez3d-button fa-tree',
                        confirmation: true,
                        confirmationWidget: {
                            'name': 'treeShape',
                            'title': 'treeShape',
                            'functionOnCreation': ['treeShapeSelectorWidget']
                        }
                    },
                    move: {
                        type: 'button',
                        tooltip: 'moveTree',
                        classed: 'ez3d-button fa-arrows',
                        active: ['isInteractiveMode', this.id],
                        eventOnClick: [
                            'EZModelTree_runMethodListener',
                            'setInteractiveMoveToggle',
                            false,
                            {draw2d: true, draw3d: this, updatePanels: false, fitRange: false}
                        ]
                    },
                    delete: {
                        type: 'button',
                        tooltip: 'deleteTree',
                        classed: 'ez3d-button fa-trash',
                        eventOnClick: [
                            'EZModelTree_deleteListener',
                            this.id,
                            true,
                            {draw2d: true, draw3d: this, updatePanels: true, fitRange: true}
                        ]
                    },
                    clone: {
                        type: 'button',
                        tooltip: 'cloneTree',
                        classed: 'ez3d-button fa-clone',
                        eventOnClick: [
                            'EZModelTree_cloneListener',
                            null,
                            true,
                            {draw2d: true, draw3d: this, updatePanels: true, fitRange: false}
                        ]
                    }
                }
            }
        },
        functionOnClick: ['clickOnFoldableBlockTrees', this.id]
    };
    return objectJSON;
};

EZModelArea.prototype.watchAttribute = function(attr, eventName) {
    Object.defineProperty(
        (attr.split('.').length > 1) ? this[attr.split('.')[0]] : this,
        (attr.split('.').length > 1) ? attr.split('.')[1] : attr, {
            get: () => {
                switch (attr) {
                    case 'name':
                        return this.getName();
                    default:
                        return ez3dScene.getMethodAttributeValidation(this, attr);
                }
            },
            set: (val) => {
                ee.emitEvent('showProgressBar');
                ee.emitEvent('updateProgressBar', [{title: 'editAreaValues'}]);

                const value = ez3dScene.setMethodAttributeValidation(this, attr, val);
                // update
                switch (attr) {
                    case 'offset':
                        this.watcherAdjustmentOffset(attr, value[0]);
                        break;
                    case 'disabled':
                        this.watcherAdjustmentDisabled(value[0]);
                        break;
                    case 'inclination':
                        this.parent.updateFromArea(this);
                        break;
                }
                ee.emitEvent('hideProgressBar');

                // emitEvent
                if (ez3dScene.isLoadingProject === false) {
                    ee.emitEvent(eventName, [attr, value[0]]);
                }
            },
            enumerable: true,
            configurable: true
        });
};

EZModelArea.prototype.watcherAdjustmentOffset = function(attr, value) {
    if (Array.isArray(value)) {
        this.__data[attr] = value;
    } else {
        this.__data[attr] = [value];
    }
    this.updatePath(false, true);
    if (this.subareas.length === 1 &&
        this.subareas[0].fromScratch) {
        this.createSubareas();
    }
    this.updateSystem();
};

EZModelArea.prototype.watcherAdjustmentDisabled = function(value) {
    if (this.__data.populated === false) {
        this.__data.populated = true;
        this.parent.parent.__data.populated = true;
    }
    this.updateSystem(value);
};

EZModelBuilding.prototype.watchAttribute = function(attr, eventName) {
    Object.defineProperty(
        (attr.split('.').length > 1) ? this[attr.split('.')[0]] : this,
        (attr.split('.').length > 1) ? attr.split('.')[1] : attr, {
            get: () => {
                switch (attr) {
                    case 'name':
                        return this.getName();
                    default:
                        return ez3dScene.getMethodAttributeValidation(this, attr);
                }
            },
            set: (val) => {
                const value = ez3dScene.setMethodAttributeValidation(this, attr, val);
                let heightChange = false;
                // update
                switch (attr) {
                    case 'height':
                        this.__data.ridge.height = value[0] + this.__data.ridge.maxRoof;
                        heightChange = true;
                        break;
                    case 'offset':
                        this.watcherAdjustmentOffset(attr, value[0]);
                        break;
                    case 'ridge.height':
                        this.__data.height = value[0] - this.__data.ridge.maxRoof;
                        heightChange = true;
                        break;
                }
                if (heightChange && this.roofs[0].type === 'pergola') {
                    // building's height post readjustment
                    this.roofs[0].createPergolaPost();
                }
                // emitEvent
                if (ez3dScene.isLoadingProject === false) {
                    ee.emitEvent(eventName, [attr, value[0]]);
                }
            },
            enumerable: true
        });
};

EZModelBuilding.prototype.watcherAdjustmentOffset = function(attr, value) {
    if (Array.isArray(value)) {
        this.__data[attr] = value;
    } else {
        this.__data[attr] = [value];
    }
};

EZModelBuilding.prototype.getAreas = function() {
    const areas = [];
    this.roofs.forEach((roof) => {
        roof.areas.forEach((area) => {
            areas.push(area);
        });
    });
    return areas;
};

EZModelKeepout.prototype.watchAttribute = function(attr, eventName) {
    Object.defineProperty(
        (attr.split('.').length > 1) ? this[attr.split('.')[0]] : this,
        (attr.split('.').length > 1) ? attr.split('.')[1] : attr, {
            get: () => {
                switch (attr) {
                    case 'name':
                        return this.getName();
                    default:
                        return ez3dScene.getMethodAttributeValidation(this, attr);
                }
            },
            set: (val) => {
                const value = ez3dScene.setMethodAttributeValidation(this, attr, val);
                // update
                var building = ez3dScene.activeBuilding;
                ee.emitEvent('showProgressBar');
                ee.emitEvent('updateProgressBar', [{
                    title: 'editKeepoutValues'
                }]);
                building.updateKeepoutsOperator()
                    .then(() => {
                        ee.emitEvent('hideProgressBar');
                        // emitEvent
                        if (ez3dScene.isLoadingProject === false) {
                            ee.emitEvent(eventName, [attr, value[0]]);
                        }
                    });
            },
            enumerable: true
        });
};

EZModelRoof.prototype.watchAttribute = function(attr, eventName) {
    Object.defineProperty(
        (attr.split('.').length > 1) ? this[attr.split('.')[0]] : this,
        (attr.split('.').length > 1) ? attr.split('.')[1] : attr, {
            get: () => {
                switch (attr) {
                    case 'name':
                        return this.getName();
                    default:
                        return ez3dScene.getMethodAttributeValidation(this, attr);
                }
            },
            set: (val) => {
                const value = ez3dScene.setMethodAttributeValidation(this, attr, val);
                // update
                switch (attr) {
                    case 'type':
                        // material
                        ee.emitEvent('roofTypeChanged');
                        this.__data.material = this.getDefaultValue('material', value[0]);
                        // roofPointsSymmetry
                        this.__data.roofPointsSymmetry = this
                            .getDefaultValue('roofPointsSymmetry', value[0]);
                        if (value[0] === 'mansard' && this.path.vertices.length === 4) {
                            this.__data.roofPointsSymmetry = true;
                        }
                        // parallelRidge
                        this.checkParallelRidge(value[0]);
                        this.needsUpdate = true;
                        break;
                    case 'inclination':
                        // console.log('EZModelRoof', 'inclination changed to', value[0]);
                        this.needsUpdate = true;
                        break;
                    case 'orientation': {
                        const axis = this.checkInclinationAxis(value[0]);
                        // console.log('EZModelRoof', 'orientation changed to', axis);
                        this.__data.orientation = (axis > -1) ? axis : 0;
                        this.needsUpdate = true;
                        break;
                    }
                    default:
                        // console.log('EZModelRoof', 'watchAttribute', attr + ' does not needsUpdate');
                }
                // emitEvent
                if (ez3dScene.isLoadingProject === false) {
                    ee.emitEvent(eventName, [attr, value[0]]);
                }
            },
            enumerable: true
        });
};

EZModelScene.prototype.watchAttribute = function(attr, eventName) {
    Object.defineProperty(
        (attr.split('.').length > 1) ? this[attr.split('.')[0]] : this,
        (attr.split('.').length > 1) ? attr.split('.')[1] : attr, {
            get: () => {
                return ez3dScene.getMethodAttributeValidation(this, attr);
            },
            set: (val) => {
                const value = ez3dScene.setMethodAttributeValidation(this, attr, val);
                // emitEvent
                if (this.isLoadingProject === false) {
                    ee.emitEvent(eventName, [attr, value[0]]);
                }
            },
            enumerable: true
        });
};

/**
 *
 * Method to watchers: get attributes validated
 *
 * @param {object} EZmodel  - this is EZModel
 * @param {string} attr     - this is the attribute
 *
 * @return {string/number} value of the attribute
 */
EZModelScene.prototype.getMethodAttributeValidation = function (EZmodel, attr) {
    const [attribute, subattribute] = attr.split('.');
    if (subattribute) {
        return EZmodel.__data[attribute][subattribute];
    }
    if (EZmodel !== 'EZModelScene' && EZmodel !== 'EZModelSystem') {
        if (attr === 'name' && EZmodel.__data[attr] === undefined) {
            return EZmodel.getName();
        }
    }
    return EZmodel.__data[attr];
};

/**
 * Method to watchers: set attributes validated
 *
 * @param {object} EZmodel      - this is EZModel
 * @param {string} attr         - this is the attribute
 * @param {string/number} val   - value of this attribute
 *
 * @return {array} new value and old value of the attribute
 */
EZModelScene.prototype.setMethodAttributeValidation = function (EZmodel, attr, val) {
    // oldValue solo para system y area
    var oldValue;
    var value = (ez3dScene.utils.isNumber(val)) ? parseFloat(val) : val;
    if (attr.split('.').length > 1) {
        if (EZmodel.constructor.name === 'EZModelSystem' ||
            EZmodel.constructor.name === 'EZModelArea') {
            oldValue = EZmodel.__data[attr.split('.')[0]][attr.split('.')[1]];
        }
        EZmodel.__data[attr.split('.')[0]][attr.split('.')[1]] = value;
    } else {
        if (EZmodel.constructor.name === 'EZModelSystem' ||
            EZmodel.constructor.name === 'EZModelArea') {
            oldValue = EZmodel.__data[attr];
        }
        EZmodel.__data[attr] = value;
    }
    return [value, oldValue];
};
EZModelSubarea.prototype.watchAttribute = function(attr, eventName) {
    Object.defineProperty(
        (attr.split('.').length > 1) ? this[attr.split('.')[0]] : this,
        (attr.split('.').length > 1) ? attr.split('.')[1] : attr, {
            get: () => {
                switch (attr) {
                    case 'name':
                        return this.getName();
                    default:
                        return ez3dScene.getMethodAttributeValidation(this, attr);
                }
            },
            set: (val) => {
                const value = ez3dScene.setMethodAttributeValidation(this, attr, val);

                ee.addOnceListener('setSubareaAttrValue', function() {
                    if (ez3dScene.isLoadingProject === false) {
                        ee.emitEvent(eventName, [attr, value[0]]);
                    }
                });

                // update
                switch (attr) {
                    case 'disabled':
                        ee.emitEvent('showProgressBar');
                        ee.emitEvent('updateProgressBar', [{
                            title: 'editSubareaValues'
                        }]);
                        this.parent.areaUpdateSystemOperator(value[0])
                            .then(() => {
                                ee.emitEvent('setSubareaAttrValue');
                                ee.emitEvent('hideProgressBar');
                            });
                        break;
                    case 'fromScratch':
                        ee.emitEvent('setSubareaAttrValue');
                        break;
                    case 'offset':
                        ee.emitEvent('showProgressBar');
                        ee.emitEvent('updateProgressBar', [{
                            title: 'editSubareaValues'
                        }]);
                        this.watcherAdjustmentOffsetOperator(value[0], attr)
                            .then(() => {
                                ee.emitEvent('setSubareaAttrValue');
                                ee.emitEvent('hideProgressBar');
                            });
                        break;
                }
            },
            enumerable: true
        });
};

EZModelSubarea.prototype.watcherAdjustmentOffset = function (value, attr) {
    if (Array.isArray(value)) {
        this.__data[attr] = value;
    } else {
        this.__data[attr] = [value];
    }
    this.updatePath();
    this.system.updateGrid(false, false, true);
    this.updateSystem();
    ee.emitEvent('watcherAdjustmentOffsetFunctionFinished');
};

EZModelSystem.prototype.watchAttribute = function(attr, eventName) {
    Object.defineProperty(
        (attr.split('.').length > 1) ? this[attr.split('.')[0]] : this,
        (attr.split('.').length > 1) ? attr.split('.')[1] : attr, {
            get: () => {
                return ez3dScene.getMethodAttributeValidation(this, attr);
            },
            set: (val) => {
                const value = ez3dScene.setMethodAttributeValidation(this, attr, val);
                // exceptions
                this.resetGrid = false;
                const resetLine = true;
                const resetPath = false;
                const movingGrid = false;

                switch (attr.split('.')[0]) {
                    case 'azimuth':
                        this.__data.azimuth = ez3dScene.utils.cleanDegrees(this.azimuth);
                        break;
                    case 'inclination':
                        this.calculateTotalInset();
                        break;
                    case 'inset':
                        this.calculateTotalInset();
                        break;
                    case 'placement':
                        this.watcherAdjustmentPlacement(value[1], value[0]);
                        this.watcherUpdateSails();
                        break;
                    case 'structure':
                        this.watcherAdjustmentStructure(value[1], value[0]);
                        break;
                    case 'totalInset':
                        this.calculateInset();
                        break;
                    case 'sails':
                        this.watcherSailsToggling(attr === 'sails.enabled');
                        this.watcherUpdateSails();
                        break;
                    default:
                        break;
                }

                // update
                ee.emitEvent('showProgressBar');
                ee.emitEvent('updateProgressBar', [{
                    title: 'editSubareaValues'
                }]);
                this.parent.updateSystemGrid([resetLine, this.resetGrid, resetPath, movingGrid])
                    .then(() => {
                        this.parent.updateSystemOperator(true)
                            .then(() => {
                                ee.emitEvent('hideProgressBar');
                                // emitEvent
                                if (ez3dScene.isLoadingProject === false) {
                                    ee.emitEvent(eventName, [attr, value[0]]);
                                }
                            });
                    });
            },
            enumerable: true
        });
};

EZModelSystem.prototype.watcherAdjustmentStructure = function(oldValue, value) {
    if (oldValue !== value) {
        this.__data.azimuth = (value === 'EW') ? ez3dScene.utils.cleanDegrees(this.azimuth + 90) : ez3dScene.utils.cleanDegrees(this.azimuth - 90);
        this.__data.staggered.enabled = false;
        this.resetGrid = true;
    }
};

EZModelSystem.prototype.watcherAdjustmentPlacement = function(oldValue, value) {
    if (oldValue !== value) {
        this.resetGrid = true;
    }
};

/**
 * Method to enable dilatation lines and set default values for faking sails.
 *
 * @param {boolean} haveSailsBeenToggled - have sails been toggled?
 */
EZModelSystem.prototype.watcherSailsToggling = function(haveSailsBeenToggled) {
    if (!haveSailsBeenToggled) return;

    if (this.sails.enabled) {
        // enable hidden dilatation lines
        this.dilatationLines.enabled = this.sails.enabled;

        // disable vertical dilatations, staggered, inset and EW
        this.dilatationLines.row = 0;
        this.staggered.enabled = false;
        this.inset.y = 0;
        this.useShadowsCalculation = false;
        this.structure = 'Standard';

        // initialize corridor width
        this.dilatationLines.w = 0;
    } else {
        var type = this.getAncestor('EZModelRoof').type;
        const defaultInset = ez3dScene.getDefaultModelProperty('subareaByRoofType', type, 'inset');
        this.__data.inset.y = defaultInset.y;
        this.dilatationLines = ez3dScene.getDefaultModelProperty('subareaByRoofType', type, 'dilatationLines');
    }
};
/**
 * Method to set dilatation lines dimensions from sails properties.
 */
EZModelSystem.prototype.watcherUpdateSails = function() {
    if (this.sails.enabled) {
        // calc horizontal dilatationLines
        this.dilatationLines.y = this.sails.levels * this.model[this.placement].y;
        this.dilatationLines.h = this.sails.spacing;
    }
};

EZModelTree.prototype.watchAttribute = function(attr, eventName) {
    Object.defineProperty(
        (attr.split('.').length > 1) ? this[attr.split('.')[0]] : this,
        (attr.split('.').length > 1) ? attr.split('.')[1] : attr, {
            get: () => {
                switch (attr) {
                    case 'name':
                        return this.getName();
                    default:
                        return ez3dScene.getMethodAttributeValidation(this, attr);
                }
            },
            set: (val) => {
                const value = ez3dScene.setMethodAttributeValidation(this, attr, val);
                // exceptions
                switch (attr.split('.')[0]) {
                    case 'crownTopHeight':
                        this.getTreeHeight(value[0], this.__data);
                        break;
                    case 'crownHigherRadius':
                    case 'crownMiddleRadius':
                    case 'crownLowerRadius':
                        this.getTreeRadius(value[0], this.__data, attr.split('.')[0]);
                        break;
                    case 'shape':
                        this.updateOnTreeShapeChange();
                        break;
                    default:
                        break;
                }

                // emitEvent
                if (ez3dScene.isLoadingProject === false) {
                    ee.emitEvent(eventName, [attr, value[0]]);
                }
            },
            enumerable: true
        });
};

