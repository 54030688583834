/**
 * EZApiConstructor
 * @class
 * @classdesc This is a description of the **EZApiConstructor** class.
 *
 * @description This is the constructor of the **EZApiConstructor** class.
 *
 * @param       {EZModelScene} ez3dScene [description]
 */
function EZApiConstructor(ez3dScene) {
    // ez3dScene = ez3dScene;
    this.eventListeners = new initEventListeners();
    this.createGeneralFunctions = _createGeneralFunctions;
    var listenerFunctions = Object.getPrototypeOf(this.eventListeners);

    var listenerFunctionsList = Object.keys(listenerFunctions);
    listenerFunctionsList.forEach((name) => {
        this.eventListeners[name]();
    });
}

EZApiConstructor.prototype.createGeneralEvent = _createGeneralEvent;

function _createGeneralEvent(title, eventName, args) {
    ee.addListener(eventName, function(res) {
        var event = new CustomEvent(title, {
            'detail': args
        });
        self.ez3dMainContainer.dispatchEvent(event);
    });
}

function _createGeneralFunctions(title, functionName) {
    EZApiConstructor.prototype[functionName] = function(arg, callBack) {
        ee.emitEvent(title, [arg]);
        if (callBack) {
            callBack();
        }
    };
}

EZApiConstructor.prototype.saveProject = _saveProject;
EZApiConstructor.prototype.refreshViewport = _refreshViewport;
EZApiConstructor.prototype.sendOperatorFinished = _sendOperatorFinished;

function _saveProject(callback) {
    ez3dScene.saveProject(function(res) {
        if (callback) {
            callback(res);
        }
    });
}

function _refreshViewport() {
    ez3dScene.refreshViewport();
}

function _sendOperatorFinished(response) {
    var event = new CustomEvent(response.event, {
        'detail': response.args[0]
    });
    // console.log('sendOperatorFinished', response.event, response.args[0]);

    const ez3dMainContainer = document.getElementById('ez3d-main-container');
    ez3dMainContainer.dispatchEvent(event);
}

EZApiConstructor.prototype.setCustomPanel = _setCustomPanel;
EZApiConstructor.prototype.setUnits = _setUnits;

/**
 * _setCustomPanel - description
 *
 * @param  {type} customPanelData description
 * @param  {type} callback        description
 */
function _setCustomPanel(customPanelData, callback) {
    ee.emitEvent('setCustomPanel', [customPanelData]);
    if (callback) {
        callback();
    }
}

/**
 * _setUnits - description
 *
 * @param  {type} unitToChange description
 * @param  {type} callback     description
 */
function _setUnits(unitToChange, callback) {
    var units = [{
        name: 'm',
        conversion: 1
    },
    {
        name: 'cm',
        conversion: 100
    },
    {
        name: 'mm',
        conversion: 1000
    },
    {
        name: 'ft',
        conversion: 3.28084
    },
    {
        name: 'in',
        conversion: 39.3701
    }];

    ee.emitEvent('EZModelScene_setUnitsListener', [{
        args: _.findIndex(units, function(unit) {
            return unit.name === unitToChange;
        })
    }]);
    ee.emitEvent('updatePanels');

    if (callback) {
        callback();
    }
}


EZApiConstructor.prototype.getAreaInfo = _getAreaInfo;
EZApiConstructor.prototype.getAreaOffset = _getAreaOffset;

function _getAreaInfo(id, callback) {
    var self = this;
    var area = (typeof id === 'string')
        ? ez3dScene.findById(id) : id;

    if (area.constructor.name !== 'EZModelArea') {
        console.error('This id it is not a valid area id');
        return false;
    }

    var result = _.cloneDeepWith(area,
        function(value, name) {
            switch (name) {
                case 'axis':
                case 'editableAttr':
                case 'ez3dScene':
                case 'flattenOrigin':
                case 'parent':
                case 'path':
                case 'subareas':
                case '__data':
                    return null;
                default:
                    break;
            }
        }
    );

    // PATH
    result.cornerAngles = area.path.cornerAngles;
    result.lineData = area.path.lineData;
    result.lineDataFlatten = area.path.lineDataFlatten;
    result.verticesMCoords = area.path.vertices
        .map(function(vertice) {
            return {
                x: vertice.xF,
                y: vertice.yF,
                ortoX: vertice.x,
                ortoY: vertice.y
            };
        });
    result.wallSizes = area.path.edgeDimensions;
    result.wallAzimuth = area.path.edgeAzimuths;

    // CHILDREN
    result.subareas = area.subareas.map(
        function(subarea) {
            return self.getSubareaInfo(subarea);
        });

    // CLEAN
    result = this.cleanResult(result);
    // CALLBACK
    if (callback) {
        callback(result);
    }
    return result;
}

function _getAreaOffset(id, offset, callback) {
    var result = {};
    var self = this;
    var area = ez3dScene.findById(id);

    if (area.constructor.name !== 'EZModelArea') {
        console.error('This id it is not a valid area id');
        return false;
    }

    var offsetPaths = area.path.getOffsetteds(offset, true)[0];
    result = offsetPaths.vertices
        .map(function(vertice) {
            return {
                x: vertice.x,
                y: vertice.y
            };
        });
    callback(result);
}

EZApiConstructor.prototype.getCurrentBuildingId = _getCurrentBuildingId;
EZApiConstructor.prototype.getLayoutData = _getLayoutData;
EZApiConstructor.prototype.getNumberOfModules = _getNumberOfModules;
EZApiConstructor.prototype.getTotalPower = _getTotalPower;
EZApiConstructor.prototype.getPower = _getPower;
EZApiConstructor.prototype.getBuildingInfo = _getBuildingInfo;
EZApiConstructor.prototype.getRoofsInfo = _getRoofsInfo;
EZApiConstructor.prototype.getBuildingPosition = _getBuildingPosition;

function _getCurrentBuildingId(callback) {
    var self = this;
    callback(ez3dScene.activeBuilding.id);
}

function _getLayoutData(callback) {
    var result = [];
    var self = this;
    var buildings = ez3dScene.buildings;

    result = buildings.map(function(building) {
        return self.getBuildingInfo(building);
    });

    if (callback) {
        callback(result);
    }
    return result;
}

/**
 * [_getBuildingInfo description]
 * @param       {[type]}   id       [description]
 * @param       {Function} callback [description]
 * @constructor
 * @return      {[type]}            [description]
 */
function _getBuildingInfo(id, callback) {
    var self = this;
    var building = (typeof id === 'string')
        ? ez3dScene.findById(id) : id;

    if (building.constructor.name !== 'EZModelBuilding') {
        console.error('This id it is not a valid building id');
        return false;
    }

    var result = _.cloneDeepWith(building,
        function(value, name) {
            switch (name) {
                case 'areas':
                case 'editableAttr':
                case 'ez3dScene':
                case 'index':
                case 'keepouts':
                case 'parent':
                case 'roofs':
                case 'structures':
                case '__data':
                    return null;
                default:
                    break;
            }
        }
    );
    if (building.path) {
        // PATH
        result.centerDeg = {
            lat: building.path.center.lat,
            lng: building.path.center.lng
        };
        result.centerMCoords = {
            x: building.path.center.x,
            y: building.path.center.y
        };
        result.verticesMCoords = building.path.vertices
            .map(function(vertice) {
                return {
                    x: vertice.x,
                    y: vertice.y
                };
            });
        result.buildingArea = building.path.getSurface();
    }

    // CHILDREN
    result.roofs = building.roofs.map(function(roof) {
        return self.getRoofsInfo(roof);
    });

    delete result.path;

    result.keepouts = building.keepouts.map(
        function(keepout) {
            var keepoutToReturn = _.cloneDeepWith(keepout,
                function(value, name) {
                    switch (name) {
                        case 'crop':
                        case 'editableAttr':
                        case 'ez3dScene':
                        case 'index':
                        case 'isCreated':
                        case 'isEditing':
                        case 'multiAreas':
                        case 'parent':
                        case 'path':
                        case '__data':
                            return null;
                        default:
                            break;
                    }
                }
            );

            keepoutToReturn.verticesMCoords = keepout.path.vertices
                .map(function(vertice) {
                    return {
                        x: vertice.x,
                        y: vertice.y
                    };
                });

            keepoutToReturn.center = {};
            keepoutToReturn.center.x = keepout.path.x;
            keepoutToReturn.center.y = keepout.path.y;

            keepoutToReturn.wallSizes = keepout.path.edgeDimensions;
            keepoutToReturn.wallAzimuth = keepout.path.edgeAzimuths;

            return keepoutToReturn;
        });

    // CLEAN
    result = this.cleanResult(result);
    // CALLBACK
    if (callback) {
        callback(result);
    }
    return result;
}

function _getRoofsInfo(id, callback) {
    var self = this;
    var roof = (typeof id === 'string')
        ? ez3dScene.findById(id) : id;

    if (roof.constructor.name !== 'EZModelRoof') {
        console.error('This id it is not a valid roof id');
        return false;
    }

    var result = _.cloneDeepWith(roof,
        function(value, name) {
            switch (name) {
            case 'areas':
            case 'baseHeight':
            case 'disabled':
            case 'editableAttr':
            case 'ez3dScene':
            case 'index':
            case 'isCreated':
            case 'isEditing':
            case 'parent':
            case 'path':
            case 'roofPoints':
            case '__data':
                return null;
            default:
                break;
            }
        }
    );

    // CHILDREN
    result.areas = roof.areas.map(function(area) {
        return self.getAreaInfo(area);
    });

    // CLEAN
    result = this.cleanResult(result);
    // CALLBACK
    if (callback) {
        callback(result);
    }
    return result;
}

function _getBuildingPosition(id, callback) {
    var result = {};
    var self = this;
    var building = ez3dScene.findById(id);

    if (building.constructor.name !== 'EZModelBuilding') {
        console.error('This id it is not a valid building id');
        return false;
    }

    result.centerDeg = {};
    result.centerDeg.lat = building.path.center.lat;
    result.centerDeg.lng = building.path.center.lng;
    result.centerMCoords = {};
    result.centerMCoords.x = building.path.center.x;
    result.centerMCoords.y = building.path.center.y;
    result.verticesMCoords = building.path.vertices
        .map(function(vertice) {
            var verticesToReturn = {};
            verticesToReturn.x = vertice.x;
            verticesToReturn.y = vertice.y;
            return verticesToReturn;
        });
    callback(result);
}

function _getNumberOfModules(callback) {
    var result = [];
    var self = this;
    var buildings = ez3dScene.buildings;

    result = buildings.map(
        function(building) {
            var buildingToReturn = {};
            buildingToReturn.id = building.id;
            buildingToReturn.name = building.name;
            buildingToReturn.modules = building.systemInfo.modules;
            buildingToReturn.areas = building.getAreas().map(
                function(area) {
                    var areaToReturn = {};
                    areaToReturn.id = area.id;
                    areaToReturn.name = area.name;
                    areaToReturn.modules = area.systemInfo.modules;
                    areaToReturn.subareas = area.subareas.map(
                        function(subarea) {
                            var subareaToReturn = {};
                            subareaToReturn.id = subarea.id;
                            subareaToReturn.name = subarea.name;
                            subareaToReturn.modules = subarea.systemInfo.modules;
                            return subareaToReturn;
                        });
                    return areaToReturn;
                });
            return buildingToReturn;
        });

    callback(result);
}

function _getTotalPower(callback) {
    var result = 0;
    var self = this;
    result = ez3dScene.buildings.reduce(
        function(acc, building) {
            return acc + building.systemInfo.power;
        }, 0);
    return result;
}

function _getPower(callback) {
    var result = [];
    var self = this;
    var buildings = ez3dScene.buildings;
    result = buildings.map(
        function(building) {
            var buildingToReturn = {};
            buildingToReturn.id = building.id;
            buildingToReturn.name = building.name;
            buildingToReturn.power = building.systemInfo.power;
            buildingToReturn.areas = building.getAreas().map(
                function(area) {
                    var areaToReturn = {};
                    areaToReturn.id = area.id;
                    areaToReturn.name = area.name;
                    areaToReturn.power = area.systemInfo.power;
                    areaToReturn.subareas = area.subareas.map(
                        function(subarea) {
                            var subareaToReturn = {};
                            subareaToReturn.id = subarea.id;
                            subareaToReturn.name = subarea.name;
                            subareaToReturn.power = subarea.systemInfo.power;
                            return subareaToReturn;
                        });
                    return areaToReturn;
                });
            return buildingToReturn;
        });

    callback(result);
}

/**
 * [initEventListeners description]
 */
function initEventListeners() {
    this.ez3dMainContainer = document.getElementById('ez3d-main-container');
}

initEventListeners.prototype.initListenerActiveChanged = function() {
    ee.addListener('activeChanged', (response, data) => {
        let event;
        let eventDeprecate;
        switch (data.constructor.name) {
            case 'EZModelBuilding':
                event = new CustomEvent('activeBuilding', {
                    'detail': data.id
                });
                this.ez3dMainContainer.dispatchEvent(event);

                // @TODO DEPRECATE
                eventDeprecate = new CustomEvent('editBuilding', {
                    'detail': data.id
                });
                this.ez3dMainContainer.dispatchEvent(eventDeprecate);
                break;
            case 'EZModelArea':
                event = new CustomEvent('activeArea', {
                    'detail': data.id
                });
                this.ez3dMainContainer.dispatchEvent(event);

                event = new CustomEvent('resetSubarea', {
                    'detail': data.id
                });
                this.ez3dMainContainer.dispatchEvent(event);

                // @TODO DEPRECATE
                eventDeprecate = new CustomEvent('editArea', {
                    'detail': data.id
                });
                this.ez3dMainContainer.dispatchEvent(eventDeprecate);
                break;
            case 'EZModelSubarea':
                event = new CustomEvent('activeSubarea', {
                    'detail': data.id
                });
                this.ez3dMainContainer.dispatchEvent(event);

                // @TODO DEPRECATE
                eventDeprecate = new CustomEvent('editSubarea', {
                    'detail': data.id
                });
                this.ez3dMainContainer.dispatchEvent(eventDeprecate);
                break;
            case 'EZModelTree':
                event = new CustomEvent('activeTree', {
                    'detail': data.id
                });
                this.ez3dMainContainer.dispatchEvent(event);

                // @TODO DEPRECATE
                eventDeprecate = new CustomEvent('editTree', {
                    'detail': data.id
                });
                this.ez3dMainContainer.dispatchEvent(eventDeprecate);
                break;
            case 'EZModelKeepout':
                event = new CustomEvent('activeKeepout', {
                    'detail': data.id
                });
                this.ez3dMainContainer.dispatchEvent(event);

                // @TODO DEPRECATE
                eventDeprecate = new CustomEvent('editKeepout', {
                    'detail': data.id
                });
                this.ez3dMainContainer.dispatchEvent(eventDeprecate);
                break;
            default:
        }
    });
};

initEventListeners.prototype.initListenerFinishCreation = function() {
    ee.addListener('finishCreation', (response) => {
        let event;
        switch (response.args.constructor.name) {
            case 'EZModelBuilding':
                event = new CustomEvent('buildingCreated', {
                    'detail': response.args.id
                });
                break;
            case 'EZModelRoof':
                event = new CustomEvent('roofCreated', {
                    'detail': response.args.id
                });
                break;
            case 'EZModelSubarea':
                event = new CustomEvent('subareaCreated', {
                    'detail': response.args.id
                });
                break;
            case 'EZModelKeepout':
                event = new CustomEvent('keepoutCreated', {
                    'detail': response.args.id
                });
                break;
            default:
        }
        this.ez3dMainContainer.dispatchEvent(event);
    });
};

initEventListeners.prototype.initListenerEditPoints = function() {
    ee.addListener('EZModelBuilding_editListener', () => {
        var event = new CustomEvent('editVertices');
        this.ez3dMainContainer.dispatchEvent(event);
    });

    ee.addListener('setActive_Finished', (response) => {
        if (response[2] && response[2].constructor.name === 'EZModelRoof') {
            var event = new CustomEvent('editRoof');
            this.ez3dMainContainer.dispatchEvent(event);
        }
    });

    ee.addListener('finishRoofEditOperator', () => {
        var event = new CustomEvent('editRoofFinished');
        this.ez3dMainContainer.dispatchEvent(event);
    });
};

initEventListeners.prototype.closePanel = function() {
    ee.addListener('closePanel', (response) => {
        let event;
        switch (response.args.constructor.name) {
            case 'EZModelSubarea':
                event = new CustomEvent('editSubareaFinished', {
                    'detail': response.args.id
                });
                break;
            default:
        }
        this.ez3dMainContainer.dispatchEvent(event);
    });
};

initEventListeners.prototype.initListenerSetAttributeChanged = function() {
    let event;
    ee.addListener('setAttribute_Finished', (response) => {
        var objectId = response[0];
        var object = response[2];
        var attributeChanged = response[3];

        switch (response[1]) {
            case 'EZModelBuilding':
                event = new CustomEvent('buildingChanged', {
                    'detail': [objectId, object[attributeChanged],
                        attributeChanged, ez3dScene.context]
                });
                break;
            case 'EZModelArea':
                event = new CustomEvent('areaChanged', {
                    'detail': [objectId, object[attributeChanged],
                        attributeChanged, ez3dScene.context]
                });
                break;
            case 'EZModelSubarea':
                event = new CustomEvent('subareaChanged', {
                    'detail': [objectId, object[attributeChanged],
                        attributeChanged, ez3dScene.context]
                });
                break;
            case 'EZModelTree':
                event = new CustomEvent('treeChanged', {
                    'detail': [objectId, object[attributeChanged],
                        attributeChanged, ez3dScene.context]
                });
                break;
            case 'EZModelKeepout':
                event = new CustomEvent('keepoutChanged', {
                    'detail': [objectId, object[attributeChanged],
                        attributeChanged, ez3dScene.context]
                });
                break;
            case 'EZModelRoof':
                event = new CustomEvent('roofChanged', {
                    'detail': [objectId, object[attributeChanged],
                        attributeChanged, ez3dScene.context]
                });
                break;
            case 'EZModelScene':
                if (attributeChanged.split('.').length > 1) {
                    event = new CustomEvent('sceneChanged', {
                        'detail': ['EZModelScene', ez3dScene[
                            attributeChanged.split('.')[0]][attributeChanged.split('.')[1]],
                        attributeChanged]
                    });
                } else {
                    event = new CustomEvent('sceneChanged', {
                        'detail': ['EZModelScene', ez3dScene[
                            attributeChanged], attributeChanged]
                    });
                }
                break;
            default:
        }
        if (event) {
            this.ez3dMainContainer.dispatchEvent(event);
        }
    });

    ee.addListener('EZModelSubarea_editSystemListener', () => {
        event = new CustomEvent('subareaChanged', {
            'detail': [ez3dScene.active.id, [], 'modules']
        });

        this.ez3dMainContainer.dispatchEvent(event);
    });
};

// TODO RESUME SELF CLEANING

initEventListeners.prototype.initListenerDeleteObject = function() {
    var self = this;
    var event;
    ee.addListener('delete_Finished', function(response) {
        switch (response[1]) {
            case 'EZModelBuilding':
                event = new CustomEvent('buildingRemoved', {
                    'detail': response[0]
                });
                break;
            case 'EZModelArea':
                event = new CustomEvent('areaRemoved', {
                    'detail': response[0]
                });
                break;
            case 'EZModelSubarea':
                event = new CustomEvent('subareaRemoved', {
                    'detail': response[0]
                });
                break;
            case 'EZModelTree':
                event = new CustomEvent('treeRemoved', {
                    'detail': response[0]
                });
                break;
            case 'EZModelKeepout':
                event = new CustomEvent('keepoutRemoved', {
                    'detail': response[0]
                });
                break;
            default:
                event = undefined;
                console.warn('(Sentry) args: ' + response);
        }
        if (event !== undefined) {
            self.ez3dMainContainer.dispatchEvent(event);
        }
    });
};

initEventListeners.prototype.initListenerChangeTab = function() {
    var self = this;
    ee.addListener('tabChanged', function(res) {
        var event;
        switch (res.args) {
            case 'ez3d-building-info-tab':
                event = new CustomEvent('tabChangedApi', {
                    'detail': 'building'
                });
                break;
            case 'ez3d-areas-info-tab':
                event = new CustomEvent('tabChangedApi', {
                    'detail': 'areas'
                });
                break;
            case 'ez3d-objects-tab':
                event = new CustomEvent('tabChangedApi', {
                    'detail': 'objects'
                });
                break;
            case 'ez3d-keepout-info-tab':
                event = new CustomEvent('tabChangedApi', {
                    'detail': 'keepout'
                });
                break;
            case 'ez3d-tree-info-tab':
                event = new CustomEvent('tabChangedApi', {
                    'detail': 'tree'
                });
                break;
            case 'ez3d-preferences-tab':
                event = new CustomEvent('tabChangedApi', {
                    'detail': 'preferences'
                });
                break;
            case 'ez3d-map-info-tab':
                event = new CustomEvent('tabChangedApi', {
                    'detail': 'map'
                });
                break;
            default:
                event = undefined;
                console.warn('(Sentry) args: ' + res.args);
        }
        if (event) {
            self.ez3dMainContainer.dispatchEvent(event);
        }
    });
};

initEventListeners.prototype.initListenerSaveProject = function() {
    var self = this;
    ee.addListener('projectSaved', function() {
        var event;
        if (ez3dScene.layoutRules.scenePreferences.snapShotCrm === true) {
            var args = {
                'detail': ez3dScene.snapshot.data.image
            };
            if (ez3dScene.projectCenterChanged === true) {
                args.projectCenter = {
                    'lat': ez3dScene.projectCenter.lat,
                    'lng': ez3dScene.projectCenter.lng
                }
            }
            event = new CustomEvent('layoutProjectSaved', args);
        } else {
            event = new CustomEvent('layoutProjectSaved');
        }
        self.ez3dMainContainer.dispatchEvent(event);
        ee.emitEvent('saveApiEvent-finished');
    });
};

initEventListeners.prototype.initListenerClone = function() {
    var self = this;

    ee.addListener('clone_Finished', function() {
        var activeObject = ez3dScene.active;
        var event;
        switch (activeObject.constructor.name) {
            case 'EZModelBuilding':
                event = new CustomEvent('buildingCloned', {
                    'detail': activeObject.id
                });
                break;
            case 'EZModelArea':
                event = new CustomEvent('areaCloned', {
                    'detail': activeObject.id
                });
                break;
            case 'EZModelSubarea':
                event = new CustomEvent('subareaCloned', {
                    'detail': activeObject.id
                });
                break;
            case 'EZModelTree':
                event = new CustomEvent('treeCloned', {
                    'detail': activeObject.id
                });
                break;
            case 'EZModelKeepout':
                event = new CustomEvent('keepoutCloned', {
                    'detail': activeObject.id
                });
                break;
            default:
        }
        self.ez3dMainContainer.dispatchEvent(event);
    });

    ee.addListener('cloneSubarea_Finished', function(res) {
        var activeObject = ez3dScene.active;
        var event = new CustomEvent('subareaCloned', {
            'detail': res[0]
        });
        self.ez3dMainContainer.dispatchEvent(event);
    });
};

initEventListeners.prototype.initListenerMovePoints = function() {
    var self = this;
    ee.addListener('EZModelLocation_moveListener', function() {
        var event = new CustomEvent('locationChanged');
        self.ez3dMainContainer.dispatchEvent(event);
    });
};

initEventListeners.prototype.initListenerEditSubarea = function() {
    var self = this;
    ee.addListener('editSubarea_Finished', function() {
        var event = new CustomEvent('editSubareaPath', {
            'detail': [ez3dScene.active.id]
        });
        self.ez3dMainContainer.dispatchEvent(event);
    });
};

initEventListeners.prototype.initListenerMoveSubarea = function() {
    var self = this;
    ee.addListener('moveSubarea_Finished', function() {
        var event = new CustomEvent('subareaMoved', {
            'detail': [ez3dScene.active.id]
        });
        self.ez3dMainContainer.dispatchEvent(event);
    });

    ee.addListener('moveSystem_Finished', function() {
        var event = new CustomEvent('modulesMoved', {
            'detail': [ez3dScene.active.id]
        });
        self.ez3dMainContainer.dispatchEvent(event);
    });
};

initEventListeners.prototype.initListenerDeleteSubarea = function() {
    var self = this;
    ee.addListener('deleteSubarea_Finished', function() {
        var event = new CustomEvent('subareaRemoved', {
            'detail': [ez3dScene.active.id]
        });
        self.ez3dMainContainer.dispatchEvent(event);
    });
};

initEventListeners.prototype.initListenerFullscreen = function() {
    var self = this;
    ee.addListener('ez3d-fullscreen-disabled', function() {
        var event = new CustomEvent('ez3d-fullscreen-disabled');
        self.ez3dMainContainer.dispatchEvent(event);
    });
    ee.addListener('ez3d-fullscreen-enabled', function() {
        var event = new CustomEvent('ez3d-fullscreen-enabled');
        self.ez3dMainContainer.dispatchEvent(event);
    });
};

initEventListeners.prototype.initListenerLockInterface = function() {
    var self = this;
    ee.addListener('sendLockInterface', function(val) {
        var event = new CustomEvent('lockInterface', {
            'detail': val
        });
        self.ez3dMainContainer.dispatchEvent(event);
    });
};

initEventListeners.prototype.initListenerInvalidOffset = function() {
    var self = this;
    ee.addListener('invalidOffset', function(response) {
        var event = new CustomEvent('invalidOffset', {
            'detail': response.args.id
        });
        self.ez3dMainContainer.dispatchEvent(event);
    });
};

initEventListeners.prototype.initListenerUndoRedoPanels = function() {
    var self = this;
    ee.addListener('changePanelOnUndoRedo', function(panelName) {
        var event = new CustomEvent('changePanelOnUndoRedo', {
            'detail': panelName
        });
        self.ez3dMainContainer.dispatchEvent(event);
    });
};

initEventListeners.prototype.initListenerUndoRedoExecuted = function() {
    var self = this;
    ee.addListener('executedUndoRedo', function() {
        var event = new CustomEvent('executedUndoRedo');
        self.ez3dMainContainer.dispatchEvent(event);
    });
};

initEventListeners.prototype.notifyModuleNotFound = function() {
    var self = this;
    ee.addListener('moduleNotFound', function(panelName) {
        var event = new CustomEvent('moduleNotFound', {
            'detail': panelName
        });
        self.ez3dMainContainer.dispatchEvent(event);
    });
};

EZApiConstructor.prototype.setAttribute = _setAttribute;
EZApiConstructor.prototype.setActive = _setActive;
EZApiConstructor.prototype.disabledMap = _disabledMap;
EZApiConstructor.prototype.cleanResult = _cleanResult;
EZApiConstructor.prototype.updateRender = _updateRender;
/**
 * _setAttribute - description
 *
 * @param  {type} objectId description
 * @param  {type} attr     description
 * @param  {type} value    description
 * @param  {type} callback description
 */
function _setAttribute(objectId, attr, value, callback) {
    ee.addOnceListener('setAttribute_Finished', function(response) {
        callback(response);
    });
    ee.emitEvent('EZModelScene_setAttributeListener', [{
        args: [objectId, attr, value],
        updateRender: {draw2d: true, draw3d: objectId, updatePanels: true, fitRange: false},
        undo: false,
        notifications: true
    }]);
}

/**
 * [_updateRender description]
 */
function _updateRender() {
    ee.emitEvent('updateRender',
        [{draw2d: true, draw3d: true, updatePanels: true, fitRange: true}]);
}

/**
 * _setActive - description
 *
 * @param  {type} objectId description
 * @param  {type} callback description
 */
function _setActive(objectId, callback) {
    ee.emitEvent('EZModelScene_setActiveListener', [{
        args: objectId,
        undo: false,
        updateRender: {draw2d: true, draw3d: false, updatePanels: true, fitRange: true}}]);
    ee.addOnceListener('setActive_Finished', function(response) {
        callback(response);
    });
}

/**
 * _disabledMap - description
 *
 * @param  {type} callback description
 */
function _disabledMap(callback) {
    ez3dViewport.ez3dPlayer.activateMapper(false);
    if (callback) {
        callback();
    }
}

function _cleanResult(list) {
    Object.keys(list).forEach(function(key) {
        if (list[key] === null) {
            delete list[key];
        }
    });
    return list;
}

EZApiConstructor.prototype.getSubareaInfo = _getSubareaInfo;
EZApiConstructor.prototype.getModuleInfoBySubarea = _getModuleInfoBySubarea;
EZApiConstructor.prototype.getModulesStructureBySubarea = _getModulesStructureBySubarea;

function _getSubareaInfo(id, callback) {
    var self = this;
    var subarea = (typeof id === 'string')
        ? ez3dScene.findById(id) : id;

    if (subarea.constructor.name !== 'EZModelSubarea') {
        console.error('This id it is not a valid subarea id');
        return false;
    }

    var result = _.cloneDeepWith(subarea,
        function(value, name) {
            switch (name) {
            case 'editableAttr':
            case 'ez3dScene':
            case 'fromScratch':
            case 'index':
            case 'isCreated':
            case 'isEditing':
            case 'parent':
            case 'path':
            case 'system':
            case '__data':
                return null;
            default:
                break;
            }
        }
    );

    // PATH
    result.cornerAngles = subarea.path.cornerAngles;
    result.lineData = subarea.path.lineData;
    result.lineDataFlatten = subarea.path.lineDataFlatten;
    result.surface = subarea.path.getSurface();
    result.verticesMCoords = subarea.path.vertices
        .map(function(vertice) {
            return {
                x: vertice.xF,
                y: vertice.yF,
                ortoX: vertice.x,
                ortoY: vertice.y
            };
        });
    result.wallSizes = subarea.path.edgeDimensions;
    result.wallAzimuth = subarea.path.edgeAzimuths;
    if (subarea.path.offsettedPaths) {
        result.offsettedPaths = subarea.path.offsettedPaths
            .map(function(offsetted) {
                return {
                    cornerAngles: offsetted.cornerAngles,
                    lineData: offsetted.lineData,
                    lineDataFlatten: offsetted.lineDataFlatten,
                    verticesMCoords: offsetted.vertices
                        .map(function(vertice) {
                            return {
                                x: vertice.xF,
                                y: vertice.yF,
                                ortoX: vertice.x,
                                ortoY: vertice.y
                            };
                        }),
                    wallSizes: offsetted.edgeDimensions,
                    wallAzimuth: offsetted.edgeAzimuths
                };
            });
    }

    // CHILDREN
    result.system = _.cloneDeepWith(subarea.system,
        function(value, name) {
            switch (name) {
            case 'area':
            case 'index':
            case 'subareas':
            case 'keepouts':
            case 'editableAttr':
            case 'ez3dScene':
            case 'fromScratch':
            case 'id':
            case 'modulesBox':
            case 'parent':
            case 'path':
            case 'testing':
            case '__data':
                return null;
            default:
                break;
            }
        }
    );

    // CLEAN
    result.system = this.cleanResult(result.system);
    result = this.cleanResult(result);

    // CALLBACK
    if (callback) {
        callback(result);
    }
    return result;
}

function _getModuleInfoBySubarea(id, callback) {
    var subarea = ez3dScene.findById(id);
    var result = {};

    if (subarea.constructor.name !== 'EZModelSubarea') {
        console.error('This id it is not a valid subarea id');
        return false;
    }

    result.id = subarea.system.model.id;
    result.name = subarea.system.model.name;
    result.reference = subarea.system.model.reference;
    result.width = subarea.system.model.width;
    result.height = subarea.system.model.height;
    result.length = subarea.system.model.length;
    result.power = subarea.system.model.power;

    callback(result);
}

function _getModulesStructureBySubarea(id, callback) {
    var subarea = ez3dScene.findById(id);
    var result = [];

    if (subarea.constructor.name !== 'EZModelSubarea') {
        console.error('This id it is not a valid subarea id');
        return false;
    }

    var rows = subarea.system.grid.rows.map(function(row) {
        return row.modules.filter(function(module) {
            return module.type === 'module';
        }).map(function(module) {
            var res = {};

            res.x = module.x;
            res.y = module.y;
            res.row = module.row;
            res.col = module.col;
            res.rX = subarea.system.inclination;
            res.rY = parseFloat(subarea.system.azimuth);

            return res;
        });
    });
    result = rows.reduce(
        function(acc, row) {
            return acc.concat(row);
        }, []);

    return result;
}

EZApiConstructor.prototype.changeTab = _changeTab;
EZApiConstructor.prototype.getLockedInterface = _getLockedInterface;

function _changeTab(tab, callback) {
    switch (tab) {
        case 'building':
            ee.emitEvent('tabChanged', [{args: 'ez3d-building-info-tab'}]);
            break;
        case 'areas':
            ee.emitEvent('tabChanged', [{args: 'ez3d-areas-info-tab'}]);
            break;
        case 'objects':
            ee.emitEvent('tabChanged', [{args: 'ez3d-objects-tab'}]);
            break;
        case 'preferences':
            ee.emitEvent('tabChanged', [{args: 'ez3d-preferences-tab'}]);
            break;
        case 'map':
            ee.emitEvent('tabChanged', [{args: 'ez3d-map-info-tab'}]);
            break;
        default:
            break;
    }
    if (callback) {
        callback(tab);
    }
}

function _getLockedInterface(callback) {
    var lockedInterface = ez3dScene.layoutInstance.ez3dViewport.lockedInterface;
    if (callback) {
        callback(lockedInterface);
    }
}

