/**
 * @class EZModelUtils
 * @classdesc This is a description of the **EZModelUtils** class.
 *
 * @description This is the constructor of the **EZModelUtils** class.
 *
 * @param {EZModelScene} ez3dScene  - Main container for 3DLayout project model.
 */
const EZModelUtils = {};

EZModelUtils.transformAtan2ToAzimuthSpace = function(angle) {
    angle = (angle + 90) * -1;
    return EZModelUtils.cleanDegrees(angle);
};

EZModelUtils.getCardinalPoints = function(angle, facing) {
    var orientation = '-';
    if (facing === true) {
        angle -= 90;
        angle = EZModelUtils.cleanDegrees(angle);
    }
    if (angle > -45 && angle <= 45) {
        // N
        orientation = 'N';
    } else if (angle > 45 && angle <= 135) {
        // E
        orientation = 'E';
    } else if ((angle > 135 && angle <= 180) ||
        (angle < -135 && angle >= -180)) {
        // S
        orientation = 'S';
    } else if (angle > -135 && angle <= -45) {
        // W
        orientation = 'W';
    }

    return orientation;
};

EZModelUtils.calculateBearing = function(point1, point2) {
    var bearing = geolib.getRhumbLineBearing(
        {latitude: point1.lat, longitude: point1.lng},
        {latitude: point2.lat, longitude: point2.lng}
    );

    return bearing;
};

EZModelUtils.calculateDistance = function(point1, point2) {
    var distance = geolib.getDistance(
        {latitude: point1.lat, longitude: point1.lng},
        {latitude: point2.lat, longitude: point2.lng}, 0, 4
    );

    return distance;
};

EZModelUtils.convertSphericalToCartesian = function(point, sphericalOrigin) {
    var distance = EZModelUtils.calculateDistance(point, sphericalOrigin);
    var bearing = EZModelUtils.calculateBearing(point, sphericalOrigin);

    var cartesian = EZModelUtils.calculateCartesian(distance, bearing);

    return cartesian;
};

EZModelUtils.calcCartesianDistance = function(point1, point2) {
    if (point2 === undefined) {
        point2 = {
            x: 0.0,
            y: 0.0
        };
    }

    var dx = point2.x - point1.x;
    var dy = point2.y - point1.y;

    return Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2));
};

EZModelUtils.calculateCartesian = function(distance, bearing) {
    var vector = {
        x: -distance * Math.sin(EZModelUtils.degToRad(bearing + 360)),
        y: distance * Math.cos(EZModelUtils.degToRad(bearing + 360))
    };

    return vector;
};

/**
 * [translateSphericalByCartesianOffset description]
 * @param  {EZModelLocation} initialLocation [EZModelLocation]
 * @param  {object} lastPosition    [cartesian object {x: x, y: y}]
 * @param {object} initialCartesian [description]
 * @return {object}                 [new object {lat: lat, lng: lng}]
 */
EZModelUtils.translateSphericalByCartesianOffset = function(initialLocation, lastPosition, initialCartesian) {
    var initialPoint = {
        lat: initialLocation.lat,
        lon: initialLocation.lng
    };
    if (!initialCartesian) {
        initialCartesian = {
            x: initialLocation.x,
            y: initialLocation.y
        };
    }
    var distance = EZModelUtils.calcCartesianDistance(lastPosition, initialCartesian);
    var bearing = EZModelUtils.cleanDegrees(EZModelUtils.radToDeg(Math.atan2(initialCartesian.y - lastPosition.y, initialCartesian.x - lastPosition.x)) - 90);
    var newPos = geolib.computeDestinationPoint(initialPoint, distance, bearing);

    newPos = {
        lat: newPos.latitude,
        lng: newPos.longitude
    };

    if (distance > 20) {
        const newInitialPoint = {
            lat: newPos.lat,
            lng: newPos.lng,
            ez3dScene: ez3dScene,
        };
        initialCartesian = EZModelUtils.convertSphericalToCartesian(newPos, ez3dScene.projectCenter);
        newPos = EZModelUtils.translateSphericalByCartesianOffset(newInitialPoint, lastPosition, initialCartesian);
    }

    return newPos;
};

EZModelUtils.convertCartesianToSpherical = function(cartesian, sphericalOrigin) {
    return EZModelUtils.translateSphericalByCartesianOffset(sphericalOrigin, cartesian, sphericalOrigin);
};

EZModelUtils.fixCartesianToSphericalPrecision = function(newLoc, cartesian) {
    var newPos = EZModelUtils.translateSphericalByCartesianOffset(newLoc, cartesian);

    var deltaSpherical = {
        lat: newPos.lat - newLoc.lat,
        lng: newPos.lng - newLoc.lng
    };

    newLoc.lat += deltaSpherical.lat;
    newLoc.lng += deltaSpherical.lng;

    return newLoc;
};

/**
 * point rotation from origin (in atan2 space - CW positive / CCW negative)
 *
 * same function as rotatePoint but with different angle space
 *
 * @param  {[object]} point cartesian point to be rotated
 * @param  {float} theta rotation angle in radians
 * @return {object}       cartesian point
 */
EZModelUtils.changeAxis = function(point, theta) {
    var x = point.x;
    var y = point.y;
    var alpha = Math.atan2(y, x) - theta;
    var r = Math.hypot(x, y);
    var x1 = r * Math.cos(alpha);
    var y1 = r * Math.sin(alpha);
    return {x: x1, y: y1};
};

/**
 * point rotation from origin (in azimuth space - CW negative / CCW positive)
 *
 * same function as changeAxis but with different angle space
 *
 * @param  {[object]} point cartesian point to be rotated
 * @param  {float} theta rotation angle in radians
 * @return {object}       cartesian point
 */
EZModelUtils.rotatePoint = function(point, theta) {
    var x = point.x;
    var y = point.y;
    var x1 = x * Math.cos(theta) - y * Math.sin(theta);
    var y1 = x * Math.sin(theta) + y * Math.cos(theta);
    return {x: x1, y: y1};
};

/**
 * bentleyOttmannIntersection https://bl.ocks.org/1wheel/464141fe9b940153e636
 * @param  {[type]} points [description]
 * @return {[type]}        [description]
 */
EZModelUtils.bentleyOttmannIntersection = function(points) {
    // creates new point
    function newPoint(x, y) {
        var rv;
        if (x.map) {
            rv = {x: x[0], y: x[1]};
        } else {
            rv = {x: x, y: y};
        }
        rv.type = 'point';
        return rv;
    }

    function clone(d) {
        var val;
        if (d.type === 'point') {
            val = newPoint(d.x, d.y);
        }
        return val;
    }

    // dist
    function distP(a, b) {
        return Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2));
    }

    // http://stackoverflow.com/questions/849211/shortest-distance-between-a-point-and-a-line-segment
    // @todo clean up
    function distLine(a, b, p) {
        function sqr(x) {
            return x * x;
        }
        function dist2(v, w) {
            return sqr(v.x - w.x) + sqr(v.y - w.y);
        }
        function distToSegmentSquared(p, v, w) {
            var l2 = dist2(v, w);
            if (l2 === 0) return dist2(p, v);
            var t = ((p.x - v.x) * (w.x - v.x) + (p.y - v.y) * (w.y - v.y)) / l2;
            if (t < 0) return dist2(p, v);
            if (t > 1) return dist2(p, w);
            var val = dist2(p, {
                x: v.x + t * (w.x - v.x),
                y: v.y + t * (w.y - v.y)
            });
            return val;
        }
        function distToSegment(p, v, w) {
            return Math.sqrt(distToSegmentSquared(p, v, w));
        }
        return distToSegment(p, a, b);
    }

    function calcAngle(a, b, c) {
        var v1 = [b.x - a.x, b.y - a.y];
        var v2 = [c.x - b.x, c.y - b.y];

        var dot = v1[0] * v2[0] + v1[1] * v2[1];

        var ab = distP(a, b);
        var bc = distP(b, c);
        var ca = distP(c, a);

        // *180/Math.PI
        // return Math.acos((bc*bc + ab*ab - ca*ca)/(2*bc*ab))*180/Math.PI
        return Math.acos((bc * bc + ab * ab - ca * ca) / (2 * bc * ab));
    }

    // intersection between lines connect points [a, b] and [c, d]
    function intersection(a, b, c, d) {
        var det = (a.x - b.x) * (c.y - d.y) - (a.y - b.y) * (c.x - d.x);

        var l = a.x * b.y - a.y * b.x;
        var m = c.x * d.y - c.y * d.x;

        var ix = (l * (c.x - d.x) - m * (a.x - b.x)) / det;
        var iy = (l * (c.y - d.y) - m * (a.y - b.y)) / det;
        var i = newPoint(ix, iy);

        i.isOverlap = (ix === a.x && iy === a.y) || (ix === b.x && iy === b.y);

        i.isIntersection = !(a.x < ix ^ ix < b.x) && !(c.x < ix ^ ix < d.x) && !i.isOverlap && det;

        return i;
    }

    function isLeft(a, b, c) {
        return (b.x - a.x) * (c.y - a.y) - (b.y - a.y) * (c.x - a.x) > 0;
    }

    // http://stackoverflow.com/questions/2049582/how-to-determine-a-point-in-a-2d-triangle
    function triangleContains(a, b, c, p) {
        var b1 = isLeft(p, a, b);
        var b2 = isLeft(p, b, c);
        var b3 = isLeft(p, c, a);

        return (b1 === b2) && (b2 === b3);
    }

    function lineXatY(l, y) {
        var a = l[0];
        var b = l[1];
        var m = (a.y - b.y) / (a.x - b.x);

        return (y - a.y + m * a.x) / m;
    }

    function toPathStr(d) {
        return 'M' + d.join('L');
    }

    function negFn(d) {
        return !d;
    }

    function clamp(a, b, c) {
        return Math.max(a, Math.min(b, c));
    }

    function pairs(array) {
        var rv = [];
        array.forEach(function(d, i) {
            for (var j = i + 1; j < array.length; j++) rv.push([d, array[j]]);
        });

        return rv;
    }

    function mod(n, m) {
        return ((n % m) + m) % m;
    }

    function tree(array) {
        var key = function(d) {
            return d;
        };
        var bisect = d3.bisector(function(d) {
            return key(d);
        }).left;

        array.insert = function(d) {
            var i = array.findIndex(d);
            var val = key(d);
            if (array[i] && val === key(array[i])) {
                return undefined;
            }
            // don't add dupes
            array.splice(i, 0, d);
            return i;
        };

        array.remove = function(d) {
            var i = array.findIndex(d);
            array.splice(i, 1);
            return i;
        };

        array.swap = function(i, j) {
            //
        };

        array.findIndex = function(d) {
            return bisect(array, key(d));
        };

        array.key = function(_) {
            key = _;
            return array;
        };

        array.order = function() {
            array.sort(d3.ascendingKey(key));
            return array;
        };

        return array;
    }

    d3.ascendingKey = function(key) {
        return typeof key === 'function' ? function (a, b) {
            return key(a) < key(b) ? -1 : key(a) > key(b) ? 1 : key(a) >= key(b) ? 0 : NaN;
        } : function (a, b) {
            return a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : a[key] >= b[key] ? 0 : NaN;
        };
    };

    d3.f = function() {
        var functions = arguments;
        // convert all string arguments into field accessors
        var i = 0;
        var l = functions.length;
        while (i < l) {
            if (typeof (functions[i]) === 'string' || typeof (functions[i]) === 'number') {
                functions[i] = (function(str) {
                    return function(d) {
                        return d[str];
                    };
                })(functions[i]);
            }
            i++;
        }
        // return composition of functions
        return function(d) {
            var i = 0;
            var l = functions.length;
            while (i++ < l) d = functions[i - 1].call(this, d);
            return d;
        };
    };

    var fgreek = d3.f;
    var epsilon = 1e-9;

    points = points.map(newPoint);

    render();

    var lines;
    var linesByY;
    var intersections;

    function render() {
        lines = [];
        points.forEach(function(d, i) {
            if (i % 2) lines.push(_.sortBy([d, points[i - 1]], 'y'));
        });

        lines.forEach(function(d, i) {
            if (d[0].x === d[1].x) d[0].x += epsilon;
            if (d[0].y === d[1].y) d[0].y -= epsilon;

            d[0].line = d;
            d[1].line = d;
        });


        calcQueue();

        linesByY.forEach(function(d) {
            d.positions = [];
            d.queuePositions.forEach(function(p, i) {
                d.positions.push(p);
                var nextY = d.queuePositions[i + 1] && d.queuePositions[i + 1].y;
                if (nextY > p.y + 10) {
                    d.positions.push({x: p.x, y: nextY - 10});
                }
            });
        });
    }

    function calcQueue() {
        var queue = tree(points.slice())
            .key(function(d) {
                return d.y + epsilon * d.x;
            })
            .order();

        intersections = [];

        linesByY = _.sortBy(lines, fgreek(0, 'y'));
        linesByY.forEach(function(d) {
            d.queuePositions = [];
        });

        var statusT = tree([]);
        var index;
        for (var i = 0; i < queue.length && i < 1000; i++) {
            var d = queue[i];
            var y = d.y;
            if (d.line && d.line[0] === d) {
                // insert
                d.type = 'insert';
                index = statusT
                    .key(function(e) {
                        return lineXatY(e, d.y - epsilon / 1000);
                    })
                    .insert(d.line);
                checkIntersection(d.line, statusT[index + 1]);
                checkIntersection(d.line, statusT[index - 1]);
            } else if (d.line) {
                // removal
                d.type = 'removal';

                index = statusT.findIndex(d.line);
                statusT.remove(d.line);

                d.line.queuePositions.push({x: index, y: Math.max(y - 10, queue[i - 1].y)});
                checkIntersection(statusT[index - 1], statusT[index]);
            } else if (d.lineA && d.lineB) {
                // intersection
                statusT.key(function(e) {
                    return lineXatY(e, d.y - epsilon / 1000);
                });

                var indexA = statusT.findIndex(d.lineA);
                var indexB = statusT.findIndex(d.lineB);
                if (indexA === indexB) {
                    indexA += 1;
                }
                statusT[indexA] = d.lineB;
                statusT[indexB] = d.lineA;

                var minIndex = indexA < indexB ? indexA : indexB;
                checkIntersection(statusT[minIndex - 1], statusT[minIndex]);
                checkIntersection(statusT[minIndex + 1], statusT[minIndex + 2]);
            }

            statusT.forEach(function(data, ind) {
                data.queuePositions.push({x: ind, y: y});
            });
        }

        function checkIntersection(a, b) {
            if (!a || !b) {
                return undefined;
            }
            var k = intersection(a[0], a[1], b[0], b[1]);
            k.lineA = a;
            k.lineB = b;
            if (k.isIntersection) {
                return intersections.push(k) && queue.insert(k);
            }
            return undefined;
        }
    }

    return intersections;
};

EZModelUtils.clearDuplicates = function(array) {
    return _.uniqWith(array, _.isEqual);
};

EZModelUtils.getFixedIndex = function(index, steps, length) {
    const cleanSteps = steps % length;
    const fixedSteps = (steps >= 0) ? cleanSteps : length + cleanSteps;
    return (index + fixedSteps) % length;
};

EZModelUtils.pairElements = function(steps = 1) {
    const {getFixedIndex} = EZModelUtils;
    return (element, index, array) => {
        const fixedIndex = getFixedIndex(index, steps, array.length);
        return [element, array[fixedIndex]];
    };
};
EZModelUtils.pairWithNext = EZModelUtils.pairElements(1);
EZModelUtils.pairWithPrevious = EZModelUtils.pairElements(-1);

EZModelUtils.removeElementFromArray = function(array, element) {
    const index = (Number.isInteger(element))
        ? element : array.indexOf(element);
    if (index > -1) {
        array.splice(index, 1);
    }
    return array;
};

/**
 * Method to rotate an array
 *
 * @param  {array} array - Array to rotate
 * @param  {int} steps - rotation (can be negative)
 *
 * @return {array} - Array rotated.
 */
EZModelUtils.rotate = function(array, steps) {
    const clone = [...array];
    const delta = steps % array.length;
    clone.unshift(...clone.splice(delta));
    return clone;
};

EZModelUtils.uniteSets = (setA, setB) => {
    const accumulator = new Set(setA);
    setB.forEach((element) => accumulator.add(element));
    return accumulator;
};

/**
 * Method to deep clone an object
 *
 * @param  {object} input - Object to deep clone
 *
 * @return {object} - Deep clone
 */
EZModelUtils.cloneDeep = (input) => {
    return EZModelUtils.getSeed(input, false, false, false, true);
};

/**
 * Method to deep clone an object picking only certain values
 *
 * @param  {object} input - Object to deep clone
 * @param  {array} pick - values to deep pick
 *
 * @return {object} - Deep clone
 */
EZModelUtils.pickDeep = (input, pick) => {
    return EZModelUtils.getSeed(input, pick, false, false, true);
};

/**
 * Method to deep clone an object omiting certain values
 *
 * @param  {object} input - Object to deep clone
 * @param  {array} omit - values to deep omit
 *
 * @return {object} - Deep clone
 */
EZModelUtils.omitDeep = (input, omit = ['parent']) => {
    return EZModelUtils.getSeed(input, false, omit, false, true);
};

/**
 * Method to custom clone an object
 * @param  {object} input - Object to clone
 * @param  {array} pick - values to pick in the clone
 * @param  {array} omit - values to omit in the clone
 * @param  {boolean} temp - should temp values be included?
 * @param  {boolean} deep - should be a deep clone?
 *
 * @return {object} - clone
 */
EZModelUtils.getSeed = (input, pick, omit, temp, deep) => {
    const getItem = (item) => {
        const model = item.constructor.name.includes('EZModel');
        if (deep) {
            return EZModelUtils.getSeed(item, pick, omit, temp, deep);
        }
        if (!deep && model) {
            return item.getJson(undefined, temp);
        }
        // if (!deep && !model)
        return EZModelUtils.getSeed(item);
    };
    const getArray = (array) => {
        if (array.length === 0) return [];
        return array.map((item) => {
            if (typeof item === 'object' && item) {
                return getItem(item);
            }
            return item;
        });
    };
    if (input.constructor.name === 'Date') return input;
    if (typeof input !== 'object' || !input) return input;
    if (Array.isArray(input)) return getArray(input);
    const objectToReturn = Object.entries(input).reduce((nextInput, [key, value]) => {
        const shouldInclude = !pick || !pick.length || pick.includes(key);
        const shouldExclude = omit && omit.length && omit.includes(key);
        if (!shouldInclude || shouldExclude) return nextInput;
        if (Array.isArray(value)) {
            nextInput[key] = getArray(value);
            return nextInput;
        } else if (typeof value === 'object' && value) {
            nextInput[key] = getItem(value);
            return nextInput;
        }
        nextInput[key] = value;
        return nextInput;
    }, {});
    if (deep) {
        return Object.setPrototypeOf(objectToReturn, Object.getPrototypeOf(input));
    }
    return objectToReturn;
};


/**
 * function to merge and overwrite obj values with src values
 *
 * (this function mutates obj)
 *
 * @param  {object} obj the object to be merged (the default options)
 * @param  {object} src the object with values to overwrite (custom values)
 * @return {object}     the function returns obj
 */
EZModelUtils.mergeDefaultValues = function(obj, src) {
    return _.mergeWith(obj, src, function(objValue, srcValue) {
        if (_.isArray(objValue)) {
            return srcValue;
        }
        return undefined;
    });
};

EZModelUtils.moveLimitsByValue = function(limits, value) {
    const offsetedLimits = [];
    offsetedLimits.push({x: limits[0].x + value.x, y: limits[1].y + value.y});
    offsetedLimits.push({x: limits[2].x + value.x, y: limits[1].y + value.y});
    offsetedLimits.push({x: limits[2].x + value.x, y: limits[3].y + value.y});
    offsetedLimits.push({x: limits[0].x + value.x, y: limits[3].y + value.y});
    offsetedLimits.push(offsetedLimits[0]);

    return offsetedLimits;
};

EZModelUtils.expandLimitsByModuleDiagonal = function(limits, model, placement) {
    const expandedLimits = [];
    const moduleDiagonal = EZModelUtils.getDiagonal(model[placement].x, model[placement].y);
    expandedLimits.push({x: limits[0] - moduleDiagonal, y: limits[1] - moduleDiagonal});
    expandedLimits.push({x: limits[2] + moduleDiagonal, y: limits[1] - moduleDiagonal});
    expandedLimits.push({x: limits[2] + moduleDiagonal, y: limits[3] + moduleDiagonal});
    expandedLimits.push({x: limits[0] - moduleDiagonal, y: limits[3] + moduleDiagonal});
    expandedLimits.push(expandedLimits[0]);

    return expandedLimits;
};

/**
 * Trigonometric method to get the opposite side of a triangle with the angle and its adjacent side
 *
 * @param  {float} angle    - The angle of the triangle
 * @param  {float} adjacent - The adjacent side of the angle
 * @return {float}          - [description]
 */
EZModelUtils.getOppositeA = function(angle, adjacent) {
    var opposite = EZModelUtils.degToRad(angle);
    opposite = Math.tan(opposite);
    opposite *= adjacent;
    opposite = opposite.toFixed(2);
    opposite = parseFloat(opposite);
    return opposite;
};

/**
 * Trigonometric method to get the opposite side of a triangle with the angle and the Hypotenuse
 *
 * @param  {float} angle    - The angle of the triangle
 * @param  {float} adjacent - The hypotenuse
 * @return {float}          - [description]
 */
EZModelUtils.getOppositeH = function(angle, hypotenuse) {
    var opposite = EZModelUtils.degToRad(angle);
    opposite = Math.sin(opposite);
    opposite *= hypotenuse;
    opposite = opposite.toFixed(2);
    opposite = parseFloat(opposite);
    return opposite;
};

EZModelUtils.cleanDegrees = function(angle) {
    while (angle > 180) {
        angle -= 360;
    }
    while (angle <= -180) {
        angle += 360;
    }
    angle = angle.toFixed(2);
    angle = Number(angle);
    if (angle === -0) {
        angle = 0;
    }
    return angle;
};

EZModelUtils.degToRad = function(angle) {
    return angle * (Math.PI / 180);
};

EZModelUtils.radToDeg = function(angle) {
    return angle * (180 / Math.PI);
};

EZModelUtils.transformDegreesToAtan2Space = function(angle) {
    angle = (angle * -1) - 90;
    return EZModelUtils.cleanDegrees(angle);
};

EZModelUtils.transformDegreesToAzimuthSpace = function(angle) {
    angle = (angle + 90) * -1;
    return EZModelUtils.cleanDegrees(angle);
};

EZModelUtils.aproxPointsByDistance = function(pointA, pointB, distance = 1) {
    const {calculateLineAzimuth, degToRad, rotatePoint} = EZModelUtils;

    const lineAzimuth = calculateLineAzimuth(pointA, pointB);
    const lineAngle = degToRad(lineAzimuth);
    const rotatedA = rotatePoint(pointA, -lineAngle);
    const pointX = {x: rotatedA.x, y: rotatedA.y + distance};
    return rotatePoint(pointX, lineAngle);
};

EZModelUtils.aproxPointsByPercent = function(pointA, pointB, percent = 0.5) {
    const {aproxCoordsByPercent} = EZModelUtils;
    return {
        x: aproxCoordsByPercent(pointA.x, pointB.x, percent),
        y: aproxCoordsByPercent(pointA.y, pointB.y, percent),
        z: aproxCoordsByPercent(pointA.z, pointB.z, percent),
    };
};

EZModelUtils.aproxPointsByRatio = function(pointA, pointB, ratio = 2) {
    const {aproxCoordsByRatio} = EZModelUtils;
    return {
        x: aproxCoordsByRatio(pointA.x, pointB.x, ratio),
        y: aproxCoordsByRatio(pointA.y, pointB.y, ratio),
        z: aproxCoordsByRatio(pointA.z, pointB.z, ratio),
    };
};

EZModelUtils.calculateLineAzimuth = function(pointA, pointB) {
    const {cleanDegrees, radToDeg} = EZModelUtils;
    const vector = {
        x: pointB.x - pointA.x,
        y: pointB.y - pointA.y
    };
    const angle = Math.atan2(vector.y, vector.x);
    return cleanDegrees(radToDeg(angle + Math.PI / 2));
};

EZModelUtils.aproxPointToLine = function(point, line, pointB, side, slope) {
    const {calculateLineAzimuth, degToRad, cleanDegrees,
        intersectLines, rotatePoint} = EZModelUtils;
    const pointA = line[0];

    let lineAzimuth = line[1];
    if (lineAzimuth === undefined && pointB) {
        lineAzimuth = calculateLineAzimuth(pointA, pointB);
    }

    const lineAngle = degToRad(lineAzimuth);
    const rotatedA = rotatePoint(pointA, -lineAngle);
    const rotatedX = rotatePoint(point, -lineAngle);

    if (side && rotatedX.x > rotatedA.x) return point;
    if (slope && cleanDegrees(slope + 180) !== lineAzimuth) {
        const intersect = intersectLines(
            [rotatedA, 0], [rotatedX, slope - lineAzimuth]);
        if (intersect === undefined) debugger;
        rotatedX.y = intersect.y;
    }
    rotatedX.x = rotatedA.x;

    if (pointB) {
        const rotatedB = rotatePoint(pointB, -lineAngle);
        if (rotatedX.y > rotatedA.y) rotatedX.y = rotatedA.y;
        if (rotatedX.y < rotatedB.y) rotatedX.y = rotatedB.y;
    }
    return rotatePoint(rotatedX, lineAngle);
};

EZModelUtils.aproxCoordsByPercent = function(coordA, coordB, percent = 0.5) {
    return (coordA * (1 - percent) + (coordB * percent));
};

EZModelUtils.aproxCoordsByRatio = function(coordA, coordB, ratio = 2) {
    return (coordA + (ratio - 1) * coordB) / ratio;
};

EZModelUtils.getMiddlePoint = function(pointA, pointB, isSpherical) {
    if (isSpherical) return EZModelUtils.getCentroid(pointA, pointB);
    return EZModelUtils.aproxPointsByRatio(pointA, pointB);
};

EZModelUtils.getCenterPoint = function(points) {
    const center = {x: 0, y: 0, z: 0};
    points.forEach((point) => {
        center.x += point.x;
        center.y += point.y;
        center.z += point.z;
    });
    if (points.length > 0) {
        center.x /= points.length;
        center.y /= points.length;
        if (isNaN(center.z)) center.z = 0;
        center.z /= points.length;
    }
    return center;
};

EZModelUtils.getCentroid = function(point1, point2) {
    const centroid = d3.geoCentroid(
        ez3dScene.generateGeoJSON([point1, point2]));
    return {x: centroid[0], y: centroid[1]};
};

EZModelUtils.getPointsPercent = function(first, second, point) {
    let percent = 0.5;
    const closeX = (Math.abs(first.x - second.x) < 0.01);
    const closeY = (Math.abs(first.y - second.y) < 0.01);
    if (closeX && closeY) return 0;

    if (closeX) {
        percent = (point.y - first.y) / (second.y - first.y);
    } else {
        percent = (point.x - first.x) / (second.x - first.x);
    }
    return percent;
};

EZModelUtils.intersectLines = function(lineA, lineB) {
    const {cleanDegrees, degToRad, rotatePoint} = EZModelUtils;

    const [pointA, azimuthA] = lineA;
    const [pointB, azimuthB] = lineB;

    const angleA = degToRad(azimuthA);
    const rotatedA = rotatePoint(pointA, -angleA);
    const rotatedB = rotatePoint(pointB, -angleA);

    const angleB = cleanDegrees(azimuthA - azimuthB);

    if (Math.abs(angleB) === 180) return undefined;
    if (Math.abs(angleB) !== 90) {
        const slope = Math.tan(degToRad(angleB));
        const n = rotatedB.y - rotatedB.x / slope;
        rotatedB.y = n + rotatedA.x / slope;
    }
    rotatedB.x = rotatedA.x;
    return rotatePoint(rotatedB, angleA);
};

EZModelUtils.setLocation = function(point, parent) {
    const location = new EZModelLocation(ez3dScene,
        (point.lng === undefined) ? point.x : point.lng,
        (point.lat === undefined) ? point.y : point.lat);
    location.setCoordSystem('building', parent, 'EZModelRoof');
    return location;
};

EZModelUtils.calculateDelta = function  (latlng, deltaMeters, metersPerDegree) {
    let result = {
        lat: latlng.lat - deltaMeters.y / metersPerDegree,
        lng: latlng.lng + deltaMeters.x / metersPerDegree / Math.cos(EZModelUtils.degToRad(latlng.lat))
    };
    return result;
};

/**
 * Determines if a point is in a square created by other 2 
 * @param  {EZModelLocation} point point to check 
 * @param  {EZModelLocation} squarePoint1 firs point of square
 * @param  {EZModelLocation} squarePoint2 second point of square
 * @return {boolean} 
 */

EZModelUtils.checkPointInSquare = function (point, squarePoint1, squarePoint2){
    if (squarePoint1.x <= squarePoint2.x) {
        if (point.x < squarePoint1.x || point.x > squarePoint2.x) return false;
    } else {
        if (point.x > squarePoint1.x || point.x < squarePoint2.x) return false;
    }
    if (squarePoint1.y <= squarePoint2.y) {
        if (point.y < squarePoint1.y || point.y > squarePoint2.y) return false;
    } else {
        if (point.y > squarePoint1.y || point.y < squarePoint2.y) return false;
    }
    return true;
}

/**
 * Calculates intersection point of two segments
 * @param  {array} segmentA [location1, location2]
 * @param  {array} segmentB [location1, location2]
 * @return {EZModelLocation} intersection point or undefined if no inteserct
 */
EZModelUtils.intersectSegments = function (segmentA, segmentB) {
    const {calculateLineAzimuth, intersectLines, checkPointInSquare} = EZModelUtils;
    const [pointA1, pointA2] = segmentA;
    const [pointB1, pointB2] = segmentB;

    const azimuthA = calculateLineAzimuth(pointA1, pointA2);
    const azimuthB = calculateLineAzimuth(pointB1, pointB2);

    const intersect = intersectLines([pointA1, azimuthA], [pointB1, azimuthB]);

    if (!intersect || isNaN(intersect.x) || isNaN(intersect.y)) {
        return undefined;
    }

    if (checkPointInSquare(intersect, pointA1, pointA2) &&
        checkPointInSquare(intersect, pointB1, pointB2)) {
            return intersect;
    } else {
        return undefined;
    }
};


/**
 * Calculate Vector from porint1 to point2
 * @param  {Object} point1  {x,y,z}
 * @param  {Object} point2  {x,y,z}
 * @return {Object} vector {x,y,z}
 */
EZModelUtils.getVector = function (point1, point2) {
    point1 = (point1.hasOwnProperty('z')) ? point1 : {x: point1.x, y: point1.y, z: 0};
    point2 = (point2.hasOwnProperty('z')) ? point2 : {x: point2.x, y: point2.y, z: 0};
    const vector = {x: point2.x - point1.x, y: point2.y - point1.y, z: point2.z - point1.z};
    return vector;
};


/**
 * Calculates dot product of 2 vectors
 * @param  {Object} vector1 {x,y,z}
 * @param  {Object} vector2 {x,y,z}
 * @return {Number} dot product
 */
EZModelUtils.dotProduct = function (vector1, vector2) {
    vector1 = (vector1.hasOwnProperty('z')) ? vector1 : {x: vector1.x, y: vector1.y, z: 0};
    vector2 = (vector2.hasOwnProperty('z')) ? vector2 : {x: vector2.x, y: vector2.y, z: 0};
    const product = (vector1.x * vector2.x) + (vector1.y * vector2.y) + (vector1.z * vector2.z);
    return product;
};

/**
 * Calculate squared module of vector
 * @param  {Object} vector {x,y,z}
 * @return {Number} squared module
 */
EZModelUtils.squaredModuleOfVector = function (vector) {
    vector = (vector.hasOwnProperty('z')) ? vector : {x: vector.x, y: vector.y, z: 0};
    return (vector.x ** 2) + (vector.y ** 2) + (vector.z ** 2);
};

/**
 * Calculate module of vector
 * @param  {Object} vector {x,y,z}
 * @return {Number} module
 */
EZModelUtils.moduleOfVector = function (vector) {
    return Math.sqrt(EZModelUtils.squaredModuleOfVector(vector));
};

/**
 * Calculates angle between 2 vectors
 * @param  {Object} vector1 {x,y,z}
 * @param  {Object} vector2 {x,y,z}
 * @return {Number} angle in radians
 */
EZModelUtils.angleOf2Vectors = function (vector1, vector2) {
    const dotProduct = EZModelUtils.dotProduct(vector1, vector2);
    const module1 = EZModelUtils.moduleOfVector(vector1);
    const module2 = EZModelUtils.moduleOfVector(vector2);

    return Math.acos(dotProduct / (module1 * module2));
};

EZModelUtils.getElementOuterHeight = function(el) {
    var height = 0;
    if (el.offsetHeight) {
        height = el.offsetHeight;
    }
    var style = getComputedStyle(el);

    height += parseInt(style.marginTop, 10) + parseInt(style.marginBottom, 10);
    return height;
};

EZModelUtils.getElementOuterWidth = function(el) {
    var width = 0;
    if (el.offsetWidth) {
        width = el.offsetWidth;
    }
    var style = getComputedStyle(el);

    width += parseInt(style.marginLeft, 10) + parseInt(style.marginRight, 10);
    return width;
};

EZModelUtils.getElementOffset = function(element) {
    var de = document.documentElement;
    var box = element.getBoundingClientRect();
    var top = box.top + window.pageYOffset - de.clientTop;
    var left = box.left + window.pageXOffset - de.clientLeft;
    return {top: top, left: left};
};


EZModelUtils.getLineFormulaY = function(point1, point2) {
    var m = (point2.y - point1.y) / (point2.x - point1.x);
    var f = function(x) {
        return m * (x - point1.x) + point1.y;
    };
    return f;
};

EZModelUtils.getLineFormulaX = function(point1, point2) {
    var m = (point2.y - point1.y) / (point2.x - point1.x);
    var f = function(y) {
        return ((y - point1.y) / m) + point1.x;
    };
    return f;
};

EZModelUtils.getLineExtendedToLimits = function(point1, point2, limits) {
    var minX = limits[0];
    var minY = limits[1];
    var maxX = limits[2];
    var maxY = limits[3];

    var x = EZModelUtils.getLineFormulaX(point1, point2);
    var y = EZModelUtils.getLineFormulaY(point1, point2);

    var p1 = {};
    var p2 = {};

    p1.y = y(minX);
    if (p1.y < minY) {
        p1.x = x(minY);
        p1.y = minY;
    } else if (p1.y > maxY) {
        p1.x = x(maxY);
        p1.y = maxY;
    } else {
        p1.x = minX;
    }

    p2.y = y(maxX);
    if (p2.y < minY) {
        p2.x = x(minY);
        p2.y = minY;
    } else if (p2.y > maxY) {
        p2.x = x(maxY);
        p2.y = maxY;
    } else {
        p2.x = maxX;
    }

    return [[p1.x, p1.y], [p2.x, p2.y]];
};

/**
 * create an array defining perpendiculars and parallel lines to the first vector
 * crossing on each point
 * @param  {arrray} points - the original set of points
 * @param  {array} limits - viewport limits
 * @return {array}        array with the lines calculated
 */
EZModelUtils.createGuideLinesCollection = function(points, limits) {
    var guideLines = [];
    var vectors = [];
    var newLine = [];
    var point1;
    var point2;

    for (var i = 0; i < points.length - 1; i++) {
        point1 = [points[i].x, points[i].y];
        point2 = [points[i + 1].x, points[i + 1].y];
        var vector1 = {
            x: point2[0] - point1[0],
            y: point2[1] - point1[1]
        };

        var vector2 = {
            x: vector1.y,
            y: -vector1.x
        };

        vectors.push(vector1);
        vectors.push(vector2);
    }

    vectors.forEach(function(vector, k) {
        point1 = {
            x: points[0].x + vector.x,
            y: points[0].y + vector.y
        };

        var lastPointIndex = points.length - 1;
        if (ez3dScene.tempPath.estimatedPoint === true) {
            lastPointIndex = points.length - 2;
        }

        point2 = {
            x: points[lastPointIndex].x + vector.x,
            y: points[lastPointIndex].y + vector.y
        };

        newLine = EZModelUtils.getLineExtendedToLimits(
            points[0], point1, limits);
        guideLines.push(newLine[0], newLine[1]);
        guideLines.push(null);

        newLine = EZModelUtils.getLineExtendedToLimits(
            points[lastPointIndex], point2, limits);
        guideLines.push(newLine[0], newLine[1]);
        guideLines.push(null);
    });

    return guideLines;
};

/**
 * create an array defining perpendiculars and parallel lines to the first vector
 * crossing on each point
 * @param  {arrray} points - the original set of points
 * @param  {array} limits - viewport limits
 * @return {array}        array with the lines calculated
 */
EZModelUtils.createGuideLinesCollectionNew = function(points, limits) {
    var guideLines = [];
    var vectors = [];
    var vector1;
    var vector2;
    var selectedEdges = [];
    var newLine = [];
    var point1Index = points.length - 1;
    if (ez3dScene.tempPath.vertices[ez3dScene.tempPath.vertices.length - 1].estimated === true) {
        point1Index -= 1;
    }
    var point1 = points[point1Index];
    var point2 = points[0];

    points.forEach(function(point) {
        if (point.active === true) {
            point1 = point;
            point1Index = point.index;

            if (point1Index === 0) {
                point2 = points[points.length - 1];
            } else {
                point2 = points[point1Index - 1];
            }
        }
    });

    ez3dScene.tempPath.vertices.forEach(function(d) {
        if (d.selectedEdge === true) {
            selectedEdges.push(d.index);
        }
    });
    if (selectedEdges.length === 0) {
        vector1 = {
            x: point2.x - point1.x,
            y: point2.y - point1.y
        };
        vector2 = {
            x: vector1.y,
            y: -vector1.x
        };
        vectors.push(vector1);
        vectors.push(vector2);
    } else {
        var path = ez3dScene.tempPath;
        selectedEdges.forEach(function(index) {
            vector1 = {
                x: -Math.sin(ez3dScene.utils.degToRad(path.edgeAzimuths[index])),
                y: Math.cos(ez3dScene.utils.degToRad(path.edgeAzimuths[index]))
            };
            vector2 = {
                x: vector1.y,
                y: -vector1.x
            };
            vectors.push(vector1);
            vectors.push(vector2);
        });
    }

    vectors.forEach(function(vector) {
        var point3 = {
            x: point1.x + vector.x,
            y: point1.y + vector.y
        };

        newLine = EZModelUtils.getLineExtendedToLimits(point1, point3, limits);
        guideLines.push(newLine[0], newLine[1]);
        guideLines.push(null);
    });

    var selectedLocs = [];
    points.forEach(function(point) {
        if (point.drawGuides === true) {
            selectedLocs.push(point);
        }
    });

    selectedLocs.forEach(function(loc) {
        vectors.forEach(function(vector) {
            var point5 = {
                x: loc.x + vector.x,
                y: loc.y + vector.y
            };

            newLine = EZModelUtils.getLineExtendedToLimits(loc, point5, limits);
            guideLines.push(newLine[0], newLine[1]);
            guideLines.push(null);
        });
    });

    return guideLines;
};


/**
 * Adds a listener to mutant tilesload of google provider
 * @param  {Object} mutant  google Mutant mapper
 */
EZModelUtils.mutantEvent = function (mutant) {
    google.maps.event.addListener(mutant, 'tilesloaded', function(evt) {
        if (mutant.mapDataProviders) {
            ee.emitEvent('Mapper.AttributionChange', [mutant.mapDataProviders]);
        }
    });
};

/**
 * Calculates longitud coord from tile coords
 * @param  {float} x  x coord
 * @param  {float} z  z coord
 * @return {float}   longitud
 */
EZModelUtils.tile2lng = function  (x, z) {
    return (x / Math.pow(2, z) * 360 - 180);
};

/**
 * Calculates latitud coord from tile coords
 * @param  {float} y  y coord
 * @param  {float} z  z coord
 * @return {float}   latitud
 */
EZModelUtils.tile2lat = function  (y, z) {
    let n = Math.PI - 2 * Math.PI * y / Math.pow(2, z);
    return (180 / Math.PI * Math.atan(0.5 * (Math.exp(n) - Math.exp(-n))));
};

/**
 * Calculates latitud coord from tile coords
 * @param  {array} limits  limits of background [widthMin, heightMin, widthMax, heightMax]
 * @param  {int} tileWidth  tile width
 * @param  {int} tileHeight  tile height
 * @return {array}   array of tile representations: {id, x, y, w, h}
 */
EZModelUtils.calculateMapTiles = function(limits, tileWidth, tileHeight) {
    const tiles = [];
    let tilesCounter = 0;
    for (let j = limits[1]; j < limits[3]; j += tileHeight) {
        for (let i = limits[0]; i < limits[2]; i += tileWidth) {
            tiles.push({
                id: 'mapId_' + tilesCounter,
                x: i,
                y: j,
                w: tileWidth,
                h: tileHeight
            });
            tilesCounter++;
        }
    }
    return tiles;
};

EZModelUtils.collide = function(points, polygon, all) {
    /* Check vertices */
    for (var p = 0; p < points.length; p++) {
        var point = points[p];
        var inside = false;

        for (var i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
            var first = ((polygon[i].Y >= point.Y) !== (polygon[j].Y >= point.Y));
            var second = (point.X <= (polygon[j].X - polygon[i].X) * (point.Y - polygon[i].Y) / (polygon[j].Y - polygon[i].Y) + polygon[i].X);
            if (first && second) {
                inside = !inside;
            }
        }
        if (all && !inside) {
            return false;
        }
        if (!all && inside) {
            return true;
        }
    }
    return all;
};

EZModelUtils.collide2 = function(point, polygon, all) {
    /* Check vertices */
    var inside = false;

    for (var i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
        var first = ((polygon[i].y >= point.y) !== (polygon[j].y >= point.y));
        var second = (point.x <= (polygon[j].x - polygon[i].x) * (point.y - polygon[i].y) / (polygon[j].y - polygon[i].y) + polygon[i].x);
        if (first && second) {
            inside = !inside;
        }
    }
    if (all && !inside) {
        return false;
    }
    if (!all && inside) {
        return true;
    }

    return all;
};

EZModelUtils.offsetPolyline = function(polygons, scale, delta) {
    EZModelUtils.reverseCopy(polygons);
    polygons = EZModelUtils.scaleup(polygons, scale);
    var cpr = new ClipperLib.Clipper();

    var joinType = ClipperLib.JoinType.jtSquare;
    var miterLimit = 2;
    var AutoFix = true;

    return cpr.OffsetPolygons(polygons, delta * scale, joinType, miterLimit, AutoFix);
};

// helper function to scale up polygon coordinates
EZModelUtils.scaleup = function(poly, scale) {
    if (!scale) {
        scale = 1;
    }
    for (var i = 0; i < poly.length; i++) {
        for (var j = 0; j < poly[i].length; j++) {
            if (scale >= 1) {
                poly[i][j].X = parseInt(poly[i][j].X * scale, 10);
                poly[i][j].Y = parseInt(poly[i][j].Y * scale, 10);
            } else {
                poly[i][j].X = parseFloat(poly[i][j].X * scale);
                poly[i][j].Y = parseFloat(poly[i][j].Y * scale);
            }
        }
    }
    return poly;
};

// converts polygons to SVG path string
EZModelUtils.polys2path = function(poly, scale) {
    var path = '';
    if (!scale) {
        scale = 1;
    }
    for (var i = 0; i < poly.length; i++) {
        for (var j = 0; j < poly[i].length; j++) {
            if (j) {
                path += 'L';
            } else {
                path += 'M';
            }
            path += (poly[i][j].X / scale) + ', ' + (poly[i][j].Y / scale);
        }
        path += 'Z';
    }
    return path;
};

EZModelUtils.reverseCopy = function(poly) {
    var klen = poly.length;
    for (var k = 0; k < klen; k++) {
        var len = poly[k].length;
        poly[k].length = len * 2 - 2;
        for (var j = 1; j <= len - 2; j++) {
            poly[k][len - 1 + j] = {
                X: poly[k][len - 1 - j].X,
                Y: poly[k][len - 1 - j].Y
            };
        }
    }
};

EZModelUtils.calculateCornersAngles = function(vertices) {
    var nextVertex = {};
    var prevVertex = {};
    var nextVector = {};
    var prevVector = {};

    var angleToNext;
    var angleToPrev;
    var angle;

    var bias = ez3dScene.layoutRules.scenePreferences.angleBias;
    var regular = true;
    var cornerAngles = [];

    if (vertices.length > 2) {
        vertices.forEach(function(vertex, i) {
            if (i === vertices.length - 1) {
                nextVertex = vertices[0];
            } else {
                nextVertex = vertices[i + 1];
            }
            if (i === 0) {
                prevVertex = vertices[vertices.length - 1];
            } else {
                prevVertex = vertices[i - 1];
            }

            nextVector = {
                x: nextVertex.x - vertex.x,
                y: nextVertex.y - vertex.y
            };

            prevVector = {
                x: prevVertex.x - vertex.x,
                y: prevVertex.y - vertex.y
            };

            angleToNext = Math.atan2(nextVector.y, nextVector.x);
            angleToPrev = Math.atan2(prevVector.y, prevVector.x);

            angle = angleToPrev - angleToNext;
            if (angle < -Math.PI) {
                angle += Math.PI * 2;
            }
            angle = ez3dScene.utils.cleanDegrees(ez3dScene.utils.radToDeg(angle));

            if (regular) {
                if (Math.abs(Math.abs(angle) - 90) >= bias) {
                    regular = false;
                }
            }
            cornerAngles.push(angle);
        });
    }
    // console.log('corner angles ' + cornerAngles);
    return cornerAngles;
};

EZModelUtils.calculateProjectRange = function(ez3dScene) {
    var minX;
    var minY;
    var maxX;
    var maxY;

    if ((ez3dScene.buildings && ez3dScene.buildings.length > 0) || ez3dScene.trees.length > 0) {
        var pointsX = [];
        var pointsY = [];
        ez3dScene.buildings.forEach(function(building) {
            if (building.path) {
                var centerOffsetX = building.path.center.x;
                var centerOffsetY = building.path.center.y;
                building.path.vertices.forEach(function(v) {
                    pointsX.push(v.x + centerOffsetX);
                    pointsY.push(v.y + centerOffsetY);
                });
            }
        });

        ez3dScene.trees.forEach(function(tree) {
            pointsX.push(tree.position.x);
            pointsY.push(tree.position.y);
        });

        minX = d3.min(pointsX);
        minY = d3.min(pointsY);
        maxX = d3.max(pointsX);
        maxY = d3.max(pointsY);
    } else {
        minX = -10;
        minY = -10;
        maxX = 10;
        maxY = 10;
    }

    minX -= 50;
    minY -= 50;
    maxX += 50;
    maxY += 50;

    return [minX, minY, maxX, maxY];
    // return [minX, maxX, minY, maxY];
};

EZModelUtils.calculatePathRange = function(path) {
    var minX;
    var minY;
    var maxX;
    var maxY;

    var pointsX = [];
    var pointsY = [];

    if (path.coordSystem === 'building') {
        var building = path.getAncestor('EZModelBuilding');
        var offsetX = building.path.center.x;
        var offsetY = building.path.center.y;
        path.vertices.forEach(function(v) {
            pointsX.push(v.x + offsetX);
            pointsY.push(v.y + offsetY);
        });
    } else if (path.coordSystem === 'area') {
        path.vertices.forEach(function(v) {
            pointsX.push(v.xF);
            pointsY.push(v.yF);
        });
    } else {
        path.vertices.forEach(function(v) {
            pointsX.push(v.x);
            pointsY.push(v.y);
        });
    }

    minX = d3.min(pointsX);
    minY = d3.min(pointsY);
    maxX = d3.max(pointsX);
    maxY = d3.max(pointsY);

    return [minX, maxX, minY, maxY];
};

EZModelUtils.calculateAreaRange = function(area) {
    var minX;
    var minY;
    var maxX;
    var maxY;

    var pointsX = [];
    var pointsY = [];

    if (area.areaMCoords === undefined) {
        area.path.vertices.forEach(function(v) {
            pointsX.push(v.xF);
            pointsY.push(v.yF);
        });
    } else {
        area.areaMCoords.forEach(function(v) {
            pointsX.push(v.x);
            pointsY.push(v.y);
        });
    }

    minX = d3.min(pointsX);
    minY = d3.min(pointsY);
    maxX = d3.max(pointsX);
    maxY = d3.max(pointsY);

    return [minX, maxX, minY, maxY];
};

EZModelUtils.calculateSubareaRange = function(subarea) {
    var minX;
    var minY;
    var maxX;
    var maxY;

    var pointsX = [];
    var pointsY = [];

    subarea.path.vertices.forEach(function(v) {
        pointsX.push(v.xF);
        pointsY.push(v.yF);
    });

    minX = d3.min(pointsX);
    minY = d3.min(pointsY);
    maxX = d3.max(pointsX);
    maxY = d3.max(pointsY);

    return [minX, maxX, minY, maxY];
};

EZModelUtils.calculateBuildingRange = function(building) {
    // @todo add keepout and subarea range to this...
    var minX;
    var minY;
    var maxX;
    var maxY;

    var centerOffsetX = building.path.center.x;
    var centerOffsetY = building.path.center.y;

    var pointsX = [];
    var pointsY = [];

    building.path.vertices.forEach(function(v) {
        pointsX.push(v.x);
        pointsY.push(v.y);
    });

    minX = d3.min(pointsX) + centerOffsetX;
    minY = d3.min(pointsY) + centerOffsetY;
    maxX = d3.max(pointsX) + centerOffsetX;
    maxY = d3.max(pointsY) + centerOffsetY;

    return [minX, maxX, minY, maxY];
};

EZModelUtils.calculateTreeRange = function(tree) {
    var minX;
    var minY;
    var maxX;
    var maxY;

    var centerOffsetX = tree.position.x;
    var centerOffsetY = tree.position.y;

    var radius = d3.max([
        tree.crownHigherRadius,
        tree.crownMiddleRadius,
        tree.crownLowerRadius
    ]);

    minX = centerOffsetX - (radius * 4);
    minY = centerOffsetY - (radius * 4);
    maxX = centerOffsetX + (radius * 4);
    maxY = centerOffsetY + (radius * 4);

    return [minX, maxX, minY, maxY];
};

EZModelUtils.convexHull = function (points) {
    function cross(o, a, b) {
        return (a[0] - o[0]) * (b[1] - o[1]) - (a[1] - o[1]) * (b[0] - o[0]);
    }
    points.sort(function(a, b) {
        return a[0] === b[0] ? a[1] - b[1] : a[0] - b[0];
    });

    var lower = [];
    for (var i = 0; i < points.length; i++) {
        while (lower.length >= 2 && cross(lower[lower.length - 2], lower[lower.length - 1], points[i]) <= 0) {
            lower.pop();
        }
        lower.push(points[i]);
    }

    var upper = [];
    for (var j = points.length - 1; j >= 0; j--) {
        while (upper.length >= 2 && cross(upper[upper.length - 2], upper[upper.length - 1], points[j]) <= 0) {
            upper.pop();
        }
        upper.push(points[j]);
    }

    upper.pop();
    lower.pop();
    return lower.concat(upper);
};

EZModelUtils.formatVertices = function(vertices, flatten) {
    const flattenFormat = (vertex) => ({
        X: (vertex.xF === undefined) ? vertex.x : vertex.xF,
        Y: (vertex.yF === undefined) ? vertex.y : vertex.yF,
    });
    const format = (vertex) => ({
        X: (vertex.x === undefined) ? vertex.X : vertex.x,
        Y: (vertex.y === undefined) ? vertex.Y : vertex.y,
    });

    return vertices.map((flatten) ? flattenFormat : format);
};

/**
 * intersectPaths returns intersection of two polygons
 * @param  {array} polygon1 array of 2d vertices [polygon 1 to be intersected]
 * @param  {array} polygon2 array of 2d vertices [polygon 2 to be intersected]
 * @return {array}  array of 2d vertices
 */
EZModelUtils.intersectPaths = function(path1, path2, flatten, invert) {
    var polygon1 = (path1.constructor.name === 'EZModelPath')
        ? path1.formatVertices(flatten) : EZModelUtils.formatVertices(path1, flatten);
    var polygon2 = (path2.constructor.name === 'EZModelPath')
        ? path2.formatVertices(flatten) : EZModelUtils.formatVertices(path2, flatten);

    polygon1.forEach(function(point) {
        point.X *= 1000;
        point.Y *= 1000;
    });
    polygon2.forEach(function(point) {
        point.X *= 1000;
        point.Y *= 1000;
    });

    var cpr = new ClipperLib.Clipper();
    cpr.AddPaths([polygon1], ClipperLib.PolyType.ptSubject, true);
    cpr.AddPaths([polygon2], ClipperLib.PolyType.ptClip, true);

    var clippedPaths = new ClipperLib.Paths();
    var clippedType = (invert)
        ? ClipperLib.ClipType.ctDifference
        : ClipperLib.ClipType.ctIntersection;
    cpr.Execute(
        clippedType, clippedPaths,
        ClipperLib.PolyFillType.pftNonZero,
        ClipperLib.PolyFillType.pftNonZero
    );
    clippedPaths.forEach(function(clippedPath) {
        clippedPath.forEach(function(point) {
            point.x = point.X / 1000;
            point.y = point.Y / 1000;
            delete point.X; delete point.Y;
        });
    });

    return clippedPaths;
};

EZModelUtils.offsetPaths = function (path, input, flatten) {
    var polygon = (path.constructor.name === 'EZModelPath')
        ? path.formatVertices(flatten) : path;
    var offset = input * 1000;
    polygon.forEach(function(point) {
        point.X *= 1000;
        point.Y *= 1000;
    });

    var co = new ClipperLib.ClipperOffset(20.0, 0.25);
    co.AddPaths([polygon],
        ClipperLib.JoinType.jtMiter,
        ClipperLib.EndType.etClosedPolygon);

    var offsettedPaths = new ClipperLib.Paths();
    co.Execute(offsettedPaths, offset);

    offsettedPaths.forEach(function (offsettedPath) {
        offsettedPath.forEach(function(point) {
            point.x = point.X / 1000;
            point.y = point.Y / 1000;
            delete point.X; delete point.Y;
        });
    });

    return offsettedPaths;
};

/**
 * create an array defining perpendiculars and parallel lines to the first vector
 * crossing on each point
 * @param  {arrray} points - the original set of points
 * @param  {array} limits - viewport limits
 * @return {array}        array with the lines calculated
 */
EZModelUtils.createGuideLinesCollectionOld = function(points, limits) {
    var guideLines = [];
    var newLine;

    var vector1 = {
        x: points[1].x - points[0].x,
        y: points[1].y - points[0].y
    };

    var vector2 = {
        x: vector1.y,
        y: -vector1.x
    };

    points.forEach(function(point, i, points) {
        var point1 = {
            x: point.x + vector1.x,
            y: point.y + vector1.y
        };
        var point2 = {
            x: point.x + vector2.x,
            y: point.y + vector2.y
        };

        newLine = EZModelUtils.getLineExtendedToLimits(point, point1, limits);
        guideLines.push(newLine[0], newLine[1]);
        guideLines.push(null);
        newLine = EZModelUtils.getLineExtendedToLimits(point, point2, limits);
        guideLines.push(newLine[0], newLine[1]);

        if (i + 1 !== points.length) {
            guideLines.push(null);
        }
    });

    return guideLines;
};

EZModelUtils.buildingIsInLimits = function(building, limits) {
    var points = [];
    points.push({
        x: limits[0],
        y: limits[1]
    });
    points.push({
        x: limits[2],
        y: limits[1]
    });
    points.push({
        x: limits[2],
        y: limits[3]
    });
    points.push({
        x: limits[0],
        y: limits[3]
    });
    points.push(points[0]);
    var value = false;
    building.path.vertices.forEach(function(vertice) {
        var a = EZModelUtils.collide2(
            {
                x: vertice.x + building.path.center.x,
                y: vertice.y + building.path.center.y
            },
            points,
            true
        );
        if (a === true) {
            value = a;
        }
    });

    return value;
};

EZModelUtils.subareaIsInLimits = function(subarea, limits) {
    var value = 0;
    subarea.path.vertices.forEach(function(vertice) {
        var a = EZModelUtils.collide2(
            {
                x: vertice.xF,
                y: vertice.yF
            },
            limits,
            true
        );
        if (a === true) {
            value += 1;
        }
    });

    return value;
};

EZModelUtils.limitsIsInSubarea = function(limits, subarea) {
    var value = false;
    var subVertex = [];
    subarea.path.vertices.forEach(function(vertex) {
        subVertex.push({
            x: vertex.xF,
            y: vertex.yF
        });
    });
    limits.forEach(function(vertice) {
        var a = EZModelUtils.collide2(
            {
                x: vertice.x,
                y: vertice.y
            },
            subVertex,
            true
        );
        if (a === true) {
            value = a;
        }
    });

    return value;
};

EZModelUtils.moduleIsInLimits = function(moduleCoords, limits) {
    var value = false;
    var a = EZModelUtils.collide2(
        moduleCoords,
        limits,
        true
    );
    if (a === true) {
        value = a;
    }

    return value;
};

EZModelUtils.countModules = function(d, element) {
    const elementsGroup = d3.select(element.parentElement);

    // COUNT BY ROW
    const rowLiteral = elementsGroup.data()[0].system.grid.rows[d.row].literal;
    // Count modules at left
    const leftSide = rowLiteral.slice(0, d.col);
    let leftCounter = 0;
    for (let j = leftSide.length; j > 0; j--) {
        if (leftSide[j - 1] === 0) {
            break;
        } else {
            leftCounter += 1;
        }
    }
    // Count modules at right
    const rightSide = rowLiteral.slice(d.col + 1);
    let rightCounter = 0;
    for (let i = 0; i < rightSide.length; i++) {
        if (rightSide[i] === 0) {
            break;
        } else {
            rightCounter += 1;
        }
    }

    // COUNT BY COLUMN
    const columnLiteral = [];
    const rows = elementsGroup.data()[0].system.grid.rows;
    for (let row = 0; row < rows.length; row++) {
        for (let mod = 0; mod < rows[row].modules.length; mod++) {
            const module = rows[row].modules[mod];
            if (module && module.col === d.col) {
                columnLiteral.push(module.type === 'module' ? 1 : 0);
            }
        }
    }
    // Count modules at bottom
    const bottomSide = columnLiteral.slice(0, d.row);
    let bottomCounter = 0;
    for (let l = bottomSide.length; l > 0; l--) {
        if (bottomSide[l - 1] === 0) {
            break;
        } else {
            bottomCounter += 1;
        }
    }
    // Count modules at top
    const upperSide = columnLiteral.slice(d.row + 1);
    let upperCounter = 0;
    for (let m = 0; m < upperSide.length; m++) {
        if (upperSide[m] === 0) {
            break;
        } else {
            upperCounter += 1;
        }
    }

    // Array for creating different paragraphs in context panel
    const text = [
        'col ' + (d.col + 1) + ', row ' + (d.row + 1),
        (leftCounter + 1) + ' / ' + (leftCounter + rightCounter + 1) + ' in a row',
        (bottomCounter + 1) + ' / ' + (bottomCounter + upperCounter + 1) + ' in a column'
    ];
    return text;
};

EZModelUtils.isValidModule = ({power, length, height, width}) => {
    if (!length || length < 0) return false;
    if (!height || height < 0 || height > 1) return false;
    if (!width || width < 0) return false;
    if (!power || power < 0) return false;
    return true;
};

EZModelUtils.getSymmetricRoofTypes = (types) => {
    const list = types;

    list['courtyard'] = list['flat'];
    list['pitched'] = list['pent'];
    list['pergola'] = list['pent'];
    list['mansard'] = list['pyramid'];
    list['gambrel'] = list['gabled'];
    list['cross'] = list['gabled'];

    return list;
};

/**
 * Function to truncate transform object values (5).
 * @param  {object} transformObject     - Object with 'k, x, y' values
 * @return {string} Stringified object with transform values
 */
EZModelUtils.parseTransformValues = (transformObject) => {
    let stringifiedZoom;
    const parsedTransformValue = {};
    if (transformObject) {
        Object.keys(transformObject).forEach((key) => {
            parsedTransformValue[key] = transformObject[key].toFixed(5);
        });
        stringifiedZoom = JSON.stringify(parsedTransformValue);
    } else {
        stringifiedZoom = '';
    }
    return stringifiedZoom;
};

/**
 * Method to remove all children and references of the object
 * ez3dScene.utils.destroyObject(object);
 */
EZModelUtils.destroyObject = function(object) {
    if (object === undefined) {
        return;
    }
    if (object) {
        Object.keys(object).forEach(
            function (val) {
                if (val === 'ez3dScene' || val === 'parent') {
                    object[val] = undefined;
                } else if (typeof object[val] === 'object') {
                    EZModelUtils.destroyObject(object[val]);
                }
            }
        );
    }
    object = undefined;
};

EZModelUtils.getDiagonal = (width, height) => Math.hypot(width, height);

/**
 * Function to calculate vector between two points.
 * It parses the parameters to arrays in case any of these is an object.
 * @param {object/array} point1    - Object with x and y coords for point1.
 * @param {object/array} point2    - Object with x and y coords for point2.
 * @return {number}                - Vector between points.
 */
EZModelUtils.getVector = function(point1, point2) {
    if (!Array.isArray(point1)) {
        point1 = [point1.x, point1.y];
    }
    if (!Array.isArray(point2)) {
        point2 = [point2.x, point2.y];
    }
    return Math.sqrt(
        Math.pow(parseFloat(point1[0]) - parseFloat(point2[0]), 2) +
        Math.pow(parseFloat(point1[1]) - parseFloat(point2[1]), 2)
    );
};


/**
 * Function to get Base64 Images.
 * It parses the parameters to arrays in case any of these is an object.
 * @param {string} url    - image's url
 * @return {string}       - image in base64.
 */
EZModelUtils.getBase64Image = function(url) {
    return document.getElementById(url).src;
};


/**
 *
 * @param   {number} number
 * @return {string}
 */
EZModelUtils.getHexColor = function(number) {
    return '#' + ((number) >>> 0).toString(16).slice(-6);
};

/// Snapshot Utils

/**
 * get dataURL png image from canvas
 * @param  {object} canvas a canvas element to adquire image
 * @return {object}        image DOM element
 */
EZModelUtils.getImg = function (canvas) {
    const url = canvas.toDataURL('image/png');

    const img = document.createElement('img');
    img.width = canvas.width;
    img.height = canvas.height;
    img.src = url;

    return img;
};

/**
 * draw scaled version of the img in a new 2d canvas
 * @param  {object} img    image dom element
 * @param  {float} ratioX factor for x scale
 * @param  {float} ratioY factor for y scale
 * @param  {boolean} draw   true to append the new canvas in the DOM, false for invisible canvas
 * @param  {string} id     id for the canvas
 * @param  {boolean} texture   true if the canvas is a real size texture
 * @return {object}        2d canvas DOM element
 */
EZModelUtils.resizeImage = function (img, ratioX, ratioY, draw, id, texture) {
    const canvas = d3.select('#texture-operations-canvas')
        .append('canvas')
        .attr('width', img.width)
        .attr('height', img.height)
        .style('display', function() {
            let value = 'none';
            if (draw) {
                value = 'inline-block';
            }
            return value;
        })
        .attr('id', id)
        .node();

    if (texture === true) {
        d3.select(canvas)
            .style('margin-right', '5px')
            .style('margin-left', '5px')
            .lower();
    }

    const ctx = canvas.getContext('2d');
    ctx.imageSmoothingEnabled = false;
    const canvasCopy = document.createElement('canvas');
    const copyContext = canvasCopy.getContext('2d');

    canvasCopy.width = img.width;
    canvasCopy.height = img.height;
    copyContext.drawImage(img, 0, 0);

    canvas.width = THREE.Math.floorPowerOfTwo(img.width * ratioX);
    canvas.height = THREE.Math.floorPowerOfTwo(img.height * ratioY);
    ctx.drawImage(canvasCopy, 0, 0, canvasCopy.width, canvasCopy.height,
        0, 0, canvas.width, canvas.height);

    return canvas;
}

EZModelUtils.capitalize = function(string) {
    if (EZModelUtils.isNumber(string.charAt(0))) {
        return string;
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
};

EZModelUtils.toFixed = function(num, fixed) {
    var re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
    return num.toString().match(re)[0];
};

EZModelUtils.addUnderscore = function(string) {
    return string.split(' ').join('_');
};

EZModelUtils.isNumber = function(n) {
    return !isNaN(parseFloat(n)) && isFinite(n) && !Array.isArray(n);
};

EZModelUtils.isUuidV4 = function(str) {
    var uuidV4Regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[4][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return uuidV4Regex.test(str);
};

EZModelUtils.formatDateTime = function(n) {
    return n.split('T');
};

EZModelUtils.camelCaseWithSeparator = function(string, separator) {
    const wordsArray = string.split(separator);
    return wordsArray
        .map((word, i) => (i === 0) ? word : this.capitalize(word))
        .join('');
};

/**
 * Returns number (n) in string, with 'decimalDigits' indicated,  in local
 * format
 * @param  {number} n   number to be converted
 * @param  {number} decimalDigits Fraction digits to be showed
 * @return {String}        number in String with local format
 */
EZModelUtils.formatDecimalComma = function (n, decimalDigits = 2) {
    if (n === undefined) {
        console.warn('n param is undefined');
        return '';
    }
    return n.toLocaleString(undefined, {
        minimumFractionDigits: decimalDigits,
        maximumFractionDigits: decimalDigits,
    });
};

EZModelUtils.voronoiStrokeScale = d3.scalePow().exponent(0.5)
    .domain([0.25, 200])
    .range([0.01, 1 / 1000]);

EZModelUtils.voronoiPrecisionScale = d3.scaleLinear()
    .domain([0.25, 1, 2, 5, 10, 15, 50, 100, 150, 200])
    .range([10, 3, 1.5, 0.6, 0.3, 0.2, 0.07, 0.04, 0.03, 0.02]);

/**
 * pathPrecisionSample returns a set of points in the given pathNode
 * simplified to a given precision
 * @param  {[type]} pathNode  [description]
 * @param  {[type]} precision [description]
 * @return {[type]}           [description]
 */
EZModelUtils.pathPrecisionSample = function(pathNode, precision) {
    var samples = [];
    if (pathNode) {
        var pathLength = pathNode.getTotalLength();
        for (var sampleVal, sampleLength = 0; sampleLength <= pathLength; sampleLength += precision) {
            sampleVal = pathNode.getPointAtLength(sampleLength);
            samples.push([sampleVal.x, sampleVal.y]);
        }
    }
    return samples;
}

