/**
 * @type {EventEmitter}
 */
const ee = new EventEmitter();

const xmlhttp = new XMLHttpRequest();

/**
 * Ezzing3D
 * @class
 * @classdesc Main 3DLayout class.
 *
 * When bootstrap is executed:
 *
 * * it creates the main html template inside the container
 *
 * * and instantiate the main objects:
 *  * {@link EZModelScene}     - The model object (stores the project)
 *  * {@link EZViewport}    - manages the viewport (2d & 3d canvas, aside GUI and buttons)
 *  * {@link EZApiConstructor} - The API object
 *
 * @description The constructor just create the object, which waits to be bootstrapped.
 */
class Ezzing3D {
    /**
     * Ezzing3D constructor
     */
    constructor() {
        this.ez3dScene = undefined;
        this.ez3dViewport = undefined;
        this.EZApi = undefined;
    }
    /**
     * function to merge custom preferences with default (non declared) preferences
     *
     * @param  {object} preferences - object with custom preferences
     *
     * @return {object}     object with custom preferences and default prefs merged
     */
    static loadPreferences(preferences) {
        const {mergeDefaultValues} = EZModelUtils;
        let localConfig;
        // import vars from the config.json
        /* eslint-disable */
        let apiURL = ('@@apiURL' === 'undefined') ? undefined : '@@apiURL';
        if (apiURL[apiURL.length - 1] === '/') {
            apiURL = apiURL.slice(0, -1);
        }
        if (!apiURL.includes('/api')) {
            apiURL += '/api';
        }
        const defaultLanguage = ('@@defaultLanguage' === 'undefined')
            ? undefined : '@@defaultLanguage';
        const customLogoUrl = ('@@customLogoUrl' === 'undefined')
            ? undefined : '@@customLogoUrl';
        const defaultUnits = ('@@defaultUnits' === 'undefined')
            ? undefined : '@@defaultUnits';
        const userToken = ('@@userToken' === 'undefined')
            ? undefined : '@@userToken';
        localConfig = {
            // @@ vars are imported from the config.json
            // layoutId: @@layoutId,
            // token: @@token,
            //
            apiURL: apiURL,
            customLogoUrl: customLogoUrl,
            defaultLanguage: defaultLanguage,
            defaultUnits: defaultUnits,
            userToken: userToken,
            //
            activeMapper: @@activeMapper,
            activeRenderer: @@activeRenderer,
            angleBias: @@angleBias,
            closeRightCanvas: @@closeRightCanvas,
            collisionBias: @@collisionBias,
            debugPromises: @@debugPromises,
            defaultProjectId: @@defaultProjectId,
            enableApi: @@enableApi,
            enableNotifications: @@enableNotifications,
            enablePlayer: @@enablePlayer,
            enableUndoRedo: @@enableUndoRedo,
            gridOffsetX: @@gridOffsetX,
            gridOffsetY: @@gridOffsetY,
            gridSize: @@gridSize,
            loadMockup: @@loadMockup,
            manageFullscreen: @@manageFullscreen,
            movementStep: @@movementStep,
            showCoordinates: @@showCoordinates,
            showTotalPower: @@showTotalPower,
            snapShotCrm: @@snapShotCrm,
            snapToGrid: @@snapToGrid,
            snapToGuides: @@snapToGuides,
            viewportMode: @@viewportMode
        };

        // merge config.json scenePreferences over default layoutRules.scenePreferences
        layoutRules.scenePreferences = mergeDefaultValues(
            layoutRules.scenePreferences, localConfig);

        // merge integrator layoutRules over default layoutRules
        layoutRules = (preferences)
            ? mergeDefaultValues(layoutRules, preferences) : layoutRules;

        defaultButtons = null;
        defaultLanguages = null;
        defaultColors = null;
        defaultModules = null;
        defaultModelValues = null;
        defaultProviders = null;
        scenePreferencesDefaultValues = null;

        areaDefaultValues = null;
        buildingDefaultValues = null;
        keepoutDefaultValues = null;
        roofDefaultValues = null;
        roofByTypeDefaultValues = null;
        roofShapesDefaultValues = null;
        subareaDefaultValues = null;
        subareaByRoofTypeDefaultValues = null;
        treeDefaultValues = null;

        if (preferences && preferences.token) {
            layoutRules.scenePreferences.token = preferences.token;
            layoutRules.scenePreferences.apiKey = undefined;
            layoutRules.scenePreferences.urlHash = undefined;
        } else if (preferences && preferences.apiKey) {
            layoutRules.scenePreferences.token = undefined;
            layoutRules.scenePreferences.apiKey = preferences.apiKey;
            layoutRules.scenePreferences.urlHash = undefined;
        } else if (preferences && preferences.urlHash) {
            layoutRules.scenePreferences.token = undefined;
            layoutRules.scenePreferences.apiKey = undefined;
            layoutRules.scenePreferences.urlHash = preferences.urlHash;
        } else {
            layoutRules.scenePreferences.token =
                layoutRules.scenePreferences.userToken;
            layoutRules.scenePreferences.apiKey = undefined;
            layoutRules.scenePreferences.urlHash = undefined;
        }
        return layoutRules;
    }
    /**
     * loading sequence
     */
    _loadingSequence () {
        var layoutId = this.ez3dScene.layoutRules.layoutId;
        if (this.ez3dScene.layoutRules.scenePreferences.loadMockup === false) {
            if (layoutId === 0 &&
                (this.ez3dScene.layoutRules.scenePreferences.urlHash === undefined)) {
                this.ez3dScene.generateModel();
                this.ez3dViewport.loadProject(ez3dScene);
            } else {
                this.ez3dScene.getProject(layoutId, (response) => {
                    this.ez3dScene.loadProject(response);
                    this.ez3dScene.generateModel();
                    this.ez3dViewport.loadProject(ez3dScene);
                });
            }
        } else {
            console.warn('Loading Mockup Project');
            this.ez3dScene.loadProject(project);
            this.ez3dScene.generateModel();
            this.ez3dViewport.loadProject(ez3dScene);
        }
        console.log('Loading 3DLayout v' +
            this.ez3dScene.version);
    }

    /**
     * The bootstrap function launch the app deploy:
     *
     * * paste the main html template (ez3d-main-container) inside the container
     *
     * * merge all the layoutRules (config.json -> ops -> default)
     *
     * * and instantiate the main objects:
     *
     *  * {@link EZModelScene}      - The model object (stores the project)
     *  * {@link EZViewport}        - manages the viewport (2d & 3d canvas, aside GUI and buttons)
     *  * {@link EZApiConstructor}  - The API object
     *
     * @param  {string}         container     - The id of the html container where the layout will be created
     * @param  {layoutRules}    [ops] - json with the rules and config files to create the layout
     * @param  {Function}       [callback]    - function to be launched once layout is ready
     */
    bootstrap (container, htmlfile, ops, callback) {
        // @todo replace with a proper import when we have ES6+ modules
        const {isValidModule} = EZModelUtils;

        xmlhttp.onreadystatechange = () => {
            if (xmlhttp.readyState !== 4 || xmlhttp.status !== 200) return;

            container.innerHTML = xmlhttp.responseText;
            const invisibleViewportModes = [0, 3, 9];
            const isInvisibleViewport = ops && ops.scenePreferences &&
                invisibleViewportModes.includes(ops.scenePreferences.viewportMode);

            // @todo this should happen in viewport mode activation
            if (isInvisibleViewport) {
                container.style.display = 'none';
            }
            // control sentry error
            if (ops.scenePreferences && ops.scenePreferences.viewportMode) {
                console.log('viewportMode' + ops.scenePreferences.viewportMode);
            }

            const ez3dMainContainer = document.getElementById('ez3d-main-container');

            this.ez3dScene = new EZModelScene(Ezzing3D.loadPreferences(ops), this);
            // window.ez3dScene = this.ez3dScene;

            ez3dScene.layoutRules.layoutId = (ops && ops.id) ? ops.id
                : ez3dScene.layoutRules.scenePreferences.defaultProjectId;

            // @todo viewport quizá deberia instanciarse despues, en el loading sequence...
            //
            this.ez3dViewport = new EZViewport(ez3dScene, this);
            window.ez3dViewport = this.ez3dViewport;

            if (ez3dScene.layoutRules.scenePreferences.userData.browser !== 'Chrome') {
                this.ez3dViewport.showBrowserAlert();
            } else if (_.isEmpty(ez3dScene.layoutRules.defaultModules)) {
                this.ez3dViewport.showEmptyModulesAlert();
                ez3dScene.layoutRules.defaultModelValues.area.disabled = true;
            } else if (ez3dScene.layoutRules.defaultModules.every(isValidModule)) {
                if (ez3dScene.layoutRules.scenePreferences.enableApi === true) {
                    this.EZApi = new EZApiConstructor(ez3dScene);
                    window.EZApi = this.EZApi;
                }

                ee.addOnceListener('viewportReady', () => {
                    console.log('viewportReady');
                    if (callback !== undefined) {
                        callback(undefined, this.EZApi, ez3dMainContainer);
                    }
                });

                this._loadingSequence();
            } else {
                const invalidModulesId = ez3dScene.layoutRules.defaultModules
                    .filter(_.negate(isValidModule))
                    .map((module) => module.id);
                this.ez3dViewport.showInvalidModulesAlert(invalidModulesId);
            }
        };
        xmlhttp.open('GET', '@@ezzing3dURL/' + htmlfile, true);
        if (ops) {
            xmlhttp.setRequestHeader('X-Api-Key', ops.token || ops.apiKey);
        }
        xmlhttp.send();
    }
}

window.Ezzing3D = Ezzing3D;
