EZModelArea.prototype.benchmarkSetAttributeWithoutId = function(args) {
    var promise = new Promise((resolve, reject) => {
        var objectId = ez3dScene.active.id;
        var attr = args[0];
        var value = args[1];

        ez3dScene.setAttribute([objectId, attr, value])
            .then((result) => {
                var status = {
                    elementId: objectId,
                    status: 'FINISHED',
                };
                ee.emitEvent('updatePanels');
                resolve(status);
            });
    });

    return promise;
};

EZModelBuilding.prototype.benchmarkSetAttributeWithoutId = function(args) {
    var promise = new Promise((resolve, reject) => {
        var objectId = ez3dScene.active.id;
        var attr = args[0];
        var value = args[1];

        ez3dScene.setAttribute([objectId, attr, value])
            .then((result) => {
                var status = {
                    elementId: objectId,
                    status: 'FINISHED',
                };
                ee.emitEvent('updatePanels');
                resolve(status);
            });
    });

    return promise;
};

EZModelKeepout.prototype.benchmarkSetAttributeWithoutId = function(args) {
    var promise = new Promise((resolve, reject) => {
        var objectId = ez3dScene.active.id;
        var attr = args[0];
        var value = args[1];

        ez3dScene.setAttribute([objectId, attr, value])
            .then((result) => {
                var status = {
                    elementId: objectId,
                    status: 'FINISHED',
                };
                ee.emitEvent('updatePanels');
                resolve(status);
            });
    });

    return promise;
};

EZModelScene.prototype.benchmarkDispatchClick = function(htmlId) {
    var promise = new Promise((resolve, reject) => {
        if (d3.select('#' + htmlId).node() === null) {
            reject({status: 'CANCELLED', error: 'no element with this id: ' + htmlId});
        } else {
            d3.select('#' + htmlId).dispatch('click');
            resolve({status: 'FINISHED', args: htmlId});
        }
    });

    return promise;
};

EZModelScene.prototype.benchmarkExecuteEvent = function(eventObject, isWaitOp) {
    var promise = new Promise((resolve, reject) => {
        var event = eventObject.event;
        var args = eventObject.args;
        var notifications = eventObject.notifications;
        var updateRender = eventObject.updateRender;
        var eventFinished = eventObject.eventFinished;

        if (eventFinished) {
            ee.addOnceListener(eventFinished, function(data, time) {
                if (time && !isWaitOp) {
                    if (isNaN(time)) {
                        ez3dScene.benchmarkResume += ' (' + time + ')';
                    } else {
                        ez3dScene.benchmarkResume += ': ' + (Date.now() - time) / 1000 + 's';
                    }
                }
                resolve({status: 'FINISHED', args: data, time: time});
            });
        } else {
            resolve({status: 'FINISHED'});
        }

        ee.emitEvent(event, [{
            args: args,
            notifications: notifications,
            updateRender: updateRender}]
        );
    });

    return promise;
};

EZModelScene.prototype.benchmarkExecuteEventsArray = function(eventList, delay) {
    this.benchmarkResume = '\n*************************************' +
                           '\n*                                   *' +
                           '\n* Benchmark. Operations and timings *' +
                           '\n*                                   *' +
                           '\n*************************************';

    var promise = new Promise((resolve, reject) => {
        if (!delay) {
            delay = 10;
        }

        var waitEvent = {
            event: 'EZModelScene_waitListener',
            args: delay,
            undo: false,
            notifications: false,
            updateRender: true,
            eventFinished: 'wait_Finished'
        };

        var eventsWithDelays = [];
        eventList.forEach(function(event, i) {
            eventsWithDelays.push(event);
            if (i !== eventList.length - 1) {
                eventsWithDelays.push(waitEvent);
            }
        });

        var bigPromiseTime = Date.now();
        var counter = 0;
        var bigPromise = eventsWithDelays.reduce((accumulatorPromise, eventObject) => {
            return accumulatorPromise.then(() => {
                // Add newStep or subStep in benchmarkResume
                if (eventObject.newStep) {
                    ez3dScene.benchmarkResume += '\n\n\n* ' + eventObject.newStep + ' *';
                } else if (eventObject.subStep) {
                    ez3dScene.benchmarkResume += '\n\n--- ' + eventObject.subStep + ' ---';
                }
                // Add resume if the current promise isn't a wait operator
                var isWaitOp = eventObject.event.indexOf('wait') > -1;
                if (!isWaitOp) {
                    counter++;
                    ez3dScene.benchmarkResume += '\n' + counter + '. ' + eventObject.event;
                }

                return ez3dScene.benchmarkExecuteEvent(eventObject, isWaitOp);
            });
        }, Promise.resolve());

        bigPromise.then((result) => {
            // Benchmark time
            bigPromiseTime = (Date.now() - bigPromiseTime) / 1000;
            var minutes = Math.floor(bigPromiseTime / 60);
            var seconds = Math.floor(bigPromiseTime - minutes * 60);

            // Show benchmark resume
            var projectInfo = ez3dScene.getTotalModulesAndPowerStr();
            ez3dScene.benchmarkResume += '\n\n\n*************************************\n\n' +
            'Navigator: ' + this.layoutRules.scenePreferences.userData.browser + '\n' +
            'Operative System: ' + this.layoutRules.scenePreferences.userData.OS + '\n\n' +
            'Total time: ' + ((minutes < 10) ? '0' + minutes : minutes) + ':' + ((seconds < 10) ? '0' + seconds : seconds) + 's ' +
            '(' + projectInfo.totalModules + ' modules generating ' + projectInfo.totalPower + 'WP)';
            console.log(ez3dScene.benchmarkResume);
            resolve({status: 'FINISHED', time: bigPromiseTime});
        }).catch((error) => {
            console.error('Cancelled list of events execution');
            reject({status: 'CANCELLED', error: error});
        });
    });

    return promise;
};

EZModelScene.prototype.benchmarkMoveRoofPoints = function(args) {
    var self = this;
    var promise = new Promise((resolve) => {
        var roofPoints = self.active.roofPoints;
        var roofPoint = roofPoints[args[0]];
        var movement = args[1];

        // Set roof point active
        self.setActive(roofPoint.id)
            .then(() => {
                // Move roof point
                self.active.move(movement)
                    .then(() => {
                        // Set roof active
                        self.setActive(self.active.parent.id)
                            .then(() => {
                                var status = {
                                    elementId: self.active.id,
                                    status: 'FINISHED'
                                };
                                resolve(status);
                            });
                    });
            });
    });

    return promise;
};

EZModelScene.prototype.benchmarkSetActiveWithoutId = function(args) {
    var promise = new Promise((resolve, reject) => {
        var object = '';
        // args[0] is the string selector and args[1] the position on the results array
        if (args.length > 1) {
            object = d3.selectAll(args[0]).nodes()[args[1]];
        } else {
            object = d3.select(args[0]).node();
        }
        var objectId = object.id.replace('foldable-group-', '').replace('svgProject-roofPoints-EZModelBuilding_', '');

        ez3dScene.setActive(objectId)
            .then((result) => {
                var status = {
                    elementId: objectId,
                    status: 'FINISHED'
                };
                ee.emitEvent('updatePanels');
                resolve(status);
            });
    });

    return promise;
};

EZModelScene.prototype.benchmarkSetAttributeScene = function(args) {
    var promise = new Promise((resolve, reject) => {
        var objectId = '';
        var attr = args[0];
        var value = args[1];

        ez3dScene.setAttribute([objectId, attr, value])
            .then((result) => {
                var status = {
                    elementId: objectId,
                    status: 'FINISHED',
                };
                ee.emitEvent('updatePanels');
                resolve(status);
            });
    });

    return promise;
};

// fitRangeToContainer
//
// to get a valid range:

// ez3dViewport.svgElements.svgProject.limits
// to use with limits = true,
// or
// ez3dScene.utils.calculateBuildingRange(ez3dScene.active)
// to use with limits = false
//
// pass as argument an object like this :
//    {
//         range: [-436.1244258311224, -47.34565381459482, -382.3712409468079, 8.045277725607342],
//         duration: 5000,
//         limits: true,
//         container: 'svgProject'
//     }
// limits = false for building ranges, true for container limits
//
EZModelScene.prototype.fitRangeToContainer = function(args) {
    var self = this;
    var promise = new Promise((resolve, reject) => {
        var range = args.range;
        var duration = args.duration || 250;
        var limits = args.limits || false;
        var containerName = args.container || 'svgProject';
        var svgContainer = ez3dViewport.svgElements[containerName];
        if (svgContainer) {
            svgContainer.fitRangeToContainer(range, duration, limits);
            self.wait(duration)
                .then((result) => {
                    resolve({status: 'FINISHED', args: range});
                });
        } else {
            reject({status: 'CANCELLED', error: 'no container with this name: ' + containerName});
        }
    });

    return promise;
};

EZModelScene.prototype.wait = function(milliseconds) {
    var promise = new Promise((resolve, reject) => {
        ee.addOnceListener('ez3d-wait-finished', function(milliseconds) {
            resolve({status: 'FINISHED', args: milliseconds});
        });

        /**
         * Wait between operators
         * @param {float} milliseconds Miliseconds
         * @param {date} start Date.now.
         */
        function wait(milliseconds, start) {
            var elapsed = Date.now() - start;
            if (elapsed < milliseconds) {
                setTimeout(wait, 100, milliseconds, start);
            } else {
                ee.emitEvent('ez3d-wait-finished', [milliseconds]);
            }
        }
        var start = Date.now();
        wait(milliseconds, start);
    });

    return promise;
};

EZModelScene.prototype.benchmark10KModVDV = function (generateTextures) {
    var eventList = [
        // Change project center
        {
            event: 'EZModelScene_changeProjectCenterListener',
            args: {lat: 40.43958633830143, lng: -3.5473344587619104},
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'changeProjectCenter_Finished',
            newStep: 'Change project center'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1500,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // Deactivate textures
        {
            event: 'EZModelScene_benchmarkSetAttributeSceneListener',
            args: ['layoutRules.scenePreferences.buildingTextures', false],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetAttributeScene_Finished'
        },
        // Deactivate custom shapes
        {
            event: 'EZModelScene_benchmarkSetAttributeSceneListener',
            args: ['layoutRules.scenePreferences.enableBuildingShapes', false],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'benchmarkSetAttributeScene_Finished'
        },
        // Fit range
        {
            event: 'EZModelScene_fitRangeToContainerListener',
            args: {
                range: [-225.49109705732923, -267.4707501291438, 221.9010940542173, 179.00746306389084],
                duration: 5000,
                limits: true,
                container: 'svgProject'
            },
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'fitRangeToContainer_Finished',
            newStep: 'Fit range'
        },
        // Create building 1
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-index-add-building',
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'activeUpdated',
            newStep: 'Create building 1'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.44038192853998,
                    lng: -3.5489598100594044
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.43960356985887,
                    lng: -3.54830391339522
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.439474133828654,
                    lng: -3.5485692123048733
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.44025180351997,
                    lng: -3.549224766983096
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'building-create-panel_ez3dBottomPanel button',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'EW',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'setAttribute_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-finish-roof-edit',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'finishRoofEditOperator'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'roof-details-panel_ez3dBottomPanel .ez3d-square-button-green',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-widget-buildingHeight i',
            undo: false,
            notifications: false,
            updateRender: false,
        },
        // ---- Set building height ----
        {
            event: 'EZModelBuilding_benchmarkSetAttributeWithoutIdListener',
            args: ['ridge.height', 20],
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'benchmarkSetAttributeWithoutId_Finished',
            subStep: 'Set building height'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Create keepout 1 --
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-add-keepout',
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'activeUpdated',
            subStep: 'Create keepout 1'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.440299645425476,
                    lng: -3.5490816670331924
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.440303011183865,
                    lng: -3.549074812688804
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.44028040661635,
                    lng: -3.549055770051968
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.440277040326116,
                    lng: -3.549062623210239
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'keepout-create-panel_ez3dBottomPanel button',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        // ---- Set keepout offset ----
        {
            event: 'EZModelKeepout_benchmarkSetAttributeWithoutIdListener',
            args: ['offset', 0.7],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetAttributeWithoutId_Finished'
        },
        // ---- Set keepout height ----
        {
            event: 'EZModelKeepout_benchmarkSetAttributeWithoutIdListener',
            args: ['height', 2],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetAttributeWithoutId_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Create keepout 2 --
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'keepout-info-panel_ez3dInfoPanel .fa-clone',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated',
            subStep: 'Create keepout 2'
        },
        {
            event: 'EZModelKeepout_moveListener',
            args: [
                {
                    lat: -0.0007152904433525009,
                    lng: 0.0006003106428384974
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Create keepout 3 --
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-add-keepout',
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'activeUpdated',
            subStep: 'Create keepout 3'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.44024181199878,
                    lng: -3.5490332942345337
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.44024517775717,
                    lng: -3.549026439890145
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.440199732516746,
                    lng: -3.548988148366618
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.44019636471932,
                    lng: -3.5489949980061266
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'keepout-create-panel_ez3dBottomPanel button',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        // ---- Set keepout offset ----
        {
            event: 'EZModelKeepout_benchmarkSetAttributeWithoutIdListener',
            args: ['offset', 0.7],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetAttributeWithoutId_Finished'
        },
        // ---- Set keepout height ----
        {
            event: 'EZModelKeepout_benchmarkSetAttributeWithoutIdListener',
            args: ['height', 2],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetAttributeWithoutId_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Create keepout 4 --
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'keepout-info-panel_ez3dInfoPanel .fa-clone',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated',
            subStep: 'Create keepout 4'
        },
        {
            event: 'EZModelKeepout_moveListener',
            args: [
                {
                    lat: -0.00007484371207056029,
                    lng: 0.00006251503929410873
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Create keepout 5 --
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'keepout-info-panel_ez3dInfoPanel .fa-clone',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated',
            subStep: 'Create keepout 5'
        },
        {
            event: 'EZModelKeepout_moveListener',
            args: [
                {
                    lat: -0.00007430912219064112,
                    lng: 0.00006181255744586167
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Create keepout 6 --
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'keepout-info-panel_ez3dInfoPanel .fa-clone',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated',
            subStep: 'Create keepout 6'
        },
        {
            event: 'EZModelKeepout_moveListener',
            args: [
                {
                    lat: -0.00007377452730139566,
                    lng: 0.000059705236821194774
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Create keepout 7 --
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'keepout-info-panel_ez3dInfoPanel .fa-clone',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated',
            subStep: 'Create keepout 5'
        },
        {
            event: 'EZModelKeepout_moveListener',
            args: [
                {
                    lat: -0.00007537831869797174,
                    lng: 0.00006251482046071288
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Create keepout 8 --
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'keepout-info-panel_ez3dInfoPanel .fa-clone',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated',
            subStep: 'Create keepout 8'
        },
        {
            event: 'EZModelKeepout_moveListener',
            args: [
                {
                    lat: -0.00007377452375578741,
                    lng: 0.0000611099117482361
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Create keepout 9 --
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'keepout-info-panel_ez3dInfoPanel .fa-clone',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated',
            subStep: 'Create keepout 9'
        },
        {
            event: 'EZModelKeepout_moveListener',
            args: [
                {
                    lat: -0.0000737745326873096,
                    lng: 0.00006181218514278441
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Create keepout 10 --
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'keepout-info-panel_ez3dInfoPanel .fa-clone',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated',
            subStep: 'Create keepout 10'
        },
        {
            event: 'EZModelKeepout_moveListener',
            args: [
                {
                    lat: -0.00007575500013956571,
                    lng: 0.00006047690320043486
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // Create building 2
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-index-add-building',
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'activeUpdated',
            newStep: 'Create building 2'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.44025180348388,
                    lng: -3.5492247668014225
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.439474133861985,
                    lng: -3.548569210092734
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.4393770246308,
                    lng: -3.548768453085583
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.44015433533214,
                    lng: -3.5494235215322085
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'building-create-panel_ez3dBottomPanel button',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'EW',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'setAttribute_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-finish-roof-edit',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'finishRoofEditOperator'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'roof-details-panel_ez3dBottomPanel .ez3d-square-button-green',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-widget-buildingHeight i',
            undo: false,
            notifications: false,
            updateRender: false,
        },
        // ---- Set building height ----
        {
            event: 'EZModelBuilding_benchmarkSetAttributeWithoutIdListener',
            args: ['ridge.height', 17],
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'benchmarkSetAttributeWithoutId_Finished',
            subStep: 'Set building height'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Create keepout 1 --
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-add-keepout',
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.440084226289166,
                    lng: -3.5492198505783583
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.440080926037155,
                    lng: -3.549226617131092
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.44012443073849,
                    lng: -3.5492632846611656
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.440127737603554,
                    lng: -3.549256531647926
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'keepout-create-panel_ez3dBottomPanel button',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        // ---- Set keepout offset ----
        {
            event: 'EZModelKeepout_benchmarkSetAttributeWithoutIdListener',
            args: ['offset', 0.7],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetAttributeWithoutId_Finished'
        },
        // ---- Set keepout height ----
        {
            event: 'EZModelKeepout_benchmarkSetAttributeWithoutIdListener',
            args: ['height', 2],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetAttributeWithoutId_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Create keepout 2 --
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'keepout-info-panel_ez3dInfoPanel .fa-clone',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated',
            subStep: 'Create keepout 2'
        },
        {
            event: 'EZModelKeepout_moveListener',
            args: [
                {
                    lat: -0.0001574080303683445,
                    lng: 0.00013168952112607712
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Create keepout 3 --
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'keepout-info-panel_ez3dInfoPanel .fa-clone',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated',
            subStep: 'Create keepout 3'
        },
        {
            event: 'EZModelKeepout_moveListener',
            args: [
                {
                    lat: -0.00007388538487163032,
                    lng: 0.0000607797107301522
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Create keepout 4 --
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'keepout-info-panel_ez3dInfoPanel .fa-clone',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated',
            subStep: 'Create keepout 4'
        },
        {
            event: 'EZModelKeepout_moveListener',
            args: [
                {
                    lat: -0.00007517034479320728,
                    lng: 0.000059935469079785975
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Create keepout 5 -
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'keepout-info-panel_ez3dInfoPanel .fa-clone',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated',
            subStep: 'Create keepout 5'
        },
        {
            event: 'EZModelKeepout_moveListener',
            args: [
                {
                    lat: -0.00007388538487163032,
                    lng: 0.00006077955124617063
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Create keepout 6 --
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'keepout-info-panel_ez3dInfoPanel .fa-clone',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated',
            subStep: 'Create keepout 6'
        },
        {
            event: 'EZModelKeepout_moveListener',
            args: [
                {
                    lat: -0.00015740803037544993,
                    lng: 0.00013168870554824963
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // Create building 3
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-index-add-building',
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'activeUpdated',
            newStep: 'Create building 3'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.44047955986652,
                    lng: -3.5487600856197594
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.43970189024462,
                    lng: -3.548104528911071
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.439604781013436,
                    lng: -3.54830377190392
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.440382091714774,
                    lng: -3.5489588403505454
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'building-create-panel_ez3dBottomPanel button',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'EW',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'setAttribute_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-finish-roof-edit',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'finishRoofEditOperator'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'roof-details-panel_ez3dBottomPanel .ez3d-square-button-green',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-widget-buildingHeight i',
            undo: false,
            notifications: false,
            updateRender: false,
        },
        // ---- Set building height ----
        {
            event: 'EZModelBuilding_benchmarkSetAttributeWithoutIdListener',
            args: ['ridge.height', 17],
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'benchmarkSetAttributeWithoutId_Finished',
            subStep: 'Set building height'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Create keepout 1 --
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-add-keepout',
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.4403119826718,
                    lng: -3.548755169396695
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.44030868241979,
                    lng: -3.548761935949429
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.44035218712113,
                    lng: -3.5487986034795025
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.44035549398619,
                    lng: -3.5487918504662628
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'keepout-create-panel_ez3dBottomPanel button',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        // ---- Set keepout offset ----
        {
            event: 'EZModelKeepout_benchmarkSetAttributeWithoutIdListener',
            args: ['offset', 0.7],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetAttributeWithoutId_Finished'
        },
        // ---- Set keepout height ----
        {
            event: 'EZModelKeepout_benchmarkSetAttributeWithoutIdListener',
            args: ['height', 2],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetAttributeWithoutId_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Create keepout 2 --
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'keepout-info-panel_ez3dInfoPanel .fa-clone',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated',
            subStep: 'Create keepout 2'
        },
        {
            event: 'EZModelKeepout_moveListener',
            args: [
                {
                    lat: -0.0001574080303683445,
                    lng: 0.00013168952112607712
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Create keepout 3 --
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'keepout-info-panel_ez3dInfoPanel .fa-clone',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated',
            subStep: 'Create keepout 3'
        },
        {
            event: 'EZModelKeepout_moveListener',
            args: [
                {
                    lat: -0.00007388538487163032,
                    lng: 0.0000607797107301522
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Create keepout 4 --
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'keepout-info-panel_ez3dInfoPanel .fa-clone',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated',
            subStep: 'Create keepout 4'
        },
        {
            event: 'EZModelKeepout_moveListener',
            args: [
                {
                    lat: -0.00007517034479320728,
                    lng: 0.000059935469079785975
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Create keepout 5 -
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'keepout-info-panel_ez3dInfoPanel .fa-clone',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated',
            subStep: 'Create keepout 5'
        },
        {
            event: 'EZModelKeepout_moveListener',
            args: [
                {
                    lat: -0.00007388538487163032,
                    lng: 0.00006077955124617063
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Create keepout 6 --
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'keepout-info-panel_ez3dInfoPanel .fa-clone',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated',
            subStep: 'Create keepout 6'
        },
        {
            event: 'EZModelKeepout_moveListener',
            args: [
                {
                    lat: -0.00015740803037544993,
                    lng: 0.00013168870554824963
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // Create building 4
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-index-add-building',
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'activeUpdated',
            newStep: 'Create building 4'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.44085437015333,
                    lng: -3.5461266072658764
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.44007872454863,
                    lng: -3.5477111743094687
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.440637926481514,
                    lng: -3.5481839499882235
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.441415298678436,
                    lng: -3.5465985414202423
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'building-create-panel_ez3dBottomPanel button',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-select-gabled',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'EZModelRoof_setType'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-finish-roof-edit',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'finishRoofEditOperator'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'roof-details-panel_ez3dBottomPanel .ez3d-square-button-green',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-widget-buildingHeight i',
            undo: false,
            notifications: false,
            updateRender: false,
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Disable area 2 --
        {
            event: 'EZModelScene_benchmarkSetActiveWithoutIdListener',
            args: ['.ez3d-foldable-group-block-area:nth-child(2)'],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetActiveWithoutId_Finished',
            subStep: 'Disable area 2'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelArea_benchmarkSetAttributeWithoutIdListener',
            args: ['disabled', true],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetAttributeWithoutId_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // Create building 5
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-index-add-building',
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'activeUpdated',
            newStep: 'Create building 5'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.44046664134967,
                    lng: -3.545804193450985
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.439695919295474,
                    lng: -3.5473941353827874
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.43912903940369,
                    lng: -3.546919603648199
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.43990013414923,
                    lng: -3.5453328026437596
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'building-create-panel_ez3dBottomPanel button',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-select-gabled',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'EZModelRoof_setType'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-finish-roof-edit',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'finishRoofEditOperator'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'roof-details-panel_ez3dBottomPanel .ez3d-square-button-green',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-widget-buildingHeight i',
            undo: false,
            notifications: false,
            updateRender: false,
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Disable area 2 --
        {
            event: 'EZModelScene_benchmarkSetActiveWithoutIdListener',
            args: ['.ez3d-foldable-group-block-area:nth-child(2)'],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetActiveWithoutId_Finished',
            subStep: 'Disable area 2'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelArea_benchmarkSetAttributeWithoutIdListener',
            args: ['disabled', true],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetAttributeWithoutId_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
    ];

    // Generate all textures
    if (generateTextures === undefined || generateTextures === true) {
        eventList.push({
            event: 'tabChanged',
            args: 'ez3d-building-info-tab',
            undo: false,
            notifications: false,
            updateRender: false,
            subStep: 'Generate all textures'
        });
        eventList.push({
            event: 'EZModelScene_getTexturesListener',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'getTextures_Finished',
        });
        eventList.push({
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        });
    }

    // Fit range
    eventList.push({
        event: 'EZModelScene_fitRangeToContainerListener',
        args: {
            range: [-246.65815739331063, -258.63136857353834, 220.4990467265591, 187.84684461949615],
            duration: 5000,
            limits: true,
            container: 'svgProject'
        },
        undo: false,
        notifications: true,
        updateRender: false,
        eventFinished: 'fitRangeToContainer_Finished',
        newStep: 'Fit range'
    });

    ez3dScene.benchmarkExecuteEventsArray(eventList);
};

EZModelScene.prototype.benchmark10KMod = function (generateTextures) {
    var eventList = [
        // Change project center
        {
            event: 'EZModelScene_changeProjectCenterListener',
            args: {lat: 40.43958633830143, lng: -3.5473344587619104},
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'changeProjectCenter_Finished',
            newStep: 'Change project center'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1500,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // Deactivate textures
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-preferences-tab',
            undo: false,
            notifications: true,
            updateRender: false,
            newStep: 'Deactivate textures'
        },
        {
            event: 'EZModelScene_benchmarkSetAttributeSceneListener',
            args: ['layoutRules.scenePreferences.buildingTextures', false],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetAttributeScene_Finished'
        },
        // Deactivate custom shapes
        {
            event: 'EZModelScene_benchmarkSetAttributeSceneListener',
            args: ['layoutRules.scenePreferences.enableBuildingShapes', false],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'benchmarkSetAttributeScene_Finished'
        },
        // Fit range
        {
            event: 'EZModelScene_fitRangeToContainerListener',
            args: {
                range: [-225.49109705732923, -267.4707501291438, 221.9010940542173, 179.00746306389084],
                duration: 5000,
                limits: true,
                container: 'svgProject'
            },
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'fitRangeToContainer_Finished',
            newStep: 'Fit range'
        },
        // Create building 1
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-index-add-building',
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'activeUpdated',
            newStep: 'Create building 1'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.44038192853998,
                    lng: -3.5489598100594044
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.43960356985887,
                    lng: -3.54830391339522
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.439474133828654,
                    lng: -3.5485692123048733
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.44025180351997,
                    lng: -3.549224766983096
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-finish-building-create',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-select-gabled',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'EZModelRoof_setType'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-finish-roof-edit',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        // -- Create keepout 1 --
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-objects-tab',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'tabChanged',
            subStep: 'Create keepout 1'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-add-keepout',
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.440299645425476,
                    lng: -3.5490816670331924
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.440303011183865,
                    lng: -3.549074812688804
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.44028040661635,
                    lng: -3.549055770051968
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.440277040326116,
                    lng: -3.549062623210239
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-finish-keepout-create',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        // ---- Set keepout offset ----
        {
            event: 'EZModelKeepout_benchmarkSetAttributeWithoutIdListener',
            args: ['offset', 0.7],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetAttributeWithoutId_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Create keepout 2 --
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-clone-keepout',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated',
            subStep: 'Create keepout 2'
        },
        {
            event: 'EZModelKeepout_moveListener',
            args: [
                {
                    lat: -0.0007152904433525009,
                    lng: 0.0006003106428384974
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Create keepout 3 --
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-objects-tab',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'tabChanged',
            subStep: 'Create keepout 3'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-add-keepout',
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.44024181199878,
                    lng: -3.5490332942345337
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.44024517775717,
                    lng: -3.549026439890145
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.440199732516746,
                    lng: -3.548988148366618
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.44019636471932,
                    lng: -3.5489949980061266
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-finish-keepout-create',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        // ---- Set keepout offset ----
        {
            event: 'EZModelKeepout_benchmarkSetAttributeWithoutIdListener',
            args: ['offset', 0.7],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetAttributeWithoutId_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Create keepout 4 --
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-clone-keepout',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated',
            subStep: 'Create keepout 4'
        },
        {
            event: 'EZModelKeepout_moveListener',
            args: [
                {
                    lat: -0.00007484371207056029,
                    lng: 0.00006251503929410873
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Create keepout 5 --
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-clone-keepout',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated',
            subStep: 'Create keepout 5'
        },
        {
            event: 'EZModelKeepout_moveListener',
            args: [
                {
                    lat: -0.00007430912219064112,
                    lng: 0.00006181255744586167
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Create keepout 6 --
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-clone-keepout',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated',
            subStep: 'Create keepout 6'
        },
        {
            event: 'EZModelKeepout_moveListener',
            args: [
                {
                    lat: -0.00007377452730139566,
                    lng: 0.000059705236821194774
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Create keepout 7 --
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-clone-keepout',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated',
            subStep: 'Create keepout 7'
        },
        {
            event: 'EZModelKeepout_moveListener',
            args: [
                {
                    lat: -0.00007537831869797174,
                    lng: 0.00006251482046071288
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Create keepout 8 --
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-clone-keepout',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated',
            subStep: 'Create keepout 8'
        },
        {
            event: 'EZModelKeepout_moveListener',
            args: [
                {
                    lat: -0.00007377452375578741,
                    lng: 0.0000611099117482361
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Create keepout 9 --
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-clone-keepout',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated',
            subStep: 'Create keepout 9'
        },
        {
            event: 'EZModelKeepout_moveListener',
            args: [
                {
                    lat: -0.0000737745326873096,
                    lng: 0.00006181218514278441
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Create keepout 10 --
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-clone-keepout',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated',
            subStep: 'Create keepout 10'
        },
        {
            event: 'EZModelKeepout_moveListener',
            args: [
                {
                    lat: -0.00007575500013956571,
                    lng: 0.00006047690320043486
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // Create building 2
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-index-add-building',
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'activeUpdated',
            newStep: 'Create building 2'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.44025180348388,
                    lng: -3.5492247668014225
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.439474133861985,
                    lng: -3.548569210092734
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.4393770246308,
                    lng: -3.548768453085583
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.44015433533214,
                    lng: -3.5494235215322085
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-finish-building-create',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-select-gabled',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'EZModelRoof_setType'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-finish-roof-edit',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        // -- Create keepout 1 --
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-objects-tab',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'tabChanged',
            subStep: 'Create keepout 1'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-add-keepout',
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.440084226289166,
                    lng: -3.5492198505783583
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.440080926037155,
                    lng: -3.549226617131092
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.44012443073849,
                    lng: -3.5492632846611656
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.440127737603554,
                    lng: -3.549256531647926
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-finish-keepout-create',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        // ---- Set keepout offset ----
        {
            event: 'EZModelKeepout_benchmarkSetAttributeWithoutIdListener',
            args: ['offset', 0.7],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetAttributeWithoutId_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Create keepout 2 --
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-clone-keepout',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated',
            subStep: 'Create keepout 2'
        },
        {
            event: 'EZModelKeepout_moveListener',
            args: [
                {
                    lat: -0.0001574080303683445,
                    lng: 0.00013168952112607712
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Create keepout 3 --
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-clone-keepout',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated',
            subStep: 'Create keepout 3'
        },
        {
            event: 'EZModelKeepout_moveListener',
            args: [
                {
                    lat: -0.00007388538487163032,
                    lng: 0.0000607797107301522
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Create keepout 4 --
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-clone-keepout',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated',
            subStep: 'Create keepout 4'
        },
        {
            event: 'EZModelKeepout_moveListener',
            args: [
                {
                    lat: -0.00007517034479320728,
                    lng: 0.000059935469079785975
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Create keepout 5 --
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-clone-keepout',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated',
            subStep: 'Create keepout 5'
        },
        {
            event: 'EZModelKeepout_moveListener',
            args: [
                {
                    lat: -0.00007388538487163032,
                    lng: 0.00006077955124617063
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Create keepout 6 --
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-clone-keepout',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated',
            subStep: 'Create keepout 6'
        },
        {
            event: 'EZModelKeepout_moveListener',
            args: [
                {
                    lat: -0.00015740803037544993,
                    lng: 0.00013168870554824963
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // Create building 3
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-building-info-tab',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'setActive_Finished',
            subStep: 'Create building 3'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-clone-building-button',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated',
        },
        {
            event: 'EZModelBuilding_moveListener',
            args: [
                {
                    lat: 0.00023675638263398469,
                    lng: 0.00045568118166317007
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // Create building 4
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-index-add-building',
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'activeUpdated',
            newStep: 'Create building 4'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.44085437015333,
                    lng: -3.5461266072658764
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.44007872454863,
                    lng: -3.5477111743094687
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.440637926481514,
                    lng: -3.5481839499882235
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.441415298678436,
                    lng: -3.5465985414202423
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-finish-building-create',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-select-gabled',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'EZModelRoof_setType'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'SN',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'EZModelRoof_setOrientation'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-finish-roof-edit',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        // -- Disable area 2 --
        {
            event: 'EZModelScene_benchmarkSetActiveWithoutIdListener',
            args: ['.ez3d-foldable-group-block-area:nth-child(2)'],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetActiveWithoutId_Finished',
            subStep: 'Disable area 2'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelArea_benchmarkSetAttributeWithoutIdListener',
            args: ['disabled', true],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetAttributeWithoutId_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // Create building 5
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-building-info-tab',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'tabChanged',
            subStep: 'Create building 5'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-clone-building-button',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated',
        },
        {
            event: 'EZModelBuilding_moveListener',
            args: [
                {
                    lat: -0.0009350743165867925,
                    lng: 0.0007821080156085181
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-move-building-button',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'benchmarkDispatchClick_Finished'
        }
    ];

    // Generate all textures
    if (generateTextures === undefined || generateTextures === true) {
        eventList.push({
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-building-info-tab',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'tabChanged',
            newStep: 'Generate all textures'
        });
        eventList.push({
            event: 'EZModelScene_getTexturesListener',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'getTextures_Finished',
        });
        eventList.push({
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        });
    }

    // Fit range
    eventList.push({
        event: 'EZModelScene_fitRangeToContainerListener',
        args: {
            range: [-246.65815739331063, -258.63136857353834, 220.4990467265591, 187.84684461949615],
            duration: 5000,
            limits: true,
            container: 'svgProject'
        },
        undo: false,
        notifications: true,
        updateRender: false,
        eventFinished: 'fitRangeToContainer_Finished',
        newStep: 'Fit range'
    });

    ez3dScene.benchmarkExecuteEventsArray(eventList);
};

EZModelScene.prototype.benchmark1KModVDV = function (generateTextures) {
    var eventList = [
        // Change project center
        {
            event: 'EZModelScene_changeProjectCenterListener',
            args: {lat: 40.6538537, lng: -3.7654579},
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'changeProjectCenter_Finished',
            newStep: 'Change project center'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1500,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // Deactivate custom shapes
        {
            event: 'EZModelScene_benchmarkSetAttributeSceneListener',
            args: ['layoutRules.scenePreferences.enableBuildingShapes', false],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'benchmarkSetAttributeScene_Finished'
        },
        // Fit range
        {
            event: 'EZModelScene_fitRangeToContainerListener',
            args: {
                range: [-167.7479318091337, -103.04416176812333, 163.60003149508051, 98.90653077599225],
                duration: 5000,
                limits: true,
                container: 'svgProject'
            },
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'fitRangeToContainer_Finished',
            newStep: 'Fit range'
        },
        // Create building 1
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-index-add-building',
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'activeUpdated',
            newStep: 'Create building 1'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.6545421269694,
                    lng: -3.7658277235153603
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65433492359081,
                    lng: -3.76586199558803
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.654352671366276,
                    lng: -3.766049774232854
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65455986095438,
                    lng: -3.7660157114781367
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'building-create-panel_ez3dBottomPanel button',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-select-hipped',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'EZModelRoof_setType'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'EW',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'setAttribute_Finished'
        },
        // -- Move roof points --
        {
            event: 'EZModelScene_benchmarkMoveRoofPointsListener',
            args: [0, {lat: -0.000018386024528638245, lng: 4.4853010194856324e-14}],
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'benchmarkMoveRoofPoints_Finished',
            subStep: 'Move roof points'
        },
        {
            event: 'EZModelScene_benchmarkMoveRoofPointsListener',
            args: [1, {lat: 0.000017806930557640044, lng: 0.000006034528536869033}],
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'benchmarkMoveRoofPoints_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-finish-roof-edit',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'finishRoofEditOperator'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'roof-details-panel_ez3dBottomPanel .ez3d-square-button-green',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-widget-buildingHeight i',
            undo: false,
            notifications: false,
            updateRender: false,
        },
        // ---- Set building height ----
        {
            event: 'EZModelBuilding_benchmarkSetAttributeWithoutIdListener',
            args: ['ridge.height', 15],
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'benchmarkSetAttributeWithoutId_Finished',
            subStep: 'Set building height'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Disable area 4 --
        {
            event: 'tabChanged',
            args: 'ez3d-areas-info-tab',
            undo: false,
            notifications: false,
            updateRender: false,
            subStep: 'Disable area 4'
        },
        {
            event: 'EZModelScene_benchmarkSetActiveWithoutIdListener',
            args: ['.ez3d-foldable-group-block-area:nth-child(4)'],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetActiveWithoutId_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelArea_benchmarkSetAttributeWithoutIdListener',
            args: ['disabled', true],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetAttributeWithoutId_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // Create building 2
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-index-add-building',
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'activeUpdated',
            newStep: 'Create building 2'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65436073695747,
                    lng: -3.7661348955980425
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65432479177096,
                    lng: -3.765754569784386
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65426566608201,
                    lng: -3.7657643459964762
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.654301656852006,
                    lng: -3.766144544198747
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'building-create-panel_ez3dBottomPanel button',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-select-hipped',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'EZModelRoof_setType'
        },
        // -- Move roof points--
        {
            event: 'EZModelScene_benchmarkMoveRoofPointsListener',
            args: [0, {lat: -0.000006043727459825732, lng: 0.000056631587363575875}],
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'benchmarkMoveRoofPoints_Finished',
            subStep: 'Move roof points'
        },
        {
            event: 'EZModelScene_benchmarkMoveRoofPointsListener',
            args: [1, {lat: 0.0000048444233939903825, lng: -0.00005683156630942321}],
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'benchmarkMoveRoofPoints_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-finish-roof-edit',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'finishRoofEditOperator'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'roof-details-panel_ez3dBottomPanel .ez3d-square-button-green',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        // ---- Set building height ----
        {
            event: 'EZModelBuilding_benchmarkSetAttributeWithoutIdListener',
            args: ['ridge.height', 11],
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'benchmarkSetAttributeWithoutId_Finished',
            subStep: 'Set building height'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Disable area 2 --
        {
            event: 'EZModelScene_benchmarkSetActiveWithoutIdListener',
            args: ['.ez3d-foldable-group-block-area:nth-child(2)'],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetActiveWithoutId_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelArea_benchmarkSetAttributeWithoutIdListener',
            args: ['disabled', true],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetAttributeWithoutId_Finished'
        },
        // -- Disable area 3 --
        {
            event: 'EZModelScene_benchmarkSetActiveWithoutIdListener',
            args: ['.ez3d-foldable-group-block-area:nth-child(3)'],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetActiveWithoutId_Finished',
            subStep: 'Disable area 3'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelArea_benchmarkSetAttributeWithoutIdListener',
            args: ['disabled', true],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetAttributeWithoutId_Finished'
        },
        // -- Disable area 4 --
        {
            event: 'EZModelScene_benchmarkSetActiveWithoutIdListener',
            args: ['.ez3d-foldable-group-block-area:nth-child(4)'],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetActiveWithoutId_Finished',
            subStep: 'Disable area 4'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelArea_benchmarkSetAttributeWithoutIdListener',
            args: ['disabled', true],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetAttributeWithoutId_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // Create building 3
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-index-add-building',
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'activeUpdated',
            newStep: 'Create building 3'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65435857287686,
                    lng: -3.765472129048313
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65421683676123,
                    lng: -3.7654957578940627
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.654241429639875,
                    lng: -3.7657725760741694
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.654384778272764,
                    lng: -3.765748863831833
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'building-create-panel_ez3dBottomPanel button',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-select-hipped',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'EZModelRoof_setType'
        },
        // -- Move roof points --
        {
            event: 'EZModelScene_benchmarkMoveRoofPointsListener',
            args: [0, {lat: 0.000002093515334422591, lng: -0.000026095623179234195}],
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'benchmarkMoveRoofPoints_Finished',
            subStep: 'Move roof points'
        },
        {
            event: 'EZModelScene_benchmarkMoveRoofPointsListener',
            args: [1, {lat: -0.000002606715710840035, lng: 0.00002601520229772447}],
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'benchmarkMoveRoofPoints_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-finish-roof-edit',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'finishRoofEditOperator'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'roof-details-panel_ez3dBottomPanel .ez3d-square-button-green',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-widget-buildingHeight i',
            undo: false,
            notifications: false,
            updateRender: false,
        },
        // ---- Set building height ----
        {
            event: 'EZModelBuilding_benchmarkSetAttributeWithoutIdListener',
            args: ['ridge.height', 15],
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'benchmarkSetAttributeWithoutId_Finished',
            subStep: 'Set building height'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Disable area 3 --
        {
            event: 'EZModelScene_benchmarkSetActiveWithoutIdListener',
            args: ['.ez3d-foldable-group-block-area:nth-child(3)'],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetActiveWithoutId_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelArea_benchmarkSetAttributeWithoutIdListener',
            args: ['disabled', true],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetAttributeWithoutId_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // Create building 4
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-index-add-building',
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'activeUpdated',
            newStep: 'Create building 4'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.654291060277515,
                    lng: -3.766039245408298
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.654274097220636,
                    lng: -3.765853703367387
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65395040567367,
                    lng: -3.7659048187046027
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65396760212795,
                    lng: -3.766090260421354
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'building-create-panel_ez3dBottomPanel button',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-select-hipped',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'EZModelRoof_setType'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'EW',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'setAttribute_Finished'
        },
        // -- Move roof points--
        {
            event: 'EZModelScene_benchmarkMoveRoofPointsListener',
            args: [0, {lat: 0.00000995115551205572, lng: 0.000002623365880527473}],
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'benchmarkMoveRoofPoints_Finished',
            subStep: 'Move roof points'
        },
        {
            event: 'EZModelScene_benchmarkMoveRoofPointsListener',
            args: [1, {lat: -0.000010137523283049177, lng: -6.142129866937296e-7}],
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'benchmarkMoveRoofPoints_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-finish-roof-edit',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'finishRoofEditOperator'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'roof-details-panel_ez3dBottomPanel .ez3d-square-button-green',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-widget-buildingHeight i',
            undo: false,
            notifications: false,
            updateRender: false,
        },
        // ---- Set building height ----
        {
            event: 'EZModelBuilding_benchmarkSetAttributeWithoutIdListener',
            args: ['ridge.height', 15],
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'benchmarkSetAttributeWithoutId_Finished',
            subStep: 'Set building height'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Disable area 4 --
        {
            event: 'EZModelScene_benchmarkSetActiveWithoutIdListener',
            args: ['.ez3d-foldable-group-block-area:nth-child(4)'],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetActiveWithoutId_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelArea_benchmarkSetAttributeWithoutIdListener',
            args: ['disabled', true],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetAttributeWithoutId_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Create keepout 1 --
        {
            event: 'tabChanged',
            args: 'ez3d-objects-tab',
            undo: false,
            notifications: false,
            updateRender: false,
            subStep: 'Create keepout 1'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-add-keepout',
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65424429762916,
                    lng: -3.765969901763926
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65423778483916,
                    lng: -3.7659708489854236
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65423683909157,
                    lng: -3.765961731845839
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.6542433159902,
                    lng: -3.765960547416741
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'keepout-create-panel_ez3dBottomPanel button',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        // ---- Set keepout height ----
        {
            event: 'EZModelKeepout_benchmarkSetAttributeWithoutIdListener',
            args: ['height', 2.5],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetAttributeWithoutId_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Create keepout 2 --
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'keepout-info-panel_ez3dInfoPanel .fa-clone',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated',
            subStep: 'Create keepout 2'
        },
        {
            event: 'EZModelKeepout_moveListener',
            args: [
                {
                    lat: -0.00015058347320717758,
                    lng: -0.000001952330964893889
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // Create building 5
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-index-add-building',
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'activeUpdated',
            newStep: 'Create building 5'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65416413532397,
                    lng: -3.7655420006553983
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.653964754540645,
                    lng: -3.7655732045858934
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.653977172865986,
                    lng: -3.7657116885492576
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65417650851368,
                    lng: -3.7656808382242826
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'building-create-panel_ez3dBottomPanel button',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'EW',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'setAttribute_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-finish-roof-edit',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'finishRoofEditOperator'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'roof-details-panel_ez3dBottomPanel .ez3d-square-button-green',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        // ---- Set building height ----
        {
            event: 'EZModelBuilding_benchmarkSetAttributeWithoutIdListener',
            args: ['ridge.height', 15],
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'benchmarkSetAttributeWithoutId_Finished',
            subStep: 'Set building height'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // Create building 6
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-index-add-building',
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'activeUpdated',
            newStep: 'Create building 6'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65377692443974,
                    lng: -3.765826914394768
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65379416057016,
                    lng: -3.7660125029842417
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65395795182163,
                    lng: -3.765986200329222
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65394074011476,
                    lng: -3.765800558333502
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'building-create-panel_ez3dBottomPanel button',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'EW',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'setAttribute_Finished'
        },
        // -- Move roof points--
        {
            event: 'EZModelScene_benchmarkMoveRoofPointsListener',
            args: [0, {lat: 0.0000020224410377522872, lng: -0.000021805569128563462}],
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'benchmarkMoveRoofPoints_Finished',
            subStep: 'Move roof points'
        },
        {
            event: 'EZModelScene_benchmarkMoveRoofPointsListener',
            args: [1, {lat: 0.00000202244103064686, lng: -0.00002180551560737598}],
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'benchmarkMoveRoofPoints_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-finish-roof-edit',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'finishRoofEditOperator'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'roof-details-panel_ez3dBottomPanel .ez3d-square-button-green',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-widget-buildingHeight i',
            undo: false,
            notifications: false,
            updateRender: false,
        },
        // ---- Set building height ----
        {
            event: 'EZModelBuilding_benchmarkSetAttributeWithoutIdListener',
            args: ['ridge.height', 15],
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'benchmarkSetAttributeWithoutId_Finished',
            subStep: 'Set building height'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Create keepout 1 --
        {
            event: 'tabChanged',
            args: 'ez3d-objects-tab',
            undo: false,
            notifications: false,
            updateRender: false,
            subStep: 'Create keepout 1'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-add-keepout',
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.653816728186015,
                    lng: -3.765917037008357
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.653796413927566,
                    lng: -3.7659207234855763
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.653793718861216,
                    lng: -3.765896089763676
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65381397898169,
                    lng: -3.7658929949826003
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'keepout-create-panel_ez3dBottomPanel button',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        // ---- Set keepout inclined ----
        {
            event: 'EZModelKeepout_benchmarkSetAttributeWithoutIdListener',
            args: ['type', 'inclined'],
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'benchmarkSetAttributeWithoutId_Finished'
        },
        // ---- Set keepout height ----
        {
            event: 'EZModelKeepout_benchmarkSetAttributeWithoutIdListener',
            args: ['height', 0.5],
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'benchmarkSetAttributeWithoutId_Finished'
        },
        // ---- Set keepout offset ----
        {
            event: 'EZModelKeepout_benchmarkSetAttributeWithoutIdListener',
            args: ['offset', 0],
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'benchmarkSetAttributeWithoutId_Finished'
        },
        // -- Create keepout 2 --
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'keepout-info-panel_ez3dInfoPanel .fa-clone',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated',
            subStep: 'Create keepout 2'
        },
        {
            event: 'EZModelKeepout_moveListener',
            args: [
                {
                    lat: 0.000048390859390679,
                    lng: -0.0000013866241377336053
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Create keepout 3 --
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'keepout-info-panel_ez3dInfoPanel .fa-clone',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated',
            subStep: 'Create keepout 3'
        },
        {
            event: 'EZModelKeepout_moveListener',
            args: [
                {
                    lat: 0.00004996882182695117,
                    lng: -0.000005199841823344542
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Create keepout 4 --
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'keepout-info-panel_ez3dInfoPanel .fa-clone',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated',
            subStep: 'Create keepout 4'
        },
        {
            event: 'EZModelKeepout_moveListener',
            args: [
                {
                    lat: 0.00004891684545782482,
                    lng: -0.0000020799379298885867
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Create subarea in area 1 --
        {
            event: 'EZModelScene_benchmarkSetActiveWithoutIdListener',
            args: ['.ez3d-foldable-group-block-area:nth-child(1)'],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetActiveWithoutId_Finished',
            subStep: 'Create subarea in area 1'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelArea_createSubareaListener',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65393754948433,
                    lng: -3.7658051886567607
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65378087073225,
                    lng: -3.7658303932754027
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.653786830803114,
                    lng: -3.7658946119723256
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.653943543807216,
                    lng: -3.7658698250004106
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'subarea-create-panel_ez3dBottomPanel button',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // Create building 7
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-index-add-building',
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'activeUpdated',
            newStep: 'Create building 7'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65333337265025,
                    lng: -3.7659645481531188
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65330598517055,
                    lng: -3.7656714565138443
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.653160772013194,
                    lng: -3.7656945236813724
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.6531881401778,
                    lng: -3.765987749888338
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'building-create-panel_ez3dBottomPanel button',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-finish-roof-edit',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'finishRoofEditOperator'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'roof-details-panel_ez3dBottomPanel .ez3d-square-button-green',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-widget-buildingHeight i',
            undo: false,
            notifications: false,
            updateRender: false,
        },
        // ---- Set building height ----
        {
            event: 'EZModelBuilding_benchmarkSetAttributeWithoutIdListener',
            args: ['ridge.height', 15],
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'benchmarkSetAttributeWithoutId_Finished',
            subStep: 'Set building height'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Disable area 2 --
        {
            event: 'EZModelScene_benchmarkSetActiveWithoutIdListener',
            args: ['.ez3d-foldable-group-block-area:nth-child(2)'],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetActiveWithoutId_Finished',
            subStep: 'Disable area 2'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelArea_benchmarkSetAttributeWithoutIdListener',
            args: ['disabled', true],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetAttributeWithoutId_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // Create building 8.1
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-index-add-building',
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'activeUpdated',
            newStep: 'Create building 8.1'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.653215509931094,
                    lng: -3.766252274524472
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.653358610622725,
                    lng: -3.766228605275398
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65333981583276,
                    lng: -3.766032515799666
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65319665592599,
                    lng: -3.7660560799626026
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'building-create-panel_ez3dBottomPanel button',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-finish-roof-edit',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'finishRoofEditOperator'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'roof-details-panel_ez3dBottomPanel .ez3d-square-button-green',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-widget-buildingHeight i',
            undo: false,
            notifications: false,
            updateRender: false,
        },
        // ---- Set building height ----
        {
            event: 'EZModelBuilding_benchmarkSetAttributeWithoutIdListener',
            args: ['ridge.height', 15],
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'benchmarkSetAttributeWithoutId_Finished',
            subStep: 'Set building height'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Disable area 2 --
        {
            event: 'EZModelScene_benchmarkSetActiveWithoutIdListener',
            args: ['.ez3d-foldable-group-block-area:nth-child(2)'],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetActiveWithoutId_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelArea_benchmarkSetAttributeWithoutIdListener',
            args: ['disabled', true],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetAttributeWithoutId_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Create keepout 1 --
        {
            event: 'tabChanged',
            args: 'ez3d-objects-tab',
            undo: false,
            notifications: false,
            updateRender: false,
            subStep: 'Create keepout 1'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-add-keepout',
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65333046586021,
                    lng: -3.766171316159928
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65332981841527,
                    lng: -3.7661650327058687
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65332531988315,
                    lng: -3.766165831868933
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65332596726206,
                    lng: -3.766172115613873
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'keepout-create-panel_ez3dBottomPanel button',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        // ---- Set keepout height ----
        {
            event: 'EZModelKeepout_benchmarkSetAttributeWithoutIdListener',
            args: ['height', 2],
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'benchmarkSetAttributeWithoutId_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // Create building 8.2
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-index-add-building',
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'activeUpdated',
            newStep: 'Create building 8.2'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.653316763125,
                    lng: -3.7662355356508193
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65325501894317,
                    lng: -3.766245748121326
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.6532575894495,
                    lng: -3.7662725678730635
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65331932942834,
                    lng: -3.7662623500496575
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        // -- Set populate with modules false --
        {
            event: 'EZModelBuilding_benchmarkSetAttributeWithoutIdListener',
            args: ['populated', false],
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'benchmarkSetAttributeWithoutId_Finished',
            subStep: 'Set populate with modules false'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'building-create-panel_ez3dBottomPanel button',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Move roof points--
        {
            event: 'EZModelScene_benchmarkMoveRoofPointsListener',
            args: [0, {lat: 0.000001165452914619891, lng: 1.9037077425565485e-7}],
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'benchmarkMoveRoofPoints_Finished',
            subStep: 'Move roof points'
        },
        {
            event: 'EZModelScene_benchmarkMoveRoofPointsListener',
            args: [1, {lat: 8.001024696113745e-7, lng: 1.306925763167044e-7}],
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'benchmarkMoveRoofPoints_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-finish-roof-edit',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'finishRoofEditOperator'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'roof-details-panel_ez3dBottomPanel .ez3d-square-button-green',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        // ---- Set building height ----
        {
            event: 'EZModelBuilding_benchmarkSetAttributeWithoutIdListener',
            args: ['ridge.height', 15],
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'benchmarkSetAttributeWithoutId_Finished',
            subStep: 'Set building height'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // Create building 9
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-index-add-building',
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'activeUpdated',
            newStep: 'Create building 9'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65417598855653,
                    lng: -3.765680479803352
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65374370446663,
                    lng: -3.765749209798526
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65375138623825,
                    lng: -3.7658333794200276
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65337322441431,
                    lng: -3.7658943991327614
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65338005519504,
                    lng: -3.7659616255077335
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.653345157386276,
                    lng: -3.7659668293251016
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65334866347645,
                    lng: -3.76599862016493
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65340459267936,
                    lng: -3.7659892289725714
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65340119474005,
                    lng: -3.765948568614353
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.6537823734599,
                    lng: -3.765887047192883
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65377683541137,
                    lng: -3.765827339278096
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.6540801326013,
                    lng: -3.7657789346678916
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.6540811995009,
                    lng: -3.7657912803518934
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.654099667653234,
                    lng: -3.7657882052256277
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.654108035449056,
                    lng: -3.7658799394076485
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.654273618337854,
                    lng: -3.7658532543881766
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65426531621249,
                    lng: -3.765768070703556
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65424089387156,
                    lng: -3.7657721425237534
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65418474941713,
                    lng: -3.7657806308302235
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        // -- Allow irregular angles --
        {
            event: 'EZModelBuilding_benchmarkSetAttributeWithoutIdListener',
            args: ['regular', true],
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'benchmarkSetAttributeWithoutId_Finished',
            subStep: 'Allow irregular angles'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'building-create-panel_ez3dBottomPanel button',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-finish-roof-edit',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'finishRoofEditOperator'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'roof-details-panel_ez3dBottomPanel .ez3d-square-button-green',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        // ---- Set building height ----
        {
            event: 'EZModelBuilding_benchmarkSetAttributeWithoutIdListener',
            args: ['ridge.height', 5],
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'benchmarkSetAttributeWithoutId_Finished',
            subStep: 'Set building height'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Create subarea in area 1 --
        {
            event: 'EZModelScene_benchmarkSetActiveWithoutIdListener',
            args: ['.ez3d-foldable-group-block-area:nth-child(1)'],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetActiveWithoutId_Finished',
            subStep: 'Create subarea in area 1'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelArea_createSubareaListener',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65397822715847,
                    lng: -3.7657123282299465
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.653425738306574,
                    lng: -3.7657999121725223
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65343929078639,
                    lng: -3.7659421377935414
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65399152018854,
                    lng: -3.765854749685198
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'subarea-create-panel_ez3dBottomPanel button',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'subarea-info-panel_ez3dBottomPanel button',
            undo: false,
            notifications: true,
            updateRender: false
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'subarea-details-panel_ez3dBottomPanel .ez3d-square-button-green',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        }
    ];

    // Generate all textures
    if (generateTextures === undefined || generateTextures === true) {
        eventList.push({
            event: 'tabChanged',
            args: 'ez3d-building-info-tab',
            undo: false,
            notifications: false,
            updateRender: false,
            subStep: 'Generate all textures'
        });
        eventList.push({
            event: 'EZModelScene_getTexturesListener',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'getTextures_Finished',
        });
        eventList.push({
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        });
    }

    // Fit range
    eventList.push({
        event: 'EZModelScene_fitRangeToContainerListener',
        args: {
            range: [-141.9513772033719, -98.1548292117921, 69.35282110068167, 103.79586333232348],
            duration: 5000,
            limits: true,
            container: 'svgProject'
        },
        undo: false,
        notifications: true,
        updateRender: false,
        eventFinished: 'fitRangeToContainer_Finished',
        newStep: 'Fit range'
    });

    ez3dScene.benchmarkExecuteEventsArray(eventList);
};

EZModelScene.prototype.benchmark1KMod = function (generateTextures) {
    var eventList = [
        // Change project center
        {
            event: 'EZModelScene_changeProjectCenterListener',
            args: {lat: 40.6538537, lng: -3.7654579},
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'changeProjectCenter_Finished',
            newStep: 'Change project center'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1500,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // Deactivate textures
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-preferences-tab',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'tabChanged',
            newStep: 'Deactivate textures'
        },
        {
            event: 'EZModelScene_benchmarkSetAttributeSceneListener',
            args: ['layoutRules.scenePreferences.buildingTextures', false],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetAttributeScene_Finished'
        },
        // Deactivate custom shapes
        {
            event: 'EZModelScene_benchmarkSetAttributeSceneListener',
            args: ['layoutRules.scenePreferences.enableBuildingShapes', false],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'benchmarkSetAttributeScene_Finished'
        },
        // Fit range
        {
            event: 'EZModelScene_fitRangeToContainerListener',
            args: {
                range: [-167.7479318091337, -103.04416176812333, 163.60003149508051, 98.90653077599225],
                duration: 5000,
                limits: true,
                container: 'svgProject'
            },
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'fitRangeToContainer_Finished',
            newStep: 'Fit range'
        },
        // Create building 1
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-index-add-building',
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'activeUpdated',
            newStep: 'Create building 1'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.6545421269694,
                    lng: -3.7658277235153603
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65433492359081,
                    lng: -3.76586199558803
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.654352671366276,
                    lng: -3.766049774232854
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65455986095438,
                    lng: -3.7660157114781367
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-finish-building-create',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-select-hipped',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'EZModelRoof_setType'
        },
        // -- Move roof points --
        {
            event: 'EZModelScene_benchmarkMoveRoofPointsListener',
            args: [0, {lat: -0.000018386024528638245, lng: 4.4853010194856324e-14}],
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'benchmarkMoveRoofPoints_Finished',
            subStep: 'Move roof points'
        },
        {
            event: 'EZModelScene_benchmarkMoveRoofPointsListener',
            args: [1, {lat: 0.000017806930557640044, lng: 0.000006034528536869033}],
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'benchmarkMoveRoofPoints_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-finish-roof-edit',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Disable area 4 --
        {
            event: 'EZModelScene_benchmarkSetActiveWithoutIdListener',
            args: ['.ez3d-foldable-group-block-area:nth-child(4)'],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetActiveWithoutId_Finished',
            subStep: 'Disable area 4'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelArea_benchmarkSetAttributeWithoutIdListener',
            args: ['disabled', true],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetAttributeWithoutId_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // Create building 2
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-index-add-building',
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'activeUpdated',
            newStep: 'Create building 2'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65436073695747,
                    lng: -3.7661348955980425
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65432479177096,
                    lng: -3.765754569784386
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65426566608201,
                    lng: -3.7657643459964762
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.654301656852006,
                    lng: -3.766144544198747
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-finish-building-create',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-select-hipped',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'EZModelRoof_setType'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'SN',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'EZModelRoof_setOrientation'
        },
        // -- Move roof points--
        {
            event: 'EZModelScene_benchmarkMoveRoofPointsListener',
            args: [0, {lat: -0.000006043727459825732, lng: 0.000056631587363575875}],
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'benchmarkMoveRoofPoints_Finished',
            subStep: 'Move roof points'
        },
        {
            event: 'EZModelScene_benchmarkMoveRoofPointsListener',
            args: [1, {lat: 0.0000048444233939903825, lng: -0.00005683156630942321}],
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'benchmarkMoveRoofPoints_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-finish-roof-edit',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Disable area 2 --
        {
            event: 'EZModelScene_benchmarkSetActiveWithoutIdListener',
            args: ['.ez3d-foldable-group-block-area:nth-child(2)'],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetActiveWithoutId_Finished',
            subStep: 'Disable area 2'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelArea_benchmarkSetAttributeWithoutIdListener',
            args: ['disabled', true],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetAttributeWithoutId_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Disable area 3 --
        {
            event: 'EZModelScene_benchmarkSetActiveWithoutIdListener',
            args: ['.ez3d-foldable-group-block-area:nth-child(3)'],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetActiveWithoutId_Finished',
            subStep: 'Disable area 3'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelArea_benchmarkSetAttributeWithoutIdListener',
            args: ['disabled', true],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetAttributeWithoutId_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Disable area 4 --
        {
            event: 'EZModelScene_benchmarkSetActiveWithoutIdListener',
            args: ['.ez3d-foldable-group-block-area:nth-child(4)'],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetActiveWithoutId_Finished',
            subStep: 'Disable area 4'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelArea_benchmarkSetAttributeWithoutIdListener',
            args: ['disabled', true],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetAttributeWithoutId_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // Create building 3
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-index-add-building',
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'activeUpdated',
            newStep: 'Create building 3'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65435857287686,
                    lng: -3.765472129048313
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65421683676123,
                    lng: -3.7654957578940627
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.654241429639875,
                    lng: -3.7657725760741694
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.654384778272764,
                    lng: -3.765748863831833
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-finish-building-create',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-select-hipped',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'EZModelRoof_setType'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'SN',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'EZModelRoof_setOrientation'
        },
        // -- Move roof points--
        {
            event: 'EZModelScene_benchmarkMoveRoofPointsListener',
            args: [0, {lat: 0.000002093515334422591, lng: -0.000026095623179234195}],
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'benchmarkMoveRoofPoints_Finished',
            subStep: 'Move roof points'
        },
        {
            event: 'EZModelScene_benchmarkMoveRoofPointsListener',
            args: [1, {lat: -0.000002606715710840035, lng: 0.00002601520229772447}],
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'benchmarkMoveRoofPoints_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-finish-roof-edit',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Disable area 3 --
        {
            event: 'EZModelScene_benchmarkSetActiveWithoutIdListener',
            args: ['.ez3d-foldable-group-block-area:nth-child(3)'],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetActiveWithoutId_Finished',
            subStep: 'Disable area 3'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelArea_benchmarkSetAttributeWithoutIdListener',
            args: ['disabled', true],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetAttributeWithoutId_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // Create building 4
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-index-add-building',
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'activeUpdated',
            newStep: 'Create building 4'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.654291060277515,
                    lng: -3.766039245408298
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.654274097220636,
                    lng: -3.765853703367387
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65395040567367,
                    lng: -3.7659048187046027
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65396760212795,
                    lng: -3.766090260421354
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-finish-building-create',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-select-hipped',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'EZModelRoof_setType'
        },
        // -- Move roof points--
        {
            event: 'EZModelScene_benchmarkMoveRoofPointsListener',
            args: [0, {lat: 0.00000995115551205572, lng: 0.000002623365880527473}],
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'benchmarkMoveRoofPoints_Finished',
            subStep: 'Move roof points'
        },
        {
            event: 'EZModelScene_benchmarkMoveRoofPointsListener',
            args: [1, {lat: -0.000010137523283049177, lng: -6.142129866937296e-7}],
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'benchmarkMoveRoofPoints_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-finish-roof-edit',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Disable area 4 --
        {
            event: 'EZModelScene_benchmarkSetActiveWithoutIdListener',
            args: ['.ez3d-foldable-group-block-area:nth-child(4)'],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetActiveWithoutId_Finished',
            subStep: 'Disable area 4'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelArea_benchmarkSetAttributeWithoutIdListener',
            args: ['disabled', true],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetAttributeWithoutId_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Create keepout 1 --
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-objects-tab',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'tabChanged',
            subStep: 'Create keepout 1'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-add-keepout',
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65424429762916,
                    lng: -3.765969901763926
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65423778483916,
                    lng: -3.7659708489854236
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65423683909157,
                    lng: -3.765961731845839
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.6542433159902,
                    lng: -3.765960547416741
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-finish-keepout-create',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        // -- Create keepout 2 --
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-clone-keepout',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated',
            subStep: 'Create keepout 2'
        },
        {
            event: 'EZModelKeepout_moveListener',
            args: [
                {
                    lat: -0.00015058347320717758,
                    lng: -0.000001952330964893889
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // Create building 5
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-index-add-building',
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'activeUpdated',
            newStep: 'Create building 5'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65416413532397,
                    lng: -3.7655420006553983
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.653964754540645,
                    lng: -3.7655732045858934
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.653977172865986,
                    lng: -3.7657116885492576
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65417650851368,
                    lng: -3.7656808382242826
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-finish-building-create',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-select-gabled',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'EZModelRoof_setType'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-finish-roof-edit',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // Create building 6
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-index-add-building',
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'activeUpdated',
            newStep: 'Create building 6'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65377692443974,
                    lng: -3.765826914394768
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65379416057016,
                    lng: -3.7660125029842417
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65395795182163,
                    lng: -3.765986200329222
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65394074011476,
                    lng: -3.765800558333502
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-finish-building-create',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-select-gabled',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'EZModelRoof_setType'
        },
        // -- Move roof points--
        {
            event: 'EZModelScene_benchmarkMoveRoofPointsListener',
            args: [0, {lat: 0.0000020224410377522872, lng: -0.000021805569128563462}],
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'benchmarkMoveRoofPoints_Finished',
            subStep: 'Move roof points'
        },
        {
            event: 'EZModelScene_benchmarkMoveRoofPointsListener',
            args: [1, {lat: 0.00000202244103064686, lng: -0.00002180551560737598}],
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'benchmarkMoveRoofPoints_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-finish-roof-edit',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Create keepout 1 --
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-objects-tab',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'tabChanged',
            subStep: 'Create keepout 1'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-add-keepout',
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.653816728186015,
                    lng: -3.765917037008357
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.653796413927566,
                    lng: -3.7659207234855763
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.653793718861216,
                    lng: -3.765896089763676
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65381397898169,
                    lng: -3.7658929949826003
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'keepout-create-panel_ez3dInfoPanel #ez3d-select-inclined',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'setAttribute_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-finish-keepout-create',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        // -- Create keepout 2 --
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-clone-keepout',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated',
            subStep: 'Create keepout 2'
        },
        {
            event: 'EZModelKeepout_moveListener',
            args: [
                {
                    lat: 0.000048390859390679,
                    lng: -0.0000013866241377336053
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Create keepout 3 --
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-clone-keepout',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated',
            subStep: 'Create keepout 3'
        },
        {
            event: 'EZModelKeepout_moveListener',
            args: [
                {
                    lat: 0.00004996882182695117,
                    lng: -0.000005199841823344542
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Create keepout 4 --
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-clone-keepout',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated',
            subStep: 'Create keepout 4'
        },
        {
            event: 'EZModelKeepout_moveListener',
            args: [
                {
                    lat: 0.00004891684545782482,
                    lng: -0.0000020799379298885867
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Create subarea in area 1 --
        {
            event: 'EZModelScene_benchmarkSetActiveWithoutIdListener',
            args: ['.ez3d-foldable-group-block-area:nth-child(1)'],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetActiveWithoutId_Finished',
            subStep: 'Create subarea in area 1'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelArea_createSubareaListener',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65393754948433,
                    lng: -3.7658051886567607
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65378087073225,
                    lng: -3.7658303932754027
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.653786830803114,
                    lng: -3.7658946119723256
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.653943543807216,
                    lng: -3.7658698250004106
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'subarea-create-panel_ez3dBottomPanel button',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // Create building 7
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-index-add-building',
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'activeUpdated',
            newStep: 'Create building 7'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65333337265025,
                    lng: -3.7659645481531188
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65330598517055,
                    lng: -3.7656714565138443
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.653160772013194,
                    lng: -3.7656945236813724
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.6531881401778,
                    lng: -3.765987749888338
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-finish-building-create',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-select-gabled',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'EZModelRoof_setType'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'SN',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'EZModelRoof_setOrientation'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-finish-roof-edit',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Disable area 2 --
        {
            event: 'EZModelScene_benchmarkSetActiveWithoutIdListener',
            args: ['.ez3d-foldable-group-block-area:nth-child(2)'],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetActiveWithoutId_Finished',
            subStep: 'Disable area 2'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelArea_benchmarkSetAttributeWithoutIdListener',
            args: ['disabled', true],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetAttributeWithoutId_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // Create building 8.1
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-index-add-building',
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'activeUpdated',
            newStep: 'Create building 8.1'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.653215509931094,
                    lng: -3.766252274524472
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.653358610622725,
                    lng: -3.766228605275398
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65333981583276,
                    lng: -3.766032515799666
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65319665592599,
                    lng: -3.7660560799626026
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-finish-building-create',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-select-gabled',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'EZModelRoof_setType'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'SN',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'EZModelRoof_setOrientation'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-finish-roof-edit',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Disable area 2 --
        {
            event: 'EZModelScene_benchmarkSetActiveWithoutIdListener',
            args: ['.ez3d-foldable-group-block-area:nth-child(2)'],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetActiveWithoutId_Finished',
            subStep: 'Disable area 2'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelArea_benchmarkSetAttributeWithoutIdListener',
            args: ['disabled', true],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetAttributeWithoutId_Finished'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Create keepout 1 --
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-objects-tab',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'tabChanged',
            subStep: 'Create keepout 1'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-add-keepout',
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65333046586021,
                    lng: -3.766171316159928
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65332981841527,
                    lng: -3.7661650327058687
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65332531988315,
                    lng: -3.766165831868933
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65332596726206,
                    lng: -3.766172115613873
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-finish-keepout-create',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // Create building 8.2
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-index-add-building',
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'activeUpdated',
            newStep: 'Create building 8.2'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.653316763125,
                    lng: -3.7662355356508193
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65325501894317,
                    lng: -3.766245748121326
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.6532575894495,
                    lng: -3.7662725678730635
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65331932942834,
                    lng: -3.7662623500496575
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        // -- Set populate with modules false --
        {
            event: 'EZModelBuilding_benchmarkSetAttributeWithoutIdListener',
            args: ['populated', false],
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'benchmarkSetAttributeWithoutId_Finished',
            subStep: 'Set populate with modules false'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-finish-building-create',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-select-gabled',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'EZModelRoof_setType'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'SN',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'EZModelRoof_setOrientation'
        },
        // -- Move roof points--
        {
            event: 'EZModelScene_benchmarkMoveRoofPointsListener',
            args: [0, {lat: 0.000001165452914619891, lng: 1.9037077425565485e-7}],
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'benchmarkMoveRoofPoints_Finished',
            subStep: 'Move roof points'
        },
        {
            event: 'EZModelScene_benchmarkMoveRoofPointsListener',
            args: [1, {lat: 8.001024696113745e-7, lng: 1.306925763167044e-7}],
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'benchmarkMoveRoofPoints_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-finish-roof-edit',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // Create building 9
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-index-add-building',
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'activeUpdated',
            newStep: 'Create building 9'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65417598855653,
                    lng: -3.765680479803352
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65374370446663,
                    lng: -3.765749209798526
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65375138623825,
                    lng: -3.7658333794200276
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65337322441431,
                    lng: -3.7658943991327614
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65338005519504,
                    lng: -3.7659616255077335
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.653345157386276,
                    lng: -3.7659668293251016
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65334866347645,
                    lng: -3.76599862016493
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65340459267936,
                    lng: -3.7659892289725714
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65340119474005,
                    lng: -3.765948568614353
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.6537823734599,
                    lng: -3.765887047192883
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65377683541137,
                    lng: -3.765827339278096
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.6540801326013,
                    lng: -3.7657789346678916
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.6540811995009,
                    lng: -3.7657912803518934
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.654099667653234,
                    lng: -3.7657882052256277
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.654108035449056,
                    lng: -3.7658799394076485
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.654273618337854,
                    lng: -3.7658532543881766
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65426531621249,
                    lng: -3.765768070703556
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65424089387156,
                    lng: -3.7657721425237534
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65418474941713,
                    lng: -3.7657806308302235
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-finish-building-create',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-finish-roof-edit',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        // -- Create subarea in area 1 --
        {
            event: 'EZModelScene_benchmarkSetActiveWithoutIdListener',
            args: ['.ez3d-foldable-group-block-area:nth-child(1)'],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetActiveWithoutId_Finished',
            subStep: 'Create subarea in area 1'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelArea_createSubareaListener',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65397822715847,
                    lng: -3.7657123282299465
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.653425738306574,
                    lng: -3.7657999121725223
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65343929078639,
                    lng: -3.7659421377935414
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.65399152018854,
                    lng: -3.765854749685198
                }
            ],
            undo: false,
            notifications: false,
            updateRender: false,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'subarea-create-panel_ez3dBottomPanel button',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        }
    ];

    // Generate all textures
    if (generateTextures === undefined || generateTextures === true) {
        eventList.push({
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-building-info-tab',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'tabChanged',
            newStep: 'Generate all textures'
        });
        eventList.push({
            event: 'EZModelScene_getTexturesListener',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'getTextures_Finished',
        });
        eventList.push({
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        });
    }

    // Fit range
    eventList.push({
        event: 'EZModelScene_fitRangeToContainerListener',
        args: {
            range: [-141.9513772033719, -98.1548292117921, 69.35282110068167, 103.79586333232348],
            duration: 5000,
            limits: true,
            container: 'svgProject'
        },
        undo: false,
        notifications: true,
        updateRender: false,
        eventFinished: 'fitRangeToContainer_Finished',
        newStep: 'Fit range'
    });

    ez3dScene.benchmarkExecuteEventsArray(eventList);
};

EZModelScene.prototype.benchmark = function () {
    var eventList = [
        // seleccionar el tab configuracion, y desactivar texturas
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-preferences-tab',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'tabChanged',
            newStep: 'Deactivate textures'
        },
        {
            event: 'EZModelScene_benchmarkSetAttributeSceneListener',
            args: ['layoutRules.scenePreferences.buildingTextures', false],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetAttributeScene_Finished'
        },
        {
            event: 'EZModelScene_fitRangeToContainerListener',
            args: {
                range: [-91.00333133010476, -86.741055837898, -10.280851521182129, -5.780923978507516],
                duration: 5000,
                limits: true,
                container: 'svgProject'
            },
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'fitRangeToContainer_Finished'
        },
        // create building 1
        {
            event: 'EZModelScene_createBuildingListener',
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'activeUpdated',
            newStep: 'Create building 1'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.46175266506025,
                    lng: -3.6516945617025036
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.461838328213,
                    lng: -3.6515205088998752
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.46175350571473,
                    lng: -3.651448902264894
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.46166789580631,
                    lng: -3.6516229257042347
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-finish-building-create',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-select-gabled',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'EZModelRoof_setType'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'SN',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'EZModelRoof_setOrientation'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-close-roof-button',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        // // seleccionar el tab area
        // {
        //     event: 'EZModelScene_benchmarkDispatchClickListener',
        //     args: 'ez3d-areas-info-tab',
        //     undo: false,
        //     notifications: true,
        //     updateRender: false,
        //     eventFinished: 'tabChanged'
        // },
        // // seleccionar area
        {
            event: 'EZModelScene_benchmarkSetActiveWithoutIdListener',
            args: ['.ez3d-foldable-group-block-area:nth-child(2)'],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetActiveWithoutId_Finished',
            newStep: 'Select area and disable it'
        },
        // poner area disabled
        {
            event: 'EZModelArea_benchmarkSetAttributeWithoutIdListener',
            args: ['disabled', true],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetAttributeWithoutId_Finished'
        },
        // {
        //     event: 'EZModelScene_benchmarkDispatchClickListener',
        //     args: 'ez3d-add-keepout',
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'activeUpdated'
        // },
        // {
        //     event: 'EZModelScene_addLocationListener',
        //     args: [
        //         {
        //             lat: 40.46170308639121,
        //             lng: -3.6515716603513777
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'addLocation_Finished'
        // },
        // {
        //     event: 'EZModelScene_addLocationListener',
        //     args: [
        //         {
        //             lat: 40.46169680608205,
        //             lng: -3.6515845718906617
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'addLocation_Finished'
        // },
        // {
        //     event: 'EZModelScene_addLocationListener',
        //     args: [
        //         {
        //             lat: 40.46170257612674,
        //             lng: -3.651589426683628
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'addLocation_Finished'
        // },
        // {
        //     event: 'EZModelScene_addLocationListener',
        //     args: [
        //         {
        //             lat: 40.461708852959966,
        //             lng: -3.6515765155016857
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'addLocation_Finished'
        // },
        // {
        //     event: 'EZModelScene_benchmarkDispatchClickListener',
        //     args: 'ez3d-button-finish-keepout-create',
        //     undo: false,
        //     notifications: true,
        //     updateRender: false,
        //     eventFinished: 'activeUpdated'
        // },
        // {
        //     event: 'EZModelScene_benchmarkDispatchClickListener',
        //     args: 'ez3d-button-clone-keepout',
        //     undo: false,
        //     notifications: true,
        //     updateRender: false,
        //     eventFinished: 'activeUpdated'
        // },
        // {
        //     event: 'EZModelKeepout_moveListener',
        //     args: [
        //         {
        //             lat: 0.00005180581255359584,
        //             lng: 0.00007815298789948244
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'move_Finished'
        // },
        // // keepouts up
        // {
        //     event: 'EZModelScene_benchmarkDispatchClickListener',
        //     args: 'ez3d-add-keepout',
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'activeUpdated'
        // },
        // {
        //     event: 'EZModelScene_addLocationListener',
        //     args: [
        //         {
        //             lat: 40.46170360243475,
        //             lng: -3.6516342110032136
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'addLocation_Finished'
        // },
        // {
        //     event: 'EZModelScene_addLocationListener',
        //     args: [
        //         {
        //             lat: 40.46169678217636,
        //             lng: -3.6516282321529623
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'addLocation_Finished'
        // },
        // {
        //     event: 'EZModelScene_addLocationListener',
        //     args: [
        //         {
        //             lat: 40.4617005447021,
        //             lng: -3.651620854073199
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'addLocation_Finished'
        // },
        // {
        //     event: 'EZModelScene_addLocationListener',
        //     args: [
        //         {
        //             lat: 40.46170736532109,
        //             lng: -3.651626833127311
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'addLocation_Finished'
        // },
        // {
        //     event: 'EZModelScene_benchmarkDispatchClickListener',
        //     args: 'ez3d-button-finish-keepout-create',
        //     undo: false,
        //     notifications: true,
        //     updateRender: false,
        //     eventFinished: 'activeUpdated'
        // },
        // {
        //     event: 'EZModelScene_benchmarkDispatchClickListener',
        //     args: 'ez3d-button-clone-keepout',
        //     undo: false,
        //     notifications: true,
        //     updateRender: false,
        //     eventFinished: 'activeUpdated'
        // },
        // {
        //     event: 'EZModelKeepout_moveListener',
        //     args: [
        //         {
        //             lat: 0.000040256390818171894,
        //             lng: 0.00005326323966192703
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'move_Finished'
        // },
        // {
        //     event: 'EZModelScene_benchmarkDispatchClickListener',
        //     args: 'ez3d-button-clone-keepout',
        //     undo: false,
        //     notifications: true,
        //     updateRender: false,
        //     eventFinished: 'activeUpdated'
        // },
        // {
        //     event: 'EZModelKeepout_moveListener',
        //     args: [
        //         {
        //             lat: 0.000020933329594186034,
        //             lng: 0.000014814945451302464
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'move_Finished'
        // },
        // {
        //     event: 'EZModelScene_benchmarkDispatchClickListener',
        //     args: 'ez3d-button-clone-keepout',
        //     undo: false,
        //     notifications: true,
        //     updateRender: false,
        //     eventFinished: 'activeUpdated'
        // },
        // {
        //     event: 'EZModelKeepout_moveListener',
        //     args: [
        //         {
        //             lat: 0.00003945126320559211,
        //             lng: 0.000052557799686603346
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'move_Finished'
        // },
        // seleccionar el tab building para ponerlo activo
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-building-info-tab',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'tabChanged',
            newStep: 'Clone building 1 (creating building 2)'
        },
        // clone(b1) building 2
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-clone-building-button',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelBuilding_moveListener',
            args: [
                {
                    lat: 0.0000944140353453804,
                    lng: 0.00016659898280257934
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },
        // seleccionar el tab building para ponerlo activo
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-building-info-tab',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'tabChanged'
        },
        // clone(b1) building 3
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-clone-building-button',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated',
            newStep: 'Clone building 2 (creating building 3)'
        },
        {
            event: 'EZModelBuilding_moveListener',
            args: [
                {
                    lat: 0.00009482295720175671,
                    lng: 0.00016539667645432132
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },
        // create building 4
        {
            event: 'EZModelScene_createBuildingListener',
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'activeUpdated',
            newStep: 'Create building 4'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.46200968096842,
                    lng: -3.6511702733654916
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.46211764960022,
                    lng: -3.6509538384567097
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.46203464238385,
                    lng: -3.6508824816436634
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.46192674669373,
                    lng: -3.6510989587739267
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-finish-building-create',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-select-gabled',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'EZModelRoof_setType'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'SN',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'EZModelRoof_setOrientation'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-close-roof-button',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        // // // seleccionar el tab area
        // // {
        // //     event: 'EZModelScene_benchmarkDispatchClickListener',
        // //     args: 'ez3d-areas-info-tab',
        // //     undo: false,
        // //     notifications: true,
        // //     updateRender: false,
        // //     eventFinished: 'tabChanged'
        // // },
        // seleccionar area
        {
            event: 'EZModelScene_benchmarkSetActiveWithoutIdListener',
            args: ['.ez3d-foldable-group-block-area:nth-child(2)'],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetActiveWithoutId_Finished',
            newStep: 'Select area and disable it'
        },
        // poner area disabled
        {
            event: 'EZModelArea_benchmarkSetAttributeWithoutIdListener',
            args: ['disabled', true],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetAttributeWithoutId_Finished'
        },
        // // keepouts
        // {
        //     event: 'EZModelScene_benchmarkDispatchClickListener',
        //     args: 'ez3d-add-keepout',
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'activeUpdated'
        // },
        // {
        //     event: 'EZModelScene_addLocationListener',
        //     args: [
        //         {
        //             lat: 40.46195627441383,
        //             lng: -3.651069073090017
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'addLocation_Finished'
        // },
        // {
        //     event: 'EZModelScene_addLocationListener',
        //     args: [
        //         {
        //             lat: 40.461960540884284,
        //             lng: -3.651072579206886
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'addLocation_Finished'
        // },
        // {
        //     event: 'EZModelScene_addLocationListener',
        //     args: [
        //         {
        //             lat: 40.46196552129193,
        //             lng: -3.651062129234336
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'addLocation_Finished'
        // },
        // {
        //     event: 'EZModelScene_addLocationListener',
        //     args: [
        //         {
        //             lat: 40.46196125148101,
        //             lng: -3.6510586221454893
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'addLocation_Finished'
        // },
        // {
        //     event: 'EZModelScene_benchmarkDispatchClickListener',
        //     args: 'ez3d-button-finish-keepout-create',
        //     undo: false,
        //     notifications: true,
        //     updateRender: false,
        //     eventFinished: 'activeUpdated'
        // },
        // {
        //     event: 'EZModelScene_benchmarkDispatchClickListener',
        //     args: 'ez3d-button-clone-keepout',
        //     undo: false,
        //     notifications: true,
        //     updateRender: false,
        //     eventFinished: 'activeUpdated'
        // },
        // {
        //     event: 'EZModelKeepout_moveListener',
        //     args: [
        //         {
        //             lat: 0.00005096299572215912,
        //             lng: 0.00008046645278891873
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'move_Finished'
        // },
        // volver al building 3 para clonarlo
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-block-index-3',
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'setActive_Finished',
            newStep: 'Clone building 3 (creating building 5)'
        },
        // clone(b1) building 5
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-clone-building-button',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelBuilding_moveListener',
            args: [
                {
                    lat: -0.00029529853802756634,
                    lng: -0.00024672011381454695
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-building-info-tab',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'tabChanged'
        },
        // clone(b1) building 6
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-clone-building-button',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated',
            newStep: 'Clone building 5 (creating building 6)'
        },
        {
            event: 'EZModelBuilding_moveListener',
            args: [
                {
                    lat: 0.00009424540184710395,
                    lng: 0.0001670373617095322
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },
        // seleccionar el tab building para ponerlo activo
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-building-info-tab',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'tabChanged'
        },
        // clone(b1) building 7
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-clone-building-button',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated',
            newStep: 'Clone building 6 (creating building 7)'
        },
        {
            event: 'EZModelBuilding_moveListener',
            args: [
                {
                    lat: 0.00009492863640758742,
                    lng: 0.0001677227316130292
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },
        // create building 8
        {
            event: 'EZModelScene_createBuildingListener',
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'activeUpdated',
            newStep: 'Create building 8'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.46187551998493,
                    lng: -3.6510550397522015
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.46200256544283,
                    lng: -3.6507942921588947
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.461919655080564,
                    lng: -3.650724650052798
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.461792747925244,
                    lng: -3.6509853891514283
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-finish-building-create',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-select-gabled',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'EZModelRoof_setType'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'SN',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'EZModelRoof_setOrientation'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-close-roof-button',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        // // seleccionar el tab area
        // {
        //     event: 'EZModelScene_benchmarkDispatchClickListener',
        //     args: 'ez3d-areas-info-tab',
        //     undo: false,
        //     notifications: true,
        //     updateRender: false,
        //     eventFinished: 'tabChanged'
        // },
        // seleccionar area
        {
            event: 'EZModelScene_benchmarkSetActiveWithoutIdListener',
            args: ['.ez3d-foldable-group-block-area:nth-child(2)'],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetActiveWithoutId_Finished',
            newStep: 'Select area and disable it'
        },
        // poner area disabled
        {
            event: 'EZModelArea_benchmarkSetAttributeWithoutIdListener',
            args: ['disabled', true],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetAttributeWithoutId_Finished'
        },
        // // keepouts
        // {
        //     event: 'EZModelScene_benchmarkDispatchClickListener',
        //     args: 'ez3d-add-keepout',
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'activeUpdated'
        // },
        // {
        //     event: 'EZModelScene_addLocationListener',
        //     args: [
        //         {
        //             lat: 40.46184051959106,
        //             lng: -3.6509544284583564
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'addLocation_Finished'
        // },
        // {
        //     event: 'EZModelScene_addLocationListener',
        //     args: [
        //         {
        //             lat: 40.46183472805808,
        //             lng: -3.6509495506888596
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'addLocation_Finished'
        // },
        // {
        //     event: 'EZModelScene_addLocationListener',
        //     args: [
        //         {
        //             lat: 40.461828864039155,
        //             lng: -3.650961580300736
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'addLocation_Finished'
        // },
        // {
        //     event: 'EZModelScene_addLocationListener',
        //     args: [
        //         {
        //             lat: 40.46183465807896,
        //             lng: -3.6509664582142483
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'addLocation_Finished'
        // },
        // {
        //     event: 'EZModelScene_benchmarkDispatchClickListener',
        //     args: 'ez3d-button-finish-keepout-create',
        //     undo: false,
        //     notifications: true,
        //     updateRender: false,
        //     eventFinished: 'activeUpdated'
        // },
        // {
        //     event: 'EZModelScene_benchmarkDispatchClickListener',
        //     args: 'ez3d-button-clone-keepout',
        //     undo: false,
        //     notifications: true,
        //     updateRender: false,
        //     eventFinished: 'activeUpdated'
        // },
        // {
        //     event: 'EZModelKeepout_moveListener',
        //     args: [
        //         {
        //             lat: 0.00003442502883643783,
        //             lng: 0.00003167233979306516
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'move_Finished'
        // },
        // {
        //     event: 'EZModelScene_benchmarkDispatchClickListener',
        //     args: 'ez3d-button-clone-keepout',
        //     undo: false,
        //     notifications: true,
        //     updateRender: false,
        //     eventFinished: 'activeUpdated'
        // },
        // {
        //     event: 'EZModelKeepout_moveListener',
        //     args: [
        //         {
        //             lat: 0.00002715751934090349,
        //             lng: 0.000037202442691341986
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'move_Finished'
        // },
        // {
        //     event: 'EZModelScene_benchmarkDispatchClickListener',
        //     args: 'ez3d-button-clone-keepout',
        //     undo: false,
        //     notifications: true,
        //     updateRender: false,
        //     eventFinished: 'activeUpdated'
        // },
        // {
        //     event: 'EZModelKeepout_moveListener',
        //     args: [
        //         {
        //             lat: 0.000050298772805490444,
        //             lng: 0.00007842682134295842
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'move_Finished'
        // },
        // volver al building 7 para clonarlo
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-block-index-7',
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'setActive_Finished',
            newStep: 'Cloning building 7 (creating building 9)'
        },
        // clone(b1) building 9
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-clone-building-button',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelBuilding_moveListener',
            args: [
                {
                    lat: -0.00039432130414951416,
                    lng: -0.0002194707919884742
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },
        // seleccionar el tab building para ponerlo activo
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-building-info-tab',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'tabChanged'
        },
        // clone(b1) building 10
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-clone-building-button',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated',
            newStep: 'Cloning building 9 (creating building 10)'
        },
        {
            event: 'EZModelBuilding_moveListener',
            args: [
                {
                    lat: 0.00009516810704468526,
                    lng: 0.00016667570456352365
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },
        // seleccionar el tab building para ponerlo activo
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-building-info-tab',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'tabChanged'
        },
        // clone(b1) building 11
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-clone-building-button',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated',
            newStep: 'Cloning building 10 (creating building 11)'
        },
        {
            event: 'EZModelBuilding_moveListener',
            args: [
                {
                    lat: 0.0000943692819532771,
                    lng: 0.00016615729649060995
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },
        // create(b1) building 12
        {
            event: 'EZModelScene_createBuildingListener',
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'activeUpdated',
            newStep: 'Create building 12'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.46164467833498,
                    lng: -3.650914490559724
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.461771366327895,
                    lng: -3.6506565339319534
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.46168745983423,
                    lng: -3.650585653097617
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.46156091216311,
                    lng: -3.6508433564070817
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-finish-building-create',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-select-gabled',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'EZModelRoof_setType'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'SN',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'EZModelRoof_setOrientation'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-close-roof-button',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        // // seleccionar el tab area
        // {
        //     event: 'EZModelScene_benchmarkDispatchClickListener',
        //     args: 'ez3d-areas-info-tab',
        //     undo: false,
        //     notifications: true,
        //     updateRender: false,
        //     eventFinished: 'tabChanged'
        // },
        // seleccionar area
        {
            event: 'EZModelScene_benchmarkSetActiveWithoutIdListener',
            args: ['.ez3d-foldable-group-block-area:nth-child(2)'],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetActiveWithoutId_Finished',
            newStep: 'Select area and disable it'
        },
        // poner area disabled
        {
            event: 'EZModelArea_benchmarkSetAttributeWithoutIdListener',
            args: ['disabled', true],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetAttributeWithoutId_Finished'
        },
        // // keepouts "crearlosssssss"
        // {
        //     event: 'EZModelScene_benchmarkDispatchClickListener',
        //     args: 'ez3d-add-keepout',
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'activeUpdated'
        // },
        // {
        //     event: 'EZModelScene_addLocationListener',
        //     args: [
        //         {
        //             lat: 40.46184051959106,
        //             lng: -3.6509544284583564
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'addLocation_Finished'
        // },
        // {
        //     event: 'EZModelScene_addLocationListener',
        //     args: [
        //         {
        //             lat: 40.46183472805808,
        //             lng: -3.6509495506888596
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'addLocation_Finished'
        // },
        // {
        //     event: 'EZModelScene_addLocationListener',
        //     args: [
        //         {
        //             lat: 40.461828864039155,
        //             lng: -3.650961580300736
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'addLocation_Finished'
        // },
        // {
        //     event: 'EZModelScene_addLocationListener',
        //     args: [
        //         {
        //             lat: 40.46183465807896,
        //             lng: -3.6509664582142483
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'addLocation_Finished'
        // },
        // {
        //     event: 'EZModelScene_benchmarkDispatchClickListener',
        //     args: 'ez3d-button-finish-keepout-create',
        //     undo: false,
        //     notifications: true,
        //     updateRender: false,
        //     eventFinished: 'activeUpdated'
        // },
        // {
        //     event: 'EZModelScene_benchmarkDispatchClickListener',
        //     args: 'ez3d-button-clone-keepout',
        //     undo: false,
        //     notifications: true,
        //     updateRender: false,
        //     eventFinished: 'activeUpdated'
        // },
        // {
        //     event: 'EZModelKeepout_moveListener',
        //     args: [
        //         {
        //             lat: 0.00003442502883643783,
        //             lng: 0.00003167233979306516
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'move_Finished'
        // },
        // seleccionar el tab building para ponerlo activo
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-building-info-tab',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'tabChanged'
        },
        // // create(b1) building 13
        // {
        //     event: 'EZModelScene_createBuildingListener',
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'activeUpdated'
        // },
        // {
        //     event: 'EZModelScene_addLocationListener',
        //     args: [
        //         {
        //             lat: 40.461770608887846,
        //             lng: -3.650655760586037
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'addLocation_Finished'
        // },
        // {
        //     event: 'EZModelScene_addLocationListener',
        //     args: [
        //         {
        //             lat: 40.461793703711535,
        //             lng: -3.650609253818307
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'addLocation_Finished'
        // },
        // {
        //     event: 'EZModelScene_addLocationListener',
        //     args: [
        //         {
        //             lat: 40.46172831370276,
        //             lng: -3.6505534011069773
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'addLocation_Finished'
        // },
        // {
        //     event: 'EZModelScene_addLocationListener',
        //     args: [
        //         {
        //             lat: 40.461705218858555,
        //             lng: -3.65059987853208
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'addLocation_Finished'
        // },
        // {
        //     event: 'EZModelScene_benchmarkDispatchClickListener',
        //     args: 'ez3d-button-finish-building-create',
        //     undo: false,
        //     notifications: true,
        //     updateRender: false,
        //     eventFinished: 'activeUpdated'
        // },
        // {
        //     event: 'EZModelScene_waitListener',
        //     args: 1000,
        //     undo: false,
        //     notifications: true,
        //     updateRender: false,
        //     eventFinished: 'wait_Finished'
        // },
        // {
        //     event: 'EZModelScene_benchmarkDispatchClickListener',
        //     args: 'ez3d-select-gabled',
        //     undo: false,
        //     notifications: true,
        //     updateRender: false,
        //     eventFinished: 'EZModelRoof_setType'
        // },
        // {
        //     event: 'EZModelScene_benchmarkDispatchClickListener',
        //     args: 'SN',
        //     undo: false,
        //     notifications: true,
        //     updateRender: false,
        //     eventFinished: 'EZModelRoof_setOrientation'
        // },
        // {
        //     event: 'EZModelScene_benchmarkDispatchClickListener',
        //     args: 'ez3d-close-roof-button',
        //     undo: false,
        //     notifications: true,
        //     updateRender: false,
        //     eventFinished: 'activeUpdated'
        // },
        // // // keepouts "crearlosssssss"
        // // seleccionar el tab building para ponerlo activo
        // {
        //     event: 'EZModelScene_benchmarkDispatchClickListener',
        //     args: 'ez3d-building-info-tab',
        //     undo: false,
        //     notifications: true,
        //     updateRender: false,
        //     eventFinished: 'tabChanged'
        // },
        // // create(b1) building 14
        // {
        //     event: 'EZModelScene_createBuildingListener',
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'activeUpdated'
        // },
        // {
        //     event: 'EZModelScene_addLocationListener',
        //     args: [
        //         {
        //             lat: 40.461704315717796,
        //             lng: -3.6505994358335747
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'addLocation_Finished'
        // },
        // {
        //     event: 'EZModelScene_addLocationListener',
        //     args: [
        //         {
        //             lat: 40.46169237916414,
        //             lng: -3.6505891390979106
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'addLocation_Finished'
        // },
        // {
        //     event: 'EZModelScene_addLocationListener',
        //     args: [
        //         {
        //             lat: 40.461710448393816,
        //             lng: -3.6505531605067176
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'addLocation_Finished'
        // },
        // {
        //     event: 'EZModelScene_addLocationListener',
        //     args: [
        //         {
        //             lat: 40.461722383362094,
        //             lng: -3.650563454360647
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'addLocation_Finished'
        // },
        // {
        //     event: 'EZModelScene_benchmarkDispatchClickListener',
        //     args: 'ez3d-button-finish-building-create',
        //     undo: false,
        //     notifications: true,
        //     updateRender: false,
        //     eventFinished: 'activeUpdated'
        // },
        // {
        //     event: 'EZModelScene_waitListener',
        //     args: 1000,
        //     undo: false,
        //     notifications: true,
        //     updateRender: false,
        //     eventFinished: 'wait_Finished'
        // },
        // {
        //     event: 'EZModelScene_benchmarkDispatchClickListener',
        //     args: 'ez3d-select-pent',
        //     undo: false,
        //     notifications: true,
        //     updateRender: false,
        //     eventFinished: 'EZModelRoof_setType'
        // },
        // // // keepouts "crearlosssssss"
        // // create(b1) building 15
        // {
        //     event: 'EZModelScene_createBuildingListener',
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'activeUpdated'
        // },
        // {
        //     event: 'EZModelScene_addLocationListener',
        //     args: [
        //         {
        //             lat: 40.46178589752692,
        //             lng: -3.650601680360784
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'addLocation_Finished'
        // },
        // {
        //     event: 'EZModelScene_addLocationListener',
        //     args: [
        //         {
        //             lat: 40.46179519414541,
        //             lng: -3.650582504495042
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'addLocation_Finished'
        // },
        // {
        //     event: 'EZModelScene_addLocationListener',
        //     args: [
        //         {
        //             lat: 40.46173757594638,
        //             lng: -3.650534474494741
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'addLocation_Finished'
        // },
        // {
        //     event: 'EZModelScene_addLocationListener',
        //     args: [
        //         {
        //             lat: 40.46172827822753,
        //             lng: -3.650553640971384
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'addLocation_Finished'
        // },
        // {
        //     event: 'EZModelScene_benchmarkDispatchClickListener',
        //     args: 'ez3d-button-finish-building-create',
        //     undo: false,
        //     notifications: true,
        //     updateRender: false,
        //     eventFinished: 'activeUpdated'
        // },
        // {
        //     event: 'EZModelScene_waitListener',
        //     args: 1000,
        //     undo: false,
        //     notifications: true,
        //     updateRender: false,
        //     eventFinished: 'wait_Finished'
        // },
        // {
        //     event: 'EZModelScene_benchmarkDispatchClickListener',
        //     args: 'ez3d-select-gabled',
        //     undo: false,
        //     notifications: true,
        //     updateRender: false,
        //     eventFinished: 'EZModelRoof_setType'
        // },
        // {
        //     event: 'EZModelScene_benchmarkDispatchClickListener',
        //     args: 'SN',
        //     undo: false,
        //     notifications: true,
        //     updateRender: false,
        //     eventFinished: 'EZModelRoof_setOrientation'
        // },
        // {
        //     event: 'EZModelScene_benchmarkDispatchClickListener',
        //     args: 'ez3d-close-roof-button',
        //     undo: false,
        //     notifications: true,
        //     updateRender: false,
        //     eventFinished: 'activeUpdated'
        // },
        // // // keepouts "crearlosssssss"
        // volver al building 11 para clonarlo
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-block-index-11',
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'setActive_Finished',
            newStep: 'Clone building 11 (creating building 16)'
        },
        // clone building 16
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-clone-building-button',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelBuilding_moveListener',
            args: [
                {
                    lat: -0.0002975107677940514,
                    lng: -0.00024601460388895546
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },
        // seleccionar el tab building para ponerlo activo
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-building-info-tab',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'tabChanged'
        },
        // clone building 17
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-clone-building-button',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated',
            newStep: 'Clone building 16 (creating building 17)'
        },
        {
            event: 'EZModelBuilding_moveListener',
            args: [
                {
                    lat: 0.0000950610262506757,
                    lng: 0.00016683390839444812
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },
        // seleccionar el tab building para ponerlo activo
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-building-info-tab',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'tabChanged'
        },
        // clone building 18
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-clone-building-button',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated',
            newStep: 'Clone building 17 (creating building 18)'
        },
        {
            event: 'EZModelBuilding_moveListener',
            args: [
                {
                    lat: 0.00009492863640758742,
                    lng: 0.0001677227316130292
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },
        // volver al building 12 para clonarlo
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-block-index-12',
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'setActive_Finished'
        },
        // clone building 19
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-clone-building-button',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated',
            newStep: 'Clone building 18 (creating building 19)'
        },
        {
            event: 'EZModelBuilding_moveListener',
            args: [
                {
                    lat: -0.00012505407068630348,
                    lng: 0.00010741246661227777
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },
        // // volver al building 13 para clonarlo
        // {
        //     event: 'EZModelScene_benchmarkDispatchClickListener',
        //     args: 'ez3d-block-index-13',
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'setActive_Finished'
        // },
        // // clone building 20
        // {
        //     event: 'EZModelScene_benchmarkDispatchClickListener',
        //     args: 'ez3d-clone-building-button',
        //     undo: false,
        //     notifications: true,
        //     updateRender: false,
        //     eventFinished: 'activeUpdated'
        // },
        // {
        //     event: 'EZModelBuilding_moveListener',
        //     args: [
        //         {
        //             lat: 0.00009492863640758742,
        //             lng: 0.0001677227316130292
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'move_Finished'
        // },
        // // volver al building 14 para clonarlo
        // {
        //     event: 'EZModelScene_benchmarkDispatchClickListener',
        //     args: 'ez3d-block-index-14',
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'setActive_Finished'
        // },
        // // clone building 21
        // {
        //     event: 'EZModelScene_benchmarkDispatchClickListener',
        //     args: 'ez3d-clone-building-button',
        //     undo: false,
        //     notifications: true,
        //     updateRender: false,
        //     eventFinished: 'activeUpdated'
        // },
        // {
        //     event: 'EZModelBuilding_moveListener',
        //     args: [
        //         {
        //             lat: 0.00009492863640758742,
        //             lng: 0.0001677227316130292
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'move_Finished'
        // },
        // // volver al building 15 para clonarlo
        // {
        //     event: 'EZModelScene_benchmarkDispatchClickListener',
        //     args: 'ez3d-block-index-15',
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'setActive_Finished'
        // },
        // // clone building 22
        // {
        //     event: 'EZModelScene_benchmarkDispatchClickListener',
        //     args: 'ez3d-clone-building-button',
        //     undo: false,
        //     notifications: true,
        //     updateRender: false,
        //     eventFinished: 'activeUpdated'
        // },
        // {
        //     event: 'EZModelBuilding_moveListener',
        //     args: [
        //         {
        //             lat: 0.00009492863640758742,
        //             lng: 0.0001677227316130292
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'move_Finished'
        // },
        // volver al building 18 para clonarlo
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-block-index-15',
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'setActive_Finished',
            newStep: 'Clone building 15 (creating building 23)'
        },
        // clone building 23
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-clone-building-button',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelBuilding_moveListener',
            args: [
                {
                    lat: -0.00033343732290802564,
                    lng: -0.0002657229483244983
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },
        // clone building 24
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-clone-building-button',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated',
            newStep: 'Clone building 23 (creating building 24)'
        },
        {
            event: 'EZModelBuilding_moveListener',
            args: [
                {
                    lat: 0.0000952116758980992,
                    lng: 0.00016572515421398037
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },
        // clone building 25
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-clone-building-button',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated',
            newStep: 'Clone building 24 (creating building 25)'
        },
        {
            event: 'EZModelBuilding_moveListener',
            args: [
                {
                    lat: 0.00009592309875472438,
                    lng: 0.00016441686490820118
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },
        // clone building 26
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-clone-building-button',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated',
            newStep: 'Clone building 25 (creating building 26)'
        },
        {
            event: 'EZModelBuilding_moveListener',
            args: [
                {
                    lat: 0.00009450708063241109,
                    lng: 0.00016612645620428523
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },
        // create building 27
        {
            event: 'EZModelScene_createBuildingListener',
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'activeUpdated',
            newStep: 'Create building 27'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.461426295996105,
                    lng: -3.6505319664327756
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.4614880316163,
                    lng: -3.650405173105913
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.461404650544225,
                    lng: -3.6503352472991217
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.46134295249402,
                    lng: -3.6504619418960718
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-finish-building-create',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-select-gabled',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'EZModelRoof_setType'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'SN',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'EZModelRoof_setOrientation'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-close-roof-button',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        // // seleccionar el tab area
        // {
        //     event: 'EZModelScene_benchmarkDispatchClickListener',
        //     args: 'ez3d-areas-info-tab',
        //     undo: false,
        //     notifications: true,
        //     updateRender: false,
        //     eventFinished: 'tabChanged'
        // },
        // seleccionar area
        {
            event: 'EZModelScene_benchmarkSetActiveWithoutIdListener',
            args: ['.ez3d-foldable-group-block-area:nth-child(2)'],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetActiveWithoutId_Finished',
            newStep: 'Select area and disable it'
        },
        // poner area disabled
        {
            event: 'EZModelArea_benchmarkSetAttributeWithoutIdListener',
            args: ['disabled', true],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetAttributeWithoutId_Finished'
        },
        // // keepouts "crearlosssssss"
        // // volver al building 13 para clonarlo
        // {
        //     event: 'EZModelScene_benchmarkDispatchClickListener',
        //     args: 'ez3d-block-index-13',
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'setActive_Finished'
        // },
        // // clone building 28
        // {
        //     event: 'EZModelScene_benchmarkDispatchClickListener',
        //     args: 'ez3d-clone-building-button',
        //     undo: false,
        //     notifications: true,
        //     updateRender: false,
        //     eventFinished: 'activeUpdated'
        // },
        // {
        //     event: 'EZModelBuilding_moveListener',
        //     args: [
        //         {
        //             lat: 0.00009492863640758742,
        //             lng: 0.0001677227316130292
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'move_Finished'
        // },
        // // volver al building 14 para clonarlo
        // {
        //     event: 'EZModelScene_benchmarkDispatchClickListener',
        //     args: 'ez3d-block-index-14',
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'setActive_Finished'
        // },
        // // clone building 29
        // {
        //     event: 'EZModelScene_benchmarkDispatchClickListener',
        //     args: 'ez3d-clone-building-button',
        //     undo: false,
        //     notifications: true,
        //     updateRender: false,
        //     eventFinished: 'activeUpdated'
        // },
        // {
        //     event: 'EZModelBuilding_moveListener',
        //     args: [
        //         {
        //             lat: 0.00009492863640758742,
        //             lng: 0.0001677227316130292
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'move_Finished'
        // },
        // // volver al building 15 para clonarlo
        // {
        //     event: 'EZModelScene_benchmarkDispatchClickListener',
        //     args: 'ez3d-block-index-15',
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'setActive_Finished'
        // },
        // // clone building 30
        // {
        //     event: 'EZModelScene_benchmarkDispatchClickListener',
        //     args: 'ez3d-clone-building-button',
        //     undo: false,
        //     notifications: true,
        //     updateRender: false,
        //     eventFinished: 'activeUpdated'
        // },
        // {
        //     event: 'EZModelBuilding_moveListener',
        //     args: [
        //         {
        //             lat: 0.00009492863640758742,
        //             lng: 0.0001677227316130292
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'move_Finished'
        // },
        // volver al building 26 para clonarlo
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-block-index-20',
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'setActive_Finished',
            newStep: 'Clone building 20 (creating building 31)'
        },
        // clone building 31
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-clone-building-button',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelBuilding_moveListener',
            args: [
                {
                    lat: -0.0003846109335370329,
                    lng: -0.000417858794937942
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },
        // clone building 32
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-clone-building-button',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated',
            newStep: 'Clone building 31 (creating building 32)'
        },
        {
            event: 'EZModelBuilding_moveListener',
            args: [
                {
                    lat: 0.00009526031827533643,
                    lng: 0.00016647110279066624
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },
        // clone building 33
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-clone-building-button',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated',
            newStep: 'Clone building 32 (creating building 33)'
        },
        {
            event: 'EZModelBuilding_moveListener',
            args: [
                {
                    lat: 0.00009424213084940902,
                    lng: 0.0001659236584385937
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },
        // clone building 34
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-clone-building-button',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated',
            newStep: 'Clone building 33 (creating building 34)'
        },
        {
            event: 'EZModelBuilding_moveListener',
            args: [
                {
                    lat: 0.00009571296499188975,
                    lng: 0.00016605374363454217
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },
        // clone building 35
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-clone-building-button',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated',
            newStep: 'Clone building 34 (creating building 35)'
        },
        {
            event: 'EZModelBuilding_moveListener',
            args: [
                {
                    lat: 0.00009486151588333769,
                    lng: 0.00016637544732711618
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },
        // hasta aqui ----------------------------------------------------------
        // create building 1(36) abajo
        {
            event: 'EZModelScene_createBuildingListener',
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.46173755647043,
                    lng: -3.651732786156637
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.461603115877246,
                    lng: -3.651614558010505
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.461547698075776,
                    lng: -3.6517231854718792
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.46168206228908,
                    lng: -3.6518413821190188
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-finish-building-create',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-select-gabled',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'EZModelRoof_setType'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-close-roof-button',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        // seleccionar area
        {
            event: 'EZModelScene_benchmarkSetActiveWithoutIdListener',
            args: ['.ez3d-foldable-group-block-area:nth-child(1)'],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetActiveWithoutId_Finished'
        },
        // poner area disabled
        {
            event: 'EZModelArea_benchmarkSetAttributeWithoutIdListener',
            args: ['disabled', true],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetAttributeWithoutId_Finished'
        },
        // // keepouts
        // {
        //     event: 'EZModelScene_benchmarkDispatchClickListener',
        //     args: 'ez3d-add-keepout',
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'activeUpdated'
        // },
        // {
        //     event: 'EZModelScene_addLocationListener',
        //     args: [
        //         {
        //             lat: 40.46170333546811,
        //             lng: -3.6517879468881698
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'addLocation_Finished'
        // },
        // {
        //     event: 'EZModelScene_addLocationListener',
        //     args: [
        //         {
        //             lat: 40.46169929267728,
        //             lng: -3.6517958774065673
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'addLocation_Finished'
        // },
        // {
        //     event: 'EZModelScene_addLocationListener',
        //     args: [
        //         {
        //             lat: 40.461694426516665,
        //             lng: -3.6517916078426853
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'addLocation_Finished'
        // },
        // {
        //     event: 'EZModelScene_addLocationListener',
        //     args: [
        //         {
        //             lat: 40.46169846855076,
        //             lng: -3.65178367578551
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'addLocation_Finished'
        // },
        // {
        //     event: 'EZModelScene_benchmarkDispatchClickListener',
        //     args: 'ez3d-button-finish-keepout-create',
        //     undo: false,
        //     notifications: true,
        //     updateRender: false,
        //     eventFinished: 'activeUpdated'
        // },
        // {
        //     event: 'EZModelScene_benchmarkDispatchClickListener',
        //     args: 'ez3d-button-clone-keepout',
        //     undo: false,
        //     notifications: true,
        //     updateRender: false,
        //     eventFinished: 'activeUpdated'
        // },
        // {
        //     event: 'EZModelKeepout_moveListener',
        //     args: [
        //         {
        //             lat: -0.00011666464158821555,
        //             lng: 0.00009832775874363264
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'move_Finished'
        // },
        // {
        //     event: 'EZModelScene_benchmarkDispatchClickListener',
        //     args: 'ez3d-add-keepout',
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'activeUpdated'
        // },
        // {
        //     event: 'EZModelScene_addLocationListener',
        //     args: [
        //         {
        //             lat: 40.46166718413765,
        //             lng: -3.651776870700229
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'addLocation_Finished'
        // },
        // {
        //     event: 'EZModelScene_addLocationListener',
        //     args: [
        //         {
        //             lat: 40.46167064728513,
        //             lng: -3.651770227498973
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'addLocation_Finished'
        // },
        // {
        //     event: 'EZModelScene_addLocationListener',
        //     args: [
        //         {
        //             lat: 40.461662493487964,
        //             lng: -3.651762907406879
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'addLocation_Finished'
        // },
        // {
        //     event: 'EZModelScene_addLocationListener',
        //     args: [
        //         {
        //             lat: 40.46165903049218,
        //             lng: -3.651769550351655
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'addLocation_Finished'
        // },
        // {
        //     event: 'EZModelScene_benchmarkDispatchClickListener',
        //     args: 'ez3d-button-finish-keepout-create',
        //     undo: false,
        //     notifications: true,
        //     updateRender: false,
        //     eventFinished: 'activeUpdated'
        // },
        // {
        //     event: 'EZModelScene_benchmarkDispatchClickListener',
        //     args: 'ez3d-button-clone-keepout',
        //     undo: false,
        //     notifications: true,
        //     updateRender: false,
        //     eventFinished: 'activeUpdated'
        // },
        // {
        //     event: 'EZModelKeepout_moveListener',
        //     args: [
        //         {
        //             lat: -0.000021187968314961836,
        //             lng: 0.000026129079829573243
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'move_Finished'
        // },
        // {
        //     event: 'EZModelScene_benchmarkDispatchClickListener',
        //     args: 'ez3d-button-clone-keepout',
        //     undo: false,
        //     notifications: true,
        //     updateRender: false,
        //     eventFinished: 'activeUpdated'
        // },
        // {
        //     event: 'EZModelKeepout_moveListener',
        //     args: [
        //         {
        //             lat: -0.00002694272023262556,
        //             lng: 0.000013064532219164704
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'move_Finished'
        // },
        // seleccionar el tab building para ponerlo activo
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-building-info-tab',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'tabChanged'
        },
        // clone building 2(37) abajo
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-clone-building-button',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelBuilding_moveListener',
            args: [
                {
                    lat: -0.00035483776253641963,
                    lng: 0.00024210623767340067
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },
        // seleccionar el tab building para ponerlo activo
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-building-info-tab',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'tabChanged'
        },
        // clone building 3(38) abajo
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-clone-building-button',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelBuilding_moveListener',
            args: [
                {
                    lat: -0.0002956224460533008,
                    lng: 0.0002014402397017001
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },
        // create building 4(39) abajo
        {
            event: 'EZModelScene_createBuildingListener',
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.46154736529684,
                    lng: -3.651723171152462
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.461449316652136,
                    lng: -3.651639512350739
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.46150306540369,
                    lng: -3.6515307112563202
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_addLocationListener',
            args: [
                {
                    lat: 40.4616018111564,
                    lng: -3.651614254141634
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'addLocation_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-button-finish-building-create',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelScene_waitListener',
            args: 1000,
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'wait_Finished'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-select-gabled',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'EZModelRoof_setType'
        },
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-close-roof-button',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        // seleccionar area
        {
            event: 'EZModelScene_benchmarkSetActiveWithoutIdListener',
            args: ['.ez3d-foldable-group-block-area:nth-child(1)'],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetActiveWithoutId_Finished'
        },
        // poner area disabled
        {
            event: 'EZModelArea_benchmarkSetAttributeWithoutIdListener',
            args: ['disabled', true],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'benchmarkSetAttributeWithoutId_Finished'
        },
        // // keepouts
        // {
        //     event: 'EZModelScene_benchmarkDispatchClickListener',
        //     args: 'ez3d-add-keepout',
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'activeUpdated'
        // },
        // {
        //     event: 'EZModelScene_addLocationListener',
        //     args: [
        //         {
        //             lat: 40.461568908414186,
        //             lng: -3.651672746277231
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'addLocation_Finished'
        // },
        // {
        //     event: 'EZModelScene_addLocationListener',
        //     args: [
        //         {
        //             lat: 40.46156509937162,
        //             lng: -3.651680111327916
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'addLocation_Finished'
        // },
        // {
        //     event: 'EZModelScene_addLocationListener',
        //     args: [
        //         {
        //             lat: 40.46156032168909,
        //             lng: -3.651675848628434
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'addLocation_Finished'
        // },
        // {
        //     event: 'EZModelScene_addLocationListener',
        //     args: [
        //         {
        //             lat: 40.46156413023415,
        //             lng: -3.651668482299624
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'addLocation_Finished'
        // },
        // {
        //     event: 'EZModelScene_benchmarkDispatchClickListener',
        //     args: 'ez3d-button-finish-keepout-create',
        //     undo: false,
        //     notifications: true,
        //     updateRender: false,
        //     eventFinished: 'activeUpdated'
        // },
        // {
        //     event: 'EZModelScene_benchmarkDispatchClickListener',
        //     args: 'ez3d-button-clone-keepout',
        //     undo: false,
        //     notifications: true,
        //     updateRender: false,
        //     eventFinished: 'activeUpdated'
        // },
        // {
        //     event: 'EZModelKeepout_moveListener',
        //     args: [
        //         {
        //             lat: -0.00008450654687663928,
        //             lng: 0.00006173185603097764
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'move_Finished'
        // },
        // {
        //     event: 'EZModelScene_benchmarkDispatchClickListener',
        //     args: 'ez3d-add-keepout',
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'activeUpdated'
        // },
        // {
        //     event: 'EZModelScene_addLocationListener',
        //     args: [
        //         {
        //             lat: 40.461535664572416,
        //             lng: -3.651666076713808
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'addLocation_Finished'
        // },
        // {
        //     event: 'EZModelScene_addLocationListener',
        //     args: [
        //         {
        //             lat: 40.461539593504995,
        //             lng: -3.6516588096037497
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'addLocation_Finished'
        // },
        // {
        //     event: 'EZModelScene_addLocationListener',
        //     args: [
        //         {
        //             lat: 40.4615306932768,
        //             lng: -3.6516505069222878
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'addLocation_Finished'
        // },
        // {
        //     event: 'EZModelScene_addLocationListener',
        //     args: [
        //         {
        //             lat: 40.46152676455903,
        //             lng: -3.651657773757648
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'addLocation_Finished'
        // },
        // {
        //     event: 'EZModelScene_benchmarkDispatchClickListener',
        //     args: 'ez3d-button-finish-keepout-create',
        //     undo: false,
        //     notifications: true,
        //     updateRender: false,
        //     eventFinished: 'activeUpdated'
        // },
        // {
        //     event: 'EZModelScene_benchmarkDispatchClickListener',
        //     args: 'ez3d-button-clone-keepout',
        //     undo: false,
        //     notifications: true,
        //     updateRender: false,
        //     eventFinished: 'activeUpdated'
        // },
        // {
        //     event: 'EZModelKeepout_moveListener',
        //     args: [
        //         {
        //             lat: -0.000021264379952867785,
        //             lng: 0.000023543553076432744
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'move_Finished'
        // },
        // seleccionar el tab building para ponerlo activo
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-building-info-tab',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'tabChanged'
        },
        // clone building 5(40)
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-clone-building-button',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelBuilding_moveListener',
            args: [
                {
                    lat: -0.00035524407852705053,
                    lng: 0.00024220764797933114
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },
        // clone building 6(41)
        {
            event: 'EZModelScene_benchmarkDispatchClickListener',
            args: 'ez3d-clone-building-button',
            undo: false,
            notifications: true,
            updateRender: false,
            eventFinished: 'activeUpdated'
        },
        {
            event: 'EZModelBuilding_moveListener',
            args: [
                {
                    lat: -0.00029352186236764055,
                    lng: 0.00020282754957889892
                }
            ],
            undo: false,
            notifications: true,
            updateRender: true,
            eventFinished: 'move_Finished'
        },

        // ADD TREES -----------------------------------------------------------

        // add Tree
        // {
        //     event: 'EZModelScene_benchmarkDispatchClickListener',
        //     args: 'ez3d-add-tree',
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'createTree_Finished'
        // },
        // {
        //     event: 'EZModelTree_moveListener',
        //     args: [
        //         {
        //             lat: 0.00003147322242824657,
        //             lng: 0.0002632447972144014
        //         }
        //     ],
        //     undo: false,
        //     notifications: true,
        //     updateRender: true,
        //     eventFinished: 'move_Finished'
        // },

        // este es el final ----------------------------------------------------

        // // seleccionar el tab building para ponerlo activo
        // {
        //     event: 'EZModelScene_benchmarkDispatchClickListener',
        //     args: 'ez3d-building-info-tab',
        //     undo: false,
        //     notifications: true,
        //     updateRender: false,
        //     eventFinished: 'tabChanged'
        // },
        // // // Generate all textures
        // {
        //     event: 'EZModelScene_getTexturesListener',
        //     undo: false,
        //     notifications: true,
        //     updateRender: false,
        //     eventFinished: 'getTextures_Finished'
        // },
        // // Finish
        // {
        //     event: 'EZModelScene_fitRangeToContainerListener',
        //     args: {
        //         range: [-101.30229704439205, -96.86723854282053, 82.87594581883468, 72.67119527230355],
        //         duration: 10000,
        //         limits: true,
        //         container: 'svgProject'
        //     },
        //     undo: false,
        //     notifications: true,
        //     updateRender: false,
        //     eventFinished: 'fitRangeToContainer_Finished'
        // },
    ];
    ez3dScene.benchmarkExecuteEventsArray(eventList);
};

EZModelBuilding.prototype.addStepToCustomShape = function() {
    var self = this;

    var promise = new Promise((resolve, reject) => {
        if (this.customPath) {
            const customShapeSteps = this.customPath.customShapeSteps;
            const prefs = ez3dScene.layoutRules.scenePreferences;
            customShapeSteps.push([prefs.defaultWallDimension, prefs.defaultCornerAngle]);
            this.recalculateStepCustomPath()
                .then(() => {
                    resolve({
                        status: 'FINISHED'
                    });
                });
        } else {
            resolve({
                status: 'FINISHED'
            });
        }
    });

    return promise;
};

EZModelScene.prototype.createBuildingShape = function() {
    const self = this;

    var promise = new Promise((resolve, reject) => {
        const oldObjectId = self.active.id;
        const snapOldValue = self.layoutRules.scenePreferences.snapToGuides;
        self.layoutRules.scenePreferences.snapToGuides = false;

        // Create building
        const newBuilding = self.factories['Building'].createBuilding(self);
        newBuilding.creatingBuildingShape = true;
        self.tempObject = newBuilding;

        // Create custom building path
        newBuilding.customPath = new EZModelPath(self, []);
        newBuilding.customPath.locked = true;

        // Create custom keepout path
        newBuilding.customKeepoutPath = new EZModelPath(self, []);
        newBuilding.customKeepoutPath.locked = true;


        self.setActive(newBuilding.id)
            .then(() => {
                /**
                 * Function to execute after finishing operation.
                 */
                function finishCreateBuildingShape() {
                    delete newBuilding.customPath.locked;
                    self.layoutRules.scenePreferences.snapToGuides = snapOldValue;
                    const buildingShapeType = newBuilding.customPath.buildingShapeType;
                    delete newBuilding.creatingBuildingShape;

                    self.createBuilding(true, oldObjectId)
                        .then((result) => {
                            if (result.status === 'CANCELLED') {
                                ee.emitEvent('createBuilding_Cancelled');
                                ee.emitEvent('cancelCreateBuildingShapeOperator');
                            } else {
                                ee.removeListener('cancelCreateBuildingShapeOperator', cancelCreateBuildingShape);
                                if (buildingShapeType === 'oShape') {
                                    // Create keepout
                                    newBuilding.createKeepout(true)
                                        .then(() => {
                                            delete newBuilding.customPath;
                                            delete newBuilding.customKeepoutPath;
                                            const status = {
                                                status: 'FINISHED',
                                                undo: true
                                            };
                                            resolve(status);
                                        });
                                } else {
                                    delete newBuilding.creatingBuildingShape;
                                    delete newBuilding.customPath;
                                    delete newBuilding.customKeepoutPath;
                                    const status = {
                                        status: 'FINISHED',
                                        undo: true
                                    };
                                    resolve(status);
                                }
                            }
                        });
                }

                /**
                 * Function to execute after cancelling operation.
                 */
                function cancelCreateBuildingShape() {
                    ee.removeListener('finishCreateBuildingShapeOperator', finishCreateBuildingShape);
                    self.setActive(oldObjectId)
                        .then(() => {
                            delete self.tempObject;
                            delete self.tempPath;
                            delete newBuilding.customPath;
                            delete newBuilding.customKeepoutPath;
                            self.locations = [];
                            self.layoutRules.scenePreferences.snapToGuides = snapOldValue;
                            resolve({status: 'CANCELLED'});
                        });
                }

                newBuilding.recalculateStepCustomPath();
                ee.emitEvent('updateRender',
                    [{draw2d: true, draw3d: false, updatePanels: true, fitRange: false}]);
                ee.emitEvent('building_createBuildingShapeListener');
                ee.addOnceListener('finishCreateBuildingShapeOperator', finishCreateBuildingShape);
                ee.addOnceListener('cancelCreateBuildingShapeOperator', cancelCreateBuildingShape);
            });
    });

    return promise;
};

EZModelBuilding.prototype.recalculateStepCustomPath = function(args) {
    let type = undefined;
    let index = undefined;
    let value = undefined;
    if (args) {
        type = args[0];
        index = args[1];
        value = args[2];
    }

    var promise = new Promise((resolve) => {
        if (this.customPath) {
            const customPath = this.customPath;
            const keepoutPath = this.customKeepoutPath;
            let cartesianVertices;
            let keepoutCartesianVertices;
            const angle = customPath.buildingShapeValues[customPath.buildingShapeType].angle;

            switch (customPath.buildingShapeType) {
                case 'step':
                    if (args) {
                        if (type === 'corner' && index === 0 && value === undefined) {
                            value = 0;
                        }
                        customPath.customShapeSteps[index][type === 'wall' ? 0 : 1] = value;
                    }
                    cartesianVertices = customPath.calculateStepByStepCartesianVertices();
                    break;
                case 'rectangle':
                    cartesianVertices = customPath.calculateRectangularCartesianVertices();
                    break;
                case 'lShape':
                    customPath.calculateLShapeBuildingValues(args);
                    cartesianVertices = customPath.calculateLShapeCartesianVertices();
                    break;
                case 'cShape':
                    customPath.calculateCShapeBuildingValues(args);
                    cartesianVertices = customPath.calculateCShapeCartesianVertices();
                    break;
                case 'oShape':
                    // Building
                    customPath.calculateOShapeBuildingValues(args);
                    cartesianVertices = customPath.calculateOShapeBuildingCartesianVertices();
                    // Keepout
                    keepoutPath.calculateOShapeBuildingValues(args);
                    keepoutCartesianVertices = keepoutPath.calculateOShapeKeepoutCartesianVertices();
                    break;
                default:
            }

            cartesianVertices = customPath.rotateCartesianVertices(cartesianVertices, angle);
            customPath.calculateCustomShapeLocations(cartesianVertices);

            if (keepoutCartesianVertices) {
                keepoutCartesianVertices = keepoutPath.rotateCartesianVertices(keepoutCartesianVertices, angle);
                keepoutPath.calculateCustomShapeLocations(keepoutCartesianVertices);
            } else if (keepoutPath.vertices.length) {
                // Reset vertices array to avoid drawing the keepout
                // because keepoutPath is created on buildingShape operator
                keepoutPath.vertices = [];
            }

            resolve({status: 'FINISHED'});
        } else {
            resolve({status: 'FINISHED'});
        }
    });

    return promise;
};

EZModelBuilding.prototype.removeStepToCustomShape = function(index) {
    var promise = new Promise((resolve) => {
        if (this.customPath) {
            const customShapeSteps = this.customPath.customShapeSteps;
            customShapeSteps.splice(index, 1);
            if (customShapeSteps.length === 1 && customShapeSteps[0].length > 1) {
                customShapeSteps[0] = [customShapeSteps[0][0]];
            }
            this.recalculateStepCustomPath()
                .then(() => {
                    resolve({
                        status: 'FINISHED'
                    });
                });
        } else {
            resolve({
                status: 'FINISHED'
            });
        }
    });

    return promise;
};

/**
* EZModelElectrical
* @class
* @classdesc The **EZModelElectrical** class represents an electrical diagram.
*/
class EZModelElectrical {
    /**
     * This is the constructor of the **EZModelElectrical** class.
     * @param {object}          data        - JSON with the data of the electrical diagram.
     */
    constructor(data = {}) {
        this.diagram = data.diagram;
        this.products = data.products;
    }
}

/**
 * EZFactoryElectrical
 * @class
 * @classdesc This is the **EZFactoryElectrical** class.
 *
 * @description This is the constructor of the **EZFactoryElectrical** class.
 */
function EZFactoryElectrical() {
    //
}

/**
 * Method to create a new electrical diagram
 *
 * @param  {object} data    - JSON or {@link EZModelPath} of the electrical diagram.
 *
 * @return {EZModelElectrical}   The new electrical diagram created.
 */
EZFactoryElectrical.prototype.create = function(data) {
    let newElectrical;
    if (data === undefined) {
        newElectrical = new EZModelElectrical();
    } else if (data instanceof EZModelElectrical) {
        // data === (EZModelElectrical)
        newElectrical = this.createFromV3(data, true);
    } else if (data.version && data.version >= 3.0) {
        // data === v3 JSON
        newElectrical = this.createFromV3(data, false);
    } else {
        console.error('Error creating Electrical with data ' + data);
    }
    return newElectrical;
};

/**
 * Method to create a electrical using data from the new (v3) model
 *
 * @param  {object}         newJson - JSON with the data of the electrical.
 * @param  {boolean}        clone   - Is the electrical being created as a clone?
 *
 * @return {EZModelElectrical}    The new electrical created.
 */
EZFactoryElectrical.prototype.createFromV3 = function(newJson, clone) {
    var data = _.cloneDeep(newJson);
    return new EZModelElectrical(data, clone);
};

EZFactoryElectrical.prototype.generateRandomMockup = function() {
    const data = {
        diagram: [],
        products:
            {
                'modules': [],          // Bloque de módulos
                'fuseBox': [],          // Caja de fusibles
                'wireDC': [],           // Cables CC
                'combinerBoxDC': [],    // Combiner box CC
                'monitoringDC': [],     // NOT SURE: Monitorización CC
                'inverter': [],         // Inversor
                'rcdevice': [],         // NOT SURE: Diferencial AC <==> Residual Currrent Device ?????
                'circuitBreaker': [],   // NOT SURE: Magnetotérmico AC ?????
                'combinerBoxAC': [],    // Combiner box AC
                'disconnectorAC': [],   // Seccionador AC
                'wireAC': [],           // Cables AC
                'transformer': [],      // Transformador
                'grid': [],             // Red electrical
                'meter': [],            // Contador
                'monitoringAC': [],     // NOT SURE: Monitorización AC
            },
    };
    return new EZModelElectrical(data);
};

/**
  * Method to get the fields about this class.
  * @param  {array} fields               - **Required**. The fields to get.
  * @return {object} values              - Object with the fields to getted.
  */
EZModelElectrical.prototype.getJson = function (fields) {
    var values = {version: ez3dScene.version};
    fields = fields || [
        'diagram',
        'products',
    ];

    fields.forEach(function (field) {
        if (this[field] !== undefined) {
            if (this[field] instanceof Object) {
                values[field] = _.cloneDeep(this[field]);
            } else {
                values[field] = self[field];
            }
        }
    });
    return values;
};


