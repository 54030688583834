/**
 * Method to save a project in CRM server.
 * @param  {function} callback - Callback's function.
 * @param  {function} error - error control.
 */
EZModelScene.prototype.sendSaveProject = function(callback, error, retry = 0) {
    var self = this;
    var dataJson = self.getJson();

    var toSend = {
        data: dataJson
    };

    if (self.projectCenterChanged === true) {
        if (self.layoutRules.scenePreferences.enableApi === true) {
            ee.addOnceListener('saveApiEvent-finished', function() {
                self.projectCenterChanged = false;
            });
        } else {
            self.projectCenterChanged = false;
        }
    }

    var connection;
    if (window.XMLHttpRequest) {
        connection = new XMLHttpRequest();
    } else if (window.ActiveXObject) {
        connection = new ActiveXObject('Microsoft.XMLHTTP');
    }

    if (connection.onreadystatechange === null) {
        connection.onreadystatechange = function(res) {
            var viewportMode = self.layoutRules.scenePreferences.viewportMode;
            var invisible = false;
            if (viewportMode === 0 || viewportMode === 3 || viewportMode === 9) {
                invisible = true;
            }
            if (connection.readyState === 4) {
                if (connection.status === 200) {
                    if (callback) {
                        callback(res);
                    }
                    ee.emitEvent('projectSaved');
                    ee.emitEvent('close-saving-notification');

                    if (!invisible) {
                        self.layoutInstance.ez3dViewport.ez3dGuiManager.notificationManager(['projectSaved', {style: 'success', spiner: false}]);
                    }
                } else if (connection.status === 400) {
                    if (!error) {
                        ee.emitEvent('close-saving-notification');
                        if (!invisible) {
                            self.layoutInstance.ez3dViewport.ez3dGuiManager.notificationManager(['layoutNotEditable', {style: 'error', spiner: false}]);
                        }
                    }
                } else {
                    setTimeout(function() {
                        if (!error && !invisible) {
                            self.layoutInstance.ez3dViewport.ez3dGuiManager.notificationManager(['errorSaveProject', {style: 'error', event: 'projectSaved', spiner: true}]);
                        }
                        if (retry < 5) {
                            retry += 1;
                            self.sendSaveProject(callback, true, retry);
                        }
                    }, 1000);
                }
            }
        };
    }

    var prefs = self.layoutRules.scenePreferences;

    if (prefs.urlHash && prefs
        .editHash === prefs.urlHash) {
        connection.open('POST', prefs.apiURL + '/layout/h/' + prefs.urlHash);
    } else {
        connection.open('POST', prefs.apiURL + '/layout/' + (self.layoutRules.layoutId || '@@defaultProjectId'));
    }

    if (prefs.token !== undefined) {
        connection.setRequestHeader('Authorization', 'Bearer ' + (prefs.token || self.layoutRules.userToken));
    } else if (prefs.apiKey !== undefined) {
        connection.setRequestHeader('X-Api-Key', (prefs.apiKey || self.layoutRules.userToken));
    }

    connection.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');

    if (prefs.snapShotCrm === true) {
        d3.select('#svgProject-limitsGroup').style('display', 'none');
        d3.select('#svgProject-gridGroup').style('display', 'none');
        setTimeout(function(attr, val) {
            html2canvas(document.getElementById('ez3d-canvas')).then(function(canvas) {
                // var image = canvas.toDataURL('image/png');
                var image = canvas.toDataURL('image/jpeg', 0.5);
                dataJson.image = image;

                //
                //  ???????  la imagen se guarda para que la API
                //                 pueda acceder a ella
                //
                self.snapshot = {};
                self.snapshot.data = {};
                self.snapshot.data.image = image;

                connection.send(JSON.stringify(toSend));
                d3.select('#svgProject-limitsGroup').style('display', 'inherit');
                d3.select('#svgProject-gridGroup').style('display', 'inherit');
                // dataJson.image = document.getElementById('playerContainer').childNodes[0].toDataURL('image/jpeg', 0.5);
            });
        }, 1000);
        // self.layoutInstance.ez3dViewport.svgElements.svgProject.fitRangeToContainer(self.layoutInstance.ez3dViewport.svgElements.svgProject.projectRange, 250);
    } else {
        connection.send(JSON.stringify(toSend));
    }
};

/**
 * Method to get a project from the CRM server.
 * @param  {string}      layoutId - Layout's ID to find.
 * @param  {function} callback - Callback's function.
 */
EZModelScene.prototype.getProject = function(layoutId, callback) {
    // Update this code
    var xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function() {
        if (xhttp.readyState === 4 && xhttp.status === 200) {
            var response = xhttp.response;
            callback(response);
        }
    };

    var prefs = ez3dScene.layoutRules.scenePreferences;
    if (prefs.urlHash === undefined) {
        xhttp.open('GET', `${prefs.apiURL}/layout/${layoutId}`);
    } else {
        xhttp.open('GET', `${prefs.apiURL}/layout/h/${prefs.urlHash}`);
    }

    if (prefs.token !== undefined) {
        xhttp.setRequestHeader('Authorization', 'Bearer ' + prefs.token);
    } else if (prefs.apiKey !== undefined) {
        xhttp.setRequestHeader('X-Api-Key', prefs.apiKey);
    }
    xhttp.send();
};

/**
 * Method to get all projects from the CRM server.
 * @param  {function} callback - Callback function.
 */
EZModelScene.prototype.getLayouts = function(callback) {
    // Update this code
    var xhttp = new XMLHttpRequest();
    var headers = {};

    xhttp.onreadystatechange = function() {
        if (xhttp.readyState === 4 && xhttp.status === 200) {
            var response = xhttp.response;
            callback(response);
        }
    };
    xhttp.open('GET', ez3dScene.layoutRules.scenePreferences.apiURL + '/layouts');

    if (ez3dScene.layoutRules.scenePreferences.token !== undefined) {
        xhttp.setRequestHeader('Authorization', 'Bearer ' + ez3dScene.layoutRules.scenePreferences.token);
    } else if (ez3dScene.layoutRules.scenePreferences.apiKey !== undefined) {
        xhttp.setRequestHeader('X-Api-Key', ez3dScene.layoutRules.scenePreferences.apiKey);
    }
    xhttp.send();
};

/**
 * Method to save layout snapshots in CRM server.
 * @param  {function} data - json info.
 */
EZModelScene.prototype.saveLayoutSnapShot = function (data) {
    // conect to crm
    var fd = new FormData();
    var layoutId = data.id;
    fd.append('layout_id', layoutId);
    fd.append('slug', data.slug);

    if (data.slug === 'svg' || data.slug === 'svgAutocad') {
        data.image = new Blob([data.image], {type: 'image/svg+xml'});
        fd.append('image', data.image);
    } else if (data.slug === 'dxf') {
        data.image = new Blob([data.image]);
        fd.append('image', data.image);
    } else {
        data.image.toBlob(function(blob) {
            fd.append('image', blob);
        }, 'image/jpeg', 0.5);
    }

    var connection;
    if (window.XMLHttpRequest) {
        connection = new XMLHttpRequest();
    } else if (window.ActiveXObject) {
        connection = new ActiveXObject('Microsoft.XMLHTTP');
    }

    if (connection.onreadystatechange === null) {
        connection.onreadystatechange = function() {
            if (connection.readyState === 4) {
                if (connection.status === 200) {
                    ee.emitEvent('saveSnapshot_Finished');
                    ez3dViewport.ez3dGuiManager.notificationManager(['snapShotSaved', {style: 'success', spiner: false}]);
                } else if (connection.status === 400) {
                    ee.emitEvent('saveSnapshot_Finished');
                    ez3dViewport.ez3dGuiManager.notificationManager(['snapshotError', {style: 'error', spiner: false}]);
                } else {
                    setTimeout(function () {
                        ez3dViewport.ez3dGuiManager.notificationManager(['There is an error in the server connection, we are trying to save the project, please stand by ...', {style: 'error', event: 'projectSaved', spiner: true}]);
                        // ez3dScene.saveLayoutSnapShot(callback, true);
                    }, 1000);
                }
            }
        };
    }

    var prefs = ez3dScene.layoutRules.scenePreferences;

    if (prefs.urlHash && prefs.editHash === prefs.urlHash) {
        connection.open('POST', prefs.apiURL + '/layout/h/' + prefs.urlHash);
        // ???
    } else {
        connection.open('POST', prefs.apiURL + '/offer/saveLayoutAttachment');
    }

    if (prefs.token !== undefined) {
        connection.setRequestHeader('Authorization', 'Bearer ' + (prefs.token || ez3dScene.layoutRules.userToken));
    } else if (prefs.apiKey !== undefined) {
        connection.setRequestHeader('X-Api-Key', (prefs.apiKey || ez3dScene.layoutRules.userToken));
    }

    setTimeout(function() {
        fd.processData = false;
        fd.contentType = false;
        connection.send(fd);
    }, 1000);
    //  ez3dViewport.svgElements.svgProject.fitRangeToContainer(ez3dViewport.svgElements.svgProject.projectRange, 250);
};

/**
 * Method to send layout snapshots to helpers-api in .svg and return a .dxf file.
 * @param  {function} data - json info.
 * @param  {function} callback - callback function.
 */
EZModelScene.prototype.convertSvgToDxf = function (data, callback) {
    // conect
    var fd = new FormData();

    data = new Blob([data], {type: 'image/svg+xml'});
    fd.append('image', data);

    var connection;
    if (window.XMLHttpRequest) {
        connection = new XMLHttpRequest();
    } else if (window.ActiveXObject) {
        connection = new ActiveXObject('Microsoft.XMLHTTP');
    }

    if (connection.onreadystatechange === null) {
        connection.onreadystatechange = function() {
            if (connection.readyState === 4) {
                if (connection.status === 200) {
                    callback(connection.response);
                    // ez3dViewport.ez3dGuiManager.notificationManager(['convertedDxf', {style: 'success', spiner: false}]);
                } else if (connection.status === 400) {
                    ez3dViewport.ez3dGuiManager.notificationManager(['Something is wrong with the snapShot', {style: 'error', spiner: false}]);
                } else {
                    setTimeout(function () {
                        ez3dViewport.ez3dGuiManager.notificationManager(['There is an error in the server connection, we are trying to save the project, please stand by ...', {style: 'error', event: 'projectSaved', spiner: true}]);
                    }, 1000);
                }
            }
        };
    }
    connection.open('POST', 'https://helpers-layout-api.ezzing.com/api/inkScape');

    setTimeout(function() {
        fd.processData = false;
        fd.contentType = false;
        connection.send(fd);
    }, 1000);
};

/**
 * Method to return country and timezone for fill create account widget
 * @param  {function} callback - callback function.
 */
EZModelScene.prototype.fillCreateAccount = function(callback) {
    var connection;
    if (window.XMLHttpRequest) {
        connection = new XMLHttpRequest();
    } else if (window.ActiveXObject) {
        connection = new ActiveXObject('Microsoft.XMLHTTP');
    }
    // response
    if (connection.onreadystatechange === null) {
        connection.onreadystatechange = function() {
            if (connection.readyState === 4) {
                if (connection.status === 200) {
                    var response = JSON.parse(connection.response);
                    var countries = [];
                    var timezones = [];
                    response.countries.forEach(function (country) {
                        countries[country.code] = country.name;
                    });
                    response.timezones.forEach(function (timezone) {
                        timezones[timezone] = timezone;
                    });
                    var json = {
                        countries: countries,
                        timezones: timezones
                    };
                    callback(json);
                }
            }
        };
    }

    // open
    connection.open('GET', ez3dScene.layoutRules.scenePreferences.apiURL + '/layout/register/');

    if (ez3dScene.layoutRules.scenePreferences.token !== 'undefined') {
        connection.setRequestHeader('Authorization', 'Bearer ' + (ez3dScene.layoutRules.scenePreferences.token || ez3dScene.layoutRules.userToken));
    } else if (ez3dScene.layoutRules.scenePreferences.apiKey !== 'undefined') {
        connection.setRequestHeader('X-Api-Key', (ez3dScene.layoutRules.scenePreferences.apiKey || ez3dScene.layoutRules.userToken));
    }
    connection.send(null);
};

/**
 * Method to create account in crm
 * @param  {function} payload - info.
 */
EZModelScene.prototype.saveAccountCreated = function(payload) {
    // conection
    var connection;
    if (window.XMLHttpRequest) {
        connection = new XMLHttpRequest();
    } else if (window.ActiveXObject) {
        connection = new ActiveXObject('Microsoft.XMLHTTP');
    }
    // response
    if (connection.onreadystatechange === null) {
        connection.onreadystatechange = function () {
            if (connection.readyState === 4) {
                if (connection.status === 200) {
                    ez3dViewport.ez3dGuiManager.notificationManager(['accountCreated', {
                        style: 'success',
                        spiner: false
                    }]);
                    // close widget
                    ez3dViewport.ez3dGuiManager.removeWidget('ez3d-widget-create-account');
                    // redirect to https://crm.ezzing.com/check-email
                    var apiURLWithoutApi = ez3dScene.layoutRules.scenePreferences.apiURL;
                    if (apiURLWithoutApi.includes('/api')) {
                        apiURLWithoutApi = apiURLWithoutApi.slice(0, -4);
                    }
                    location.href = apiURLWithoutApi + '/check-email';
                } else if (connection.status === 400) {
                    ez3dViewport.ez3dGuiManager.notificationManager(['accountNotCreated', {
                        style: 'error',
                        spiner: false
                    }]);
                } else {
                    setTimeout(function () {
                        // self.createAccount();
                    }, 1000);
                }
            }
        };
    }
    // open
    connection.open('POST', ez3dScene.layoutRules.scenePreferences.apiURL + '/layout/register/');

    //  content type

    // open connection
    connection.setRequestHeader('Content-Type', 'application/json;charset=UTF-');
    //  get Bear and userToken
    if (ez3dScene.layoutRules.scenePreferences.token !== 'undefined') {
        connection.setRequestHeader('Authorization', 'Bearer ' + (ez3dScene.layoutRules.scenePreferences.token || ez3dScene.layoutRules.userToken));
    } else if (ez3dScene.layoutRules.scenePreferences.apiKey !== 'undefined') {
        connection.setRequestHeader('X-Api-Key', (ez3dScene.layoutRules.scenePreferences.apiKey || ez3dScene.layoutRules.userToken));
    }
    connection.send(JSON.stringify(payload));
};

// Get base64 image
EZModelScene.prototype.getBase64Image = function(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
        var reader = new FileReader();
        reader.onloadend = function() {
            callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
};
